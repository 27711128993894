import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";

import { Container, FloatingLabel, Tab, Tabs } from "react-bootstrap";
import { API_URL } from "../../../redux/constants/apiConstants";

import Menu from "../../../core/Menu";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import {
  createSalesOrderInternal,
  FilterProductsForInvoice,
  listOrderFromInvoice,
  listOrderTypeInvoice,
  listPriceSummaryBasedOnProductAndCustomer,
  listProductsForInvoice,
  listSalesOrderInternalDetails,
  updateSalesOrderAdminEdit,
} from "../../../redux/actions/invoiceActions";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../../core/Popup";
import {
  listCategories,
  listProductDetailsBasedCategory,
  listGetSamplesByCategory,
} from "../../../redux/actions/productActions";
import SuccessPopup from "../../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import SampleItem from "../../../core/SampleItem";
import { listCustomerDetails } from "../../../redux/actions/customerActions";
import {
  listSupplierDetails,
  listSuppliersForDropdown,
} from "../../../redux/actions/supplierActions";
import { SUPPLIER_DETAILS_RESET } from "../../../redux/constants/supplierConstants";
import { CUSTOMER_DETAILS_RESET } from "../../../redux/constants/customerConstants";
import SalesOrderItemAdvanced from "../../../core/SalesOrderItemAdvanced";
import SalesOrderItemAdminEdit from "../../../core/SalesOrderItemAdminEdit";
var FilteredProduct = [];
let obj = {};

const EditSalesOrderInternalAdmin = ({ match, history }) => {
  const salesOrderId = match.params.id;
  const dispatch = useDispatch();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  const supplierDropdownList = useSelector(
    (state) => state.supplierDropdownList
  );
  const {
    loading: loadingSuppliers,
    error: errorSupplier,
    suppliers: suppliersDropdown,
  } = supplierDropdownList;

  const supplierDetails = useSelector((state) => state.supplierDetails);
  const {
    loading: loadingSupplierDetails,
    error: errorSupplierDetails,
    supplier,
  } = supplierDetails;
  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const invoiceOrderFromList = useSelector(
    (state) => state.invoiceOrderFromList
  );
  const {
    loading: loadingOrderFrom,
    error: errorOrderFrom,
    orderFroms,
  } = invoiceOrderFromList;
  // console.log("products");
  // console.log(products);
  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const priceSummaryBasedOnProductAndCustomerList = useSelector(
    (state) => state.priceSummaryBasedOnProductAndCustomerList
  );
  const {
    loading: loadingPriceSummaryList,
    error: errorPriceSummaryList,
    priceSummaryList,
  } = priceSummaryBasedOnProductAndCustomerList;

  const salesOrderInternalUpdate = useSelector(
    (state) => state.salesOrderInternalUpdate
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedSalesOrder,
  } = salesOrderInternalUpdate;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;
  // console.log(salesorderinternalsingle);

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const customerDetails = useSelector((state) => state.customerDetails);
  const {
    loading: loadingDetailsCustomer,
    error: errorDetailsCustomer,
    customer,
  } = customerDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [switchDiscountPercentage, setSwitchDiscountPercentage] =
    useState(false);
  const [switchTOpen, setSwitchTOpen] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [mappingOption, setMappingOption] = useState(false);
  const [categoryQuantity, setCategoryQuantity] = useState(1);
  const [invoiceDate, setInvoiceDate] = useState("");

  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "",
    unitPrice: "",
    vat: "",
    ait: "",
    discount: "",
    lineTotal: "0.00",
    switchDiscountPercentage: false,
    discountType: "F",
    discountRate: "",
  });
  const [lineTotal, setLineTotal] = useState(0);
  const [productVatAmmount, setProductVatAmmount] = useState(0.0);
  const [productAitAmmount, setProductAitAmmount] = useState(0.0);
  const [quantityPrice, setQuantityPrice] = useState(0.0);
  const [vadTotal, setVadTotal] = useState(0.0);
  const [subPurchase, setSubPurchase] = useState(0.0);
  const [vatAmmount, setVatAmmount] = useState(0.0);
  const [aitAmmount, setAitAmmount] = useState(0.0);
  const [discountAmmount, setDiscountAmmount] = useState(0.0);
  const [adjustmentAmmount, setAdjustmentAmmount] = useState(0.0);

  const [total, setTotal] = useState(0.0);
  const [total2, setTotal2] = useState(0.0);
  const [selected, setSelected] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [selectedOrderFrom, setSelectedOrderFrom] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [data, setData] = useState({
    isOpen: false,
    soRelatedLcId: "",
    soCustomerReferance: "",
    currency: "TK",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    taxRate: "",
    taxAmmount: "0.00",
    vatRate: "",
    vatAmmount: "0.00",
    aitRate: "",
    aitAmmount: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0.00",
    discountRate: "",
    discountAmmount: "0.00",
    adjustmentAmount: "0.00",
    switchDiscountPercentage: false,
    sobCurrencyConversionRate: parseFloat(1),
    editableField: 1,
    items: [],
  });
  const [showMore, setShowMore] = useState(false);
  //   uuidv4()
  const users = [
    { id: 1, label: "ann" },
    { id: 2, label: "rudi" },
    { id: 3, label: "rudolph" },
    { id: 3, label: "3meterstoolong" },
  ];

  // console.log("product");
  // console.log(product);

  useEffect(() => {
    if (userInfo) {
      setSwitchTStuck(true);
      dispatch(listProductsForInvoice());
      dispatch(listSalesOrderInternalDetails(salesOrderId));

      dispatch(listCategories());
      dispatch(listCustomersForSignup());
      dispatch(listSuppliersForDropdown());
      dispatch(listOrderTypeInvoice());
      dispatch(listOrderFromInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: {
          from: { pathname: `/salesorderinternal/admin/${salesOrderId}/edit` },
        },
      };
      history.push(location);
    }
  }, [salesOrderId, dispatch, history, userInfo]);

  const changeValues = () => {
    setSwitchTStuck(true);
    if (
      JSON.stringify(salesorderinternalsingle) !== "{}" &&
      salesorderinternalsingle !== undefined
    ) {
      var d = new Date(
        salesorderinternalsingle.stockOutGeneralData.invoiceDate
      );

      d = d.toString();
      // console.log("d");
      // console.log(d);
      d = d.split(" ");

      d = [d[2], d[1], d[3]].join("-");
      setInvoiceDate(d);
      setSelectedCustomer([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soCustomerId,
          label: salesorderinternalsingle.stockOutGeneralData.customerName,
        },
      ]);
      dispatch(
        listCustomerDetails(
          salesorderinternalsingle.stockOutGeneralData.soCustomerId
        )
      );
      setSelectedOrderType([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soJobTypeId,
          label: salesorderinternalsingle.stockOutGeneralData.jobTypeName,
        },
      ]);
      setSelectedOrderFrom([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soOrderFromId,
          label: salesorderinternalsingle.stockOutGeneralData.soOrderFrom,
        },
      ]);

      salesorderinternalsingle.stockOutItemsData.map((single) => {
        if (!FilteredProduct.includes(single.sodProductId)) {
          FilteredProduct.push(single.sodProductId);
        }
      });

      dispatch(FilterProductsForInvoice(FilteredProduct));
      setSwitchTStuck(true);

      let salesOrderInternalArray =
        salesorderinternalsingle.stockOutItemsData.map((singleData, i) => {
          return {
            index: i,
            productId: singleData.sodProductId,
            productName: singleData.productName,
            quantity: singleData.sodQty || 0,
            discountType:
              singleData.sodUnitDiscountType === null
                ? "F"
                : singleData.sodUnitDiscountType,
            vat: singleData.sodUnitVatPercantage || 0,
            productVatAmmount:
              singleData.sodBaseUnitPrice *
              (Math.abs(singleData.sodUnitVatPercantage || 0) / 100),
            ait: singleData.sodUnitAitPercantage || 0,
            productAitAmmount:
              singleData.sodBaseUnitPrice *
              (Math.abs(singleData.sodUnitAitPercantage || 0) / 100),
            sumVatAit:
              singleData.sodBaseUnitPrice *
                (Math.abs(singleData.sodUnitVatPercantage || 0) / 100) +
              singleData.sodBaseUnitPrice *
                (Math.abs(singleData.sodUnitAitPercantage || 0) / 100),
            sodBaseUnitPrice: singleData.sodBaseUnitPrice,
            unitPrice: singleData.sodUnitPrice,
            discountRate: singleData.sodUnitDiscountRate || 0,
            discountAmmount: singleData.sodUnitDiscountAmount || 0,
            switchDiscountPercentage:
              singleData.sodUnitDiscountType === "F" ? false : true,
            lineTotal:
              parseFloat(singleData.sodFinalCalculatedUnitPrice).toFixed(2) ||
              0,
          };
        });

      setData({
        ...data,
        sobCurrencyConversionRate: parseFloat(
          salesorderinternalsingle.stockOutBillData.sobCurrencyConversionRate
        ),
        soCustomerReferance:
          salesorderinternalsingle.stockOutGeneralData.soCustomerReferance,
        soRelatedLcId:
          salesorderinternalsingle.stockOutGeneralData.soRelatedLcId,
        currency:
          salesorderinternalsingle.stockOutBillData.sobCurrencyName === "BDT"
            ? "TK"
            : "$",
        invoiceNumber: salesorderinternalsingle.stockOutGeneralData.soSystemNo,
        items: salesOrderInternalArray,
        subTotal: salesorderinternalsingle.stockOutBillData.calculatedBill,
        vatRate: salesorderinternalsingle.stockOutBillData.billVatPercantage,
        aitRate: salesorderinternalsingle.stockOutBillData.billAitPercantage,
        discount: parseFloat(
          salesorderinternalsingle.stockOutBillData.billDiscountRate
        ).toFixed(2),
        switchDiscountPercentage:
          salesorderinternalsingle.stockOutBillData.billDiscountType === "F"
            ? false
            : true,
        adjustmentAmount: parseFloat(
          salesorderinternalsingle.stockOutBillData.billAdjustmentAmount
        ).toFixed(2),
        notes: salesorderinternalsingle.stockOutGeneralData.soRemarks,
      });
    }
  };

  useEffect(() => {
    changeValues();
  }, [salesorderinternalsingle]);

  useEffect(() => {
    handleCalculateSubTotal();
    handleCalculateTotal();
    handleProductCalculateTotal();
  }, []);

  useEffect(() => {
    handleProductCalculateTotal();
  }, [product]);

  useEffect(() => {
    handleCalculateTotal();
  }, [data]);

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    // console.log("d");
    // console.log(d);
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const handleRowDel = (items) => {
    // console.log("items");
    // console.log(items);
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.productId;
    });
    // console.log("FilteredProduct");
    // console.log(FilteredProduct);
    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);
    var newItems = data.items.map((single, i) => {
      return {
        ...single,
        index: i,
      };
    });
    setData({
      ...data,
      items: newItems,
    });
    handleCalculateSubTotal();
  };

  const handleAddEvent = (evt) => {
    console.log("added");
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "0",
      description: "",
      quantity: 1,
    };
    // console.log("selectedCustomer");
    // console.log(selectedCustomer);
    if (
      product.id !== "" &&
      selectedOrderFrom.length > 0 &&
      selectedOrderType.length > 0 &&
      ((selectedOrderType[0].label === "Purchase_Return" &&
        selectedSupplier.length > 0) ||
        selectedCustomer.length > 0)
    ) {
      if (
        selectedOrderType.length > 0 &&
        selectedOrderType[0].label === "Sample_Issue"
      ) {
        setSwitchTStuck(true);
        data.items.push({
          index: data.items.length,
          productId: product.id,
          productName: product.label,
          quantity: product.quantity || 0,
          vat: product.vat || 0,
          ait: product.ait || 0,
          unitPrice: 0,
          discountType: "F",
          discountRate: 0,
          discountAmmount: 0,
          lineTotal: 0,
        });
        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "",
          unitPrice: "",
          vat: "",
          ait: "",
          discount: "",
          switchDiscountPercentage: false,
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        setAdjustmentAmmount(0.0);
        handleCalculateSubTotal();
      } else {
        if (lineTotal < 0) {
          setOpenPopup({
            ...openPopup,
            title: "VALUE ISSUE!!!",
            subTitle: "Line Total can't be negative!",
            isOpen: true,
          });
        } else {
          setSwitchTStuck(true);

          // console.log("product.switchDiscountPercentage");
          // console.log(product.switchDiscountPercentage);
          var discountAmmount = product.discount || 0;
          if (product.switchDiscountPercentage) {
            discountAmmount = lineTotal * (product.discount / 100);
          }

          data.items.push({
            productId: product.id,
            productName: product.label,
            quantity: product.quantity || 0.0,
            vat: product.vat || 0,
            ait: product.ait || 0,
            unitPrice: lineTotal,
            discountRate: product.discount || 0,
            discountType: product.switchDiscountPercentage ? "P" : "F",
            discountAmmount: discountAmmount || 0,
            lineTotal: parseFloat(lineTotal * product.quantity).toFixed(2) || 0,
          });
          setData({
            ...data,
            items: data.items,
            vatRate: "",
            aitRate: "",
          });

          setProduct({
            ...product,
            id: "",
            label: "",
            quantity: "",
            unitPrice: "",
            vat: "",
            ait: "",
            discount: "",
            switchDiscountPercentage: false,
          });
          FilteredProduct.push(product.id);
          dispatch(FilterProductsForInvoice(FilteredProduct));
          setSelected([]);
          setProductVatAmmount(0.0);
          setProductAitAmmount(0.0);
          setDiscountAmmount(0.0);
          setAdjustmentAmmount(0.0);
          handleCalculateSubTotal();
        }
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };
  // console.log("products");
  // console.log(products);
  const handleProductCalculateTotal = () => {
    // console.log("product");
    // console.log(product);
    var quantity = parseFloat(product.quantity || 0);
    var unitPrice = parseFloat(product.unitPrice || 0);
    var vat = parseFloat(product.vat || 0);
    var ait = parseFloat(product.ait || 0);
    var discount = parseFloat(product.discount || 0);
    var productDiscountAmmount = discount;

    var lineTotal = parseFloat(0);
    var quantityPrice = parseFloat(0);
    var vadTotal = parseFloat(0);
    var subPurchase = parseFloat(0);

    if (product.switchDiscountPercentage) {
      productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
    }
    lineTotal = lineTotal + (unitPrice - productDiscountAmmount);

    quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

    var lineTotalVat = unitPrice * (vat / 100);
    var lineTotalAit = unitPrice * (ait / 100);

    lineTotal = lineTotal + lineTotalVat + lineTotalAit;

    vadTotal = lineTotalVat + lineTotalAit;
    vadTotal = parseFloat(vadTotal.toFixed(2));
    lineTotal = lineTotal.toFixed(2);

    quantityPrice = parseFloat(quantityPrice.toFixed(2));

    subPurchase = unitPrice + vadTotal;
    subPurchase = parseFloat(subPurchase.toFixed(2));

    setQuantityPrice(quantityPrice);
    setVadTotal(vadTotal);
    setSubPurchase(subPurchase);
    setLineTotal(lineTotal);
  };

  const handleCalculateSubTotal = () => {
    var items = data.items;

    var subTotal = parseFloat(0);

    items.map(function (item) {
      // console.log("item");
      var singleLineTotal = parseFloat(item.lineTotal);
      // console.log(typeof singleLineTotal);
      subTotal = subTotal + singleLineTotal;

      subTotal = parseFloat(subTotal);
    });
    setData({
      ...data,
      subTotal: parseFloat(subTotal).toFixed(2),
      taxAmmount: parseFloat(
        parseFloat(subTotal) * (data.taxRate / 100)
      ).toFixed(2),
      discountAmmount: parseFloat(
        parseFloat(subTotal) * (data.discountRate / 100)
      ).toFixed(2),
      total: subTotal - data.discountAmmount + parseFloat(data.taxAmmount),
    });
    setTotal(subTotal - data.discountAmmount + parseFloat(data.taxAmmount));
    setTotal2(subTotal - data.discountAmmount + parseFloat(data.taxAmmount));
  };
  const handleCalculateTotal = () => {
    var items = data.items;

    var subTotal = parseFloat(0);
    var discountOverall = parseFloat(data.discount || 0);
    var adjustmentAmount = parseFloat(data.adjustmentAmount || 0);
    var sobCurrencyConversionRate =
      data.currency === "TK"
        ? parseFloat(1)
        : parseFloat(data.sobCurrencyConversionRate || parseFloat(1));
    var productOverallDiscountAmmount = discountOverall;

    items.map(function (item) {
      // console.log("item");
      var singleLineTotal = parseFloat(item.lineTotal);
      // console.log(typeof singleLineTotal);
      subTotal = subTotal + singleLineTotal;

      subTotal = parseFloat(subTotal);
    });
    var totalT = parseFloat(0);
    var totalT2 = parseFloat(0);
    var vatAmmountT = parseFloat(0);
    vatAmmountT = subTotal * (data.vatRate / 100);
    setVatAmmount(vatAmmountT);

    var aitAmmountT = parseFloat(0);
    aitAmmountT = subTotal * (data.aitRate / 100);
    setAitAmmount(aitAmmountT);

    totalT = subTotal + vatAmmountT + aitAmmountT;

    if (data.switchDiscountPercentage) {
      productOverallDiscountAmmount =
        totalT * (productOverallDiscountAmmount / 100);
    }

    setDiscountAmmount(productOverallDiscountAmmount);
    setAdjustmentAmmount(adjustmentAmount);

    // console.log("totalT");
    // console.log(totalT);
    totalT = totalT - productOverallDiscountAmmount - adjustmentAmount;

    setTotal(totalT.toFixed(2));
    totalT2 = totalT;
    totalT2 = totalT2 * sobCurrencyConversionRate;
    setTotal2(totalT2.toFixed(2));
  };

  const handleCalculateTotalFromEditable = (id, items) => {
    // console.log("data.items");
    // console.log(items);
    var items = items;
    var subTotal = parseFloat(0);
    var sum = parseFloat(0);
    var newItems = items.map(function (sitems) {
      var quantity = parseFloat(sitems.quantity || 0);
      var sodBaseUnitPrice = parseFloat(sitems.sodBaseUnitPrice || 0);
      var unitPrice = parseFloat(sitems.unitPrice || 0);
      var vat = parseFloat(sitems.vat || 0);
      var ait = parseFloat(sitems.ait || 0);
      var productVatAmmount = parseFloat(sitems.productVatAmmount || 0);
      var productAitAmmount = parseFloat(sitems.productAitAmmount || 0);
      var discount = parseFloat(sitems.discountRate || 0);
      var productDiscountAmmount = discount;

      var lineTotal = parseFloat(0);
      var quantityPrice = parseFloat(0);
      var vadTotal = parseFloat(0);
      var subPurchase = parseFloat(0);

      if (sitems.switchDiscountPercentage) {
        productDiscountAmmount =
          sodBaseUnitPrice * (productDiscountAmmount / 100);
      }

      lineTotal = lineTotal + (sodBaseUnitPrice - productDiscountAmmount);

      quantityPrice = parseFloat(sodBaseUnitPrice) * parseFloat(quantity);

      var lineTotalVat = sodBaseUnitPrice * (vat / 100);
      var lineTotalAit = sodBaseUnitPrice * (ait / 100);

      lineTotal = lineTotal + lineTotalVat + lineTotalAit;

      vadTotal = lineTotalVat + lineTotalAit;
      vadTotal = parseFloat(vadTotal.toFixed(2));
      lineTotal = lineTotal.toFixed(2);
      sum = sum + lineTotal * parseFloat(sitems.quantity);
      quantityPrice = parseFloat(quantityPrice.toFixed(2));

      subPurchase = sodBaseUnitPrice + vadTotal;
      subPurchase = parseFloat(subPurchase.toFixed(2));

      for (var key in sitems) {
        if (key == "lineTotal" && sitems.index == id) {
          sitems[key] = parseFloat(
            parseFloat(sitems.unitPrice).toFixed(2) *
              parseFloat(sitems.quantity)
          ).toFixed(2);
          if (
            parseFloat(
              parseFloat(sitems.unitPrice).toFixed(2) *
                parseFloat(sitems.quantity)
            ).toFixed(2) < 0
          ) {
            setOpenPopup({
              ...openPopup,
              title: "NEGATIVE VALUE!!!",
              subTitle: "New price cann't be negative!",
              isOpen: true,
            });
          }
        } else if (key == "productVatAmmount" && sitems.index == id) {
          sitems[key] = lineTotalVat;
        } else if (key == "productAitAmmount" && sitems.index == id) {
          sitems[key] = lineTotalAit;
        } else if (key == "sumVatAit" && sitems.index == id) {
          sitems[key] = vadTotal;
        } else if (key == "unitPrice" && sitems.index == id) {
          sitems[key] = parseFloat(lineTotal).toFixed(2);
        }
      }
      return sitems;
    });
    // console.log("sum");
    // console.log(sum);
    setData({
      ...data,
      items: newItems,
      subTotal: parseFloat(sum).toFixed(2),
    });
  };

  const onItemizedProductEdit = (evt) => {
    console.log("evt");
    console.log(evt);
    // console.log(evt.productId);

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      // console.log("sitems");
      // console.log(sitems);
      for (var key in sitems) {
        if (key == evt.name && sitems.index == evt.index) {
          // console.log("Hello");
          sitems["productId"] = evt.id;
          sitems["productName"] = evt.label;
        }
      }
      return sitems;
    });
    // console.log("newItems");
    // console.log(newItems);
    if (evt.id === null) {
      FilteredProduct = FilteredProduct.filter((item) => {
        return item !== evt.productId;
      });

      dispatch(FilterProductsForInvoice(FilteredProduct));
    } else {
      FilteredProduct.push(evt.id);
      dispatch(FilterProductsForInvoice(FilteredProduct));
    }

    setData({
      ...data,
      items: newItems,
    });
  };
  // console.log(data.items);

  const onItemizedItemEdit = (evt) => {
    // console.log("evt");
    // console.log(evt.target.value);
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var items = data.items.slice();

    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == item.name && sitems.index == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });

    handleCalculateTotalFromEditable(item.id, newItems);
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
    // handleCalculateTotal();
  };

  const editProductField = (event) => {
    // await setProduct({
    //   ...product,

    // });
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
  };

  const openModal = (event) => {
    event.preventDefault();

    if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      var flag = 0;
      var flag2 = 0;

      if (data.items.length > 0) {
        var dataArray = data.items.map((singleData) => {
          if (
            singleData.unitPrice === "1.0000" ||
            singleData.unitPrice === "1.00" ||
            singleData.unitPrice === "1.0" ||
            singleData.unitPrice === "1" ||
            singleData.unitPrice === 1
          ) {
            flag = 1;
          }
          if (parseFloat(singleData.unitPrice) < 0) {
            flag2 = 1;
          }
          return {
            sodProductId: parseInt(singleData.productId),

            sodMappedProductId: 0,
            sodBatches: null,
            productName: singleData.productName,
            sodQty: parseFloat(singleData.quantity),
            sodBaseUnitPrice: parseFloat(singleData.sodBaseUnitPrice),
            sodUnitPrice: parseFloat(singleData.unitPrice),
            sodUnitDiscountType: singleData.discountType,
            sodUnitDiscountRate: parseFloat(singleData.discountRate),
            sodUnitDiscountAmount: parseFloat(singleData.discountAmmount),

            sodUnitVatPercantage: parseInt(singleData.vat),
            sodUnitAitPercantage: parseInt(singleData.ait),
            sodFinalCalculatedUnitPrice: parseFloat(singleData.lineTotal),
            itemRemark: "sdw",
          };
        });

        var data1 = {
          stockOutGeneralData: {
            soSystemId: parseInt(salesOrderId),
            invoiceDate: new Date(
              salesorderinternalsingle.stockOutGeneralData.invoiceDate
            ),
            soSystemNo: salesorderinternalsingle.stockOutGeneralData.soSystemNo,
            soJobTypeId: parseInt(selectedOrderType[0].id),
            soOrderFromId: parseInt(selectedOrderFrom[0].id),
            soCurrentStatus: 0,
            soRemarks: data.notes !== "" ? data.notes : null,
            soDestinationChannelId: 2,
            soCustomerReferance:
              data.soCustomerReferance !== "" ? data.soCustomerReferance : null,
            soRelatedLcId:
              selectedOrderType.length > 0 &&
              selectedOrderType[0].label === "LC_Local"
                ? data.soRelatedLcId
                : null,
            soCustomerId:
              selectedCustomer.length > 0 ? selectedCustomer[0].id : null,
            customerName:
              selectedCustomer.length > 0 ? selectedCustomer[0].label : null,
            customerAddress:
              JSON.stringify(customer) !== "{}" && selectedCustomer.length > 0
                ? customer.customerAddress
                : null,
            soSupplierId:
              selectedSupplier.length > 0 ? selectedSupplier[0].id : null,
            supplierName:
              selectedSupplier.length > 0 ? selectedSupplier[0].label : null,
            supplierAddress:
              JSON.stringify(supplier) !== "{}" && selectedSupplier.length > 0
                ? supplier.supplierAddress
                : null,
            soPriceReview: flag ? 1 : 0,
            soCompanyId: 2,
            jobTypeName: selectedOrderType[0].label,
          },
          stockOutItemsData: dataArray,
          stockOutBillData: {
            calculatedBill: parseFloat(data.subTotal),
            billDiscountType: data.switchDiscountPercentage ? "P" : "F",
            billDiscountRate: data.switchDiscountPercentage ? data.discount : 0,
            billDiscountAmount: discountAmmount,
            billDiscountNote: "stweering",
            billVatPercantage: data.vatRate || 0,
            billAitPercantage: data.aitRate || 0,
            billAdjustmentAmount: data.adjustmentAmount || 0,
            finalBill: parseFloat(total),
            sobCurrencyName: data.currency === "TK" ? "BDT" : "USD",
            sobCurrencyConversionRate: parseFloat(
              data.sobCurrencyConversionRate
            ),
            sobFinalConvertedBill: parseFloat(total2),
          },
        };
        // console.log("data1");
        // console.log(data1);
        if (flag2) {
          setOpenPopup({
            ...openPopup,
            title: "NEGATIVE VALUE!!!",
            subTitle: "None of the new price can be negative!",
            isOpen: true,
          });
        } else {
          dispatch(updateSalesOrderAdminEdit(data1)).then((result) => {
            if (result?.title === "One or more validation errors occurred.") {
              setOpenPopup({
                ...openPopup,
                title: "ERROR!!!",
                subTitle: "Something went wrong!",
                isOpen: true,
              });
            } else if (result?.errMsg) {
              setOpenPopup({
                ...openPopup,
                title: "ERROR!!!",
                subTitle: `${result?.errMsg}`,
                isOpen: true,
              });
            } else {
              history.push("/salesorderinternal/general");
            }
          });

          handleCalculateTotal();
        }
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });

  return (
    <div>
      <Menu />

      <Container>
        <Form>
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={
                selectedOrderType.length > 0 &&
                (selectedOrderType[0].label === "Sample_Issue" ||
                  selectedOrderType[0].label === "Mixing_Issue" ||
                  selectedOrderType[0].label === "Exchange_Outward" ||
                  selectedOrderType[0].label === "Loan_Issue" ||
                  selectedOrderType[0].label === "Purchase_Return" ||
                  selectedOrderType[0].label === "Warehouse_Transfer")
                  ? 12
                  : 9
              }
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <Form.Label className="fw-bold">
                  Invoice&nbsp;Number:&nbsp;{data.invoiceNumber}
                </Form.Label>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          marginRight: "10px",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Type:</Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="orderTypeId"
                          label="Order Type"
                          disabled={
                            loadingOrderType
                              ? true
                              : data.items.length > 0 && switchTStuck
                              ? true
                              : false
                          }
                          onChange={(selectedOrderType) => {
                            dispatch({ type: SUPPLIER_DETAILS_RESET });
                            dispatch({ type: CUSTOMER_DETAILS_RESET });
                            setSelectedSupplier([]);
                            setSelectedCustomer([]);
                            setSelectedOrderType(selectedOrderType);
                          }}
                          options={orderTypes !== undefined ? orderTypes : []}
                          placeholder="Order Type"
                          selected={selectedOrderType}
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                          // marginRight: "10px",
                        }}
                      >
                        <Form.Group controlId="form-customerId">
                          <Form.Label className="fw-bold">
                            Order From:
                          </Form.Label>

                          <Typeahead
                            clearButton
                            id="basic-example"
                            name="customerId"
                            label="Order From"
                            disabled={
                              loadingOrderFrom
                                ? true
                                : data.items.length > 0 && switchTStuck
                                ? true
                                : false
                            }
                            onChange={(selectedOrderFrom) => {
                              setSelectedOrderFrom(selectedOrderFrom);
                            }}
                            options={orderFroms !== undefined ? orderFroms : []}
                            placeholder="Order From"
                            selected={selectedOrderFrom}
                            // disabled={switchTStuck ? true : false}
                          />
                        </Form.Group>
                      </div>
                    </Form.Group>

                    {selectedOrderType.length > 0 &&
                    selectedOrderType[0].label === "Purchase_Return" ? (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Supplier Name:
                        </Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="customerId"
                          label="Supplier Name"
                          disabled={
                            loadingSuppliers
                              ? true
                              : data.items.length > 0 && switchTStuck
                              ? true
                              : false
                          }
                          onChange={(selected) => {
                            // console.log("selected.length");
                            // console.log(selected.length);
                            if (selected.length > 0) {
                              // console.log("selected");
                              // console.log(selected);
                              dispatch(listSupplierDetails(selected[0].id));
                              if (product.id !== "" && selected.length > 0) {
                                dispatch(
                                  listPriceSummaryBasedOnProductAndCustomer({
                                    customerId: selected[0].id,
                                    prodcutId: product.id,
                                  })
                                );
                              }
                            }
                            setSelectedSupplier(selected);
                          }}
                          options={
                            suppliersDropdown !== undefined
                              ? suppliersDropdown
                              : []
                          }
                          placeholder="Supplier Name"
                          selected={selectedSupplier}
                          // disabled={switchTStuck ? true : false}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Customer Name:
                        </Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="customerId"
                          label="Customer Name"
                          //disabled={
                            //loadingCustomer
                             // ? true
                             // : data.items.length > 0 && switchTStuck
                             // ? true
                             // : false
                         // }
                          onChange={(selected) => {
                            if (selected.length > 0) {
                              dispatch(listCustomerDetails(selected[0].id));
                              if (product.id !== "" && selected.length > 0) {
                                dispatch(
                                  listPriceSummaryBasedOnProductAndCustomer({
                                    customerId: selected[0].id,
                                    prodcutId: product.id,
                                  })
                                );
                              }
                            }
                            setSelectedCustomer(selected);
                          }}
                          options={customers !== undefined ? customers : []}
                          placeholder="Customer Name"
                          selected={selectedCustomer}
                          // disabled={switchTStuck ? true : false}
                        />
                      </Form.Group>
                    )}

                    {selectedOrderType.length > 0 &&
                      selectedOrderType[0].label === "LC_Local" && (
                        <div>
                          <Form.Group controlId="form-customerId">
                            <Form.Label className="fw-bold">
                              LC Related ID/Number:
                            </Form.Label>

                            <Form.Control
                              name="soRelatedLcId"
                              type="text"
                              value={data.soRelatedLcId}
                              onChange={(event) => {
                                setData({
                                  ...data,
                                  soRelatedLcId: event.target.value,
                                });
                              }}
                              placeholder="LC Related ID/Number"
                              disabled={
                                data.items.length > 0 && switchTStuck
                                  ? true
                                  : false
                              }
                              // required="required"
                            />
                          </Form.Group>
                        </div>
                      )}
                  </Col>
                  <Col>
                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          marginRight: "10px",
                        }}
                      >
                        <Form.Label className="fw-bold">
                          Reference No:
                        </Form.Label>

                        <Form.Control
                          name="soCustomerReferance"
                          type="text"
                          value={data.soCustomerReferance}
                          onChange={(event) => {
                            setData({
                              ...data,
                              soCustomerReferance: event.target.value,
                            });
                          }}
                          placeholder="Reference No"
                         // disabled={
                           // data.items.length > 0 && switchTStuck ? true : false
                         // }
                          // required="required"
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Date:</Form.Label>
                        <Form.Control
                          placeholder={"Who is this invoice to?"}
                          value={invoiceDate}
                          type="text"
                          name="dateOfIssue"
                          onChange={(event) => editField(event)}
                          autoComplete="name"
                          required="required"
                          //disabled
                        />
                      </div>
                    </Form.Group>

                    {selectedOrderType.length > 0 &&
                    selectedOrderType[0].label === "Purchase_Return" ? (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Supplier&nbsp;Address:&nbsp;
                        </Form.Label>
                        <Form.Control
                          placeholder={"supplierAddress"}
                          value={
                            JSON.stringify(supplier) !== "{}" &&
                            selectedSupplier.length > 0
                              ? supplier.supplierAddress !== null
                                ? supplier.supplierAddress
                                : "Not Available"
                              : "Not Available"
                          }
                          type="text"
                          name="invoiceNumber"
                          autoComplete="address"
                          onChange={(event) => editField(event)}
                          required="required"
                          as="textarea"
                          rows={1}
                          disabled
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Customer&nbsp;Address:&nbsp;
                        </Form.Label>
                        <Form.Control
                          placeholder={"customerAddress"}
                          value={
                            JSON.stringify(customer) !== "{}"
                              ? customer.customerAddress !== null
                                ? customer.customerAddress
                                : "Not Available"
                              : "Not Available"
                          }
                          type="text"
                          name="invoiceNumber"
                          autoComplete="address"
                          onChange={(event) => editField(event)}
                          required="required"
                          as="textarea"
                          rows={1}
                          disabled
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                <hr className="my-3" />

                {selectedOrderType.length > 0 &&
                selectedOrderType[0].label === "Sample_Issue" ? (
                  <Row className="mb-2">
                    <Col>
                      <Tabs
                        defaultActiveKey="profile"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                      >
                        <Tab eventKey="home" title="Sample By Category">
                          <Form.Group
                            controlId="form-quantity-unitPrice"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div style={{ width: "50%" }}>
                              <Form.Label className="fw-bold">
                                Quantity:
                              </Form.Label>
                              <Form.Control
                                name="quantity"
                                type="number"
                                value={categoryQuantity}
                                onChange={(event) => {
                                  setCategoryQuantity(event.target.value);
                                }}
                                placeholder="1"
                                step="1"
                                // step="1"

                                // required="required"
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <Form.Label className="fw-bold">
                                Product Category:
                              </Form.Label>

                              <Typeahead
                                clearButton
                                id="basic-example"
                                name="productId"
                                label="Product Category"
                                disabled={loading ? true : false}
                                onChange={(selected) => {
                                  // console.log("selected");
                                  // console.log(selected);
                                  setSelectedCategory(selected);
                                  const value =
                                    selected.length > 0
                                      ? {
                                          id: selected[0].id,
                                          label: selected[0].label,
                                        }
                                      : "";
                                  // console.log("value");
                                  // console.log(value);
                                  if (value?.id) {
                                    dispatch(
                                      //listProductDetailsBasedCategory(value.id)
                                      listGetSamplesByCategory(value.id)
                                    ).then((res) => {
                                      // console.log("res");
                                      // console.log(res);
                                      var processedData =
                                        res.length > 0 &&
                                        res.map((product) => {
                                          if (
                                            !FilteredProduct.includes(
                                              product.productId
                                            )
                                          ) {
                                            FilteredProduct.push(
                                              product.productId
                                            );
                                            return {
                                              productId: product.productId,
                                              productName: product.productName,
                                              quantity: categoryQuantity,
                                              vat: 0,
                                              ait: 0,
                                              unitPrice: 0,
                                              discountAmmount: 0,
                                              lineTotal: 0,
                                            };
                                          }
                                        });

                                      processedData = processedData.filter(
                                        (filterSingle) => {
                                          return filterSingle !== undefined;
                                        }
                                      );
                                      if (data.items.length > 0) {
                                        processedData = [
                                          ...data.items,
                                          ...processedData,
                                        ];
                                      }

                                      setData({
                                        ...data,
                                        items: processedData,
                                      });
                                      setProduct({
                                        ...product,
                                        id: "",
                                        label: "",
                                        quantity: "",
                                        unitPrice: "",
                                        vat: "",
                                        ait: "",
                                        discount: "",
                                      });
                                      console.log("FilteredProduct");
                                      console.log(FilteredProduct);
                                      dispatch(
                                        FilterProductsForInvoice(
                                          FilteredProduct
                                        )
                                      );
                                      setSwitchTStuck(true);
                                    });
                                  }

                                  // setProduct({
                                  //   ...product,
                                  //   id: value.id,
                                  //   label: value.label,
                                  // });
                                }}
                                options={
                                  categories !== undefined ? categories : []
                                }
                                placeholder="Product Category"
                                selected={selectedCategory}
                              />
                            </div>
                          </Form.Group>
                        </Tab>
                        <Tab eventKey="profile" title="Sample By Product">
                          <Form.Group
                            controlId="form-quantity-unitPrice"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div style={{ width: "50%" }}>
                              <Form.Label className="fw-bold">
                                Product Name:
                              </Form.Label>

                              <Typeahead
                                clearButton
                                id="basic-example"
                                name="productId"
                                label="Product Name"
                                disabled={loadingProduct ? true : false}
                                onChange={(selected) => {
                                  // console.log("selected");
                                  // console.log(selected);
                                  setSelected(selected);
                                  const value =
                                    selected.length > 0
                                      ? {
                                          id: selected[0].id,
                                          label: selected[0].label,
                                        }
                                      : {
                                          id: "",
                                          label: "",
                                        };
                                  // console.log(value);
                                  setProduct({
                                    ...product,
                                    id: value.id,
                                    label: value.label,
                                  });
                                }}
                                options={products !== undefined ? products : []}
                                placeholder="Product Name"
                                selected={selected}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <Form.Label className="fw-bold">
                                Quantity:
                              </Form.Label>
                              <Form.Control
                                name="quantity"
                                type="number"
                                value={product.quantity}
                                onChange={(event) => {
                                  editProductField(event);
                                }}
                                placeholder="1"
                                step="1"
                                // step="1"

                                // required="required"
                              />
                            </div>
                          </Form.Group>

                          <Button
                            className="fw-bold my-3"
                            onClick={handleAddEvent}
                          >
                            Add Item
                          </Button>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                ) : selectedOrderType.length > 0 &&
                  (selectedOrderType[0].label === "Mixing_Issue" ||
                    selectedOrderType[0].label === "Exchange_Outward" ||
                    selectedOrderType[0].label === "Loan_Issue" ||
                    selectedOrderType[0].label === "Purchase_Return") ? (
                  <Row className="mb-2">
                    <Col>
                      <Form.Group controlId="form-productId">
                        <Form.Label className="fw-bold">
                          Product Name:
                        </Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="productId"
                          label="Product Name"
                          disabled={loadingProduct ? true : false}
                          onChange={(selected) => {
                            // console.log("selected");
                            // console.log(selected);
                            setSelected(selected);
                            const value =
                              selected.length > 0
                                ? {
                                    id: selected[0].id,
                                    label: selected[0].label,
                                  }
                                : {
                                    id: "",
                                    label: "",
                                  };
                            // console.log(value);
                            setProduct({
                              ...product,
                              id: value.id,
                              label: value.label,
                            });
                          }}
                          options={products !== undefined ? products : []}
                          placeholder="Product Name"
                          selected={selected}
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="form-quantity-unitPrice"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Form.Label className="fw-bold">Quantity:</Form.Label>
                          <Form.Control
                            name="quantity"
                            type="number"
                            value={product.quantity}
                            onChange={(event) => {
                              editProductField(event);
                            }}
                            placeholder="1"

                            // step="1"

                            // required="required"
                          />
                        </div>
                        <div>
                          <Form.Label className="fw-bold">
                            Unit Price:
                          </Form.Label>
                          <Form.Control
                            name="unitPrice"
                            type="number"
                            value={product.unitPrice}
                            onChange={(event) => {
                              editProductField(event);
                            }}
                            placeholder="0.00"
                            // min="1"
                            step="0.1"
                            presicion="2"
                            // required="required"
                          />
                        </div>
                      </Form.Group>

                      <Button className="fw-bold my-3" onClick={handleAddEvent}>
                        Add Item
                      </Button>
                    </Col>
                    <Col></Col>
                  </Row>
                ) : selectedOrderType.length > 0 &&
                  selectedOrderType[0].label === "Warehouse_Transfer" ? (
                  <Row className="mb-2">
                    <Col>
                      <Form.Group controlId="form-productId">
                        <Form.Label className="fw-bold">
                          Product Name:
                        </Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="productId"
                          label="Product Name"
                          disabled={loadingProduct ? true : false}
                          onChange={(selected) => {
                            // console.log("selected");
                            // console.log(selected);
                            setSelected(selected);
                            const value =
                              selected.length > 0
                                ? {
                                    id: selected[0].id,
                                    label: selected[0].label,
                                  }
                                : {
                                    id: "",
                                    label: "",
                                  };
                            // console.log(value);
                            setProduct({
                              ...product,
                              id: value.id,
                              label: value.label,
                            });
                          }}
                          options={products !== undefined ? products : []}
                          placeholder="Product Name"
                          selected={selected}
                        />
                      </Form.Group>
                      <Form.Group controlId="form-quantity">
                        <Form.Label className="fw-bold">Quantity:</Form.Label>
                        <Form.Control
                          name="quantity"
                          type="number"
                          value={product.quantity}
                          onChange={(event) => {
                            editProductField(event);
                          }}
                          placeholder="1"
                        />
                      </Form.Group>
                      <Button className="fw-bold my-3" onClick={handleAddEvent}>
                        Add Item
                      </Button>
                    </Col>
                    <Col></Col>
                  </Row>
                ) : (
                  <Row className="mb-2">
                    <Col>
                      <Form.Group controlId="form-productId">
                        <Form.Label className="fw-bold">
                          Product Name:
                        </Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="productId"
                          label="Product Name"
                          disabled={loadingProduct ? true : false}
                          onChange={(selected) => {
                            // console.log("selected");
                            // console.log(selected);
                            setSelected(selected);
                            const value =
                              selected.length > 0
                                ? {
                                    id: selected[0].id,
                                    label: selected[0].label,
                                  }
                                : {
                                    id: "",
                                    label: "",
                                  };
                            // console.log(value);
                            setProduct({
                              ...product,
                              id: value.id,
                              label: value.label,
                            });
                            if (
                              value.id !== "" &&
                              selectedCustomer.length > 0
                            ) {
                              console.log("Hi");
                              dispatch(
                                listPriceSummaryBasedOnProductAndCustomer({
                                  customerId: selectedCustomer[0].id,
                                  prodcutId: value.id,
                                })
                              );
                            }
                          }}
                          options={products !== undefined ? products : []}
                          placeholder="Product Name"
                          selected={selected}
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="form-quantity-unitPrice"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Form.Label className="fw-bold">Quantity:</Form.Label>
                          <InputGroup style={{ width: "80%" }}>
                            <Form.Control
                              name="quantity"
                              type="number"
                              value={product.quantity}
                              onChange={(event) => {
                                editProductField(event);
                              }}
                              placeholder="1"

                              // step="1"

                              // required="required"
                            />
                            <Button
                              variant="dark"
                              onClick={() => {
                                if (
                                  product.id !== "" &&
                                  selectedOrderType.length > 0 &&
                                  ((selectedOrderType[0].label ===
                                    "Purchase_Return" &&
                                    selectedSupplier.length > 0) ||
                                    selectedCustomer.length > 0) &&
                                  product.quantity !== ""
                                ) {
                                  var myHeaders = new Headers();
                                  myHeaders.append(
                                    "Authorization",
                                    `Bearer ${userInfo.token}`
                                  );
                                  var requestOptions = {
                                    method: "GET",
                                    headers: myHeaders,
                                    redirect: "follow",
                                  };

                                  fetch(
                                    `${API_URL}/Price/GetPriceByCustomerAndQty/${selected[0].id}/${product.id}/${product.quantity}`,
                                    requestOptions
                                  )
                                    .then((response) => response.json())
                                    .then((result) => {
                                      if (result?.errMsg) {
                                        if (
                                          result.errMsg ===
                                          "No base price found for this product!"
                                        ) {
                                          setProduct((prevState) => ({
                                            ...prevState,
                                            unitPrice: Math.abs(0),
                                          }));
                                        }
                                      } else {
                                        console.log(result);
                                        setProduct((prevState) => ({
                                          ...prevState,
                                          unitPrice: Math.abs(
                                            result.dataObj.data.productPrice
                                          ),
                                        }));
                                      }
                                    })
                                    .catch((error) =>
                                      console.log("error", error)
                                    );
                                } else {
                                  var msg =
                                    product.id === ""
                                      ? "Product Name"
                                      : "" + selectedCustomer.length < 1
                                      ? "Customer Name"
                                      : selectedSupplier.length < 1
                                      ? "Supplier Name"
                                      : "" + product.quantity === ""
                                      ? "Product Quantity"
                                      : "";
                                  msg = msg + " Missing!";
                                  setOpenPopup({
                                    ...openPopup,
                                    title: "EMPTY FIELD!!!",
                                    subTitle: `${msg}`,
                                    isOpen: true,
                                  });
                                }
                              }}
                            >
                              Get Price
                            </Button>
                          </InputGroup>
                        </div>
                        <div>
                          <Form.Label className="fw-bold">
                            Unit Price:
                          </Form.Label>
                          <InputGroup>
                            <Form.Select
                              aria-label="Default select example"
                              style={{ maxWidth: "40%" }}
                              disabled={
                                data.items.length > 0 && switchTStuck
                                  ? true
                                  : false
                              }
                              value={data.currency}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  currency: e.target.value,
                                });
                              }}
                            >
                              <option value="TK">BDT</option>
                              <option value="$">USD</option>
                            </Form.Select>
                            <Form.Control
                              name="unitPrice"
                              type="number"
                              value={product.unitPrice}
                              onChange={(event) => {
                                editProductField(event);
                              }}
                              placeholder="0.00"
                              // min="1"
                              step="0.1"
                              presicion="2"
                              // required="required"
                            />
                          </InputGroup>
                        </div>
                      </Form.Group>

                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Apply VAT and AIT?"
                        className="mt-2"
                        defaultChecked={switchT}
                        onChange={(e) => {
                          if (switchT) {
                            setSwitchT(false);
                            setProduct({
                              ...product,
                              vat: "0.00",
                              ait: "0.00",
                            });
                          } else {
                            setSwitchT(true);
                            setData({
                              ...data,
                              vat: "0.00",
                              ait: "0.00",
                            });
                          }
                        }}
                        disabled={
                          data.items.length > 0 && switchTStuck ? true : false
                        }
                      />
                      <Button className="fw-bold my-3" onClick={handleAddEvent}>
                        Add Item
                      </Button>
                    </Col>
                    <Col>
                      <Form.Group
                        controlId="form-vat-ait"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Form.Label className="fw-bold">VAT:</Form.Label>
                          <InputGroup>
                            <Form.Control
                              name="vat"
                              type="number"
                              value={product.vat}
                              onChange={(event) => {
                                editProductField(event);
                                setProductVatAmmount(
                                  product.unitPrice *
                                    (Math.abs(event.target.value) / 100)
                                );
                              }}
                              placeholder="0.0"
                              disabled={switchT ? false : true}
                            />
                            <InputGroup.Text id="basic-addon1">
                              %
                            </InputGroup.Text>
                            <InputGroup.Text id="basic-addon1">
                              » {parseFloat(productVatAmmount).toFixed(2)}
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div>
                          <Form.Label className="fw-bold">AIT:</Form.Label>
                          <InputGroup>
                            <Form.Control
                              name="ait"
                              type="number"
                              value={product.ait}
                              onChange={(event) => {
                                editProductField(event);
                                setProductAitAmmount(
                                  product.unitPrice *
                                    (Math.abs(event.target.value) / 100)
                                );
                              }}
                              placeholder="0.0"
                              disabled={switchT ? false : true}
                            />
                            <InputGroup.Text id="basic-addon1">
                              %
                            </InputGroup.Text>
                            <InputGroup.Text id="basic-addon1">
                              » {parseFloat(productAitAmmount).toFixed(2)}
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                      </Form.Group>
                      <Form.Label className="fw-bold">
                        SUM(VAT | AIT):
                      </Form.Label>
                      <Form.Control
                        placeholder={"SUM(VAT | AIT)"}
                        type="number"
                        name="vadTotal"
                        value={vadTotal}
                        // onChange={(event) => editProductField(event)}
                        required="required"
                        disabled
                      />

                      <Form.Group
                        controlId="form-discount-finalPrice"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Form.Label className="fw-bold">Discount:</Form.Label>
                          <InputGroup>
                            <Form.Control
                              style={{ width: "120px" }}
                              name="discount"
                              type="number"
                              value={product.discount}
                              onChange={(event) => {
                                editProductField(event);
                              }}
                              placeholder="0.00"
                              // min="0"
                              // step="1"
                              autoComplete="off"
                              // required="required"
                            />

                            <InputGroup.Text id="basic-addon1">
                              <Form.Check
                                type="checkbox"
                                id="custom-checkbox"
                                label="%"
                                className=""
                                checked={product.switchDiscountPercentage}
                                onChange={(e) => {
                                  if (product.switchDiscountPercentage) {
                                    // setSwitchDiscountPercentage(false);
                                    setProduct({
                                      ...product,
                                      switchDiscountPercentage: false,
                                    });
                                  } else {
                                    // setSwitchDiscountPercentage(true);
                                    setProduct({
                                      ...product,
                                      switchDiscountPercentage: true,
                                    });
                                  }
                                }}
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </div>

                        <div>
                          <Form.Label className="fw-bold">
                            New Price:
                          </Form.Label>
                          <Form.Control
                            placeholder={"line total"}
                            // min="1"

                            type="number"
                            name="lineTotal"
                            value={lineTotal}
                            // onChange={(event) => editProductField(event)}
                            required="required"
                            disabled
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {selectedOrderType.length > 0 &&
                (selectedOrderType[0].label === "Sample_Issue" ||
                  selectedOrderType[0].label === "Warehouse_Transfer") ? (
                  <SampleItem
                    onItemizedItemEdit={onItemizedItemEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                  />
                ) : (
                  <SalesOrderItemAdminEdit
                    onItemizedProductEdit={onItemizedProductEdit}
                    onItemizedItemEdit={onItemizedItemEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                    escalate={data.editableField}
                    switchMappingOption={false}
                    allOptionAuto={false}
                    products={products !== undefined ? products : []}
                    mappingOption={{
                      mappingOption,
                      setMappingOption,
                    }}
                  />
                )}

                <Row className="mt-4 justify-content-end">
                  <Col lg={6}>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Subtotal:</span>
                      <span>
                        {data.currency}
                        {parseFloat(data.subTotal).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">VAT:</span>
                      <span>
                        <span className="small ">({data.vatRate || 0}%)</span>
                        {data.currency}
                        {vatAmmount.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">AIT:</span>
                      <span>
                        <span className="small ">({data.aitRate || 0}%)</span>
                        {data.currency}
                        {aitAmmount.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">Discount:</span>
                      <span>
                        {data.switchDiscountPercentage ? (
                          <span className="small ">
                            ({parseFloat(data.discount).toFixed(2) || 0}%)
                          </span>
                        ) : (
                          ""
                        )}

                        {data.currency}
                        {discountAmmount.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Adjustment amount:</span>
                      <span>
                        {data.currency}{" "}
                        {parseFloat(adjustmentAmmount).toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Final Bill:</span>
                      <span>
                        {data.currency}
                        {parseFloat(total).toFixed(4) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Conversion Rate:</span>
                      <span>
                        {parseFloat(
                          data.sobCurrencyConversionRate || 0
                        ).toFixed(4) || parseFloat(1).toFixed(4)}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Converted Amount:</span>
                      <span>
                        {data.currency}
                        {parseFloat(total2 || 0).toFixed(4) || 0}
                      </span>
                    </div>
                    <hr />
                    <div
                      className="d-flex flex-row align-items-start justify-content-between"
                      style={{
                        fontSize: "1.125rem",
                      }}
                    >
                      <span className="fw-bold">Total:</span>
                      <span className="fw-bold">
                        {data.currency}
                        {parseFloat(total2).toFixed(4) || 0}
                      </span>
                    </div>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Form.Label className="fw-bold">Remarks:</Form.Label>
                <Form.Control
                  placeholder="Keep some notes!"
                  name="notes"
                  as="textarea"
                  className="my-2"
                  value={data.notes}
                  onChange={(event) => {
                    setData({
                      ...data,
                      notes: event.target.value,
                    });
                  }}
                  rows={1}
                />
                <hr className="my-3" />
                {selectedOrderType.length > 0 &&
                (selectedOrderType[0].label === "Sample_Issue" ||
                  selectedOrderType[0].label === "Mixing_Issue" ||
                  selectedOrderType[0].label === "Exchange_Outward" ||
                  selectedOrderType[0].label === "Loan_Issue" ||
                  selectedOrderType[0].label === "Purchase_Return" ||
                  selectedOrderType[0].label === "Warehouse_Transfer") ? (
                  <div>
                    <Button
                      variant="primary"
                      size="lg"
                      type="button"
                      onClick={openModal}
                    >
                      {loadingUpdate ? (
                        <CircularProgress size="1rem" color="error" />
                      ) : (
                        ""
                      )}{" "}
                      Update Sales Order
                    </Button>{" "}
                    <Button
                      variant="danger"
                      size="lg"
                      onClick={() => {
                        history.push("/salesorderinternal/general");
                      }}
                    >
                      Back
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Card>
            </Col>
            <Col
              md={4}
              lg={3}
              style={{
                display:
                  selectedOrderType.length > 0 &&
                  (selectedOrderType[0].label === "Sample_Issue" ||
                    selectedOrderType[0].label === "Mixing_Issue" ||
                    selectedOrderType[0].label === "Exchange_Outward" ||
                    selectedOrderType[0].label === "Loan_Issue" ||
                    selectedOrderType[0].label === "Purchase_Return" ||
                    selectedOrderType[0].label === "Warehouse_Transfer")
                    ? "none"
                    : "",
                transition: "all 0.5s ease-out",
              }}
            >
              <div
                className="pt-md-3 pt-xl-4 mt-4 p-3"
                style={{
                  backgroundColor: "#f6f6fd",
                  backgroundImage:
                    "url(" +
                    "https://www.transparenttextures.com/patterns/asfalt-light.png" +
                    ")",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    height:
                      JSON.stringify(priceSummaryList) === "[]"
                        ? "200px"
                        : "auto",
                    width: "100%",
                    /* From https://css.glass */
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "16px",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                >
                  {JSON.stringify(priceSummaryList) !== "[]" ? (
                    priceSummaryList !== undefined ? (
                      <div style={{ padding: "15px" }}>
                        <h4>{priceSummaryList[0].productName}</h4>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {priceSummaryList
                              .slice(0, showMore ? priceSummaryList.length : 3)
                              .map((single) => {
                                return (
                                  <tr>
                                    <td>{dateConverter(single.salesDate)}</td>
                                    <td>{single.salesPrice}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Show Less" : "Show More"}
                        </button>
                      </div>
                    ) : (
                      <div style={{ padding: "15px" }}>Loading.. </div>
                    )
                  ) : (
                    <div style={{ padding: "15px" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>No Data</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">VAT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="vatRate"
                      type="number"
                      value={data.vatRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">AIT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="aitRate"
                      type="number"
                      value={data.aitRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Discount rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="discount"
                      type="number"
                      value={data.discount}
                      onChange={(event) => {
                        editField(event);
                      }}
                      placeholder="0.00"
                      step="any"
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      <Form.Check
                        type="checkbox"
                        id="custom-checkbox"
                        label="%"
                        className=""
                        checked={data.switchDiscountPercentage}
                        onChange={(e) => {
                          if (data.switchDiscountPercentage) {
                            // setSwitchDiscountPercentage(false);
                            setData({
                              ...data,
                              switchDiscountPercentage: false,
                            });
                          } else {
                            // setSwitchDiscountPercentage(true);
                            setData({
                              ...data,
                              switchDiscountPercentage: true,
                            });
                          }
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">
                    Adjustment Amount:
                  </Form.Label>
                  <Form.Control
                    name="adjustmentAmount"
                    type="number"
                    value={data.adjustmentAmount}
                    onChange={(event) => {
                      editField(event);
                    }}
                    placeholder="1.00"
                  />
                </Form.Group>
                {data.currency === "$" && (
                  <Form.Group className="my-3">
                    <Form.Label className="fw-bold">
                      Conversion Rate:
                    </Form.Label>
                    <Form.Control
                      name="sobCurrencyConversionRate"
                      type="number"
                      value={data.sobCurrencyConversionRate}
                      onChange={(event) => {
                        editField(event);
                      }}
                      placeholder="0.00"
                    />
                  </Form.Group>
                )}
                <div>
                  <Button
                    variant="primary"
                    size="sm"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Update Sales Order
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      history.push("/salesorderinternal/general");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      </Container>
    </div>
  );
};

export default EditSalesOrderInternalAdmin;

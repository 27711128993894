import React, { useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import autoTable from "jspdf-autotable";

import { Button as MuiButton } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function PDFGenerator({ columns, data, classes, loading, pdfTitle }) {
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const headerRef = useRef(null);

  const generatePDF = async () => {
    setIsGeneratingPDF(true);
    const img = new Image();
    img.src = process.env.PUBLIC_URL + "/images/companyHeader.jpg";
    img.style.marginLeft = "55px";

    //const pdfTitle = "PendingAndCancelReport";
    const doc = new jsPDF();

    doc.addImage(img, "PNG", 3, 3, 205, 30);
    doc.autoTable({
      head: [columns.map((column) => column.Header)],
      body: data.map((row) => columns.map((column) => row[column.accessor])),
      startY: 40,
      styles: {
        //   fontSize: 15,
      },
    });

    doc.save(`${pdfTitle}.pdf`);
  };

  return (
    <div>
      <div ref={headerRef}>
        <img src="" alt="" />
      </div>

      <MuiButton
        className={classes.submitButtonGroup}
        startIcon={
          loading ? (
            <CircularProgress size="1rem" color="error" />
          ) : (
            <PictureAsPdfIcon fontSize="small" />
          )
        }
        size="large"
        text="Export PDF"
        type="submit"
        onClick={generatePDF}
      >
        <img
          width={40}
          height={40}
          src={process.env.PUBLIC_URL + "/images/pdf-icon.png"}
          alt=""
        />
      </MuiButton>
    </div>
  );
}

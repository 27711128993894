import {
  INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL,
  INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST,
  INVOICE_CHEQUE_KEY_VALUE_LIST_RESET,
  INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS,
  INVOICE_DETAILS_FAIL,
  INVOICE_DETAILS_REQUEST,
  INVOICE_DETAILS_RESET,
  INVOICE_DETAILS_SUCCESS,
  PAYMENT_CREATE_FAIL,
  PAYMENT_CREATE_REQUEST,
  PAYMENT_CREATE_RESET,
  PAYMENT_CREATE_SUCCESS,
  PAYMENT_DETAILS_FAIL,
  PAYMENT_DETAILS_REQUEST,
  PAYMENT_DETAILS_RESET,
  PAYMENT_DETAILS_SUCCESS,
  PAYMENT_HISTORY_DETAILS_FAIL,
  PAYMENT_HISTORY_DETAILS_REQUEST,
  PAYMENT_HISTORY_DETAILS_RESET,
  PAYMENT_HISTORY_DETAILS_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_MODE_LIST_FAIL,
  PAYMENT_MODE_LIST_REQUEST,
  PAYMENT_MODE_LIST_SUCCESS,
  PAYMENT_UPDATE_FAIL,
  PAYMENT_UPDATE_REQUEST,
  PAYMENT_UPDATE_RESET,
  PAYMENT_UPDATE_SUCCESS,
} from "../constants/paymentConstants";

export const PaymentListReducer = (state = { payments: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case PAYMENT_LIST_REQUEST:
      return { loading: true };
    case PAYMENT_LIST_SUCCESS:
      return {
        loading: false,
        payments: action.payload,
      };
    case PAYMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const PaymentModeListReducer = (
  state = { paymentmodes: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case PAYMENT_MODE_LIST_REQUEST:
      return { loading: true };
    case PAYMENT_MODE_LIST_SUCCESS:
      return {
        loading: false,
        paymentmodes: action.payload,
      };
    case PAYMENT_MODE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const InvoiceChequeListReducer = (
  state = { invoicecheques: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST:
      return { loading: true };
    case INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS:
      return {
        loading: false,
        invoicecheques: action.payload,
      };
    case INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL:
      return { loading: false, error: action.payload };

    case INVOICE_CHEQUE_KEY_VALUE_LIST_RESET:
      return { invoicecheques: {} };
    default:
      return state;
  }
};

export const InvoiceDetailsReducer = (
  state = { invoiceSingle: {} },
  action
) => {
  switch (action.type) {
    case INVOICE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case INVOICE_DETAILS_SUCCESS:
      return { loading: false, invoiceSingle: action.payload };
    case INVOICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case INVOICE_DETAILS_RESET:
      return { invoiceSingle: {} };

    default:
      return state;
  }
};

export const PaymentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_CREATE_REQUEST:
      return { loading: true };
    case PAYMENT_CREATE_SUCCESS:
      return { loading: false, success: true, payment: action.payload };
    case PAYMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const PaymentDetailsReducer = (
  state = { paymentSingle: {} },
  action
) => {
  switch (action.type) {
    case PAYMENT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PAYMENT_DETAILS_SUCCESS:
      return { loading: false, paymentSingle: action.payload };
    case PAYMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_DETAILS_RESET:
      return { paymentSingle: {} };

    default:
      return state;
  }
};

export const PaymentUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_UPDATE_REQUEST:
      return { loading: true };
    case PAYMENT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PAYMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const PaymentHistoryDetailsReducer = (
  state = { paymentHistoryList: [] },
  action
) => {
  switch (action.type) {
    case PAYMENT_HISTORY_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PAYMENT_HISTORY_DETAILS_SUCCESS:
      return { loading: false, paymentHistoryList: action.payload };
    case PAYMENT_HISTORY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_HISTORY_DETAILS_RESET:
      return { paymentHistoryList: [] };

    default:
      return state;
  }
};

import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  id: 0,
  fullname: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: [date.getFullYear(), mnth, day].join("-"),
  isPermanent: false,
};

const AddInhouseEmployee = () => {
  const { values, setValues, handleChange } = useForm(initialValues);

  return (
    <Form>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Input
            label="Full Name"
            name="fullname"
            value={values.fullname}
            onChange={handleChange}
          />
          <Input
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          <Input
            label="mobile"
            name="mobile"
            value={values.mobile}
            onChange={handleChange}
          />
          <Input
            label="city"
            name="city"
            value={values.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            variant="outlined"
            size="small"
            label="Department"
            name="departmentId"
            value={values.departmentId}
            onChange={handleChange}
            options={getDepartmentCollection}
          />
          <RadioGroup
            label="gender"
            name="gender"
            value={values.gender}
            onChange={handleChange}
            items={genderItems}
          />
          <Datepicker
            label="hireDate"
            name="hireDate"
            value={values.hireDate}
            onChange={handleChange}
          />
          <Checkbox
            label="Permanent Employee"
            name="isPermanent"
            value={values.isPermanent}
            onChange={handleChange}
          />
          <div>
            <Button type="submit" color="primary" size="large" text="Submit" />
            <Button color="secondary" size="large" text="Reset" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AddInhouseEmployee;

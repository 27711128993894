import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  BANK_LIST_FAIL,
  BANK_LIST_REQUEST,
  BANK_LIST_SUCCESS,
  CHEQUE_CREATE_FAIL,
  CHEQUE_CREATE_REQUEST,
  CHEQUE_CREATE_SUCCESS,
  CHEQUE_DELETE_FAIL,
  CHEQUE_DELETE_REQUEST,
  CHEQUE_DELETE_SUCCESS,
  CHEQUE_DETAILS_FAIL,
  CHEQUE_DETAILS_REQUEST,
  CHEQUE_DETAILS_SUCCESS,
  CHEQUE_LIST_FAIL,
  CHEQUE_LIST_REQUEST,
  CHEQUE_LIST_SUCCESS,
  CHEQUE_STATUS_LIST_FAIL,
  CHEQUE_STATUS_LIST_REQUEST,
  CHEQUE_STATUS_LIST_SUCCESS,
  CHEQUE_TYPE_LIST_FAIL,
  CHEQUE_TYPE_LIST_REQUEST,
  CHEQUE_TYPE_LIST_SUCCESS,
  CHEQUE_UPDATE_FAIL,
  CHEQUE_UPDATE_REQUEST,
  CHEQUE_UPDATE_SUCCESS,
  ISSUER_BANK_LIST_FAIL,
  ISSUER_BANK_LIST_REQUEST,
  ISSUER_BANK_LIST_SUCCESS,
} from "../constants/chequeConstants";

export const listCheque = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CHEQUE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/CustomerPayment/GetChequeInfoGrid`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({ type: CHEQUE_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: CHEQUE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listChequeStatus = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CHEQUE_STATUS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetChequeStatusKeyValue`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: CHEQUE_STATUS_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: CHEQUE_STATUS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listBank = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BANK_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetBankListKeyValue/1`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: BANK_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: BANK_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listIssuerBank = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ISSUER_BANK_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetBankListKeyValue/0`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: ISSUER_BANK_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: ISSUER_BANK_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listChequeType = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CHEQUE_TYPE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetPaymentMethodKeyValue/1`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: CHEQUE_TYPE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: CHEQUE_TYPE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createCheque = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHEQUE_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/CustomerPayment/AddChequeInfo`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: CHEQUE_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: CHEQUE_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: CHEQUE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCheque = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHEQUE_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/CustomerPayment/UpdateChequeInfo`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: CHEQUE_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: CHEQUE_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: CHEQUE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listChequeDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: CHEQUE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/CustomerPayment/GetChequeInfoById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log(data[0]);

    dispatch({ type: CHEQUE_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: CHEQUE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCheque = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHEQUE_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "delete",

      url: `${API_URL}/Customer/DeleteCustomer?id=${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await Axios(config);
    dispatch({ type: CHEQUE_DELETE_SUCCESS });
    //
  } catch (error) {
    dispatch({
      type: CHEQUE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

class EditableProductField extends React.Component {
  render() {
    // console.log("this.props.celldata");
    // console.log(this.props.onItemizedItemEdit);
    var { celldata, itemizeditemedit, ...others } = this.props;

    return (
      <InputGroup className="my-1 flex-nowrap">
        {this.props.celldata.leading != null && (
          <InputGroup.Text className="bg-light fw-bold border-0 text-secondary px-2">
            <span
              className="border border-2 border-secondary rounded-circle d-flex align-items-center justify-content-center small"
              style={{ width: "20px", height: "20px" }}
            >
              {this.props.celldata.leading}
            </span>
          </InputGroup.Text>
        )}

        <Form.Control
          index={celldata.index}
          className={celldata.textAlign}
          type={celldata.type}
          placeholder={celldata.placeholder}
          min={celldata.min}
          name={celldata.name}
          id={celldata.id}
          value={celldata.value}
          step={celldata.step}
          presicion={celldata.presicion}
          aria-label={celldata.ariaLabel}
          onChange={itemizeditemedit}
          {...others}
          required
        />
      </InputGroup>
    );
  }
}

export default EditableProductField;

import { API_URL } from "../constants/apiConstants";
import Axios from "axios";

import {
  BATCH_DETAILS_FAIL,
  BATCH_DETAILS_REQUEST,
  BATCH_DETAILS_SUCCESS,
  BATCH_LIST_FAIL,
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_UPDATE_FAIL,
  BATCH_UPDATE_REQUEST,
  BATCH_UPDATE_SUCCESS,

  

} from "../constants/BatchConstants";

export const listBatches = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: BATCH_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Batch/GetBatchesGridData/${order}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({ type: BATCH_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: BATCH_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listBatchDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: BATCH_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Batch/GetBatchInfoById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log(data[0]);

    dispatch({
      type: BATCH_DETAILS_SUCCESS,
      payload: data.dataObj[0],
    });
  } catch (error) {
    dispatch({
      type: BATCH_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBatch = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: BATCH_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/Batch/UpdateBatch`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: BATCH_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: BATCH_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    // console.log("finalGotData");
    // console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: BATCH_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getLastMappAndBatch = (customerId, productId) => async (
  dispatch,
  getState
) => {
  try {
    // Dispatch a request action
    // You can use constants here if needed
    dispatch({ type: "GET_LAST_MAPP_AND_BATCH_REQUEST" });

    // Get user information from the state
    const {
      userLogin: { userInfo },
    } = getState();

    // Create the API URL with the provided parameters
    const apiUrl = `https://octopi.algologix.com.bd/api/StockOut/GetLastMappAndBatchByCustomerAndProduct/${customerId}/${productId}`;

    // Set up the Axios request configuration
    const config = {
      method: "get",
      url: apiUrl,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Make the API request
    const { data } = await Axios(config);

    // Dispatch a success action with the received data
    // You can use constants here if needed
    dispatch({ type: "GET_LAST_MAPP_AND_BATCH_SUCCESS", payload: data });

    // Optionally, you can return the data if needed
    return data;
  } catch (error) {
    // Dispatch a failure action with the error message
    // You can use constants here if needed
    dispatch({
      type: "GET_LAST_MAPP_AND_BATCH_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    // Optionally, you can throw the error to be caught by the component
    throw error;
  }
};
import { API_URL } from "../constants/apiConstants";
import Axios from "axios";

import {
  INVOICE_DUPLI_PRODUCT_LIST_REQUEST,
  INVOICE_DUPLI_PRODUCT_LIST_FAIL,
  INVOICE_DUPLI_PRODUCT_LIST_SUCCESS,
  INVOICE_PRODUCT_LIST_FAIL,
  INVOICE_PRODUCT_LIST_REQUEST,
  INVOICE_PRODUCT_LIST_SUCCESS,
  ORDER_TYPE_LIST_FAIL,
  ORDER_TYPE_LIST_REQUEST,
  ORDER_TYPE_LIST_SUCCESS,
  SALESORDERINTERNAL_CREATE_FAIL,
  SALESORDERINTERNAL_CREATE_REQUEST,
  SALESORDERINTERNAL_CREATE_SUCCESS,
  SALESORDERINTERNAL_LIST_FAIL,
  SALESORDERINTERNAL_LIST_REQUEST,
  SALESORDERINTERNAL_LIST_SUCCESS,
  TALLYSALES_CREATE_REQUEST,
  TALLYSALES_CREATE_SUCCESS,
  TALLYSALES_CREATE_FAIL,
  SALESORDERINTERNAL_DETAILS_REQUEST,
  SALESORDERINTERNAL_DETAILS_SUCCESS,
  SALESORDERINTERNAL_DETAILS_FAIL,
  BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_REQUEST,
  BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_SUCCESS,
  BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_FAIL,
  SALESORDERINTERNAL_PACKAGE_REQUEST,
  SALESORDERINTERNAL_PACKAGE_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_FAIL,
  SALESORDERINTERNAL_UPDATE_REQUEST,
  SALESORDERINTERNAL_UPDATE_SUCCESS,
  SALESORDERINTERNAL_UPDATE_FAIL,
  SALESORDERINTERNAL_ESCALATION_REQUEST,
  SALESORDERINTERNAL_ESCALATION_SUCCESS,
  SALESORDERINTERNAL_ESCALATION_FAIL,
  SALESORDERINTERNAL_PACKAGE_DONE_FAIL,
  SALESORDERINTERNAL_PACKAGE_DONE_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_DONE_REQUEST,
  SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_REQUEST,
  SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_FAIL,
  SALESORDERINTERNAL_PACKAGE_DONE_EDIT_FAIL,
  SALESORDERINTERNAL_PACKAGE_DONE_EDIT_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_DONE_EDIT_REQUEST,
  PRICESUMMARY_CUSTOMER_PRODUCT_LIST_REQUEST,
  PRICESUMMARY_CUSTOMER_PRODUCT_LIST_SUCCESS,
  PRICESUMMARY_CUSTOMER_PRODUCT_LIST_FAIL,
  INVOICE_PACK_TYPE_LIST_REQUEST,
  INVOICE_PACK_TYPE_LIST_SUCCESS,
  INVOICE_PACK_TYPE_LIST_FAIL,
  ORDER_FROM_LIST_REQUEST,
  ORDER_FROM_LIST_SUCCESS,
  ORDER_FROM_LIST_FAIL,
  STOCKIN_CREATE_REQUEST,
  STOCKIN_CREATE_SUCCESS,
  STOCKIN_CREATE_FAIL,
  INVOICE_KEY_VALUE_LIST_REQUEST,
  INVOICE_KEY_VALUE_LIST_SUCCESS,
  INVOICE_KEY_VALUE_LIST_FAIL,
  SALESORDERINTERNAL_FILTER_LIST_FAIL,
  SALESORDERINTERNAL_FILTER_LIST_SUCCESS,
  SALESORDERINTERNAL_FILTER_LIST_REQUEST,
  PENDING_LIST_FAIL,
  PENDING_LIST_SUCCESS,
  PENDING_LIST_REQUEST,
  JOB_LIST_SINGLE_FAIL,
  JOB_LIST_SINGLE_SUCCESS,
  JOB_LIST_SINGLE_REQUEST,
  STOCKOUT_PENDING_UPDATE_FAIL,
  STOCKOUT_PENDING_UPDATE_SUCCESS,
  STOCKOUT_PENDING_UPDATE_REQUEST,

  //Admin edit
  SALESORDERINTERNAL_PENDING_PRICE_LIST_REQUEST,
  SALESORDERINTERNAL_PENDING_PRICE_LIST_SUCCESS,
  SALESORDERINTERNAL_PENDING_PRICE_LIST_FAIL,

  //PACKING STICKER
  LIST_ORDER_FOR_PACKING_EIDT_STICKER_REQUEST,
  LIST_ORDER_FOR_PACKING_EIDT_STICKER_SUCCESS,
  LIST_ORDER_FOR_PACKING_EIDT_STICKER_FAIL,
  LIST_ORDER_FOR_PACKING_EIDT_STICKER_RESET,
  
  DETAILS_EDIT_PACKING_STICKER_REQUEST,
  DETAILS_EDIT_PACKING_STICKER_SUCCESS,
  DETAILS_EDIT_PACKING_STICKER_FAIL,
  DETAILS_EDIT_PACKING_STICKER_RESET,
  
  CREATE_PACKING_STICKER_REQUEST,
  CREATE_PACKING_STICKER_SUCCESS,
  CREATE_PACKING_STICKER_FAIL,
  CREATE_PACKING_STICKER_RESET,
  
  UPDATE_PACKING_STICKER_REQUEST,
  UPDATE_PACKING_STICKER_SUCCESS,
  UPDATE_PACKING_STICKER_FAIL,
  UPDATE_PACKING_STICKER_RESET,
  
  //PACKING STICKER END
  LATEST_BATCH_MAP_DETAILS_FAIL,
  LATEST_BATCH_MAP_DETAILS_SUCCESS,
  LATEST_BATCH_MAP_DETAILS_REQUEST,
  
} from "../constants/invoiceConstants";

export const listProductsForInvoice = () => async (dispatch, getState) => {
  try {
    // console.log("hiii");
    dispatch({ type: INVOICE_PRODUCT_LIST_REQUEST });
    dispatch({ type: INVOICE_DUPLI_PRODUCT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Product/GetProducts`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.productId,
        label: single.productName,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: INVOICE_PRODUCT_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
    dispatch({
      type: INVOICE_DUPLI_PRODUCT_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: INVOICE_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch({
      type: INVOICE_DUPLI_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FilterProductsForInvoice =
  (FilteredProduct) => async (dispatch, getState) => {
    try {
      // console.log("hiii");
      // dispatch({ type: INVOICE_PRODUCT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
        invoiceProductList: { products },
        invoiceProductListDupli: { productsDupli },
      } = getState();

      // console.log("productsDupli");
      // console.log(productsDupli);
      console.log("FilteredProduct");
      console.log(FilteredProduct);

      var filteredResult = productsDupli.filter((singleData) => {
        return !FilteredProduct.includes(singleData.id);
      });
      // console.log("hiii");
      // console.log("filteredResult");
      // console.log(filteredResult);

      dispatch({
        type: INVOICE_PRODUCT_LIST_SUCCESS,
        payload: filteredResult.sort((a, b) => a.label.localeCompare(b.label)),
      });
    } catch (error) {
      dispatch({
        type: INVOICE_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listOrderTypeInvoice = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_TYPE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetJobTypeInKeyValue/Stock%20Out`,
    };
    const { data } = await Axios(config);

    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: ORDER_TYPE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: ORDER_TYPE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listOrderTypeStockin = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_TYPE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetJobTypeInKeyValue/Stock%20In`,
    };
    const { data } = await Axios(config);

    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: ORDER_TYPE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: ORDER_TYPE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createSalesOrderInternal =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/StockOut/CreateStockOutOrder`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      // await Axios(config)
      //   .then(function (response) {
      //     // console.log(response);
      //     // console.log(response.data);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      // dispatch({ type: SALESORDERINTERNAL_CREATE_SUCCESS, payload: data });

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_CREATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_CREATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSalesOrderInternal = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: SALESORDERINTERNAL_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockOut/StockOutOrderGridData/${order}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({ type: SALESORDERINTERNAL_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: SALESORDERINTERNAL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSalesOrderInternalWithCurrentStatus =
  (status_id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockOut/SoStatusDataGrid?currentStatus=${
          status_id ?? 0
        }`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      dispatch({
        type: SALESORDERINTERNAL_LIST_SUCCESS,
        payload: data.dataObj,
      });
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //Confusing
  export const listSalesOrderPendingInternal =
  (order, datas) => async (dispatch, getState) => {
    try {
      console.log("datas");
      console.log(datas);
      dispatch({ type: SALESORDERINTERNAL_FILTER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockOut/SoAllPendingGrid?startDate=${datas.startDate}&endDate=${datas.endDate}&customerId=${datas.customerId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      console.log("data");
      console.log(data);
      dispatch({
        type: SALESORDERINTERNAL_FILTER_LIST_SUCCESS,
        payload: data.dataObj,
      });
      return data;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_FILTER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//rakib
export const resetListSalesOrderInternalWithCurrentStatus =
  () => async (dispatch, getState) => {
    dispatch({ type: SALESORDERINTERNAL_LIST_REQUEST });

    setTimeout(() => {
      dispatch({
        type: SALESORDERINTERNAL_LIST_SUCCESS,
        payload: [],
      });
    }, 1000);
  };

export const listSalesOrderFilterInternal =
  (order, datas) => async (dispatch, getState) => {
    try {
      console.log("datas");
      console.log(datas);
      dispatch({ type: SALESORDERINTERNAL_FILTER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockOut/StockOutPrintReportGridData?currentStatus=${order}&startDate=${datas.startDate}&endDate=${datas.endDate}&customerId=${datas.customerId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      console.log("data");
      console.log(data);
      dispatch({
        type: SALESORDERINTERNAL_FILTER_LIST_SUCCESS,
        payload: data.dataObj,
      });
      return data;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_FILTER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//shahed issue with Admin Edit Grid

export const listSalesOrderAdminEdit =
  (order, datas) => async (dispatch, getState) => {
    try {
      console.log("datas");
      console.log(datas);
      dispatch({ type: SALESORDERINTERNAL_FILTER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockOut/StockOutAdminEidtGridData?startDate=${datas.startDate}&endDate=${datas.endDate}&customerId=${datas.customerId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      console.log("data");
      console.log(data);
      dispatch({
        type: SALESORDERINTERNAL_FILTER_LIST_SUCCESS,
        payload: data.dataObj,
      });
      return data;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_FILTER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//



export const createTallySales = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TALLYSALES_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/TallySales/AddTallySales`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    // await Axios(config)
    //   .then(function (response) {
    //     // console.log(response);
    //     // console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // dispatch({ type: TALLYSALES_CREATE_SUCCESS, payload: data });

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: TALLYSALES_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: TALLYSALES_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    // console.log("finalGotData");
    // console.log(finalGotData);
    // return finalGotData;
  } catch (error) {
    dispatch({
      type: TALLYSALES_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSalesOrderInternalDetails =
  (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: SALESORDERINTERNAL_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/StockOut/GetStockOutOrderDetails/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);

      // console.log("data[0]");
      // console.log(data[0]);

      dispatch({
        type: SALESORDERINTERNAL_DETAILS_SUCCESS,
        payload: data.dataObj,
      });
      return data.dataObj;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listBatchAndMappedItemBasedOnProduct =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockOut/GetMappedItemsAndBatchesByProduct/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      var { data } = await Axios(config);
      // console.log("data");
      // console.log(data);
      const finalBatch = data.dataObj.data.batchList.map((single) => {
        return {
          id: single.key,
          label: single.value,
        };
      });
      const finalMappedItem = data.dataObj.data.mappedItems.map((single) => {
        return {
          id: single.key,
          label: single.value,
        };
      });

      var final = {
        finalBatch: finalBatch,
        finalMappedItem: finalMappedItem,
      };
      // console.log("final");
      // console.log(final);
      dispatch({
        type: BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_SUCCESS,
        payload: final,
      });
      return final;
    } catch (error) {
      dispatch({
        type: BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const escalateSalesOrderInternal =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_ESCALATION_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/StockOut/SubmitForMapItemAndBatch`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_ESCALATION_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_ESCALATION_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_ESCALATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const packageSalesOrderInternal =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_PACKAGE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/StockOut/SubmitForPackaging`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      // await Axios(config)
      //   .then(function (response) {
      //     // console.log(response);
      //     // console.log(response.data);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      // dispatch({ type: SALESORDERINTERNAL_CREATE_SUCCESS, payload: data });

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_PACKAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const packageDoneSalesOrderInternal =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_PACKAGE_DONE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "POST",

        url: `${API_URL}/StockOut/SubmitPackaging`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      // await Axios(config)
      //   .then(function (response) {
      //     // console.log(response);
      //     // console.log(response.data);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      // dispatch({ type: SALESORDERINTERNAL_CREATE_SUCCESS, payload: data });

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_DONE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_DONE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_PACKAGE_DONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPackagingDoneSalesOrderInternalDetails =
  (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/StockOut/GetPackagingInfo/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);

      // console.log("data[0]");
      // console.log(data[0]);

      dispatch({
        type: SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_SUCCESS,
        payload: data.dataObj,
      });
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateSalesOrderInternal =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/StockOut/UpdateStockOutOrder`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      // await Axios(config)
      //   .then(function (response) {
      //     // console.log(response);
      //     // console.log(response.data);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      // dispatch({ type: SALESORDERINTERNAL_CREATE_SUCCESS, payload: data });

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_UPDATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_UPDATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//Admin Edit
export const updateSalesOrderAdminEdit =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/StockOut/AdminUpdateStockOutOrder`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };


      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_UPDATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_UPDATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
   
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const packageSalesOrderAdminInvoiceUpdate =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_PACKAGE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/StockOut/AdminSubmitForPackaging`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

    
      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_PACKAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
//Admin Edit


export const packageDoneEditSalesOrderInternal =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_PACKAGE_DONE_EDIT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "PUT",

        url: `${API_URL}/StockOut/UpdatePackaging`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      // await Axios(config)
      //   .then(function (response) {
      //     // console.log(response);
      //     // console.log(response.data);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      // dispatch({ type: SALESORDERINTERNAL_CREATE_SUCCESS, payload: data });

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_DONE_EDIT_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SALESORDERINTERNAL_PACKAGE_DONE_EDIT_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      // return finalGotData;
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_PACKAGE_DONE_EDIT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPriceSummaryBasedOnProductAndCustomer =
  (dataF) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRICESUMMARY_CUSTOMER_PRODUCT_LIST_REQUEST });
      console.log("dataF");
      console.log(dataF);
      console.log("data-end");
      const {
        userLogin: { userInfo },
      } = getState();

      // https://octopi.algologix.com.bd/api/Marketing/GetBindVisitByUsers?userSysId=${decodedata.UserSystemId}

      var config = {
        method: "get",

        url: `${API_URL}/TallySales/GetPriceSummary?customerId=${dataF.customerId}&prodcutId=${dataF.prodcutId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // console.log("data");
      const { data } = await Axios(config);
      console.log("data");
      console.log(data);
      dispatch({
        type: PRICESUMMARY_CUSTOMER_PRODUCT_LIST_SUCCESS,
        payload: data.dataObj,
      });
    } catch (error) {
      dispatch({
        type: PRICESUMMARY_CUSTOMER_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPackTypesForInvoice = () => async (dispatch, getState) => {
  try {
    // console.log("hiii");
    dispatch({ type: INVOICE_PACK_TYPE_LIST_REQUEST });
    // dispatch({ type: INVOICE_DUPLI_PACK_TYPE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Product/GetPackType`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    console.log("data");
    console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.packId,
        label: single.packTypeName,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: INVOICE_PACK_TYPE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
    // dispatch({
    //   type: INVOICE_DUPLI_PACK_TYPE_LIST_SUCCESS,
    //   payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    // });
  } catch (error) {
    dispatch({
      type: INVOICE_PACK_TYPE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    // dispatch({
    //   type: INVOICE_DUPLI_PACK_TYPE_LIST_FAIL,
    //   payload:
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message,
    // });
  }
};

export const listOrderFromInvoice = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_FROM_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetOrderFromInKeyValue`,
    };
    const { data } = await Axios(config);

    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: ORDER_FROM_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: ORDER_FROM_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPendingInvoice = (status) => async (dispatch, getState) => {
  try {
    dispatch({ type: PENDING_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetLifecyclesInKeyValue/stock_out/${status}`,
    };
    const { data } = await Axios(config);

    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: PENDING_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: PENDING_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listGetJobSearchSingle = (datas) => async (dispatch, getState) => {
  try {
    // console.log("datas");
    // console.log(datas);
    dispatch({ type: JOB_LIST_SINGLE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockOut/GetJobBySearch/${datas}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    console.log("data");
    console.log(data);
    dispatch({
      type: JOB_LIST_SINGLE_SUCCESS,
      payload: data.dataObj,
    });
    return data;
  } catch (error) {
    dispatch({
      type: JOB_LIST_SINGLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//admin Edit

export const listSalesOrderInternalPendingPrice =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: SALESORDERINTERNAL_PENDING_PRICE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockOut/GetSoPendingPriceGrid`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);

      dispatch({
        type: SALESORDERINTERNAL_PENDING_PRICE_LIST_SUCCESS,
        payload: data.dataObj,
      });
    } catch (error) {
      dispatch({
        type: SALESORDERINTERNAL_PENDING_PRICE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const updateStockout = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCKOUT_PENDING_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/StockOut/UpdateStockOutAssociateCurrentStatus/${data.soSystemId}/${data.associateCurrenStatus}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: "",
    };

    // await Axios(config)
    //   .then(function (response) {
    //     // console.log(response);
    //     // console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // dispatch({ type: SALESORDERINTERNAL_CREATE_SUCCESS, payload: data });  
    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: STOCKOUT_PENDING_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: STOCKOUT_PENDING_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    // console.log("finalGotData");
    // console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: STOCKOUT_PENDING_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//sticker
// export const listUndefinePackingStrikcerAction22 = () => async (dispatch, getState) => {
//   try {
//     dispatch({ type: LIST_UNDEFINE_PACKING_STICKER_REQUEST });

//     const {
//       userLogin: { userInfo },
//     } = getState();

//     var config = {
//       method: "get",
//       url: `${API_URL}/StockOut/StickerStockOutOrderGridData`,
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };
//     const { data } = await Axios(config);
    
//     dispatch({ type: LIST_UNDEFINE_PACKING_STICKER_SUCCESS, payload: data.dataObj });
//   } catch (error) {
//     dispatch({
//       type: LIST_UNDEFINE_PACKING_STICKER_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

// export const listUndefinePackingStrikcerAction = (order) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: LIST_UNDEFINE_PACKING_STICKER_REQUEST });

//     const {
//       userLogin: { userInfo },
//     } = getState();

//     var config = {
//       method: "get",
//       //url: `${API_URL}/StockOut/StockOutOrderGridData/${order}`,
//       url: `${API_URL}/StockOut/StickerStockOutOrderGridData`,
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };
//     const { data } = await Axios(config);
//     // console.log("data");
//     // console.log(data);
//     dispatch({ type: LIST_UNDEFINE_PACKING_STICKER_SUCCESS, payload: data.dataObj });
//   } catch (error) {
//     dispatch({
//       type: LIST_UNDEFINE_PACKING_STICKER_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

// export const listDefineEditPackingStrikcerAction = () => async (dispatch, getState) => {
//   try {
//     dispatch({ type: LIST_DEFINE_EDIT_PACKING_STICKER_REQUEST });

//     const {
//       userLogin: { userInfo },
//     } = getState();

//     var config = {
//       method: "get",
//       url: `${API_URL}/StockOut/StickerStockOutEditListGridData`,
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };
//     const { data } = await Axios(config);
    
//     dispatch({ type: LIST_DEFINE_EDIT_PACKING_STICKER_SUCCESS, payload: data.dataObj[0] });
//   } catch (error) {
//     dispatch({
//       type: LIST_DEFINE_EDIT_PACKING_STICKER_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

export const listSalesOrderListForPackingAndEditAction = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: LIST_ORDER_FOR_PACKING_EIDT_STICKER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockOut/StickerStockOutOrderGridData/${order}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({ type: LIST_ORDER_FOR_PACKING_EIDT_STICKER_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: LIST_ORDER_FOR_PACKING_EIDT_STICKER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listDetailsEditPackingStickerAction = (id) => async (dispatch, getState) => {

  try {
    dispatch({ type: DETAILS_EDIT_PACKING_STICKER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/StockOut/GetStickerPackagingDetailsForEdit/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    //dispatch({ type: FUNCTION_LIST_DETAILS_SUCCESS, payload: data.dataObj[0] });
    dispatch({ type: DETAILS_EDIT_PACKING_STICKER_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DETAILS_EDIT_PACKING_STICKER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPackingStickerAction = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_PACKING_STICKER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/StockOut/CreateStickerPackaging`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: CREATE_PACKING_STICKER_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: CREATE_PACKING_STICKER_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
  
    return finalGotData;
  } catch (error) {
    dispatch({
      type: CREATE_PACKING_STICKER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePackingStickerAction = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_PACKING_STICKER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/StockOut/UpdateStickerPackaging`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: UPDATE_PACKING_STICKER_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_PACKING_STICKER_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: UPDATE_PACKING_STICKER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
//Asif

export const listLatesBatchAndMapDetails =
  (customerId, productId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LATEST_BATCH_MAP_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/StockOut/GetLastMappAndBatchByCustomerAndProduct/${customerId}/${productId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);
      console.log("data");
      console.log(data);
      dispatch({
        type: LATEST_BATCH_MAP_DETAILS_SUCCESS,
        payload: data.dataObj,
      });

      return data;
    } catch (error) {
      dispatch({
        type: LATEST_BATCH_MAP_DETAILS_FAIL,
        payload:
          error.response && error.response.data.errMsg
            ? error.response.data.errMsg
            : error.message,
      });
      return error.response.data;
    }
  };
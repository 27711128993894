import React from "react";
import { FormControl, InputAdornment, TextField } from "@mui/material";
const Input = (props) => {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    extraError = null,
    error = null,
    touched = null,
    ...other
  } = props;
  return (
    // <FormControl fullWidth>
    <TextField
      variant={variant || "outlined"}
      label={label}
      size="small"
      name={name}
      value={value !== undefined ? value : ""}
      onChange={onChange}
      {...other}
      {...(error && touched && { error: true, helperText: error })}
      {...(extraError && { error: true, helperText: extraError })}
    />
    // </FormControl>
  );
};

export default Input;

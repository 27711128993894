  import React, { useCallback, useState } from "react";
  import { useSelector, useDispatch } from "react-redux";
  import { v4 as uuidv4 } from "uuid";
  import { useFormik } from "formik";
  import {
    Grid,
    Paper,
    Button as MuiButton,
  } from "@mui/material";

  import { Link, withRouter } from "react-router-dom";
  import { makeStyles } from "@mui/styles";
  import { useTheme } from "@mui/material/styles";
  import CircularProgress from "@mui/material/CircularProgress";
  import ReplayIcon from "@mui/icons-material/Replay";
  import PublishIcon from "@mui/icons-material/Publish";
  import PersonAddIcon from "@mui/icons-material/PersonAdd";
  import DeleteIcon from "@mui/icons-material/Delete";
  import ButtonGroup from "@mui/material/ButtonGroup";
  import Menu from "../../core/Menu";
  import PageHeader from "../../core/PageHeader";
  import { useForm, Form } from "../../core/useForm";
  import Input from "../../core/controls/Input";
  import Button from "../../core/controls/Button";
  import { LocationSchema } from "../../schemas";
  import { useEffect } from "react";
  import Notification from "../../core/Notification";
  import "../Customers/Customer.css";
  import {
  deleteLocationAction,
  listLocationDetailsAction,
  updateLocationAction,
  } from "../../redux/actions/locationAction";
  import ConfirmDialog from "../../core/ConfirmDialog";

  // Define and use MUI styles for component styling

  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
    adornmentText: {
      "& .MuiTypography-root": {
        color: "#f3b33d",
        fontWeight: "bolder",
        fontSize: "1.5rem",
      },
    },
    submitButtonGroup: {
      backgroundColor: "#192A53",
      color: "#fff",
      margin: useTheme().spacing(1),
      "& .MuiButtonBase-root": {
        textTransform: "none",
        color: "#fff",
        border: "2px solid #5C636A",
      },
      "&:hover": {
        backgroundColor: " #192A53",
      },
    },
  }));
// Initial values for the form
const initialValues = {
  locationId: "",
  locationName: "",
};

// EditLocation component
const EditLocation = ({ match, history }) => {
  // Extract location ID from the URL parameters
  const locId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();

  // State for notifications and confirm dialog
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // Redux state for location details and update status
  const listLocationDetailsState = useSelector((state) => state.listLocationDetailsState);
  const { loading: loadinglistDetails, error: errorlistDetails, locations } = listLocationDetailsState;

  const updateLocationState = useSelector((state) => state.updateLocationState);
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = updateLocationState;

  // Redux state for user login information
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Formik setup for form handling and validation
  const formik = useFormik({
    initialValues,
    validationSchema: LocationSchema,
    onSubmit: (values, action) => {
      // Dispatch the updateLocationAction and handle the result
      dispatch(updateLocationAction({
        locationId: values.locationId,
        locationName: values.locationName,
      })).then((result) => {
        if (result?.errMsg) {
          // console.log(result);
          if (result.errMsg == "Location Already Exist") {
            action.setFieldError("errorClientName", result.errMsg);
          } 
          action.setSubmitting(false);
        } else {
          // Navigate to the location list on success
          history.push("/locationlist");
        }
      });
    },
  });

  const changeValues = () => {
    if (locations && Object.keys(locations).length !== 0) {
      formik.setValues({
        locationId: locations.locationId || "",
        locationName: locations.locationName || "",
      });
    }
  };
  // Fetch location details on component mount
  useEffect(() => {
    if (userInfo) {
      dispatch(listLocationDetailsAction(locId));
    } else {
      // Redirect to signin if user is not logged in
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/location/${locId}/edit` } },
      };
      history.push(location);
    }
  }, [locId, dispatch,history, userInfo]);

  // Set form values based on location details
  useEffect(() => {
    changeValues();
  }, [locations]);

  // Handle delete action
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    // Dispatch deleteLocationAction and navigate to location list
    dispatch(deleteLocationAction(id));
    history.push("/locationlist");
  };

  return (
    <div>
      {/* Menu component */}
      <Menu />
      
      {/* PageHeader component */}
      <PageHeader
        icon={<PersonAddIcon />}
        title="Locations"
        subtitle="Add a Location"
      />
      
      {/* Main content area */}
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            {/* Input field for Location Name */}
            <Grid item xs={12} md={6}>
            <Input
              label="Location Name"
              name="locationName"
              error={formik.errors.locationName}
              extraError={formik.errors.errorClientName}  // Update this line
              touched={formik.touched.locationName}
              value={formik.values.locationName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Empty grid item */}
            </Grid>
            
            {/* ButtonGroup for Update and Reset */}
            <Grid item xs={12} md={6}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Update
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                  }}
                />
              </ButtonGroup>
              
              {/* Back and Delete buttons */}
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/locationlist",
                    state: {},
                  };
                  history.push(location);
                }}
              />
              <MuiButton
                onClick={() => {
                  // onDelete(params.row.employeeId);
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure that you want to delete this data?",
                    subTitle: "Once it is deleted, you can't restore!",
                    onConfirm: () => {
                      onDelete(match.params.id);
                    },
                  });
                }}
                color="error"
                startIcon={
                  loadinglistDetails ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : (
                    <DeleteIcon />
                  )
                }
                size="large"
              >
                Delete
              </MuiButton>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      
      {/* ConfirmDialog component */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      
      {/* Notification component */}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

// Export component with router higher-order component
export default withRouter(EditLocation);
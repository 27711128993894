import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Decoder from "jwt-decode";
import {
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@mui/material";

import { Box, Typography, IconButton, Tooltip } from "@mui/material";

import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import AddInhouseEmployee from "./AddInhouseEmployee";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIhouseEmployeedupli from "./AddIhouseEmployeedupli";
import useTable from "../../core/useTable";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

import { Link } from "react-router-dom";
import ActionButton from "../../core/controls/ActionButton";
import {
  deactivateEmployee,
  deleteEmployee,
  listEmployees,
} from "../../redux/actions/employeeActions";
import { emprecords } from "../../DummyData";
import Actions from "../../core/Actions";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";

import {
  EMPLOYEE_CREATE_RESET,
  EMPLOYEE_DEACTIVATE_RESET,
  EMPLOYEE_DELETE_RESET,
  EMPLOYEE_UPDATE_RESET,
} from "../../redux/constants/employeeConstants";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "../../core/controls/Button";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";

const useStyles = makeStyles(() => ({
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#0288d1",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "170px !important",
      maxWidth: "170px !important",
    },

    // "& .MuiDataGrid-columnHeaderTitle": {
    //   minWidth: "140px !important",
    //   maxWidth: "140px !important",
    //   textAlign: "center",
    // },

    "& .MuiDataGrid-cell": {
      minWidth: "170px !important",
      maxWidth: "170px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#0288d1",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='employeeId'], .MuiDataGrid-cell[data-field='employeeId']":
      {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#0288d1",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  // grid: {
  //   flexDirection: "column-reverse",
  // },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },

  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },

  actionsExceptAdmin: {
    display: "flex",
    justifyContent: "flex-start !important",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end !important",
      width: "420px",
    },
  },
}));

const InhouseEmployeesdupli = ({ history }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  // console.log("classes");
  // console.log(classes);

  const employeeList = useSelector((state) => state.employeeList);
  const { loading, error, employees } = employeeList;

  const employeeCreate = useSelector((state) => state.employeeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    employee: createdProduct,
  } = employeeCreate;

  // console.log("employees");
  // console.log(employees);

  const employeeDeactivate = useSelector((state) => state.employeeDeactivate);
  const {
    loading: loadingDeactivate,
    error: errorDeactivate,
    success: successDeactivate,
  } = employeeDeactivate;

  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = employeeUpdate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const decodedata =
    userInfo !== null && userInfo !== undefined
      ? Decoder(userInfo.token)
      : null;

  console.log("decodedata");
  console.log(decodedata.UserRoleId);

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowCode, setRowCode] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [flag, setFlag] = useState(false);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listEmployees());
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: EMPLOYEE_UPDATE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: EMPLOYEE_CREATE_RESET });
      }

      if (successDeactivate) {
        setNotify({
          isOpen: true,
          message: "Deactivated Successfully",
          type: "error",
        });

        dispatch({ type: EMPLOYEE_DEACTIVATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/inhouseemployees" } },
      };
      history.push(location);
    }

    // console.log(successUpdate);
  }, [dispatch, classes, history, userInfo, successDeactivate, successUpdate]);

  const handleEvent = (params) => {
    history.push(`/employee/${params.row.employeeId}/edit`);

    // console.log(params.row);
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
        employees.find((row) => row.employeeId === id)
      );
      setRowId(selectedRowsData[0].employeeId);
      setRowCode(selectedRowsData[0].employeeCode);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deactivateEmployee(id));
    setNotify({
      isOpen: true,
      message: "Deactivated Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };
  // console.log("decodedata.UserRoleId");
  // console.log(decodedata.UserRoleId);
  const columns = [
    {
      field: "employeeId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "employeeCode",
      headerName: "Employee Code",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fullName",
      headerName: "Fullname",
      width: 200,
      valueGetter: (params) =>
        params.row.empFirstName + " " + params.row.empLastName,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "empFirstName",
    //   headerName: "Firstname",
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "empLastName",
    //   headerName: "Lastname",
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "departmentName",
      headerName: "Department",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "designation",
      headerName: "Designation",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "empPresentAddress",
      headerName: "Present Address",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "empParmanentAddress",
      headerName: "Permanent Address",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "dateOfJoining",
      headerName: "Hired Date",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "empContactNo",
      headerName: "Contact #",
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "guardianContactNo",
    //   headerName: "Guardian Contact #",
    //   headerAlign: "center",
    //   align: "center",
    // },

    // {
    //   field: "channelName",
    //   headerName: "Location",
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "actions",
    //   headerName: "Action",
    //   type: "actions",
    //   renderCell: (params) => (
    //     <Box>
    //       <Tooltip title="Edit Employee">
    //         <IconButton>
    //           <ActionButton color="primary">
    //             <EditOutlinedIcon fontSize="small" />
    //           </ActionButton>
    //         </IconButton>
    //       </Tooltip>

    //       <Tooltip title="Delete Employee">
    //         <IconButton
    //           onClick={() => {
    //             // onDelete(params.row.employeeId);
    //             setConfirmDialog({
    //               isOpen: true,
    //               title: "Are you sure that you want to delete this data?",
    //               subTitle: "Once it is deleted, you can't restore!",
    //               onConfirm: () => {
    //                 onDelete(params.row.employeeId);
    //               },
    //             });
    //           }}
    //         >
    //           <ActionButton color="error">
    //             <CloseOutlinedIcon fontSize="small" />
    //           </ActionButton>
    //         </IconButton>
    //       </Tooltip>
    //     </Box>
    //   ),
    // },
  ];
  // useEffect(() => {}, [employeeList]);

  //   const [records, setRecords] = useState(emprecords);
  // const [filterFn, setFilterFn] = useState({
  //   fn: (items) => {
  //     return items;
  //   },
  // });

  //   const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
  //     useTable(employees, headCells, filterFn);

  // const handleSearch = (e) => {
  //   let target = e.target;
  //   setFilterFn({
  //     fn: (items) => {
  //       if (target.value == "") return items;
  //       else return items.filter((x) => x.empName.includes(target.value));
  //     },
  //   });
  // };

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Employees"
        subtitle="See the total Employee List"
      />
      {loadingDeactivate && <>loading..</>}
      {error ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#0288D1" />
          {/* <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#6C4AB6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          {parseInt(decodedata.UserRoleId) === 1 ||
          parseInt(decodedata.UserRoleId) === 5 ? (
            <Toolbar className={classes.toolBar}>
              <Button
                text="Add New"
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ visibility: "hidden" }}
                onClick={() => {
                  history.push("/addemployee");
                  // setOpenPopup(true);
                  // setNotify({
                  //   isOpen: true,
                  //   message: "Submitted Successfully",
                  //   type: "success",
                  // });
                }}
              />
              <Box className={classes.actions}>
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  text="Add New"
                  color="primary"
                  onClick={() => {
                    history.push("/addemployee");
                    // setOpenPopup(true);
                    // setNotify({
                    //   isOpen: true,
                    //   message: "Submitted Successfully",
                    //   type: "success",
                    // });
                  }}
                />
                <Button
                  startIcon={<EditOutlinedIcon fontSize="small" />}
                  text="Edit"
                  color="secondary"
                  onClick={() => {
                    if (flag === true || rowId === null) {
                      setOpenPopup({
                        ...openPopup,
                        title: "SELECTION ISSUE!!!",
                        subTitle:
                          "Minimum and Maximum one row have to be slected!",
                        isOpen: true,
                      });
                    }
                    if (rowClicked === true) {
                      history.push(`/employee/${rowId}/edit`);
                    }
                  }}
                />
                <Button
                  startIcon={<CloseOutlinedIcon fontSize="small" />}
                  text="DEACTIVATE"
                  color="error"
                  onClick={() => {
                    // console.log(rowId);

                    if (flag === true || rowCode === null) {
                      setOpenPopup({
                        ...openPopup,
                        title: "SELECTION ISSUE!!!",
                        subTitle:
                          "Minimum and Maximum one row have to be slected!",
                        isOpen: true,
                      });
                    }
                    if (rowClicked === true) {
                      setConfirmDialog({
                        isOpen: true,
                        title:
                          "Are you sure that you want to deactivate this employee?",
                        subTitle: "Once it is deactivated, you can't activate!",
                        onConfirm: () => {
                          onDelete(rowCode);
                        },
                      });
                    }
                  }}
                />
              </Box>
              {/* <Input
              label="Search Employees"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.searchInput}
              // onChange={handleSearch}
            /> */}
            </Toolbar>
          ) : (
            <Toolbar className={classes.toolBar}>
              <Button
                text="Add New"
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ visibility: "hidden" }}
                onClick={() => {
                  history.push("/addemployee");
                  // setOpenPopup(true);
                  // setNotify({
                  //   isOpen: true,
                  //   message: "Submitted Successfully",
                  //   type: "success",
                  // });
                }}
              />
              <div className={classes.actionsExceptAdmin}>
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  text="Add New"
                  color="primary"
                  onClick={() => {
                    history.push("/addemployee");
                    // setOpenPopup(true);
                    // setNotify({
                    //   isOpen: true,
                    //   message: "Submitted Successfully",
                    //   type: "success",
                    // });
                  }}
                />
                <Button
                  startIcon={<EditOutlinedIcon fontSize="small" />}
                  text="Edit"
                  color="secondary"
                  onClick={() => {
                    if (flag === true || rowId === null) {
                      setOpenPopup({
                        ...openPopup,
                        title: "SELECTION ISSUE!!!",
                        subTitle:
                          "Minimum and Maximum one row have to be slected!",
                        isOpen: true,
                      });
                    }
                    if (rowClicked === true) {
                      history.push(`/employee/${rowId}/edit`);
                    }
                  }}
                />
              </div>
            </Toolbar>
          )}

          {/* <Paper className={classes.pageContent}> */}
          {/* <AddInhouseEmployee /> */}
          {/* <AddIhouseEmployeedupli /> */}
          {/* <Box
            m="10px"
            sx={{
              height: 400,
              //   width: "1246px",
              //   margin: "0 auto",
            }}
          > */}
          {/* style={{ overflowX: "scroll !important" }} */}
          {/* <Box
              m="10px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                  overflowX: "auto",
                },
                "& .MuiDataGrid-cell": {
                  minWidth: "140px !important",
                  maxWidth: "140px !important",
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: "#94e2cd",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#A4A9FC",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeader": {
                  minWidth: "140px !important",
                  maxWidth: "140px !important",
                },

                "& .MuiDataGrid-virtualScroller": {
                  overflowX: "auto",
                  backgroundColor: "#F9F9F9",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#fff",
                },
                "& .MuiCheckbox-root": {
                  color: "#b7ebde !important",
                },
              }}
            > */}
          <div className={classes.colHeader}>
            <DataGrid
              className={classes.grid}
              columnBuffer={2}
              columnThreshold={2}
              disableVirtualization
              checkboxSelection
              rows={employees}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.employeeId + "-" + generateRandom()}
              onRowClick={handleEvent}
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </div>

          {/* </Box>
          </Box> */}
          {/* </Paper> */}
          {/* <TblPagination /> */}
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          {/* <Popup
            title="Employee Form"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <AddIhouseEmployeedupli />
          </Popup> */}
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </div>
      )}
    </div>
  );
};

export default InhouseEmployeesdupli;

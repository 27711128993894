import React, { useCallback, useState } from "react";
import {
  //   Autocomplete,
  GoogleMap,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import { v4 } from "uuid";
import { useFormik } from "formik";
import Axios from "axios";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  createFilterOptions,
  Fab,
  Popover,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import BoyIcon from "@mui/icons-material/Boy";
import NavigationIcon from "@mui/icons-material/Navigation";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "./Map.css";
import InfoWindowCore from "./InfoWindowCore";
import Input from "./controls/Input";
import Datepicker from "./controls/Datepicker";
import { Form } from "./useForm";
import { signUpSchema } from "../schemas";
import Button from "./controls/Button";
import { useMediaQuery } from "react-responsive";

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  employee: {
    id: "",
    label: "",
  },
};
const MarketingMap = (props) => {
  const {
    isLoaded,
    customersinMap,
    userInfo,
    employees,
    loadingEmployee,
    openPopup,
    setOpenPopup,
    API_URL,
  } = props;
  console.log("customersinMap");
  console.log(customersinMap);
  const [selected, setSelected] = useState("");
  const [showFilter, setShowFilter] = useState(true);
  const [searchedData, setSearchedData] = useState([]);
  const [choosedCenter, setChoosedCenter] = useState({
    lat: 23.797963,
    lng: 90.401263,
  });
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      var config = {
        method: "get",
        url: `${API_URL}/Marketing/GetMktLocComp?employeeId=${values.employee.id}&startDate=${values.from}&endDate=${values.to}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: "",
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          return error.response.data;
        });

      if (finalGotData?.errMsg) {
      } else {
        if (finalGotData.dataObj.length > 0) {
          console.log("finalGotData");
          console.log(finalGotData);
          var organizedData = finalGotData.dataObj.filter((single) => {
            return (
              single.empFirstName !== null &&
              single.empLastName !== null &&
              single.userUniqueCode !== null &&
              single.visitLat !== null &&
              single.visitLong !== null
            );
          });

          var organizedData2 = organizedData.map((single) => {
            return {
              name:
                single.empFirstName +
                " " +
                single.empLastName +
                "-" +
                single.userUniqueCode,
              location: {
                lat: single.visitLat,
                lng: single.visitLong,
              },
            };
          });
          setSearchedData(organizedData2);
          // setDownloadedData(finalGotData.dataObj);
          // setTimeout(() => {
          //   csvDownloadRef.current.link.click();
          // }, 500);
        } else {
          action.setSubmitting(false);
          setOpenPopup({
            ...openPopup,
            title: "ZERO DATA!!!",
            subTitle: "No Data found with this specifications!",
            isOpen: true,
          });
        }
      }
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const containerStyle = {
    width: "100%",
    height: "74vh",
  };

  const center = {
    lat: 23.797963,
    lng: 90.401263,
  };

  const markers = [
    {
      name: "location-1",
      location: {
        lat: 23.7375,
        lng: 90.4083,
      },
    },
    {
      name: "location-2",
      location: {
        lat: 23.7182,
        lng: 90.3866,
      },
    },
    {
      name: "location-3",
      location: {
        lat: 23.7373,
        lng: 90.3972,
      },
    },
    {
      name: "location-4",
      location: {
        lat: 23.7661,
        lng: 90.4299,
      },
    },
    {
      name: "location-5",
      location: {
        lat: 23.7685,
        lng: 90.4255,
      },
    },
  ];
  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
    // GetEmployeeDetails(e, v);
  });

  const employeeOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  return isLoaded ? (
    <>
      <div
        className={props.classes.sectionContainer}
        style={
          isMobile && !showFilter
            ? {
                background: "transparent",
                outline: "none",
                border: "none",
                boxShadow: "none",
                paddingLeft: "0px",
              }
            : {}
        }
      >
        {!isMobile ? (
          <Form onSubmit={formik.handleSubmit}>
            {/* <Grid container> */}
            <Grid item xs={12} md={6}>
              <Datepicker
                label="From"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
                style={{ width: "100%", backgroundColor: "white" }}
              />
              <Datepicker
                label="To"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
                style={{ width: "100%", backgroundColor: "white" }}
              />

              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingEmployee ? true : false}
                id="combo-box-demo1"
                isOptionEqualToValue={employeeOptionCheck}
                onChange={employeeChange}
                value={formik.values.employee}
                options={employees !== undefined ? employees : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Employee List"
                    name="employee"
                    error={
                      formik.errors.employee !== undefined
                        ? formik.errors.employee.id
                        : ""
                    }
                    touched={
                      formik.touched.employee !== undefined
                        ? formik.touched.employee
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%", backgroundColor: "white" }}
                  />
                )}
                freeSolo={true}
                // noOptionsText={
                //   showOptionMessage ? "No Option" : "continue typing.."
                // }
              />

              <Button
                text="Search"
                color="primary"
                style={{ marginLeft: "9px" }}
                type="submit"
                onClick={() => {
                  // setConfirmDialog({
                  //   ...confirmDialog,
                  //   isOpen: false,
                  // });
                }}
              />

              {/* </Grid> */}
            </Grid>
          </Form>
        ) : (
          ""
        )}
        {isMobile ? (
          showFilter ? (
            <Form onSubmit={formik.handleSubmit}>
              {/* <Grid container> */}
              <Grid item xs={12} md={6}>
                <Datepicker
                  label="From"
                  name="from"
                  value={formik.values.from}
                  onChange={formik.handleChange}
                  style={{ width: "100%", backgroundColor: "white" }}
                />
                <Datepicker
                  label="To"
                  name="to"
                  value={formik.values.to}
                  onChange={formik.handleChange}
                  style={{ width: "100%", backgroundColor: "white" }}
                />

                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingEmployee ? true : false}
                  id="combo-box-demo1"
                  isOptionEqualToValue={employeeOptionCheck}
                  onChange={employeeChange}
                  value={formik.values.employee}
                  options={employees !== undefined ? employees : []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label="Employee List"
                      name="employee"
                      error={
                        formik.errors.employee !== undefined
                          ? formik.errors.employee.id
                          : ""
                      }
                      touched={
                        formik.touched.employee !== undefined
                          ? formik.touched.employee
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", backgroundColor: "white" }}
                    />
                  )}
                  freeSolo={true}
                  // noOptionsText={
                  //   showOptionMessage ? "No Option" : "continue typing.."
                  // }
                />

                <Button
                  text="Search"
                  color="primary"
                  style={{ marginLeft: "9px" }}
                  type="submit"
                  onClick={() => {
                    // setConfirmDialog({
                    //   ...confirmDialog,
                    //   isOpen: false,
                    // });
                  }}
                />
                <Fab
                  title="hide filter box"
                  sx={{ ml: 1 }}
                  color="error"
                  size="small"
                  aria-label="hide"
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  <VisibilityOffIcon />
                </Fab>
                {/* </Grid> */}
              </Grid>
            </Form>
          ) : (
            <>
              <Fab
                title="show filter box"
                size="small"
                aria-label="hide"
                onClick={() => {
                  setShowFilter(true);
                }}
                // onClick={handleClick}
                aria-describedby={id}
                sx={{
                  background: "rgba(0, 0, 0, 0.6)",
                  color: "white",
                }}
              >
                <VisibilityIcon />
              </Fab>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                The content of the Popover.
              </Popover>
            </>
          )
        ) : (
          ""
        )}
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={choosedCenter}
        zoom={10}
        options={{
          // mapTypeControl: false,
          // mapTypeId: "satellite",
          // mapTypeId: "terrain",
          // mapTypeId: "hybrid",
          // fullscreenControl: false,
          // streetViewControl: false,
          // zoomControl: false,
          // disableDefaultUI: false,
          // draggableCursor: false,
          // draggable: false,
          navigationControl: true,
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {customersinMap.length > 0 &&
          customersinMap.map((single) => {
            return (
              <div key={single.name}>
                <Marker
                  position={single.location}
                  // options={{
                  //   icon: "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png",

                  // }}
                  // icon={{
                  //   url: "https://cdn2.iconfinder.com/data/icons/it-administrator/500/vab942_3_company_it_administrator_isometric_cartoon_woman_business-256.png",

                  //   fillOpacity: 1,
                  //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                  // }}
                  // icon={{
                  //   url: "https://cdn0.iconfinder.com/data/icons/thin-line-color-2/21/31_1-256.png",

                  //   fillOpacity: 1,
                  //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                  // }}
                  // icon={{
                  //   url: "https://cdn-icons-png.flaticon.com/512/6362/6362247.png",

                  //   fillOpacity: 1,
                  //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                  // }}
                  onClick={() => {
                    console.log("single");
                    console.log(single);
                    setSelected(single);
                    setChoosedCenter(single.location);
                  }}
                />
              </div>
            );
          })}
        {searchedData.length > 0 &&
          searchedData.map((single) => {
            {
              /* console.log("single");
          console.log(single); */
            }
            return (
              <div key={single.name + v4()}>
                <Marker
                  position={single.location}
                  // options={{
                  //   icon: "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png",

                  // }}
                  icon={{
                    url: "https://cdn2.iconfinder.com/data/icons/it-administrator/500/vab942_3_company_it_administrator_isometric_cartoon_woman_business-256.png",

                    fillOpacity: 1,
                    scaledSize: new window.google.maps.Size(50, 50), // scaled size
                  }}
                  // icon={{
                  //   url: "https://cdn0.iconfinder.com/data/icons/thin-line-color-2/21/31_1-256.png",

                  //   fillOpacity: 1,
                  //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                  // }}
                  // icon={{
                  //   url: "https://cdn-icons-png.flaticon.com/512/6362/6362247.png",

                  //   fillOpacity: 1,
                  //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                  // }}
                  onClick={() => {
                    console.log("single");
                    console.log(single);
                    setSelected(single);
                    setChoosedCenter(single.location);
                  }}
                  // options={{
                  //   icon: URL(
                  //     "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png"
                  //   ),
                  // }}
                />
              </div>
            );
          })}
        {isLoaded && selected !== "" && (
          <>
            <InfoWindowCore selected={selected} setSelected={setSelected} />
          </>
        )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default MarketingMap;

export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAIL = "GET_PRODUCT_LIST_FAIL";

export const GET_REFERENCE_LIST_REQUEST = "GET_REFERENCE_LIST_REQUEST";
export const GET_REFERENCE_LIST_SUCCESS = "GET_REFERENCE_LIST_SUCCESS";
export const GET_REFERENCE_LIST_FAIL = "GET_REFERENCE_LIST_FAIL";

export const GET_CHANNEL_LIST_REQUEST = "GET_CHANNEL_LIST_REQUEST";
export const GET_CHANNEL_LIST_SUCCESS = "GET_CHANNEL_LIST_SUCCESS";
export const GET_CHANNEL_LIST_FAIL = "GET_CHANNEL_LIST_FAIL";

export const GET_CURRENT_STOCK_REQUEST = "GET_CURRENT_STOCK_REQUEST";
export const GET_CURRENT_STOCK_SUCCESS = "GET_CURRENT_STOCK_SUCCESS";
export const GET_CURRENT_STOCK_FAIL = "GET_CURRENT_STOCK_FAIL";
export const GET_CURRENT_STOCK_RESET = "GET_CURRENT_STOCK_RESET";

export const GET_STOCK_ADJUSTMENT_REQUEST = "GET_STOCK_ADJUSTMENT_REQUEST";
export const GET_STOCK_ADJUSTMENT_SUCCESS = "GET_STOCK_ADJUSTMENT_SUCCESS";
export const GET_STOCK_ADJUSTMENT_FAIL = "GET_STOCK_ADJUSTMENT_FAIL";
export const GET_STOCK_ADJUSTMENT_RESET = "GET_STOCK_ADJUSTMENT_RESET";

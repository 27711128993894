import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Tooltip, IconButton, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import ListIcon from "@mui/icons-material/List";
import { InfinitySpin } from "react-loader-spinner";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

//import Menu from "../../core/Menu";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import Button from "../../../core/controls/Button";
import Notification from "../../../core/Notification";
import ConfirmDialog from "../../../core/ConfirmDialog";
import Popup from "../../../core/Popup";
import {
  //deleteLocationAction,
  listProductFunctionsAction,
} from "../../../redux/actions/productActions";
import {
  FUNCTION_DELETE_RESET,
} from "../../../redux/constants/productConstants";


// Styles using makeStyles
const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#007991",
      color: "#ffff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
    },

    "& .MuiDataGrid-cell": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#007991",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },
    "& .MuiDataGrid-columnHeader[data-field='customerId'], .MuiDataGrid-cell[data-field='customerId']":
      {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#007991",
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },
}));


const ListProductFunction = ({ history }) => {

        const classes = useStyles();
        const dispatch = useDispatch();

        // Selectors from Redux state
        const { loading, error, functions } = useSelector((state) => state.functionList);
        const { userInfo } = useSelector((state) => state.userLogin);
        const successDelete = useSelector((state) => state.successDelete);


        // State for various components
        const [openPopup, setOpenPopup] = useState({ isOpen: false, title: "", subTitle: "" });
        const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
        const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
        const [pageSize, setPageSize] = React.useState(25);
        const [rowId, setRowId] = useState(null);
        const [rowClicked, setRowClicked] = useState(false);
        const [flag, setFlag] = useState(false);

        // Function to generate a random string
        const generateRandom = () => {
            var length = 8,
            charset =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            return retVal;
        };  

     // useEffect to dispatch actions based on Redux state changes
        useEffect(() => {
            if (userInfo) {
            // Dispatch action to list functions
            dispatch(listProductFunctionsAction());

            // Handle success states for update, delete, and create actions
         

            if (successDelete) {
                setNotify({ isOpen: true, message: "Deleted Successfully", type: "error" });
                dispatch({ type: FUNCTION_DELETE_RESET });
            }

            
        }
        }, [dispatch, history, userInfo, successDelete]);

        // Handle row click event
        const handleEvent = (params) => {
            // history.push(`/customer/${params.row.customerId}/edit`);
        };

        // Handle row selection event
        const onRowsSelectionHandler = (ids) => {
            if (ids.length > 1) {
            setRowClicked(false);
            setFlag(true);
            } else if (ids.length < 1) {
            setRowClicked(false);
            setFlag(true);
            } else {
            // console.log(ids);
            ids = ids[0].split("-");
            var temp = ids[0];
            ids = [];
            ids.push(temp);
            const arrOfNum = ids.map((str) => {
                return Number(str);
            });
            ids = arrOfNum;
            const selectedRowsData = ids.map((id) =>
                functions.find((row) => row.functionId === id)
            );
            setRowId(selectedRowsData[0].functionId);
            setRowClicked(!rowClicked);
            setFlag(false);
            }
	    };
        
         // Handle delete action
        const onDelete = (id) => {
            setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
            });

            //dispatch(deleteLocationAction(id));
            setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "error",
            });
            setRowClicked(false);
            setFlag(false);
             // Reload the page after successful deletion
            window.location.reload();
        };

        const columns = [
            {
                field: "functionId",
                headerName: "ID",
                headerAlign: "center",
                align: "center",
              },
              {
                field: "functionName",
                headerName: "Function Name",
                cellClassName: "name-column--cell",
                headerAlign: "center",
                align: "center",
              },
        ];
    
        return (
            <div>
              <Menu />
              <PageHeader
                icon={<ListIcon />}
                title="Product Function"
                subtitle="This is the Function list"
              />
        
              {error === "Network Error" ? (
                <div className={classes.wrapperWhite}>
                  <Toolbar
                    className={classes.toolBar}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      className="m-4"
                      src={process.env.PUBLIC_URL + "/images/networkerror.png"}
                      alt=""
                      width="130"
                      height="35"
                      style={{ width: "500px", height: "auto" }}
                    />
                  </Toolbar>
                </div>
              ) : loading ? (
                <div
                  className="text-center"
                  style={{ width: "100%", display: "flex", justifyContent: "center" }}
                >
                  <InfinitySpin width="200" color="#007991" />
                
                </div>
              ) : (
                <div className={classes.wrapperWhite}>
                  <Toolbar className={classes.toolBar}>
                    <Button
                      text="Add New"
                      size="small"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      style={{ visibility: "hidden" }}
                      onClick={() => {
                        history.push("/addemployee");
                      }}
                    />
                    <div className={classes.actions}>
                      <Button
                        startIcon={<AddIcon fontSize="small" />}
                        text="Add New"
                        color="success"
                        onClick={() => {
                          history.push("/function/add");
                        }}
                      />
                      <Button
                        startIcon={<EditOutlinedIcon fontSize="small" />}
                        text="Edit"
                        color="secondary"
                        onClick={() => {
                          if (flag === true || rowId === null) {
                            setOpenPopup({
                              ...openPopup,
                              title: "SELECTION ISSUE!!!",
                              subTitle:
                                "Minimum and Maximum one row have to be slected!",
                              isOpen: true,
                            });
                          }
                          if (rowClicked === true) {
                            history.push(`/function/${rowId}/edit`);
                          }
                        }}
                      />
                      <Button
                        startIcon={<CloseOutlinedIcon fontSize="small" />}
                        text="DELETE"
                        color="error"
                        onClick={() => {
                              console.log(rowId);

                            if (flag === true || rowId === null) {
                                setOpenPopup({
                                ...openPopup,
                                title: "SELECTION ISSUE!!!",
                                subTitle:
                                    "Minimum and Maximum one row have to be slected!",
                                isOpen: true,
                                });
                            }
                            if (rowClicked === true) {
                                setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure that you want to delete this data?",
                                    subTitle: "Once it is deleted, you can't restore!",
                                    onConfirm: () => {
                                      console.log("Delete confirmed");
                                      onDelete(rowId);
                                    },
                                });
                            }
                        }}
                      />
                    </div>
                  </Toolbar>
        
                  <div className={classes.colHeader}>
                    <DataGrid
                      className={classes.grid}
                      title={"Hello"}
                      columnBuffer={2}
                      columnThreshold={2}
                      disableVirtualization
                      checkboxSelection
                      rows={functions}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      getRowId={(row) => row.functionId + "-" + generateRandom()}
                      //getRowId={(row) => row.functionId}

                      onRowClick={handleEvent}
                      onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[25, 50, 100]}
                     
                    />
                  </div>
        
                  <Notification notify={notify} setNotify={setNotify} />
                  <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                  />
                  <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
                </div>
              )}
            </div>
        
        );

};
export default withRouter(ListProductFunction);
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Button as MuiButton,
} from "@mui/material";

import { Box, Typography, IconButton, Tooltip } from "@mui/material";

import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useTable from "../../core/useTable";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import InventoryIcon from "@mui/icons-material/Inventory";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Link } from "react-router-dom";
import ActionButton from "../../core/controls/ActionButton";
import {
  deleteEmployee,
  listEmployees,
} from "../../redux/actions/employeeActions";
import { emprecords } from "../../DummyData";
import Actions from "../../core/Actions";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";

import {
  EMPLOYEE_CREATE_RESET,
  EMPLOYEE_DELETE_RESET,
  EMPLOYEE_UPDATE_RESET,
} from "../../redux/constants/employeeConstants";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "../../core/controls/Button";
import {
  escalateSalesOrderInternal,
  listSalesOrderInternal,
  listSalesOrderInternalDetails,
} from "../../redux/actions/invoiceActions";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import { Form } from "react-bootstrap";
import {
  SALESORDERINTERNAL_CREATE_REQUEST,
  SALESORDERINTERNAL_CREATE_RESET,
  SALESORDERINTERNAL_ESCALATION_RESET,
  SALESORDERINTERNAL_UPDATE_REQUEST,
  SALESORDERINTERNAL_UPDATE_RESET,
} from "../../redux/constants/invoiceConstants";
import SalesOrderView from "../../core/SalesOrderView";
import {
  escalateStockin,
  listStockin,
  listStockinDetails,
} from "../../redux/actions/stockInActions";
import {
  STOCKIN_CREATE_RESET,
  STOCKIN_ESCALATION_RESET,
  STOCKIN_UPDATE_RESET,
} from "../../redux/constants/stockInConstants";
import StockinView from "../../core/StockinView";

const useStyles = makeStyles(() => ({
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiDataGrid-main": {
      border: "3px groove #F8F8FF",
      borderRadius: "5px",
      borderBottom: "none",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#4b3217",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "200px !important",
      maxWidth: "200px !important",
    },

    // "& .MuiDataGrid-columnHeaderTitle": {
    //   minWidth: "140px !important",
    //   maxWidth: "140px !important",
    //   textAlign: "center",
    // },

    "& .MuiDataGrid-cell": {
      minWidth: "200px !important",
      maxWidth: "200px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#4b3217",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='siSystemId'], .MuiDataGrid-cell[data-field='siSystemId']":
      {
        display: "none",
      },

    "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3']":
      {
        minWidth: "100px !important",
        maxWidth: "100px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#4b3217",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  // grid: {
  //   flexDirection: "column-reverse",
  // },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    "& div:nth-child(1)": {
      margin: "15px",
    },
    [useTheme().breakpoints.up("md")]: {
      display: "flex !important",
      justifyContent: "space-between !important",
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
      "& div:nth-child(1)": {
        margin: "0px",
      },
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },

  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  actions2: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "right",
      width: "420px",
    },
  },
}));

const Stockin = ({ history }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  // console.log("classes");
  // console.log(classes);

  const stockinList = useSelector((state) => state.stockinList);
  const { loading, error, stockin } = stockinList;

  // console.log("employees");
  // console.log(employees);

  const stockinDetails = useSelector((state) => state.stockinDetails);
  const {
    loading: loadingStockinDetails,
    error: errorStockinDetails,
    stockinsingle,
  } = stockinDetails;

  const stockinUpdate = useSelector((state) => state.stockinUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedStockin,
  } = stockinUpdate;

  const stockinCreate = useSelector((state) => state.stockinCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    createdStockin,
  } = stockinCreate;

  const stockinEscalate = useSelector((state) => state.stockinEscalate);
  const {
    loading: loadingEscalate,
    error: errorEscalate,
    success: successEscalate,
    escalatedStockin,
  } = stockinEscalate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("50");
  const [dataForEscalation, setDataForEscalation] = useState([]);
  const selectedOrderRef = useRef(null);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listStockin(selectedOrder));
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: STOCKIN_UPDATE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Stockin Created.",
          type: "success",
        });

        dispatch({ type: STOCKIN_CREATE_RESET });
      }

      if (successEscalate) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Escalated Successfully",
          type: "error",
        });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setDataForEscalation([]);

        dispatch({ type: STOCKIN_ESCALATION_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/stockin/list" } },
      };
      history.push(location);
    }

    // console.log(successUpdate);
  }, [
    dispatch,
    classes,
    history,
    userInfo,
    successUpdate,
    selectedOrder,
    successEscalate,
  ]);

  const handleEvent = (params) => {
    // history.push(`/employee/${params.row.employeeId}/edit`);
    // console.log(params.row);
  };

  const buttonsBasedOnSalesOrderType = () => {
    console.log("selectedOrder");
    console.log(selectedOrder);
    if (selectedOrder === "50") {
      return (
        <Box className={classes.actions}>
          <Button
            startIcon={<AddIcon fontSize="small" />}
            text="Add New"
            color="primary"
            onClick={() => {
              history.push("/stockin/create");
              // setOpenPopup(true);
              // setNotify({
              //   isOpen: true,
              //   message: "Submitted Successfully",
              //   type: "success",
              // });
            }}
          />
          <Button
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/stockin/${rowId}/edit`);
              }
            }}
          />
          <Button
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              if (dataForEscalation.length > 0) {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure that you want to escalate this data?",
                  subTitle: "This data will be sent to the operation team!",
                  onConfirm: () => {
                    // console.log("dataForEscalation");
                    // console.log(dataForEscalation);
                    dispatch(escalateStockin(dataForEscalation));
                  },
                });
              } else {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "No Data is slected!",
                  isOpen: true,
                });
              }
            }}
          />
          {/* <Button
          startIcon={<CloseOutlinedIcon fontSize="small" />}
          text="DELETE"
          color="error"
          disabled
          onClick={() => {
            // console.log(rowId);

            if (flag === true || rowId === null) {
              setOpenPopup({
                ...openPopup,
                title: "SELECTION ISSUE!!!",
                subTitle:
                  "Minimum and Maximum one row have to be slected!",
                isOpen: true,
              });
            }
            if (rowClicked === true) {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to delete this data?",
                subTitle: "Once it is deleted, you can't restore!",
                onConfirm: () => {
                  onDelete(rowId);
                },
              });
            }
          }}
        /> */}
        </Box>
      );
    } else if (selectedOrder === "1") {
      // console.log("Hello");
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<TelegramIcon fontSize="small" />}
            text="ACTION"
            color="success"
            onClick={() => {
              // alert("clicked");
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/action`);
              }
            }}
          />
          {/* <Button
          startIcon={<CloseOutlinedIcon fontSize="small" />}
          text="DELETE"
          color="error"
          disabled
          onClick={() => {
            // console.log(rowId);

            if (flag === true || rowId === null) {
              setOpenPopup({
                ...openPopup,
                title: "SELECTION ISSUE!!!",
                subTitle:
                  "Minimum and Maximum one row have to be slected!",
                isOpen: true,
              });
            }
            if (rowClicked === true) {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to delete this data?",
                subTitle: "Once it is deleted, you can't restore!",
                onConfirm: () => {
                  onDelete(rowId);
                },
              });
            }
          }}
        /> */}
        </Box>
      );
    } else if (selectedOrder === "2") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<InventoryIcon fontSize="small" />}
            text="PACKAGING"
            color="success"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/package`);
              }
            }}
          />
          {/* <Button
          startIcon={<CloseOutlinedIcon fontSize="small" />}
          text="DELETE"
          color="error"
          disabled
          onClick={() => {
            // console.log(rowId);

            if (flag === true || rowId === null) {
              setOpenPopup({
                ...openPopup,
                title: "SELECTION ISSUE!!!",
                subTitle:
                  "Minimum and Maximum one row have to be slected!",
                isOpen: true,
              });
            }
            if (rowClicked === true) {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to delete this data?",
                subTitle: "Once it is deleted, you can't restore!",
                onConfirm: () => {
                  onDelete(rowId);
                },
              });
            }
          }}
        /> */}
        </Box>
      );
    }
  };

  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={stockin}
          columns={
            selectedOrder === "50"
              ? [...columns_part_b_O_0, ...columns_part_a]
              : selectedOrder === "1"
              ? [...columns_part_a, ...columns_part_b_O_1]
              : selectedOrder === "2"
              ? [...columns_part_a, ...columns_part_b_O_2]
              : columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.siSystemId + "-" + generateRandom()}
          onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
    // } else if (selectedOrder === "1") {
    // } else if (selectedOrder === "2") {
    // }
  };

  const onRowsSelectionHandler = (ids) => {
    // if (selectedOrder === "0") {
    //   if (ids.length > 1) {
    //     // console.log("ids");
    //     // console.log(ids);

    //     setRowClicked(false);
    //     setFlag(true);
    //   } else if (ids.length < 1) {
    //     setRowClicked(false);
    //     setFlag(true);
    //   } else {
    //     ids = ids[0].split("-");
    //     var temp = ids[0];
    //     ids = [];
    //     ids.push(temp);
    //     const arrOfNum = ids.map((str) => {
    //       return Number(str);
    //     });
    //     ids = arrOfNum;
    //     // console.log("ids");
    //     // console.log(ids);
    //     const selectedRowsData = ids.map((id) =>
    //       salesorderinternal.find((row) => row.soSystemId === id)
    //     );
    //     // console.log("selectedRowsData");
    //     // console.log(selectedRowsData);
    //     setRowId(selectedRowsData[0].soSystemId);
    //     setRowClicked(!rowClicked);
    //     setFlag(false);
    //   }
    // } else {
    if (ids.length > 1) {
      if (selectedOrder === "50") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        // console.log("newids");
        // console.log(newids);

        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        // console.log("ids");
        // console.log(ids);
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      // console.log("ids");
      // console.log(ids);
      const selectedRowsData = ids.map((id) =>
        stockin.find((row) => row.siSystemId === id)
      );
      //   console.log("selectedRowsData");
      //   console.log(selectedRowsData);
      setDataForEscalation([selectedRowsData[0].siSystemId]);
      setRowId(selectedRowsData[0].siSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteEmployee(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };

  const openModal = (id) => {
    dispatch(listStockinDetails(id)).then((res) => {
      setIsOpen(true);
    });
  };
  const closeModal = (event) => setIsOpen(false);

  console.log("stockin");
  console.log(stockin);
  const columns_part_a = [
    {
      field: "siSystemNo",
      headerName: "Stockin No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "siInvoiceNo",
      headerName: "Invoice No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "siInvoiceDate",
      headerName: "Invoice Date",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "supplierName",
      headerName: "Partner Name",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.supplierName || params.row.customerName,
    },
    
    {
      field: "jobType",
      headerName: "Receive Type",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "sidFinalAmount",
      headerName: "Total Bill",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "siSystemId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
  ];

  const columns_part_b_O_0 = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              // console.log("params");
              // console.log(params);
              history.push(`/stockin/${row.siSystemId}/edit`);
            }}
          />
        );
      },
    },

    {
      field: "actions2",
      headerName: "ESCALATE",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              // console.log("params");
              // console.log(params);
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to escalate this data?",
                subTitle: "This data will be sent to the operation team!",
                onConfirm: () => {
                  // console.log("dataForEscalation");
                  // console.log(dataForEscalation);
                  dispatch(escalateStockin([row.siSystemId]));
                },
              });
            }}
          />
        );
      },
    },
    {
      field: "actions3",
      headerName: "VIEW",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="VIEW"
            focuselementref={selectedOrderRef}
            startIcon={<VisibilityIcon fontSize="small" />}
            text="VIEW"
            color="secondary"
            onClick={() => {
              // console.log("params");
              // console.log(params);

              openModal(row.siSystemId);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_1 = [
    {
      field: "actions",
      headerName: "PUSH FOR PACKAGING",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="PUSH FOR PACKAGING"
            focuselementref={selectedOrderRef}
            startIcon={<TelegramIcon fontSize="small" />}
            text="PUSH FOR PACKAGING"
            color="success"
            onClick={() => {
              // console.log("params");
              // console.log(params);
              history.push(`/salesorderinternal/${row.soSystemId}/action`);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_2 = [
    {
      field: "actions",
      headerName: "GENERATE INVOICE",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PACKAGING"
            focuselementref={selectedOrderRef}
            startIcon={<PrintIcon fontSize="small" />}
            text="PACKAGING"
            color="secondary"
            onClick={() => {
              // console.log("params");
              // console.log(params);
              history.push(`/salesorderinternal/${row.soSystemId}/package`);
            }}
          />
        );
      },
    },
  ];
  // useEffect(() => {}, [employeeList]);

  //   const [records, setRecords] = useState(emprecords);
  // const [filterFn, setFilterFn] = useState({
  //   fn: (items) => {
  //     return items;
  //   },
  // });

  //   const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
  //     useTable(employees, headCells, filterFn);

  // const handleSearch = (e) => {
  //   let target = e.target;
  //   setFilterFn({
  //     fn: (items) => {
  //       if (target.value == "") return items;
  //       else return items.filter((x) => x.empName.includes(target.value));
  //     },
  //   });
  // };
  console.log("stockinsingle");
  console.log(stockinsingle);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Stockin"
        subtitle="See the total Stockin List"
      />
      {/* {loadingDelete && <>loading..</>} */}
      {error ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#4b3217" />
          {/* <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#6C4AB6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <div style={{ visibility: "hidden" }}>
              <Form.Select
                aria-label="Default select example"
                value={selectedOrder}
                onChange={(e) => {
                  setSelectedOrder(e.target.value);
                }}
              >
                <option value="0">Created Sales Orders</option>
                <option value="1">Escalated Sales Orders</option>
                <option value="2">Sales Orders sent for packaging</option>
              </Form.Select>
            </div>
            {buttonsBasedOnSalesOrderType()}
          </Toolbar>
          {gridBasedOnSalesOrderType()}
          {/* </Box>
          </Box> */}
          {/* </Paper> */}
          {/* <TblPagination /> */}

          {JSON.stringify(stockinsingle) !== "{}" &&
            stockinsingle !== undefined && (
              <StockinView
                showModal={isOpen}
                closeModal={closeModal}
                info={stockinsingle}
                items={stockinsingle?.stockInItemsData}
                currency={"TK"}
                subTotal={stockinsingle?.stockInBillData?.sibCalculatedAmount}
                discountAmmount={
                  stockinsingle?.stockInBillData?.sibDiscountAmouunt
                }
                total={stockinsingle?.stockInBillData?.sibFinalAmount}
              />
            )}
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          {/* <Popup
            title="Employee Form"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <AddIhouseEmployeedupli />
          </Popup> */}
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </div>
      )}
    </div>
  );
};

export default Stockin;

import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
//

import ListIcon from "@mui/icons-material/List";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import {
  deleteActivity,
  listDoneActivity,
} from "../../redux/actions/MarketingActivityActions";
import {
  Box,
  Tooltip,
  IconButton,
  Toolbar,
  Collapse,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import ActionButton from "../../core/controls/ActionButton";

import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import {
  ACTIVITY_CREATE_RESET,
  DONE_ACTIVITY_DELETE_RESET,
  DONE_ACTIVITY_UPDATE_RESET,
} from "../../redux/constants/MarketingActivityConstants";
import Popup from "../../core/Popup";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "../../core/controls/Button";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  colHeader: {
    height: 550,
    "& .MuiTableHead-root .MuiTableCell-root": {
      backgroundColor: "#1565c0",
      color: "#fff",
      textAlign: "center",
    },

    "& .MuiTableHead-root .MuiTableCell-root .MuiButtonBase-root": {
      color: "#fff !important",
    },

    "& .MuiTableBody-root .MuiTable-root .MuiTableHead-root .MuiTableCell-root":
      {
        backgroundColor: "transparent",
        color: "black",
        textAlign: "center",
      },
    "& .MuiTableBody-root .MuiTable-root .MuiTableBody-root .MuiTableCell-root":
      {
        backgroundColor: "transparent",
        color: "black",
        textAlign: "center",
      },

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },
  },

  // grid: {
  //   flexDirection: "column-reverse",
  // },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },
}));

var visitIds = null;

const MarketingActivities = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const doneActivityList = useSelector((state) => state.doneActivityList);
  const {
    loading: loadingDoneActivity,
    error: errorDoneActivity,
    doneActivities,
  } = doneActivityList;
  // console.log(doneActivities);

  const activityCreate = useSelector((state) => state.activityCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    activity: createdactivity,
  } = activityCreate;

  const activityDelete = useSelector((state) => state.activityDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = activityDelete;

  const activityUpdate = useSelector((state) => state.activityUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = activityUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [flag, setFlag] = useState(false);

  const [clickedIndex, setClickedIndex] = useState(-1);
  const [stp, setStp] = useState("none");
  const [test, setTest] = useState(false);

  useEffect(() => {
    if (userInfo) {
      dispatch(listDoneActivity());

      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: DONE_ACTIVITY_UPDATE_RESET });
      }

      if (successDelete) {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });

        dispatch({ type: DONE_ACTIVITY_DELETE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: ACTIVITY_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/marketingactivities" } },
      };
      history.push(location);
    }
  }, [
    dispatch,
    history,
    userInfo,
    successCreate,
    successDelete,
    successUpdate,
  ]);

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
    } else {
      const selectedRowsData = ids.map((id) =>
        doneActivities.find((row) => row.visitId === id)
      );
      setRowId(selectedRowsData[0].visitId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteActivity(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };
  const detailStyles = {
    borderTop: "2px solid",
    borderTopColor: "primary.main",
    pt: 2,
  };

  const getExpandedData = (rowData) => {
    const data = doneActivities.filter((data) => rowData[0] === data.visitId);
    // console.log("datafilter");
    // console.log(data);
    return (
      <>
        {data[0].visitDetailsUpdate.map((row) => (
          <TableRow key={row.mktActivityId}>
            <TableCell align="right">{row.mktActivityId}</TableCell>
            <TableCell align="right">{row.mktActivityList}</TableCell>
            <TableCell align="right">{row.visitDetailsId}</TableCell>
            <TableCell align="right">{row.voiceOfCustomer}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const GetRowId = (visitId) => {
    // console.log(visitId);
    setRowId(visitId);
  };
  // console.log("rowId");
  // console.log(rowId);

  const options = {
    // onRowsSelect: (rowsSelected, allRows) => {
    //   console.log("allRowsSelected");
    //   var vId = doneActivities[rowsSelected[0].index].visitId;
    //   setRowId(vId);
    //   console.log(doneActivities[rowsSelected[0].index].visitId);
    // },
    onDownload: (buildHead, buildBody, columns, data) => {
      buildHead = () => {
        return ["col-1", "col-2"];
      };
      buildBody = () => {
        return [
          ["A", "B"],
          ["C", "D"],
        ];
      };
      return "\uFEFF" + buildHead() + "\n" + buildBody();
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      // console.log("currentRowsSelected", currentRowsSelected);
      // console.log("allRowsSelected", allRowsSelected);
      // console.log("rowsSelected", rowsSelected);
      if (rowsSelected.length > 0) {
        var vId = doneActivities[currentRowsSelected[0].index].visitId;
        // console.log("vId");
        // console.log(vId);
        setRowId(vId);
        setRowClicked(true);
      } else {
        setRowId(null);
        setRowClicked(false);
      }
    },
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      // console.log(changedColumn, filterList);
    },
    selectableRows: "single",
    selectToolbarPlacement: stp,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      // console.log(selectedRows);
      // console.log("displayData");
      // console.log(displayData);
      return (
        <Tooltip title="edit">
          <IconButton
            onClick={() => {
              // console.log(test);
              setTest(!test);
              // console.log(test);
            }}
            style={{
              marginRight: "24px",
              height: "48px",
              top: "50%",
              display: "block",
              position: "relative",
              transform: "translateY(-50%)",
            }}
          ></IconButton>
        </Tooltip>
      );
    },
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    expandableRows: true,
    // onRowClick: (rowData, rowState) => {
    //   console.log("rowData, rowState");
    //   console.log(rowData, rowState);
    // },

    renderExpandableRow: (rowData, rowMeta) => {
      // console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Activity ID</TableCell>
                      <TableCell align="right">Activity Done</TableCell>
                      <TableCell align="right">Visit Details ID</TableCell>
                      <TableCell align="right">Voice of Customer</TableCell>
                      {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>{getExpandedData(rowData)}</TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },

    // page: 1,
  };

  const columns = [
    {
      name: "visitId",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "visitDate",
      label: "Visit Date",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const handleEvent = (params) => {
    // history.push(`/product/${params.row.visitId}/edit`);
  };

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<ListIcon />}
        title="Marketing Activity"
        subtitle="This is the Marketing Activity list"
      />

      {errorDoneActivity === "Network Error" ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loadingDoneActivity ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#1565C0" />
          {/* <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#1565C0"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
        </div>
      ) : (
        <>
          <div className={classes.wrapperWhite}>
            <Toolbar className={classes.toolBar}>
              <Button
                text="Add New"
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ visibility: "hidden" }}
                onClick={() => {
                  history.push("/addemployee");
                  // setOpenPopup(true);
                  // setNotify({
                  //   isOpen: true,
                  //   message: "Submitted Successfully",
                  //   type: "success",
                  // });
                }}
              />
              <div className={classes.actions}>
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  text="Add New"
                  color="success"
                  onClick={() => {
                    history.push("/addmarketingactivity");
                  }}
                  style={{
                    backgroundColor: "#1B5E20",
                    color: "white",
                  }}
                />
                <Button
                  startIcon={<EditOutlinedIcon fontSize="small" />}
                  text="Edit"
                  color="secondary"
                  onClick={() => {
                    if (flag === true || rowId === null) {
                      setOpenPopup({
                        ...openPopup,
                        title: "NOT SELECTED!!!",
                        subTitle:
                          "Minimum and Maximum one row have to be slected!",
                        isOpen: true,
                      });
                    }
                    if (rowClicked === true) {
                      history.push(`/marketingactivity/${rowId}/edit`);
                    }
                  }}
                  style={{
                    backgroundColor: "#7B1FA2",
                    color: "white",
                  }}
                />
                <Button
                  startIcon={<CloseOutlinedIcon fontSize="small" />}
                  text="DELETE"
                  color="error"
                  onClick={() => {
                    // console.log(rowId);

                    if (flag === true || rowId === null) {
                      setOpenPopup({
                        ...openPopup,
                        title: "NOT SELECTED!!!",
                        subTitle:
                          "Minimum and Maximum one row have to be slected!",
                        isOpen: true,
                      });
                    }
                    if (rowClicked === true) {
                      setConfirmDialog({
                        isOpen: true,
                        title:
                          "Are you sure that you want to delete this data?",
                        subTitle: "Once it is deleted, you can't restore!",
                        onConfirm: () => {
                          onDelete(rowId);
                        },
                      });
                    }
                  }}
                  style={{
                    backgroundColor: "#C62828",
                    color: "white",
                  }}
                />
              </div>
              {/* <Input
              label="Search Employees"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.searchInput}
              // onChange={handleSearch}
            /> */}
            </Toolbar>

            {/* <div className={classes.colHeader}>
            <DataGrid
              className={classes.grid}
              columnBuffer={2}
              columnThreshold={2}
              disableVirtualization
              checkboxSelection
              rows={doneActivities}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.visitId}
              onRowClick={handleEvent}
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </div> */}
            <div className={classes.colHeader}>
              <MUIDataTable
                data={doneActivities}
                columns={columns}
                options={options}
              />
            </div>
            <Notification notify={notify} setNotify={setNotify} />
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(MarketingActivities);

import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import ConfirmDialog from "../../core/ConfirmDialog";
import { InhouseEmployeeSchema } from "../../schemas";
import {
  createEmployee,
  deleteEmployee,
  listEmployeeDetails,
  updateEmployee,
} from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import { API_URL } from "../../redux/constants/apiConstants";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  listChannel,
  listDeparment,
  listDesignation,
} from "../../redux/actions/channelActions";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
var d = [date.getFullYear(), mnth, day].join("-");
// console.log(typeof d);
const initialValues = {
  employeeId: "",
  employeeCode: uuidv4(),
  empFirstName: "",
  empLastName: "",
  empPresentAddress: "",
  empParmanentAddress: "",
  bloodGroup: "",
  dateOfJoining: d,
  empContactNo: "",
  guardianContactNo: "",
  designation: {
    id: "",
    label: "",
  },
  department: {
    id: "",
    label: "",
  },
  channel: {
    id: "",
    label: "",
  },
  empTypeId: "",
};

const EditEmployee = ({ match, history }) => {
  const employeeId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [employeeTypes, setEmployeeTypes] = useState([]);

  const channelList = useSelector((state) => state.channelList);
  const {
    loading: loadingChannels,
    error: errorChannels,
    channels,
  } = channelList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const employeeDetails = useSelector((state) => state.employeeDetails);
  const { loading, error, employee } = employeeDetails;

  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = employeeUpdate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  // const { values, setValues, handleChange } = useForm(initialValues);

  // console.log(employee);

  const formik = useFormik({
    initialValues,
    validationSchema: InhouseEmployeeSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      // console.log(values);

      dispatch(
        updateEmployee({
          employeeId: employeeId,
          employeeCode: values.employeeCode,
          empFirstName: values.empFirstName,
          empLastName: values.empLastName,
          empPresentAddress: values.empPresentAddress,
          empParmanentAddress: values.empParmanentAddress,
          bloodGroup: values.bloodGroup,
          dateOfJoining: values.dateOfJoining,
          empContactNo: values.empContactNo,
          guardianContactNo: values.guardianContactNo,
          designationId: values.designation.id,
          departmentId: values.department.id,
          channelId: values.channel.id,
          empTypeId: values.empTypeId,
        })
      )
        .then((result) => {
          // console.log("result");
          // console.log(result);
          if (result?.errMsg) {
            console.log(result);
            action.setFieldError("errorEmployeePhone", result.errMsg);

            action.setSubmitting(false);
          } else {
            history.push("/inhouseemployees");
          }

          // console.log("data");
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
          console.log(err);
        });

      // if (!loadingCreate && successCreate) {
      // const location = {
      //   pathname: "/inhouseemployees",
      //   state: { message: "submitted", data: {} },
      // };
      // history.push(location);
      // }
    },
  });

  // useEffect(() => {
  //   if (successUpdate) {
  //     // dispatch({ type: PRODUCT_UPDATE_RESET });
  //     // dispatch({ type: PRODUCT_DETAILS_RESET });
  //     // history.push("/admin/productlist");
  //   } else {
  //     console.log("employee");
  //     console.log(employee);
  //     if (!employee.empName || employee.employeeId !== employeeId) {
  //       listEmployeeDetails(employeeId);
  //     } else {
  //       var date = new Date(employee.dateOfJoining),
  //         mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //         day = ("0" + date.getDate()).slice(-2);
  //       const d = [date.getFullYear(), mnth, day].join("-");
  //       console.log(d);
  //       formik.setValues({
  //         employeeCode: employee.employeeCode,
  //         empName: employee.empName,
  //         designationId: employee.designationId,
  //         empPresentAddress: employee.empPresentAddress,
  //         empParmanentAddress: employee.empParmanentAddress,
  //         dateOfJoining: d,
  //         departmentId: employee.departmentId,
  //         channelId: employee.channelId,
  //         empContactNo: employee.empContactNo,
  //         guardianContactNo: employee.guardianContactNo,
  //       });
  //     }
  //   }
  // }, [employeeId, dispatch, employee, history, successUpdate]);

  // useEffect(() => {
  //   dispatch(listEmployeeDetails(employeeId));
  // }, [employeeId, dispatch, history, successUpdate]);

  const changeValues = () => {
    // console.log("employee");
    // console.log(employee);
    if (JSON.stringify(employee) !== "{}") {
      var date = new Date(employee.dateOfJoining),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const d = [date.getFullYear(), mnth, day].join("-");
      // console.log(d);
      formik.setValues({
        employeeId: employee.employeeId,
        employeeCode: employee.employeeCode,
        empFirstName: employee.empFirstName,
        empLastName: employee.empLastName,
        empPresentAddress: employee.empPresentAddress,
        empParmanentAddress: employee.empParmanentAddress,
        dateOfJoining: d,
        empContactNo: employee.empContactNo,
        guardianContactNo: employee.guardianContactNo,
        empTypeId: employee.empTypeId,
        bloodGroup: employee.bloodGroup,
      });
      formik.setFieldValue("designation", {
        id: employee.designationId || "",
        label: employee.designation || "",
      });

      formik.setFieldValue("channel", {
        id: employee.channelId || "",
        label: employee.channelName || "",
      });
      formik.setFieldValue("department", {
        id: employee.departmentId || "",
        label: employee.departmentName || "",
      });
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listEmployeeDetails(employeeId));
      dispatch(listDesignation());
      dispatch(listChannel());
      dispatch(listDeparment());
      loadEmployeeType();
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/employee/${employeeId}/edit` } },
      };
      history.push(location);
    }
  }, [employeeId, dispatch, history, userInfo]);

  useEffect(() => {
    changeValues();
  }, [employee, employee.dateOfJoining]);

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteEmployee(id));
    history.push("/inhouseemployees");
  };

  const loadEmployeeType = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/Employee/GetEmployeeType`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const final = result.dataObj.map((single) => {
          return {
            id: single.empTypeId,
            title: single.employeeTypeName,
          };
        });

        setEmployeeTypes(final);
      })
      .catch((error) => console.log("error", error));
  };

  const channelChange = useCallback((e, v) => {
    formik.setFieldValue("channel", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const departmentChange = useCallback((e, v) => {
    formik.setFieldValue("department", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });
  // useEffect(() => {

  // }, []);
  // useEffect(() => {
  //   loadDesignation();
  //   loadDepartment();
  //   loadLocation();
  //   loadEmployeeType();
  // }, []);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<BrowserUpdatedIcon />}
        title="Employees"
        subtitle="Update data of an inhouse employee"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              {/* <Input
                label="Employee Code"
                name="employeeCode"
                value={formik.values.employeeCode}
                disabled
              /> */}
              <Input
                label="Employee Firstname"
                name="empFirstName"
                error={formik.errors.empFirstName}
                touched={formik.touched.empFirstName}
                value={formik.values.empFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Employee Lastname"
                name="empLastName"
                error={formik.errors.empLastName}
                touched={formik.touched.empLastName}
                value={formik.values.empLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingDesignations ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={designationChange}
                value={formik.values.designation}
                options={designations !== undefined ? designations : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Designation"
                    name="designation"
                    error={
                      formik.errors.designation !== undefined
                        ? formik.errors.designation.id
                        : ""
                    }
                    touched={
                      formik.touched.designation !== undefined
                        ? formik.touched.designation
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Select
                variant="outlined"
                size="small"
                label="Blood Group"
                name="bloodGroup"
                error={formik.errors.bloodGroup}
                touched={formik.touched.bloodGroup}
                value={formik.values.bloodGroup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  {
                    id: "A+",
                    title: "A+",
                  },
                  {
                    id: "A-",
                    title: "A-",
                  },
                  {
                    id: "B+",
                    title: "B+",
                  },
                  {
                    id: "B-",
                    title: "B-",
                  },
                  {
                    id: "O+",
                    title: "O+",
                  },
                  {
                    id: "O-",
                    title: "O-",
                  },
                  {
                    id: "AB+",
                    title: "AB+",
                  },
                  {
                    id: "AB-",
                    title: "AB-",
                  },
                ]}
              />
              <Input
                label="Present Address"
                name="empPresentAddress"
                error={formik.errors.empPresentAddress}
                touched={formik.touched.empPresentAddress}
                value={formik.values.empPresentAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Permanent Address"
                name="empParmanentAddress"
                error={formik.errors.empParmanentAddress}
                touched={formik.touched.empParmanentAddress}
                value={formik.values.empParmanentAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Datepicker
                label="Date of Joining"
                name="dateOfJoining"
                value={formik.values.dateOfJoining}
                onChange={formik.handleChange}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingDepartments ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={departmentChange}
                value={formik.values.department}
                options={departments !== undefined ? departments : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Department"
                    name="department"
                    error={
                      formik.errors.department !== undefined
                        ? formik.errors.department.id
                        : ""
                    }
                    touched={
                      formik.touched.department !== undefined
                        ? formik.touched.department
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingChannels ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={channelChange}
                value={formik.values.channel}
                options={channels !== undefined ? channels : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Channel"
                    name="channel"
                    error={
                      formik.errors.channel !== undefined
                        ? formik.errors.channel.id
                        : ""
                    }
                    touched={
                      formik.touched.channel !== undefined
                        ? formik.touched.channel
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Select
                variant="outlined"
                size="small"
                label="Employee Type"
                name="empTypeId"
                error={formik.errors.empTypeId}
                touched={formik.touched.empTypeId}
                value={"" || formik.values.empTypeId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={employeeTypes}
              />
              <Input
                label="Contact #"
                name="empContactNo"
                error={formik.errors.empContactNo}
                extraError={formik.errors.errorEmployeePhone}
                touched={formik.touched.empContactNo}
                value={formik.values.empContactNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />
              <Input
                label="Guardian Contact No #"
                name="guardianContactNo"
                error={formik.errors.guardianContactNo}
                touched={formik.touched.guardianContactNo}
                value={formik.values.guardianContactNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Input
                label="Email Address"
                name="email"
                value={values.email}
                onChange={handleChange}
                className="fullwidth-input"
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <Input
                label="username"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <Input
                label="password"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Update
                </MuiButton>
              </ButtonGroup>

              <Button
                color="secondary"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/inhouseemployees",
                    state: {},
                  };
                  history.push(location);
                }}
              />
              <MuiButton
                onClick={() => {
                  // onDelete(params.row.employeeId);
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure that you want to delete this data?",
                    subTitle: "Once it is deleted, you can't restore!",
                    onConfirm: () => {
                      onDelete(match.params.id);
                    },
                  });
                }}
                color="error"
                startIcon={
                  loading ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : (
                    <DeleteIcon />
                  )
                }
                size="large"
              >
                Delete
              </MuiButton>
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
              loadingChannels && loadingDesignations && loadingDepartments
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default withRouter(EditEmployee);

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_RESET,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_WITH_ROLE_LIST_FAIL,
  USER_WITH_ROLE_LIST_REQUEST,
  USER_WITH_ROLE_LIST_SUCCESS,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const updatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_CHANGE_REQUEST:
      return { loading: true };
    case USER_PASSWORD_CHANGE_SUCCESS:
      return { loading: false, success: true };
    case USER_PASSWORD_CHANGE_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_CHANGE_RESET:
      return {};
    default:
      return state;
  }
};

export const userWithRoleListReducer = (
  state = { usersWithRoles: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case USER_WITH_ROLE_LIST_REQUEST:
      return { loading: true };
    case USER_WITH_ROLE_LIST_SUCCESS:
      return {
        loading: false,
        usersWithRoles: action.payload,
      };
    case USER_WITH_ROLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

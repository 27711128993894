import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { useForm, Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Checkbox from "../../../core/controls/Checkbox";
import Button from "../../../core/controls/Button";
import { FunctionSchema } from "../../../schemas";
import Notification from "../../../core/Notification";
import "../../Customers/Customer.css";
import { createProductFunctionAction } from "../../../redux/actions/productActions";
import { FUNCTION_CREATE_RESET } from "../../../redux/constants/productConstants";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

const initialValues = {
  functionName: "",
};

const CreateProductFunction = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  
  // Notification state
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // Checkbox state
  const [checked, setChecked] = useState(true);

  // Redux state for createProductFunction
  const createProductFuctionState = useSelector((state) => state.createProductFuctionState);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    functions: createdFunction,
  } = createProductFuctionState;

  // Redux state for user login
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema: FunctionSchema,
    onSubmit: (values, action) => {
      dispatch(
        createProductFunctionAction({
          functionId: 0,
          functionName: values.functionName,
        })
      ).then((result) => {
        if (result?.errMsg) {
            if (result.errMsg === "Product Function Already Exist") {
              action.setFieldError("errorClientName", result.errMsg);
            }
            action.setSubmitting(false);
          } else {
            if (checked === true) {
              action.resetForm();
            } else {
              history.push("/productfunctionlist");
            }  
        }
      });
    },
  });

  // useEffect to handle successCreate and redirect
  useEffect(() => {
    if (userInfo) {
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });
        dispatch({ type: FUNCTION_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/function/add" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Function"
        subtitle="Add a Function"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              {/* Input component for Function Name */}
              <Input
                label="Function Name"
                name="functionName"
                error={formik.errors.functionName}
                extraError={formik.errors.errorClientName}
                touched={formik.touched.functionName}
                value={formik.values.functionName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid></Grid>
            <Grid item xs={12} md={12}>
              {/* Checkbox component for staying on the page */}
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {/* ButtonGroup for Submit and Reset */}
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              {/* Back button */}
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/productfunctionlist",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      {/* Notification component */}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(CreateProductFunction);

import {
  CHANNEL_LIST_FAIL,
  CHANNEL_LIST_REQUEST,
  CHANNEL_LIST_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DESIGNATION_LIST_FAIL,
  DESIGNATION_LIST_REQUEST,
  DESIGNATION_LIST_SUCCESS,
  EMPLOYEEBY_CHANNEL_LIST_FAIL,
  EMPLOYEEBY_CHANNEL_LIST_REQUEST,
  EMPLOYEEBY_CHANNEL_LIST_SUCCESS,
} from "../constants/channelConstants";

export const channelListReducer = (state = { channels: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case CHANNEL_LIST_REQUEST:
      return { loading: true };
    case CHANNEL_LIST_SUCCESS:
      return {
        loading: false,
        channels: action.payload,
      };
    case CHANNEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const employeesByChannelListReducer = (
  state = { employeesByChannel: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case EMPLOYEEBY_CHANNEL_LIST_REQUEST:
      return { loading: true };
    case EMPLOYEEBY_CHANNEL_LIST_SUCCESS:
      return {
        loading: false,
        employeesByChannel: action.payload,
      };
    case EMPLOYEEBY_CHANNEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const designationListReducer = (
  state = { designations: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case DESIGNATION_LIST_REQUEST:
      return { loading: true };
    case DESIGNATION_LIST_SUCCESS:
      return {
        loading: false,
        designations: action.payload,
      };
    case DESIGNATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const departmentListReducer = (state = { departments: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case DEPARTMENT_LIST_REQUEST:
      return { loading: true };
    case DEPARTMENT_LIST_SUCCESS:
      return {
        loading: false,
        departments: action.payload,
      };
    case DEPARTMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

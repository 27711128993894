import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { InhouseEmployeeSchema } from "../../schemas";
import { createEmployee } from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import { API_URL } from "../../redux/constants/apiConstants";
import { EMPLOYEE_CREATE_RESET } from "../../redux/constants/employeeConstants";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDeparment,
  listDesignation,
} from "../../redux/actions/channelActions";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  employeeCode: uuidv4(),
  empFirstName: "",
  empLastName: "",
  empPresentAddress: "",
  empParmanentAddress: "",
  bloodGroup: "",
  dateOfJoining: [date.getFullYear(), mnth, day].join("-"),
  empContactNo: "",
  guardianContactNo: "",
  designation: {
    id: "",
    label: "",
  },
  department: {
    id: "",
    label: "",
  },
  channel: {
    id: "",
    label: "",
  },
  empTypeId: "",
};

const AddIhouseEmployeedupli = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
  // const { values, setValues, handleChange } = useForm(initialValues);

  const channelList = useSelector((state) => state.channelList);
  const { loading: loadingChannels, error, channels } = channelList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const employeeCreate = useSelector((state) => state.employeeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    employee: createdProduct,
  } = employeeCreate;
  // console.log(loadingCreate);
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: InhouseEmployeeSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      dispatch(
        createEmployee({
          employeeCode: values.employeeCode,
          empFirstName: values.empFirstName,
          empLastName: values.empLastName,
          empPresentAddress: values.empPresentAddress,
          empParmanentAddress: values.empParmanentAddress,
          bloodGroup: values.bloodGroup,
          dateOfJoining: values.dateOfJoining,
          empContactNo: values.empContactNo,
          guardianContactNo: values.guardianContactNo,
          designationId: values.designation.id,
          departmentId: values.department.id,
          channelId: values.channel.id,
          empTypeId: values.empTypeId,
        })
      )
        .then((result) => {
          // console.log("result");
          // console.log(result);
          if (result?.errMsg) {
            action.setFieldError("error", result.errMsg);
            action.setSubmitting(false);
          } else {
            if (checked === true) {
              action.resetForm();
            } else {
              history.push("/inhouseemployees");
            }
          }
          // console.log("data");
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
          console.log(err);
        });
    },
  });

  // const loadDepartment = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`${API_URL}/Department/GetDepartment`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       const final = result.dataObj.map((single) => {
  //         return {
  //           id: single.departmentId,
  //           title: single.departmentName,
  //         };
  //       });
  //       setDepartments(final);
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // const loadDesignation = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`${API_URL}/Designation/GetDesignation`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       const final = result.dataObj.map((single) => {
  //         return {
  //           id: single.designationId,
  //           title: single.designation1,
  //         };
  //       });

  //       setDesignations(final);
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // const loadLocation = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`${API_URL}/Channel/GetChannels`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       const final = result.dataObj.map((single) => {
  //         return {
  //           id: single.channelId,
  //           title: single.channelName,
  //         };
  //       });

  //       setLocations(final);
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  const loadEmployeeType = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/Employee/GetEmployeeType`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const final = result.dataObj.map((single) => {
          return {
            id: single.empTypeId,
            title: single.employeeTypeName,
          };
        });

        setEmployeeTypes(final);
      })
      .catch((error) => console.log("error", error));
  };

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(listDesignation());
      loadEmployeeType();
      dispatch(listChannel());
      dispatch(listDeparment());
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: EMPLOYEE_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/addemployee" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  const channelChange = useCallback((e, v) => {
    formik.setFieldValue("channel", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const departmentChange = useCallback((e, v) => {
    formik.setFieldValue("department", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });
  console.log("formik.errors");
  console.log(formik.errors);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Employees"
        subtitle="Add an inhouse employee"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              {/* <Input
                label="Employee Code"
                name="employeeCode"
                value={formik.values.employeeCode}
                disabled
              /> */}
              <Input
                label="Employee Firstname"
                name="empFirstName"
                error={formik.errors.empFirstName}
                touched={formik.touched.empFirstName}
                value={formik.values.empFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Employee Lastname"
                name="empLastName"
                error={formik.errors.empLastName}
                touched={formik.touched.empLastName}
                value={formik.values.empLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingDesignations ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={designationChange}
                value={formik.values.designation}
                options={designations !== undefined ? designations : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Designation"
                    name="designation"
                    error={
                      formik.errors.designation !== undefined
                        ? formik.errors.designation.id
                        : ""
                    }
                    touched={
                      formik.touched.designation !== undefined
                        ? formik.touched.designation
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Select
                variant="outlined"
                size="small"
                label="Blood Group"
                name="bloodGroup"
                error={formik.errors.bloodGroup}
                touched={formik.touched.bloodGroup}
                value={formik.values.bloodGroup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  {
                    id: "A+",
                    title: "A+",
                  },
                  {
                    id: "A-",
                    title: "A-",
                  },
                  {
                    id: "B+",
                    title: "B+",
                  },
                  {
                    id: "B-",
                    title: "B-",
                  },
                  {
                    id: "O+",
                    title: "O+",
                  },
                  {
                    id: "O-",
                    title: "O-",
                  },
                  {
                    id: "AB+",
                    title: "AB+",
                  },
                  {
                    id: "AB-",
                    title: "AB-",
                  },
                ]}
              />
              <Input
                label="Present Address"
                name="empPresentAddress"
                error={formik.errors.empPresentAddress}
                touched={formik.touched.empPresentAddress}
                value={formik.values.empPresentAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Permanent Address"
                name="empParmanentAddress"
                error={formik.errors.empParmanentAddress}
                touched={formik.touched.empParmanentAddress}
                value={formik.values.empParmanentAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Datepicker
                label="Date of Joining"
                name="dateOfJoining"
                value={formik.values.dateOfJoining}
                onChange={formik.handleChange}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingDepartments ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={departmentChange}
                value={formik.values.department}
                options={departments !== undefined ? departments : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Department"
                    name="department"
                    error={
                      formik.errors.department !== undefined
                        ? formik.errors.department.id
                        : ""
                    }
                    touched={
                      formik.touched.department !== undefined
                        ? formik.touched.department
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingChannels ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={channelChange}
                value={formik.values.channel}
                options={channels !== undefined ? channels : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Channel"
                    name="channel"
                    error={
                      formik.errors.channel !== undefined
                        ? formik.errors.channel.id
                        : ""
                    }
                    touched={
                      formik.touched.channel !== undefined
                        ? formik.touched.channel
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Select
                variant="outlined"
                size="small"
                label="Employee Type"
                name="empTypeId"
                error={formik.errors.empTypeId}
                touched={formik.touched.empTypeId}
                value={formik.values.empTypeId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={employeeTypes}
              />
              <Input
                label="Contact #"
                name="empContactNo"
                error={formik.errors.empContactNo}
                extraError={formik.errors.error}
                touched={formik.touched.empContactNo}
                value={formik.values.empContactNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />

              <Input
                label="Guardian Contact No #"
                name="guardianContactNo"
                error={formik.errors.guardianContactNo}
                touched={formik.touched.guardianContactNo}
                value={formik.values.guardianContactNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Input
                label="Email Address"
                name="email"
                value={values.email}
                onChange={handleChange}
                className="fullwidth-input"
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <Input
                label="username"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <Input
                label="password"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/inhouseemployees",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
              loadingChannels && loadingDesignations && loadingDepartments
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(AddIhouseEmployeedupli);

import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  CUSTOMER_CREATE_FAIL,
  CUSTOMER_CREATE_REQUEST,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DETAILS_FAIL,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_PRODUCTS_TOP_REQUEST,
  PRODUCT_PRODUCTS_TOP_SUCCESS,
  PRODUCT_PRODUCTS_TOP_FAIL,
  CUSTOMER_MAP_LIST_REQUEST,
  CUSTOMER_MAP_LIST_SUCCESS,
  CUSTOMER_MAP_LIST_FAIL,
  UNASSIGNED_CUSTOMER_LIST_REQUEST,
  UNASSIGNED_CUSTOMER_LIST_SUCCESS,
  UNASSIGNED_CUSTOMER_LIST_FAIL,
  ASSIGNED_CUSTOMER_LIST_REQUEST,
  ASSIGNED_CUSTOMER_LIST_SUCCESS,
  ASSIGNED_CUSTOMER_LIST_FAIL,
  ASSIGNED_CUSTOMER_UPDATE_REQUEST,
  ASSIGNED_CUSTOMER_UPDATE_SUCCESS,
  ASSIGNED_CUSTOMER_UPDATE_FAIL,
  GET_PRODUCT_LIST_FAIL,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_REQUEST,
} from "../constants/customerConstants";

export const listCustomers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    
    var config = {
      method: "get",
      url: `${API_URL}/Customer/GetCustomerDetails`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    dispatch({ type: CUSTOMER_LIST_SUCCESS, payload: data.dataObj });
  } 
  catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

///shahed
export const customerListforAutoComp = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    
    var config = {
      method: "get",
      url: `${API_URL}/Customer/GetCustomerDetails`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    dispatch({ type: CUSTOMER_LIST_SUCCESS, payload: data.dataObj });
  } 
  catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
//shahed

// //explore
// export const getProductsList = () => async (dispatch, getState) => {
//   try {
//     dispatch({ type: GET_PRODUCT_LIST_REQUEST });

//     const {
//       userLogin: { userInfo },
//     } = getState();
    
//     var config = {
//       method: "get",
//       url: `${API_URL}/KeyValue/GetProductList`,
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };
//     const { data } = await Axios(config);
//     console.log(data);
//     dispatch({ type: GET_PRODUCT_LIST_SUCCESS, payload: data.dataObj });
//   } catch (error) {
//     dispatch({
//       type: GET_PRODUCT_LIST_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

export const listCustomersForMap = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_MAP_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Customer/GetCustomerDetails`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    var organizedData = data.dataObj.filter((single) => {
      return (
        single.customerName !== null &&
        single.customerLat !== null &&
        single.customerLong !== null
      );
    });

    var organizedData2 = organizedData.map((single) => {
      return {
        name: single.customerName,
        address: single.customerAddress,
        location: {
          lat: single.customerLat,
          lng: single.customerLong,
        },
      };
    });
    // console.log("organizedData2");
    // console.log(organizedData2);
    dispatch({ type: CUSTOMER_MAP_LIST_SUCCESS, payload: organizedData2 });
  } catch (error) {
    dispatch({
      type: CUSTOMER_MAP_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listCustomerDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: CUSTOMER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Customer/GetCustomerById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log(data[0]);

    dispatch({ type: CUSTOMER_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: CUSTOMER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createCustomer = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/Customer/AddCustomer`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: CUSTOMER_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: CUSTOMER_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    return finalGotData;
  } catch (error) {
    dispatch({
      type: CUSTOMER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCustomer = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/Customer/CustomerUpdate`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: CUSTOMER_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: CUSTOMER_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: CUSTOMER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCustomerGeo = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/Customer/CustomerGeoUpdate`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: CUSTOMER_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: CUSTOMER_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: CUSTOMER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCustomer = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "delete",

      url: `${API_URL}/Customer/DeleteCustomer?id=${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await Axios(config);
    dispatch({ type: CUSTOMER_DELETE_SUCCESS });
    //
  } catch (error) {
    dispatch({
      type: CUSTOMER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUnAssignedCustomers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: UNASSIGNED_CUSTOMER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Customer/GetCustomerByEmpolyee?id=0`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    dispatch({ type: UNASSIGNED_CUSTOMER_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: UNASSIGNED_CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listAssignedCustomers =
  (employeeId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ASSIGNED_CUSTOMER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      var config = {
        method: "get",
        url: `${API_URL}/Customer/GetCustomerByEmpolyee?id=0`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const unAssignedCustomers = await Axios(config);

      var config = {
        method: "get",
        url: `${API_URL}/Customer/GetCustomerByEmpolyee?id=${employeeId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const assignedCustomers = await Axios(config);
      dispatch({
        type: ASSIGNED_CUSTOMER_LIST_SUCCESS,
        payload: {
          employeeId: employeeId,
          assignedCustomers: assignedCustomers.data.dataObj,
          unAssignedCustomers: unAssignedCustomers.data.dataObj,
        },
      });
    } catch (error) {
      dispatch({
        type: ASSIGNED_CUSTOMER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createAssignedCustomers = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSIGNED_CUSTOMER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
      assignedCustomerList: {
        employeeId,
        assignedCustomers,
        unAssignedCustomers,
      },
    } = getState();
    const response = unAssignedCustomers.filter((item) =>
      data.includes(item.customerId)
    );
    const response2 = assignedCustomers.concat(response);
    const UnAssignedResponseFilter = unAssignedCustomers.filter(
      (item) => !data.includes(item.customerId)
    );

    dispatch({
      type: ASSIGNED_CUSTOMER_LIST_SUCCESS,
      payload: {
        employeeId: employeeId,
        unAssignedCustomers: UnAssignedResponseFilter,
        assignedCustomers: response2,
      },
    });
  } catch (error) {
    dispatch({
      type: ASSIGNED_CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateAssignedCustomers = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSIGNED_CUSTOMER_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
      assignedCustomerList: {
        employeeId,
        assignedCustomers,
        unAssignedCustomers,
      },
    } = getState();

    const unAssignedResponseFilter = assignedCustomers.filter(
      (item) => !data.includes(item.customerId)
    );
    const unAssigned = unAssignedResponseFilter.map((single) => {
      return {
        customerId: single.customerId,
        employeeId: 0,
      };
    });

    const assignedResponseFilter = assignedCustomers.filter((item) =>
      data.includes(item.customerId)
    );

    const assigned = assignedResponseFilter.map((single) => {
      return {
        customerId: single.customerId,
        employeeId: employeeId,
      };
    });

    const final = assigned.concat(unAssigned);

    var config = {
      method: "put",

      url: `${API_URL}/Customer/UpdateAssignEmployee`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(final),
    };

    Axios(config).then(function (response) {
      dispatch({ type: ASSIGNED_CUSTOMER_UPDATE_SUCCESS });
    });
  } catch (error) {
    dispatch({
      type: ASSIGNED_CUSTOMER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

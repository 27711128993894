import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "../../core/controls/DropDown.css";

const EditProductChangeField = (props) => {
  const [selected, setSelected] = useState([]);
  const {
    info,
    name,
    allItem,
    onItemizedProductEdit,
    item,
    placeholder,
    ...others
  } = props;
  const changeValues = () => {
    // console.log("props.allItem");

    let value = {
      id: info.id === "" || info.id === null ? "" : info.id,
      label: info.label === "" || info.label === null ? "" : info.label,
    };

    // console.log("value");
    // console.log(value);
    if (value.id === "") {
      setSelected([]);
    } else {
      setSelected([value]);
    }
  };
  useEffect(() => {
    if (props?.info) {
      changeValues();
    }
  }, [info]);

  // console.log("props.allItem.index");
  // console.log(props.allItem.index);
  return (
    <div style={{ position: "static !important" }}>
      <Typeahead
        clearButton
        //   style={{ position: "none !important" }}
        className="rbt3"
        id="basic-example"
        name={name}
        label="Batch #"
        // multiple
        // disabled={loadingProduct ? true : false}
        onChange={(selected) => {
          console.log("selected");
          console.log(selected);
          setSelected(selected);
          let value =
            selected.length > 0
              ? {
                  index: allItem.index === undefined ? "" : allItem.index,
                  productId: allItem.productId,
                  name: name,
                  id: selected[0]?.id,
                  label: selected[0]?.label || "",
                }
              : "";
          if (selected.length <= 0) {
            value = {
              index: allItem.index === undefined ? "" : allItem.index,
              productId: allItem.productId,
              name: name,
              id: null,
              label: null,
            };
          }
          //   console.log("value here");
          //   console.log(value);

          onItemizedProductEdit(value);
        }}
        options={item !== undefined ? item : []}
        placeholder={placeholder}
        selected={selected}
        {...others}
      />
    </div>
  );
};

export default EditProductChangeField;

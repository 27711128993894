import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash, BiShowAlt } from "react-icons/bi";
import EditableField from "./EditableField";
import EditableProductField from "./EditableProductField";
import { Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useCallback } from "react";
import { Autocomplete } from "@mui/material";
import Input from "./controls/Input";
import Select from "react-select";
import "react-bootstrap-typeahead/css/Typeahead.css";

import EditProductDropdownField from "./controls/EditProductDropdownField";
import EditProductDropdownFieldMultiple from "./controls/EditProductDropdownFieldMultiple";

class SalesOrderItem extends React.Component {
  constructor(props) {
    super(props);

    // this.update = this.update.bind(this);
    // this.increment = this.increment.bind(this);
  }
  // update = (nextState) => {
  //   this.setState(nextState);
  // };
  // increment() {
  //   this.setState({ count: this.state.count + 1 });
  // }
  render() {
    // const { switchMappingOption } = this.state;
    // var setSwitchMappingOption = this.setState;
    var openModalForBatchAndMap = this.props.openModalForBatchAndMap;
    var onItemizedBatchEdit = this.props.onItemizedBatchEdit;
    var onItemizedItemEdit = this.props.onItemizedItemEdit;
    var onItemizedBatchAndMapEdit = this.props.onItemizedBatchAndMapEdit;
    var currency = this.props.currency;
    var rowDel = this.props.onRowDel;
    var escalate = this.props.escalate === 1 ? false : true;
    // var switchMappingOption = this.props.switchMappingOption;
    // var setSwitchMappingOption = this.props.setSwitchMappingOption;
    var allOptionAuto = this.props.allOptionAuto;
    // console.log("this.props.switchMappingOption");
    // console.log(this.props.switchMappingOption);
    // console.log("escalate");
    // console.log(escalate);
    // console.log(this.props.escalate);

    var itemTable = this.props.items.map(function (item) {
      // console.log("item");
      // console.log(item);
      return (
        <ItemRow2
          openModalForBatchAndMap={openModalForBatchAndMap}
          onItemizedBatchEdit={onItemizedBatchEdit}
          onItemizedItemEdit={onItemizedItemEdit}
          onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
          item={item}
          onDelEvent={rowDel.bind(this)}
          key={item.productId}
          currency={currency}
          escalate={escalate}
          // switchMappingOption={this.state.switchMappingOption}
          // setSwitchMappingOption={setSwitchMappingOption}
          allOptionAuto={allOptionAuto}
        />
      );
    });
    return (
      <div style={{ position: "relative !important" }}>
        <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
          <Table>
            <thead>
              <tr>
                <th>Product</th>
                <th className="text-center">Quantity</th>
                {escalate === false && this.props.allOptionAuto ? (
                  <th className="text-center">Level</th>
                ) : (
                  ""
                )}
                <th className="text-center">Price</th>

                <th className="text-center">Line Total</th>

                {escalate === false && this.props.allOptionAuto ? (
                  <>
                    <th>latest mapping and batch #</th>
                    <th className="text-center">Map?</th>

                    <th className="text-center">Batch #</th>
                  </>
                ) : (
                  <th className="text-center">Action</th>
                )}
              </tr>
            </thead>
            <tbody>{itemTable}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const ItemRow2 = (props) => {
  var myRef = React.useRef(null);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);

  const onDelEvent = () => {
    props.onDelEvent(props.item);
  };

  const openModalForBatchAndMap = () => {
    props.openModalForBatchAndMap(props.item.productId);
  };

  useEffect(() => {
    if (props?.item?.mappedProductInfo?.id !== "") {
      setSwitchMappingOption(true);
    }
  }, [props.item.mappedProductInfo]);

  // console.log("props.item.batchList");
  // console.log(props.item.batchList);

  return (
    <tr ref={myRef}>
      <td style={{ width: "200px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            type: "text",
            name: "productName",
            placeholder: "Item name",
            value: props.item.productName,
            id: props.item.productName,
          }}
          style={{ width: "200px" }}
          disabled
        />
      </td>
      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            type: "number",
            name: "quantity",
            textAlign: "center",
            step: "1",
            value: props.item.quantity,
            id: props.item.productId,
          }}
          style={{ width: "80px" }}
          disabled={props.escalate}
        />
      </td>
      {props.escalate === false && props.allOptionAuto ? (
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={props.onItemizedItemEdit}
            celldata={{
              type: "text",
              name: "productLevel",
              placeholder: "Product Level",
              value: props.item.productLevel,
              id: props.item.productName,
            }}
            style={{ width: "70px" }}
            disabled
          />
        </td>
      ) : (
        ""
      )}
      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            leading: props.currency,
            type: "number",
            name: "unitPrice",
            textAlign: "text-end",
            value: props.item.unitPrice,
            id: props.item.productId,
          }}
          style={{ width: "100px" }}
          // disabled={props.escalate}
          // disabled
        />
      </td>
      {/* <td style={{ minWidth: "130px" }}>
        <EditableProductField
          itemizeditemedit={this.props.onItemizedItemEdit}
          celldata={{
            leading: this.props.currency,
            type: "number",
            name: "unitPrice",
            min: 1,
            step: "0.01",
            presicion: 2,
            textAlign: "text-end",
            value: this.props.item.unitPrice,
            id: this.props.item.unitPrice,
          }}
          disabled={this.props.escalate}
        />
      </td> */}
      <td style={{ minWidth: "110px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            leading: props.currency,
            type: "number",
            name: "lineTotal",
            step: "0.01",
            presicion: 2,
            textAlign: "text-end",
            value: props.item.lineTotal,
            id: props.item.lineTotal,
          }}
          style={{ width: "100px" }}
          disabled
        />
      </td>
      {/* <td></td> */}
      {props.escalate === false && props.allOptionAuto ? (
        <>
          <td className="text-center" style={{ minWidth: "50px" }}>
            <BiShowAlt
              onClick={openModalForBatchAndMap}
              style={{ height: "39px", width: "100%", padding: "7.5px" }}
              className="text-white mt-1 btn btn-info"
            />
          </td>

          <td
            style={{
              display: switchMappingOption ? "flex" : "",
              width: switchMappingOption ? "300px" : "2px",
              minWidth: switchMappingOption ? "300px" : "2px",
            }}
          >
            <div>
              <InputGroup.Text id="basic-addon1" style={{ margin: "6px" }}>
                <Form.Check
                  type="checkbox"
                  id="custom-checkbox"
                  label=""
                  className=""
                  checked={switchMappingOption}
                  onChange={(e) => {
                    setSwitchMappingOption(!switchMappingOption);
                  }}
                />
              </InputGroup.Text>
            </div>
            <div style={{ marginLeft: switchMappingOption ? "9px" : "0px" }}>
              {switchMappingOption ? (
                <Form.Group
                  controlId="form-productId"
                  style={{ minWidth: "200px", margin: "5px" }}
                >
                  <EditProductDropdownField
                    name="sodMappedProductId"
                    itemizeditemedit={props.onItemizedBatchAndMapEdit}
                    info={props.item.mappedProductInfo}
                    item={props.item.mappedItemList}
                    allItem={props.item}
                    placeholder="Map with a product"
                    // disabled
                  />
                </Form.Group>
              ) : (
                <></>
              )}
            </div>
          </td>

          <td style={{ width: "200px" }}>
            <Form.Group controlId="form-productId" style={{ margin: "5px" }}>
              <EditProductDropdownFieldMultiple
                name="batchInfo"
                itemizeditemedit={props.onItemizedBatchEdit}
                info={props.item.batchInfo}
                item={props.item.batchList}
                allItem={props.item}
                placeholder="Batch #"
                // disabled
              />
            </Form.Group>
          </td>
        </>
      ) : (
        ""
      )}
      {props.escalate === false && props.allOptionAuto ? (
        <></>
      ) : (
        <td className="text-center" style={{ minWidth: "50px" }}>
          <BiTrash
            onClick={onDelEvent}
            style={{ height: "33px", width: "33px", padding: "7.5px" }}
            className="text-white mt-1 btn btn-danger"
          />
        </td>
      )}
    </tr>
  );
};
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      switchMappingOption: false,
    };
  }
  onDelEvent() {
    this.props.onDelEvent(this.props.item);
  }

  render() {
    const { switchMappingOption } = this.state;

    return (
      <tr ref={this.myRef}>
        <td style={{ width: "200px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "text",
              name: "productName",
              placeholder: "Item name",
              value: this.props.item.productName,
              id: this.props.item.productName,
            }}
            style={{ width: "200px" }}
            disabled
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "number",
              name: "quantity",
              textAlign: "center",
              step: "1",
              value: this.props.item.quantity,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        {/* <td style={{ minWidth: "130px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              min: 1,
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.unitPrice,
            }}
            disabled={this.props.escalate}
          />
        </td> */}
        <td style={{ minWidth: "150px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "lineTotal",
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.lineTotal,
              id: this.props.item.lineTotal,
            }}
            disabled
          />
        </td>
        {/* <td></td> */}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <>
            <td style={{ minWidth: "auto", display: "flex" }}>
              <div>
                <InputGroup.Text id="basic-addon1">
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label=""
                    className=""
                    checked={this.props.switchMappingOption}
                    onChange={(e) => {
                      this.setState({
                        switchMappingOption: !switchMappingOption,
                      });
                    }}
                  />
                </InputGroup.Text>
              </div>
              <div style={{ marginLeft: "9px" }}>
                {switchMappingOption ? (
                  <Form.Group
                    controlId="form-productId"
                    style={{ minWidth: "250px" }}
                  >
                    <EditProductDropdownField
                      name="sodMappedProductId"
                      itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                      info={this.props.item.mappedProductInfo}
                      item={this.props.item.mappedItemList}
                      allItem={this.props.item}
                      placeholder="Map with a product"
                      // disabled
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
              </div>
            </td>
            <td style={{ minWidth: "250px" }}>
              <Form.Group controlId="form-productId">
                <EditProductDropdownField
                  name="sodRealatedBatchId"
                  itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                  info={this.props.item.batchInfo}
                  item={this.props.item.batchList}
                  allItem={this.props.item}
                  placeholder="Batch #"
                  // disabled
                />
              </Form.Group>
            </td>
          </>
        ) : (
          ""
        )}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <></>
        ) : (
          <td className="text-center" style={{ minWidth: "50px" }}>
            <BiTrash
              onClick={this.onDelEvent.bind(this)}
              style={{ height: "33px", width: "33px", padding: "7.5px" }}
              className="text-white mt-1 btn btn-danger"
            />
          </td>
        )}
      </tr>
    );
  }
}

export default SalesOrderItem;

import ExcelJs from "exceljs";

export const exportToExcelExcelJs = (
  data = [],
  headerKeyMapping,
  file_name = "Report",
  excel_header = "Report"
) => {
  let sheetName = `${file_name}.xlsx`;
  let headerName = "RequestsList";

  let workbook = new ExcelJs.Workbook();
  let sheet = workbook.addWorksheet(sheetName, {
    views: [{ showGridLines: true }],
  });

  // Ensure default row alignment
  sheet.properties.defaultRowAlignment = {
    vertical: "bottom",
    horizontal: "left",  // Set default horizontal alignment to left
  };

  let updatedTitle = [];

  for (const columnDataKey in headerKeyMapping) {
    updatedTitle.push({ name: headerKeyMapping[columnDataKey] });
  }

  sheet.addTable({
    name: `Header`,
    ref: "A2",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "",
      showRowStripes: false,
      showFirstColumn: true,
      width: 200,
    },
    columns: [{ name: excel_header }],
    rows: [[]],
  });

  sheet.addTable({
    name: headerName,
    ref: "A5",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
      width: 200,
      border: {
        top: { style: "double", color: { argb: "FF00FF00" } },
        left: { style: "double", color: { argb: "FF00FF00" } },
        bottom: { style: "double", color: { argb: "FF00FF00" } },
        right: { style: "double", color: { argb: "FF00FF00" } },
      },
    },
    columns: updatedTitle ? updatedTitle : [{ name: "" }],
    rows: data?.map((e) => {
      let arr = [];
      for (let i in e) {
        if (headerKeyMapping[i]) {
          arr.push(e[i]);
        }
      }
      return arr;
    }),
  });

  sheet.getCell("A2").font = { size: 20, bold: true };

  // Adjust column width based on content
  sheet.columns = updatedTitle.map((title, index) => {
    const col = sheet.getColumn(index + 1);
    col.width = Math.max(
      title.name.length,
      ...data.map(row => row[Object.keys(headerKeyMapping)[index]]?.toString().length || 10)
    ) + 2; // Adding some extra space for better readability
    return col;
  });

  const table = sheet.getTable(headerName);
  for (let i = 0; i < table.table.columns.length; i++) {
    sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
    sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "c5d9f1" },
    };

    for (let j = 0; j < table.table.rows.length; j++) {
      let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
      rowCell.alignment = { 
        wrapText: true, 
        horizontal: 'left'  // Ensure each cell is left-aligned
      };
      rowCell.border = {
        bottom: {
          style: "thin",
          color: { argb: "a6a6a6" },
        },
      };
    }
  }
  table.commit();

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  workbook.xlsx.writeBuffer().then((buffer) => {
    writeFile(sheetName, buffer);
  });
};

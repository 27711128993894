export const TASK_JOB_TYPE_LIST_REQUEST = "TASK_JOB_TYPE_LIST_REQUEST";
export const TASK_JOB_TYPE_LIST_SUCCESS = "TASK_JOB_TYPE_LIST_SUCCESS";
export const TASK_JOB_TYPE_LIST_FAIL = "TASK_JOB_TYPE_LIST_FAIL";

export const CHECK_LIST_REQUEST = "CHECK_LIST_REQUEST";
export const CHECK_LIST_SUCCESS = "CHECK_LIST_SUCCESS";
export const CHECK_LIST_FAIL = "CHECK_LIST_FAIL";

export const CHECK_DUPLI_LIST_REQUEST = "CHECK_DUPLI_LIST_REQUEST";
export const CHECK_DUPLI_LIST_SUCCESS = "CHECK_DUPLI_LIST_SUCCESS";
export const CHECK_DUPLI_LIST_FAIL = "CHECK_DUPLI_LIST_FAIL";

export const TASK_CREATE_REQUEST = "TASK_CREATE_REQUEST";
export const TASK_CREATE_SUCCESS = "TASK_CREATE_SUCCESS";
export const TASK_CREATE_FAIL = "TASK_CREATE_FAIL";
export const TASK_CREATE_RESET = "TASK_CREATE_RESET";

export const TASK_UPDATE_REQUEST = "TASK_UPDATE_REQUEST";
export const TASK_UPDATE_SUCCESS = "TASK_UPDATE_SUCCESS";
export const TASK_UPDATE_FAIL = "TASK_UPDATE_FAIL";
export const TASK_UPDATE_RESET = "TASK_UPDATE_RESET";

export const TASK_DETAILS_REQUEST = "TASK_DETAILS_REQUEST";
export const TASK_DETAILS_SUCCESS = "TASK_DETAILS_SUCCESS";
export const TASK_DETAILS_FAIL = "TASK_DETAILS_FAIL";
export const TASK_DETAILS_RESET = "TASK_DETAILS_RESET";

export const TASK_PENDING_CHECKLIST_DETAILS_REQUEST = "TASK_PENDING_CHECKLIST_DETAILS_REQUEST";
export const TASK_PENDING_CHECKLIST_DETAILS_SUCCESS = "TASK_PENDING_CHECKLIST_DETAILS_SUCCESS";
export const TASK_PENDING_CHECKLIST_DETAILS_FAIL = "TASK_PENDING_CHECKLIST_DETAILS_FAIL";
export const TASK_PENDING_CHECKLIST_DETAILS_RESET = "TASK_PENDING_CHECKLIST_DETAILS_RESET";

export const EMPLOYEE_TASK_LIST_REQUEST = "EMPLOYEE_TASK_LIST_REQUEST";
export const EMPLOYEE_TASK_LIST_SUCCESS = "EMPLOYEE_TASK_LIST_SUCCESS";
export const EMPLOYEE_TASK_LIST_FAIL = "EMPLOYEE_TASK_LIST_FAIL";
export const EMPLOYEE_TASK_LIST_RESET = "EMPLOYEE_TASK_LIST_RESET";

export const VEHICLE_LIST_REQUEST = "VEHICLE_LIST_REQUEST";
export const VEHICLE_LIST_SUCCESS = "VEHICLE_LIST_SUCCESS";
export const VEHICLE_LIST_FAIL = "VEHICLE_LIST_FAIL";

export const LOCATION_LIST_REQUEST = "LOCATION_LIST_REQUEST";
export const LOCATION_LIST_SUCCESS = "LOCATION_LIST_SUCCESS";
export const LOCATION_LIST_FAIL = "LOCATION_LIST_FAIL";

export const TRANSPORT_CREATE_REQUEST = "TRANSPORT_CREATE_REQUEST";
export const TRANSPORT_CREATE_SUCCESS = "TRANSPORT_CREATE_SUCCESS";
export const TRANSPORT_CREATE_FAIL = "TRANSPORT_CREATE_FAIL";
export const TRANSPORT_CREATE_RESET = "TRANSPORT_CREATE_RESET";

export const TRANSPORT_UPDATE_REQUEST = "TRANSPORT_UPDATE_REQUEST";
export const TRANSPORT_UPDATE_SUCCESS = "TRANSPORT_UPDATE_SUCCESS";
export const TRANSPORT_UPDATE_FAIL = "TRANSPORT_UPDATE_FAIL";
export const TRANSPORT_UPDATE_RESET = "TRANSPORT_UPDATE_RESET";

export const TRANSPORT_DELETE_REQUEST = "TRANSPORT_DELETE_REQUEST";
export const TRANSPORT_DELETE_SUCCESS = "TRANSPORT_DELETE_SUCCESS";
export const TRANSPORT_DELETE_FAIL = "TRANSPORT_DELETE_FAIL";
export const TRANSPORT_DELETE_RESET = "TRANSPORT_DELETE_RESET";

export const TRANSPORT_END_LOCATION_CREATE_REQUEST =
  "TRANSPORT_END_LOCATION_CREATE_REQUEST";
export const TRANSPORT_END_LOCATION_CREATE_SUCCESS =
  "TRANSPORT_END_LOCATION_CREATE_SUCCESS";
export const TRANSPORT_END_LOCATION_CREATE_FAIL =
  "TRANSPORT_END_LOCATION_CREATE_FAIL";
export const TRANSPORT_END_LOCATION_CREATE_RESET =
  "TRANSPORT_END_LOCATION_CREATE_RESET";

export const TASK_START_REQUEST = "TASK_START_REQUEST";
export const TASK_START_SUCCESS = "TASK_START_SUCCESS";
export const TASK_START_FAIL = "TASK_START_FAIL";
export const TASK_START_RESET = "TASK_START_RESET";

export const TASK_END_REQUEST = "TASK_END_REQUEST";
export const TASK_END_SUCCESS = "TASK_END_SUCCESS";
export const TASK_END_FAIL = "TASK_END_FAIL";
export const TASK_END_RESET = "TASK_END_RESET";

export const CHECKLIST_COMPLETE_REQUEST = "CHECKLIST_COMPLETE_REQUEST";
export const CHECKLIST_COMPLETE_SUCCESS = "CHECKLIST_COMPLETE_SUCCESS";
export const CHECKLIST_COMPLETE_FAIL = "CHECKLIST_COMPLETE_FAIL";
export const CHECKLIST_COMPLETE_RESET = "CHECKLIST_COMPLETE_RESET";

export const TASK_NAME_REQUEST = "TASK_NAME_REQUEST";
export const TASK_NAME_SUCCESS = "TASK_NAME_SUCCESS";
export const TASK_NAME_FAIL = "TASK_NAME_FAIL";
export const TASK_NAME_RESET = "TASK_NAME_RESET";

export const TASK_INCOMPLETE_LIST_REQUEST = "TASK_INCOMPLETE_LIST_REQUEST";
export const TASK_INCOMPLETE_LIST_SUCCESS = "TASK_INCOMPLETE_LIST_SUCCESS";
export const TASK_INCOMPLETE_LIST_FAIL = "TASK_INCOMPLETE_LIST_FAIL";

export const TASK_UNASSIGNED_LIST_REQUEST = "TASK_UNASSIGNED_LIST_REQUEST";
export const TASK_UNASSIGNED_LIST_SUCCESS = "TASK_UNASSIGNED_LIST_SUCCESS";
export const TASK_UNASSIGNED_LIST_FAIL = "TASK_UNASSIGNED_LIST_FAIL";

import React, { useState, useEffect, useRef } from "react";
import { InfoWindow } from "@react-google-maps/api";

const InfoWindowCore = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  useEffect(() => {
    if (!isComponentVisible) {
      props.setSelected("");
      setIsComponentVisible(!isComponentVisible);
    }
  }, [isComponentVisible]);

  return (
    <div ref={ref}>
      <InfoWindow
        position={props.selected.location}
        onCloseClick={() => {
          props.setSelected("");
        }}
        options={{
          pixelOffset: new window.google.maps.Size(0, -40),
        }}
      >
        <div>
          <div
            style={{
              overflow: "hidden",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {props.selected.name}
          </div>
          <div
            style={{
              overflow: "hidden",
              fontWeight: 100,
              fontSize: "13px",
            }}
          >
            {props.selected.address !== null
              ? props.selected.address
              : "No Address"}
          </div>
          {/* <h1>{selected.name}</h1> */}
          {/* <button>Close</button> */}
        </div>
      </InfoWindow>
    </div>
  );
};

export default InfoWindowCore;

const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

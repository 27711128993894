import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash } from "react-icons/bi";
import EditableField from "./EditableField";
import EditableProductField from "./EditableProductField";
import { Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useCallback } from "react";
import { Autocomplete } from "@mui/material";
import Input from "./controls/Input";
import Select from "react-select";
import "react-bootstrap-typeahead/css/Typeahead.css";

import EditProductDropdownField from "./controls/EditProductDropdownField";

class SampleItem extends React.Component {
  constructor(props) {
    super(props);

    // this.update = this.update.bind(this);
    // this.increment = this.increment.bind(this);
  }
  // update = (nextState) => {
  //   this.setState(nextState);
  // };
  // increment() {
  //   this.setState({ count: this.state.count + 1 });
  // }
  render() {
    // const { switchMappingOption } = this.state;
    // var setSwitchMappingOption = this.setState;

    var onItemizedItemEdit = this.props.onItemizedItemEdit;
    var onItemizedBatchAndMapEdit = this.props.onItemizedBatchAndMapEdit;
    var currency = this.props.currency;
    var rowDel = this.props.onRowDel;
    var escalate = this.props.escalate === 1 ? false : true;
    // var switchMappingOption = this.props.switchMappingOption;
    // var setSwitchMappingOption = this.props.setSwitchMappingOption;
    // var allOptionAuto = this.props.allOptionAuto;
    // console.log("this.props.switchMappingOption");
    // console.log(this.props.switchMappingOption);
    // console.log("escalate");
    // console.log(escalate);
    // console.log(this.props.escalate);

    var itemTable = this.props.items.map(function (item, index) {
      // console.log("item");
      // console.log(item);
      return (
        <ItemRow
          onItemizedItemEdit={onItemizedItemEdit}
          onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
          item={item}
          onDelEvent={rowDel.bind(this)}
          key={index}
          currency={currency}
          escalate={escalate}
          // switchMappingOption={this.state.switchMappingOption}
          // setSwitchMappingOption={setSwitchMappingOption}
          // allOptionAuto={allOptionAuto}
        />
      );
    });
    return (
      <div style={{ position: "relative !important" }}>
        <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
          <Table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>

                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>{itemTable}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      switchMappingOption: false,
    };
  }
  onDelEvent() {
    this.props.onDelEvent(this.props.item);
  }
  render() {
    const { switchMappingOption } = this.state;
    return (
      <tr ref={this.myRef}>
        <td style={{ width: "300px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "text",
              name: "productName",
              placeholder: "Item name",
              value: this.props.item.productName,
              id: this.props.item.productName,
            }}
            style={{ width: "200px" }}
            disabled
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "number",
              name: "quantity",

              step: "1",
              value: this.props.item.quantity,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
          />
        </td>

        {/* <td></td> */}

        <td className="text-center" style={{ minWidth: "50px" }}>
          <BiTrash
            onClick={this.onDelEvent.bind(this)}
            style={{ height: "33px", width: "33px", padding: "7.5px" }}
            className="text-white mt-1 btn btn-danger"
          />
        </td>
      </tr>
    );
  }
}

export default SampleItem;

import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  CHECKLIST_COMPLETE_FAIL,
  CHECKLIST_COMPLETE_REQUEST,
  CHECKLIST_COMPLETE_SUCCESS,
  CHECK_DUPLI_LIST_FAIL,
  CHECK_DUPLI_LIST_REQUEST,
  CHECK_DUPLI_LIST_SUCCESS,
  CHECK_LIST_FAIL,
  CHECK_LIST_REQUEST,
  CHECK_LIST_SUCCESS,
  EMPLOYEE_TASK_LIST_FAIL,
  EMPLOYEE_TASK_LIST_REQUEST,
  EMPLOYEE_TASK_LIST_SUCCESS,
  LOCATION_LIST_FAIL,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  TASK_CREATE_FAIL,
  TASK_CREATE_REQUEST,
  TASK_CREATE_SUCCESS,
  TASK_DETAILS_FAIL,
  TASK_DETAILS_REQUEST,
  TASK_DETAILS_SUCCESS,
  TASK_END_FAIL,
  TASK_END_REQUEST,
  TASK_END_SUCCESS,
  TASK_INCOMPLETE_LIST_FAIL,
  TASK_INCOMPLETE_LIST_REQUEST,
  TASK_INCOMPLETE_LIST_SUCCESS,
  TASK_JOB_TYPE_LIST_FAIL,
  TASK_JOB_TYPE_LIST_REQUEST,
  TASK_JOB_TYPE_LIST_SUCCESS,
  TASK_NAME_FAIL,
  TASK_NAME_REQUEST,
  TASK_NAME_SUCCESS,
  TASK_PENDING_CHECKLIST_DETAILS_FAIL,
  TASK_PENDING_CHECKLIST_DETAILS_REQUEST,
  TASK_PENDING_CHECKLIST_DETAILS_SUCCESS,
  TASK_START_FAIL,
  TASK_START_REQUEST,
  TASK_START_SUCCESS,
  TASK_UNASSIGNED_LIST_FAIL,
  TASK_UNASSIGNED_LIST_REQUEST,
  TASK_UNASSIGNED_LIST_SUCCESS,
  TASK_UPDATE_FAIL,
  TASK_UPDATE_REQUEST,
  TASK_UPDATE_SUCCESS,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_CREATE_REQUEST,
  TRANSPORT_CREATE_SUCCESS,
  TRANSPORT_DELETE_FAIL,
  TRANSPORT_DELETE_REQUEST,
  TRANSPORT_DELETE_SUCCESS,
  TRANSPORT_END_LOCATION_CREATE_FAIL,
  TRANSPORT_END_LOCATION_CREATE_REQUEST,
  TRANSPORT_END_LOCATION_CREATE_SUCCESS,
  TRANSPORT_UPDATE_FAIL,
  TRANSPORT_UPDATE_REQUEST,
  TRANSPORT_UPDATE_SUCCESS,
  VEHICLE_LIST_FAIL,
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
} from "../constants/taskConstants";

export const listOrderTypeTask = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_JOB_TYPE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetJobTypeInKeyValue/all`,
    };
    const { data } = await Axios(config);

    console.log("data task");
    console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: TASK_JOB_TYPE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: TASK_JOB_TYPE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listCheckForTask = () => async (dispatch, getState) => {
  try {
    // console.log("hiii");
    dispatch({ type: CHECK_LIST_REQUEST });
    dispatch({ type: CHECK_DUPLI_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Task/GetChecklists`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    // console.log("data");
    // console.log(data);
    const final = data.dataObj.data.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    console.log("final");
    console.log(final);
    dispatch({
      type: CHECK_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
    dispatch({
      type: CHECK_DUPLI_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: CHECK_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch({
      type: CHECK_DUPLI_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FilterChecksForTask =
  (FilteredProduct) => async (dispatch, getState) => {
    try {
      // console.log("hiii");
      // dispatch({ type: CHECK_LIST_REQUEST });

      const {
        userLogin: { userInfo },
        taskCheckList: { checks },
        taskCheckListDupli: { checksDupli },
      } = getState();

      // console.log("productsDupli");
      // console.log(productsDupli);
      console.log("FilteredProduct");
      console.log(FilteredProduct);

      console.log("checksDupli");
      console.log(checksDupli);

      var filteredResult = checksDupli.filter((singleData) => {
        return !FilteredProduct.includes(singleData.id);
      });
      console.log("hiii");
      console.log("filteredResult");
      console.log(filteredResult);

      dispatch({
        type: CHECK_LIST_SUCCESS,
        payload: filteredResult.sort((a, b) => a.label.localeCompare(b.label)),
      });
    } catch (error) {
      dispatch({
        type: CHECK_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createTask = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/Task/AddTask`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: TASK_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: TASK_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: TASK_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTask = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/Task/UpdateTask`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: TASK_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: TASK_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: TASK_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listTaskDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: TASK_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Task/GetTaskDetails/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    console.log("data[0]");
    console.log(data);

    dispatch({ type: TASK_DETAILS_SUCCESS, payload: data.dataObj.data });
    return data.dataObj.data;
  } catch (error) {
    dispatch({
      type: TASK_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listTaskPendingChecklistDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: TASK_PENDING_CHECKLIST_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Task/GetCompleteTasksHavingIncompleteChecklistDetails/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log("data[0]");
    // console.log(data.dataObj.data);

    dispatch({ type: TASK_PENDING_CHECKLIST_DETAILS_SUCCESS, payload: data.dataObj.data });
    return data.dataObj.data;
  } catch (error) {
    dispatch({
      type: TASK_PENDING_CHECKLIST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployeeTask = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: EMPLOYEE_TASK_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Task/GetTasks/0/-1`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log("data");
    // console.log(data);

    dispatch({ type: EMPLOYEE_TASK_LIST_SUCCESS, payload: data.dataObj.data });
    return data.dataObj;
  } catch (error) {
    dispatch({
      type: EMPLOYEE_TASK_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listVehicle = () => async (dispatch, getState) => {
  try {
    dispatch({ type: VEHICLE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Task/GetVehicles`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    // console.log("data task");
    // console.log(data);
    const final = data.dataObj.data.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: VEHICLE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listLocation = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Location/GetLocations`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    // console.log("data task");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.locationId,
        label: single.locationName,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: LOCATION_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: LOCATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTransporation = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRANSPORT_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/Task/AddTaskTransportation/${data.taskId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: TRANSPORT_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: TRANSPORT_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: TRANSPORT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTransportation =
  (data, id) => async (dispatch, getState) => {
    console.log("data");
    console.log(data);
    try {
      dispatch({ type: TRANSPORT_UPDATE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/Task/UpdateTaskTransportation/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: TRANSPORT_UPDATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: TRANSPORT_UPDATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });

      return finalGotData;
    } catch (error) {
      dispatch({
        type: TRANSPORT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteTransportation =
  (taskId, transportationId) => async (dispatch, getState) => {
    try {
      dispatch({ type: TRANSPORT_DELETE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "delete",

        url: `${API_URL}/Task/DeleteTaskTransportation/${transportationId}/${taskId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await Axios(config);
      dispatch({ type: TRANSPORT_DELETE_SUCCESS });
      //
    } catch (error) {
      dispatch({
        type: TRANSPORT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createTransporationEndLocation =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: TRANSPORT_END_LOCATION_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/Task/AddTransportationEndLocation/${data.taskId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: TRANSPORT_END_LOCATION_CREATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: TRANSPORT_END_LOCATION_CREATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: TRANSPORT_END_LOCATION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const startTask = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_START_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Task/DeclareTaskStart/${data}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: TASK_START_SUCCESS,
          payload: response.data.dataObj.data,
        });
        return response.data.dataObj.data;
      })
      .catch(function (error) {
        dispatch({
          type: TASK_START_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: TASK_START_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const endTask = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_END_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Task/DeclareTaskCompletion/${data}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: TASK_END_SUCCESS,
          payload: response.data.dataObj.data,
        });
        return response.data.dataObj.data;
      })
      .catch(function (error) {
        dispatch({
          type: TASK_END_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: TASK_END_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const completeCheckList = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKLIST_COMPLETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Task/DeclareChecklistCompletion/${data}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: CHECKLIST_COMPLETE_SUCCESS,
          payload: response.data.dataObj.data,
        });
        return response.data.dataObj.data;
      })
      .catch(function (error) {
        dispatch({
          type: CHECKLIST_COMPLETE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: CHECKLIST_COMPLETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTaskName = () => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: TASK_NAME_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Task/GetTaskName`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log(data[0]);

    dispatch({ type: TASK_NAME_SUCCESS, payload: data.dataObj.data });
    return data.dataObj;
  } catch (error) {
    dispatch({
      type: TASK_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listTaskIncomplete = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_INCOMPLETE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Task/GetTasks/0/0`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({
      type: TASK_INCOMPLETE_LIST_SUCCESS,
      payload: data.dataObj.data,
    });
  } catch (error) {
    dispatch({
      type: TASK_INCOMPLETE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listAssignedTask = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_INCOMPLETE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Task/GetAssignedTaskList`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({
      type: TASK_INCOMPLETE_LIST_SUCCESS,
      payload: data.dataObj.data,
    });
  } catch (error) {
    dispatch({
      type: TASK_INCOMPLETE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listIncompleteChecklistTask =
  (order) => async (dispatch, getState) => {
    try {
      dispatch({ type: TASK_INCOMPLETE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/Task/GetCompleteTasksHavingIncompleteChecklist`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      // console.log("data");
      // console.log(data);
      dispatch({
        type: TASK_INCOMPLETE_LIST_SUCCESS,
        payload: data.dataObj.data,
      });
    } catch (error) {
      dispatch({
        type: TASK_INCOMPLETE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listTaskUnAssigned = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_UNASSIGNED_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockOut/StockOutOrderGridData/${order}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    console.log("data");
    console.log(data);
    dispatch({
      type: TASK_UNASSIGNED_LIST_SUCCESS,
      payload: data.dataObj,
    });
  } catch (error) {
    dispatch({
      type: TASK_UNASSIGNED_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import { v4 } from "uuid";
import { storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useMediaQuery } from "react-responsive";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
  IconButton,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import ButtonGroup from "@mui/material/ButtonGroup";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import Geocode from "react-geocode";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Button from "../../core/controls/Button";
import {
  createProduct,
  listCategories,
  mappedListProducts,
  mapProduct,
} from "../../redux/actions/productActions";

import { GeoLocationSchema } from "../../schemas";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_MAP_RESET,
} from "../../redux/constants/productConstants";
import Notification from "../../core/Notification";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import {
  listCustomerDetails,
  updateCustomer,
  updateCustomerGeo,
} from "../../redux/actions/customerActions";
import Popup from "../../core/Popup";
import { CUSTOMER_UPDATE_RESET } from "../../redux/constants/customerConstants";

import "./Customer.css";
import { Circles, Dna } from "react-loader-spinner";
import SuccessPopup from "../../core/SuccessPopup";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(0.7),
    padding: useTheme().spacing(1),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
  input: {
    display: "none",
  },
  imgBox: {
    maxWidth: "80%",
    maxHeight: "80%",
    margin: "10px",
    border: "none !important",
    width: "300px !important",
    margin: "10px auto 0px auto",
  },
  img: {
    maxWidth: "500px !important",
    maxHeight: "280px !important",
    objectFit: "cover",
    borderRadius: "10px",
    boxShadow: `inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3)`,
  },
}));

const initialValues = {
  customer: {
    id: "",
    label: "",
  },
  typeAddress: "",
  customerLat: "",
  customerLong: "",
};

Geocode.setApiKey("AIzaSyBv2aartIPeHJJVpXF0NDOR9fyzqzgMyVI");
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const GeoLocation = ({ history }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [imageUpload, setImageUpload] = useState(null);
  const [source, setSource] = useState("");
  const classes = useStyles();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  // console.log(customers);
  const customerDetails = useSelector((state) => state.customerDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    customer,
  } = customerDetails;

  const customerUpdate = useSelector((state) => state.customerUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = customerUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const ref2 = useRef();
  // console.log(isMobile);
  const formik = useFormik({
    initialValues,
    validationSchema: GeoLocationSchema,
    onSubmit: (values, action) => {
      // console.log(customer);
      const successCallback = (position) => {
        // console.log(position.coords);
        var lat = position.coords.latitude.toFixed(6);
        var long = position.coords.longitude.toFixed(6);
        Geocode.fromLatLng(lat, long)
          .then((response) => {
            const address = response.results[0].formatted_address;
            let city, state, country;
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = response.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country =
                      response.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            // console.log(city, state, country);
            // console.log(address);
            if (imageUpload === null) {
              dispatch(
                updateCustomerGeo({
                  customerId: values.customer.id,
                  customerLat: lat,
                  customerLong: long,
                  androidAddress: address,
                  typeAddress: values.typeAddress,
                  locationImgRef: null,
                })
              ).then((result) => {
                if (result?.errMsg) {
                  // console.log(result);
                  if (result.errMsg == "Customer Already Exist") {
                    action.setFieldError("errorClientName", result.errMsg);
                  } else if (result.errMsg == "Contact Number Already Exist") {
                    action.setFieldError("errorClientPhone", result.errMsg);
                  }

                  action.setSubmitting(false);
                } else {
                  action.resetForm();
                  setSource("");
                  setImageUpload(null);

                  setChecked(true);
                  ref2.current.value = "";

                  setOpenPopup2({
                    ...openPopup2,
                    title: `SUCCESS!!!`,
                    subTitle: `factory Location Updated.`,
                    isOpen: true,
                  });
                }
              });
              return;
            }
            const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
            uploadBytes(imageRef, imageUpload).then((response) => {
              // alert("Image Uploaded");

              getDownloadURL(response.ref).then((url) => {
                dispatch(
                  updateCustomerGeo({
                    customerId: values.customer.id,
                    customerLat: lat,
                    customerLong: long,
                    androidAddress: address,
                    typeAddress: values.typeAddress,
                    locationImgRef: url,
                  })
                ).then((result) => {
                  if (result?.errMsg) {
                    // console.log(result);
                    if (result.errMsg == "Customer Already Exist") {
                      action.setFieldError("errorClientName", result.errMsg);
                    } else if (
                      result.errMsg == "Contact Number Already Exist"
                    ) {
                      action.setFieldError("errorClientPhone", result.errMsg);
                    }

                    action.setSubmitting(false);
                  } else {
                    setSource("");
                    setImageUpload(null);

                    setChecked(true);

                    setOpenPopup2({
                      ...openPopup2,
                      title: `SUCCESS!!!`,
                      subTitle: `factory Location Updated.`,
                      isOpen: true,
                    });
                    action.resetForm();
                    ref2.current.value = "";
                  }
                });
              });
            });
          })
          .catch((error) => console.error(error));
      };

      const errorCallback = (error) => {
        console.log(error);
        setOpenPopup({
          ...openPopup,
          title: `${error.message}!!!`,
          subTitle: `Geolocation not enabled. Please Enable to use this feature..`,
          isOpen: true,
        });
        action.setSubmitting(false);
      };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      //   if (navigator.geolocation) {
      //     console.log(values);
      //   }
      //   navigator.geolocation.getCurrentPosition(function (position, error) {
      //     console.log(error);
      //   });
      //   navigator.permissions
      //     .query({ name: "geolocation" })
      //     .then(function (result) {
      //       // Will return ['granted', 'prompt', 'denied']
      //       console.log(result);
      //       if (result.state === "denied") {
      //       }
      //     });

      //   dispatch(
      //     mapProduct({
      //       productId: values.targetProduct.id,
      //       mappedWith: values.mappedProduct.id,
      //     })
      //   ).then(() => {
      //     // console.log("checked");
      //     // console.log(checked);
      //     action.resetForm();
      //   });
    },
  });

  const changeValues = () => {
    // console.log(JSON.parse(customer));
    if (JSON.stringify(customer) !== "{}") {
      formik.setFieldValue(
        "customerAddress",
        customer.customerAddress || "Not Listed"
      );
      formik.setFieldValue("customerLat", customer.customerLat || "");
      formik.setFieldValue("customerLong", customer.customerLong || "");
    }
  };

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setImageUpload(target.files[0]);
        setSource(newUrl);
      }
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Location Updated",
          type: "success",
        });

        dispatch({ type: CUSTOMER_UPDATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/geolocation" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successUpdate]);

  useEffect(() => {
    changeValues();
  }, [customer]);

  const GetCustomerDetails = (event, values) => {
    dispatch(listCustomerDetails(values.id));
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
    GetCustomerDetails(e, v);
    // dispatch({ type: EMPLOYEE_DETAILS_RESET });

    // if (v.id === 1) {
    //   GetEmployees(e, v);
    //   GetEmployeeRoles(e, v);
    // }
  });

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  // console.log("formik.values.uploadImage");
  console.log("source");
  console.log(source);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<EditLocationIcon />}
        title="Location"
        subtitle="Update your location"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form
          onSubmit={(e) => {
            formik.handleSubmit(e);
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingCustomer ? true : false}
                id="combo-box-demo1"
                isOptionEqualToValue={customerOptionCheck}
                onChange={customerChange}
                value={formik.values.customer}
                options={customers !== undefined ? customers : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Client List"
                    name="customer"
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                freeSolo={true}
                className={classes.inputWidthSetter}
                // noOptionsText={
                //   showOptionMessage ? "No Option" : "continue typing.."
                // }
              />
              <Input
                label="Factory Address"
                name="typeAddress"
                multiline
                error={formik.errors.typeAddress}
                touched={formik.touched.typeAddress}
                value={formik.values.typeAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  onChange={(e) => handleCapture(e.target)}
                  disabled={isMobile ? false : true}
                />
                <label
                  htmlFor="icon-button-file"
                  style={{ opacity: isMobile ? 1 : "0.1" }}
                  onClick={() => {
                    if (!isMobile) {
                      setOpenPopup({
                        ...openPopup,
                        title: `DISABLED!!!`,
                        subTitle: `This feature is available to mobiles only.`,
                        isOpen: true,
                      });
                    }
                  }}
                >
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCameraRoundedIcon fontSize="large" color="primary" />
                  </IconButton>
                </label>
                <span>OR</span>
                <input
                  accept="image/*"
                  style={{ marginLeft: "8px", marginTop: "6px", width: "77%" }}
                  type="file"
                  onChange={(e) => {
                    // console.log(e.target.files[0]);
                    const file = e.target.files[0];
                    const newUrl = URL.createObjectURL(file);
                    setSource(newUrl);
                    setImageUpload(e.target.files[0]);
                  }}
                  ref={ref2}
                />
              </div>
              <Grid item xs={12} md={12}>
                <ButtonGroup className={classes.submitButtonGroup}>
                  <MuiButton
                    endIcon={
                      loadingUpdate ? (
                        <CircularProgress size="1rem" color="error" />
                      ) : (
                        <PublishIcon />
                      )
                    }
                    size="large"
                    type="submit"
                  >
                    Update your location
                  </MuiButton>
                  <MuiButton
                    size="small"
                    startIcon={<ReplayIcon />}
                    onClick={() => {
                      formik.resetForm();
                      setSource("");
                      setImageUpload(null);

                      setChecked(true);
                      ref2.current.value = "";
                    }}
                  />
                </ButtonGroup>
                <Button
                  color="error"
                  text="Back"
                  onClick={() => {
                    const location = {
                      pathname: "/dashboard",
                      state: {},
                    };
                    history.push(location);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {source !== "" ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  border={1}
                  className={classes.imgBox}
                >
                  <img src={source} alt={"snap"} className={classes.img}></img>
                </Box>
              ) : (
                <></>
              )}
            </Grid>
            {/* <Grid item xs={12} md={12}></Grid> */}
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loadingCustomer ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default GeoLocation;

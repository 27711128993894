export const DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_REQUEST = "DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_REQUEST";
export const DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_SUCCESS = "DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_SUCCESS";
export const DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_FAIL = "DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_FAIL";

export const DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_REQUEST = "DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_REQUEST";
export const DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_SUCCESS = "DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_SUCCESS";
export const DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_FAIL = "DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_FAIL";


export const DASHBOARD_CALENDER_SALES_INFO_REQUEST = "DASHBOARD_CALENDER_SALES_INFO_REQUEST";
export const DASHBOARD_CALENDER_SALES_INFO_SUCCESS = "DASHBOARD_CALENDER_SALES_INFO_SUCCESS";
export const DASHBOARD_CALENDER_SALES_INFO_FAIL = "DASHBOARD_CALENDER_SALES_INFO_FAIL";


export const DASHBOARD_CUSTOMER_SALES_INFO_REQUEST = "DASHBOARD_CUSTOMER_SALES_INFO_REQUEST";
export const DASHBOARD_CUSTOMER_SALES_INFO_SUCCESS = "DASHBOARD_CUSTOMER_SALES_INFO_SUCCESS";
export const DASHBOARD_CUSTOMER_SALES_INFO_FAIL = "DASHBOARD_CUSTOMER_SALES_INFO_FAIL";


export const DASHBOARD_PRODUCT_SALES_INFO_REQUEST = "DASHBOARD_PRODUCT_SALES_INFO_REQUEST";
export const DASHBOARD_PRODUCT_SALES_INFO_SUCCESS = "DASHBOARD_PRODUCT_SALES_INFO_SUCCESS";
export const DASHBOARD_PRODUCT_SALES_INFO_FAIL = "DASHBOARD_PRODUCT_SALES_INFO_FAIL";
import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
  FormGroup,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import {
  ChequeSchema,
  InhouseEmployeeSchema,
  PaymentSchema,
  TaskSchema,
} from "../../schemas";
import { createEmployee } from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import { API_URL } from "../../redux/constants/apiConstants";
import { EMPLOYEE_CREATE_RESET } from "../../redux/constants/employeeConstants";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDeparment,
  listDesignation,
} from "../../redux/actions/channelActions";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  listCustomersForSignup,
  listEmployees,
  listEmployeesForDropdown,
} from "../../redux/actions/userActions";
import {
  createCheque,
  listBank,
  listChequeDetails,
  listChequeStatus,
  listChequeType,
} from "../../redux/actions/chequeActions";
import {
  CHEQUE_CREATE_RESET,
  CHEQUE_DETAILS_RESET,
} from "../../redux/constants/chequeConstants";
import {
  createPayment,
  listInvoiceCheckKeyValue,
  listInvoiceDetails,
  listPaymentMode,
} from "../../redux/actions/paymentActions";
import {
  INVOICE_DETAILS_RESET,
  PAYMENT_CREATE_RESET,
} from "../../redux/constants/paymentConstants";
import {
  FilterChecksForTask,
  createTask,
  fetchTaskName,
  listCheckForTask,
  listOrderTypeTask,
} from "../../redux/actions/taskActions";
import Popup from "../../core/Popup";
import useTable from "../../core/useTable";
import ActionButton from "../../core/controls/ActionButton";
import { listSalesOrderInternalDetails } from "../../redux/actions/invoiceActions";
import { SALESORDERINTERNAL_DETAILS_RESET } from "../../redux/constants/invoiceConstants";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
  pageContentForTable: {
    overflowX: "auto",
    overflowY: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
    "& ::-webkit-scrollbar": {
      width: "0.3rem",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#F2F1F1",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  isInvoice: true,
  employee: {
    id: "",
    label: "",
  },
  invoiceRelatedTask: "0",
  taskName: "",
  taskDate: [date.getFullYear(), mnth, day].join("-"),
  invoiceCheque: {
    id: "",
    label: "",
  },
  taskDescription: "",
  destinationName: "",
  destinationAddress: "",
  jobType: {
    id: "",
    label: "",
  },
  check: {
    id: "",
    label: "",
  },
};

const headCells = [
  {
    id: "serialno",
    label: "S.No.",
  },
  {
    id: "name",
    label: "Checklist Name",
  },

  { id: "actions", label: "Action", disableSorting: true },
];

var FilteredChecklist = [];
const AddTaskInvoiceFree = ({ history }) => {
  // const systemId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [order, setOrder] = useState("1");
  const [data, setData] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  // const { values, setValues, handleChange } = useForm(initialValues);

  console.log("order");
  console.log(order);

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

  const taskJobTypeListReducer = useSelector(
    (state) => state.taskJobTypeListReducer
  );
  const {
    loading: loadingTaskJobType,
    error: errorTaskJobType,
    jobTypes,
  } = taskJobTypeListReducer;

  const invoiceChequeList = useSelector((state) => state.invoiceChequeList);
  const {
    loading: loadingInvoiceCheque,
    error: errorInvoiceCheque,
    invoicecheques,
  } = invoiceChequeList;

  const paymentModeList = useSelector((state) => state.paymentModeList);
  const {
    loading: loadingPaymentMode,
    error: errorPaymentMode,
    paymentmodes,
  } = paymentModeList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    employees,
  } = employeeList;

  const chequeTypeList = useSelector((state) => state.chequeTypeList);
  const {
    loading: loadingChequeType,
    error: errorChequeType,
    chequeTypes,
  } = chequeTypeList;

  const invoiceDetailsReducer = useSelector(
    (state) => state.invoiceDetailsReducer
  );
  const {
    loading: loadingInvoiceDetailsReducer,
    error: errorInvoiceDetailsReducer,
    invoiceSingle,
  } = invoiceDetailsReducer;

  const chequeDetails = useSelector((state) => state.chequeDetails);
  const {
    loading: loadingChequeDetails,
    error: errorChequeDetails,
    chequeSingle,
  } = chequeDetails;
  const taskCheckList = useSelector((state) => state.taskCheckList);
  const { loading: loadingChecks, error: errorChecks, checks } = taskCheckList;
  const bankList = useSelector((state) => state.bankList);
  const { loading: loadingBanks, error: errorBanks, banks } = bankList;

  const chequeStatusList = useSelector((state) => state.chequeStatusList);
  const {
    loading: loadingChequeStatus,
    error: errorChequeStatus,
    chequestatuss,
  } = chequeStatusList;

  const channelList = useSelector((state) => state.channelList);
  const { loading: loadingChannels, error, channels } = channelList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const taskCreate = useSelector((state) => state.taskCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    task: createdPayment,
  } = taskCreate;
  // console.log(loadingCreate);
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(data, headCells, filterFn);

  const formik = useFormik({
    initialValues,
    validationSchema: TaskSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      let checkListIds = [];
      if (data.length > 0) {
         checkListIds = data.map((single) => {
          return single.id;
        });
        dispatch(
          createTask({
            taskId: 25,
            taskName: values.taskName,
            taskDescription: values.taskDescription,
            taskDestination: values.destinationName,
            taskDestinationAddress: values.destinationAddress,
            taskJobTypeId: null,
            taskRelatedInvoiceId:
              values.invoiceCheque.id !== "" ? values.invoiceCheque.id : null,
            taskRelatedInvoiceName:
              values.invoiceCheque.label !== ""
                ? values.invoiceCheque.label
                : null,
            taskAssignedTo: values.employee.id,
            taskRelatedTaskId: values.invoiceRelatedTask,
            taskChecklists: checkListIds,
          })
        ).then((result) => {
          if (result?.title === "One or more validation errors occurred.") {
            setOpenPopup({
              ...openPopup,
              title: "ERROR!!!",
              subTitle: "Something went wrong!",
              isOpen: true,
            });
            // action.setFieldError("error", result.errMsg);
            action.setSubmitting(false);
          } else if (result?.errMsg) {
            // console.log(result);
            if (result.errMsg == "Customer Already Exist") {
              action.setFieldError("errorClientName", result.errMsg);
            } else if (result.errMsg == "Contact Number Already Exist") {
              action.setFieldError("errorClientPhone", result.errMsg);
            }

            action.setSubmitting(false);
          } else {
            if (checked === true) {
              FilteredChecklist = [];
              action.resetForm();
              setData([]);
            } else {
              FilteredChecklist = [];
              history.push("/task/incompletelist");
            }
          }
        });
      } else {
        setOpenPopup({
          ...openPopup,
          title: "EMPTY FIELD(S)!!!",
          subTitle: "Please Select a Checklist!",
          isOpen: true,
        });
      }
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      dispatch(fetchTaskName()).then((res) => {
        // console.log("res");
        // console.log(res);
        formik.setFieldValue("taskName", res.data);
      });

      dispatch(listInvoiceCheckKeyValue(order));

      dispatch(listPaymentMode(2));
      dispatch(listOrderTypeTask(-1));
      dispatch(listCheckForTask());
      dispatch(listEmployeesForDropdown());
      dispatch(listChequeStatus());
      dispatch(listBank());
      dispatch(listChequeType());
      dispatch(listDesignation());
      dispatch(listChannel());
      dispatch(listDeparment());
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: PAYMENT_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/task/create" } },
      };
      history.push(location);
    }
  }, [order, dispatch, history, userInfo, successCreate]);

  const changeInvoiceValues = () => {
    if (JSON.stringify(invoiceSingle) !== "{}" && invoiceSingle !== undefined) {
      formik.setFieldValue("invoiceNumber", invoiceSingle.soSystemNo || "");
      formik.setFieldValue("invoiceAmount", invoiceSingle.sobFinalBill || "");
      formik.setFieldValue("customer", {
        id: invoiceSingle.customerId || "",
        label: invoiceSingle.customerName || "",
      });
    }
  };

  useEffect(() => {
    changeInvoiceValues();
  }, [invoiceSingle]);

  const changeChequeValues = () => {
    // console.log("employee");
    // console.log(employee);
    if (JSON.stringify(chequeSingle) !== "{}" && chequeSingle !== undefined) {
      var date = new Date(chequeSingle.chequeCollectionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeCollectionDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeExpireDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeExpireDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequePlacementDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequePlacementDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeActionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeActionDate = [date.getFullYear(), mnth, day].join("-");
      // console.log(d);

      formik.setFieldValue("chequeCollectionDate", chequeCollectionDate || "");
      formik.setFieldValue("chequeDate", chequeDate || "");
      formik.setFieldValue("chequeExpireDate", chequeExpireDate || "");
      formik.setFieldValue("chequePlacementDate", chequePlacementDate || "");
      formik.setFieldValue("chequeActionDate", chequeActionDate || "");
      formik.setFieldValue("chequeNumber", chequeSingle.chequeNumber || "");
      formik.setFieldValue("chequeAmount", chequeSingle.chequeAmount || "");
      //   formik.setFieldValue("employee", {
      //     id: chequeSingle.employeeId === 0 ? "" : chequeSingle.employeeId,

      //     label:
      //       chequeSingle.employeeId === 0
      //         ? ""
      //         : chequeSingle.empFirstName !== null &&
      //           chequeSingle.empLastName !== null &&
      //           chequeSingle.employeeCode !== null
      //         ? chequeSingle.empFirstName +
      //             " " +
      //             chequeSingle.empLastName +
      //             "-" +
      //             chequeSingle.employeeCode || ""
      //         : "",
      //   });
      formik.setFieldValue("chequeType", {
        id: chequeSingle.chequeMethodInfoId || "",
        label: chequeSingle.paymentMethodName || "",
      });
      formik.setFieldValue("customer", {
        id: chequeSingle.chequeCustomerId || "",
        label: chequeSingle.customerName || "",
      });
      formik.setFieldValue("bank", {
        id: chequeSingle.chequeBeneficiaryBankId || "",
        label: chequeSingle.beneficiaryBankName || "",
      });
      formik.setFieldValue("issuerBank", {
        id: chequeSingle.chequeIssuerBankId || "",
        label: chequeSingle.chequeBankName || "",
      });
      formik.setFieldValue("chequeStatus", {
        id: chequeSingle.chequeStatusId || "",
        label: chequeSingle.paymentChequeStatusName || "",
      });
    }
  };

  useEffect(() => {
    changeChequeValues();
  }, [chequeSingle]);

  const channelChange = useCallback((e, v) => {
    formik.setFieldValue("channel", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const departmentChange = useCallback((e, v) => {
    formik.setFieldValue("department", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const jobTypeChange = useCallback((e, v) => {
    formik.setFieldValue("jobType", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const checkChange = useCallback((e, v) => {
    formik.setFieldValue("check", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeTypeChange = useCallback((e, v) => {
    formik.setFieldValue("chequeType", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const paymentModeChange = useCallback((e, v) => {
    formik.setFieldValue("paymentMode", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeChange = useCallback((e, v) => {
    formik.setFieldValue("invoiceCheque", {
      id: v?.id || "",
      label: v?.label || "",
    });

    if (v?.id === undefined) {
      formik.setFieldValue("destinationName", "");
      formik.setFieldValue("destinationAddress", "");
    } else if (v?.id !== undefined && order === "0") {
      dispatch(listSalesOrderInternalDetails(v?.id)).then((res) => {
        console.log("res details");
        console.log(res);
        formik.setFieldValue(
          "destinationName",
          res.stockOutGeneralData.customerName
        );
        formik.setFieldValue(
          "destinationAddress",
          res.stockOutGeneralData.customerAddress
        );
      });
      dispatch(listInvoiceDetails(v?.id));
    } else if (v?.id !== undefined && order === "1") {
      dispatch(listChequeDetails(v?.id));
    }
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeStatusChange = useCallback((e, v) => {
    formik.setFieldValue("chequeStatus", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const bankChange = useCallback((e, v) => {
    formik.setFieldValue("bank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const issuerBankChange = useCallback((e, v) => {
    formik.setFieldValue("issuerBank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const chequeStatusOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const bankOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  console.log("employees");
  console.log(employees);

  const handleAddEvent = (evt) => {
    var items = {
      id: "",
      name: "",
      serialno: "",
    };
    if (formik.values.check.id !== "") {
      data.push({
        id: formik.values.check.id,
        name: formik.values.check.label,
      });
      var newData = data.map((data, i) => {
        return {
          ...data,
          serialno: i + 1,
        };
      });
      setData(newData);
      FilteredChecklist.push(formik.values.check.id);
      console.log("FilteredChecklist");
      console.log(FilteredChecklist);
      dispatch(FilterChecksForTask(FilteredChecklist));
      formik.setFieldValue("check", {
        id: "",
        label: "",
      });
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "Please Select a Checklist!",
        isOpen: true,
      });
    }
  };

  const handleRowDel = (items) => {
    console.log("selected item");
    console.log(items);
    FilteredChecklist = FilteredChecklist.filter((item) => {
      return item !== items;
    });
    console.log("FilteredChecklist");
    console.log(FilteredChecklist);

    dispatch(FilterChecksForTask(FilteredChecklist));
    var index = data.findIndex((object) => {
      return object.id === items;
    });
    data.splice(index, 1);
    var newData = data.map((data, i) => {
      return {
        ...data,
        serialno: i + 1,
      };
    });
    setData(newData);
  };
  console.log("order");
  console.log(order);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Tasks"
        subtitle="Add a Task that is invoice free"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              {order === "0" && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingInvoiceCheque ? true : true}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={invoiceChequeCheck}
                  onChange={invoiceChequeChange}
                  value={formik.values.invoiceCheque}
                  options={invoicecheques !== undefined ? invoicecheques : []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label={order === "0" ? "Invoice No" : "Cheque No"}
                      name="invoiceCheque"
                      error={
                        formik.errors.invoiceCheque !== undefined
                          ? formik.errors.invoiceCheque.id
                          : ""
                      }
                      touched={
                        formik.touched.invoiceCheque !== undefined
                          ? formik.touched.invoiceCheque
                          : ""
                      }
                      {...params}
                      // style={{ width: "98%" }}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}

              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingEmployee ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={employeeChange}
                value={formik.values.employee}
                options={employees !== undefined ? employees : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Assign To"
                    name="employee"
                    error={
                      formik.errors.employee !== undefined
                        ? formik.errors.employee.id
                        : ""
                    }
                    touched={
                      formik.touched.employee !== undefined
                        ? formik.touched.employee
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              {/* <Select
                variant="outlined"
                size="small"
                label="Invoice Related Task"
                name="invoiceRelatedTask"
                error={formik.errors.invoiceRelatedTask}
                touched={formik.touched.invoiceRelatedTask}
                value={formik.values.invoiceRelatedTask}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue(
                    "isInvoice",
                    e.target.value === "0" ? true : false
                  );
                  setOrder(e.target.value);

                  // formik.setFieldValue("employee", {
                  //   id: "",
                  //   label: "",
                  // });
                  formik.setFieldValue(
                    "taskDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue("invoiceCheque", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("taskDescription", "");
                  formik.setFieldValue("destinationName", "");
                  formik.setFieldValue("destinationAddress", "");
                  formik.setFieldValue("check", {
                    id: "",
                    label: "",
                  });
                  dispatch({ type: SALESORDERINTERNAL_DETAILS_RESET });
                }}
                onBlur={formik.handleBlur}
                options={[
                  {
                    id: "0",
                    title: "Yes",
                  },
                  {
                    id: "1",
                    title: "No",
                  },
                ]}
              /> */}
              <Input
                label="Task Name"
                name="taskName"
                error={formik.errors.taskName}
                touched={formik.touched.taskName}
                value={formik.values.taskName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Datepicker
                label="Task Date"
                name="taskDate"
                value={formik.values.taskDate}
                onChange={formik.handleChange}
              />

              {/* {order === "0" && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingInvoiceCheque ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={invoiceChequeCheck}
                  onChange={invoiceChequeChange}
                  value={formik.values.invoiceCheque}
                  options={invoicecheques !== undefined ? invoicecheques : []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label={order === "0" ? "Invoice No" : "Cheque No"}
                      name="invoiceCheque"
                      error={
                        formik.errors.invoiceCheque !== undefined
                          ? formik.errors.invoiceCheque.id
                          : ""
                      }
                      touched={
                        formik.touched.invoiceCheque !== undefined
                          ? formik.touched.invoiceCheque
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )} */}

              <Input
                label="Task Description"
                name="taskDescription"
                error={formik.errors.taskDescription}
                touched={formik.touched.taskDescription}
                value={formik.values.taskDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
              />

              {/* <Autocomplete
                disablePortal
                size="small"
                disabled={loadingTaskJobType ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={jobTypeChange}
                value={formik.values.jobType}
                options={jobTypes !== undefined ? jobTypes : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Job Type"
                    name="jobType"
                    error={
                      formik.errors.jobType !== undefined
                        ? formik.errors.jobType.id
                        : ""
                    }
                    touched={
                      formik.touched.jobType !== undefined
                        ? formik.touched.jobType
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              /> */}
              {/* <FormGroup row> */}

              {/* </FormGroup> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Destination Name"
                name="destinationName"
                error={formik.errors.destinationName}
                touched={formik.touched.destinationName}
                value={formik.values.destinationName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ width: "98%" }}
                disabled={order === "0" ? true : false}
              />
              <Input
                label="Destination Address"
                name="destinationAddress"
                error={formik.errors.destinationAddress}
                touched={formik.touched.destinationAddress}
                value={formik.values.destinationAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                style={{ width: "98%" }}
                disabled={order === "0" ? true : false}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingChecks ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={useCallback(
                    (option, value) => option.value === value.value
                  )}
                  onChange={checkChange}
                  value={formik.values.check}
                  options={checks !== undefined ? checks : []}
                  onOpen={formik.handleBlur}
                  style={{ flex: 1 }}
                  renderInput={(params) => (
                    <Input
                      label="Checklist"
                      name="check"
                      error={
                        formik.errors.check !== undefined
                          ? formik.errors.check.id
                          : ""
                      }
                      touched={
                        formik.touched.check !== undefined
                          ? formik.touched.check
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                      style={{ width: "94%" }}
                    />
                  )}
                />
                <Button
                  color="success"
                  text="Add"
                  size="small"
                  endIcon={<AddCircleOutlineIcon />}
                  style={{ marginLeft: "8px" }}
                  onClick={handleAddEvent}
                />
              </div>
              <Paper
                className={classes.pageContentForTable}
                sx={{ width: "100%", overflow: "hidden" }}
              >
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {data.length > 0 ? (
                      recordsAfterPagingAndSorting().map((item, i) => (
                        <TableRow key={i}>
                          <TableCell>{item.serialno}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                color="error"
                                onClick={() => {
                                  handleRowDel(item.id);
                                  //   setSelectedActivities(
                                  //     selectedActivities.filter((single) => {
                                  //       return (
                                  //         single.mktActivityIdToShow !==
                                  //         item.mktActivityIdToShow
                                  //       );
                                  //     })
                                  //   );
                                  //   // ConcatActivity.push(item.mktActivityId);
                                  //   FilteredActivity.push(item.mktActivityId);
                                  //   FilteredActivity = FilteredActivity.filter(
                                  //     (single) => {
                                  //       return single != item.mktActivityId;
                                  //     }
                                  //   );
                                  //   console.log("FilteredActivityhere");
                                  //   console.log(FilteredActivity);
                                  //   dispatch(
                                  //     FilterListActivity(FilteredActivity)
                                  //   ).then(() => {
                                  //     formik.setFieldValue("activity", {
                                  //       id: item?.mktActivityId || "",
                                  //       label: item?.mktActivityLabel || "",
                                  //     });
                                  //     formik.setFieldValue(
                                  //       "voiceOfCustomer",
                                  //       item?.voiceOfCustomer || ""
                                  //     );
                                  //   });
                                  //   setCount(count - 1);
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </ActionButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>

                        <TableCell>No Data</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </TblContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                    setData([]);
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/task/incompletelist",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
              loadingChannels && loadingDesignations && loadingDepartments
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(AddTaskInvoiceFree);

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  TableBody,
  TableCell,
  TableRow,
  createFilterOptions,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Button from "../../core/controls/Button";
import {
  createProduct,
  createProductPrice,
  listCategories,
  listProductPriceById,
  mappedListProducts,
} from "../../redux/actions/productActions";

import {
  productPriceSchema,
  productPurchasePriceSchema,
  stockoutSchema,
} from "../../schemas";
import { withRouter } from "react-router-dom";
import { getDataGridUtilityClass } from "@mui/x-data-grid";
import useTable from "../../core/useTable";
import { emprecords } from "../../DummyData";
import Datepicker from "../../core/controls/Datepicker";
import { PRODUCT_PRICE_CREATE_RESET } from "../../redux/constants/productConstants";
import Notification from "../../core/Notification";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  listGetJobSearchSingle,
  listPendingInvoice,
  listProductsForInvoice,
  updateStockout,
} from "../../redux/actions/invoiceActions";
import {
  createProductPurchasePrice,
  createProductSellingPrice,
} from "../../redux/actions/priceActions";
import {
  PRODUCT_PURCHASE_PRICE_CREATE_RESET,
  PRODUCT_SELLING_PRICE_CREATE_RESET,
} from "../../redux/constants/priceConstants";
import Popup from "../../core/Popup";
import { ListJobSearchSingleReducer } from "../../redux/reducers/invoiceReducers";
import { JOB_LIST_SINGLE_RESET } from "../../redux/constants/invoiceConstants";

const headCells = [
  {
    id: "invoiceDate",
    label: "Purchase Date",
  },
  {
    id: "purchasePrice",
    label: "Purchase Price",
  },
];

const useStyles = makeStyles((theme) => ({
  inputFields: {
    width: "100%",
    /* From https://css.glass */
    padding: "1rem",
    paddingRight: "1.5rem",
    // background: "rgba(255, 245, 228,0.5)",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    marginBottom: "1rem",
    [useTheme().breakpoints.up("md")]: {
      width: "83%",
    },
  },
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  systemNo: "",
  pendingSingle: {
    id: "",
    label: "",
  },
};

const UpdateStockoutStatus = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pending, setPending] = useState("true");
  const [checked, setChecked] = useState(true);

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const listJobSearchSingle = useSelector((state) => state.listJobSearchSingle);
  const {
    loading: loadingJobSearch,
    error: errorJobSearch,
    singlejobsearch,
  } = listJobSearchSingle;

  console.log("singlejobsearch");
  console.log(singlejobsearch);

  const stockoutPendingUpdate = useSelector(
    (state) => state.stockoutPendingUpdate
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedStockoutPending,
  } = stockoutPendingUpdate;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const productPriceById = useSelector((state) => state.productPriceById);
  const {
    loading: loadingProductPrice,
    error: errorProductPrice,
    products: productPrice,
  } = productPriceById;
  // console.log(productPrice);
  const mappedProductList = useSelector((state) => state.mappedProductList);
  const {
    loading: mappedLoading,
    error: mappedError,
    mappedproducts,
  } = mappedProductList;

  const pendingList = useSelector((state) => state.pendingList);
  const {
    loading: loadingPending,
    error: errorPending,
    pendings,
  } = pendingList;
  //   console.log(mappedproducts);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(productPrice, headCells, filterFn);

  //   console.log(userInfo);
  const formik = useFormik({
    initialValues,
    validationSchema: stockoutSchema,
    onSubmit: (values, action) => {
      console.log(values);
      // console.log("loadingCreate");
      // console.log(loadingCreate);
      dispatch(listGetJobSearchSingle(values.systemNo)).then((result) => {
        if (result?.errMsg) {
          // console.log(result);
          if (result.errMsg == "Product price already Exist") {
            setOpenPopup({
              ...openPopup,
              title: "",
              subTitle: `${result.errMsg}`,
              isOpen: true,
            });
          }
          setOpenPopup({
            ...openPopup,
            title: "ERROR!!!",
            subTitle: `${result.errMsg}`,
            isOpen: true,
          });

          action.setSubmitting(false);
        }
      });
      // console.log("loadingCreate");
      // console.log(loadingCreate);
      // if (loadingCreate === false) {
      //   history.push("/price");
      // }
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
      dispatch(listProductsForInvoice());
      dispatch(listPendingInvoice(pending));
      dispatch({ type: JOB_LIST_SINGLE_RESET });
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/stockout/update" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successUpdate]);

  const onTagsChange = (event, values) => {
    // console.log(values);
    dispatch(mappedListProducts(values.id));
  };

  const getData = (event, values) => {
    dispatch(listProductPriceById(values.id));
  };

  const mappedProductOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const categoryChange = useCallback((e, v) => {
    formik.setFieldValue("category", {
      id: v?.id || "",
      label: v?.label || "",
    });
    onTagsChange(e, v);
  });

  const productChange = useCallback((e, v) => {
    // console.log(v.id);
    formik.setFieldValue("product", {
      id: v?.id || "",
      label: v?.label || "",
    });
    getData(e, v);
  });

  const isOptionEqualToValue = useCallback(
    (option, value) => option.value === value.value
  );

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("pendingSingle", {
      id: v?.id || "",
      label: v?.label || "",
    });
    // dispatch(listSalesOrderInternal(v?.id));
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  // console.log(formik.values.category);
  // console.log(categories);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AddShoppingCartIcon />}
        title="Stockout"
        subtitle="Update Stockout Pending"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Input
                label="Invoice No"
                name="systemNo"
                error={formik.errors.systemNo}
                touched={formik.touched.systemNo}
                value={formik.values.systemNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div>
                <Button
                  color="success"
                  text="Search"
                  endIcon={<SearchIcon />}
                  style={{ marginLeft: "8px" }}
                  type="submit"
                  endIcon={
                    loadingJobSearch ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <SearchIcon />
                    )
                  }
                />
              </div>
              <Autocomplete
                size="small"
                disabled={
                  loadingPending ||
                  singlejobsearch === undefined ||
                  JSON.stringify(singlejobsearch) === "{}" ||
                  JSON.stringify(pendings) === "[]"
                    ? true
                    : false
                }
                id="combo-box-demo-channel"
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={customerChange}
                value={formik.values.pendingSingle}
                options={pendings !== undefined ? pendings : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Decision Pending"
                    name="pendingSingle"
                    error={
                      formik.errors.pendingSingle !== undefined
                        ? formik.errors.pendingSingle.id
                        : ""
                    }
                    touched={
                      formik.touched.pendingSingle !== undefined
                        ? formik.touched.pendingSingle
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />

              <div>
                <Button
                  color="primary"
                  text="Update"
                  style={{ marginLeft: "8px" }}
                  disabled={
                    singlejobsearch !== undefined &&
                    JSON.stringify(singlejobsearch) !== "{}"
                      ? false
                      : true
                  }
                  onClick={() => {
                    dispatch(
                      updateStockout({
                        soSystemId: singlejobsearch.soSystemId,
                        associateCurrenStatus: formik.values.pendingSingle.id,
                      })
                    ).then((res) => {
                      if (res?.errMsg) {
                        setOpenPopup({
                          title: "ERROR!!!",
                          subTitle: `${res.errMsg}`,
                          isOpen: true,
                        });
                      } else {
                        setOpenPopup({
                          title: "Updated",
                          subTitle: "Stockout Pending Updated",
                          isOpen: true,
                        });
                        formik.setFieldValue("systemNo", "");
                        formik.setFieldValue("pendingSingle", {
                          id: "",
                          label: "",
                        });
                      }
                    });
                    //   const location = {
                    //     pathname: "/price",
                    //     state: {},
                    //   };
                    //   history.push(location);
                  }}
                  endIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <></>
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              {singlejobsearch !== undefined &&
                JSON.stringify(singlejobsearch) !== "{}" && (
                  <div className={classes.inputFields}>
                    <p>
                      Invoice No:{" "}
                      <span style={{ color: "#00F700" }}>
                        {singlejobsearch.soSystemNo}
                      </span>
                    </p>
                    <p>
                      Invoice Date:{" "}
                      <span style={{ color: "#00F700" }}>
                        {singlejobsearch.invoiceDate}
                      </span>
                    </p>
                    <p>
                      Customer Name:{" "}
                      <span style={{ color: "#00F700" }}>
                        {singlejobsearch.customerName}
                      </span>
                    </p>
                    <p>
                      Customer Address:{" "}
                      <span style={{ color: "#00F700" }}>
                        {singlejobsearch.customerAddress}
                      </span>
                    </p>
                    <p>
                      Current Work Status:{" "}
                      <span style={{ color: "#00F700" }}>
                        {singlejobsearch.soSystemNo}
                      </span>
                    </p>
                  </div>
                )}
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loading ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(UpdateStockoutStatus);

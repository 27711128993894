// // Importing necessary dependencies and styles
// import React, { useRef } from "react";
import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
//import "./View.css";
import Barcode from 'react-barcode';
const styles = `
  @media print {
    th, td {
      word-wrap: break-word;
      font-size: 8px;
      max-width: none; /* Add this line to override max-width */
    }
  }

  /* General styles */
  body {
    font-family: 'Calibiri', sans-serif;
  }

  /* Styles for the table */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
    table-layout: auto;
  }

  tbody tr {
    page-break-inside: avoid;
  }

  th, td {
    box-sizing: border-box;
    border: 1px solid #000;
    padding: 6px;
    text-align: left;
    font-size: 8px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;



// Function to download the invoice as PDF
const download = (id) => {
  window.scrollTo(0, 0);
  setTimeout(() => {
    const divToPrint = document.querySelector("#invoiceCapture");
    const pdfOptions = {
      margin: 10,
      filename: `invoice-${id}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 10 },
      jsPDF: {
        orientation: "portrait",
        unit: "in",
        format: [4.3, 11.69],
      },
    };
    html2canvas(divToPrint, {
      ...pdfOptions.html2canvas,
      useCORS: true, // Enable this if you have cross-origin content
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [4.3, 11.69],
      });

      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save(`krjadesh-${id}.pdf`);
    });
  }, 1000);
};


// React component class for the example
class Example extends React.Component {
  
  calculateDynamicHeight = () => {
    const contentElement = this.componentRef.current;
    const contentHeight = contentElement.offsetHeight; // Get the height of your content
  
    // Set a minimum height and add some extra space if needed
    const minHeight = 2.5; // Minimum height in inches
    const extraSpace = 1; // Additional space in inches
  
    // Calculate the dynamic height
    const dynamicHeight = Math.max(minHeight, contentHeight / 96 + extraSpace); // Convert pixels to inches (96 pixels per inch)
  
    return `{ size: 3.46in ${dynamicHeight}in }`;
  };


  render() {
    const pageStyle = `{ size: 2.28in 11in }`;
    //const pageStyle = this.calculateDynamicHeight();
    return (
      <div>
        {/* Uncommented by ChatGPT */}
        <ReactToPrint
          pageStyle={pageStyle}
          trigger={() => (
            <button type="button">Export as PDF</button>
          )}
          content={() => this.componentRef}
          bodyClass={"printElement1"}
        />
        <KarjadeshView
          ref={(el) => (this.reportRef = el)}  // Change this line, ChatGPT
          showModal={this.props.showModal}
          closeModal={this.props.closeModal}
          info={this.props.info}
          items={this.props.items}
          currency={"TK"}
          subTotal={this.props.subTotal}
          taxAmmount={this.props.taxAmmount}
          discountAmmount={this.props.discountAmmount}
          total={this.props.total}
        />
      </div>
    );
  }
}

// Commented out export for Example
// export default Example;

// Functional component for displaying the invoice details
const KarjadeshView = (props) => {
  const reportRef = useRef(null);

  const barcodeValue = `${props.info.stockOutGeneralData.soCustomerId}-${props.info.stockOutGeneralData.soSystemId}`;
  //const barcodeValue = `${props.info.stockOutGeneralData.soCustomerId}`
  // const [renderBarcode, setRenderBarcode] = useState(false);

  // useEffect(() => {
  //   // Delay rendering of Barcode to ensure it's rendered after the component is visible
  //   const timeoutId = setTimeout(() => {
  //     setRenderBarcode(true);
  //   }, 500);

  //   return () => clearTimeout(timeoutId);
  // }, []);

  //const barcodeValue = `${props.info.stockOutGeneralData.soSystemNo}`;

  // Function to convert date format
  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    d = d.split(" ");
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        size="xs"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <div
          id="invoiceCapture"
          className=" pt-1  printElement1"
          style={{ position: "relative" }}
          ref={reportRef} // ChatGPT
        >
          <div className="p-4" style={{ position: "relative", maxWidth: "58mm",overflowX: "auto" }} >
            
              <br/>
              <div style={{ fontSize: "13px", fontWeight:"bold", paddingBottom:"10px"}}>
              <text>Trade Zentrum</text>                    
              </div> 
              
                <div style={{ fontSize: "11px" }}>
                  Order Type:&nbsp;
                  {props.info.stockOutGeneralData?.jobTypeName || ""}
                </div> 
                <div style={{ fontSize: "11px" }}>
                  Inv No:{" "}
                  {props.info.stockOutGeneralData?.soSystemNo || "1222i3y"}
                </div>
                <div style={{ fontSize: "11px" }}>
                  Date:&nbsp;
                  {dateConverter(props.info.stockOutGeneralData.invoiceDate) ||
                    ""}
                </div>
                   
              
                <div style={{ fontSize: "11px" , fontWeight: "bold"}}>
                  {props.info.stockOutGeneralData?.supplierName ? (
                    <>S_Name: {props.info.stockOutGeneralData.supplierName}</>
                  ) : (
                    <>C_Name: {props.info.stockOutGeneralData?.customerName || "Not Available"}</>
                  )}
                </div>

                
              
              <div style={{ fontSize: "10px" }}>
                {props.info.stockOutGeneralData?.supplierAddress ? (
                  <>S_Address:&nbsp; : {props.info.stockOutGeneralData.supplierAddress}</>
                ) : (
                  <>C_Address:&nbsp; : {props.info.stockOutGeneralData?.customerAddress || "Not Available"}</>
                )}
             
              </div>
              <br/>

              
            <div style={{ position: "relative", maxWidth: "58mm", overflowX: "auto" }}>
              <style>{styles}</style>
              <Table className="mb-0" style={{ border: "1px solid #e3e3e3", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th className="text-center">QTY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.info.stockOutItemsData.map((item, i) => {
                      return (
                        <tr key={i} className="sticker-info">
                          <td>{item.productName} {item.mappedProductName && `(${item.mappedProductName})`}</td>
                          <td className="text-center">{item.sodQty}</td>
                        </tr>
                      );
                    })}
                  </tbody>
              </Table>
              <div style={{ width: "100%", textAlign: "center", marginTop: "10px" }}>
                  <Barcode
                      //value={props.info.stockOutGeneralData.soSystemId}
                      value={barcodeValue}
                      width={1} // Set the width to 10% of the parent container
                      height={50} // Set the fixed height
                      fontSize={6} // Specify the font size of the text below the barcode
                      textMargin={2} // Specify the margin between the barcode and the text
                  /> 
              </div>
                    {/* Add white space after the barcode */}
                <div style={{ height: "10px" }}>
                  
                </div>
            </div>
            {props.info.notes && (
              <div className="bg-light py-3 px-4 rounded">
                {props.info.notes}
              </div>
            )}
          </div>
          
        </div> 
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              <Button
                variant="primary"
                className="d-flex align-items-center justify-content-center w-100"
                onClick={() => download(props.info.stockOutGeneralData.soSystemNo)}
              >
                <BiPaperPlane
                  style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                  className="me-2"
                />
                Download
              </Button>
            </Col>
            <Col md={6}>
              {/* ChatGPT */}
              <ReactToPrint
                trigger={() => (
                  <Button variant="primary" className="d-flex align-items-center justify-content-center w-100">
                    Export as PDF
                  </Button>
                )}
                content={() => reportRef.current}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <hr className="mt-4 mb-3" />
    </div>
  );
};

// Exporting the KarjadeshView component
export default KarjadeshView;

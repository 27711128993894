import {
  GET_PENDING_AND_CANCEL_REQUEST,
  GET_PENDING_AND_CANCEL_SUCCESS,
  GET_PENDING_AND_CANCEL_FAIL,
} from "../constants/PendingAndCancelReportConstants";

//get PendingAndCancel report list
export const pendingAndCancelReportReducer = (
  state = { pendingAndCancelReports: [] },
  action
) => {
  //  console.log(action.payload);
  switch (action.type) {
    case GET_PENDING_AND_CANCEL_REQUEST:
      return { loading: true };
    case GET_PENDING_AND_CANCEL_SUCCESS:
      return {
        loading: false,
        pendingAndCancelReports: action.payload,
      };
    case GET_PENDING_AND_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// date-fns
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Datepicker = (props) => {
  const { name, label, value, onChange, ...other } = props;
  //   inputFormat="DD-MMM-YYYY"
  const convertToDefaultEvent = (name, value) => ({
    target: {
      name: name,
      value: value,
    },
  });
  return (
    <TextField
      id="date"
      label={label}
      type="date"
      // defaultValue={value}
      value={value}
      name={name}
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={onChange}
      {...other}
    />
  );
};

export default Datepicker;

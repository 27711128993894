import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";
import { makeStyles } from "@mui/styles";

import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import "bootstrap/dist/css/bootstrap.min.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import { Container, FloatingLabel, Spinner, Tab, Tabs } from "react-bootstrap";
import { API_URL } from "../../redux/constants/apiConstants";

import Menu from "../../core/Menu";
import {
  listCustomersForSignup,
  logout,
} from "../../redux/actions/userActions";
import {
  FilterProductsForInvoice,
  
} from "../../redux/actions/invoiceActions";
import Popup from "../../core/Popup";
import SuccessPopup from "../../core/SuccessPopup";
import SampleItem from "../../core/SampleItem";
import { MagnifyingGlass, RotatingLines } from "react-loader-spinner";
import { listChannel } from "../../redux/actions/channelActions";
import FormPopup from "../../core/FormPopup";
import { createStockin } from "../../redux/actions/stockInActions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { SUPPLIER_DETAILS_RESET } from "../../redux/constants/supplierConstants";
import { CUSTOMER_DETAILS_RESET } from "../../redux/constants/customerConstants";
import CheckListItem from "../../core/CheckListItem";
import {
  completeCheckList,
  createTransporationEndLocation,
  deleteTransportation,
  endTask,
  listEmployeeTask,
  listTaskDetails,
  startTask,
} from "../../redux/actions/taskActions";
import TransportationItem from "../../core/TransportationItem";
import { TASK_DETAILS_RESET } from "../../redux/constants/taskConstants";
import AddTransport from "../Transports/AddTransport";
import EditTransport from "../Transports/EditTransport";
import PageHeader from "../../core/PageHeader";
import ConfirmDialog from "../../core/ConfirmDialog";
var FilteredProduct = [];

var date = new Date();
var d = date.toString();
d = d.split(" ");

var d = [d[2], d[1], d[3]].join("-");

const useStyles = makeStyles((theme) => ({
  mainCulprit: {
    border: "1px solid #e3e3e3",
    borderRadius: "7px",
    "& .nav-item": {
      border: "1px solid #e4e4e4",
      borderTop: "none",
      borderLeft: "none",
      borderRadius: "7px",
    },
    "& .nav-item button": {
      color: "#000",
    },
    "& .active": {
      borderTop: "none",
      backgroundColor: "#ed6c02 !important",
      color: "#fff !important",
    },
    "& .active:hover": {
      borderTop: "none",
    },
  },
}));

const AddDelivery = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const invoiceOrderFromList = useSelector(
    (state) => state.invoiceOrderFromList
  );
  const {
    loading: loadingOrderFrom,
    error: errorOrderFrom,
    orderFroms,
  } = invoiceOrderFromList;

  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const invoicePackTypeList = useSelector((state) => state.invoicePackTypeList);
  const {
    loading: loadingPackType,
    error: errorPackType,
    packTypes,
  } = invoicePackTypeList;

  const priceSummaryBasedOnProductAndCustomerList = useSelector(
    (state) => state.priceSummaryBasedOnProductAndCustomerList
  );
  const {
    loading: loadingPriceSummaryList,
    error: errorPriceSummaryList,
    priceSummaryList,
  } = priceSummaryBasedOnProductAndCustomerList;

  const stockinCreate = useSelector((state) => state.stockinCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    createdStockin,
  } = stockinCreate;

  const categoryBasedProductDetails = useSelector(
    (state) => state.categoryBasedProductDetails
  );
  const {
    loading: loadingCategoryBasedProducts,
    error: errorCategoryBasedProducts,
    productList: categoryBasedProduct,
  } = categoryBasedProductDetails;

  const supplierDropdownList = useSelector(
    (state) => state.supplierDropdownList
  );
  const {
    loading: loadingSuppliers,
    error: errorSupplier,
    suppliers: suppliersDropdown,
  } = supplierDropdownList;

  const channelList = useSelector((state) => state.channelList);
  const {
    loading: loadingChannels,
    error: errorChannels,
    channels,
  } = channelList;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const supplierDetails = useSelector((state) => state.supplierDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    supplier,
  } = supplierDetails;

  const customerDetails = useSelector((state) => state.customerDetails);
  const {
    loading: loadingCustomerDetails,
    error: errorCustomerDetails,
    customer,
  } = customerDetails;

  const employeeTaskList = useSelector((state) => state.employeeTaskList);
  const {
    loading: loadingEmployeeTaskList,
    error: errorEmployeeTaskList,
    employeetasks,
  } = employeeTaskList;

  const taskDetails = useSelector((state) => state.taskDetails);
  const {
    loading: loadingTaskDetails,
    error: errorTaskDetails,
    taskSingle,
  } = taskDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [switchDiscountPercentage, setSwitchDiscountPercentage] =
    useState(false);
  const [switchTOpen, setSwitchTOpen] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [mappingOption, setMappingOption] = useState(false);
  const [categoryQuantity, setCategoryQuantity] = useState(1);
  const [flag, setFlag] = useState(0);
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "",
    unitPrice: "",
    productStrength: "",
    sidBatches: [],
    sidPacks: [],
  });

  const [key, setKey] = useState("home");
  const [lineTotal, setLineTotal] = useState(0);
  const [productVatAmmount, setProductVatAmmount] = useState(0.0);
  const [productAitAmmount, setProductAitAmmount] = useState(0.0);
  const [quantityPrice, setQuantityPrice] = useState(0.0);
  const [vadTotal, setVadTotal] = useState(0.0);
  const [subPurchase, setSubPurchase] = useState(0.0);
  const [vatAmmount, setVatAmmount] = useState(0.0);
  const [aitAmmount, setAitAmmount] = useState(0.0);
  const [discountAmmount, setDiscountAmmount] = useState(0.0);
  const [adjustmentAmmount, setAdjustmentAmmount] = useState(0.0);

  const [total, setTotal] = useState(0.0);
  const [selected, setSelected] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [selectedOrderFrom, setSelectedOrderFrom] = useState([]);
  const [selectedPackType, setSelectedPackType] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [selectedDestinationChannel, setSelectedDestinationChannel] = useState(
    []
  );
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup3, setOpenPopup3] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup4, setOpenPopup4] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup5, setOpenPopup5] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup6, setOpenPopup6] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  const [taskIdIndividual, setTaskIdIndividual] = useState(null);
  const [taskIndividual, setTaskIndividual] = useState(null);
  const [transportationIdIndividual, setTransportationIdIndividual] =
    useState(null);
  const [packIdIndividual, setPackIdIndividual] = useState(null);
  const [productIdIndividual, setProductIdIndividual] = useState(null);
  const [batchIdIndividual, setBatchIdIndividual] = useState(null);
  const [batchAndMap, setBatchAndMap] = useState();
  const [data, setData] = useState({
    isOpen: false,
    currency: "TK",
    currentDate: "",
    customerInvoice: "",
    dateOfIssue: new Date().toISOString().slice(0, 10),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    taxRate: "",
    taxAmmount: "0.00",
    vatRate: "",
    vatAmmount: "0.00",
    aitRate: "",
    aitAmmount: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "",
    discountRate: "",
    discountAmmount: "0.00",
    adjustmentAmount: "",
    switchDiscountPercentage: false,
    items: [],
  });

  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
      
        if (permissionStatus.state === "granted") {
          if (userInfo) {
            dispatch(listEmployeeTask(decodedata.UserRoleId));
            dispatch(listChannel());
          } else {
            const location = {
              pathname: "/signin",
              state: { from: { pathname: "/delivery/create" } },
            };
            history.push(location);
          }
        } else {
          dispatch(logout());
          history.push("/signin");
        }
      });
  }, [dispatch, history, userInfo]);

  const changeValues = () => {
    if (
      JSON.stringify(employeetasks) !== "[]" &&
      employeetasks !== undefined &&
      employeetasks.length > 0
    ) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const filteredTask = employeetasks.filter((singleData) => {
        return (
          singleData.taskStartTime !== null && singleData.taskEndTime === null
        );
      });

      if (filteredTask.length > 0) {
        setKey(filteredTask[0].taskId);
        setTaskIndividual(filteredTask[0]);
        setFlag(1);
      } else {
        setKey(employeetasks[0].taskId);
        setTaskIndividual(employeetasks[0].taskId);
        setFlag(0);
      }

      dispatch(listTaskDetails(employeetasks[0].taskId));
      var newData = employeetasks.map(async (single1) => {
        let single = single1;

        const response = await fetch(
          `${API_URL}/Task/GetTaskDetails/${single.taskId}`,
          requestOptions
        );
        const data = await response.json();

        // console.log("data hello");
        // console.log(data);
        return {
          started:
            single.taskStartTime !== null && single.taskEndTime === null
              ? 1
              : 0,
          taskId: single.taskId,
          taskName: single.taskName,
          jobType: single.jobType,
          taskAssignTime: single.taskAssignTime,
          taskStartTime: single.taskStartTime,
          taskEndTime: single.taskEndTime,
          taskStatus: single.taskStatus,
          taskChecklistsData: data.dataObj.data.taskChecklistsData,
          taskTransportationData: data.dataObj.data.taskTransportationData,
        };
      });
      Promise.all(newData).then((items) => {
        setData({ ...data, items: items });
      });
    }
  };
  useEffect(() => {
    changeValues();
  }, [employeetasks]);

  useEffect(() => {
    if (
      JSON.stringify(employeetasks) !== "[]" &&
      employeetasks !== undefined &&
      employeetasks.length > 0 &&
      data.items.length > 0
    ) {
      const filteredTask = data.items.filter((singleData) => {
        return String(singleData.taskId) === String(key);
      });
      setTaskIndividual(filteredTask[0]);
    }
  }, [key, data]);

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    d = d.split(" ");
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const handleRowDel = (items) => {

    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
  };

  const handleTransportationDel = (taskId, transportationId) => {
    dispatch(deleteTransportation(taskId, transportationId)).then(() => {
      const filteredTask = data.items.filter((singleData) => {
        return String(singleData.taskId) === String(taskId);
      });

      let taskTransportationData = filteredTask[0].taskTransportationData;

      var newTaskTransportationData = taskTransportationData.filter(
        (single) => {
          return (
            String(single.taskTransportationAutoId) !== String(transportationId)
          );
        }
      );

      var newTaskTransportationData = newTaskTransportationData.map(
        (single, index) => {
          return {
            ...single,
            taskTransportationAutoId: single.taskTransportationAutoId,
          };
        }
      );

      var items = data.items.slice();
      var newItems = items.map(function (sitems) {
        for (var key in sitems) {
          if (String(sitems.taskId) === String(taskId)) {
            sitems["taskTransportationData"] = newTaskTransportationData;
          }
        }
        return sitems;
      });
      setData({
        ...data,
        items: newItems,
      });
      setOpenPopup4({
        ...openPopup4,
        isOpen: false,
      });
    });
  };

  const handlePackDel = (productId, packId) => {
    const filteredProduct = data.items.filter((singleData) => {
      return singleData.productId === productId;
    });

    let sidPacks = filteredProduct[0].sidPacks;

    var newPacks = sidPacks.filter((single) => {
      return single.sipPackTypeId !== packId;
    });
    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (sitems.productId === productId) {
          sitems["sidPacks"] = newPacks;
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
    });
    setOpenPopup6({
      ...openPopup6,
      isOpen: false,
    });
  };

  const handleBatchDel = (productId, batchId) => {
    const filteredProduct = data.items.filter((singleData) => {
      return singleData.productId === productId;
    });

    let sidBatches = filteredProduct[0].sidBatches;

    var newBatches = sidBatches.filter((single) => {
      return single.batchId !== batchId;
    });

    var newBatches = newBatches.map((single, index) => {
      return {
        ...single,
        batchId: index,
      };
    });

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (sitems.productId === productId) {
          sitems["sidBatches"] = newBatches;
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
    });
    setOpenPopup4({
      ...openPopup4,
      isOpen: false,
    });
  };
  const handleAddEvent = (evt) => {
    // console.log("added");
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "0",
      description: "",
      quantity: 1,
    };
    if (
      product.id !== "" &&
      selectedChannel.length > 0 &&
      selectedDestinationChannel.length > 0 &&
      selectedOrderType.length > 0 &&
      (((selectedOrderType[0].label === "Sample_Receive" ||
        selectedOrderType[0].label === "Regular_Purchase") &&
        selectedSupplier.length > 0) ||
        ((selectedOrderType[0].label === "Exchange_Inward" ||
          selectedOrderType[0].label === "Sales_Return" ||
          selectedOrderType[0].label === "Loan_Receive") &&
          selectedCustomer.length > 0))
    ) {
      if (selectedChannel[0].label === selectedDestinationChannel[0].label) {
        setOpenPopup({
          ...openPopup,
          title: "SAME VALUE(S)!!!",
          subTitle: "Source channel and Destination channel can not be same!",
          isOpen: true,
        });
      } else {
        if (lineTotal < 0) {
          setOpenPopup({
            ...openPopup,
            title: "VALUE ISSUE!!!",
            subTitle: "Line Total can't be negative!",
            isOpen: true,
          });
        } else {
          setSwitchTStuck(true);

          data.items.push({
            productId: product.id,
            productName: product.label,
            sidDeclaredQty: product.quantity || 0.0,
            sidConfirmedQty: null,
            sidDeclaredUnitPrice: product.unitPrice || 0.0,
            sidConfirmedUnitPrice: null,
            lineTotal:
              parseFloat(product.quantity) * parseFloat(product.unitPrice),
            sidProductStrength: product.productStrength,
            sidBatches: product.sidBatches,
            sidPacks: product.sidPacks,
          });
          setData({
            ...data,
            items: data.items,
          });

          setProduct({
            ...product,
            id: "",
            label: "",
            quantity: "",
            unitPrice: "",
            productStrength: "",
            sidBatches: [],
            sidPacks: [],
          });
          FilteredProduct.push(product.id);
          dispatch(FilterProductsForInvoice(FilteredProduct));
          setSelected([]);

          handleCalculateSubTotal();
        }
      }
    } else if (
      product.id !== "" &&
      selectedChannel.length > 0 &&
      selectedDestinationChannel.length > 0 &&
      selectedOrderType.length > 0 &&
      (selectedOrderType[0].label === "Dilution_Received" ||
        selectedOrderType[0].label === "Internal_Transfer") &&
      selectedSupplier.length === 0 &&
      selectedCustomer.length === 0
    ) {
      if (selectedChannel[0].label === selectedDestinationChannel[0].label) {
        setOpenPopup({
          ...openPopup,
          title: "SAME VALUE(S)!!!",
          subTitle: "Source channel and Destination channel can not be same!",
          isOpen: true,
        });
      } else {
        if (lineTotal < 0) {
          setOpenPopup({
            ...openPopup,
            title: "VALUE ISSUE!!!",
            subTitle: "Line Total can't be negative!",
            isOpen: true,
          });
        } else {
          setSwitchTStuck(true);

          data.items.push({
            productId: product.id,
            productName: product.label,
            sidDeclaredQty: product.quantity || 0.0,
            sidConfirmedQty: null,
            sidDeclaredUnitPrice: product.unitPrice || 0.0,
            sidConfirmedUnitPrice: null,
            lineTotal:
              parseFloat(product.quantity) * parseFloat(product.unitPrice),
            sidProductStrength: product.productStrength,
            sidBatches: product.sidBatches,
            sidPacks: product.sidPacks,
          });
          setData({
            ...data,
            items: data.items,
          });

          setProduct({
            ...product,
            id: "",
            label: "",
            quantity: "",
            unitPrice: "",
            productStrength: "",
            sidBatches: [],
            sidPacks: [],
          });
          FilteredProduct.push(product.id);
          dispatch(FilterProductsForInvoice(FilteredProduct));
          setSelected([]);

          handleCalculateSubTotal();
        }
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };

  const handleAddEvent2 = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "0",
      description: "",
      quantity: 1,
    };
    if (
      product.id !== "" &&
      selectedSupplier.length > 0 &&
      selectedOrderType.length > 0 &&
      selectedOrderFrom.length > 0
    ) {
      if (
        selectedOrderType.length > 0 &&
        selectedOrderType[0].label === "Sample_Issue"
      ) {
        setSwitchTStuck(true);
        data.items.push({
          productId: product.id,
          productName: product.label,
          quantity: product.quantity || 0,
          vat: product.vat || 0,
          ait: product.ait || 0,
          unitPrice: 0,
          discountType: "F",
          discountRate: 0,
          discountAmmount: 0,
          lineTotal: 0,
        });
        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "",
          unitPrice: "",
          vat: "",
          ait: "",
          discount: "",
          switchDiscountPercentage: false,
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        setAdjustmentAmmount(0.0);
        handleCalculateSubTotal();
      } else {
        if (lineTotal < 0) {
          setOpenPopup({
            ...openPopup,
            title: "VALUE ISSUE!!!",
            subTitle: "Line Total can't be negative!",
            isOpen: true,
          });
        } else {
          setSwitchTStuck(true);
          var discountAmmount = product.discount || 0;
          if (product.switchDiscountPercentage) {
            discountAmmount = lineTotal * (product.discount / 100);
          }

          data.items.push({
            productId: product.id,
            productName: product.label,
            quantity: product.quantity || 0.0,
            vat: product.vat || 0,
            ait: product.ait || 0,
            unitPrice: lineTotal,
            discountRate: product.discount || 0,
            discountType: product.switchDiscountPercentage ? "P" : "F",
            discountAmmount: discountAmmount || 0,
            lineTotal: parseFloat(lineTotal * product.quantity).toFixed(2) || 0,
          });
          setData({
            ...data,
            items: data.items,
            vatRate: "",
            aitRate: "",
          });

          setProduct({
            ...product,
            id: "",
            label: "",
            quantity: "",
            unitPrice: "",
            vat: "",
            ait: "",
            discount: "",
            switchDiscountPercentage: false,
          });
          FilteredProduct.push(product.id);
          dispatch(FilterProductsForInvoice(FilteredProduct));
          setSelected([]);
          setProductVatAmmount(0.0);
          setProductAitAmmount(0.0);
          setDiscountAmmount(0.0);
          setAdjustmentAmmount(0.0);
          handleCalculateSubTotal();
        }
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };
  const handleProductCalculateTotal = () => {
    var quantity = parseFloat(product.quantity || 0);
    var unitPrice = parseFloat(product.unitPrice || 0);
    var vat = parseFloat(product.vat || 0);
    var ait = parseFloat(product.ait || 0);
    var discount = parseFloat(product.discount || 0);
    var productDiscountAmmount = discount;
    var lineTotal = parseFloat(0);
    var quantityPrice = parseFloat(0);
    var vadTotal = parseFloat(0);
    var subPurchase = parseFloat(0);

    if (product.switchDiscountPercentage) {
      productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
    }
    lineTotal = lineTotal + (unitPrice - productDiscountAmmount);
    quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

    var lineTotalVat = unitPrice * (vat / 100);
    var lineTotalAit = unitPrice * (ait / 100);

    lineTotal = lineTotal + lineTotalVat + lineTotalAit;

    vadTotal = lineTotalVat + lineTotalAit;
    vadTotal = parseFloat(vadTotal.toFixed(2));
    lineTotal = lineTotal.toFixed(2);

    quantityPrice = parseFloat(quantityPrice.toFixed(2));
    subPurchase = unitPrice + vadTotal;
    subPurchase = parseFloat(subPurchase.toFixed(2));
    setQuantityPrice(quantityPrice);
    setVadTotal(vadTotal);
    setSubPurchase(subPurchase);
    setLineTotal(lineTotal);
  };

  const handleCalculateSubTotal = () => {
    var items = data.items;
    var subTotal = parseFloat(0);

    items.map(function (item) {
      var singleLineTotal =
        parseFloat(item.sidDeclaredQty) * parseFloat(item.sidDeclaredUnitPrice);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    setData({
      ...data,
      subTotal: parseFloat(subTotal).toFixed(2),
      discountAmmount: parseFloat(
        parseFloat(subTotal) * (data.discountRate / 100)
      ).toFixed(2),
      total: subTotal - data.discountAmmount,
    });
    setTotal(subTotal - data.discountAmmount);
  };
  const handleCalculateTotal = () => {
    var items = data.items;
    var subTotal = parseFloat(0);
    var discountOverall = parseFloat(data.discount || 0);
    var adjustmentAmount = parseFloat(data.adjustmentAmount || 0);
    var productOverallDiscountAmmount = discountOverall;

    items.map(function (item) {
      var singleLineTotal =
        parseFloat(item.sidDeclaredQty) * parseFloat(item.sidDeclaredUnitPrice);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    var totalT = parseFloat(0);

    if (data.switchDiscountPercentage) {
      productOverallDiscountAmmount =
        subTotal * (productOverallDiscountAmmount / 100);
    }

    setDiscountAmmount(productOverallDiscountAmmount);
    setAdjustmentAmmount(adjustmentAmount);
    totalT = subTotal - productOverallDiscountAmmount - adjustmentAmount;

    setTotal(totalT.toFixed(2));
  };

  const handleCalculateTotalFromEditable = (id) => {
    var items = data.items;
    var subTotal = parseFloat(0);
    var sum = 0;

    items.map(function (items) {
      sum =
        sum +
        parseFloat(items.sidDeclaredQty).toFixed(2) *
          parseFloat(items.sidDeclaredUnitPrice).toFixed(2);
    });
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == "lineTotal" && sitems.productId == id) {
          sitems[key] = parseFloat(
            parseFloat(sitems.sidDeclaredUnitPrice).toFixed(2) *
              parseFloat(sitems.sidDeclaredQty)
          ).toFixed(2);
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
      subTotal: parseFloat(sum).toFixed(2),
    });
  };

  const onItemizedItemViewForMaps = (evt) => {
    setProductIdIndividual(evt.id);
    setPackIdIndividual(evt.packTypeId);
    setOpenPopup6({
      ...openPopup6,
      isOpen: true,
    });
  };

  const onItemizedItemAddForMaps = (evt) => {
    setProductIdIndividual(evt.id);
    setOpenPopup5({
      ...openPopup5,
      isOpen: true,
    });
  };

  const onItemizedItemViewForBatches = (evt) => {
    setProductIdIndividual(evt.id);
    setBatchIdIndividual(evt.batchId);
    setOpenPopup4({
      ...openPopup4,
      isOpen: true,
    });
  };

  const onItemizedItemViewForTransports = (evt) => {
    setTaskIdIndividual(evt.id);
    setTransportationIdIndividual(evt.transportationId);
    setOpenPopup4({
      ...openPopup4,
      isOpen: true,
    });
  };

  
  /// asif
/*
  const onItemizedItemCheckListComplete = (evt) => {
    const filteredTask = data.items.filter((singleData) => {
      return String(singleData.taskId) === String(evt.id);
    });
    let checkListData = filteredTask[0].taskChecklistsData;

    if (filteredTask[0].taskStartTime !== null) {
      setConfirmDialog({
        isOpen: true,
        title: "SURE?",
        subTitle: "Is this completed?",
        onConfirm: () => {
          dispatch(completeCheckList(evt.checkListId)).then((res) => {
            var newCheckListData = checkListData.map(function (sitems, index) {
              // console.log("sitems");
              // console.log(sitems);
              for (var key in sitems) {
                if (
                  String(sitems.taskChecklistAutoId) === String(evt.checkListId)
                ) {
                  // console.log("Hello");

                  sitems["checklistCompletionStatus"] =
                    res.checklistCompletionStatus;
                  sitems["checklistCompletionTime"] =
                    res.checklistCompletionTime;
                }
              }
              return sitems;
            });
            var items = data.items.slice();
            var newItems = items.map(function (sitems) {
              // console.log("sitems");
              // console.log(sitems);
              for (var key in sitems) {
                if (
                  String(sitems.taskChecklistAutoId) === String(evt.checkListId)
                ) {
                  // console.log("Hello");
                  sitems["taskChecklistsData"] = newCheckListData;
                }
              }
              return sitems;
            });
            setData({
              ...data,
              items: newItems,
            });

            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          });
        },
      });
    } else {
      setOpenPopup({
        ...openPopup,
        isOpen: true,
        title: "TASK NOT STARTED",
        subTitle:
          "You must have to click the start button to complete checklist..",
      });
    }
  }; */

/*
  //chat gpt sol-1
  const onItemizedItemCheckListComplete = (evt) => {
   const filteredTask = data.items.find((singleData) => String(singleData.taskId) === String(evt.id));
  //const filteredTask = data.items.filter((singleData) => {
    //  return String(singleData.taskId) === String(evt.id);
    //});


    if (!filteredTask) {
      setOpenPopup({
        ...openPopup,
        isOpen: true,
        title: "TASK NOT FOUND",
        subTitle: "Task with the specified ID was not found.",
      });
      return;
    }
  
    const checkListData = filteredTask.taskChecklistsData;
  
    if (filteredTask.taskStartTime !== null) {
      setConfirmDialog({
        isOpen: true,
        title: "SURE?",
        subTitle: "Is this completed?",
        onConfirm: async () => {
          try {
            const res = await dispatch(completeCheckList(evt.checkListId));
  
            const newCheckListData = checkListData.map((sitems) => {
              if (String(sitems.taskChecklistAutoId) === String(evt.checkListId)) {
                return {
                  ...sitems,
                  checklistCompletionStatus: res.checklistCompletionStatus,
                  checklistCompletionTime: res.checklistCompletionTime,
                 //checklistCompletionStatus: '1', // Ensure the value is '1' for Completed
                  //checklistCompletionTime: res.checklistCompletionTime,
                  
                };
              }
              return sitems;
            });
  
            const newItems = data.items.map((sitems) => {
              if (String(sitems.taskChecklistAutoId) === String(evt.checkListId)) {
                return {
                  ...sitems,
                  taskChecklistsData: newCheckListData,
                };
              }
              return sitems;
            });
  
            setData({
              ...data,
              items: newItems,
            });
  
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          } catch (error) {
            console.error("Error completing checklist:", error);
            // Handle error, display error message, etc.
          }
        },
      });
    } else {
      setOpenPopup({
        ...openPopup,
        isOpen: true,
        title: "TASK NOT STARTED",
        subTitle: "You must click the start button to complete the checklist.",
      });
    }
  };

  //chat gpt sol-1 end
*/
  //gpt sol-2
  
  const onItemizedItemCheckListComplete = async (evt) => {
    try {
      const filteredTask = data.items.find((singleData) => String(singleData.taskId) === String(evt.id));
  
      if (!filteredTask) {
        setOpenPopup({
          ...openPopup,
          isOpen: true,
          title: "TASK NOT FOUND",
          subTitle: "The task associated with this checklist was not found.",
        });
        return;
      }
  
      const { taskChecklistsData, taskStartTime } = filteredTask;
  
      if (taskStartTime !== null) {
        setConfirmDialog({
          isOpen: true,
          title: "SURE?",
          subTitle: "Is this completed?",
          onConfirm: async () => {
            try {
              const res = await dispatch(completeCheckList(evt.checkListId));
  
              const newCheckListData = taskChecklistsData.map((sitems) => {
                if (String(sitems.taskChecklistAutoId) === String(evt.checkListId)) {
                  sitems["checklistCompletionStatus"] = res.checklistCompletionStatus;
                  sitems["checklistCompletionTime"] = res.checklistCompletionTime;
                }
                return sitems;
              });
  
              const updatedItems = data.items.map((sitems) => {
                if (String(sitems.taskChecklistAutoId) === String(evt.checkListId)) {
                  sitems["taskChecklistsData"] = newCheckListData;
                }
                return sitems;
              });
  
              setData({
                ...data,
                items: updatedItems,
              });
  
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              });
            } catch (error) {
              // Handle dispatch error
              console.error("Dispatch error:", error);
            }
          },
        });
      } else {
        setOpenPopup({
          ...openPopup,
          isOpen: true,
          title: "TASK NOT STARTED",
          subTitle: "You must click the start button to complete the checklist.",
        });
      }
    } catch (error) {
      // Handle other errors
      console.error("Error:", error);
    }
  };
  
  //gpt sol-2 end


  const onCheckEndLatLongEmpty = (evt) => {
    // console.log("evt");
    // console.log(evt);
    const filteredTask = data.items.filter((singleData) => {
      return String(singleData.taskId) === String(key);
    });
    let taskTransportationData = filteredTask[0].taskTransportationData;

    let filteredTaskTransportationData = taskTransportationData.map(
      (singleValue) => {
        if (
          singleValue.taskTransportationEndLat === null ||
          singleValue.taskTransportationEndLong === null
        ) {
          return true;
        }
      }
    );

    filteredTaskTransportationData = filteredTaskTransportationData.filter(
      (filterSingle) => {
        return filterSingle !== undefined;
      }
    );

    if (filteredTaskTransportationData.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const onItemizedItemDestinationReach = (evt) => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        if (permissionStatus.state === "granted") {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure that you have reached?",
            subTitle: "Once it is updated, you can't change!",
            onConfirm: () => {
              navigator.geolocation.getCurrentPosition(function (position) {
                const filteredTask = data.items.filter((singleData) => {
                  return String(singleData.taskId) === String(evt.id);
                });
                let taskTransportationData =
                  filteredTask[0].taskTransportationData;

                const filteredTaskTransportationData =
                  taskTransportationData.filter((singleValue) => {
                    return (
                      String(singleValue.taskTransportationAutoId) ===
                      String(evt.transportationId)
                    );
                  });
                dispatch(
                  createTransporationEndLocation({
                    taskId: evt.id,
                    taskTransportationAutoId: evt.transportationId,
                    taskTransportationEndLat: position.coords.latitude,
                    taskTransportationEndLong: position.coords.longitude,
                    taskTransportationEndLocation:
                      filteredTaskTransportationData[0]
                        .taskTransportationEndLocation,
                  })
                ).then((res) => {
                  var newTaskTransportationData = taskTransportationData.map(
                    function (sitems, index) {
                      for (var key in sitems) {
                        if (
                          String(sitems.taskTransportationAutoId) ===
                          String(evt.transportationId)
                        ) {
                          // console.log("Hello");

                          sitems["taskTransportationEndLat"] =
                            position.coords.latitude;
                          sitems["taskTransportationEndLong"] =
                            position.coords.longitude;
                        }
                      }
                      return sitems;
                    }
                  );

                  var items = data.items.slice();
                  var newItems = items.map(function (sitems) {
                    for (var key in sitems) {
                      if (sitems.taskId === evt.id) {
                        sitems["taskTransportationData"] =
                          newTaskTransportationData;
                      }
                    }
                    return sitems;
                  });
                  setData({
                    ...data,
                    items: newItems,
                  });

                  setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                  });
                });
              });
            },
          });
        } else {
          dispatch(logout());
          history.push("/signin");
        }
      });
  };

  const onItemizedItemAddForBatches = (evt) => {
    setProductIdIndividual(evt.id);
    setOpenPopup3({
      ...openPopup3,
      isOpen: true,
    });
  };

  const onItemizedItemEdit = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var items = data.items.slice();

    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == item.name && sitems.productId == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });
    
    setData({
      ...data,
      items: newItems,
    });
    handleCalculateTotalFromEditable(item.id);
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
    // handleCalculateTotal();
  };

  const editProductField = (event) => {
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
    
  };

  const openModal = (event) => {
    event.preventDefault();
    if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      var flag = 0;
      if (data.items.length > 0) {
        var dataArray = data.items.map((singleData) => {
          if (
            singleData.unitPrice === "1.00" ||
            singleData.unitPrice === "1" ||
            singleData.unitPrice === 1
          ) {
            flag = 1;
          }
          return {
            sidProductId: singleData.productId,
            productName: singleData.productName,
            sidDeclaredQty: singleData.sidDeclaredQty,
            sidConfirmedQty: singleData.sidConfirmedQty,
            sidDeclaredUnitPrice: singleData.sidDeclaredUnitPrice,
            sidConfirmedUnitPrice: singleData.sidDeclaredUnitPrice,
            sidProductStrength:
              singleData.sidProductStrength === ""
                ? null
                : singleData.sidProductStrength + "%",
            sidBatches: singleData.sidBatches,
            sidPacks: singleData.sidPacks,
          };
        });
        var data1 = {
          stockInGeneralData: {
            siSystemNo: data.siSystemId,
            siInvoiceNo: data.customerInvoice,
            siSystemId: parseInt(decodedata.UserSystemId),
            siInvoiceDate: new Date(d),
            siInvoiceDateInText: d,
            siJobTypeId: parseInt(selectedOrderType[0].id),
            siRemarks: "Packing Issues",
            siSourceChannelId:
              selectedChannel.length > 0 ? selectedChannel[0].id : null,
            siSourceChannelName:
              selectedDestinationChannel.length > 0
                ? selectedDestinationChannel[0].label
                : null,
            siSupplierId:
              selectedSupplier.length > 0 ? selectedSupplier[0].id : null,
            supplierName:
              selectedSupplier.length > 0 ? selectedSupplier[0].label : null,
            supplierAddress:
              JSON.stringify(supplier) !== "{}" && selectedSupplier.length > 0
                ? supplier.supplierAddress
                : null,
            siCustomerId:
              selectedCustomer.length > 0 ? selectedCustomer[0].id : null,
            customerName:
              selectedCustomer.length > 0 ? selectedCustomer[0].label : null,
            customerAddress:
              JSON.stringify(customer) !== "{}" && selectedCustomer.length > 0
                ? customer.customerAddress
                : null,
            jobTypeName: selectedOrderType[0].label,
          },
          stockInItemsData: dataArray,
          stockInBillData: {
            sibCalculatedAmount: parseFloat(data.subTotal),
            sibDiscountAmouunt: discountAmmount,
            sibAdjustedAmount: data.adjustmentAmount || 0,
            sibFinalAmount: parseFloat(total),
            sibCurrencyName: data.currency === "TK" ? "BDT" : "USD",
          },
        };
        // console.log("data1");
        // console.log(data1);

        dispatch(createStockin(data1)).then((result) => {
          if (result?.title === "One or more validation errors occurred.") {
            setOpenPopup({
              ...openPopup,
              title: "ERROR!!!",
              subTitle: "Something went wrong!",
              isOpen: true,
            });
            // action.setFieldError("error", result.errMsg);
            // action.setSubmitting(false);
          } else {
            // if (stickToPageChecked === true) {
            //   action.resetForm();
            // } else {
            //   history.push("/products");
            // }
            // setData({
            //   ...data,
            //   isOpen: true,
            // });
            if (checked === true) {
              setOpenPopup2({
                ...openPopup2,
                title: `SUCCESS!!!`,
                subTitle: `Stockin Created.`,
                isOpen: true,
              });
              FilteredProduct = [];
              dispatch(FilterProductsForInvoice(FilteredProduct));
              setSwitchT(false);
              setSwitchTStuck(false);
              setProduct({
                ...product,
                id: "",
                label: "",
                quantity: "",
                unitPrice: "",
                productStrength: "",
                sidBatches: [],
                sidPacks: [],
              });
              setLineTotal(0.0);
              setProductVatAmmount(0.0);
              setProductAitAmmount(0.0);
              setQuantityPrice(0.0);
              setVadTotal(0.0);
              setVatAmmount(0.0);
              setAitAmmount(0.0);
              setDiscountAmmount(0.0);
              setAdjustmentAmmount(0.0);
              setTotal(0.0);
              setSelected([]);
              setSelectedCategory([]);
              setSelectedCustomer([]);
              setSelectedSupplier([]);
              setSelectedChannel([]);
              setSelectedDestinationChannel([]);
              setSelectedOrderType([]);
              dispatch({ type: SUPPLIER_DETAILS_RESET });
              dispatch({ type: CUSTOMER_DETAILS_RESET });
              setOpenPopup({
                ...openPopup,
                isOpen: false,
                title: "",
                subTitle: "",
              });

              setData({
                ...data,
                isOpen: false,
                currency: "TK",
                currentDate: "",
                customerInvoice: "",
                dateOfIssue: new Date().toISOString().slice(0, 10),
                invoiceNumber: new Date().valueOf(),
                billTo: "",
                billToEmail: "",
                billToAddress: "",
                billFrom: "",
                billFromEmail: "",
                billFromAddress: "",
                notes: "",
                total: "0.00",
                subTotal: "0.00",
                taxRate: "",
                taxAmmount: "0.00",
                vatRate: "",
                vatAmmount: "0.00",
                aitRate: "",
                aitAmmount: "0.00",
                vat: "0.00",
                ait: "0.00",
                discount: "0.00",
                discountRate: "",
                discountAmmount: "0.00",
                adjustmentAmount: "0.00",
                switchDiscountPercentage: false,
                items: [],
              });
            } else {
              history.push("/stockin/list");
            }
          }
        });

        handleCalculateTotal();
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });

  const handleKeyDown = (event, callback) => {
    // console.log("event");
    // console.log(event.key);
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      callback(event);
    }
  };
  // console.log("taskIdIndividual");
  // console.log(taskIdIndividual);
  // console.log(data.invoiceNumber);
  
  
  
  const giveAlert = () => {
    return (
      <Card className="p-4 p-xl-5 my-3 my-xl-4 text-center text-danger blink-text">
        NO TASKS FOR NOW!
      </Card>
    );
  };
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<DeliveryDiningIcon />}
        title="Delivery"
        subtitle="Delivery man update"
      />
      <Container>
        {loadingEmployeeTaskList == false &&
        JSON.stringify(data.items) === "[]" &&
        employeetasks !== undefined &&
        JSON.stringify(employeetasks) === "[]" ? (
          <>{giveAlert()}</>
        ) : (
          <Form
            onSubmit={openModal}
            // onKeyDown={(e) => {
            //   handleKeyDown(e, openModal);
            // }}
          >
            <Row style={{ fontSize: "12px" }}>
              <Col
                md={8}
                lg={12}
                style={{
                  transition: "all 0.5s ease-out",
                }}
              >
                <Card className="p-4 p-xl-5 my-3 my-xl-4">
                  {JSON.stringify(data.items) !== "[]" &&
                  employeetasks !== undefined ? (
                    <>
                      {flag === 1 ? (
                        <Tabs
                          id="fill-tab-example"
                          activeKey={key}
                          onSelect={(k) => {
                            dispatch({ type: TASK_DETAILS_RESET });
                            setKey(k);

                            dispatch(listTaskDetails(k));
                          }}
                          className={`${classes.mainCulprit} mb-3`}
                          fill
                        >
                          {data.items.map((single, i) => {
                            {
                              /* console.log("key");
                          console.log(key);
                          console.log("single.taskId");
                          console.log(single.taskId);
                          console.log("single.taskStartTime");
                          console.log(single.taskStartTime);
                          console.log("single.taskEndTime");
                          console.log(single.taskEndTime); */
                            }
                            return (
                              <Tab
                                key={i}
                                eventKey={single.taskId}
                                title={single.taskName}
                                // disabled={single.started === 0 ? true : false}
                              >
                                {JSON.stringify(taskSingle) !== "{}" &&
                                taskSingle !== undefined ? (
                                  <CheckListItem
                                    onItemizedItemCheckListComplete={
                                      onItemizedItemCheckListComplete
                                    }
                                    onItemizedItemViewForBatches={
                                      onItemizedItemViewForBatches
                                    }
                                    onItemizedItemAddForBatches={
                                      onItemizedItemAddForBatches
                                    }
                                    onItemizedItemViewForMaps={
                                      onItemizedItemViewForMaps
                                    }
                                    onItemizedItemAddForMaps={
                                      onItemizedItemAddForMaps
                                    }
                                    onItemizedItemEdit={onItemizedItemEdit}
                                    onRowAdd={handleAddEvent}
                                    onRowDel={handleRowDel}
                                    onBatchDel={handleBatchDel}
                                    onPackDel={handlePackDel}
                                    taskKey={key}
                                    items={data.items}
                                    data={data}
                                    setData={setData}
                                    taskSingle={taskSingle}
                                  />
                                ) : (
                                  <div className="flex justify-content-center">
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="45"
                                      visible={true}
                                    />
                                  </div>
                                )}
                              </Tab>
                            );
                          })}
                        </Tabs>
                      ) : (
                        <Tabs
                          id="fill-tab-example"
                          activeKey={key}
                          onSelect={(k) => {
                            dispatch({ type: TASK_DETAILS_RESET });
                            setKey(k);

                            dispatch(listTaskDetails(k));
                          }}
                          className={`${classes.mainCulprit} mb-3`}
                          fill
                        >
                          {data.items.map((single, i) => {
                            {
                              /* console.log("key");
                          console.log(key);
                          console.log("single.taskId");
                          console.log(single.taskId);
                          console.log("single.taskStartTime");
                          console.log(single.taskStartTime);
                          console.log("single.taskEndTime");
                          console.log(single.taskEndTime); */
                            }
                            return (
                              <Tab
                                key={i}
                                eventKey={single.taskId}
                                title={single.taskName}
                              >
                                {JSON.stringify(taskSingle) !== "{}" &&
                                taskSingle !== undefined ? (
                                  <CheckListItem
                                    onItemizedItemCheckListComplete={
                                      onItemizedItemCheckListComplete
                                    }
                                    onItemizedItemViewForBatches={
                                      onItemizedItemViewForBatches
                                    }
                                    onItemizedItemAddForBatches={
                                      onItemizedItemAddForBatches
                                    }
                                    onItemizedItemViewForMaps={
                                      onItemizedItemViewForMaps
                                    }
                                    onItemizedItemAddForMaps={
                                      onItemizedItemAddForMaps
                                    }
                                    onItemizedItemEdit={onItemizedItemEdit}
                                    onRowAdd={handleAddEvent}
                                    onRowDel={handleRowDel}
                                    onBatchDel={handleBatchDel}
                                    onPackDel={handlePackDel}
                                    taskKey={key}
                                    items={data.items}
                                    data={data}
                                    setData={setData}
                                    taskSingle={taskSingle}
                                  />
                                ) : (
                                  <div className="flex justify-content-center">
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="45"
                                      visible={true}
                                    />
                                  </div>
                                )}
                              </Tab>
                            );
                          })}
                        </Tabs>
                      )}
                    </>
                  ) : (
                    <div className="flex justify-content-center">
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="56"
                        visible={true}
                      />
                    </div>
                  )}

                  <div className="flex justify-content-center">
                    {taskIndividual === null ? (
                      <Button className="fw-bold my-2 btn-light" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ margin: "0px", padding: "0px" }}
                        />{" "}
                        Loading...
                      </Button>
                    ) : taskIndividual !== null &&
                      taskIndividual.taskStartTime !== null &&
                      taskIndividual.taskEndTime === null ? (
                      <Button
                        className="fw-bold my-2 btn-success"
                        onClick={() => {
                          alert("Hello");
                        }}
                        disabled
                      >
                        STARTED
                      </Button>
                    ) : taskIndividual !== null &&
                      taskIndividual.taskStartTime === null &&
                      taskIndividual.taskEndTime === null ? (
                      <Button
                        className="fw-bold my-2 btn-success"
                        onClick={() => {
                          if (onCheckEndLatLongEmpty()) {
                            setOpenPopup({
                              ...openPopup,
                              isOpen: true,
                              title: "MISSED END LOCATION",
                              subTitle:
                                "You have not clicked the reach button below!!",
                            });
                          } else {
                            setConfirmDialog({
                              isOpen: true,
                              title: "WANNA START?",
                              subTitle: `Are you sure that you want to start Task - ${taskIndividual.taskName}?`,
                              onConfirm: () => {
                                dispatch(startTask(key)).then((res) => {
                                  // console.log(res);
                                  var newTask = data.items.map(function (
                                    sitems,
                                    index
                                  ) {
                                    // console.log("sitems");
                                    // console.log(sitems);
                                    for (var key in sitems) {
                                      if (
                                        String(sitems.taskId) ===
                                        String(taskIndividual.taskId)
                                      ) {
                                        // console.log("Hello");

                                        sitems["taskStartTime"] =
                                          res.taskStartTime;
                                      }
                                    }
                                    return sitems;
                                  });

                                  // console.log("newTask");
                                  // console.log(newTask);
                                  setFlag(1);
                                  setData({
                                    ...data,
                                    items: newTask,
                                  });
                                  setConfirmDialog({
                                    ...confirmDialog,
                                    isOpen: false,
                                  });
                                });
                              },
                            });
                          }
                        }}
                      >
                        START
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr className="my-3" />

                  <div className="flex justify-content-center">
                    {taskIndividual === null ? (
                      <Button
                        className="fw-bold my-2 btn-primary"
                        onClick={() => {
                          setOpenPopup3({
                            ...openPopup3,
                            isOpen: true,
                          });
                        }}
                        disabled
                      >
                        ADD TRANSPORT
                      </Button>
                    ) : taskIndividual !== null &&
                      taskIndividual.taskStartTime !== null &&
                      taskIndividual.taskEndTime !== null ? (
                      <Button
                        className="fw-bold my-2 btn-info"
                        onClick={() => {
                          alert("Hello");
                        }}
                        disabled
                        style={{ opacity: 0.4 }}
                      >
                        TRANSPORT LIST
                      </Button>
                    ) : (
                      taskIndividual !== null &&
                      ((taskIndividual.taskStartTime === null &&
                        taskIndividual.taskEndTime === null) ||
                        (taskIndividual.taskStartTime !== null &&
                          taskIndividual.taskEndTime === null)) && (
                        <Button
                          className="fw-bold my-2 btn-primary"
                          onClick={() => {
                            const filteredTask = data.items.filter(
                              (singleData) => {
                                return (
                                  String(singleData.taskId) === String(key)
                                );
                              }
                            );
                            if (onCheckEndLatLongEmpty()) {
                              setOpenPopup({
                                ...openPopup,
                                isOpen: true,
                                title: "MISSED END LOCATION",
                                subTitle:
                                  "You have not clicked the reach button above!!",
                              });
                            } else if (filteredTask[0].taskStartTime === null) {
                              setOpenPopup({
                                ...openPopup,
                                isOpen: true,
                                title: "TASK NOT STARTED",
                                subTitle:
                                  "You must have to click the start button and then you can click ADD TRANSPORT!",
                              });
                            } else {
                              setOpenPopup3({
                                ...openPopup3,
                                isOpen: true,
                              });
                            }
                          }}
                        >
                          ADD TRANSPORT
                        </Button>
                      )
                    )}
                  </div>
                  <hr className="my-3" />
                  {selectedOrderType.length > 0 &&
                  (selectedOrderType[0].label === "Sample_Issue" ||
                    selectedOrderType[0].label === "Warehouse_Transfer") ? (
                    <SampleItem
                      onItemizedItemEdit={onItemizedItemEdit}
                      onRowAdd={handleAddEvent}
                      onRowDel={handleRowDel}
                      currency={data.currency}
                      items={data.items}
                      switchMappingOption={false}
                      allOptionAuto={false}
                    />
                  ) : (
                    <></>
                  )}
                  {data.items.length > 0 ? (
                    <TransportationItem
                      onItemizedItemViewForTransports={
                        onItemizedItemViewForTransports
                      }
                      onItemizedItemDestinationReach={
                        onItemizedItemDestinationReach
                      }
                      onItemizedItemViewForBatches={
                        onItemizedItemViewForBatches
                      }
                      onItemizedItemAddForBatches={onItemizedItemAddForBatches}
                      onItemizedItemViewForMaps={onItemizedItemViewForMaps}
                      onItemizedItemAddForMaps={onItemizedItemAddForMaps}
                      onItemizedItemEdit={onItemizedItemEdit}
                      onRowAdd={handleAddEvent}
                      onRowDel={handleRowDel}
                      onTransportationDel={handleTransportationDel}
                      onBatchDel={handleBatchDel}
                      onPackDel={handlePackDel}
                      currency={data.currency}
                      taskKey={key}
                      items={data.items}
                      data={data}
                      setData={setData}
                    />
                  ) : (
                    <></>
                  )}

                  <div className="flex justify-content-center">
                    {taskIndividual === null ? (
                      <Button
                        className="fw-bold my-2 btn-success"
                        onClick={() => {
                          dispatch({ type: TASK_DETAILS_RESET });
                          setKey(36);
                          dispatch(listTaskDetails(36));
                        }}
                        disabled
                        style={{ opacity: 0.4 }}
                      >
                        ACCOMPLISH
                      </Button>
                    ) : taskIndividual !== null &&
                      taskIndividual.taskStartTime !== null &&
                      taskIndividual.taskEndTime !== null ? (
                      <Button
                        className="fw-bold my-2 btn-success"
                        onClick={() => {
                          dispatch({ type: TASK_DETAILS_RESET });
                          setKey(36);
                          dispatch(listTaskDetails(36));
                        }}
                        disabled
                        style={{ opacity: 0.4 }}
                      >
                        {taskIndividual.taskName} is finished.
                      </Button>
                    ) : (
                      taskIndividual !== null &&
                      ((taskIndividual.taskStartTime === null &&
                        taskIndividual.taskEndTime === null) ||
                        (taskIndividual.taskStartTime !== null &&
                          taskIndividual.taskEndTime === null)) && (
                        <Button
                          className="fw-bold my-2 btn-success"
                          onClick={() => {
                            const filteredTask = data.items.filter(
                              (singleData) => {
                                return (
                                  String(singleData.taskId) === String(key)
                                );
                              }
                            );
                            if (onCheckEndLatLongEmpty()) {
                              setOpenPopup({
                                ...openPopup,
                                isOpen: true,
                                title: "MISSED END LOCATION",
                                subTitle:
                                  "You have not clicked the reach button above!!",
                              });
                            } else if (filteredTask[0].taskStartTime === null) {
                              setOpenPopup({
                                ...openPopup,
                                isOpen: true,
                                title: "TASK NOT STARTED",
                                subTitle:
                                  "You must have to click the start button and then you can click ACCOMPLISH..",
                              });
                            } else {
                              var msg = ""
                              
                              if (data && data.items && data.items[0] && data.items[0].taskChecklistsData) {
                                var status = data.items[0].taskChecklistsData;
                                var isAnyStatusZero = status.some(info => info.checklistCompletionStatus === 0);
                                msg = isAnyStatusZero === true ? "আপনি কি নিশ্চিত যে পেন্ডিং রেখে " +taskIndividual.taskName+ "শেষ করবেন?" : 
                                "আপনি কি নিশ্চিত যে " +taskIndividual.taskName+ " শেষ হয়েছে?"
                              }
                              
                              setConfirmDialog({
                                isOpen: true,
                                title: "ARE YOU SURE?",
                                subTitle: msg,
                                onConfirm: () => {
                                  dispatch(endTask(key)).then((res) => {
                                    // console.log(res);
                                    var newTask = data.items.map(function (
                                      sitems,
                                      index
                                    ) {
                                      // console.log("sitems");
                                      // console.log(sitems);
                                      for (var key in sitems) {
                                        if (
                                          String(sitems.taskId) ===
                                          String(taskIndividual.taskId)
                                        ) {
                                          // console.log("Hello");
                                          sitems["started"] = 0;
                                          sitems["taskEndTime"] =
                                            res.taskEndTime;
                                        }
                                      }
                                      return sitems;
                                    });

                                    // console.log("newTask");
                                    // console.log(newTask);

                                    setFlag(0);

                                    var pos = data.items.findIndex(
                                      (i) => String(i.taskId) === String(key)
                                    );
                                    // console.log("pos");
                                    // console.log(pos);
                                    // console.log(data.items[pos + 1]);
                                    setData({
                                      ...data,
                                      items: newTask,
                                    });

                                    setConfirmDialog({
                                      ...confirmDialog,
                                      isOpen: false,
                                    });

                                    // console.log("data.items[pos + 1]");
                                    // console.log(data.items[pos + 1]);
                                    if (data.items[pos + 1] === undefined) {
                                      var taskNext = data.items.filter(
                                        (single) => {
                                          return (
                                            single.taskStartTime === null &&
                                            single.taskEndTime === null
                                          );
                                        }
                                      );
                                      if (taskNext.length > 0) {
                                        setTimeout(() => {
                                          dispatch({
                                            type: TASK_DETAILS_RESET,
                                          });
                                          setTaskIndividual(taskNext[0]);
                                          setKey(taskNext[0].taskId);
                                          dispatch(
                                            listTaskDetails(taskNext[0].taskId)
                                          );
                                        }, [2000]);
                                      } else {
                                        setTimeout(() => {
                                          setOpenPopup({
                                            ...openPopup,
                                            title: "CONGRATULATIONS!",
                                            subTitle:
                                              "All of your tasks are finished!",
                                            isOpen: true,
                                          });
                                          setKey(employeetasks[0].taskId);
                                        }, [2000]);
                                      }

                                      // setTimeout(() => {
                                      //   setKey(employeetasks[0].taskId);
                                      // }, [2000]);
                                    } else {
                                      var taskNext = data.items.filter(
                                        (single) => {
                                          return (
                                            single.taskStartTime === null &&
                                            single.taskEndTime === null
                                          );
                                        }
                                      );
                                      if (taskNext.length > 0) {
                                        setTimeout(() => {
                                          dispatch({
                                            type: TASK_DETAILS_RESET,
                                          });
                                          setTaskIndividual(
                                            data.items[pos + 1]
                                          );
                                          setKey(data.items[pos + 1].taskId);
                                          dispatch(
                                            listTaskDetails(
                                              data.items[pos + 1].taskId
                                            )
                                          );
                                        }, [2000]);
                                      } else {
                                        setTimeout(() => {
                                          setOpenPopup({
                                            ...openPopup,
                                            title: "CONGRATULATIONS!",
                                            subTitle:
                                              "All of your tasks are finished!",
                                            isOpen: true,
                                          });
                                          setKey(employeetasks[0].taskId);
                                        }, [2000]);
                                      }
                                      // console.log("Asif in");
                                    }
                                    dispatch(
                                      listEmployeeTask(decodedata.UserRoleId)
                                    );
                                  });
                                },
                              });
                            }
                          }}
                          // disabled
                        >
                          ACCOMPLISH
                        </Button>
                      )
                    )}
                  </div>

                  <hr className="my-4" />
                </Card>
              </Col>
            </Row>
          </Form>
        )}

        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
        <FormPopup
          title="Add Transportation"
          openPopup={openPopup3}
          setOpenPopup={setOpenPopup3}
        >
          {/* <AddInhouseEmployee /> */}

          <AddTransport
            openPopup={openPopup3}
            setOpenPopup={setOpenPopup3}
            data={data}
            setData={setData}
            taskId={key}
          />
        </FormPopup>

        <FormPopup
          title="Update Transportation"
          openPopup={openPopup4}
          setOpenPopup={setOpenPopup4}
        >
          {/* <AddInhouseEmployee /> */}

          <EditTransport
            openPopup={openPopup4}
            setOpenPopup={setOpenPopup4}
            data={data}
            setData={setData}
            taskId={taskIdIndividual}
            transportationId={transportationIdIndividual}
            handleBatchDel={handleBatchDel}
          />
        </FormPopup>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </Container>
    </div>
  );
};

export default withRouter(AddDelivery);

import {
  CUSTOMER_CREATE_FAIL,
  CUSTOMER_CREATE_REQUEST,
  CUSTOMER_CREATE_RESET,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_RESET,
  CUSTOMER_DETAILS_FAIL,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_DETAILS_RESET,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_RESET,
  CUSTOMER_UPDATE_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_PRODUCTS_TOP_FAIL,
  PRODUCT_PRODUCTS_TOP_REQUEST,
  PRODUCT_PRODUCTS_TOP_SUCCESS,
  CUSTOMER_MAP_LIST_REQUEST,
  CUSTOMER_MAP_LIST_SUCCESS,
  CUSTOMER_MAP_LIST_FAIL,
  UNASSIGNED_CUSTOMER_LIST_REQUEST,
  UNASSIGNED_CUSTOMER_LIST_SUCCESS,
  UNASSIGNED_CUSTOMER_LIST_FAIL,
  ASSIGNED_CUSTOMER_LIST_REQUEST,
  ASSIGNED_CUSTOMER_LIST_SUCCESS,
  ASSIGNED_CUSTOMER_LIST_FAIL,
  ASSIGNED_CUSTOMER_UPDATE_REQUEST,
  ASSIGNED_CUSTOMER_UPDATE_SUCCESS,
  ASSIGNED_CUSTOMER_UPDATE_FAIL,
  ASSIGNED_CUSTOMER_UPDATE_RESET,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
} from "../constants/customerConstants";

export const customerListReducer = (state = { customers: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case CUSTOMER_LIST_REQUEST:
      return { loading: true };
    case CUSTOMER_LIST_SUCCESS:
      return {
        loading: false,
        customers: action.payload,
      };
    case CUSTOMER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const getProductsListReducer = (state = { productNameList: [] }, action) => {
//   // console.log(action.payload);
//   switch (action.type) {
//     case GET_PRODUCT_LIST_REQUEST:
//       return { loading: true };
//     case GET_PRODUCT_LIST_SUCCESS:
//       return {
//         loading: false,
//         productNameList: action.payload,
//       };
//     case GET_PRODUCT_LIST_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

export const customerMapListReducer = (
  state = { customersinMap: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case CUSTOMER_MAP_LIST_REQUEST:
      return { loading: true };
    case CUSTOMER_MAP_LIST_SUCCESS:
      return {
        loading: false,
        customersinMap: action.payload,
      };
    case CUSTOMER_MAP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerDetailsReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_REQUEST:
      return { loading: true, ...state };
    case CUSTOMER_DETAILS_SUCCESS:
      return { loading: false, customer: action.payload };
    case CUSTOMER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_DETAILS_RESET:
      return { customer: {} };

    default:
      return state;
  }
};

export const customerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_CREATE_REQUEST:
      return { loading: true };
    case CUSTOMER_CREATE_SUCCESS:
      return { loading: false, success: true, customer: action.payload };
    case CUSTOMER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const customerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_DELETE_REQUEST:
      return { loading: true };
    case CUSTOMER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CUSTOMER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const customerUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_UPDATE_REQUEST:
      return { loading: true };
    case CUSTOMER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CUSTOMER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const unAssignedCustomerListReducer = (
  state = { unAssignedCustomers: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case UNASSIGNED_CUSTOMER_LIST_REQUEST:
      return { loading: true };
    case UNASSIGNED_CUSTOMER_LIST_SUCCESS:
      return {
        loading: false,
        unAssignedCustomers: action.payload,
      };
    case UNASSIGNED_CUSTOMER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const assignedCustomerListReducer = (
  state = { assignedCustomers: [], unAssignedCustomers: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case ASSIGNED_CUSTOMER_LIST_REQUEST:
      return { loading: true, ...state };
    case ASSIGNED_CUSTOMER_LIST_SUCCESS:
      return {
        loading: false,
        employeeId: action.payload.employeeId,
        assignedCustomers: action.payload.assignedCustomers.sort((p1, p2) =>
          p1.customerId > p2.customerId
            ? 1
            : p1.customerId < p2.customerId
            ? -1
            : 0
        ),
        unAssignedCustomers: action.payload.unAssignedCustomers.sort((p1, p2) =>
          p1.customerId > p2.customerId
            ? 1
            : p1.customerId < p2.customerId
            ? -1
            : 0
        ),
      };
    case ASSIGNED_CUSTOMER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateAssignedCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGNED_CUSTOMER_UPDATE_REQUEST:
      return { loading: true };
    case ASSIGNED_CUSTOMER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ASSIGNED_CUSTOMER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ASSIGNED_CUSTOMER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

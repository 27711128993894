import {
  ACTIVITYDUPLI_LIST_FAIL,
  ACTIVITYDUPLI_LIST_REQUEST,
  ACTIVITYDUPLI_LIST_SUCCESS,
  ACTIVITY_CREATE_FAIL,
  ACTIVITY_CREATE_REQUEST,
  ACTIVITY_CREATE_RESET,
  ACTIVITY_CREATE_SUCCESS,
  ACTIVITY_LIST_FAIL,
  ACTIVITY_LIST_REQUEST,
  ACTIVITY_LIST_SUCCESS,
  DONE_ACTIVITY_DELETE_FAIL,
  DONE_ACTIVITY_DELETE_REQUEST,
  DONE_ACTIVITY_DELETE_RESET,
  DONE_ACTIVITY_DELETE_SUCCESS,
  DONE_ACTIVITY_DETAILS_FAIL,
  DONE_ACTIVITY_DETAILS_REQUEST,
  DONE_ACTIVITY_DETAILS_RESET,
  DONE_ACTIVITY_DETAILS_SUCCESS,
  DONE_ACTIVITY_LIST_FAIL,
  DONE_ACTIVITY_LIST_REQUEST,
  DONE_ACTIVITY_LIST_SUCCESS,
  DONE_ACTIVITY_UPDATE_FAIL,
  DONE_ACTIVITY_UPDATE_REQUEST,
  DONE_ACTIVITY_UPDATE_RESET,
  DONE_ACTIVITY_UPDATE_SUCCESS,
} from "../constants/MarketingActivityConstants";

export const activityListReducer = (state = { activities: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case ACTIVITY_LIST_REQUEST:
      return { loading: true };
    case ACTIVITY_LIST_SUCCESS:
      return {
        loading: false,
        activities: action.payload,
      };
    case ACTIVITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const activityDupliListReducer = (
  state = { activitiesDupli: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case ACTIVITYDUPLI_LIST_REQUEST:
      return { loading: true };
    case ACTIVITYDUPLI_LIST_SUCCESS:
      return {
        loading: false,
        activitiesDupli: action.payload,
      };
    case ACTIVITYDUPLI_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const DoneActivityListReducer = (
  state = { doneActivities: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case DONE_ACTIVITY_LIST_REQUEST:
      return { loading: true };
    case DONE_ACTIVITY_LIST_SUCCESS:
      return {
        loading: false,
        doneActivities: action.payload,
      };
    case DONE_ACTIVITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const doneActivityDetailsReducer = (
  state = { activity: {} },
  action
) => {
  switch (action.type) {
    case DONE_ACTIVITY_DETAILS_REQUEST:
      return { loading: true, ...state };
    case DONE_ACTIVITY_DETAILS_SUCCESS:
      return { loading: false, activity: action.payload };
    case DONE_ACTIVITY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case DONE_ACTIVITY_DETAILS_RESET:
      return { activity: {} };

    default:
      return state;
  }
};

export const activityCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_CREATE_REQUEST:
      return { loading: true };
    case ACTIVITY_CREATE_SUCCESS:
      return { loading: false, success: true, activity: action.payload };
    case ACTIVITY_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ACTIVITY_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const activityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DONE_ACTIVITY_DELETE_REQUEST:
      return { loading: true };
    case DONE_ACTIVITY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DONE_ACTIVITY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case DONE_ACTIVITY_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const activityUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DONE_ACTIVITY_UPDATE_REQUEST:
      return { loading: true };
    case DONE_ACTIVITY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case DONE_ACTIVITY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case DONE_ACTIVITY_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

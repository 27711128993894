import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { ChequeSchema, InhouseEmployeeSchema } from "../../schemas";
import { createEmployee } from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import { API_URL } from "../../redux/constants/apiConstants";
import { EMPLOYEE_CREATE_RESET } from "../../redux/constants/employeeConstants";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDeparment,
  listDesignation,
} from "../../redux/actions/channelActions";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  listCustomersForSignup,
  listEmployees,
  listEmployeesForDropdown,
} from "../../redux/actions/userActions";
import {
  createCheque,
  listBank,
  listChequeDetails,
  listChequeStatus,
  listChequeType,
  listIssuerBank,
  updateCheque,
} from "../../redux/actions/chequeActions";
import { CHEQUE_CREATE_RESET } from "../../redux/constants/chequeConstants";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  employee: {
    id: "",
    label: "",
  },
  chequeCollectionDate: [date.getFullYear(), mnth, day].join("-"),
  chequeType: {
    id: "",
    label: "",
  },
  customer: {
    id: "",
    label: "",
  },
  chequeDate: [date.getFullYear(), mnth, day].join("-"),
  chequePlacementDate: [date.getFullYear(), mnth, day].join("-"),
  chequeExpireDate: [date.getFullYear(), mnth, day].join("-"),
  chequeActionDate: [date.getFullYear(), mnth, day].join("-"),
  chequeNumber: "",
  chequeAmount: "",
  bank: {
    id: "",
    label: "",
  },
  issuerBank: {
    id: "",
    label: "",
  },
  chequeStatus: {
    id: 0,
    label: "Pending",
  },
  chequeActionRemarks:"",
};

const EditChequeStatus = ({ data, openPopup, setOpenPopup, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
  // const { values, setValues, handleChange } = useForm(initialValues);

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    employees,
  } = employeeList;

  const chequeTypeList = useSelector((state) => state.chequeTypeList);
  const {
    loading: loadingChequeType,
    error: errorChequeType,
    chequeTypes,
  } = chequeTypeList;

  const bankList = useSelector((state) => state.bankList);
  const { loading: loadingBanks, error: errorBanks, banks } = bankList;

  const issuerBankList = useSelector((state) => state.issuerBankList);
  const {
    loading: loadingIssuerBanks,
    error: errorIssuerBanks,
    issuerbanks,
  } = issuerBankList;

  const chequeStatusList = useSelector((state) => state.chequeStatusList);
  const {
    loading: loadingChequeStatus,
    error: errorChequeStatus,
    chequestatuss,
  } = chequeStatusList;

  const channelList = useSelector((state) => state.channelList);
  const { loading: loadingChannels, error, channels } = channelList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const chequeDetails = useSelector((state) => state.chequeDetails);
  const {
    loading: loadingChequeDetails,
    error: errorChequeDetails,
    chequeSingle,
  } = chequeDetails;

  const chequeUpdate = useSelector((state) => state.chequeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = chequeUpdate;
  // console.log(loadingCreate);
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  console.log("data");
  console.log(data);

  const formik = useFormik({
    initialValues,
    validationSchema: ChequeSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      dispatch(
        updateCheque({
          chequeInfoId: data.chequeInfoId,
          chequeCollectionDate: values.chequeCollectionDate,
          chequeCustomerId: values.customer.id,
          chequeDate: values.chequeDate,
          chequeExpireDate: values.chequeExpireDate,
          chequeNumber: values.chequeNumber,
          chequeAmount: values.chequeAmount,
          chequeIssuerBankId: values.issuerBank.id,
          chequeMethodInfoId: values.chequeType.id,
          chequePlacementDate: values.chequePlacementDate,
          chequeBeneficiaryBankId: values.bank.id,
          chequeCollectedBy: values.employee.id,
          chequeStatusId: values.chequeStatus.id,
          chequeActionDate: values.chequeActionDate,
          chequeStatusRemarks: values.chequeStatusRemarks,
          createdBy: 1001,
          createdDate: "2023-05-05T16:41:29.180Z",
          modifiedBy: 1001,
          modifiedDate: "2023-05-05T16:41:29.180Z",
        })
      ).then((result) => {
        if (result?.title === "One or more validation errors occurred.") {
          //   setOpenPopup({
          //     ...openPopup,
          //     title: "ERROR!!!",
          //     subTitle: "Something went wrong!",
          //     isOpen: true,
          //   });
          // action.setFieldError("error", result.errMsg);
          action.setSubmitting(false);
        } else if (result?.errMsg) {
          // console.log(result);
          if (result.errMsg == "Cheque Number Already Exist") {
            action.setFieldError("errorChequeNumber", result.errMsg);
          } else if (result.errMsg == "Contact Number Already Exist") {
            action.setFieldError("errorClientPhone", result.errMsg);
          }

          action.setSubmitting(false);
        } else {
          setOpenPopup({
            ...openPopup,
            isOpen: false,
          });
        }
      });
    },
  });

  const loadEmployeeType = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/Employee/GetEmployeeType`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const final = result.dataObj.map((single) => {
          return {
            id: single.empTypeId,
            title: single.employeeTypeName,
          };
        });

        setEmployeeTypes(final);
      })
      .catch((error) => console.log("error", error));
  };

  // useEffect(() => {

  // }, []);

  const changeValues = () => {
    // console.log("employee");
    // console.log(employee);
    if (JSON.stringify(data) !== "{}" && data !== undefined) {
      dispatch(listChequeStatus());
      var date = new Date(data.chequeCollectionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeCollectionDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(data.chequeDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(data.chequeExpireDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeExpireDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(data.chequePlacementDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequePlacementDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(data.chequeActionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeActionDate = [date.getFullYear(), mnth, day].join("-");
      // console.log(d);
      formik.setValues({
        chequeCollectionDate: chequeCollectionDate,
        chequeDate: chequeDate,
        chequeExpireDate: chequeExpireDate,
        chequePlacementDate: chequePlacementDate,
        chequeActionDate: chequeActionDate,
        chequeNumber: data.chequeNumber,
        chequeAmount: data.chequeAmount,
      });
      formik.setFieldValue("employee", {
        id: data.employeeId === 0 ? "" : data.employeeId,

        label:
          data.employeeId === 0
            ? ""
            : data.empFirstName !== null &&
              data.empLastName !== null &&
              data.employeeCode !== null
            ? data.empFirstName +
                " " +
                data.empLastName +
                "-" +
                data.employeeCode || ""
            : "",
      });
      formik.setFieldValue("chequeType", {
        id: data.chequeMethodInfoId || "",
        label: data.paymentMethodName || "",
      });
      formik.setFieldValue("customer", {
        id: data.chequeCustomerId || "",
        label: data.customerName || "",
      });
      formik.setFieldValue("bank", {
        id: data.chequeBeneficiaryBankId || "",
        label: data.beneficiaryBankName || "",
      });
      formik.setFieldValue("issuerBank", {
        id: data.chequeIssuerBankId || "",
        label: data.chequeBankName || "",
      });
      formik.setFieldValue("chequeStatus", {
        id: data.chequeStatusId || "",
        label: data.paymentChequeStatusName || "",
      });
    }
  };

  useEffect(() => {
    changeValues();
  }, [data]);

  const channelChange = useCallback((e, v) => {
    formik.setFieldValue("channel", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const departmentChange = useCallback((e, v) => {
    formik.setFieldValue("department", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeTypeChange = useCallback((e, v) => {
    formik.setFieldValue("chequeType", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeStatusChange = useCallback((e, v) => {
    formik.setFieldValue("chequeStatus", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const bankChange = useCallback((e, v) => {
    formik.setFieldValue("bank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const issuerBankChange = useCallback((e, v) => {
    formik.setFieldValue("issuerBank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6}>
            {/* <Autocomplete
              disablePortal
              size="small"
              disabled={loadingEmployee ? true : false}
              id="combo-box-demo-channel"
              isOptionEqualToValue={useCallback(
                (option, value) => option.value === value.value
              )}
              onChange={employeeChange}
              value={formik.values.employee}
              options={employees !== undefined ? employees : []}
              onOpen={formik.handleBlur}
              renderInput={(params) => (
                <Input
                  label="Cheque Collected By"
                  name="employee"
                  error={
                    formik.errors.employee !== undefined
                      ? formik.errors.employee.id
                      : ""
                  }
                  touched={
                    formik.touched.employee !== undefined
                      ? formik.touched.employee
                      : ""
                  }
                  {...params}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <Datepicker
              label="Cheque Collection Date"
              name="chequeCollectionDate"
              value={formik.values.chequeCollectionDate}
              onChange={formik.handleChange}
            />

            <Autocomplete
              disablePortal
              size="small"
              disabled={loadingChequeType ? true : false}
              id="combo-box-demo-channel"
              isOptionEqualToValue={useCallback(
                (option, value) => option.value === value.value
              )}
              onChange={chequeTypeChange}
              value={formik.values.chequeType}
              options={chequeTypes !== undefined ? chequeTypes : []}
              onOpen={formik.handleBlur}
              renderInput={(params) => (
                <Input
                  label="Cheque Type"
                  name="chequeType"
                  error={
                    formik.errors.chequeType !== undefined
                      ? formik.errors.chequeType.id
                      : ""
                  }
                  touched={
                    formik.touched.chequeType !== undefined
                      ? formik.touched.chequeType
                      : ""
                  }
                  {...params}
                  onBlur={formik.handleBlur}
                />
              )}
            /> */}
            <Autocomplete
              disablePortal
              size="small"
              disabled={true}
              id="combo-box-demo-channel"
              isOptionEqualToValue={useCallback(
                (option, value) => option.value === value.value
              )}
              onChange={customerChange}
              value={formik.values.customer}
              options={customers !== undefined ? customers : []}
              onOpen={formik.handleBlur}
              filterOptions={filterOptions}
              renderInput={(params) => (
                <Input
                  label="Customer Name"
                  name="customer"
                  error={
                    formik.errors.customer !== undefined
                      ? formik.errors.customer.id
                      : ""
                  }
                  touched={
                    formik.touched.customer !== undefined
                      ? formik.touched.customer
                      : ""
                  }
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  {...params}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <Datepicker
              label="Cheque Date"
              name="chequeDate"
              value={formik.values.chequeDate}
              onChange={formik.handleChange}
              disabled={true}
            />
            <Datepicker
              label="Cheque Placement Date"
              name="chequePlacementDate"
              value={formik.values.chequePlacementDate}
              onChange={formik.handleChange}
              disabled={true}
            />
            {/* <Datepicker
                label="Cheque Expire Date"
                name="chequeExpireDate"
                value={formik.values.chequeExpireDate}
                onChange={formik.handleChange}
              /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Cheque Number"
              name="chequeNumber"
              error={formik.errors.chequeNumber}
              extraError={formik.errors.errorChequeNumber}
              touched={formik.touched.chequeNumber}
              value={formik.values.chequeNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={true}
            />
            <Input
              label="Cheque Amount"
              name="chequeAmount"
              error={formik.errors.chequeAmount}
              touched={formik.touched.chequeAmount}
              value={formik.values.chequeAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={true}
            />
            {/* <Autocomplete
              disablePortal
              size="small"
              disabled={loadingBanks ? true : false}
              id="combo-box-demo-channel"
              isOptionEqualToValue={useCallback(
                (option, value) => option.value === value.value
              )}
              onChange={bankChange}
              value={formik.values.bank}
              options={banks !== undefined ? banks : []}
              onOpen={formik.handleBlur}
              renderInput={(params) => (
                <Input
                  label="Beneficiary Bank"
                  name="bank"
                  error={
                    formik.errors.bank !== undefined
                      ? formik.errors.bank.id
                      : ""
                  }
                  touched={
                    formik.touched.bank !== undefined ? formik.touched.bank : ""
                  }
                  {...params}
                  onBlur={formik.handleBlur}
                />
              )}
            />

            <Autocomplete
              disablePortal
              size="small"
              disabled={loadingIssuerBanks ? true : false}
              id="combo-box-demo-channel"
              isOptionEqualToValue={useCallback(
                (option, value) => option.value === value.value
              )}
              onChange={issuerBankChange}
              value={formik.values.issuerBank}
              options={issuerbanks !== undefined ? issuerbanks : []}
              onOpen={formik.handleBlur}
              renderInput={(params) => (
                <Input
                  label="Cheque Issuer Bank"
                  name="issuerBank"
                  error={
                    formik.errors.issuerBank !== undefined
                      ? formik.errors.issuerBank.id
                      : ""
                  }
                  touched={
                    formik.touched.issuerBank !== undefined
                      ? formik.touched.issuerBank
                      : ""
                  }
                  {...params}
                  onBlur={formik.handleBlur}
                />
              )}
            /> */}

            <Autocomplete
              size="small"
              disabled={loadingChequeType ? true : false}
              id="combo-box-demo-channel"
              isOptionEqualToValue={useCallback(
                (option, value) => option.value === value.value
              )}
              onChange={chequeStatusChange}
              value={formik.values.chequeStatus}
              options={chequestatuss !== undefined ? chequestatuss : []}
              onOpen={formik.handleBlur}
              renderInput={(params) => (
                <Input
                  label="chequestatus"
                  name="chequeStatus"
                  error={
                    formik.errors.chequeStatus !== undefined
                      ? formik.errors.chequeStatus.id
                      : ""
                  }
                  touched={
                    formik.touched.chequeStatus !== undefined
                      ? formik.touched.chequeStatus
                      : ""
                  }
                  {...params}
                  onBlur={formik.handleBlur}
                />
              )}
            />
  
           
            {/* <Datepicker
                label="Cheque Action Date"
                name="chequeActionDate"
                value={formik.values.chequeActionDate}
                onChange={formik.handleChange}
              /> */}
          </Grid>

          <Grid item xs={12} md={12}>
            <ButtonGroup className={classes.submitButtonGroup} type="submit">
              <MuiButton
                endIcon={
                  loadingUpdate ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : (
                    <PublishIcon />
                  )
                }
                size="large"
                type="submit"
              >
                Update
              </MuiButton>
            </ButtonGroup>
            <Button
              color="error"
              text="Cancel"
              onClick={() => {
                setOpenPopup({
                  ...openPopup,
                  isOpen: false,
                });
              }}
            />
          </Grid>
        </Grid>
      </Form>
      <div
        className="paperdesign2"
        style={{
          display:
            loadingChannels && loadingDesignations && loadingDepartments
              ? ""
              : "none",
        }}
      >
        <div>
          <Circles
            height="70"
            width="100"
            color="#F3EC19"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            // wrapperClass=""
            // style={{
            //   width: "100%",
            //   height: "100%",
            //   display: "flex !important",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
            visible={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
            }}
          >
            Please wait
          </p>
        </div>
      </div>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(EditChequeStatus);

import React, { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Datepicker from "../../../core/controls/Datepicker";
import Button from "../../../core/controls/Button";
import { useEffect } from "react";
import {getProductsList} from "../../../redux/actions/CurrentStockReportActions"
import Notification from "../../../core/Notification";
import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";

import axios from "axios";

import { exportToExcelExcelJs } from "../../../utils/json-to-excel-export-exceljs";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  productName: {
    id: "",
    label: "",
  },
};

const ProdctInReport = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const csvDownloadRef = useRef(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [checked, setChecked] = useState(true);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });


  const productsNameList = useSelector((state) => state.productNameList);
  const {
    loading: loadingProductsName,
    error: errorProductsName,
    productNameList,
  } = productsNameList;

 

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const heading_with_title = {
    siSystemId:"System Id",
    siSystemNo: "Invoice No",
    siInvoiceNo: "Supplier Invoice No",
    siInvoiceDate: "Transaction Date",
    siSupplierId:"Supplier Id",
    supplierName: "Supplier Name",
    customerName:"Customer Name",
    jobType: "Type Of Sales",
    sidProductId: "Product Id",
    productName: "Product Name",
    sidConfirmedQty: "Receive Quantity",
    sidConfirmedUnitPrice: "Unit Price",
    siCurrentStatus: "Pending Id",
    lifecycleName: "Work Pending Name",
  };

  const heading_field_values = [
    "siSystemId",
    "siSystemNo",
    "siInvoiceNo",
    "siInvoiceDate",
    "siSupplierId",
    "supplierName",
    "customerName",
    "jobType",
    "sidProductId",
    "productName",
    "sidConfirmedQty",
    "sidConfirmedUnitPrice",
    "siCurrentStatus",
    "lifecycleName",
  ];

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      console.log("values: ", values);
      const axios_config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };
      const api_endpoint = `${API_URL}/Report/GetProductInReport?startDate=${
        values?.from
      }&endDate=${values?.to}&productId=${
        values?.productName?.id !== "" ? values?.productName?.id : "0"
      }`;

      const response = await axios.get(api_endpoint, axios_config);
      const { data } = response;

      if (response?.errMsg) {
      } else {
        if (data?.dataObj?.length > 0) {
          let updatedData = [];

          for (const value of data?.dataObj) {
            const newSerializedObject = {
              siSystemId: value?.siSystemId,
              siSystemNo: value?.siSystemNo,
              siInvoiceNo: value?.siInvoiceNo,
              siInvoiceDate: value?.siInvoiceDate,
              siSupplierId: value?.siSupplierId,
              supplierName: value?.supplierName,
              customerName:value?.customerName,
              jobType: value?.jobType,
              sidProductId: value?.sidProductId,
              productName: value?.productName,
              sidConfirmedQty: value?.sidConfirmedQty,
              sidConfirmedUnitPrice: value?.sidConfirmedUnitPrice,
              siCurrentStatus: value?.siCurrentStatus,
              lifecycleName: value?.lifecycleName,

            };
            updatedData.push(newSerializedObject);
          }

          exportToExcelExcelJs(
            updatedData,
            heading_with_title,
            `Product_Stock_In_Report_Data_${
              values?.productName?.id !== ""
                ? data?.dataObj[0]?.productName
                : "All_Products"
            }(${values.from}_to_${values.to})`,
            `Report For ${
              values?.productName?.id !== ""
                ? data?.dataObj[0]?.productName
                : "All Product"
            } (${values.from} to ${values.to})`
          );
        } else {
          action.setSubmitting(false);
          setOpenPopup({
            ...openPopup,
            title: "ZERO DATA!!!",
            subTitle: "No Data found with this specifications!",
            isOpen: true,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(getProductsList());
      
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/productin" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const productNameChange = useCallback((e, v) => {
    formik.setFieldValue("productName", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AssessmentIcon />}
        title="Product In Report"
        subtitle="Generate Report"
      />
      <Paper className={classes.pageContent}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Datepicker
                label="From"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
              />
              <Datepicker
                label="To"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
                  <Autocomplete
                    size="small"
                    disabled={loadingProductsName ? true : false}
                    id="combo-box-demo-channel"
                    // isOptionEqualToValue={isOptionEqualToValue}
                    onChange={productNameChange}
                    value={formik.values.productName}
                    options={
                      productNameList !== undefined ? productNameList : []
                    }
                    onOpen={formik.handleBlur}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Product Name"
                        name="customer"
                        error={
                          formik.errors.customer !== undefined
                            ? formik.errors.customer.id
                            : ""
                        }
                        touched={
                          formik.touched.customer !== undefined
                            ? formik.touched.customer
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
            </Grid>
            <Grid item xs={12} md={12}>
              <MuiButton
                className={classes.submitButtonGroup}
                endIcon={
                  loadingProductsName ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : null
                }
                size="large"
                type="submit"
                // onClick={() => downloadExcelReport()}
              >
                <img
                  width={40}
                  height={40}
                  src={process.env.PUBLIC_URL + "/images/excel-icon.png"}
                  alt=""
                />
              </MuiButton>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </div>
  );
};
export default withRouter(ProdctInReport);

export const BARCODE_CREATE_REQUEST = "BARCODE_CREATE_REQUEST";
export const BARCODE_CREATE_SUCCESS = "BARCODE_CREATE_SUCCESS";
export const BARCODE_CREATE_FAIL = "BARCODE_CREATE_FAIL";
export const BARCODE_CREATE_RESET = "BARCODE_CREATE_RESET";

export const BARCODE_PRINT_LIST_REQUEST = "BARCODE_PRINT_LIST_REQUEST";
export const BARCODE_PRINT_LIST_SUCCESS = "BARCODE_PRINT_LIST_SUCCESS";
export const BARCODE_PRINT_LIST_FAIL = "BARCODE_PRINT_LIST_FAIL";
export const BARCODE_PRINT_LIST_RESET = "BARCODE_PRINT_LIST_RESET";

export const BARCODE_REMOVE_REQUEST = "BARCODE_REMOVE_REQUEST";
export const BARCODE_REMOVE_SUCCESS = "BARCODE_REMOVE_SUCCESS";
export const BARCODE_REMOVE_FAIL = "BARCODE_REMOVE_FAIL";
export const BARCODE_REMOVE_RESET = "BARCODE_REMOVE_RESET";

export const REPACKING_BARCODE_CREATE_REQUEST = "REPACKING_BARCODE_CREATE_REQUEST";
export const REPACKING_BARCODE_CREATE_SUCCESS = "REPACKING_BARCODE_CREATE_SUCCESS";
export const REPACKING_BARCODE_CREATE_FAIL = "REPACKING_BARCODE_CREATE_FAIL";
export const REPACKING_BARCODE_CREATE_RESET = "REPACKING_BARCODE_CREATE_RESET";

export const REPACKING_PRINT_LIST_REQUEST = "REPACKING_PRINT_LIST_REQUEST";
export const REPACKING_PRINT_LIST_SUCCESS = "REPACKING_PRINT_LIST_SUCCESS";
export const REPACKING_PRINT_LIST_FAIL = "REPACKING_PRINT_LIST_FAIL";
export const REPACKING_PRINT_LIST_RESET = "REPACKING_PRINT_LIST_RESET";

export const REPACKING_BARCODE_REMOVE_REQUEST = "REPACKING_BARCODE_REMOVE_REQUEST";
export const REPACKING_BARCODE_REMOVE_SUCCESS = "REPACKING_BARCODE_REMOVE_SUCCESS";
export const REPACKING_BARCODE_REMOVE_FAIL = "REPACKING_BARCODE_REMOVE_FAIL";
export const REPACKING_BARCODE_REMOVE_RESET = "REPACKING_BARCODE_REMOVE_RESET";


export const BARCODE_STOCK_OUT_MAP_REQUEST = "BARCODE_STOCK_OUT_MAP_REQUEST";
export const BARCODE_STOCK_OUT_MAP_SUCCESS = "BARCODE_STOCK_OUT_MAP_SUCCESS";
export const BARCODE_STOCK_OUT_MAP_FAIL = "BARCODE_STOCK_OUT_MAP_FAIL";
export const BARCODE_STOCK_OUT_MAP_RESET = "BARCODE_STOCK_OUT_MAP_RESET";

export const BARCODE_DECODE_REQUEST = "BARCODE_DECODE_REQUEST";
export const BARCODE_DECODE_SUCCESS = "BARCODE_DECODE_SUCCESS";
export const BARCODE_DECODE_FAIL = "BARCODE_DECODE_FAIL";
export const BARCODE_DECODE_RESET = "BARCODE_DECODE_RESET";
export const UPDATE_DECODED_DATA = 'UPDATE_DECODED_DATA';

export const INSERT_SO_BARCODE_REQUEST = "INSERT_SO_BARCODE_REQUEST";
export const INSERT_SO_BARCODE_SUCCESS = "INSERT_SO_BARCODE_SUCCESS";
export const INSERT_SO_BARCODE_FAIL = "INSERT_SO_BARCODE_FAIL";
export const INSERT_SO_BARCODE_RESET = "INSERT_SO_BARCODE_RESET";

export const UPDATE_SO_BARCODE_REQUEST = "UPDATE_SO_BARCODE_REQUEST";
export const UPDATE_SO_BARCODE_SUCCESS = "UPDATE_SO_BARCODE_SUCCESS";
export const UPDATE_SO_BARCODE_FAIL = "UPDATE_SO_BARCODE_FAIL";
export const UPDATE_SO_BARCODE_RESET = "UPDATE_SO_BARCODE_RESET";

export const REMOVE_SO_BARCODE_REQUEST = "REMOVE_SO_BARCODE_REQUEST";
export const REMOVE_SO_BARCODE_SUCCESS = "REMOVE_SO_BARCODE_SUCCESS";
export const REMOVE_SO_BARCODE_FAIL = "REMOVE_SO_BARCODE_FAIL";
export const REMOVE_SO_BARCODE_RESET = "REMOVE_SO_BARCODE_RESET";


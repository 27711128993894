import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import AddInhouseEmployee from "./AddInhouseEmployee";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIhouseEmployeedupli from "./AddIhouseEmployeedupli";
import useTable from "../../core/useTable";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import Button from "../../core/controls/Button";
import { Link } from "react-router-dom";
import ActionButton from "../../core/controls/ActionButton";
import {
  deleteEmployee,
  listEmployees,
} from "../../redux/actions/employeeActions";
import { emprecords } from "../../DummyData";

const columns = [
  { field: "employeeId", headerName: "ID" },
  {
    field: "employeeCode",
    headerName: "Employee Code",
    cellClassName: "name-column--cell",
  },
  {
    field: "empName",
    headerName: "Fullname",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "empPresentAddress",
    headerName: "Present Address",
  },
  {
    field: "empParmanentAddress",
    headerName: "Permanent Address",
  },

  {
    field: "dateOfJoining",
    headerName: "Hired Date",
  },
  {
    field: "empContactNo",
    headerName: "Contact #",
  },
  {
    field: "guardianContactNo",
    headerName: "Guardian Contact #",
  },
  {
    field: "designation",
    headerName: "Designation",
  },
  {
    field: "departmentName",
    headerName: "Department",
  },
  {
    field: "channelName",
    headerName: "Location",
  },
  {
    field: "actions",
    headerName: "Action",
  },
];

const useStyles = makeStyles(() => ({
  colHeader: {
    height: 550,
    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },
    "& .MuiDataGrid-cell": {
      minWidth: "140px !important",
      maxWidth: "140px !important",
      borderBottom: "none",
    },

    "& .name-column--cell": {
      color: "#94e2cd",
    },
    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#A4A9FC",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "140px !important",
      maxWidth: "140px !important",
    },

    "& .MuiDataGrid-cell": {
      minWidth: "140px !important",
      maxWidth: "140px !important",
      borderBottom: "none",
    },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#A4A9FC",
    },

    "& .MuiCheckbox-root": {
      color: "#b7ebde !important",
    },
  },
  grid: {
    flexDirection: "column-reverse",
  },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },
}));

const InhouseEmployeesdupli = ({ history }) => {
  const dispatch = useDispatch();

  const employeeList = useSelector((state) => state.employeeList);
  const { loading, error, employees } = employeeList;

  const employeeDelete = useSelector((state) => state.employeeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = employeeDelete;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/inhouseemployees" } },
      };
      history.push(location);
    }
    dispatch(listEmployees());
  }, [dispatch, history, userInfo, successDelete]);
  // useEffect(() => {}, [employeeList]);

  const classes = useStyles();
  //   const [records, setRecords] = useState(emprecords);
  // const [filterFn, setFilterFn] = useState({
  //   fn: (items) => {
  //     return items;
  //   },
  // });

  //   const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
  //     useTable(employees, headCells, filterFn);

  // const handleSearch = (e) => {
  //   let target = e.target;
  //   setFilterFn({
  //     fn: (items) => {
  //       if (target.value == "") return items;
  //       else return items.filter((x) => x.empName.includes(target.value));
  //     },
  //   });
  // };

  const deleteEmployeeHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this data?")) {
      dispatch(deleteEmployee(id));
    }
  };
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Employees"
        subtitle="See the total Employee List"
      />
      {loadingDelete && <>loading..</>}
      {loading ? (
        "loading"
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <Button
              text="Add New"
              size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                history.push("/addemployee");
              }}
            />

            {/* <Input
              label="Search Employees"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.searchInput}
              onChange={handleSearch}
            /> */}
          </Toolbar>
          {/* <Paper className={classes.pageContent}> */}
          {/* <AddInhouseEmployee /> */}
          {/* <AddIhouseEmployeedupli /> */}
          {/* <Box
            m="10px"
            sx={{
              height: 400,
              //   width: "1246px",
              //   margin: "0 auto",
            }}
          > */}
          {/* style={{ overflowX: "scroll !important" }} */}
          {/* <Box
              m="10px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                  overflowX: "auto",
                },
                "& .MuiDataGrid-cell": {
                  minWidth: "140px !important",
                  maxWidth: "140px !important",
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: "#94e2cd",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#A4A9FC",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeader": {
                  minWidth: "140px !important",
                  maxWidth: "140px !important",
                },

                "& .MuiDataGrid-virtualScroller": {
                  overflowX: "auto",
                  backgroundColor: "#F9F9F9",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#fff",
                },
                "& .MuiCheckbox-root": {
                  color: "#b7ebde !important",
                },
              }}
            > */}
          <div className={classes.colHeader}>
            <DataGrid
              className={classes.grid}
              columnBuffer={2}
              columnThreshold={2}
              disableVirtualization
              checkboxSelection
              rows={emprecords}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.employeeId}
            />
          </div>
          {/* </Box>
          </Box> */}
          {/* </Paper> */}
          {/* <TblPagination /> */}
        </div>
      )}
    </div>
  );
};

export default InhouseEmployeesdupli;

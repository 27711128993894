import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import AddInhouseEmployee from "./AddInhouseEmployee";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIhouseEmployeedupli from "./AddIhouseEmployeedupli";
import useTable from "../../core/useTable";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import Button from "../../core/controls/Button";
import { Link } from "react-router-dom";
import ActionButton from "../../core/controls/ActionButton";
import {
  deleteEmployee,
  listEmployees,
} from "../../redux/actions/employeeActions";
const emprecords = [
  {
    id: "0",
    fullname: "Asif",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "1",
    fullname: "Shawon",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "2",
    fullname: "Jubayer",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "3",
    fullname: "Rifat",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "4",
    fullname: "Rabbi",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "5",
    fullname: "Provat",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "6",
    fullname: "Zunayed",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "7",
    fullname: "Siam",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
  {
    id: "8",
    fullname: "Rafi",
    dateofjoining: "kjasdkjhasd",
    designation: "developer",
    department: "hr",
    presentaddress: "xyz",
    location: "xyz",
    permanentaddress: "xyz",
    contact: "#19820912809128",
    email: "kajsds@gmail.com",
    username: "kajsds@gmail.com",
    password: "kajsds@gmail.com",
  },
];

const headCells = [
  {
    id: "employeeId",
    label: "ID",
  },
  {
    id: "employeeCode",
    label: "Employee Code",
  },
  {
    id: "empName",
    label: "Fullname",
  },
  {
    id: "empPresentAddress",
    label: "Present Address",
  },
  { id: "empParmanentAddress", label: "Permanent Address" },
  {
    id: "dateOfJoining",
    label: "Hired Date",
  },
  { id: "empContactNo", label: "Contact #" },
  { id: "guardianContactNo", label: "Guardian Contact #" },
  {
    id: "designationId",
    label: "Designation",
  },
  {
    id: "departmentId",
    label: "Department",
  },

  { id: "channelId", label: "Location" },
  // { id: "username", label: "Present Address", disableSorting: true },
  { id: "actions", label: "Action", disableSorting: true },
];

const useStyles = makeStyles(() => ({
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },
}));

const InhouseEmployees = ({ history }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  // console.log("classes");
  // console.log(classes);

  const employeeList = useSelector((state) => state.employeeList);
  const { loading, error, employees } = employeeList;
  // console.log("employees");
  // console.log(employees);

  const employeeDelete = useSelector((state) => state.employeeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = employeeDelete;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/inhouseemployees" } },
      };
      history.push(location);
    }
    dispatch(listEmployees());
  }, [dispatch, history, userInfo, successDelete]);
  // useEffect(() => {}, [employeeList]);

  const [records, setRecords] = useState(emprecords);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(employees, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else return items.filter((x) => x.empName.includes(target.value));
      },
    });
  };

  const deleteEmployeeHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this data?")) {
      dispatch(deleteEmployee(id));
    }
  };
  
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Employees"
        subtitle="See the total Employee List"
      />
      {loadingDelete && <>loading..</>}
      {loading ? (
        "loading"
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <Button
              text="Add New"
              size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                history.push("/addemployee");
              }}
            />

            <Input
              label="Search Employees"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.searchInput}
              onChange={handleSearch}
            />
          </Toolbar>
          <Paper className={classes.pageContent}>
            {/* <AddInhouseEmployee /> */}
            {/* <AddIhouseEmployeedupli /> */}

            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().length > 0
                  ? recordsAfterPagingAndSorting().map((item) => (
                      <TableRow key={item.employeeId}>
                        <TableCell>{item.employeeId}</TableCell>
                        <TableCell>{item.employeeCode}</TableCell>
                        <TableCell>{item.empName}</TableCell>
                        <TableCell>{item.empPresentAddress}</TableCell>
                        <TableCell>{item.empParmanentAddress}</TableCell>
                        <TableCell>{item.dateOfJoining}</TableCell>
                        <TableCell>{item.empContactNo}</TableCell>
                        <TableCell>{item.guardianContactNo}</TableCell>
                        <TableCell>{item.designationId}</TableCell>
                        <TableCell>{item.departmentId}</TableCell>
                        <TableCell>{item.channelId}</TableCell>
                        <TableCell>
                          <div style={{ display: "flex" }}>
                            <ActionButton color="primary">
                              <EditOutlinedIcon fontSize="small" />
                            </ActionButton>
                            <ActionButton
                              color="error"
                              onClick={() =>
                                deleteEmployeeHandler(item.employeeId)
                              }
                            >
                              <CloseOutlinedIcon fontSize="small" />
                            </ActionButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  : "No Data"}
              </TableBody>
            </TblContainer>
          </Paper>
          <TblPagination />
        </div>
      )}
    </div>
  );
};

export default InhouseEmployees;

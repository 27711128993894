import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  PRODUCT_PURCHASE_PRICE_CREATE_FAIL,
  PRODUCT_PURCHASE_PRICE_CREATE_REQUEST,
  PRODUCT_PURCHASE_PRICE_CREATE_SUCCESS,
  PRODUCT_PURCHASE_PRICE_DETAILS_FAIL,
  PRODUCT_PURCHASE_PRICE_DETAILS_REQUEST,
  PRODUCT_PURCHASE_PRICE_DETAILS_SUCCESS,
  PRODUCT_PURCHASE_PRICE_UPDATE_FAIL,
  PRODUCT_PURCHASE_PRICE_UPDATE_REQUEST,
  PRODUCT_PURCHASE_PRICE_UPDATE_SUCCESS,
  PRODUCT_SELLING_PRICE_CREATE_FAIL,
  PRODUCT_SELLING_PRICE_CREATE_REQUEST,
  PRODUCT_SELLING_PRICE_CREATE_SUCCESS,
  PRODUCT_SELLING_PRICE_DETAILS_FAIL,
  PRODUCT_SELLING_PRICE_DETAILS_REQUEST,
  PRODUCT_SELLING_PRICE_DETAILS_SUCCESS,
  PRODUCT_SELLING_PRICE_UPDATE_FAIL,
  PRODUCT_SELLING_PRICE_UPDATE_REQUEST,
  PRODUCT_SELLING_PRICE_UPDATE_SUCCESS,
  PURCHASE_PRICE_HISTORY_DETAILS_FAIL,
  PURCHASE_PRICE_HISTORY_DETAILS_REQUEST,
  PURCHASE_PRICE_HISTORY_DETAILS_SUCCESS,
  PURCHASE_PRICE_LIST_FAIL,
  PURCHASE_PRICE_LIST_REQUEST,
  PURCHASE_PRICE_LIST_SUCCESS,
  SELLING_PRICE_HISTORY_DETAILS_FAIL,
  SELLING_PRICE_HISTORY_DETAILS_REQUEST,
  SELLING_PRICE_HISTORY_DETAILS_SUCCESS,
  SELLING_PRICE_LIST_FAIL,
  SELLING_PRICE_LIST_REQUEST,
  SELLING_PRICE_LIST_SUCCESS,
} from "../constants/priceConstants";

export const listPurchasePrices = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PURCHASE_PRICE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Price/GetAllPurcashePriceGrid`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    dispatch({ type: PURCHASE_PRICE_LIST_SUCCESS, payload: data.dataObj.data });
  } catch (error) {
    dispatch({
      type: PURCHASE_PRICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPurchasePriceHistoryDetails =
  (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: PURCHASE_PRICE_HISTORY_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Price/GetPurchasePriceHistory/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: PURCHASE_PRICE_HISTORY_DETAILS_SUCCESS,
            payload: response.data.dataObj.data,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: PURCHASE_PRICE_HISTORY_DETAILS_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: PURCHASE_PRICE_HISTORY_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createProductPurchasePrice =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_PURCHASE_PRICE_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/Price/AddPurchasePrice`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: PRODUCT_PURCHASE_PRICE_CREATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: PRODUCT_PURCHASE_PRICE_CREATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: PRODUCT_PURCHASE_PRICE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listProductPurchasePriceDetails =
  (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: PRODUCT_PURCHASE_PRICE_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Price/GetPurchasePriceById/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);

      // console.log(data[0]);

      dispatch({
        type: PRODUCT_PURCHASE_PRICE_DETAILS_SUCCESS,
        payload: data.dataObj.data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_PURCHASE_PRICE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateProductPurchasePrice =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_PURCHASE_PRICE_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/Price/UpdateProductPurchasePrice`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: PRODUCT_PURCHASE_PRICE_UPDATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: PRODUCT_PURCHASE_PRICE_UPDATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: PRODUCT_PURCHASE_PRICE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSellingPrices = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SELLING_PRICE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Price/GetAllSellingPrice`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    dispatch({ type: SELLING_PRICE_LIST_SUCCESS, payload: data.dataObj.data });
  } catch (error) {
    dispatch({
      type: SELLING_PRICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSellingPriceHistoryDetails =
  (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: SELLING_PRICE_HISTORY_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Price/GetProductPriceHistory/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: SELLING_PRICE_HISTORY_DETAILS_SUCCESS,
            payload: response.data.dataObj.data,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: SELLING_PRICE_HISTORY_DETAILS_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: SELLING_PRICE_HISTORY_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createProductSellingPrice =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_SELLING_PRICE_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/Price/AddProductPrice`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: PRODUCT_SELLING_PRICE_CREATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: PRODUCT_SELLING_PRICE_CREATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: PRODUCT_SELLING_PRICE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listProductSellingPriceDetails =
  (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: PRODUCT_SELLING_PRICE_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Price/GetAllSellingPriceByProductId/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);

      // console.log(data[0]);

      dispatch({
        type: PRODUCT_SELLING_PRICE_DETAILS_SUCCESS,
        payload: data.dataObj.data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_SELLING_PRICE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateProductSellingPrice =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_SELLING_PRICE_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/Price/UpdateProductSellingPrice`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: PRODUCT_SELLING_PRICE_UPDATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: PRODUCT_SELLING_PRICE_UPDATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: PRODUCT_SELLING_PRICE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

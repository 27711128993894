import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Button from "../../core/controls/Button";
import {
  createProduct,
  listBrands,
  listCategories,
  listCountryOrigins,
  listFunctions,
  listGroups,
  mappedListProducts,
} from "../../redux/actions/productActions";

import { productSchema } from "../../schemas";
import { PRODUCT_CREATE_RESET } from "../../redux/constants/productConstants";
import Notification from "../../core/Notification";
import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  productName: "",
  function: {
    id: "",
    label: "",
  },
  hsCode: "",
  productPackSize: "",
  category: {
    id: "",
    label: "",
  },
  countryOrigin: {
    id: "",
    label: "",
  },
  brand: {
    id: "",
    label: "",
  },
  group: {
    id: "",
    label: "",
  },
  mappedCategory: {
    id: "",
    label: "",
  },
  mappedProduct: {
    id: "",
    label: "",
  },
  productLevel: {
    id: "",
    label: "",
  },
  productShelfLife: "",
  createdBy: "0",
  createdDate: [date.getFullYear(), mnth, day].join("-"),
  modifiedBy: "0",
  modifitedDate: [date.getFullYear(), mnth, day].join("-"),
};

const AddProduct = ({ history }) => {
  const dispatch = useDispatch();
  const [mapping, setMapping] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [stickToPageChecked, setStickToPageChecked] = useState(true);
  const classes = useStyles();

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const functionList = useSelector((state) => state.functionList);
  const {
    loading: loadingFunctions,
    error: errorFunctions,
    functions,
  } = functionList;

  const countryOriginList = useSelector((state) => state.countryOriginList);
  const {
    loading: loadingCountryOrigins,
    error: errorCountryOrigins,
    countryOrigins,
  } = countryOriginList;

  const brandList = useSelector((state) => state.brandList);
  const { loading: loadingBrands, error: errorBrands, brands } = brandList;

  const groupList = useSelector((state) => state.groupList);
  const { loading: loadingGroups, error: errorGroups, groups } = groupList;

  const mappedProductList = useSelector((state) => state.mappedProductList);
  const {
    loading: mappedLoading,
    error: mappedError,
    mappedproducts,
  } = mappedProductList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,
    onSubmit: (values, action) => {
      console.log("values");
      console.log(values);

      dispatch(
        createProduct({
          productName: values.productName,
          functionId: values.function.id !== "" ? values.function.id : null,
          categoryId: values.category.id,
          mappedWith: null,
          hsCode: values.hsCode !== "" ? values.hsCode : null,
          cooId:
            values.countryOrigin.id !== "" ? values.countryOrigin.id : null,
          brandId: values.brand.id !== "" ? values.brand.id : null,
          groupId: values.group.id !== "" ? values.group.id : null,
          sampleProduct: 1,
          productLevel:
            values.productLevel.id !== "" ? values.productLevel.id : null,
          productShelfLife: parseInt(values.productShelfLife),
          createdBy: values.createdBy,
          createdDate: values.createdDate,
          modifiedBy: values.modifiedBy,
          modifitedDate: values.modifitedDate,
          productPackSize:
            values.productPackSize !== "" ? values.productPackSize : null,
        })
      ).then((result) => {
        if (result?.title) {
          setOpenPopup({
            ...openPopup,
            title: "ERROR!!!",
            subTitle: "Something went wrong!",
            isOpen: true,
          });
          // action.setFieldError("error", result.errMsg);
          action.setSubmitting(false);
        } else if (result?.errMsg) {
          action.setFieldError("error", result.errMsg);
          action.setSubmitting(false);
        } else {
          if (stickToPageChecked === true) {
            action.resetForm();
          } else {
            history.push("/products");
          }
        }
      });
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
      dispatch(listFunctions());
      dispatch(listCountryOrigins());
      dispatch(listBrands());
      dispatch(listGroups());
      if (successCreate && stickToPageChecked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: PRODUCT_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/addproduct" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  const onTagsChange = (event, values) => {
    // console.log(values);
    dispatch(mappedListProducts(values.id));
  };

  const mappedCategoryOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const mappedProductOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const mappedCategoryChange = useCallback((e, v) => {
    formik.setFieldValue("mappedCategory", {
      id: v?.id || "",
      label: v?.label || "",
    });
    onTagsChange(e, v);
  });

  const mappedProductChange = useCallback((e, v) => {
    formik.setFieldValue("mappedProduct", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  // console.log(formik.values.category);
  // console.log(categories);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AddShoppingCartIcon />}
        title="Products"
        subtitle="Add a product"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form
          onSubmit={(e) => {
            formik.setFieldValue("createdBy", decodedata.UserSystemId);
            formik.setFieldValue("modifiedBy", decodedata.UserSystemId);
            formik.handleSubmit(e);
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loading ? true : false}
                id="combo-box-demo"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("category", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });

                  if (formik.values.mappedCategory.id !== "") {
                    formik.setFieldValue("mappedCategory", {
                      id: "",
                      label: "",
                    });
                  }
                  if (formik.values.mappedProduct.id !== "") {
                    formik.setFieldValue("mappedProduct", {
                      id: "",
                      label: "",
                    });
                  }
                })}
                value={formik.values.category}
                options={categories !== undefined ? categories : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label={
                      <Fragment>
                        Product Category
                        <span style={{ color: "#C62828" }}> *</span>
                      </Fragment>
                    }
                    name="category"
                    error={
                      formik.errors.category !== undefined
                        ? formik.errors.category.id
                        : ""
                    }
                    touched={
                      formik.touched.category !== undefined
                        ? formik.touched.category
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />

              <Input
                label={
                  <Fragment>
                    Product Name
                    <span style={{ color: "#C62828" }}> *</span>
                  </Fragment>
                }
                name="productName"
                error={formik.errors.productName}
                extraError={formik.errors.error}
                touched={formik.touched.productName}
                value={formik.values.productName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingFunctions ? true : false}
                id="combo-box-demo"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("function", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });
                })}
                value={formik.values.function}
                options={functions !== undefined ? functions : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Function Name"
                    name="function"
                    error={
                      formik.errors.function !== undefined
                        ? formik.errors.function.id
                        : ""
                    }
                    touched={
                      formik.touched.function !== undefined
                        ? formik.touched.function
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Input
                label="HS Code"
                name="hsCode"
                error={formik.errors.hsCode}
                extraError={formik.errors.error}
                touched={formik.touched.hsCode}
                value={formik.values.hsCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingCountryOrigins ? true : false}
                id="combo-box-demo"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("countryOrigin", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });
                })}
                value={formik.values.countryOrigin}
                options={countryOrigins !== undefined ? countryOrigins : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Country of Origin"
                    name="countryOrigin"
                    error={
                      formik.errors.countryOrigin !== undefined
                        ? formik.errors.countryOrigin.id
                        : ""
                    }
                    touched={
                      formik.touched.countryOrigin !== undefined
                        ? formik.touched.countryOrigin
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingBrands ? true : false}
                id="combo-box-demo"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("brand", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });
                })}
                value={formik.values.brand}
                options={brands !== undefined ? brands : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Brand Name"
                    name="brand"
                    error={
                      formik.errors.brand !== undefined
                        ? formik.errors.brand.id
                        : ""
                    }
                    touched={
                      formik.touched.brand !== undefined
                        ? formik.touched.brand
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingGroups ? true : false}
                id="combo-box-demo"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("group", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });
                })}
                value={formik.values.group}
                options={groups !== undefined ? groups : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Group Name"
                    name="group"
                    error={
                      formik.errors.group !== undefined
                        ? formik.errors.group.id
                        : ""
                    }
                    touched={
                      formik.touched.group !== undefined
                        ? formik.touched.group
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                size="small"
                // disabled={loadingGroups ? true : false}
                id="combo-box-demo"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("productLevel", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });
                })}
                value={formik.values.productLevel}
                options={[
                  {
                    id: "Level 1",
                    label: "Level 1",
                  },
                  {
                    id: "Level 2",
                    label: "Level 2",
                  },
                  {
                    id: "Level 3",
                    label: "Level 3",
                  },
                ]}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Level"
                    name="productLevel"
                    error={
                      formik.errors.productLevel !== undefined
                        ? formik.errors.productLevel.id
                        : ""
                    }
                    touched={
                      formik.touched.productLevel !== undefined
                        ? formik.touched.productLevel
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Input
                label={
                  <Fragment>
                    Product Shelf Life
                    <span style={{ color: "#C62828" }}> *</span>
                  </Fragment>
                }
                name="productShelfLife"
                error={formik.errors.productShelfLife}
                extraError={formik.errors.error}
                touched={formik.touched.productShelfLife}
                value={formik.values.productShelfLife}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">month(s)</InputAdornment>
                  ),
                }}
              />
              <Input
                label="Product Pack Size"
                name="productPackSize"
                error={formik.errors.productPackSize}
                extraError={formik.errors.error}
                touched={formik.touched.productPackSize}
                value={formik.values.productPackSize}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={stickToPageChecked}
                onChange={(e) => {
                  setStickToPageChecked(!stickToPageChecked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ButtonGroup className={classes.submitButtonGroup}>
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                    setMapping(false);
                    setChecked(false);
                    setChecked2(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/products",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
              loading &&
              loadingFunctions &&
              loadingCountryOrigins &&
              loadingBrands &&
              loadingGroups
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default AddProduct;

import {
  INVOICE_DUPLI_PRODUCT_LIST_FAIL,
  INVOICE_DUPLI_PRODUCT_LIST_REQUEST,
  INVOICE_DUPLI_PRODUCT_LIST_SUCCESS,
  INVOICE_PRODUCT_LIST_FAIL,
  INVOICE_PRODUCT_LIST_REQUEST,
  INVOICE_PRODUCT_LIST_SUCCESS,
  ORDER_TYPE_LIST_FAIL,
  ORDER_TYPE_LIST_REQUEST,
  ORDER_TYPE_LIST_SUCCESS,
  SALESORDERINTERNAL_CREATE_FAIL,
  SALESORDERINTERNAL_CREATE_REQUEST,
  SALESORDERINTERNAL_CREATE_RESET,
  SALESORDERINTERNAL_CREATE_SUCCESS,
  SALESORDERINTERNAL_DETAILS_FAIL,
  SALESORDERINTERNAL_DETAILS_REQUEST,
  SALESORDERINTERNAL_DETAILS_RESET,
  SALESORDERINTERNAL_DETAILS_SUCCESS,
  SALESORDERINTERNAL_LIST_FAIL,
  SALESORDERINTERNAL_LIST_REQUEST,
  SALESORDERINTERNAL_LIST_SUCCESS,
  TALLYSALES_CREATE_FAIL,
  TALLYSALES_CREATE_REQUEST,
  TALLYSALES_CREATE_RESET,
  TALLYSALES_CREATE_SUCCESS,
  BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_REQUEST,
  BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_SUCCESS,
  BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_FAIL,
  BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_RESET,
  SALESORDERINTERNAL_PACKAGE_REQUEST,
  SALESORDERINTERNAL_PACKAGE_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_FAIL,
  SALESORDERINTERNAL_PACKAGE_RESET,
  SALESORDERINTERNAL_UPDATE_REQUEST,
  SALESORDERINTERNAL_UPDATE_SUCCESS,
  SALESORDERINTERNAL_UPDATE_FAIL,
  SALESORDERINTERNAL_UPDATE_RESET,
  SALESORDERINTERNAL_ESCALATION_REQUEST,
  SALESORDERINTERNAL_ESCALATION_SUCCESS,
  SALESORDERINTERNAL_ESCALATION_FAIL,
  SALESORDERINTERNAL_ESCALATION_RESET,
  SALESORDERINTERNAL_PACKAGE_DONE_REQUEST,
  SALESORDERINTERNAL_PACKAGE_DONE_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_DONE_FAIL,
  SALESORDERINTERNAL_PACKAGE_DONE_RESET,
  SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_REQUEST,
  SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_FAIL,
  SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_RESET,
  SALESORDERINTERNAL_PACKAGE_DONE_EDIT_REQUEST,
  SALESORDERINTERNAL_PACKAGE_DONE_EDIT_SUCCESS,
  SALESORDERINTERNAL_PACKAGE_DONE_EDIT_FAIL,
  SALESORDERINTERNAL_PACKAGE_DONE_EDIT_RESET,
  PRICESUMMARY_CUSTOMER_PRODUCT_LIST_REQUEST,
  PRICESUMMARY_CUSTOMER_PRODUCT_LIST_SUCCESS,
  PRICESUMMARY_CUSTOMER_PRODUCT_LIST_FAIL,
  INVOICE_PACK_TYPE_LIST_REQUEST,
  INVOICE_PACK_TYPE_LIST_SUCCESS,
  INVOICE_PACK_TYPE_LIST_FAIL,
  ORDER_FROM_LIST_REQUEST,
  ORDER_FROM_LIST_SUCCESS,
  ORDER_FROM_LIST_FAIL,
  STOCKIN_CREATE_REQUEST,
  STOCKIN_CREATE_SUCCESS,
  STOCKIN_CREATE_FAIL,
  STOCKIN_CREATE_RESET,
  SALESORDERINTERNAL_FILTER_LIST_FAIL,
  SALESORDERINTERNAL_FILTER_LIST_SUCCESS,
  SALESORDERINTERNAL_FILTER_LIST_REQUEST,
  SALESORDERINTERNAL_FILTER_LIST_RESET,
  PENDING_LIST_REQUEST,
  PENDING_LIST_SUCCESS,
  PENDING_LIST_FAIL,
  JOB_LIST_SINGLE_REQUEST,
  JOB_LIST_SINGLE_SUCCESS,
  JOB_LIST_SINGLE_FAIL,
  JOB_LIST_SINGLE_RESET,
  STOCKOUT_PENDING_UPDATE_REQUEST,
  STOCKOUT_PENDING_UPDATE_SUCCESS,
  STOCKOUT_PENDING_UPDATE_FAIL,
  STOCKOUT_PENDING_UPDATE_RESET,
  SALESORDERINTERNAL_PENDING_PRICE_LIST_REQUEST,
  SALESORDERINTERNAL_PENDING_PRICE_LIST_SUCCESS,
  SALESORDERINTERNAL_PENDING_PRICE_LIST_FAIL,

//PACKING STICKER
LIST_ORDER_FOR_PACKING_EIDT_STICKER_REQUEST,
LIST_ORDER_FOR_PACKING_EIDT_STICKER_SUCCESS,
LIST_ORDER_FOR_PACKING_EIDT_STICKER_FAIL,

DETAILS_EDIT_PACKING_STICKER_REQUEST,
DETAILS_EDIT_PACKING_STICKER_SUCCESS,
DETAILS_EDIT_PACKING_STICKER_FAIL,
DETAILS_EDIT_PACKING_STICKER_RESET,

CREATE_PACKING_STICKER_REQUEST,
CREATE_PACKING_STICKER_SUCCESS,
CREATE_PACKING_STICKER_FAIL,
CREATE_PACKING_STICKER_RESET,

UPDATE_PACKING_STICKER_REQUEST,
UPDATE_PACKING_STICKER_SUCCESS,
UPDATE_PACKING_STICKER_FAIL,
UPDATE_PACKING_STICKER_RESET,

//PACKING STICKER END

LATEST_BATCH_MAP_DETAILS_REQUEST,
  LATEST_BATCH_MAP_DETAILS_SUCCESS,
  LATEST_BATCH_MAP_DETAILS_FAIL,
  LATEST_BATCH_MAP_DETAILS_RESET,

} from "../constants/invoiceConstants";

export const invoiceProductListReducer = (state = { products: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case INVOICE_PRODUCT_LIST_REQUEST:
      return { loading: true };
    case INVOICE_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case INVOICE_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const invoiceProductListReducerDupli = (
  state = { productsDupli: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case INVOICE_DUPLI_PRODUCT_LIST_REQUEST:
      return { loading: true };
    case INVOICE_DUPLI_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        productsDupli: action.payload,
      };
    case INVOICE_DUPLI_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const invoiceOrderTypeListReducer = (
  state = { orderTypes: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case ORDER_TYPE_LIST_REQUEST:
      return { loading: true };
    case ORDER_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        orderTypes: action.payload,
      };
    case ORDER_TYPE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listSalesOrderInternalReducer = (
  state = { salesorderinternal: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case SALESORDERINTERNAL_LIST_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_LIST_SUCCESS:
      return {
        loading: false,
        salesorderinternal: action.payload,
      };
    case SALESORDERINTERNAL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listSalesOrderInternalPendingPriceReducer = (
  state = { salesorderinternalpendingprice: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case SALESORDERINTERNAL_PENDING_PRICE_LIST_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_PENDING_PRICE_LIST_SUCCESS:
      return {
        loading: false,
        salesorderinternalpendingprice: action.payload,
      };
    case SALESORDERINTERNAL_PENDING_PRICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listSalesOrderInternalFilterReducer = (
  state = { salesorderinternal: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case SALESORDERINTERNAL_FILTER_LIST_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_FILTER_LIST_SUCCESS:
      return {
        loading: false,
        salesorderinternal: action.payload,
      };
    case SALESORDERINTERNAL_FILTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_FILTER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

export const TallySalesCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TALLYSALES_CREATE_REQUEST:
      return { loading: true };
    case TALLYSALES_CREATE_SUCCESS:
      return { loading: false, success: true, tallySale: action.payload };
    case TALLYSALES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TALLYSALES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const SalesOrderInternalCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SALESORDERINTERNAL_CREATE_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        createdSalesOrder: action.payload,
      };
    case SALESORDERINTERNAL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const salesOrderInternalDetailsReducer = (
  state = { salesorderinternalsingle: {} },
  action
) => {
  switch (action.type) {
    case SALESORDERINTERNAL_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SALESORDERINTERNAL_DETAILS_SUCCESS:
      return { loading: false, salesorderinternalsingle: action.payload };
    case SALESORDERINTERNAL_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_DETAILS_RESET:
      return { salesorderinternalsingle: {} };

    default:
      return state;
  }
};

export const listBatchAndMappedItemBasedOnProductReducer = (
  state = { batchAndMappedItems: {} },
  action
) => {
  switch (action.type) {
    case BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_REQUEST:
      return { loading: true, ...state };
    case BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_SUCCESS:
      return { loading: false, batchAndMappedItems: action.payload };
    case BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_RESET:
      return { batchAndMappedItems: {} };

    default:
      return state;
  }
};

export const SalesOrderInternalPackageReducer = (state = {}, action) => {
  switch (action.type) {
    case SALESORDERINTERNAL_PACKAGE_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_PACKAGE_SUCCESS:
      return {
        loading: false,
        success: true,
        packagedSalesOrder: action.payload,
      };
    case SALESORDERINTERNAL_PACKAGE_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_PACKAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const SalesOrderInternalPackageDoneReducer = (state = {}, action) => {
  switch (action.type) {
    case SALESORDERINTERNAL_PACKAGE_DONE_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_PACKAGE_DONE_SUCCESS:
      return {
        loading: false,
        success: true,
        packageDoneSalesOrder: action.payload,
      };
    case SALESORDERINTERNAL_PACKAGE_DONE_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_PACKAGE_DONE_RESET:
      return {};
    default:
      return state;
  }
};

export const SalesOrderInternalEscalateReducer = (state = {}, action) => {
  switch (action.type) {
    case SALESORDERINTERNAL_ESCALATION_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_ESCALATION_SUCCESS:
      return {
        loading: false,
        success: true,
        escalatedSalesOrder: action.payload,
      };
    case SALESORDERINTERNAL_ESCALATION_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_ESCALATION_RESET:
      return {};
    default:
      return state;
  }
};

export const SalesOrderInternalUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SALESORDERINTERNAL_UPDATE_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedSalesOrder: action.payload,
      };
    case SALESORDERINTERNAL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const salesOrderInternalPackageDoneDetailsReducer = (
  state = { salesorderinternalpackagingdonesingle: {} },
  action
) => {
  switch (action.type) {
    case SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_SUCCESS:
      return {
        loading: false,
        salesorderinternalpackagingdonesingle: action.payload,
      };
    case SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_RESET:
      return { salesorderinternalpackagingdonesingle: {} };

    default:
      return state;
  }
};

export const SalesOrderInternalPackageDoneUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case SALESORDERINTERNAL_PACKAGE_DONE_EDIT_REQUEST:
      return { loading: true };
    case SALESORDERINTERNAL_PACKAGE_DONE_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedSalesOrderPackageDone: action.payload,
      };
    case SALESORDERINTERNAL_PACKAGE_DONE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case SALESORDERINTERNAL_PACKAGE_DONE_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const listPriceSummaryBasedOnProductAndCustomerReducer = (
  state = { priceSummaryList: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case PRICESUMMARY_CUSTOMER_PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRICESUMMARY_CUSTOMER_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        priceSummaryList: action.payload,
      };
    case PRICESUMMARY_CUSTOMER_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const invoicePackTypeListReducer = (
  state = { packTypes: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case INVOICE_PACK_TYPE_LIST_REQUEST:
      return { loading: true };
    case INVOICE_PACK_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        packTypes: action.payload,
      };
    case INVOICE_PACK_TYPE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const invoiceOrderFromListReducer = (
  state = { orderFroms: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case ORDER_FROM_LIST_REQUEST:
      return { loading: true };
    case ORDER_FROM_LIST_SUCCESS:
      return {
        loading: false,
        orderFroms: action.payload,
      };
    case ORDER_FROM_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pendingListReducer = (state = { pendings: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case PENDING_LIST_REQUEST:
      return { loading: true };
    case PENDING_LIST_SUCCESS:
      return {
        loading: false,
        pendings: action.payload,
      };
    case PENDING_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ListJobSearchSingleReducer = (
  state = { singlejobsearch: {} },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case JOB_LIST_SINGLE_REQUEST:
      return { loading: true };
    case JOB_LIST_SINGLE_SUCCESS:
      return {
        loading: false,
        singlejobsearch: action.payload,
      };
    case JOB_LIST_SINGLE_FAIL:
      return { loading: false, error: action.payload };
    case JOB_LIST_SINGLE_RESET:
      return {};
    default:
      return state;
  }
};

export const StockoutPendingUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCKOUT_PENDING_UPDATE_REQUEST:
      return { loading: true };
    case STOCKOUT_PENDING_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedStockoutPending: action.payload,
      };
    case STOCKOUT_PENDING_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STOCKOUT_PENDING_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};


// export const listUndefinePackingReducer = (
//   state = { listUndef: [] },
//   action
// ) => {
//   // console.log(action.payload);
//   switch (action.type) {
//     case LIST_UNDEFINE_PACKING_STICKER_REQUEST:
//       return { loading: true };
//     case LIST_UNDEFINE_PACKING_STICKER_SUCCESS:
//       return {
//         loading: false,
//         listUndef: action.payload,
//       };
//     case LIST_UNDEFINE_PACKING_STICKER_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };


// export const listEditDefinePackingReducer = (state = { editPackingList: [] }, action) => {
//   switch (action.type) {
//     case LIST_DEFINE_EDIT_PACKING_STICKER_REQUEST:
//       return { loading: true };
//     case LIST_DEFINE_EDIT_PACKING_STICKER_SUCCESS:
//       return {
//         loading: false,
//         editPackingList: action.payload,
//       };
//     case LIST_DEFINE_EDIT_PACKING_STICKER_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

export const listOrderPackingAndEditReducer = (
  state = { orderList: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case LIST_ORDER_FOR_PACKING_EIDT_STICKER_REQUEST:
      return { loading: true };
    case LIST_ORDER_FOR_PACKING_EIDT_STICKER_SUCCESS:
      return {
        loading: false,
        orderList: action.payload,
      };
    case LIST_ORDER_FOR_PACKING_EIDT_STICKER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const packingStickerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PACKING_STICKER_REQUEST:
      return { loading: true };
    case CREATE_PACKING_STICKER_SUCCESS:
      return { loading: false, success: true, createSticker: action.payload };
    case CREATE_PACKING_STICKER_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_PACKING_STICKER_RESET:
      return {};
    default:
      return state;
  }
};

export const packingDetailsEditReducer = (
  state = { salesorderinternalpackagingdonesingle: {} },
  action
) => {
  switch (action.type) {
    case DETAILS_EDIT_PACKING_STICKER_REQUEST:
      return { loading: true, ...state };
    case DETAILS_EDIT_PACKING_STICKER_SUCCESS:
      return { loading: false, salesorderinternalpackagingdonesingle: action.payload };
    case DETAILS_EDIT_PACKING_STICKER_FAIL:
      return { loading: false, error: action.payload };
    case DETAILS_EDIT_PACKING_STICKER_RESET:
      return { salesorderinternalpackagingdonesingle: {} };

    default:
      return state;
  }
};

export const packingStickerUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PACKING_STICKER_REQUEST:
      return { loading: true };
    case UPDATE_PACKING_STICKER_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_PACKING_STICKER_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_PACKING_STICKER_RESET:
      return {};
    default:
      return state;
  }
};

//Asif
export const latestBatchAndMapDetailsReducer = (
  state = { batchMapsingle: {} },
  action
) => {
  switch (action.type) {
    case LATEST_BATCH_MAP_DETAILS_REQUEST:
      return { loading: true, ...state };
    case LATEST_BATCH_MAP_DETAILS_SUCCESS:
      return { loading: false, batchMapsingle: action.payload };
    case LATEST_BATCH_MAP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case LATEST_BATCH_MAP_DETAILS_RESET:
      return { batchMapsingle: {} };

    default:
      return state;
  }
};
import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";

import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";

import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Datepicker from "../../../core/controls/Datepicker";
import Button from "../../../core/controls/Button";
import { useEffect } from "react";
import { EMPLOYEE_CREATE_RESET } from "../../../redux/constants/employeeConstants";
import Notification from "../../../core/Notification";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";

import ListAltIcon from "@mui/icons-material/ListAlt";
import axios from "axios";

import ConveyanceExportModal from "./component/ConveyanceExportModal";
import { listEmployees } from "../../../redux/actions/employeeActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  employee: {
    id: "",
    label: "",
  },
};

const DeliveryConveyanceReport = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const employeeList = useSelector((state) => state.employeeList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    employees,
  } = employeeList;

  const getFilteredEmployeeList = (employeeList) => {
    const list = [];
    employeeList?.map((employee) => {
      list.push({
        id: employee.employeeId,
        label: `${employee.empFirstName} ${employee.empLastName}`,
      });
    });

    return list;
  };

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openConveyanceModal, setOpenConveyanceModal] = useState(false);
  const [conveyanceData, setConveyanceData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);

  const closeConveyanceModal = () => {
    setOpenConveyanceModal(false);
  };

  useEffect(() => {
    dispatch(listEmployees());
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      const axios_config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };
      const api_endpoint = `${API_URL}/Report/GetDeliveryConveyance?startDate=${
        values?.from
      }&endDate=${values?.to}&employeeId=${
        values?.employee?.id !== "" ? values?.employee?.id : "0"
      }`;

      setStartDate(values?.from);
      setEndDate(values?.to);

      if (values?.employee?.id === "") {
        setOpenPopup({
          ...openPopup,
          title: "Employee Name Not Found",
          subTitle: "Please Give Employee Name!",
          isOpen: true,
        });
      } else {
        setEmployeeId(values?.employee?.id);
        const response = await axios.get(api_endpoint, axios_config);
        const { data } = response;

        if (response?.errMsg) {
        } else {
          if (data?.dataObj?.length > 0) {
            localStorage.setItem(
              "conveyance_data",
              JSON.stringify(data?.dataObj)
            );
            setConveyanceData(data?.dataObj);
            setOpenConveyanceModal(true);
          } else {
            action.setSubmitting(false);
            setOpenPopup({
              ...openPopup,
              title: "ZERO DATA!!!",
              subTitle: "No Data found with this specifications!",
              isOpen: true,
            });
          }
        }
      }
    },
  });

  // useEffect(() => {
  //   if (userInfo) {
  //     dispatch(listCustomersForSignup());
  //     if (successCreate && checked) {
  //       setNotify({
  //         isOpen: true,
  //         message: "Added Successfully",
  //         type: "success",
  //       });

  //       dispatch({ type: EMPLOYEE_CREATE_RESET });
  //     }
  //   } else {
  //     const location = {
  //       pathname: "/signin",
  //       state: { from: { pathname: "/report/delivery-conveyance" } },
  //     };
  //     history.push(location);
  //   }
  // }, [dispatch, history, userInfo, successCreate]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AssessmentIcon />}
        title="Delivery Conveyance"
        subtitle="Delivery Conveyance Report"
      />
      <Paper className={classes.pageContent}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Datepicker
                label="From"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
              />
              <Datepicker
                label="To"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingCustomer ? true : false}
                id="combo-box-demo1"
                isOptionEqualToValue={customerOptionCheck}
                onChange={(e, v) => {
                  customerChange(e, v);
                }}
                value={formik.values.customer}
                options={
                  employees !== undefined
                    ? getFilteredEmployeeList(employees)
                    : []
                }
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Employee Name"
                    name="customer"
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                freeSolo={true}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MuiButton
                className={classes.submitButtonGroup}
                // endIcon={
                //   loadingCreate ? (
                //     <CircularProgress size="1rem" color="error" />
                //   ) : null
                // }
                size="large"
                type="submit"
              >
                <img
                  width={40}
                  height={40}
                  src={process.env.PUBLIC_URL + "/images/pdf-icon.png"}
                  alt=""
                />
              </MuiButton>

              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      {conveyanceData.length > 0 && openConveyanceModal === true ? (
        <ConveyanceExportModal
          showModal={openConveyanceModal}
          closeModal={closeConveyanceModal}
          item_list={conveyanceData}
          date_range={`${startDate} to ${endDate}`}
        />
      ) : null}
    </div>
  );
};

export default withRouter(DeliveryConveyanceReport);

import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import {LocationSchema } from "../../schemas";

import { useEffect } from "react";

import Notification from "../../core/Notification";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import { createLocationAction } from "../../redux/actions/locationAction";
import { LOCATION_CREATE_RESET } from "../../redux/constants/locationConstants";



const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

const initialValues = {
  locationName: "",
};

const AddLocation = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
   // const { values, setValues, handleChange } = useForm(initialValues);
   const channelList = useSelector((state) => state.channelList);
   const {
     loading: loadingChannels,
     error: errorChannels,
     channels,
   } = channelList;
 
  const createLocationState = useSelector((state) => state.createLocationState);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    location: createdLocation,
  } = createLocationState;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: LocationSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      // fetch("https://geolocation-db.com/json/", requestOptions)
      //   .then((response) => response.json())
      //   .then((result) => {
         
          dispatch(
            createLocationAction({
              locationId: 0,
              locationName: values.locationName,
              
            })
          ).then((result) => {
            if (result?.errMsg) {
              // console.log(result);
              if (result.errMsg == "Location Already Exist") {
                action.setFieldError("errorClientName", result.errMsg);
              } 

              action.setSubmitting(false);
            } else {
              if (checked === true) {
                action.resetForm();
              } else {
                history.push("/locationlist");
              }
            }
          });

          // if (loadingCreate === false) {
          // history.push("/customers");
          // }
        // })
        // .catch((error) => console.log("error", error));
    },
  });

  


  useEffect(() => {
    if (userInfo) {

  
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: LOCATION_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/location/add" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Location"
        subtitle="Add a Location"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Input
                label="Location Name"
                name="locationName"
                error={formik.errors.locationName}
                extraError={formik.errors.errorClientName}
                touched={formik.touched.locationName}
                value={formik.values.locationName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              
            </Grid>
                <Grid></Grid>
            <Grid item xs={12} md={12}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/locationlist",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loadingChannels ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(AddLocation);

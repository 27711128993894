import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";

import { Container, FloatingLabel, Tab, Tabs } from "react-bootstrap";
import InvoiceItem from "../../core/InvoiceItem";
import CustomTypeaheadSelect from "../../core/CustomTypeaheadSelect";
import { API_URL } from "../../redux/constants/apiConstants";

import Menu from "../../core/Menu";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import {
  createSalesOrderInternal,
  FilterProductsForInvoice,
  listBatchAndMappedItemBasedOnProduct,
  listOrderFromInvoice,
  listOrderTypeInvoice,
  listPriceSummaryBasedOnProductAndCustomer,
  listProductsForInvoice,
} from "../../redux/actions/invoiceActions";
import SalesOrderItem from "../../core/SalesOrderItem";
import InvoiceModal from "../../core/InvoiceModal";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../core/Popup";
import SalesOrderInternalModal from "../../core/SalesOrderInternalModal";
import {
  listCategories,
  listProductDetailsBasedCategory,
  listGetSamplesByCategory,
} from "../../redux/actions/productActions";
import SuccessPopup from "../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import SampleItem from "../../core/SampleItem";
import Checkbox from "../../core/controls/Checkbox";
import { listCustomerDetails } from "../../redux/actions/customerActions";
import { CUSTOMER_DETAILS_RESET } from "../../redux/constants/customerConstants";
import PaymentInfo from "../Invoices/PaymentInfo";
import {
  listSupplierDetails,
  listSuppliersForDropdown,
} from "../../redux/actions/supplierActions";
import { SUPPLIER_DETAILS_RESET } from "../../redux/constants/supplierConstants";
import { listChannel } from "../../redux/actions/channelActions";
import StockAdjustmentItem from "../../core/StockAdjustmentItem";
import {
  getChannelsList,
  getCurrentStock,
} from "../../redux/actions/CurrentStockReportActions";
import { GET_CURRENT_STOCK_RESET } from "../../redux/constants/CurrentStockReportConstants";
import { adjustStockin } from "../../redux/actions/stockInActions";

var FilteredProduct = [];

var date = new Date();
var d = date.toString();
d = d.split(" ");
//   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
//   day = ("0" + date.getDate()).slice(-2);
var d = [d[2], d[1], d[3]].join("-");

const CreatestockAdjustment = ({ history }) => {
  const dispatch = useDispatch();

  const getChannelsNameList = useSelector((state) => state.getChannelNameList);
  const {
    loading: loadingChannelsName,
    error: errorChannelsName,
    channelNameList,
  } = getChannelsNameList;

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const stockinAdjust = useSelector((state) => state.stockinAdjust);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    adjustedStockin,
  } = stockinAdjust;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const [checked, setChecked] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [mappingOption, setMappingOption] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "",
    currentStock: "",
  });

  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [data, setData] = useState({
    isOpen: false,
    soCustomerReferance: "",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),

    notes: "",

    items: [],
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(getChannelsList());
      dispatch(listProductsForInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/stockin/stockadjustment" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  const handleRowDel = (items) => {
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.productId;
    });

    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
  };

  // const getNumberOfItems = ()

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "0",
      description: "",
      quantity: 1,
    };

    if (
      data.soCustomerReferance !== "" &&
      product.id !== "" &&
      product.currentStock !== "" &&
      product.quantity !== "" &&
      selectedChannel.length > 0
    ) {
      setSwitchTStuck(true);
      data.items.push({
        productId: product.id,
        productName: product.label,
        quantity: product.quantity || 0.0,
        currentStock: product.currentStock || 0.0,
      });
      setData({
        ...data,
        items: data.items,
        vatRate: "",
        aitRate: "",
      });

      setProduct({
        ...product,
        id: "",
        label: "",
        quantity: "",
        currentStock: "",
      });
      FilteredProduct.push(product.id);
      dispatch(FilterProductsForInvoice(FilteredProduct));
      setSelected([]);
      dispatch({ type: GET_CURRENT_STOCK_RESET });
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };

  const onItemizedItemEdit = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var items = data.items.slice();

    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == item.name && sitems.productId == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });

    setData({
      ...data,
      items: newItems,
    });
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
  };

  const editProductField = (event) => {
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
  };

  const openModal = (event) => {
    event.preventDefault();

    if (data.items.length > 0) {
      var dataArray = data.items.map((singleData) => {
        return {
          productId: parseInt(singleData.productId),

          productQty: parseFloat(singleData.quantity),
        };
      });

      var data1 = {
        channelId: parseInt(selectedChannel[0].id),
        stockAdjutmentRemark: data.notes !== "" ? data.notes : null,
        saDatetime: new Date(d),
        saReferanceNo:
          data.soCustomerReferance !== "" ? data.soCustomerReferance : null,

        stockAdjutmentProducts: dataArray,
      };

      dispatch(adjustStockin(data1)).then((result) => {
        if (result?.title === "One or more validation errors occurred.") {
          setOpenPopup({
            ...openPopup,
            title: "ERROR!!!",
            subTitle: "Something went wrong!",
            isOpen: true,
          });
        } else if (result?.errMsg) {
          setOpenPopup({
            ...openPopup,
            title: "ERROR!!!",
            subTitle: `${result?.errMsg}`,
            isOpen: true,
          });
        } else {
          if (checked === true) {
            setOpenPopup2({
              ...openPopup2,
              title: `SUCCESS!!!`,
              subTitle: `Stock Adjusted.`,
              isOpen: true,
            });
            FilteredProduct = [];
            dispatch(FilterProductsForInvoice(FilteredProduct));

            setSwitchTStuck(false);
            setProduct({
              ...product,
              id: "",
              label: "",
              quantity: "",
              currentStock: "",
            });

            setSelected([]);
            setSelectedChannel([]);

            dispatch({ type: GET_CURRENT_STOCK_RESET });
            // setSelectedOrderType([]);
            setOpenPopup({
              ...openPopup,
              isOpen: false,
              title: "",
              subTitle: "",
            });

            setData({
              ...data,
              isOpen: false,
              soCustomerReferance: "",
              currentDate: "",
              dateOfIssue: new Date().toLocaleDateString(),

              notes: "",

              items: [],
            });
          } else {
            history.push("/salesorderinternal");
          }
        }
      });
    } else {
      setOpenPopup({
        ...openPopup,
        title: "ADDITION ISSUE!!!",
        subTitle: "No products added!",
        isOpen: true,
      });
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });

  const handleKeyDown = (event, callback) => {
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      callback(event);
    }
  };

  return (
    <div>
      <Menu />

      <Container>
        <Form>
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">Reference No:</Form.Label>

                      <Form.Control
                        name="soCustomerReferance"
                        type="text"
                        value={data.soCustomerReferance}
                        onChange={(event) => {
                          setData({
                            ...data,
                            soCustomerReferance: event.target.value,
                          });
                        }}
                        disabled={
                          data.items.length > 0 && switchTStuck ? true : false
                        }
                        placeholder="Reference No"

                        // required="required"
                      />
                    </Form.Group>

                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">Channel:</Form.Label>

                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="channelId"
                        label="Channel"
                        disabled={
                          loadingChannelsName
                            ? true
                            : data.items.length > 0 && switchTStuck
                            ? true
                            : false
                        }
                        onChange={(selectedChannel) => {
                          setSelectedChannel(selectedChannel);
                        }}
                        options={
                          channelNameList !== undefined ? channelNameList : []
                        }
                        placeholder="Channel"
                        selected={selectedChannel}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="form-orderTypeId">
                      <Form.Label className="fw-bold">Date:</Form.Label>
                      <Form.Control
                        placeholder={"date?"}
                        value={d}
                        type="text"
                        name="dateOfIssue"
                        onChange={(event) => editField(event)}
                        autoComplete="name"
                        required="required"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr className="my-3" />

                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-productId">
                      <Form.Label className="fw-bold">Product Name:</Form.Label>

                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="productId"
                        label="Product Name"
                        disabled={loadingProduct ? true : false}
                        onChange={(selected) => {
                          setSelected(selected);
                          const value =
                            selected.length > 0
                              ? {
                                  id: selected[0].id,
                                  label: selected[0].label,
                                }
                              : {
                                  id: "",
                                  label: "",
                                };
                          const valueChannel =
                            selectedChannel.length > 0
                              ? {
                                  id: selectedChannel[0].id,
                                  label: selectedChannel[0].label,
                                }
                              : {
                                  id: "",
                                  label: "",
                                };

                          let data = {
                            productNameId: value.id !== "" ? value.id : 0,
                            channelNameId:
                              valueChannel.id !== "" ? valueChannel.id : 0,
                          };

                          dispatch(getCurrentStock(data)).then((res) => {
                            setProduct({
                              ...product,
                              id: value.id,
                              label: value.label,
                              currentStock:
                                res.length > 0 ? res[0].currentQty : 0,
                            });
                          });
                        }}
                        options={products !== undefined ? products : []}
                        placeholder="Product Name"
                        selected={selected}
                      />
                    </Form.Group>
                    <Form.Group controlId="form-quantity-unitPrice">
                      <Form.Label className="fw-bold">Quantity:</Form.Label>
                      <Form.Control
                        name="quantity"
                        type="number"
                        value={product.quantity}
                        onChange={(event) => {
                          editProductField(event);
                        }}
                        placeholder="1"
                      />
                    </Form.Group>
                    <Button className="fw-bold my-3" onClick={handleAddEvent}>
                      Add Item
                    </Button>
                  </Col>
                  <Col>
                    <Form.Group controlId="form-quantity-unitPrice">
                      <Form.Label className="fw-bold">
                        Current Stock:
                      </Form.Label>
                      <Form.Control
                        name="currentStock"
                        type="number"
                        value={product.currentStock}
                        onChange={(event) => {
                          editProductField(event);
                        }}
                        placeholder="current stock will show here.."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <StockAdjustmentItem
                  onItemizedItemEdit={onItemizedItemEdit}
                  onRowAdd={handleAddEvent}
                  onRowDel={handleRowDel}
                  currency={data.currency}
                  items={data.items}
                  switchMappingOption={false}
                  allOptionAuto={false}
                  mappingOption={{ mappingOption, setMappingOption }}
                />

                <hr className="my-4" />
                <Form.Label className="fw-bold">Remarks:</Form.Label>
                <Form.Control
                  placeholder="Keep some notes!"
                  name="notes"
                  as="textarea"
                  className="my-2"
                  value={data.notes}
                  onChange={(event) => {
                    setData({
                      ...data,
                      notes: event.target.value,
                    });
                  }}
                  rows={1}
                />
                <hr className="my-4" />
                <Checkbox
                  label="Stay in this Page"
                  name="stay"
                  value={checked}
                  onChange={(e) => {
                    setChecked(!checked);
                  }}
                />
                <div>
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Save
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={() => {
                      history.push("/salesorderinternal");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      </Container>
    </div>
  );
};

export default CreatestockAdjustment;

export const EMPLOYEE_LIST_REQUEST = "EMPLOYEE_LIST_REQUEST";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_FAIL = "EMPLOYEE_LIST_FAIL";

export const EMPLOYEE_DROPDOWN_LIST_REQUEST = "EMPLOYEE_DROPDOWN_LIST_REQUEST";
export const EMPLOYEE_DROPDOWN_LIST_SUCCESS = "EMPLOYEE_DROPDOWN_LIST_SUCCESS";
export const EMPLOYEE_DROPDOWN_LIST_FAIL = "EMPLOYEE_DROPDOWN_LIST_FAIL";

export const EMPLOYEEALL_LIST_REQUEST = "EMPLOYEEALL_LIST_REQUEST";
export const EMPLOYEEALL_LIST_SUCCESS = "EMPLOYEEALL_LIST_SUCCESS";
export const EMPLOYEEALL_LIST_FAIL = "EMPLOYEEALL_LIST_FAIL";

export const EMPLOYEE_DETAILS_REQUEST = "EMPLOYEE_DETAILS_REQUEST";
export const EMPLOYEE_DETAILS_SUCCESS = "EMPLOYEE_DETAILS_SUCCESS";
export const EMPLOYEE_DETAILS_FAIL = "EMPLOYEE_DETAILS_FAIL";
export const EMPLOYEE_DETAILS_RESET = "EMPLOYEE_DETAILS_RESET";

export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_FAIL = "EMPLOYEE_DELETE_FAIL";
export const EMPLOYEE_DELETE_RESET = "EMPLOYEE_DELETE_RESET";

export const EMPLOYEE_DEACTIVATE_REQUEST = "EMPLOYEE_DEACTIVATE_REQUEST";
export const EMPLOYEE_DEACTIVATE_SUCCESS = "EMPLOYEE_DEACTIVATE_SUCCESS";
export const EMPLOYEE_DEACTIVATE_FAIL = "EMPLOYEE_DEACTIVATE_FAIL";
export const EMPLOYEE_DEACTIVATE_RESET = "EMPLOYEE_DEACTIVATE_RESET";

export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAIL = "EMPLOYEE_CREATE_FAIL";
export const EMPLOYEE_CREATE_RESET = "EMPLOYEE_CREATE_RESET";

export const EMPLOYEE_UPDATE_REQUEST = "EMPLOYEE_UPDATE_REQUEST";
export const EMPLOYEE_UPDATE_SUCCESS = "EMPLOYEE_UPDATE_SUCCESS";
export const EMPLOYEE_UPDATE_FAIL = "EMPLOYEE_UPDATE_FAIL";
export const EMPLOYEE_UPDATE_RESET = "EMPLOYEE_UPDATE_RESET";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCT_PRODUCTS_TOP_REQUEST = "PRODUCT_PRODUCTS_TOP_REQUEST";
export const PRODUCT_PRODUCTS_TOP_SUCCESS = "PRODUCT_PRODUCTS_TOP_SUCCESS";
export const PRODUCT_PRODUCTS_TOP_FAIL = "PRODUCT_PRODUCTS_TOP_FAIL";

export const EMPLOYEE_BY_DEPARTMENT_LIST_REQUEST =
  "EMPLOYEE_BY_DEPARTMENT_LIST_REQUEST";
export const EMPLOYEE_BY_DEPARTMENT_LIST_SUCCESS =
  "EMPLOYEE_BY_DEPARTMENT_LIST_SUCCESS";
export const EMPLOYEE_BY_DEPARTMENT_LIST_FAIL =
  "EMPLOYEE_BY_DEPARTMENT_LIST_FAIL";

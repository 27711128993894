import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import EditableProductField from "./EditableProductField";
import { Form } from "react-bootstrap";

const ActionButtons = ({ onUpdate, onRemove, onSubmit, item,enableBarcodeField }) => (
  
  <div className="text-center">
    <Button onClick={() => onSubmit(item)} variant="success" className="me-2"
    >
      Submit
    </Button>
    <Button onClick={() => {
        enableBarcodeField(item.index); // Enable the barcode field
        onUpdate(item);
      }} variant="primary" className="me-2" >
      Update
    </Button>
    <Button onClick={() => onRemove(item.index)} variant="danger">
      Remove
    </Button>
  </div>
);

class PackingItemBarcodeMap extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      editableBarcodeFields: {}, // State to manage which barcode fields are editable
    };
  }
  enableBarcodeField = (index) => {
    this.setState((prevState) => ({
      editableBarcodeFields: {
        ...prevState.editableBarcodeFields,
        [index]: true,
      },
    }));
  };
  render() {
    const { items, onItemizedItemEdit, onBarcodeChange, onUpdate, onRemove, onSubmit } 
    = this.props;
 const { editableBarcodeFields } = this.state;
    
    const handleBarcodeChange = (e, index) => {
      const { value } = e.target;
      onBarcodeChange(value, index); // Pass the barcode value and index to the parent component
    };
    const itemTable = items.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <Form.Control
              type="text"
              //value={index + 1}
              value={item.productId}
              disabled
              
              style={{
                width: "40px",
                textAlign: "center",
                backgroundColor: "transparent",
                border: "none",
              }}
            /> 
            
          </td>
          <td style={{ width: "250px" }}>
            <EditableProductField
              itemizeditemedit={onItemizedItemEdit}
              celldata={{
                type: "text",
                name: "productName",
                placeholder: "Item name",
                value: item.productName,
                id: item.index,
                index: item.index,
              }}
              style={{ width: "250px" }}
              disabled
            />
          </td>
          <td style={{ minWidth: "70px", width: "70px" }}>
            <EditableProductField
              itemizeditemedit={onItemizedItemEdit}
              celldata={{
                type: "text",
                name: "quantity",
                value: item.quantity,
                id: item.index,
                index: item.index,
              }}
              style={{ width: "100px" }}
              disabled
            />
          </td>
          <td style={{ minWidth: "80px" }}>
            <EditableProductField
              itemizeditemedit={onItemizedItemEdit}
              celldata={{
                type: "text",
                name: "packSize",
                value: item.packSize,
                id: item.index,
                index: item.index,
              }}
              style={{ width: "80px" }}
              disabled
            />
          </td>
  
          <td style={{ width: "200px" }}>
          <EditableProductField
              itemizeditemedit={onItemizedItemEdit}
              celldata={{
                type: "text",
                name: "barcodeData",
                value: item.barcodeData,
                id: item.index,
                index: item.index,
              }}
              placeholder={"Scan barcode here"}
              onBlur={(e) => handleBarcodeChange(e, index)}
              //disabled={!!item.barcodeData} // Disable field if barcodeData is not null
              style={{ width: "80px" }}
            />
          </td>
          
           <td className="text-center">
          <ActionButtons  onUpdate={onUpdate} onRemove={onRemove} onSubmit={onSubmit} item={item} 
           enableBarcodeField={this.enableBarcodeField}/>
        </td>
        </tr>
      );
    });

    return (
      <div style={{ position: "relative !important" }}>
       
        <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
          <Table>
            <thead>
              <tr>
                <th>P.Id</th>
                <th>Product Name</th>
                <th className="text-center">Order Quantity</th>
                <th className="text-center">Pack Size</th>
                <th className="text-center">Barcode Info</th>
                <th className="text-center">Action Area</th>
              </tr>
            </thead>
            <tbody>{itemTable}</tbody>
          </Table>
        </div>
        
      </div>
    );
  }
}
export default PackingItemBarcodeMap;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";

import ListIcon from "@mui/icons-material/List";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

import { useJsApiLoader } from "@react-google-maps/api";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import {
  deleteCustomer,
  listCustomers,
} from "../../redux/actions/customerActions";
import {
  Box,
  Tooltip,
  IconButton,
  Toolbar,
  Button as MuiButton,
} from "@mui/material";
import ActionButton from "../../core/controls/ActionButton";
import Button from "../../core/controls/Button";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import {
  PRODUCT_DELETE_RESET,
  PRODUCT_UPDATE_RESET,
} from "../../redux/constants/productConstants";
import {
  CUSTOMER_CREATE_RESET,
  CUSTOMER_DELETE_RESET,
  CUSTOMER_UPDATE_RESET,
} from "../../redux/constants/customerConstants";
import Popup from "../../core/Popup";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Map from "../../core/Map";
import { mapOptions } from "../../core/MapConfiguration";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import {
  CHEQUE_CREATE_RESET,
  CHEQUE_DELETE_RESET,
  CHEQUE_UPDATE_RESET,
} from "../../redux/constants/chequeConstants";
import {
  listCheque,
  listChequeDetails,
} from "../../redux/actions/chequeActions";
import EditChequeStatus from "./EditChequeStatus";
import FormPopup from "../../core/FormPopup";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "flex-start !important",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end !important",
      width: "420px",
    },
  },
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#6C4AB6",
      color: "#ffff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
    },

    // "& .MuiDataGrid-columnHeaderTitle": {
    //   minWidth: "250px !important",
    //   maxWidth: "250px !important",
    //   textAlign: "center",
    // },

    "& .MuiDataGrid-cell": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#6C4AB6",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },
    "& .MuiDataGrid-columnHeader[data-field='chequeInfoId'], .MuiDataGrid-cell[data-field='chequeInfoId']":
      {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },
    "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3']":
      {
        minWidth: "100px !important",
        maxWidth: "100px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#6C4AB6",
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },
}));

const Cheques = ({ history }) => {
  const { isLoaded } = useJsApiLoader({
    id: mapOptions.googleMapApiKey,
    googleMapsApiKey: mapOptions.googleMapApiKey,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const chequeList = useSelector((state) => state.chequeList);
  const { loading, error, cheques } = chequeList;

  const chequeDetails = useSelector((state) => state.chequeDetails);
  const {
    loading: loadingChequeDetails,
    error: errorChequeDetails,
    chequeSingle,
  } = chequeDetails;

  // console.log(customers);
  const chequeCreate = useSelector((state) => state.chequeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = chequeCreate;

  const customerDelete = useSelector((state) => state.customerDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = customerDelete;

  const chequeUpdate = useSelector((state) => state.chequeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = chequeUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // console.log(userInfo);

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [flag, setFlag] = useState(false);
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,

    title: "",
    subTitle: "",
  });
  const selectedOrderRef = useRef(null);
  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listCheque());

      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: CHEQUE_UPDATE_RESET });
      }

      if (successDelete) {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });

        dispatch({ type: CHEQUE_DELETE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: CHEQUE_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/cheque/list" } },
      };
      history.push(location);
    }
  }, [
    dispatch,
    history,
    userInfo,
    successCreate,
    successDelete,
    successUpdate,
  ]);

  const handleEvent = (params) => {
    history.push(`/cheque/${params.row.chequeInfoId}/edit`);
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
    } else {
      // console.log(ids);
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
        cheques.find((row) => row.chequeInfoId === id)
      );
      setRowId(selectedRowsData[0].chequeInfoId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    dispatch(deleteCustomer(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const columns = [
    {
      field: "actions",
      headerName: "Status Edit",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<BorderColorOutlinedIcon fontSize="small" />}
            text="Edit"
            color="warning"
            onClick={() => {
              dispatch(listChequeDetails(row.chequeInfoId)).then(() => {
                setOpenPopup2({
                  ...openPopup2,
                  isOpen: true,
                });
              });
            }}
          />
        );
      },
    },
    {
      field: "chequeInfoId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customerName",
      headerName: "Customername",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chequeAmount",
      headerName: "Cheque Amount",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chequeNumber",
      headerName: "Cheque Number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "paymentMethodName",
      headerName: "Method",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "customerAddress",
      headerName: "Customer Address",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fullname",
      headerName: "Collected By",
      valueGetter: (params) =>
        params.row.empFirstName +
        " " +
        params.row.empLastName +
        " " +
        params.row.employeeCode,
      headerAlign: "center",
      align: "center",
    },
   
  
    {
      field: "paymentChequeStatusName",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "chequeDate",
      headerName: "Cheque Date",
      valueGetter: (params) => dateConverter(params.row.chequeDate),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chequePlacementDate",
      headerName: "Placement Date",
      valueGetter: (params) => dateConverter(params.row.chequePlacementDate),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chequeExpireDate",
      headerName: "Expiry Date",
      valueGetter: (params) => dateConverter(params.row.chequeExpireDate),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chequeCollectionDate",
      headerName: "Collection Date",
      valueGetter: (params) => dateConverter(params.row.chequeCollectionDate),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chequeActionDate",
      headerName: "Action Date",
      valueGetter: (params) => dateConverter(params.row.chequeActionDate),
      headerAlign: "center",
      align: "center",
    },
  ];

  // const columnGroupingModel = [
  //   {
  //     groupId: "Full name",
  //     children: [{ field: "customerName" }, { field: "customerAddress" }],
  //   },
  // ];

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<ListIcon />}
        title="Cheques"
        subtitle="This is the cheques list"
      />

      {error === "Network Error" ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#6C4AB6" />
          {/* <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#6C4AB6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <Button
              text="Add New"
              size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              style={{ visibility: "hidden" }}
              onClick={() => {
                history.push("/addemployee");
                // setOpenPopup(true);
                // setNotify({
                //   isOpen: true,
                //   message: "Submitted Successfully",
                //   type: "success",
                // });
              }}
            />
            <div className={classes.actions}>
              <Button
                startIcon={<AddIcon fontSize="small" />}
                text="Add New"
                color="success"
                onClick={() => {
                  history.push("/cheque/create");
                }}
                style={{ marginRight: "4px" }}
              />
              <Button
                startIcon={<EditOutlinedIcon fontSize="small" />}
                text="Edit"
                color="secondary"
                onClick={() => {
                  if (flag === true || rowId === null) {
                    setOpenPopup({
                      ...openPopup,
                      title: "SELECTION ISSUE!!!",
                      subTitle:
                        "Minimum and Maximum one row have to be slected!",
                      isOpen: true,
                    });
                  }
                  if (rowClicked === true) {
                    history.push(`/cheque/${rowId}/edit`);
                  }
                }}
              />
            </div>
          </Toolbar>

          <div className={classes.colHeader}>
            <DataGrid
              className={classes.grid}
              // experimentalFeatures={{ columnGrouping: true }}
              title={"Hello"}
              columnBuffer={2}
              columnThreshold={2}
              disableVirtualization
              checkboxSelection
              rows={cheques}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.chequeInfoId + "-" + generateRandom()}
              onRowClick={handleEvent}
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
              // columnGroupingModel={columnGroupingModel}
            />
          </div>
          {/* <Map isLoaded={isLoaded} /> */}
          {JSON.stringify(chequeSingle) !== "{}" &&
            chequeSingle !== undefined && (
              <FormPopup
                title="Update Cheque Status"
                openPopup={openPopup2}
                setOpenPopup={setOpenPopup2}
              >
                {/* <AddInhouseEmployee /> */}

                <EditChequeStatus
                  openPopup={openPopup2}
                  setOpenPopup={setOpenPopup2}
                  data={chequeSingle}
                />
              </FormPopup>
            )}
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        </div>
      )}
    </div>
  );
};

export default withRouter(Cheques);

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "./controls/Button";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";

const useStyles = makeStyles(() => ({
  dialog: {
    padding: useTheme().spacing(2),
    position: "absolute",
    top: useTheme().spacing(5),
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogAction: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  titleIcon: {
    backgroundColor: useTheme().palette.error.light,
    color: useTheme().palette.error.main,
    "&:hover": {
      //   backgroundColor: useTheme().palette.error.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
      color: "#C62828",
    },
  },
}));

const ConfirmDialog = (props) => {
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogContent}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          text="No"
          color="secondary"
          onClick={() => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          }}
        />
        <Button text="Yes" color="error" onClick={confirmDialog.onConfirm} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

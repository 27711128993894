import Axios from "axios";
import Decoder from "jwt-decode";
import { API_URL } from "../constants/apiConstants";

import {
  ACTIVITYDUPLI_LIST_FAIL,
  ACTIVITYDUPLI_LIST_REQUEST,
  ACTIVITYDUPLI_LIST_SUCCESS,
  ACTIVITY_CREATE_FAIL,
  ACTIVITY_CREATE_REQUEST,
  ACTIVITY_CREATE_SUCCESS,
  ACTIVITY_LIST_FAIL,
  ACTIVITY_LIST_REQUEST,
  ACTIVITY_LIST_SUCCESS,
  DONE_ACTIVITY_DELETE_FAIL,
  DONE_ACTIVITY_DELETE_REQUEST,
  DONE_ACTIVITY_DELETE_SUCCESS,
  DONE_ACTIVITY_DETAILS_FAIL,
  DONE_ACTIVITY_DETAILS_REQUEST,
  DONE_ACTIVITY_DETAILS_SUCCESS,
  DONE_ACTIVITY_LIST_FAIL,
  DONE_ACTIVITY_LIST_REQUEST,
  DONE_ACTIVITY_LIST_SUCCESS,
  DONE_ACTIVITY_UPDATE_FAIL,
  DONE_ACTIVITY_UPDATE_REQUEST,
  DONE_ACTIVITY_UPDATE_SUCCESS,
} from "../constants/MarketingActivityConstants";

export const listActivity = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTIVITY_LIST_REQUEST });
    dispatch({ type: ACTIVITYDUPLI_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Marketing/GetActivity`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    const final = data.dataObj.map((single) => {
      return {
        id: single.mktActivityId,
        label: single.mktActivityList,
      };
    });
    dispatch({
      type: ACTIVITY_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
    dispatch({
      type: ACTIVITYDUPLI_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: ACTIVITY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch({
      type: ACTIVITYDUPLI_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FilterListActivity =
  (FilteredProduct) => async (dispatch, getState) => {
    try {
      // console.log("hiii");
      // dispatch({ type: INVOICE_PRODUCT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
        activityList: { activities },
        activityDupliList: { activitiesDupli },
      } = getState();

      // console.log("products");
      // console.log(products);
      // console.log("FilteredProduct");
      // console.log(FilteredProduct);

      var filteredResult = activitiesDupli.filter((singleData) => {
        return !FilteredProduct.includes(singleData.id);
      });
      // console.log("hiii");
      // console.log("filteredResult");
      // console.log(filteredResult);

      dispatch({
        type: ACTIVITY_LIST_SUCCESS,
        payload: filteredResult.sort((a, b) => a.label.localeCompare(b.label)),
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const ConcatListActivity =
  (ConcatActivity) => async (dispatch, getState) => {
    try {
      // console.log("hiii");
      // dispatch({ type: INVOICE_PRODUCT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
        activityList: { activities },
        activityDupliList: { activitiesDupli },
      } = getState();

      // console.log("products");
      // console.log(products);
      // console.log("ConcatActivity");
      // console.log(ConcatActivity);

      var filteredResult = activitiesDupli.filter((singleData) => {
        return ConcatActivity.includes(singleData.id);
      });
      var response1 = activities;
      var response2 = response1.concat(filteredResult);
      // console.log("hiii");
      // console.log("filteredResult");
      // console.log(filteredResult);

      dispatch({
        type: ACTIVITY_LIST_SUCCESS,
        payload: response2.sort((a, b) => a.label.localeCompare(b.label)),
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const doneActivityDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: DONE_ACTIVITY_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Marketing/GetDetailsById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    dispatch({ type: DONE_ACTIVITY_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DONE_ACTIVITY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createActivity = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTIVITY_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/Marketing/AddMarketingActivity`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    await Axios(config)
      .then(function (response) {
        // console.log(response);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    dispatch({ type: ACTIVITY_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ACTIVITY_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteActivity = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DONE_ACTIVITY_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "delete",
      url: `${API_URL}/Marketing/DeleteVisit?id=${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await Axios(config);
    dispatch({ type: DONE_ACTIVITY_DELETE_SUCCESS });
    //
  } catch (error) {
    dispatch({
      type: DONE_ACTIVITY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listDoneActivity = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DONE_ACTIVITY_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const decodedata =
      userInfo !== null && userInfo !== undefined
        ? Decoder(userInfo.token)
        : null;
    console.log(decodedata.UserSystemId);
    console.log("decodedata");

    // https://octopi.algologix.com.bd/api/Marketing/GetBindVisitByUsers?userSysId=${decodedata.UserSystemId}

    var config = {
      method: "get",

      url: `${API_URL}/Marketing/GetBindVisitByUsers?userSysId=${decodedata.UserSystemId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({ type: DONE_ACTIVITY_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DONE_ACTIVITY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateActivity = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: DONE_ACTIVITY_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    var config = {
      method: "put",

      url: `${API_URL}/Marketing/UpdateVisit`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    Axios(config).then(function (response) {
      dispatch({ type: DONE_ACTIVITY_UPDATE_SUCCESS });
    });
  } catch (error) {
    dispatch({
      type: DONE_ACTIVITY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import {
  PRODUCT_PURCHASE_PRICE_CREATE_FAIL,
  PRODUCT_PURCHASE_PRICE_CREATE_REQUEST,
  PRODUCT_PURCHASE_PRICE_CREATE_RESET,
  PRODUCT_PURCHASE_PRICE_CREATE_SUCCESS,
  PRODUCT_PURCHASE_PRICE_DETAILS_FAIL,
  PRODUCT_PURCHASE_PRICE_DETAILS_REQUEST,
  PRODUCT_PURCHASE_PRICE_DETAILS_RESET,
  PRODUCT_PURCHASE_PRICE_DETAILS_SUCCESS,
  PRODUCT_PURCHASE_PRICE_UPDATE_FAIL,
  PRODUCT_PURCHASE_PRICE_UPDATE_REQUEST,
  PRODUCT_PURCHASE_PRICE_UPDATE_RESET,
  PRODUCT_PURCHASE_PRICE_UPDATE_SUCCESS,
  PRODUCT_SELLING_PRICE_CREATE_FAIL,
  PRODUCT_SELLING_PRICE_CREATE_REQUEST,
  PRODUCT_SELLING_PRICE_CREATE_RESET,
  PRODUCT_SELLING_PRICE_CREATE_SUCCESS,
  PRODUCT_SELLING_PRICE_DETAILS_FAIL,
  PRODUCT_SELLING_PRICE_DETAILS_REQUEST,
  PRODUCT_SELLING_PRICE_DETAILS_RESET,
  PRODUCT_SELLING_PRICE_DETAILS_SUCCESS,
  PRODUCT_SELLING_PRICE_UPDATE_FAIL,
  PRODUCT_SELLING_PRICE_UPDATE_REQUEST,
  PRODUCT_SELLING_PRICE_UPDATE_RESET,
  PRODUCT_SELLING_PRICE_UPDATE_SUCCESS,
  PURCHASE_PRICE_HISTORY_DETAILS_FAIL,
  PURCHASE_PRICE_HISTORY_DETAILS_REQUEST,
  PURCHASE_PRICE_HISTORY_DETAILS_RESET,
  PURCHASE_PRICE_HISTORY_DETAILS_SUCCESS,
  PURCHASE_PRICE_LIST_FAIL,
  PURCHASE_PRICE_LIST_REQUEST,
  PURCHASE_PRICE_LIST_SUCCESS,
  SELLING_PRICE_HISTORY_DETAILS_FAIL,
  SELLING_PRICE_HISTORY_DETAILS_REQUEST,
  SELLING_PRICE_HISTORY_DETAILS_RESET,
  SELLING_PRICE_HISTORY_DETAILS_SUCCESS,
  SELLING_PRICE_LIST_FAIL,
  SELLING_PRICE_LIST_REQUEST,
  SELLING_PRICE_LIST_SUCCESS,
} from "../constants/priceConstants";

export const purchasePriceListReducer = (
  state = { purchasePrices: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case PURCHASE_PRICE_LIST_REQUEST:
      return { loading: true };
    case PURCHASE_PRICE_LIST_SUCCESS:
      return {
        loading: false,
        purchasePrices: action.payload,
      };
    case PURCHASE_PRICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const purchasePriceHistoryDetailsReducer = (
  state = { purchasePriceHistory: [] },
  action
) => {
  switch (action.type) {
    case PURCHASE_PRICE_HISTORY_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PURCHASE_PRICE_HISTORY_DETAILS_SUCCESS:
      return { loading: false, purchasePriceHistory: action.payload };
    case PURCHASE_PRICE_HISTORY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PURCHASE_PRICE_HISTORY_DETAILS_RESET:
      return { purchasePriceHistory: [] };

    default:
      return state;
  }
};

export const productPurchasePriceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PURCHASE_PRICE_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_PURCHASE_PRICE_CREATE_SUCCESS:
      return { loading: false, success: true, purchasePrice: action.payload };
    case PRODUCT_PURCHASE_PRICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_PURCHASE_PRICE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productPurchasePriceDetailsReducer = (
  state = { productPurchasePriceSingle: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_PURCHASE_PRICE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_PURCHASE_PRICE_DETAILS_SUCCESS:
      return { loading: false, productPurchasePriceSingle: action.payload };
    case PRODUCT_PURCHASE_PRICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_PURCHASE_PRICE_DETAILS_RESET:
      return { productPurchasePriceSingle: {} };

    default:
      return state;
  }
};

export const productPurchasePriceUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PURCHASE_PRICE_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_PURCHASE_PRICE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_PURCHASE_PRICE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_PURCHASE_PRICE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sellingPriceListReducer = (
  state = { sellingPrices: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case SELLING_PRICE_LIST_REQUEST:
      return { loading: true };
    case SELLING_PRICE_LIST_SUCCESS:
      return {
        loading: false,
        sellingPrices: action.payload,
      };
    case SELLING_PRICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sellingPriceHistoryDetailsReducer = (
  state = { sellingPriceHistory: [] },
  action
) => {
  switch (action.type) {
    case SELLING_PRICE_HISTORY_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SELLING_PRICE_HISTORY_DETAILS_SUCCESS:
      return { loading: false, sellingPriceHistory: action.payload };
    case SELLING_PRICE_HISTORY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SELLING_PRICE_HISTORY_DETAILS_RESET:
      return { sellingPriceHistory: [] };

    default:
      return state;
  }
};

export const productSellingPriceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_SELLING_PRICE_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_SELLING_PRICE_CREATE_SUCCESS:
      return { loading: false, success: true, sellingPrice: action.payload };
    case PRODUCT_SELLING_PRICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SELLING_PRICE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productSellingPriceDetailsReducer = (
  state = { productSellingPriceSingle: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_SELLING_PRICE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_SELLING_PRICE_DETAILS_SUCCESS:
      return { loading: false, productSellingPriceSingle: action.payload };
    case PRODUCT_SELLING_PRICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SELLING_PRICE_DETAILS_RESET:
      return { productSellingPriceSingle: {} };

    default:
      return state;
  }
};

export const productSellingPriceUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_SELLING_PRICE_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_SELLING_PRICE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_SELLING_PRICE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SELLING_PRICE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

import {
    BARCODE_CREATE_REQUEST,
    BARCODE_CREATE_SUCCESS,
    BARCODE_CREATE_FAIL,
    BARCODE_CREATE_RESET,

    BARCODE_PRINT_LIST_REQUEST,
    BARCODE_PRINT_LIST_SUCCESS,
    BARCODE_PRINT_LIST_FAIL,
    BARCODE_PRINT_LIST_RESET,
    
    BARCODE_REMOVE_SUCCESS,
    BARCODE_REMOVE_REQUEST,
    BARCODE_REMOVE_FAIL,
    BARCODE_REMOVE_RESET,

    REPACKING_BARCODE_CREATE_REQUEST,
    REPACKING_BARCODE_CREATE_SUCCESS,
    REPACKING_BARCODE_CREATE_RESET,
    REPACKING_BARCODE_CREATE_FAIL,

    REPACKING_PRINT_LIST_SUCCESS,
    REPACKING_PRINT_LIST_REQUEST,
    REPACKING_PRINT_LIST_FAIL,
    REPACKING_PRINT_LIST_RESET,

    REPACKING_BARCODE_REMOVE_SUCCESS,
    REPACKING_BARCODE_REMOVE_REQUEST,
    REPACKING_BARCODE_REMOVE_RESET,
    REPACKING_BARCODE_REMOVE_FAIL,

    BARCODE_STOCK_OUT_MAP_SUCCESS,
    BARCODE_STOCK_OUT_MAP_REQUEST,
    BARCODE_STOCK_OUT_MAP_FAIL,
    BARCODE_STOCK_OUT_MAP_RESET,

    BARCODE_DECODE_SUCCESS,
    BARCODE_DECODE_REQUEST,
    BARCODE_DECODE_FAIL,
    BARCODE_DECODE_RESET,

    
    INSERT_SO_BARCODE_REQUEST,
    INSERT_SO_BARCODE_SUCCESS,
    INSERT_SO_BARCODE_FAIL,
    INSERT_SO_BARCODE_RESET,
    
    UPDATE_SO_BARCODE_REQUEST,
    UPDATE_SO_BARCODE_SUCCESS,
    UPDATE_SO_BARCODE_FAIL,
    UPDATE_SO_BARCODE_RESET,

    REMOVE_SO_BARCODE_REQUEST,
    REMOVE_SO_BARCODE_SUCCESS,
    REMOVE_SO_BARCODE_FAIL,
    REMOVE_SO_BARCODE_RESET,

}from "../constants/BarcodeConstants"


export const barcodeCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case BARCODE_CREATE_REQUEST:
        return { loading: true };
      case BARCODE_CREATE_SUCCESS:
        return { loading: false, success: true, barcode: action.payload };
      case BARCODE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case BARCODE_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };

  export const listPrintBarcodeInfoReducer = (
    state = { printbarcode: [] },
    action
  ) => {
    switch (action.type) {
      case BARCODE_PRINT_LIST_REQUEST:
        return { loading: true };
      case BARCODE_PRINT_LIST_SUCCESS:
        return {
          loading: false,
          printbarcode: action.payload,
        };
      case BARCODE_PRINT_LIST_FAIL:
        return { loading: false, error: action.payload };
      case BARCODE_PRINT_LIST_RESET:
        return [];
      default:
        return state;
    }
  };
  
  export const barcodeRemoveByParamsReducer = (state = {}, action) => {
    switch (action.type) {
      case BARCODE_REMOVE_REQUEST:
        return { loading: true };
      case BARCODE_REMOVE_SUCCESS:
        return { loading: false, success: true };
      case BARCODE_REMOVE_FAIL:
        return { loading: false, error: action.payload };
      case BARCODE_REMOVE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const repackingBarcodeCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case REPACKING_BARCODE_CREATE_REQUEST:
        return { loading: true };
      case REPACKING_BARCODE_CREATE_SUCCESS:
        return { loading: false, success: true, barcode: action.payload };
      case REPACKING_BARCODE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case REPACKING_BARCODE_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };

  export const repackingListBarcodeReducer = (
    state = { printbarcode: [] },
    action
  ) => {
    switch (action.type) {
      case REPACKING_PRINT_LIST_REQUEST:
        return { loading: true };
      case REPACKING_PRINT_LIST_SUCCESS:
        return {
          loading: false,
          printbarcode: action.payload,
        };
      case REPACKING_PRINT_LIST_FAIL:
        return { loading: false, error: action.payload };
      case REPACKING_PRINT_LIST_RESET:
        return [];
      default:
        return state;
    }
  };
  export const repackingRemoveBarcodeReducer = (state = {}, action) => {
    switch (action.type) {
      case REPACKING_BARCODE_REMOVE_REQUEST:
        return { loading: true };
      case REPACKING_BARCODE_REMOVE_SUCCESS:
        return { loading: false, success: true };
      case REPACKING_BARCODE_REMOVE_FAIL:
        return { loading: false, error: action.payload };
      case REPACKING_BARCODE_REMOVE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const packingListBarcodeMapReducer = (
    state = { salesorderinternalpackagingdonesingle: {} },
    action
  ) => {
    switch (action.type) {
      case BARCODE_STOCK_OUT_MAP_REQUEST:
        return { loading: true, ...state };
      case BARCODE_STOCK_OUT_MAP_SUCCESS:
        return { loading: false, salesorderinternalpackagingdonesingle: action.payload };
      case BARCODE_STOCK_OUT_MAP_FAIL:
        return { loading: false, error: action.payload };
      case BARCODE_STOCK_OUT_MAP_RESET:
        return { salesorderinternalpackagingdonesingle: {} };
  
      default:
        return state;
    }
  };
  

  export const barcodeDecodeReducer = (
    state = { decodedData: {} },
    action
  ) => {
    switch (action.type) {
      case BARCODE_DECODE_REQUEST:
        return { loading: true, ...state };
      case BARCODE_DECODE_SUCCESS:
        return { loading: false, decodedData: action.payload };
      case BARCODE_DECODE_FAIL:
        return { loading: false, error: action.payload };
      case BARCODE_DECODE_RESET:
        return { decodedData: {} };
  
      default:
        return state;
    }
  };
  
  export const barcodeDecodeReducer1 = (
    state = { decodedData: {} },
    action
  ) => {
    switch (action.type) {
      case BARCODE_DECODE_REQUEST:
        return { ...state, loading: true };
      case BARCODE_DECODE_SUCCESS:
        console.log('Previous state:', state);
        console.log('Action payload:', action.payload);
        const newState = {
          ...state,
          decodedData: {
            barcodeProductName: action.payload.dataObj.productName,
            barcodePackSize: action.payload.dataObj.packSize,
            // Add other properties if needed
          },
          loading: false,
          error: null,
        };
        console.log('New state:', newState);
        return newState;
      case BARCODE_DECODE_FAIL:
        return { ...state, loading: false, error: action.payload };
      case BARCODE_DECODE_RESET:
        return { decodedData: {} };
      default:
        return state;
    }
  };
  
  // Inside your reducer
// export const barcodeDecodeReducer = (decodedData = {}, action) => {
//   switch (action.type) {
//     case BARCODE_DECODE_REQUEST:
//       console.log("Reducer Barcode decode request"); // Log when the decode request is initiated
//       return { loading: true };
//     case BARCODE_DECODE_SUCCESS:
//       console.log("Reducer Barcode decode success. Decoded data:", action.payload); // Log the decoded data received from the action payload
//       return { loading: false, success: true, decodedData: action.payload };
//     case BARCODE_DECODE_FAIL:
//       console.error("Reducer Barcode decode fail. Error:", action.payload); // Log any errors that occur during decoding
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
  export const insertSOBarcodeReducer = (state = {}, action) => {
    switch (action.type) {
      case INSERT_SO_BARCODE_REQUEST:
        return { loading: true };
      case INSERT_SO_BARCODE_SUCCESS:
        return { loading: false, success: true, barcode: action.payload };
      case INSERT_SO_BARCODE_FAIL:
        return { loading: false, error: action.payload };
      case INSERT_SO_BARCODE_RESET:
        return {};
      default:
        return state;
    }
  };

  export const updateStockOutBarcodeReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_SO_BARCODE_REQUEST:
        return { loading: true };
      case UPDATE_SO_BARCODE_SUCCESS:
        return { loading: false, success: true };
      case UPDATE_SO_BARCODE_FAIL:
        return { loading: false, error: action.payload };
      case UPDATE_SO_BARCODE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const removeStockOutBarcodeReducer = (state = {}, action) => {
    switch (action.type) {
      case REMOVE_SO_BARCODE_REQUEST:
        return { loading: true };
      case REMOVE_SO_BARCODE_SUCCESS:
        return { loading: false, success: true };
      case REMOVE_SO_BARCODE_FAIL:
        return { loading: false, error: action.payload };
      case REMOVE_SO_BARCODE_RESET:
        return {};
      default:
        return state;
    }
  };
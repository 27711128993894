import {
    GET_ALLOWANCE_CHECKING_REQUEST,
    GET_ALLOWANCE_CHECKING_SUCCESS,
    GET_ALLOWANCE_CHECKING_FAIL,
  } from "../constants/allowanceCheckingConstants";


  
  //get daily invoice tracking report list
  export const allowanceCheckingReducer = (
    state = { allowanceCheckerReport: [] },
    action
  ) => {
    //  console.log(action.payload);
    switch (action.type) {
      case GET_ALLOWANCE_CHECKING_REQUEST:
        return { loading: true };
      case GET_ALLOWANCE_CHECKING_SUCCESS:
        return {
          loading: false,
          allowanceCheckerReport: action.payload,
        };
      case GET_ALLOWANCE_CHECKING_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8BcJVAtI4U8DjEKyqGuPdgyJ9SDRl2Tg",
  authDomain: "octopifi-images.firebaseapp.com",
  projectId: "octopifi-images",
  storageBucket: "octopifi-images.appspot.com",
  messagingSenderId: "604423636632",
  appId: "1:604423636632:web:ad28cd8191731ebf2172ed",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import {
  TaskSchema,
} from "../../schemas";
import { useEffect } from "react";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDeparment,
  listDesignation,
} from "../../redux/actions/channelActions";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  listCustomersForSignup,
  listEmployees,
  listEmployeesForDropdown,
} from "../../redux/actions/userActions";
import {
  createCheque,
  listBank,
  listChequeDetails,
  listChequeStatus,
  listChequeType,
} from "../../redux/actions/chequeActions";
import {
  listInvoiceCheckKeyValue,
  listInvoiceDetails,
  listPaymentMode,
} from "../../redux/actions/paymentActions";
import {
  INVOICE_DETAILS_RESET,
  PAYMENT_CREATE_RESET,
} from "../../redux/constants/paymentConstants";
import {
  FilterChecksForTask,
  createTask,
  fetchTaskName,
  listCheckForTask,
  listOrderTypeTask,
} from "../../redux/actions/taskActions";
import Popup from "../../core/Popup";
import useTable from "../../core/useTable";
import ActionButton from "../../core/controls/ActionButton";
import { listSalesOrderInternalDetails } from "../../redux/actions/invoiceActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
  pageContentForTable: {
    overflowX: "auto",
    overflowY: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
    "& ::-webkit-scrollbar": {
      width: "0.3rem",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#F2F1F1",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  isInvoice: true,
  employee: {
    id: "",
    label: "",
  },
  invoiceRelatedTask: "0",
  taskName: "",
  taskDate: [date.getFullYear(), mnth, day].join("-"),
  invoiceCheque: {
    id: "",
    label: "",
  },
  taskDescription: "",
  destinationName: "",
  destinationAddress: "",
  jobType: {
    id: "",
    label: "",
  },
  check: {
    id: "",
    label: "",
  },
};

const headCells = [
  {
    id: "serialno",
    label: "S.No.",
  },
  {
    id: "name",
    label: "Checklist Name",
  },

  { id: "actions", label: "Action", disableSorting: true },
];

var FilteredChecklist = [];
const AddTask = ({ match, history }) => {
  const systemId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [order, setOrder] = useState("0");
  const [data, setData] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

// Add a state variable to hold the default checklist IDs
const [defaultCheckListIds, setDefaultCheckListIds] = useState([]);


  const taskJobTypeListReducer = useSelector(
    (state) => state.taskJobTypeListReducer
  );
  const {
    loading: loadingTaskJobType,
    error: errorTaskJobType,
    jobTypes,
  } = taskJobTypeListReducer;

  const invoiceChequeList = useSelector((state) => state.invoiceChequeList);
  const {
    loading: loadingInvoiceCheque,
    error: errorInvoiceCheque,
    invoicecheques,
  } = invoiceChequeList;


  const employeeList = useSelector((state) => state.employeeList);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    employees,
  } = employeeList;


  const invoiceDetailsReducer = useSelector(
    (state) => state.invoiceDetailsReducer
  );
  const {
    loading: loadingInvoiceDetailsReducer,
    error: errorInvoiceDetailsReducer,
    invoiceSingle,
  } = invoiceDetailsReducer;

  const taskCheckList = useSelector((state) => state.taskCheckList);
  const { loading: loadingChecks, error: errorChecks, checks } = taskCheckList;
  const bankList = useSelector((state) => state.bankList);
  const { loading: loadingBanks, error: errorBanks, banks } = bankList;

  const chequeStatusList = useSelector((state) => state.chequeStatusList);
  const {
    loading: loadingChequeStatus,
    error: errorChequeStatus,
    chequestatuss,
  } = chequeStatusList;

  const channelList = useSelector((state) => state.channelList);
  const { loading: loadingChannels, error, channels } = channelList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const taskCreate = useSelector((state) => state.taskCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    task: createdPayment,
  } = taskCreate;
  // console.log(loadingCreate);
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(data, headCells, filterFn);

//chat gpt start task create issue resolved checklist

const formik = useFormik({
  initialValues,
  validationSchema: TaskSchema,
  onSubmit: async (values, action) => {
    try {
      const checkListIds = data.map((single) => single.id);
      if (checkListIds.length === 0) {
        throw new Error("EMPTY FIELD(S)!!! Please Select a Checklist!");
      }

      const taskData = {
        taskId: 25,
        taskName: values.taskName,
        taskDescription: values.taskDescription,
        taskDestination: values.destinationName,
        taskDestinationAddress: values.destinationAddress,
        taskJobTypeId: null,
        taskRelatedInvoiceId: values.invoiceCheque.id || null,
        taskRelatedInvoiceName: values.invoiceCheque.label || null,
        taskAssignedTo: values.employee.id,
        taskRelatedTaskId: values.invoiceRelatedTask,
        taskChecklists: checkListIds,
      };

      const result = await dispatch(createTask(taskData));

      if (result?.title === "One or more validation errors occurred.") {
        setOpenPopup({
          ...openPopup,
          title: "ERROR!!!",
          subTitle: "Something went wrong!",
          isOpen: true,
        });
        action.setSubmitting(false);
      } else if (result?.errMsg) {
        if (result.errMsg === "Customer Already Exist") {
          action.setFieldError("errorClientName", result.errMsg);
        } else if (result.errMsg === "Contact Number Already Exist") {
          action.setFieldError("errorClientPhone", result.errMsg);
        }
        action.setSubmitting(false);
      } else {
        if (checked === true) {
          FilteredChecklist = [];
          action.resetForm();
          setData([]);
        } else {
          FilteredChecklist = [];
          history.push("/task/assign");
        }
      }
    } catch (error) {
      setOpenPopup({
        ...openPopup,
        title: "ERROR!!!",
        subTitle: error.message,
        isOpen: true,
      });
    }
  },
});
//Asif Code task name
///*
  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      dispatch(fetchTaskName()).then((res) => {
        formik.setFieldValue("taskName", res.data);
      });
      if (formik.values.invoiceRelatedTask === "0") {
        dispatch(listSalesOrderInternalDetails(systemId)).then((res) => {
          formik.setFieldValue("invoiceCheque", {
            id: res.stockOutGeneralData.soSystemId || "",
            label: res.stockOutGeneralData.soSystemNo || "",
          });
          formik.setFieldValue(
            "destinationName",
            res.stockOutGeneralData.customerName||res.stockOutGeneralData.supplierName
          );
          formik.setFieldValue(
            "destinationAddress",
            res.stockOutGeneralData.customerAddress||res.stockOutGeneralData.supplierAddress
          );
        });
        dispatch(listInvoiceDetails(systemId));
      }
      dispatch(listInvoiceCheckKeyValue(order));
      dispatch(listPaymentMode(2));
      dispatch(listOrderTypeTask(-1));
      dispatch(listCheckForTask());
      dispatch(listEmployeesForDropdown());
      dispatch(listChequeStatus());
      dispatch(listBank());
      dispatch(listChequeType());
      dispatch(listDesignation());
      dispatch(listChannel());
      dispatch(listDeparment());
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: PAYMENT_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/task/create" } },
      };
      history.push(location);
    }
  }, [order, dispatch, history, userInfo, successCreate, systemId]);
//asif end

  const changeInvoiceValues = () => {
    if (JSON.stringify(invoiceSingle) !== "{}" && invoiceSingle !== undefined) {
      formik.setFieldValue("invoiceNumber", invoiceSingle.soSystemNo || "");
      formik.setFieldValue("invoiceAmount", invoiceSingle.sobFinalBill || "");
      formik.setFieldValue("customer", {
        id: invoiceSingle.customerId || "",
        label: invoiceSingle.customerName || "",
      });
    }
  };

  useEffect(() => {
    changeInvoiceValues();
  }, [invoiceSingle]);
  
  const channelChange = useCallback((e, v) => {
    formik.setFieldValue("channel", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const departmentChange = useCallback((e, v) => {
    formik.setFieldValue("department", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const jobTypeChange = useCallback((e, v) => {
    formik.setFieldValue("jobType", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const checkChange = useCallback((e, v) => {
    formik.setFieldValue("check", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeTypeChange = useCallback((e, v) => {
    formik.setFieldValue("chequeType", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const paymentModeChange = useCallback((e, v) => {
    formik.setFieldValue("paymentMode", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeChange = useCallback((e, v) => {
    formik.setFieldValue("invoiceCheque", {
      id: v?.id || "",
      label: v?.label || "",
    });

    if (v?.id === undefined) {
      formik.setFieldValue("destinationName", "");
      formik.setFieldValue("destinationAddress", "");
    } else if (v?.id !== undefined && order === "0") {
      dispatch(listSalesOrderInternalDetails(v?.id)).then((res) => {
        formik.setFieldValue(
          "destinationName",
          res.stockOutGeneralData.customerName || res.stockOutGeneralData.supplierName

        );
        formik.setFieldValue(
          "destinationAddress",
          //res.stockOutGeneralData.customerAddress
          res.stockOutGeneralData.customerAddress||res.stockOutGeneralData.supplierAddress
        );
      });
      dispatch(listInvoiceDetails(v?.id));
    } else if (v?.id !== undefined && order === "1") {
      dispatch(listChequeDetails(v?.id));
    }
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });


  const invoiceChequeCheck = useCallback(
    (option, value) => option.value === value.value
  );

  
  const handleAddEvent = (evt) => {
    var items = {
      id: "",
      name: "",
      serialno: "",
    };
    if (formik.values.check.id !== "") {
      data.push({
        id: formik.values.check.id,
        name: formik.values.check.label,
      });
      var newData = data.map((data, i) => {
        return {
          ...data,
          serialno: i + 1,
        };
      });
      setData(newData);
      FilteredChecklist.push(formik.values.check.id);
      dispatch(FilterChecksForTask(FilteredChecklist));
      formik.setFieldValue("check", {
        id: "",
        label: "",
      });
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "Please Select a Checklist!",
        isOpen: true,
      });
    }
  };

  const handleRowDel = (items) => {
    FilteredChecklist = FilteredChecklist.filter((item) => {
      return item !== items;
    });
    dispatch(FilterChecksForTask(FilteredChecklist));
    var index = data.findIndex((object) => {
      return object.id === items;
    });
    data.splice(index, 1);
    var newData = data.map((data, i) => {
      return {
        ...data,
        serialno: i + 1,
      };
    });
    setData(newData);
  };
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Tasks"
        subtitle="Add a Task"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingInvoiceCheque ? true : true}
                id="combo-box-demo-channel"
                isOptionEqualToValue={invoiceChequeCheck}
                onChange={invoiceChequeChange}
                value={formik.values.invoiceCheque}
                options={invoicecheques !== undefined ? invoicecheques : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label={order === "0" ? "Invoice No" : "Cheque No"}
                    name="invoiceCheque"
                    error={
                      formik.errors.invoiceCheque !== undefined
                        ? formik.errors.invoiceCheque.id
                        : ""
                    }
                    touched={
                      formik.touched.invoiceCheque !== undefined
                        ? formik.touched.invoiceCheque
                        : ""
                    }
                    {...params}
                    // style={{ width: "98%" }}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingEmployee ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={employeeChange}
                value={formik.values.employee}
                options={employees !== undefined ? employees : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Assign To"
                    name="employee"
                    error={
                      formik.errors.employee !== undefined
                        ? formik.errors.employee.id
                        : ""
                    }
                    touched={
                      formik.touched.employee !== undefined
                        ? formik.touched.employee
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Input
                label="Task Name"
                name="taskName"
                error={formik.errors.taskName}
                touched={formik.touched.taskName}
                value={formik.values.taskName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Datepicker
                label="Task Date"
                name="taskDate"
                value={formik.values.taskDate}
                onChange={formik.handleChange}
              />
              <Input
                label="Task Description"
                name="taskDescription"
                error={formik.errors.taskDescription}
                touched={formik.touched.taskDescription}
                value={formik.values.taskDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Destination Name"
                name="destinationName"
                error={formik.errors.destinationName}
                touched={formik.touched.destinationName}
                value={formik.values.destinationName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ width: "98%" }}
                disabled={order === "0" ? true : false}
              />
              <Input
                label="Destination Address"
                name="destinationAddress"
                error={formik.errors.destinationAddress}
                touched={formik.touched.destinationAddress}
                value={formik.values.destinationAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                style={{ width: "98%" }}
                disabled={order === "0" ? true : false}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingChecks ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={useCallback(
                    (option, value) => option.value === value.value
                  )}
                  onChange={checkChange}
                  value={formik.values.check}
                  options={checks !== undefined ? checks : []}
                  onOpen={formik.handleBlur}
                  style={{ flex: 1 }}
                  renderInput={(params) => (
                    <Input
                      label="Checklist"
                      name="check"
                      error={
                        formik.errors.check !== undefined
                          ? formik.errors.check.id
                          : ""
                      }
                      touched={
                        formik.touched.check !== undefined
                          ? formik.touched.check
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                      style={{ width: "94%" }}
                    />
                  )}
                />
                <Button
                  color="success"
                  text="Add"
                  size="small"
                  endIcon={<AddCircleOutlineIcon />}
                  style={{ marginLeft: "8px" }}
                  onClick={handleAddEvent}
                />
              </div>
              <Paper
                className={classes.pageContentForTable}
                sx={{ width: "100%", overflow: "hidden" }}
              >
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {data.length > 0 ? (
                      recordsAfterPagingAndSorting().map((item, i) => (
                        <TableRow key={i}>
                          <TableCell>{item.serialno}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                color="error"
                                onClick={() => {
                                  handleRowDel(item.id);
                                 
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </ActionButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>

                        <TableCell>No Data</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </TblContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                    setData([]);
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/task/assign",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
              loadingChannels && loadingDesignations && loadingDepartments
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
export default withRouter(AddTask);

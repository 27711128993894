import {
    LOCATION_CREATE_FAIL,
    LOCATION_CREATE_REQUEST,
    LOCATION_CREATE_RESET,
    LOCATION_CREATE_SUCCESS,
    LOCATION_DELETE_FAIL,
    LOCATION_DELETE_REQUEST,
    LOCATION_DELETE_RESET,
    LOCATION_DELETE_SUCCESS,

    LOCATION_DETAILS_FAIL,
    LOCATION_DETAILS_REQUEST,
    LOCATION_DETAILS_RESET,
    LOCATION_DETAILS_SUCCESS,
    
    LOCATION_DROPDOWN_LIST_FAIL,
    LOCATION_DROPDOWN_LIST_REQUEST,
    LOCATION_DROPDOWN_LIST_SUCCESS,
    LOCATION_LIST_FAIL,
    LOCATION_LIST_REQUEST,
    LOCATION_LIST_SUCCESS,
    LOCATION_UPDATE_FAIL,
    LOCATION_UPDATE_REQUEST,
    LOCATION_UPDATE_RESET,
    LOCATION_UPDATE_SUCCESS,
  } from "../constants/locationConstants";
  
  export const locationListReducer = (state = { locations: [] }, action) => {
    // console.log(action.payload);
    switch (action.type) {
      case LOCATION_LIST_REQUEST:
        return { loading: true };
      case LOCATION_LIST_SUCCESS:
        return {
          loading: false,
          locations: action.payload,
        };
      case LOCATION_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const locationCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_CREATE_REQUEST:
        return { loading: true };
      case LOCATION_CREATE_SUCCESS:
        return { loading: false, success: true, location: action.payload };
      case LOCATION_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case LOCATION_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const locationDetailsReducer = (state = { locations: {} }, action) => {
    switch (action.type) {
      case LOCATION_DETAILS_REQUEST:
        return { loading: true, ...state };
      case LOCATION_DETAILS_SUCCESS:
        return { loading: false, locations: action.payload };
      case LOCATION_DETAILS_FAIL:
        return { loading: false, error: action.payload };
        case LOCATION_DETAILS_RESET:
          return { functions: {} };
  
      default:
        return state;
    }
  };

  
  
  export const locationUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_UPDATE_REQUEST:
        return { loading: true };
      case LOCATION_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case LOCATION_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case LOCATION_UPDATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const locationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_DELETE_REQUEST:
        return { loading: true };
      case LOCATION_DELETE_SUCCESS:
        return { loading: false, success: true };
      case LOCATION_DELETE_FAIL:
        return { loading: false, error: action.payload };
      case LOCATION_DELETE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const locationDropdownListReducer = (
    state = { locations: [] },
    action
  ) => {
    // console.log(action.payload);
    switch (action.type) {
      case LOCATION_DROPDOWN_LIST_REQUEST:
        return { loading: true, ...state };
      case LOCATION_DROPDOWN_LIST_SUCCESS:
        return {
          loading: false,
          locations: action.payload,
        };
      case LOCATION_DROPDOWN_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
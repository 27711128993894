import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";
const RadioGroup = (props) => {
  const { name = null, label = null, value, onChange, items } = props;
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup row={true} name={name} value={value} onChange={onChange}>
        {items.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.id}
            control={
              <Radio
                size="small"
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 14,
                  },
                }}
              />
            }
            label={item.title}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;

export const BATCH_LIST_REQUEST = "BATCH_LIST_REQUEST";
export const BATCH_LIST_SUCCESS = "BATCH_LIST_SUCCESS";
export const BATCH_LIST_FAIL = "BATCH_LIST_FAIL";

export const BATCH_DETAILS_REQUEST = "BATCH_DETAILS_REQUEST";
export const BATCH_DETAILS_SUCCESS = "BATCH_DETAILS_SUCCESS";
export const BATCH_DETAILS_FAIL = "BATCH_DETAILS_FAIL";
export const BATCH_DETAILS_RESET = "BATCH_DETAILS_RESET";

export const BATCH_UPDATE_REQUEST = "BATCH_UPDATE_REQUEST";
export const BATCH_UPDATE_SUCCESS = "BATCH_UPDATE_SUCCESS";
export const BATCH_UPDATE_FAIL = "BATCH_UPDATE_FAIL";
export const BATCH_UPDATE_RESET = "BATCH_UPDATE_RESET";


//shahed for batch map info for sales orderitem.js
export const GET_LAST_MAPP_AND_BATCH_REQUEST = "GET_LAST_MAPP_AND_BATCH_REQUEST";
export const GET_LAST_MAPP_AND_BATCH_SUCCESS = "GET_LAST_MAPP_AND_BATCH_SUCCESS";
export const GET_LAST_MAPP_AND_BATCH_FAIL = "GET_LAST_MAPP_AND_BATCH_FAIL";

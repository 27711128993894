import * as Yup from "yup";

export const signUpSchema = Yup.object({
  customer: Yup.object().shape({
    id: Yup.string().required("Please write your company name"),
    // etc
  }),
  userFirstName: Yup.string().required("Please enter your firstname"),
  userLastName: Yup.string().required("Please enter your lastname"),

  userContactNo: Yup.string()
    .matches(
      /^(01){1}[3456789]{1}\d{8}/,
      "Must be a valid bangladeshi mobile number"
    )
    .required("please enter your mobile number"),

  userMail: Yup.string().email().required("Please enter your email"),
  userPassword: Yup.string()
    .required("Please enter your password")
    .test(
      "isValidPass",
      "must contain atleaset a number, an uppercase letter and any symbol(!#$%&'()*+,-./:;<=>?@[]^_`{|}~)",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (
          hasUpperCase &&
          hasNumber &&
          hasLowerCase &&
          hasSymbole &&
          validConditions >= numberOfMustBeValidConditions
        ) {
          return true;
        }
        return false;
      }
    ),
  userConfirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("userPassword"), null], "Password must match"),
});

export const signinSchema = Yup.object({
  username: Yup.string().required("please enter your username"),
  userPassword: Yup.string().required("please enter your password"),
});

export const bookSummarySchema = Yup.object({
  book_summary: Yup.string().required(
    "please add some information about this book"
  ),
});

export const bookSchema = Yup.object({
  title: Yup.string().required("Please enter book name"),
  published_year: Yup.string()
    .matches(/^[0-9]+$/, "must be only digits")
    .required("please enter publishing year"),

  author: Yup.string().required("please enter Author's name"),
});

export const InhouseEmployeeSchema = Yup.object({
  empFirstName: Yup.string().required("Please enter employee's firstname"),
  empLastName: Yup.string().required("Please enter employee's lastname"),
  designation: Yup.object().shape({
    id: Yup.string().required("Please select employee's designation"),
    // etc
  }),
  bloodGroup: Yup.string().required("Please select employee's Blood Group"),
  empPresentAddress: Yup.string().required(
    "Please enter employee's present address"
  ),
  empParmanentAddress: Yup.string().required(
    "Please enter employee's permanent address"
  ),
  department: Yup.object().shape({
    id: Yup.string().required("Please select employee's department"),
    // etc
  }),
  channel: Yup.object().shape({
    id: Yup.string().required("Please select employee's channel"),
    // etc
  }),
  empTypeId: Yup.string().required("Please select employee's Type"),
  empContactNo: Yup.string()
    .matches(
      /^(01){1}[3456789]{1}\d{8}/,
      "Must be a valid bangladeshi mobile number"
    )
    .required("please enter employee's mobile number"),

  guardianContactNo: Yup.string()
    .matches(
      /^(01){1}[3456789]{1}\d{8}/,
      "Must be a valid bangladeshi mobile number"
    )
    .required("please enter employee's guardian mobile number"),
});

export const productSchema = Yup.object({
  productName: Yup.string().required("Please enter product's name"),
  category: Yup.object().shape({
    id: Yup.string().required("Please select product's category"),
    // etc
  }),
  productShelfLife: Yup.number()
    .required("Please write Product Shelf Life!")
    .test(
      "Is positive?",
      "The number must be greater than 11!",
      (value) => value > 11
    )
    .test(
      "is-decimal",
      "Can't be decimal",
      (value) => !(value + "").match(/^\d*\.{1}\d*$/)
    ),

  // Yup.string()
  //   .min(12)
  //   .required("Please write Product Shelf Life"),
});
//function schema


export const productMapSchema = Yup.object({
  // category: Yup.object().shape({
  //   id: Yup.string().required("Please select product's category"),
  //   // etc
  // }),
  // mappedProduct: Yup.object().shape({
  //   id: Yup.string().required("Please Map with one of these Products"),
  //   // etc
  // }),
  targetProduct: Yup.object().shape({
    id: Yup.string().required("Please select one of the products"),
    // etc
  }),
});

export const CustomerSchema = Yup.object({
  customerName: Yup.string().required("Please enter customer's name"),

  customerAddress: Yup.string().required(
    "Please enter customer's present address"
  ),
  // locationId: Yup.string().required("Please select company's location"),
  channel: Yup.object().shape({
    id: Yup.string().required("Please select location"),
    // etc
  }),
  employee: Yup.object().shape({
    id: Yup.string().required("Please select Employee"),
    // etc
  }),
  contactNumber: Yup.string().matches(
    /^(01){1}[3456789]{1}\d{8}/,
    "Must be a valid bangladeshi mobile number"
  ),
});

export const SupplierSchema = Yup.object({
  supplierName: Yup.string().required("Please enter company's name"),

  supplierAddress: Yup.string().required(
    "Please enter company's present address"
  ),
  // locationId: Yup.string().required("Please select company's location"),
  supplierContactPerson: Yup.string().required(
    "Please enter contact person's name"
  ),
  supplierContactNumber: Yup.string()
    .matches(
      /^(01){1}[3456789]{1}\d{8}/,
      "Must be a valid bangladeshi mobile number"
    )
    .required("please enter company's mobile number"),
});

//shahed
export const LocationSchema = Yup.object({
  locationName: Yup.string().required("Please enter Location name"),
});
export const FunctionSchema = Yup.object({
  functionName: Yup.string().required("Please enter Function name"),
});

export const productPriceSchema = Yup.object({
  sellingPrice: Yup.string()
    .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
    .required("please enter selling price"),

  product: Yup.object().shape({
    id: Yup.string().required("Please select product"),
    // etc
  }),
});

export const RoleSchema = Yup.object({
  rolename: Yup.string().required("Please add a rolename"),
});

export const UpdateRoleSchema = Yup.object({
  roleId: Yup.string().required("Please select a role"),
});

export const PasswordChangeSchema = Yup.object({
  oldPassword: Yup.string().min(2).required("Please enter your password"),
  newPassword: Yup.string()
    .required("Please enter your new password")
    .test(
      "isValidPass",
      "must contain atleaset a number, an uppercase letter and any symbol(!#$%&'()*+,-./:;<=>?@[]^_`{|}~)",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (
          hasUpperCase &&
          hasNumber &&
          hasLowerCase &&
          hasSymbole &&
          validConditions >= numberOfMustBeValidConditions
        ) {
          return true;
        }
        return false;
      }
    ),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword"), null], "Password must match"),
});

export const MarketingActivitySchema = Yup.object({
  customer: Yup.object().shape({
    id: Yup.string().required("Please select company's name"),
    // etc
  }),
});

export const MarketingActivityUpdateSchema = Yup.object({
  customer: Yup.object().shape({
    id: Yup.string().required("Please select company's name"),
    // etc
  }),
});

export const GeoLocationSchema = Yup.object({
  customer: Yup.object().shape({
    id: Yup.string().required("Please write company's name"),
    // etc
  }),
  typeAddress: Yup.string().required("Please write factory address"),
});

export const MarketingReportSchema = Yup.object({
  customer: Yup.object().shape({
    id: Yup.string().required("Please write company's name"),
    // etc
  }),
  from: Yup.string().required("Please select a date"),
  to: Yup.string().required("Please select a date"),
});

export const productPurchasePriceSchema = Yup.object({
  purchasePrice: Yup.string()
    .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
    .required("please enter purchase price"),
  product: Yup.object().shape({
    id: Yup.string().required("Please select product"),
    // etc
  }),
});

export const ChequeSchema = Yup.object({
  status: Yup.boolean(),
  employee: Yup.object().shape({
    id: Yup.string().required("Please select Cheque Collector"),
    // etc
  }),
  chequeType: Yup.object().shape({
    id: Yup.string().required("Please select Cheque Type"),
    // etc
  }),

  customer: Yup.object().shape({
    id: Yup.string().required("Please select Customer"),
    // etc
  }),
  chequeNumber: Yup.string().required("Please enter Cheque Number"),
  chequeAmount: Yup.string()
    .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
    .required("Please enter Amount"),
  // bank: Yup.object().shape({
  //   id: Yup.string().required("Please select beneficiary bank"),
  //   // etc
  // }),
  issuerBank: Yup.object().shape({
    id: Yup.string().required("Please select Issuer bank"),
    // etc
  }),

  chequeStatus: Yup.object().shape({
    id: Yup.string().when("status", {
      is: true,
      then: Yup.string().required("Please select Cheque Status"),
      otherwise: "",
    }),
  }),
});



export const PaymentSchema = Yup.object({
  paymentReference: Yup.string(),
  isInvoice: Yup.boolean(),
  employee: Yup.object().shape({
    id: Yup.string().required("Please select Collector"),
    // etc
  }),

  paymentMode: Yup.object().shape({
    id: Yup.string().required("Please select Payment Mode"),
    // etc
  }),

  // invoiceCheque: Yup.object().shape({
  //   id: Yup.string().required("Please select one from dropdown"),
  //   // etc
  // }),
  customer: Yup.object().shape({
    id: Yup.string().required("Please select Customer"),
    // etc
  }),
  invoiceNumber: Yup.string().when("paymentReference", {
    is: "0",
    then: Yup.string().required("Please enter Invoice Number"),
  }),
  invoiceAmount: Yup.string().when("paymentReference", {
    is: "0",
    then: Yup.string()
      .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
      .required("Please enter Amount"),
  }),
  

  receiveAmount: Yup.string()
    .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
    .required("Please enter Amount"),
  
    chequeNumber: Yup.string().when("paymentReference", {
    is: "1",
    then: Yup.string().required("Please enter Cheque Number"),
    otherwise: "",
    //  Yup.string().required("Please enter Cheque Number"),
  }),
    chequeAmount: Yup.string().when("paymentReference", {
    is: "1",
    then: Yup.string().required("Please enter Cheque Amount"),
    otherwise:"",
    
    //  Yup.string().required("Please enter Cheque Number"),
  }),

  // receiveAmount: Yup.string()
  //   .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
  //   .required("Please enter Amount"),
  
  //   chequeNumber: Yup.string().when("paymentReference", {
  //   is: "0",
  //   then: "",
  //   otherwise: Yup.string().when("paymentReference", {
  //     is: "2",
  //     then: "",
  //     otherwise: Yup.string().required("Please enter Cheque Number"),
  //     is: "3",
  //     then: "",
  //     otherwise: Yup.string().required("Please enter Cheque Number"),
  //   }),
  //   //  Yup.string().required("Please enter Cheque Number"),
  // }),
  // chequeAmount: Yup.string().when("paymentReference", {
  //   is: "0",
  //   then: "",
  //   otherwise: Yup.string().when("paymentReference", {
  //     is: "2",
  //     then: "",
  //     otherwise: Yup.string(),
  //     is: "3",
  //     then: "",
  //     otherwise: Yup.string()
  //       .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
  //       .required("Please enter Amount"),
  //   }),
  //   //  Yup.string().required("Please enter Cheque Number"),
  // }),
  // chequeAmount: Yup.string().when("isInvoice", {
  //   is: true,
  //   then: "",
  //   otherwise: Yup.string()
  //     .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
  //     .required("Please enter Amount"),
  // }),
  bank: Yup.object().shape({
    id: Yup.string().when("isInvoice", {
      is: false,
      then: Yup.string().required("Please select beneficiary bank"),
      otherwise: "",
    }),

    // etc
  }),

  chequeStatus: Yup.object().shape({
    id: Yup.string().when("paymentReference", {
      is: "0",
      then: "",
      otherwise: Yup.string().when("paymentReference", {
        is: "3",
        then: Yup.string().required("Please select Cheque Status"),
        otherwise: "",
      }),
      //  Yup.string().required("Please enter Cheque Number"),
    }),
  }),
  // chequeStatus: Yup.object().shape({
  //   id: Yup.string().when("isInvoice", {
  //     is: true,
  //     then: "",
  //     otherwise: Yup.string().required("Please select Cheque Status"),
  //   }),
  //   // etc
  // }),
});

export const TaskSchema = Yup.object({
  employee: Yup.object().shape({
    id: Yup.string().required("Please select Collector"),
    // etc
  }),
  taskName: Yup.string().required("Please enter Taskname"),

  // invoiceCheque: Yup.object().shape({
  //   id: Yup.string().required("Please select one from dropdown"),

  // }),

  taskDescription: Yup.string().required("Please enter Task Description"),
});

export const stockoutSchema = Yup.object({
  systemNo: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
});

// export const barcodeSchema=Yup.object({

//   productName: Yup.string().required("Please enter product's name"),

//   packSize: Yup.string()
//   .matches(/^\d*[\.{1}\d*]\d*$/, "must be only digits")
//   .required("Please enter pack size"),

// });

export const barcodeSchema = Yup.object({
 // productName: Yup.string().required("Please enter product's name"),
  packSize: Yup.string()
    .matches(/^\d*\.?\d*$/, "Must be only digits")
    .required("Please enter pack size"),
  siInvoiceNo: Yup.string().required("Please enter the SI invoice number"),
 
 // productId: Yup.string().required("Please select a product"),
 // packTypeId: Yup.string().required("Please select a pack type"),
  //packNo: Yup.string().required("Please enter pack number"),
  //receivedQuantity:Yup.string().required("Please enter receive quantity"),
});

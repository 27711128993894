import { API_URL } from "../constants/apiConstants";
import Axios from "axios";
import {
  QUOTATION_APPROVED_LIST_FAIL,
  QUOTATION_APPROVED_LIST_REQUEST,
  QUOTATION_APPROVED_LIST_SUCCESS,
  QUOTATION_APPROVE_UPDATE_FAIL,
  QUOTATION_APPROVE_UPDATE_REQUEST,
  QUOTATION_APPROVE_UPDATE_SUCCESS,
  QUOTATION_CREATE_FAIL,
  QUOTATION_CREATE_REQUEST,
  QUOTATION_CREATE_SUCCESS,
  QUOTATION_DETAILS_FAIL,
  QUOTATION_DETAILS_REQUEST,
  QUOTATION_DETAILS_SUCCESS,
  QUOTATION_UPDATE_FAIL,
  QUOTATION_UPDATE_REQUEST,
  QUOTATION_UPDATE_SUCCESS,
} from "../constants/quotationConstants";

export const createQuotation = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: QUOTATION_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/Price/CreatePriceQuotation`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    // await Axios(config)
    //   .then(function (response) {
    //     // console.log(response);
    //     // console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // dispatch({ type: QUOTATION_CREATE_SUCCESS, payload: data });

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: QUOTATION_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: QUOTATION_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: QUOTATION_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listQuotationDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: QUOTATION_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Price/GetQuotationsDetails/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log("data[0]");
    // console.log(data);

    dispatch({
      type: QUOTATION_DETAILS_SUCCESS,
      payload: data.dataObj,
    });
  } catch (error) {
    dispatch({
      type: QUOTATION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateQuotation = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: QUOTATION_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/Price/UpdatePriceQuotation`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    // await Axios(config)
    //   .then(function (response) {
    //     // console.log(response);
    //     // console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // dispatch({ type: QUOTATION_CREATE_SUCCESS, payload: data });

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: QUOTATION_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: QUOTATION_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: QUOTATION_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateApproveQuotation = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: QUOTATION_APPROVE_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/Price/ApprovePriceQuotation`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    // await Axios(config)
    //   .then(function (response) {
    //     // console.log(response);
    //     // console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // dispatch({ type: QUOTATION_APPROVE_CREATE_SUCCESS, payload: data });

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: QUOTATION_APPROVE_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: QUOTATION_APPROVE_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    console.log("finalGotData");
    console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: QUOTATION_APPROVE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listQuotationApproved = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: QUOTATION_APPROVED_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Price/GetQuotationsGrid/-1/${order}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    console.log("data");
    console.log(data);
    dispatch({
      type: QUOTATION_APPROVED_LIST_SUCCESS,
      payload: data.dataObj.data,
    });
  } catch (error) {
    dispatch({
      type: QUOTATION_APPROVED_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

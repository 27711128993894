import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    height: "auto",
    overflowX: "scroll !important",
    // overflowY: "scroll !important",
    marginTop: useTheme().spacing(1),
    scrollbarWidth: "thin",

    "& thead th": {
      maxWidth: "135px",
      minWidth: "135px",
      padding: "0 !important",
      overflowX: "auto",
      whiteSpace: "nowrap",
      textAlign: "center !important",
      fontWeight: "600",
      fontSize: "12px",
      backgroundColor: "#50577A",
      color: "#fff",
    },
    "& tbody td": {
      fontWeight: "300",
      height: "40px !important",
      padding: "5px !important",
      textAlign: "center !important",
    },
    "& tbody tr": {
      height: "40px !important",
    },
    "& tbody tr:hover": {
      backgroundColor: "#EEEEEE",
      cursor: "pointer",
    },
  },
}));

const useTable = (records, headCells, filterFn) => {
  // console.log("records");
  // console.log(records);
  const classes = useStyles();
  const pages = [20, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[0]);
  const [orderBy, setOrderBy] = useState();
  const [order, setOrder] = useState();
  const TblContainer = (props) => (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        {props.children}
      </Table>
    </TableContainer>
  );
  const TblHead = (props) => {
    const handleSortRequest = (id) => {
      const isAsc = orderBy === id && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((head) => (
            <TableCell
              key={head.id}
              sortDirection={orderBy === head.id ? order : false}
            >
              {head.disableSorting ? (
                head.label
              ) : (
                <TableSortLabel
                  active={orderBy === head.id}
                  direction={orderBy === head.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(head.id);
                  }}
                  size="small"
                >
                  {head.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const recordsAfterPagingAndSorting = () => {
    // console.log(records.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
    return stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };
  return {
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
};

export default useTable;

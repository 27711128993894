export const ROLES_LIST_REQUEST = "ROLES_LIST_REQUEST";
export const ROLES_LIST_SUCCESS = "ROLES_LIST_SUCCESS";
export const ROLES_LIST_FAIL = "ROLES_LIST_FAIL";

export const ROLES_CREATE_REQUEST = "ROLES_CREATE_REQUEST";
export const ROLES_CREATE_SUCCESS = "ROLES_CREATE_SUCCESS";
export const ROLES_CREATE_FAIL = "ROLES_CREATE_FAIL";
export const ROLES_CREATE_RESET = "ROLES_CREATE_RESET";

export const ROLES_DETAILS_REQUEST = "ROLES_DETAILS_REQUEST";
export const ROLES_DETAILS_SUCCESS = "ROLES_DETAILS_SUCCESS";
export const ROLES_DETAILS_FAIL = "ROLES_DETAILS_FAIL";
export const ROLES_DETAILS_RESET = "ROLES_DETAILS_RESET";

export const UNASSIGNED_MENU_DELETE_REQUEST = "UNASSIGNED_MENU_DELETE_REQUEST";
export const UNASSIGNED_MENU_DELETE_SUCCESS = "UNASSIGNED_MENU_DELETE_SUCCESS";
export const UNASSIGNED_MENU_DELETE_FAIL = "UNASSIGNED_MENU_DELETE_FAIL";
export const UNASSIGNED_MENU_DELETE_RESET = "UNASSIGNED_MENU_DELETE_RESET";

export const UNASSIGNED_MENU_CREATE_REQUEST = "UNASSIGNED_MENU_CREATE_REQUEST";
export const UNASSIGNED_MENU_CREATE_SUCCESS = "UNASSIGNED_MENU_CREATE_SUCCESS";
export const UNASSIGNED_MENU_CREATE_FAIL = "UNASSIGNED_MENU_CREATE_FAIL";
export const UNASSIGNED_MENU_CREATE_RESET = "UNASSIGNED_MENU_CREATE_RESET";

export const ASSIGNED_MENU_DELETE_REQUEST = "ASSIGNED_MENU_DELETE_REQUEST";
export const ASSIGNED_MENU_DELETE_SUCCESS = "ASSIGNED_MENU_DELETE_SUCCESS";
export const ASSIGNED_MENU_DELETE_FAIL = "ASSIGNED_MENU_DELETE_FAIL";
export const ASSIGNED_MENU_DELETE_RESET = "ASSIGNED_MENU_DELETE_RESET";

export const ASSIGNED_MENU_CREATE_REQUEST = "ASSIGNED_MENU_CREATE_REQUEST";
export const ASSIGNED_MENU_CREATE_SUCCESS = "ASSIGNED_MENU_CREATE_SUCCESS";
export const ASSIGNED_MENU_CREATE_FAIL = "ASSIGNED_MENU_CREATE_FAIL";
export const ASSIGNED_MENU_CREATE_RESET = "ASSIGNED_MENU_CREATE_RESET";

export const ASSIGNED_MENU_UPDATE_REQUEST = "ASSIGNED_MENU_UPDATE_REQUEST";
export const ASSIGNED_MENU_UPDATE_SUCCESS = "ASSIGNED_MENU_UPDATE_SUCCESS";
export const ASSIGNED_MENU_UPDATE_FAIL = "ASSIGNED_MENU_UPDATE_FAIL";
export const ASSIGNED_MENU_UPDATE_RESET = "ASSIGNED_MENU_UPDATE_RESET";

export const ROLE_FOR_SPECIFIC_USER_UPDATE_REQUEST =
  "ROLE_FOR_SPECIFIC_USER_UPDATE_REQUEST";
export const ROLE_FOR_SPECIFIC_USER_UPDATE_SUCCESS =
  "ROLE_FOR_SPECIFIC_USER_UPDATE_SUCCESS";
export const ROLE_FOR_SPECIFIC_USER_UPDATE_FAIL =
  "ROLE_FOR_SPECIFIC_USER_UPDATE_FAIL";
export const ROLE_FOR_SPECIFIC_USER_UPDATE_RESET =
  "ROLE_FOR_SPECIFIC_USER_UPDATE_RESET";

import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  CHANNEL_LIST_FAIL,
  CHANNEL_LIST_REQUEST,
  CHANNEL_LIST_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DESIGNATION_LIST_FAIL,
  DESIGNATION_LIST_REQUEST,
  DESIGNATION_LIST_SUCCESS,
  EMPLOYEEBY_CHANNEL_LIST_FAIL,
  EMPLOYEEBY_CHANNEL_LIST_REQUEST,
  EMPLOYEEBY_CHANNEL_LIST_SUCCESS,
} from "../constants/channelConstants";

export const listChannel = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CHANNEL_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Channel/GetChannels`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.channelId,
        label: single.channelName,
      };
    });
    dispatch({ type: CHANNEL_LIST_SUCCESS, payload: final });
  } catch (error) {
    dispatch({
      type: CHANNEL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployeeByChannel = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEEBY_CHANNEL_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Employee/GetEmployeeByChannelId/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.employeeId,
        label:
          single.empFirstName +
          (single.empLastName === null
            ? "-" + single.employeeCode
            : " " + single.empLastName + "-" + single.employeeCode),
      };
    });
    dispatch({ type: EMPLOYEEBY_CHANNEL_LIST_SUCCESS, payload: final });
  } catch (error) {
    dispatch({
      type: EMPLOYEEBY_CHANNEL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listDesignation = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DESIGNATION_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Designation/GetDesignation`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.designationId,
        label: single.designation1,
      };
    });
    dispatch({ type: DESIGNATION_LIST_SUCCESS, payload: final });
  } catch (error) {
    dispatch({
      type: DESIGNATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listDeparment = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DEPARTMENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Department/GetDepartment`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.departmentId,
        label: single.departmentName,
      };
    });
    dispatch({ type: DEPARTMENT_LIST_SUCCESS, payload: final });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

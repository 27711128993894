import React from "react";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 0,
    margin: useTheme().spacing(0.5),
  },
  secondary: {
    backgroundColor: useTheme().palette.secondary.light,
    "& .MuiButton-label": {
      color: useTheme().palette.secondary.main,
    },
  },
  primary: {
    backgroundColor: useTheme().palette.primary.light,
    "& .MuiButton-label": {
      color: useTheme().palette.primary.main,
    },
  },
}));

const ActionButton = (props) => {
  const { color, children, onClick } = props;
  const classes = useStyles();
  return (
    <Button
      className={`${classes.root} ${classes[color]}`}
      color={color}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ActionButton;

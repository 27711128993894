import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  TableBody,
  TableCell,
  TableRow,
  createFilterOptions,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Button from "../../core/controls/Button";
import {
  createProduct,
  createProductPrice,
  listCategories,
  listProductPriceById,
  mappedListProducts,
} from "../../redux/actions/productActions";

import { productPriceSchema, productPurchasePriceSchema } from "../../schemas";
import { withRouter } from "react-router-dom";
import { getDataGridUtilityClass } from "@mui/x-data-grid";
import useTable from "../../core/useTable";
import { emprecords } from "../../DummyData";
import Datepicker from "../../core/controls/Datepicker";
import { PRODUCT_PRICE_CREATE_RESET } from "../../redux/constants/productConstants";
import Notification from "../../core/Notification";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import { listProductsForInvoice } from "../../redux/actions/invoiceActions";
import {
  createProductPurchasePrice,
  createProductSellingPrice,
} from "../../redux/actions/priceActions";
import {
  PRODUCT_PURCHASE_PRICE_CREATE_RESET,
  PRODUCT_SELLING_PRICE_CREATE_RESET,
} from "../../redux/constants/priceConstants";
import Popup from "../../core/Popup";

const headCells = [
  {
    id: "invoiceDate",
    label: "Purchase Date",
  },
  {
    id: "purchasePrice",
    label: "Purchase Price",
  },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  product: {
    id: "",
    label: "",
  },
  sellingPrice: "",
};

const AddPrice = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [checked, setChecked] = useState(true);

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const productSellingPriceCreate = useSelector(
    (state) => state.productSellingPriceCreate
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    sellingPrice: createdProductSellingPrice,
  } = productSellingPriceCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const productPriceById = useSelector((state) => state.productPriceById);
  const {
    loading: loadingProductPrice,
    error: errorProductPrice,
    products: productPrice,
  } = productPriceById;
  // console.log(productPrice);
  const mappedProductList = useSelector((state) => state.mappedProductList);
  const {
    loading: mappedLoading,
    error: mappedError,
    mappedproducts,
  } = mappedProductList;
  //   console.log(mappedproducts);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(productPrice, headCells, filterFn);

  //   console.log(userInfo);
  const formik = useFormik({
    initialValues,
    validationSchema: productPriceSchema,
    onSubmit: (values, action) => {
      console.log(values);
      // console.log("loadingCreate");
      // console.log(loadingCreate);
      dispatch(
        createProductSellingPrice({
          productId: values.product.id,
          productPriceCode: "NoOffer",
          productPrice: parseFloat(values.sellingPrice),
          productProfitPercentage: 0,
          productDicsountedPrice: parseFloat(values.sellingPrice),
          productPriceActiveFrom: null,
          productPriceActiveTo: null,
        })
      ).then((result) => {
        if (result?.errMsg) {
          // console.log(result);
          if (result.errMsg == "Product price already Exist") {
            setOpenPopup({
              ...openPopup,
              title: "",
              subTitle: `${result.errMsg}`,
              isOpen: true,
            });
          }

          action.setSubmitting(false);
        } else {
          if (checked === true) {
            action.resetForm();
          } else {
            history.push("/price");
          }
        }
      });
      // console.log("loadingCreate");
      // console.log(loadingCreate);
      // if (loadingCreate === false) {
      //   history.push("/price");
      // }
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
      dispatch(listProductsForInvoice());
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: PRODUCT_SELLING_PRICE_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/addsellprice" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  const onTagsChange = (event, values) => {
    // console.log(values);
    dispatch(mappedListProducts(values.id));
  };

  const getData = (event, values) => {
    dispatch(listProductPriceById(values.id));
  };

  const mappedProductOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const categoryChange = useCallback((e, v) => {
    formik.setFieldValue("category", {
      id: v?.id || "",
      label: v?.label || "",
    });
    onTagsChange(e, v);
  });

  const productChange = useCallback((e, v) => {
    // console.log(v.id);
    formik.setFieldValue("product", {
      id: v?.id || "",
      label: v?.label || "",
    });
    getData(e, v);
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  // console.log(formik.values.category);
  // console.log(categories);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AddShoppingCartIcon />}
        title="Selling Price"
        subtitle="Add a selling price"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingProduct ? true : false}
                id="product"
                isOptionEqualToValue={mappedProductOpctionCheck}
                onChange={productChange}
                value={formik.values.product}
                options={products !== undefined ? products : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Product Name"
                    name="product"
                    error={
                      formik.errors.product !== undefined
                        ? formik.errors.product.id
                        : ""
                    }
                    touched={
                      formik.touched.product !== undefined
                        ? formik.touched.product
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />

              <Input
                label="Selling Price"
                name="sellingPrice"
                error={formik.errors.sellingPrice}
                touched={formik.touched.sellingPrice}
                value={formik.values.sellingPrice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup}>
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/price",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loading ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(AddPrice);

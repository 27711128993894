import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Button from "../../core/controls/Button";
import {
  createProduct,
  listCategories,
  mappedListProducts,
  mappedProductsPerProduct,
  mapProduct,
} from "../../redux/actions/productActions";

import { productMapSchema, productSchema } from "../../schemas";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_MAP_RESET,
} from "../../redux/constants/productConstants";
import Notification from "../../core/Notification";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  FilterProductsForInvoice,
  listProductsForInvoice,
} from "../../redux/actions/invoiceActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

const initialValues = {
  category: {
    id: "",
    label: "",
  },

  mappedProduct: [],
  targetProduct: {
    id: "",
    label: "",
  },
};

var FilteredProduct = [];
var targetProduct = [];

const MapProduct = ({ history }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [datas, setDatas] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const classes = useStyles();

  const productMap = useSelector((state) => state.productMap);
  const {
    loading: loadingMap,
    error: errorMap,
    success: successMap,
  } = productMap;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const mappedProducts = useSelector((state) => state.mappedProducts);
  const {
    loading: mappedLoading,
    error: mappedError,
    mappedproducts,
  } = mappedProducts;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const formik = useFormik({
    initialValues,
    validationSchema: productMapSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      let arrData = values.mappedProduct.map((data) => data.id);

      dispatch(mapProduct(values.targetProduct.id, arrData)).then(() => {
        // console.log("checked");
        // console.log(checked);
        action.resetForm();
      });
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
      dispatch(listProductsForInvoice());
      if (successMap) {
        setNotify({
          isOpen: true,
          message: "Mapped Successfully",
          type: "success",
        });

        dispatch({ type: PRODUCT_MAP_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/mappingproduct" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successMap]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const onTagsChange = (event, values) => {
    // console.log(values);
    dispatch(mappedListProducts(values.id));
  };

  const mappedProductOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const mappedProductChange = useCallback((e, v) => {
    console.log("v");
    console.log(v);
    if (v.length > 0) {
      formik.setFieldValue("mappedProduct", v);
      let wishData = v.map((data) => data.id);
      FilteredProduct = FilteredProduct.filter((d) => {
        return wishData.includes(d);
      });
      console.log("FilteredProduct");
      console.log(FilteredProduct);
      dispatch(FilterProductsForInvoice(FilteredProduct));
    } else {
      FilteredProduct = [];
      dispatch(FilterProductsForInvoice(FilteredProduct));
      formik.setFieldValue("mappedProduct", []);
    }
  });

  const FilterData = () => {
    FilteredProduct = [];
    console.log("datas");
    console.log(datas);
    if (datas.length > 0) {
      datas.map((single) => {
        console.log("single");
        console.log(single);
        FilteredProduct.push(single.id);
      });

      dispatch(FilterProductsForInvoice(FilteredProduct));
    }
  };

  useEffect(() => {
    FilterData();
  }, [datas]);

  const targetProductChange = useCallback((e, v) => {
    formik.setFieldValue("targetProduct", {
      id: v?.id || "",
      label: v?.label || "",
    });

    if (v?.id) {
      var catchId = -1;

      console.log("catchId");
      console.log(catchId);
      // FilteredProduct.push(v.id);
      dispatch(mappedProductsPerProduct(v.id)).then((data) => {
        formik.setFieldValue("mappedProduct", data);
        setDatas(data);
      });

      // dispatch(FilterProductsForInvoice([v.id]));
    } else {
      let arr = [];
      formik.setFieldValue("mappedProduct", []);
      dispatch(FilterProductsForInvoice(arr));
    }
  });

  console.log("formik.values.mappedProduct");
  console.log(formik.values.mappedProduct);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AddShoppingCartIcon />}
        title="Map Control"
        subtitle="Map a product"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form
          onSubmit={(e) => {
            formik.handleSubmit(e);
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              {/* <Autocomplete
                disablePortal
                size="small"
                disabled={loading ? true : false}
                id="combo-box-demo"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("category", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });

                  if (formik.values.mappedProduct.id !== "") {
                    formik.setFieldValue("mappedProduct", {
                      id: "",
                      label: "",
                    });
                  }
                  onTagsChange(e, v);
                })}
                value={formik.values.category}
                options={categories !== undefined ? categories : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Product Category"
                    name="category"
                    error={
                      formik.errors.category !== undefined
                        ? formik.errors.category.id
                        : ""
                    }
                    touched={
                      formik.touched.category !== undefined
                        ? formik.touched.category
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              /> */}

              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingProduct ? true : false}
                id="targetProduct"
                isOptionEqualToValue={mappedProductOpctionCheck}
                onChange={targetProductChange}
                value={formik.values.targetProduct}
                options={products !== undefined ? products : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Target Product"
                    name="targetProduct"
                    error={
                      formik.errors.targetProduct !== undefined
                        ? formik.errors.targetProduct.id
                        : ""
                    }
                    touched={
                      formik.touched.targetProduct !== undefined
                        ? formik.touched.targetProduct
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                multiple
                disablePortal
                size="small"
                disabled={formik.values.targetProduct.id === "" ? true : false}
                id="mappedProduct"
                // isOptionEqualToValue={mappedProductOpctionCheck}
                onChange={mappedProductChange}
                value={formik.values.mappedProduct}
                options={products !== undefined ? products : []}
                getOptionLabel={(option) => option.label}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                filterSelectedOptions
                renderInput={(params) => (
                  <Input
                    label="Mapped With"
                    name="mappedProduct"
                    error={
                      formik.errors.mappedProduct !== undefined
                        ? formik.errors.mappedProduct.id
                        : ""
                    }
                    touched={
                      formik.touched.mappedProduct !== undefined
                        ? formik.touched.mappedProduct
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup}>
                <MuiButton
                  endIcon={
                    loadingMap ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Map Product
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/dashboard",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loading ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default MapProduct;

import React from 'react';
import BarChart from "../../core/Chart/BarChart";
import PieChart from "../../core/Chart/PieChart";
import LineChart from "../../core/Chart/LineChart";
import DoughnutChart from "../../core/Chart/DoughnutChart";

const ChartToRender = ({ chartType, data, titleText }) => {
    switch (chartType) {
        case 'pie':
            return <PieChart data={data} titleText={titleText} />;
        case 'doughnut':
            return <DoughnutChart data={data} titleText={titleText} />;
        case 'bar':
            return <BarChart data={data} titleText={titleText} />;
        default:

    }
};

export default ChartToRender;

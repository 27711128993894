import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  Toolbar,
  Button as MuiButton,
  createFilterOptions,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";

import { Box, Typography, IconButton, Tooltip } from "@mui/material";

import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import InventoryIcon from "@mui/icons-material/Inventory";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RedoIcon from "@mui/icons-material/Redo";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

import {
  deleteEmployee,
} from "../../redux/actions/employeeActions";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "../../core/controls/Button";
import {
  escalateSalesOrderInternal,
  listPackagingDoneSalesOrderInternalDetails,
  listSalesOrderFilterInternal,
  listSalesOrderInternal,
  listSalesOrderInternalDetails,
} from "../../redux/actions/invoiceActions";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import { Form } from "react-bootstrap";
import {
  SALESORDERINTERNAL_ESCALATION_RESET,
  SALESORDERINTERNAL_FILTER_LIST_RESET,
  SALESORDERINTERNAL_PACKAGE_RESET,
  SALESORDERINTERNAL_UPDATE_RESET,
} from "../../redux/constants/invoiceConstants";
import SalesOrderInternalModal from "../../core/SalesOrderInternalModal";
import SalesOrderView from "../../core/SalesOrderView";
import KarjadeshView from "../../core/KarjadeshView";
import SalesOrderChalaanModal from "../../core/SalesOrderChalaanModal";
import Datepicker from "../../core/controls/Datepicker";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import { useFormik } from "formik";
import SalesOrderStickerModal from "../../core/SalesOrderStickerModal";

const useStyles = makeStyles(() => ({
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#DA2864",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "290px !important",
      maxWidth: "290px !important",
    },

    "& .MuiDataGrid-cell": {
      minWidth: "290px !important",
      maxWidth: "290px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#DA2864",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='soSystemId'], .MuiDataGrid-cell[data-field='soSystemId']":
      {
        // minWidth: "60px !important",
        // maxWidth: "60px !important",
        display: "none",
      },
    "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3'], .MuiDataGrid-columnHeader[data-field='actions4'], .MuiDataGrid-cell[data-field='actions4']":
      {
        minWidth: "100px !important",
        maxWidth: "100px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#EC6491",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: `${useTheme().spacing(0)} ${useTheme().spacing(1)}`,
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
  // grid: {
  //   flexDirection: "column-reverse",
  // },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(3),
  },
  pageContent2: {
    overflowX: "auto",
    justifyContent: "space-between",
    margin: useTheme().spacing(1),
    padding: `${useTheme().spacing(2)} ${useTheme().spacing(1)}`,
    [useTheme().breakpoints.up("md")]: {
      padding: `${useTheme().spacing(2)} ${useTheme().spacing(7)}`,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "none",
    justifyContent: "center !important",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    "& div:nth-child(1)": {
      margin: "15px",
    },
    [useTheme().breakpoints.up("md")]: {
      display: "none",
      justifyContent: "center !important",
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
      "& div:nth-child(1)": {
        margin: "0px",
      },
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },

  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  actions2: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "right",
      width: "420px",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  startDate: [date.getFullYear(), mnth, day].join("-"),
  endDate: [date.getFullYear(), mnth, day].join("-"),
  customer: {
    id: "",
    label: "",
  },
};

const PackageDoneSalesOrderInternal = ({ history }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const listSalesOrderInternalFilter = useSelector(
    (state) => state.listSalesOrderInternalFilter
  );
  const { loading, error, salesorderinternal } = listSalesOrderInternalFilter;

  const salesOrderInternalPackage = useSelector(
    (state) => state.salesOrderInternalPackage
  );
  const {
    loading: loadingPackage,
    error: errorPackage,
    success: successPackage,
    packagedSalesOrder,
  } = salesOrderInternalPackage;
 
  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  const employeeDelete = useSelector((state) => state.employeeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = employeeDelete;

  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = employeeUpdate;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

  const salesOrderInternalPackageDoneDetails = useSelector(
    (state) => state.salesOrderInternalPackageDoneDetails
  );
  const {
    loading: loadingDoneDetails,
    error: errorDoneDetails,
    salesorderinternalpackagingdonesingle,
  } = salesOrderInternalPackageDoneDetails;

  const salesOrderInternalEscalate = useSelector(
    (state) => state.salesOrderInternalEscalate
  );
  const {
    loading: loadingEscalate,
    error: errorEscalate,
    success: successEscalate,
    escalatedSalesOrder,
  } = salesOrderInternalEscalate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whatOpen, setWhatOpen] = useState(-1);
  const [isOpenKarjadesh, setIsOpenKarjadesh] = useState(false);
  const [isOpenInvoice, setIsOpenInvoice] = useState(false);
  const [isOpenSticker, setIsOpenSticker] = useState(false);
  const [isOpenChalaan, setIsOpenChalaan] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("3");
  const [dataForEscalation, setDataForEscalation] = useState([]);
  const selectedOrderRef = useRef(null);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };
 
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      let data = {
        startDate: values.startDate,
        endDate: values.endDate,
        customerId: values.customer.id !== "" ? values.customer.id : 0,
      };
      dispatch(listSalesOrderFilterInternal(selectedOrder, data)).then(
        (result) => {
          console.log(result);
          if (result?.title === "One or more validation errors occurred.") {
            action.setSubmitting(false);
          }
        }
      );
    },
  });

  useEffect(() => {
    if (userInfo) {
      // dispatch(listSalesOrderInternalFilterReducer(selectedOrder));
      dispatch(listCustomersForSignup());
      dispatch({ type: SALESORDERINTERNAL_FILTER_LIST_RESET });
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: SALESORDERINTERNAL_UPDATE_RESET });
      }
      if (successPackage) {
        setNotify({
          isOpen: true,
          message: "Saved Successfully",
          type: "success",
        });

        dispatch({ type: SALESORDERINTERNAL_PACKAGE_RESET });
      }

      if (successEscalate) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Escalated Successfully",
          type: "error",
        });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setDataForEscalation([]);

        dispatch({ type: SALESORDERINTERNAL_ESCALATION_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/salesorderinternal" } },
      };
      history.push(location);
    }

    // console.log(successUpdate);
  }, [
    dispatch,
    classes,
    history,
    userInfo,
    successDelete,
    successUpdate,
    selectedOrder,
    successEscalate,
  ]);

  const handleEvent = (params) => {
    // history.push(`/employee/${params.row.employeeId}/edit`);
    // console.log(params.row);
  };

  const buttonsBasedOnSalesOrderType = () => {
    console.log("selectedOrder");
    console.log(selectedOrder);
    if (selectedOrder === "0") {
      return (
        <Box className={classes.actions}>
          <Button
            startIcon={<AddIcon fontSize="small" />}
            text="Add New"
            color="primary"
            onClick={() => {
              history.push("/salesorderinternal/create");
            }}
          />
          <Button
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/edit`);
              }
            }}
          />
          <Button
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              if (dataForEscalation.length > 0) {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure that you want to escalate this data?",
                  subTitle: "This data will be sent to the operation team!",
                  onConfirm: () => {
                    dispatch(escalateSalesOrderInternal(dataForEscalation));
                  },
                });
              } else {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "No Data is slected!",
                  isOpen: true,
                });
              }
            }}
          />
        </Box>
      );
    } else if (selectedOrder === "1") {
      // console.log("Hello");
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<RedoIcon fontSize="small" />}
            text="ACTION"
            color="success"
            onClick={() => {
              // alert("clicked");
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/action`);
              }
            }}
          />
      </Box>
      );
    } else if (selectedOrder === "2") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/action`);
              }
            }}
          />
          
        </Box>
      );
    }
  };

  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={salesorderinternal === undefined ? [] : salesorderinternal}
          columns={
            selectedOrder === "0"
              ? [...columns_part_a, ...columns_part_b_O_0]
              : selectedOrder === "1"
              ? [...columns_part_b_O_1, ...columns_part_a]
              : selectedOrder === "2"
              ? [...columns_part_b_O_2, ...columns_part_a]
              : selectedOrder === "3"
              ? [...columns_part_b_O_3, ...columns_part_a]
              : columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.soSystemId + "-" + generateRandom()}
          onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      if (selectedOrder === "0") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
        salesorderinternal.find((row) => row.soSystemId === id)
      );
      setDataForEscalation([selectedRowsData[0].soSystemId]);
      setRowId(selectedRowsData[0].soSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteEmployee(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };

  const openModal = (id) => {
    setWhatOpen(1);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpen(true);
    });
    //
  };
  const closeModal = (event) => {
    setWhatOpen(-1);
    setIsOpen(false);
  };

  const openModalKarjadesh = (id) => {
    setWhatOpen(2);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpenKarjadesh(true);
    });
    //
  };
  const closeModalKarjadesh = (event) => {
    setWhatOpen(-1);
    setIsOpenKarjadesh(false);
  };

  const openInvoiceModal = (id) => {
    setWhatOpen(3);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpenInvoice(true);
    });
    //
  };
  const closeInvoiceModal = (event) => {
    setWhatOpen(-1);
    setIsOpenInvoice(false);
  };

  const openStickerModal = (id) => {
    setWhatOpen(5);

    dispatch(listPackagingDoneSalesOrderInternalDetails(id)).then((res) => {
      console.log("res===> ", res);
      setIsOpenSticker(true);
    });
  };

  const closeStickerModal = (event) => {
    setWhatOpen(-1);
    setIsOpenSticker(false);
  };

  const openChalaanModal = (id) => {
    setWhatOpen(4);

    dispatch(listPackagingDoneSalesOrderInternalDetails(id)).then((res) => {
      setIsOpenChalaan(true);
    });
    //
  };
  const closeChalaanModal = (event) => {
    setWhatOpen(-1);
    setIsOpenChalaan(false);
  };
  const columns_part_a = [
    {
      field: "soSystemNo",
      headerName: "Order No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "invoiceDate",
      headerName: "Order Date",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customerName",
      headerName: "Partner Name",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerName || params.row.supplierName,
    },
    {
      field: "customerAddress",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerAddress || params.row.supplierAddress,

    },
    {
      field: "jobType",
      headerName: "Order Type",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "soOrderFrom",
      headerName: "Order From",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "soSystemId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
  ];

  const columns_part_b_O_0 = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              history.push(`/salesorderinternal/${row.soSystemId}/edit`);
            }}
          />
        );
      },
    },

    {
      field: "actions2",
      headerName: "ESCALATE",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to escalate this data?",
                subTitle: "This data will be sent to the operation team!",
                onConfirm: () => {
                  dispatch(escalateSalesOrderInternal([row.soSystemId]));
                },
              });
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_1 = [
    {
      field: "actions",
      headerName: "ACTION",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="ACTION"
            focuselementref={selectedOrderRef}
            startIcon={<RedoIcon fontSize="small" />}
            text="ACTION"
            color="success"
            onClick={() => {
              history.push(`/salesorderinternal/${row.soSystemId}/action`);
            }}
          />
        );
      },
    },
    {
      field: "actions2",
      headerName: "PRINT",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PRINT"
            focuselementref={selectedOrderRef}
            startIcon={<PrintIcon fontSize="small" />}
            text="PRINT"
            color="secondary"
            onClick={() => {
              openModalKarjadesh(row.soSystemId);
            }}
          />
        );
      },
    },
    {
      field: "actions3",
      headerName: "VIEW",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="VIEW"
            focuselementref={selectedOrderRef}
            startIcon={<VisibilityIcon fontSize="small" />}
            text="VIEW"
            color="secondary"
            onClick={() => {
              openModal(row.soSystemId);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_2 = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="EDIT"
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="EDIT"
            color="secondary"
            onClick={() => {
              history.push(`/salesorderinternal/${row.soSystemId}/action`);
            }}
          />
        );
      },
    },
    {
      field: "actions3",
      headerName: "VIEW",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="VIEW"
            focuselementref={selectedOrderRef}
            startIcon={<VisibilityIcon fontSize="small" />}
            text="VIEW"
            color="secondary"
            onClick={() => {
              openModal(row.soSystemId);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_3 = [
    {
      field: "actions",
      headerName: "INVOICE",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="INVOICE"
            focuselementref={selectedOrderRef}
            startIcon={<InventoryIcon fontSize="small" />}
            text="INVOICE"
            color="success"
            onClick={() => {
              openInvoiceModal(row.soSystemId);
            }}
          />
        );
      },
    },
    {
      field: "actions5",
      headerName: "STICKER",
      headerClassName: "sticker-header",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="STICKER"
            focuselementref={selectedOrderRef}
            startIcon={
              <StickyNote2Icon
                fontSize="small"
                style={{ color: "#FFD43B", fontSize: "28px" }}
              />
            }
            text="STICKER"
            color="success"
            onClick={() => {
              openStickerModal(row.soSystemId);
            }}
            style={{ width: "fit-content" }}
          />
        );
      },
    },
    {
      field: "actions4",
      headerName: "CHALAAN",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="CHALAAN"
            focuselementref={selectedOrderRef}
            startIcon={<InventoryIcon fontSize="small" />}
            text="CHALAAN"
            color="warning"
            onClick={() => {
              openChalaanModal(row.soSystemId);
            }}
          />
        );
      },
    },
    {
      field: "actions2",
      headerName: "PACKING LIST",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PACKING LIST"
            focuselementref={selectedOrderRef}
            startIcon={<PrintIcon fontSize="small" />}
            text="PACKING LIST"
            color="secondary"
            onClick={() => {
              openModalKarjadesh(row.soSystemId);
            }}
          />
        );
      },
    },
    {
      field: "actions3",
      headerName: "VIEW",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="VIEW"
            focuselementref={selectedOrderRef}
            startIcon={<VisibilityIcon fontSize="small" />}
            text="VIEW"
            color="secondary"
            onClick={() => {
              // console.log("params");
              // console.log(params);

              openModal(row.soSystemId);
            }}
          />
        );
      },
    },
  ];

  const isOptionEqualToValue = useCallback(
    (option, value) => option.value === value.value
  );

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
 
  return (
    
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Print Invoice"
        subtitle="Generate Invoice Prints"
      />

      {loadingDelete && <>loading..</>}
      {
        <>
          <Paper className={`${classes.pageContent2} paperdesign1`}>
            <Form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Datepicker
                    label="Start Date"
                    name="startDate"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Datepicker
                    label="End Date"
                    name="endDate"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    size="small"
                    disabled={loadingCustomer ? true : false}
                    id="combo-box-demo-channel"
                    // isOptionEqualToValue={isOptionEqualToValue}
                    onChange={customerChange}
                    value={formik.values.customer}
                    options={customers !== undefined ? customers : []}
                    onOpen={formik.handleBlur}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Customer Name"
                        name="customer"
                        error={
                          formik.errors.customer !== undefined
                            ? formik.errors.customer.id
                            : ""
                        }
                        touched={
                          formik.touched.customer !== undefined
                            ? formik.touched.customer
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ButtonGroup
                    className={classes.submitButtonGroup}
                    type="submit"
                  >
                    <MuiButton
                      startIcon={
                        loading ? (
                          <CircularProgress size="1rem" color="error" />
                        ) : (
                          <SearchIcon />
                        )
                      }
                      size="large"
                      type="submit"
                    >
                      Search
                    </MuiButton>
                    <MuiButton
                      size="small"
                      startIcon={<ReplayIcon />}
                      onClick={() => {
                        // console.log(formik);
                        formik.resetForm();
                      }}
                    />
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Form>
          </Paper>
          {error === "Network Error" ? (
            <div className={classes.wrapperWhite}>
              <Toolbar
                className={classes.toolBar}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  className="m-4"
                  src={process.env.PUBLIC_URL + "/images/networkerror.png"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "500px", height: "auto" }}
                />
              </Toolbar>
            </div>
          ) : loading ? (
            <div
              className="text-center"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <InfinitySpin width="200" color="#6C4AB6" />
              {/* <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#6C4AB6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
            </div>
          ) : (
            <div className={classes.wrapperWhite}>
              {gridBasedOnSalesOrderType()}
              {/* </Box>
          </Box> */}
              {/* </Paper> */}
              {/* <TblPagination /> */}
              {JSON.stringify(salesorderinternalsingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 1 && (
                  <SalesOrderView
                    showModal={isOpen}
                    closeModal={closeModal}
                    info={salesorderinternalsingle}
                    items={salesorderinternalsingle.stockOutItemsData}
                    currency={"TK"}
                    subTotal={
                      salesorderinternalsingle.stockOutBillData.calculatedBill
                    }
                    taxAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billVatPercantage
                    }
                    discountAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billDiscountAmount
                    }
                    total={salesorderinternalsingle.stockOutBillData.finalBill}
                  />
                )}
              {JSON.stringify(salesorderinternalsingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 2 && (
                  <KarjadeshView
                    showModal={isOpenKarjadesh}
                    closeModal={closeModalKarjadesh}
                    info={salesorderinternalsingle}
                    items={salesorderinternalsingle.stockOutItemsData}
                    currency={"TK"}
                    subTotal={
                      salesorderinternalsingle.stockOutBillData.calculatedBill
                    }
                    taxAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billVatPercantage
                    }
                    discountAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billDiscountAmount
                    }
                    total={salesorderinternalsingle.stockOutBillData.finalBill}
                  />
                )}
              {/* <Example
                    showModal={isOpenKarjadesh}
                    closeModal={closeModalKarjadesh}
                    info={salesorderinternalsingle}
                    items={salesorderinternalsingle.stockOutItemsData}
                    currency={"TK"}
                    subTotal={
                      salesorderinternalsingle.stockOutBillData.calculatedBill
                    }
                    taxAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billVatPercantage
                    }
                    discountAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billDiscountAmount
                    }
                    total={salesorderinternalsingle.stockOutBillData.finalBill}
                  /> */}
              {JSON.stringify(salesorderinternalsingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 3 && (
                  <SalesOrderInternalModal
                    showModal={isOpenInvoice}
                    closeModal={closeInvoiceModal}
                    info={salesorderinternalsingle}
                    items={salesorderinternalsingle.stockOutItemsData}
                    currency={"TK"}
                    subTotal={
                      salesorderinternalsingle.stockOutBillData.calculatedBill
                    }
                    taxAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billVatPercantage
                    }
                    discountAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billDiscountAmount
                    }
                    total={salesorderinternalsingle.stockOutBillData.finalBill}
                  />
                )}

              {JSON.stringify(salesorderinternalpackagingdonesingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 4 && (
                  <SalesOrderChalaanModal
                    showModal={isOpenChalaan}
                    closeModal={closeChalaanModal}
                    info={salesorderinternalpackagingdonesingle}
                    currency={"TK"}
                  />
                )}

              {JSON.stringify(salesorderinternalpackagingdonesingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 5 && (
                  <SalesOrderStickerModal
                    showModal={isOpenSticker}
                    closeModal={closeStickerModal}
                    item_info={salesorderinternalpackagingdonesingle}
                  />
                )}

              <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
             
              <Notification notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
            </div>
          )}
        </>
      }
    </div>
  );
};

export default PackageDoneSalesOrderInternal;

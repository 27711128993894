export const STOCKIN_LIST_REQUEST = "STOCKIN_LIST_REQUEST";
export const STOCKIN_LIST_SUCCESS = "STOCKIN_LIST_SUCCESS";
export const STOCKIN_LIST_FAIL = "STOCKIN_LIST_FAIL";

export const STOCKIN_CREATE_REQUEST = "STOCKIN_CREATE_REQUEST";
export const STOCKIN_CREATE_SUCCESS = "STOCKIN_CREATE_SUCCESS";
export const STOCKIN_CREATE_FAIL = "STOCKIN_CREATE_FAIL";
export const STOCKIN_CREATE_RESET = "STOCKIN_CREATE_RESET";

export const STOCKIN_ESCALATION_REQUEST = "STOCKIN_ESCALATION_REQUEST";
export const STOCKIN_ESCALATION_SUCCESS = "STOCKIN_ESCALATION_SUCCESS";
export const STOCKIN_ESCALATION_FAIL = "STOCKIN_ESCALATION_FAIL";
export const STOCKIN_ESCALATION_RESET = "STOCKIN_ESCALATION_RESET";

export const STOCKIN_DETAILS_REQUEST = "STOCKIN_DETAILS_REQUEST";
export const STOCKIN_DETAILS_SUCCESS = "STOCKIN_DETAILS_SUCCESS";
export const STOCKIN_DETAILS_FAIL = "STOCKIN_DETAILS_FAIL";
export const STOCKIN_DETAILS_RESET = "STOCKIN_DETAILS_RESET";

export const STOCKIN_UPDATE_REQUEST = "STOCKIN_UPDATE_REQUEST";
export const STOCKIN_UPDATE_SUCCESS = "STOCKIN_UPDATE_SUCCESS";
export const STOCKIN_UPDATE_FAIL = "STOCKIN_UPDATE_FAIL";
export const STOCKIN_UPDATE_RESET = "STOCKIN_UPDATE_RESET";

export const STOCKIN_QUANTITY_UPDATE_REQUEST =
  "STOCKIN_QUANTITY_UPDATE_REQUEST";
export const STOCKIN_QUANTITY_UPDATE_SUCCESS =
  "STOCKIN_QUANTITY_UPDATE_SUCCESS";
export const STOCKIN_QUANTITY_UPDATE_FAIL = "STOCKIN_QUANTITY_UPDATE_FAIL";
export const STOCKIN_QUANTITY_UPDATE_RESET = "STOCKIN_QUANTITY_UPDATE_RESET";

export const STOCKIN_ADJUST_REQUEST = "STOCKIN_ADJUST_REQUEST";
export const STOCKIN_ADJUST_SUCCESS = "STOCKIN_ADJUST_SUCCESS";
export const STOCKIN_ADJUST_FAIL = "STOCKIN_ADJUST_FAIL";
export const STOCKIN_ADJUST_RESET = "STOCKIN_ADJUST_RESET";

import React, { useCallback, useRef, useState } from "react";
import Decoder from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";

import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Datepicker from "../../../core/controls/Datepicker";
import Button from "../../../core/controls/Button";
import { useEffect } from "react";
import { EMPLOYEE_CREATE_RESET } from "../../../redux/constants/employeeConstants";
import Notification from "../../../core/Notification";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";

import axios from "axios";

import { exportToExcelExcelJs } from "../../../utils/json-to-excel-export-exceljs";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  customer: {
    id: "",
    label: "",
  },
};

const SalesReport = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const csvDownloadRef = useRef(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  console.log("customers: ", customers);
  const employeeCreate = useSelector((state) => state.employeeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    employee: createdProduct,
  } = employeeCreate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const decodedata =
    userInfo !== null && userInfo !== undefined
      ? Decoder(userInfo.token)
      : null;

  // console.log(decodedata);
  const heading_with_title = {
    userFirstName: "First Name",
    userLastName: "Last Name",
    visitDate: "Visit Date",
    customerId: "Customer Id",
    customerName: "Customer Name",
    mktActivityId: "Activity Id",
    mktActivityList: "Activity Name",
    voiceOfCustomer: "Voice of Customer",
  };

  const heading_field_values = [
    "userFirstName",
    "userLastName",
    "visitDate",
    "customerId",
    "customerName",
    "mktActivityId",
    "mktActivityList",
    "voiceOfCustomer",
  ];

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      console.log("values: ", values);
      const axios_config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };
       
      const api_endpoint = `${API_URL}/Marketing/GetReportData?userSysId=${decodedata.UserSystemId}
      &startDate=${values.from}&endDate=${values.to}&customerId=${values.customer.id}`;

      const response = await axios.get(api_endpoint, axios_config);
      const { data } = response;

      if (response?.errMsg) {
      } else {
        if (data?.dataObj?.length > 0) {
          let updatedData = [];

          for (const value of data?.dataObj) {
            const newSerializedObject = {
                userFirstName: value?.userFirstName,
                userLastName: value?.userLastName,
              // customerAddress: value?.customerAddress,
              visitDate:value?.visitDate,
              customerId:value?.customerId,
              customerName: value?.customerName,
              mktActivityId: value?.mktActivityId,
              mktActivityList: value?.mktActivityList,
              voiceOfCustomer: value?.voiceOfCustomer,
            };
            updatedData.push(newSerializedObject);
          }

          exportToExcelExcelJs(
            updatedData,
            heading_with_title,
            `Visit_Report_Data_${
              values?.customer?.id !== ""
                ? data?.dataObj[0]?.entityName
                : "All_Customer"
            }(${values.from}_to_${values.to})`,
            `Visit Report For ${
              values?.customer?.id !== ""
                ? data?.dataObj[0]?.entityName
                : "All Customer"
            } (${values.from} to ${values.to})`
          );
        } else {
          action.setSubmitting(false);
          setOpenPopup({
            ...openPopup,
            title: "ZERO DATA!!!",
            subTitle: "No Data found with this specifications!",
            isOpen: true,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: EMPLOYEE_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/report/marketingvisit" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AssessmentIcon />}
        title="Marketing Visit Report"
        subtitle="Generate Visit Report"
      />
      <Paper className={classes.pageContent}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Datepicker
                label="From"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
              />
              <Datepicker
                label="To"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingCustomer ? true : false}
                id="combo-box-demo1"
                isOptionEqualToValue={customerOptionCheck}
                onChange={(e, v) => {
                  customerChange(e, v);
                }}
                value={formik.values.customer}
                options={customers !== undefined ? customers : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Company Name"
                    name="customer"
                    // onChange={handleSearch}
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                freeSolo={true}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MuiButton
                className={classes.submitButtonGroup}
                endIcon={
                  loadingCreate ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : null
                }
                size="large"
                type="submit"
                // onClick={() => downloadExcelReport()}
              >
                <img
                  width={40}
                  height={40}
                  src={process.env.PUBLIC_URL + "/images/excel-icon.png"}
                  alt=""
                />
              </MuiButton>

              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </div>
  );
};

export default withRouter(SalesReport);

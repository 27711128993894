import React, { useState } from "react";
import {
  Autocomplete,
  GoogleMap,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import BoyIcon from "@mui/icons-material/Boy";
import "./Map.css";
import InfoWindowCore from "./InfoWindowCore";
const Map = (props) => {
  const { isLoaded, customersinMap } = props;
  console.log("customersinMap");
  console.log(customersinMap);
  const [selected, setSelected] = useState("");
  const [choosedCenter, setChoosedCenter] = useState({
    lat: 23.797963,
    lng: 90.401263,
  });
  const containerStyle = {
    width: "100%",
    height: "74vh",
  };

  const center = {
    lat: 23.797963,
    lng: 90.401263,
  };

  const markers = [
    {
      name: "location-1",
      location: {
        lat: 23.7375,
        lng: 90.4083,
      },
    },
    {
      name: "location-2",
      location: {
        lat: 23.7182,
        lng: 90.3866,
      },
    },
    {
      name: "location-3",
      location: {
        lat: 23.7373,
        lng: 90.3972,
      },
    },
    {
      name: "location-4",
      location: {
        lat: 23.7661,
        lng: 90.4299,
      },
    },
    {
      name: "location-5",
      location: {
        lat: 23.7685,
        lng: 90.4255,
      },
    },
  ];

  console.log("selected");
  console.log(selected);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={choosedCenter}
      zoom={10}
      options={{
        // mapTypeControl: false,
        // mapTypeId: "satellite",
        // mapTypeId: "terrain",
        // mapTypeId: "hybrid",
        // fullscreenControl: false,
        // streetViewControl: false,
        // zoomControl: false,
        // disableDefaultUI: false,
        // draggableCursor: false,
        // draggable: false,
        navigationControl: true,
      }}
      yesIWantToUseGoogleMapApiInternals
    >
      {customersinMap.length > 0 &&
        customersinMap.map((single) => {
          {
            /* console.log("single");
          console.log(single); */
          }
          return (
            <div key={single.name}>
              <Marker
                position={single.location}
                // options={{
                //   icon: "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png",
                // }}
                // icon={{
                //   url: "https://cdn2.iconfinder.com/data/icons/it-administrator/500/vab942_3_company_it_administrator_isometric_cartoon_woman_business-256.png",

                //   fillOpacity: 1,
                //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                // }}
                // icon={{
                //   url: "https://cdn0.iconfinder.com/data/icons/thin-line-color-2/21/31_1-256.png",

                //   fillOpacity: 1,
                //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                // }}
                // icon={{
                //   url: "https://cdn-icons-png.flaticon.com/512/6362/6362247.png",

                //   fillOpacity: 1,
                //   scaledSize: new window.google.maps.Size(50, 50), // scaled size
                // }}
                onClick={() => {
                  console.log("single");
                  console.log(single);
                  setSelected(single);
                  setChoosedCenter(single.location);
                }}
                // options={{
                //   icon: URL(
                //     "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png"
                //   ),
                // }}
              />
            </div>
          );
        })}
      {isLoaded && selected !== "" && (
        <>
          <InfoWindowCore selected={selected} setSelected={setSelected} />
        </>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import DashboardIcon from "@mui/icons-material/Dashboard";
import Decoder from "jwt-decode";
import { useFormik } from "formik";
import { PasswordChangeSchema, signinSchema } from "../schemas";
import { Route, withRouter } from "react-router-dom";
import { signin, authenticate, isAuthenticated } from "../auth";
import { login, updatePassword } from "../redux/actions/userActions";
import "./Signin.css";
import { Link, Redirect } from "react-router-dom";
import Menu from "../core/Menu";
import PageHeader from "../core/PageHeader";
import { ASSIGNED_MENU_UPDATE_RESET } from "../redux/constants/RoleAndMenuConstants";
import Notification from "../core/Notification";
const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = ({ location, history }) => {
  // console.log("location");
  // console.log(location);
  // if (isAuthenticated()) {
  //   history.push("/books");
  // }
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const dispatch = useDispatch();
  const passwordUpdate = useSelector((state) => state.passwordUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = passwordUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  //   console.log("userLogin signin page");
  //   console.log(userLogin);
  const { loading, error, userInfo } = userLogin;

  const decodedata =
    userInfo !== null && userInfo !== undefined
      ? Decoder(userInfo.token)
      : null;

  //   console.log("signin location");
  //   console.log(location);
  //   console.log(JSON.parse(decodedata.UserMenus));
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/dashboard";
  var { from } = location.state || { from: { pathname: "/" } };
  //   console.log(redirect);

  useEffect(() => {
    if (userInfo) {
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: ASSIGNED_MENU_UPDATE_RESET });
      }
    }
  }, [history, dispatch, userInfo, successUpdate]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: PasswordChangeSchema,
      onSubmit: (values, action) => {
        console.log("values");
        console.log(values);
        dispatch(
          updatePassword({
            userSystemId: decodedata.UserSystemId,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          })
        );
      },
    });
  const redirectUser = () => {
    if (values.redirectToReferrer) {
      if (isAuthenticated()) {
        return <Redirect to="/books" />;
      }
    }
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            height: "100%",
          }}
        >
          <div
            className="spinner-grow"
            style={{ width: "5rem", height: "5rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <Menu />
          <PageHeader
            icon={<DashboardIcon />}
            title="Change Password"
            subtitle="You can change your password from here.."
          />
          <div className="signin">
            {redirectUser()}
            <main className="form-signin w-100 m-auto">
              <form className="form" onSubmit={handleSubmit}>
                <div className="mb-2 form-group required">
                  <label
                    htmlFor="oldPassword"
                    className="form-label form-label-sm"
                  >
                    Old Password
                  </label>
                  <span style={{ display: "flex" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control form-control-sm"
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="oldPassword"
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ marginBottom: "0px" }}
                    />
                    <i
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"}
                      style={{ marginLeft: "-30px", cursor: "pointer" }}
                    ></i>
                  </span>

                  {errors.error && errors.error === "password doesn't match" ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {errors.error}
                    </p>
                  ) : null}
                  {errors.oldPassword && touched.oldPassword ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {errors.oldPassword}
                    </p>
                  ) : null}
                </div>
                <div className="mb-2 form-group required">
                  <label
                    htmlFor="newPassword"
                    className="form-label form-label-sm"
                  >
                    New Password
                  </label>
                  <span style={{ display: "flex" }}>
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className="form-control form-control-sm"
                      id="newPassword"
                      name="newPassword"
                      placeholder="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ marginBottom: "0px" }}
                    />
                    <i
                      onClick={() => {
                        setShowPassword2(!showPassword2);
                      }}
                      className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"}
                      style={{ marginLeft: "-30px", cursor: "pointer" }}
                    ></i>
                  </span>

                  {errors.error && errors.error === "password doesn't match" ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {errors.error}
                    </p>
                  ) : null}
                  {errors.newPassword && touched.newPassword ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {errors.newPassword}
                    </p>
                  ) : null}
                </div>

                <div className="mb-2 form-group required">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label form-label-sm"
                  >
                    Confirm Password
                  </label>
                  <span style={{ display: "flex" }}>
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className="form-control form-control-sm"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ marginBottom: "0px" }}
                    />
                    <i
                      onClick={() => {
                        setShowPassword2(!showPassword2);
                      }}
                      className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"}
                      style={{ marginLeft: "-30px", cursor: "pointer" }}
                    ></i>
                  </span>

                  {errors.error && errors.error === "password doesn't match" ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {errors.error}
                    </p>
                  ) : null}
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {errors.confirmPassword}
                    </p>
                  ) : null}
                </div>
                <button className="w-100 btn btn-sm btn-primary" type="submit">
                  Change Password
                </button>
              </form>
            </main>
          </div>
          <Notification notify={notify} setNotify={setNotify} />
        </div>
      )}
    </>
  );
};

export default withRouter(ChangePassword);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Container, FloatingLabel } from "react-bootstrap";
import Menu from "../../../core/Menu";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import {
  FilterProductsForInvoice,
  listBatchAndMappedItemBasedOnProduct,
  listOrderTypeInvoice,
  listPackTypesForInvoice,
  listProductsForInvoice,
  listSalesOrderInternalDetails,
  createPackingStickerAction,
} from "../../../redux/actions/invoiceActions";
import { API_URL } from "../../../redux/constants/apiConstants";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../../core/Popup";
import { listCategories } from "../../../redux/actions/productActions";
import SuccessPopup from "../../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import PackagingItem from "../../../core/PackagingItem";
var FilteredProduct = [];
let obj = {};

const StockOutDefinePackingForSticker = ({ match, history }) => {
  const salesOrderId = match.params.id;
  const dispatch = useDispatch();

  const invoicePackTypeList = useSelector((state) => state.invoicePackTypeList);
  const {
    loading: loadingPackType,
    error: errorPackType,
    packTypes,
  } = invoicePackTypeList;
  
  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const salesOrderInternalPackageDone = useSelector(
    (state) => state.salesOrderInternalPackageDone
  );
  const {
    loading: loadingPackageDone,
    error: errorPackageDone,
    success: successPackageDone,
    packageDoneSalesOrder,
  } = salesOrderInternalPackageDone;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [switchDiscountPercentage, setSwitchDiscountPercentage] =
    useState(false);
  const [switchTOpen, setSwitchTOpen] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "1",
    unitPrice: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0",
    lineTotal: "0.00",
    switchDiscountPercentage: false,
  });
  const [lineTotal, setLineTotal] = useState(0.0);
  const [productVatAmmount, setProductVatAmmount] = useState(0.0);
  const [productAitAmmount, setProductAitAmmount] = useState(0.0);
  const [quantityPrice, setQuantityPrice] = useState(0.0);
  const [vadTotal, setVadTotal] = useState(0.0);
  const [subPurchase, setSubPurchase] = useState(0.0);
  const [vatAmmount, setVatAmmount] = useState(0.0);
  const [aitAmmount, setAitAmmount] = useState(0.0);
  const [discountAmmount, setDiscountAmmount] = useState(0.0);

  const [total, setTotal] = useState(0.0);
  const [selected, setSelected] = useState([]);
  const [selectedPackType, setSelectedPackType] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [batchAndMap, setBatchAndMap] = useState();
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [data, setData] = useState({
    isOpen: false,
    currency: "TK",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    taxRate: "",
    taxAmmount: "0.00",
    vatRate: "",
    vatAmmount: "0.00",
    aitRate: "",
    aitAmmount: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0.00",
    discountRate: "",
    discountAmmount: "0.00",
    adjustmentAmount: "0.00",
    switchDiscountPercentage: false,
    editableField: 1,
    items: [],
  });
  
  useEffect(() => {
    if (userInfo) {
      setSwitchTStuck(true);
      dispatch(listProductsForInvoice());
      dispatch(listPackTypesForInvoice());
      dispatch(listSalesOrderInternalDetails(salesOrderId));
      dispatch(listCategories());
      dispatch(listCustomersForSignup());
      dispatch(listOrderTypeInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: {
          from: { pathname: `/salesorderinternal/${salesOrderId}/edit` },
        },
      };
      history.push(location);
    }
  }, [salesOrderId, dispatch, history, userInfo]);

  const changeValues = async () => {
    setSwitchTStuck(true);
    if (
      JSON.stringify(salesorderinternalsingle) !== "{}" &&
      salesorderinternalsingle !== undefined
    ) {
      var d = new Date(
        salesorderinternalsingle.stockOutGeneralData.invoiceDate
      );
      d = d.toString();
      console.log("d");
      console.log(d);
      d = d.split(" ");
      d = [d[2], d[1], d[3]].join("-");
      setInvoiceDate(d);
      setSelectedCustomer([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soCustomerId,
          label: salesorderinternalsingle.stockOutGeneralData.customerName,
        },
      ]);

      //shahed
      setSelectedSupplier([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soSupplierId,
          label: salesorderinternalsingle.stockOutGeneralData.supplierName,
        },
      ]);
      //shahed
      setSelectedOrderType([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soJobTypeId,
          label: salesorderinternalsingle.stockOutGeneralData.jobTypeName,
        },
      ]);
      salesorderinternalsingle.stockOutItemsData.map((single) => {
        if (!FilteredProduct.includes(single.sodProductId)) {
          FilteredProduct.push(single.sodProductId);
        }
      });
      dispatch(FilterProductsForInvoice(FilteredProduct));
      setSwitchTStuck(true);

      obj = salesorderinternalsingle.stockOutItemsData.find(
        (o) => o.sodUnitVatPercantage === 5 && o.sodUnitAitPercantage === 3
      );
      if (JSON.stringify(obj) !== "{}" || obj !== undefined) {
        setSwitchT(true);
        setProduct({
          ...product,
          vat: 5 || 0,
          ait: 3 || 0,
        });
        setProductVatAmmount(product.unitPrice * (Math.abs(5) / 100));
        setProductAitAmmount(product.unitPrice * (Math.abs(3) / 100));
      }
    if (batchAndMap !== undefined) {
        setData({
          ...data,
          invoiceNumber:
            salesorderinternalsingle.stockOutGeneralData.soSystemNo,
          items: batchAndMap,
          subTotal: salesorderinternalsingle.stockOutBillData.calculatedBill,
          vatRate: salesorderinternalsingle.stockOutBillData.billVatPercantage,
          aitRate: salesorderinternalsingle.stockOutBillData.billAitPercantage,
          discount:
            salesorderinternalsingle.stockOutBillData.billDiscountAmount,
          switchDiscountPercentage:
            salesorderinternalsingle.stockOutBillData.billDiscountType === "F"
              ? false
              : true,
          adjustmentAmount:
            salesorderinternalsingle.stockOutBillData.billAdjustmentAmount,
        });
      }
    }
  };
  useEffect(() => {
    changeValues();
  }, [salesorderinternalsingle, batchAndMap]);

  useEffect(() => {
    if (salesorderinternalsingle?.stockOutItemsData) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      if (JSON.stringify(salesorderinternalsingle) !== "{}") {
        var newData = salesorderinternalsingle.stockOutItemsData.map(
          async (singleData, i) => {
            const response = await fetch(
              `${API_URL}/Product/GetPackType`,
              requestOptions
            );
            const data = await response.json();
            const finalPackType = data.dataObj.map((single) => {
              return {
                id: single.packId,
                label: single.packTypeName,
              };
            });

            return {
              index: singleData.sodProductId + "-" + i,
              productId: singleData.sodProductId,
              productName: singleData.productName,
              packSize: "",
              packNo: "",
              packQuantity: "",
              quantity: singleData.sodQty || 1.0,
              packTypeList: finalPackType,
              sopPackId: "",
            };
          }
        );
        Promise.all(newData).then((products) => {
          setBatchAndMap(products);
        });
      }
    }
  }, [salesorderinternalsingle]);

  useEffect(() => {
    handleCalculateSubTotal();
    handleCalculateTotal();
    handleProductCalculateTotal();
  }, []);

  useEffect(() => {
    handleProductCalculateTotal();
  }, [product]);

  useEffect(() => {
    handleCalculateTotal();
  }, [data]);

  const handleRowDel = (items) => {
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.index;
    });
    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
    handleCalculateSubTotal();
  };

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "1.00",
      description: "",
      quantity: 1,
    };
    if (
      product.id !== "" &&
      selectedCustomer.length > 0 &&
      selectedOrderType.length > 0
    ) {
      if (lineTotal < 0) {
        setOpenPopup({
          ...openPopup,
          title: "VALUE ISSUE!!!",
          subTitle: "Line Value can't be negative!",
          isOpen: true,
        });
      } else {
        setSwitchTStuck(true);
        dispatch(listBatchAndMappedItemBasedOnProduct(product.id)).then(
          (res) => {
            data.items.push({
              productId: product.id,
              productName: product.label,
              quantity: product.quantity || 1.0,
              vat: product.vat || 0,
              ait: product.ait || 0,
              unitPrice: lineTotal,
              discountAmmount: product.discount || 0,
              lineTotal: lineTotal * product.quantity || 1,
              batchList: res.finalBatch,
              mappedItemList: res.finalMappedItem,
              sodMappedProductId: "",
              sodRealatedBatchId: "",
            });
          }
        );

        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "1",
          unitPrice: "0.00",
          vat: JSON.stringify(obj) !== "{}" || obj !== undefined ? 5 : "0.00",
          ait: JSON.stringify(obj) !== "{}" || obj !== undefined ? 3 : "0.00",
          discount: "0",
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        handleCalculateSubTotal();
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };
  const handleProductCalculateTotal = () => {
    var quantity = parseFloat(product.quantity || 1.0);
    var unitPrice = parseFloat(product.unitPrice || 1.0);
    var vat = parseFloat(product.vat || 0);
    var ait = parseFloat(product.ait || 0);
    var discount = parseFloat(product.discount || 0);
    var productDiscountAmmount = discount;

    var lineTotal = parseFloat(4);
    var quantityPrice = parseFloat(4);
    var vadTotal = parseFloat(4);
    var subPurchase = parseFloat(4);

    if (product.switchDiscountPercentage) {
      productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
    }
    lineTotal = lineTotal + (unitPrice - productDiscountAmmount);
    quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

    var lineTotalVat = unitPrice * (vat / 100);
    var lineTotalAit = unitPrice * (ait / 100);

    lineTotal = lineTotal + lineTotalVat + lineTotalAit;

    vadTotal = lineTotalVat + lineTotalAit;
    vadTotal = parseFloat(vadTotal.toFixed(4));
    lineTotal = lineTotal.toFixed(4);

    quantityPrice = parseFloat(quantityPrice.toFixed(4));
    subPurchase = unitPrice + vadTotal;
    subPurchase = parseFloat(subPurchase.toFixed(4));
    setQuantityPrice(quantityPrice);
    setVadTotal(vadTotal);
    setSubPurchase(subPurchase);
    setLineTotal(lineTotal);
  };

  const handleCalculateSubTotal = () => {
    var items = data.items;
    var subTotal = parseFloat(0);
    items.map(function (item) {
      var singleLineTotal = parseFloat(item.lineTotal);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    setData({
      ...data,
      subTotal: parseFloat(subTotal).toFixed(4),
      taxAmmount: parseFloat(
        parseFloat(subTotal) * (data.taxRate / 100)
      ).toFixed(4),
      discountAmmount: parseFloat(
        parseFloat(subTotal) * (data.discountRate / 100)
      ).toFixed(4),
      total: subTotal - data.discountAmmount + parseFloat(data.taxAmmount),
    });
    setTotal(subTotal - data.discountAmmount + parseFloat(data.taxAmmount));
  };
  const handleCalculateTotal = () => {
    var items = data.items;
    var subTotal = parseFloat(0);
    var discountOverall = parseFloat(data.discount || 0);
    var adjustmentAmount = parseFloat(data.adjustmentAmount || 0);
    var productOverallDiscountAmmount = discountOverall;

    items.map(function (item) {
      var singleLineTotal = parseFloat(item.lineTotal);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    var totalT = parseFloat(0);
    var vatAmmountT = parseFloat(0);
    vatAmmountT = subTotal * (data.vatRate / 100);
    setVatAmmount(vatAmmountT);

    var aitAmmountT = parseFloat(0);
    aitAmmountT = subTotal * (data.aitRate / 100);
    setAitAmmount(aitAmmountT);

    totalT = subTotal + vatAmmountT + aitAmmountT;

    if (data.switchDiscountPercentage) {
      productOverallDiscountAmmount =
        totalT * (productOverallDiscountAmmount / 100);
    }

    setDiscountAmmount(productOverallDiscountAmmount);
    totalT = totalT - productOverallDiscountAmmount - adjustmentAmount;

    setTotal(totalT.toFixed(2));
  };

  const handleCalculateTotalFromEditable = (id, newItems) => {
    var items = newItems;
    var editedNewItems = items.map(function (sitems, i) {
      for (var key in sitems) {
        if (key == "packQuantity" && sitems.index == id) {
          if (
            sitems["quantity"] <
            parseFloat(sitems.packSize) * parseFloat(sitems.packNo)
          ) {
            setOpenPopup({
              ...openPopup,
              title: `ERROR!!!`,
              subTitle: `Can't exceed original quantity.`,
              isOpen: true,
            });
          } else if (
            parseFloat(sitems["quantity"]) >=
            parseFloat(sitems.packSize) * parseFloat(sitems.packNo)
          ) {
            // console.log("Hello");
            sitems[key] = parseFloat(
              parseFloat(sitems.packSize) * parseFloat(sitems.packNo)
            );
          }
        }
      }
      return sitems;
    });
    var finalNewItem = editedNewItems.map((single, i) => {
      if (
        single.quantity >
          parseFloat(single.packSize) * parseFloat(single.packNo) &&
        single.index === id
      ) {
        return {
          index: single.productId + "-" + newItems.length,
          productId: single.productId,
          productName: single.productName,
          packSize: "",
          packNo: "",
          packQuantity: "",
          quantity: single.quantity,
          packTypeList: single.packTypeList,
          sopPackId: "",
          delete: 1,
        };
      }
    });

    var a = finalNewItem.filter((s) => {
      return s !== undefined;
    });
    var flag = 0;
    var sum = 0;
    var newId = id.split("-");

    editedNewItems.map((single, i) => {
      if (single.productId === parseInt(newId[0])) {
        sum = parseFloat(
          sum + parseFloat(single.packSize) * parseFloat(single.packNo)
        );
      }
    });

    var x = editedNewItems.filter((num) => {
      return num.index === id;
    });

    if (x[0].quantity === sum) {
      flag = -1;
    }

    if (flag === 0) {
      var arr = a;
      if (arr[0] !== undefined) {
        var index;
        editedNewItems.map((s, i) => {
          if (s.productId === arr[0].productId) {
            index = i;
          }
        });
        editedNewItems.splice(index + 1, 0, arr[0]);
      }
    }
    setData({
      ...data,
      items: editedNewItems,
    });
  };

  const onItemizedItemEdit = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {

      for (var key in sitems) {
        if (key === "packInfo") {
          sitems["packInfo"] = {
            id: items.value.id,
            label: item.value.label,
          };
        } else if (key == item.name && sitems.index == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
    });
    var x = newItems.filter((num) => {
      return num.index === item.id;
    });

    setTimeout(() => {
      if (x[0].packSize !== "" && x[0].packNo !== "") {
        handleCalculateTotalFromEditable(item.id, newItems);
      }
    }, 3000);
  };
  const onItemizedItemConstant = (evt) => {

    var item = {
      id: evt.productId,
      value: evt.quantity,
    };

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
 
      for (var key in sitems) {
        if (sitems.index == item.id) {
          sitems["packSize"] = item.value === "" ? "" : item.value;
          sitems["packNo"] = item.value === "" ? "" : 1;
          sitems["packQuantity"] =
            item.value === "" ? "" : sitems["packQuantity"];
        }
      }
      return sitems;
    });

    setData({
      ...data,
      items: newItems,
    });
    // handleCalculateSubTotal();
    var x = newItems.filter((num) => {
      return num.index === item.id;
    });
    console.log("x");
    console.log(x);
    setTimeout(() => {
      if (x[0].packSize !== "" && x[0].packNo !== "") {
        handleCalculateTotalFromEditable(item.id, newItems);
      }
    }, 3000);
  };

  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
  };

  const onItemizedBatchAndMapEdit = (evt) => {
    var items = data.items.slice();
    var newItems = items.map(function (sitems) {

      for (var key in sitems) {
        if (key == evt.name && sitems.productId == evt.productId) {
          sitems[key] = evt.id;
        }
      }
      return sitems;
    });

    setData({
      ...data,
      items: newItems,
    });
  };

  const editProductField = (event) => {

    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
  };

  const openModal = (event) => {
    event.preventDefault();

    if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      // var flag = 0;
      if (data.items.length > 0) {
        var wasNull = false;
        for (var i in data.items) {
          if (
            data.items[i].packSize === "" ||
            data.items[i].packQuantity === ""
          ) {
            wasNull = true;
          }
        }
        if (wasNull === false) {
          var confirmedQty = data.items.map((singleData) => {
            return singleData.productId + "-" + singleData.quantity;
          });
   
          var removeDuplicateArrays = [...new Set(confirmedQty)];
        
          removeDuplicateArrays = removeDuplicateArrays.map((single) => {
            var id = single.split("-");
            return parseFloat(id[1]);
          });
 
        var sumQuantity = removeDuplicateArrays.reduce((acc, currentValue) => 
          acc + currentValue, 0);
 
          var sumPackQuantity = data.items.reduce((acc, singleData) => 
          acc + parseFloat(singleData.packQuantity), 0);
         
         
          if (Math.abs(sumQuantity - sumPackQuantity) < 0.0001) {
            var dataArray = data.items.map((singleData) => {
              return {
                soSystemId: parseInt(salesOrderId),
                sopProductId: parseInt(singleData.productId),

                sopProductQty: parseFloat(singleData.packQuantity).toFixed(4),
                sopPackId: parseInt(singleData.sopPackId), //problem
                sopPackSize: parseFloat(singleData.packSize).toFixed(4),
                sopNoPack: parseInt(singleData.packNo),
              };
            });

            dispatch(createPackingStickerAction(dataArray)).then(
              (result) => {
                if (
                  result?.title === "One or more validation errors occurred."
                ) {
                  setOpenPopup({
                    ...openPopup,
                    title: "ERROR!!!",
                    subTitle: "Something went wrong!",
                    isOpen: true,
                  });
                } else if (result?.errMsg) {
                  setOpenPopup({
                    ...openPopup,
                    title: "ERROR!!!",
                    subTitle: `${result?.errMsg}`,
                    isOpen: true,
                  });
                } else {
                  history.push("/stockoutpackagesticker/orderlist");
                }
              }
            );

            handleCalculateTotal();
          } else {
            setOpenPopup({
              ...openPopup,
              title: "QUANTITY ISSUE!!!",
              subTitle: "Quantities and Pack Quantities are not equal!",
              isOpen: true,
            });
          }
        } else {
          setOpenPopup({
            ...openPopup,
            title: "EMPTY FIELDS!!!",
            subTitle: "No Fields can be empty!",
            isOpen: true,
          });
        }
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });

  return (
    <div>
      <Menu />

      <Container>
        <Form>
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <Form.Label className="fw-bold">
                  Invoice&nbsp;Number:&nbsp;{data.invoiceNumber}
                </Form.Label>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">
                        Customer Name:
                      </Form.Label>
                       <Form.Control
                        placeholder={"partnerName"}
                        value={
                          JSON.stringify(salesorderinternalsingle) !== "{}" &&
                          salesorderinternalsingle !== undefined
                            ? salesorderinternalsingle.stockOutGeneralData.customerName !== null
                              ? salesorderinternalsingle.stockOutGeneralData.customerName
                              : // Use the supplier address when customer address is null
                                (salesorderinternalsingle.stockOutGeneralData.supplierName !== null
                                  ? salesorderinternalsingle.stockOutGeneralData.supplierName
                                  : "Partner Not Available")
                            : "loading.."
                        }
                        type="text"
          
                        disabled
                      />
                      
                    </Form.Group>
                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          marginRight: "10px",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Type:</Form.Label>
                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="orderTypeId"
                          label="Order Type"
                          disabled={
                            loadingOrderType
                              ? true
                              : data.items.length > 0 && switchTStuck
                              ? true
                              : false
                          }
                          onChange={(selectedOrderType) => {
                            setSelectedOrderType(selectedOrderType);
                          }}
                          options={orderTypes !== undefined ? orderTypes : []}
                          placeholder="Order Type"
                          selected={selectedOrderType}
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Date:</Form.Label>
                        <Form.Control
                          placeholder={"Who is this invoice to?"}
                          value={invoiceDate}
                          type="text"
                          name="dateOfIssue"
                          onChange={(event) => editField(event)}
                          autoComplete="name"
                          required="required"
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label className="fw-bold">
                      Customer&nbsp;Address:&nbsp;
                    </Form.Label>
                    <Form.Control
                      placeholder={"customerAddress"}
                      value={
                        JSON.stringify(salesorderinternalsingle) !== "{}" &&
                        salesorderinternalsingle !== undefined
                          ? salesorderinternalsingle.stockOutGeneralData.customerAddress !== null
                            ? salesorderinternalsingle.stockOutGeneralData.customerAddress
                            : // Use the supplier address when customer address is null
                              (salesorderinternalsingle.stockOutGeneralData.supplierAddress !== null
                                ? salesorderinternalsingle.stockOutGeneralData.supplierAddress
                                : "Supplier Address Not Available")
                          : "loading.."
                      }
                      type="text"
                      name="invoiceNumber"
                      autoComplete="address"
                      onChange={(event) => editField(event)}
                      required="required"
                      as="textarea"
                      rows={1}
                      disabled
                    />

                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <Form.Label className="fw-bold">Pack Type:</Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="packTypeId"
                          label="Pack Type"
                          disabled={loadingPackType ? true : false}
                          onChange={(selectedPackType) => {
                            if (
                              JSON.stringify(salesorderinternalsingle) !==
                                "{}" &&
                              salesorderinternalsingle !== undefined
                            ) {
                              var newData = data.items.map(
                                async (singleData, i) => {
                                  return {
                                    ...singleData,
                                    sopPackId:
                                      selectedPackType.length > 0
                                        ? selectedPackType[0].id
                                        : "",
                                    packInfo: {
                                      id:
                                        selectedPackType.length > 0
                                          ? selectedPackType[0].id
                                          : "",
                                      label:
                                        selectedPackType.length > 0
                                          ? selectedPackType[0].label
                                          : "",
                                    },
                                  };
                                }
                              );
                              Promise.all(newData).then((products) => {
                                setBatchAndMap(products);
                                setData({
                                  ...data,
                                  items: products,
                                });
                              });
                            }
                            // }
                            setSelectedPackType(selectedPackType);
                          }}
                          options={packTypes !== undefined ? packTypes : []}
                          placeholder="Pack Type"
                          selected={selectedPackType}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <hr className="my-4" />
                <PackagingItem
                  onItemizedItemEdit={onItemizedItemEdit}
                  onItemizedItemConstant={onItemizedItemConstant}
                  onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
                  onRowAdd={handleAddEvent}
                  onRowDel={handleRowDel}
                  currency={data.currency}
                  items={data.items}
                  escalate={data.editableField}
                  switchMappingOption={switchMappingOption}
                  setSwitchMappingOption={setSwitchMappingOption}
                  allOptionAuto={true}
                />

                <hr className="my-4" />

                <div>
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingPackageDone ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <InventoryIcon size="1rem" />
                    )}{" "}
                    Package11
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={() => {
                      history.push("/stockoutpackagesticker/orderlist");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </Card>
            </Col>
            
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      </Container>
    </div>
  );
};

export default StockOutDefinePackingForSticker;

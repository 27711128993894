import React from "react";
// import "bulma/css/bulma.css";
// import "bulma-helpers/css/bulma-helpers.min.css";
import Menu from "../core/Menu";
import { Link } from "react-router-dom";

const NotAuthorized = () => {
  return (
    <>
      <Menu />

      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <h1 className="display-1 fw-bold">401</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Opps!</span> Not Authorized.
          </p>
          <p className="lead">
            The page you’re looking for is out of your authorization
          </p>
          <Link to="/" className="btn btn-primary">
            Go Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotAuthorized;

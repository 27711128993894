import React, { useState, useCallback } from "react";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import { Route, Redirect, withRouter, Link } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { isAuthenticated } from "../auth/index";
import { RoleSchema, signUpSchema } from "../schemas";
import { signup } from "../auth";
import Input from "../core/controls/Input";

import "./Signup.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { listCustomers } from "../redux/actions/customerActions";
import {
  listCustomersForSignup,
  listEmployeeDetails,
  listEmployees,
  listRoles,
} from "../redux/actions/userActions";
import Menu from "../core/Menu";
import PageHeader from "../core/PageHeader";
import Button from "../core/controls/Button";
import FormPopup from "../core/FormPopup";
import { Form } from "../core/useForm";
import { createRole } from "../redux/actions/RoleAndMenuActions";
import { ROLES_CREATE_RESET } from "../redux/constants/RoleAndMenuConstants";
import Notification from "../core/Notification";
import AddRole from "./AddRole";
import Checkbox from "../core/controls/Checkbox";
import { EMPLOYEE_DETAILS_RESET } from "../redux/constants/employeeConstants";
import "../pages/Customers/Customer.css";
import { Circles } from "react-loader-spinner";

const useStyles = makeStyles(() => ({
  inputWidthSetter: {
    width: "100%",
    [useTheme().breakpoints.up("md")]: {
      width: "90%",
    },
  },

  inputWidthSetterwithButtoAside: {
    width: "100%",
    [useTheme().breakpoints.up("md")]: {
      width: "85%",
    },
  },
  pageContent2: {
    // margin: useTheme().spacing(5),
    padding: "0 20px 0 10px",
    // "& .MuiGrid-item": {
    //   minWidth: "480px !important",
    // },
  },
}));

const initialValues = {
  customer: {
    id: "",
    label: "",
  },
  employee: {
    id: "",
    label: "",
  },
  role: {
    id: "",
    label: "",
  },
  userFirstName: "",
  userLastName: "",
  userAddress: "",
  userContactNo: "",
  userMail: "",
  userCategoryId: "",
  userActiveStatus: "",
  clientId: "",
  userName: "",
  userPassword: "",
  userConfirmPassword: "",
};

const Signup = ({ history }) => {
  // if (isAuthenticated()) {
  //   history.push("/books");
  // }
  const dispatch = useDispatch();
  const classes = useStyles();

  const customerList = useSelector((state) => state.customerList);
  const { loading: loadingCustomer, error, customers } = customerList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    employees,
  } = employeeList;

  const roleList = useSelector((state) => state.roleList);
  const {
    loading: loadingRoleList,
    error: errorRoleList,
    success: successRoleList,
    roles,
  } = roleList;
  // console.log("roles");
  // console.log(roles);
  const employeeDetails = useSelector((state) => state.employeeDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    employee,
  } = employeeDetails;
  // console.log("employee");
  // console.log(employee);
  const createdRole = useSelector((state) => state.createdRole);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = createdRole;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  // console.log("customers");
  // console.log(customers);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOptionMessage, setShowOptionMessage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return [];
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    rowId: "",
    usersWithRoles: [],
    title: "",
    subTitle: "",
  });

  const [employeeFilterFn, setEmployeeFilterFn] = useState({
    fn: (items) => {
      return [];
    },
  });

  const [checkedEmail, setCheckedEmail] = useState(true);
  const [checkedMobile, setCheckedMobile] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      setLoading(true);

      signup({
        userSystemId: 0,
        userUniqueCode:
          values.employee.id !== ""
            ? employee.employeeCode.toString()
            : values.customer.id.toString(),
        userFirstName: values.userFirstName,
        userLastName: values.userLastName,
        userAddress: "string",
        userContactNo: values.userContactNo,
        userMail: values.userMail,
        userCategoryId: 0,
        userActiveStatus: 1,
        clientId: values.customer.id,
        userName: checkedEmail ? values.userMail : values.userContactNo,
        userPassword: values.userPassword,
        //shahed
        //userRoleId: values.role.id !== "" ? values.role.id : 10,
        userRoleId: values.role.id,
      })
        .then((response) => {
          setLoading(false);
          console.log("data");
          console.log(response);

          // console.log("data");
          // console.log(data);
          setNotify({
            isOpen: true,
            message: "New Account Created",
            type: "success",
          });
          action.resetForm();
          setCheckedEmail(true);
          setCheckedMobile(false);
          // if (data.error) {
          //   action.setFieldError("general", data.error);
          //   action.setSubmitting(false);
          // } else {
          //   setSuccess(true);
          //   action.resetForm();
          // }
        })
        .catch((error) => {
          console.log("Hello");
          console.log(error.response.data);
          action.setFieldError("general", error.response.data.errMsg);
          action.setSubmitting(false);
        });

      // sending js object
    },
  });

  const changeValues = () => {
    formik.setFieldValue("userFirstName", employee.empFirstName || "");
    formik.setFieldValue("userLastName", employee.empLastName || "");
    var re = /^\+88$/;
    const str = employee.empContactNo || "";

    if (re.test(str.slice(0, 3)) === true) {
      formik.setFieldValue("userContactNo", str.slice(3, 12));
    } else {
      formik.setFieldValue("userContactNo", employee.empContactNo || "");
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      dispatch(listEmployees());
      dispatch(listRoles());
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "New Role Created",
          type: "success",
        });
        dispatch({ type: ROLES_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/signup" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);
  const resetValue = () => {
    formik.resetForm();
  };

  useEffect(() => {
    changeValues();
  }, [employee]);

  useEffect(() => {
    resetValue();
  }, []);

  const handleSearch = (e) => {
    // console.log("Hello");
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        // console.log("items");
        // console.log(items);
        if (target.value === "") {
          return [];
        } else if (target.value.length > 4) {
          return items.filter((x) =>
            x.label.toUpperCase().startsWith(target.value.toUpperCase())
          );
        } else {
          return [];
        }
      },
    });
  };

  const handleEmployeeSearch = (e) => {
    // console.log("Hello");
    let target = e.target;
    setEmployeeFilterFn({
      fn: (items) => {
        // console.log("items");
        // console.log(items);
        if (target.value === "") {
          return [];
        } else if (target.value.length > 4) {
          return items.filter((x) =>
            x.label.toUpperCase().startsWith(target.value.toUpperCase())
          );
        } else {
          return [];
        }
      },
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  const GetEmployees = (event, values) => {
    dispatch(listEmployees());
  };

  const GetEmployeeRoles = (event, values) => {
    dispatch(listRoles());
  };

  const GetEmployeeDetails = (event, values) => {
    // console.log("Emloyee Id");
    // console.log(values);
    dispatch(listEmployeeDetails(values.id));
  };

  const changeDefaultCustomer = () => {};

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });

    dispatch({ type: EMPLOYEE_DETAILS_RESET });
    // formik.setFieldValue("employee", {
    //   id: "",
    //   label: "",
    // });

    if (v.id === 1) {
      GetEmployees(e, v);
      GetEmployeeRoles(e, v);
    }
  });

  // console.log("formik.values.defaultCustomer");
  // console.log(formik.values.defaultCustomer);

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const roleOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const roleChange = useCallback((e, v) => {
    formik.setFieldValue("role", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
    GetEmployeeDetails(e, v);
  });

  const employeeOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  // console.log("formik.values.employee");
  // console.log(formik.errors);
  // console.log("JSON.stringify(employee)");
  // console.log(JSON.stringify(employee));
  return (
    <>
      <Menu />
      <PageHeader
        icon={<GroupAddIcon />}
        title="Signup"
        subtitle="Signup an User"
      />

      <div className="signup">
        <main className="form-signup w-100 paperdesign1">
          <form className="form" onSubmit={formik.handleSubmit}>
            {/* <h1 className="h4 mb-3 fw-normal">Create free account</h1> */}

            <p
              className="text-sm-start fst-normal semi-strong-text"
              style={{ visibility: "hidden" }}
            >
              Already have an account? <Link to="/signin">Signin</Link>
            </p>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="mb-3 form-group required">
                  <Autocomplete
                    disablePortal
                    size="small"
                    disabled={loadingCustomer ? true : false}
                    id="combo-box-demo1"
                    isOptionEqualToValue={customerOptionCheck}
                    onChange={customerChange}
                    value={formik.values.customer}
                    options={customers !== undefined ? customers : []}
                    onOpen={formik.handleBlur}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Customer List"
                        name="customer"
                        error={
                          formik.errors.customer !== undefined
                            ? formik.errors.customer.id
                            : ""
                        }
                        touched={
                          formik.touched.customer !== undefined
                            ? formik.touched.customer
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    freeSolo={true}
                    className={classes.inputWidthSetter}
                    // noOptionsText={
                    //   showOptionMessage ? "No Option" : "continue typing.."
                    // }
                  />
                </div>

                {/* <div className="mb-3 form-group required">
                          <Autocomplete
                            disablePortal
                            size="small"
                            disabled={loadingCustomer ? true : false}
                            id="combo-box-demo1"
                            isOptionEqualToValue={customerOptionCheck}
                            onChange={(e, v) => {
                              // console.log("v");
                              // console.log(v);
                              if (v.id === "1" || v.id === 1) {
                                formik.setFieldValue("defaultCustomer", true);
                              } else {
                                formik.setFieldValue("defaultCustomer", false);
                              }

                              customerChange(e, v);
                            }}
                            value={formik.values.customer}
                            options={customers !== undefined ? customers : []}
                            onOpen={formik.handleBlur}
                            renderInput={(params) => (
                              <Input
                                label="Company Name"
                                name="customer"
                                onChange={handleSearch}
                                error={
                                  formik.errors.customer !== undefined
                                    ? formik.errors.customer.id
                                    : ""
                                }
                                touched={
                                  formik.touched.customer !== undefined
                                    ? formik.touched.customer
                                    : ""
                                }
                                {...params}
                                onBlur={formik.handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            freeSolo={true}
                            className={classes.inputWidthSetter}
                            // noOptionsText={
                            //   showOptionMessage ? "No Option" : "continue typing.."
                            // }
                          />
                        </div> */}
                {formik.values.customer !== "" &&
                formik.values.customer.label === "Trade Zentrum" ? (
                  <div className="mb-3 form-group required">
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled={loadingEmployee ? true : false}
                      id="combo-box-demo1"
                      isOptionEqualToValue={employeeOptionCheck}
                      onChange={employeeChange}
                      value={formik.values.employee}
                      options={employees !== undefined ? employees : []}
                      onOpen={formik.handleBlur}
                      renderInput={(params) => (
                        <Input
                          label="Employee List"
                          name="employee"
                          onChange={handleEmployeeSearch}
                          error={
                            formik.errors.employee !== undefined
                              ? formik.errors.employee.id
                              : ""
                          }
                          touched={
                            formik.touched.employee !== undefined
                              ? formik.touched.employee
                              : ""
                          }
                          {...params}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      freeSolo={true}
                      className={classes.inputWidthSetter}
                      // noOptionsText={
                      //   showOptionMessage ? "No Option" : "continue typing.."
                      // }
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="mb-3 form-group required">
                  <Input
                    label="Email @"
                    name="userMail"
                    error={formik.errors.userMail}
                    touched={formik.touched.userMail}
                    value={formik.values.userMail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.inputWidthSetter}
                  />
                  <Checkbox
                    label={`Take Email @ as username ${
                      checkedEmail ? "✔" : "?"
                    }`}
                    name="isUserIdMail"
                    value={checkedEmail}
                    onChange={(e) => {
                      if (checkedEmail) {
                        setCheckedEmail(false);
                        setCheckedMobile(true);
                      } else {
                        setCheckedEmail(true);
                        setCheckedMobile(false);
                      }
                    }}
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        height: "25px !important",
                      },
                    }}
                  />
                  {checkedEmail && formik.errors.general ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {formik.errors.general}
                    </p>
                  ) : null}
                </div>

                <div className="mb-3 form-group required">
                  <Input
                    sx={{
                      "& input": {
                        marginBottom: "0px !important",
                      },
                    }}
                    label="Password"
                    name="userPassword"
                    type={showPassword ? "text" : "password"}
                    error={formik.errors.userPassword}
                    touched={formik.touched.userPassword}
                    value={formik.values.userPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={classes.inputWidthSetter}
                  />
                </div>

                <div className="mb-3 form-group required">
                  <Input
                    sx={{
                      "& input": {
                        marginBottom: "0px !important",
                      },
                    }}
                    label="Confirm Password"
                    name="userConfirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    error={formik.errors.userConfirmPassword}
                    touched={formik.touched.userConfirmPassword}
                    value={formik.values.userConfirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={classes.inputWidthSetter}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="mb-3 form-group required">
                  <Input
                    label="First Name"
                    name="userFirstName"
                    error={formik.errors.userFirstName}
                    touched={formik.touched.userFirstName}
                    value={formik.values.userFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.inputWidthSetter}
                    disabled={JSON.stringify(employee) !== "{}" ? true : false}
                  />
                </div>
                <div className="mb-3 form-group required">
                  <Input
                    label="Last Name"
                    name="userLastName"
                    error={formik.errors.userLastName}
                    touched={formik.touched.userLastName}
                    value={formik.values.userLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.inputWidthSetter}
                    disabled={JSON.stringify(employee) !== "{}" ? true : false}
                  />
                </div>

                <div className="mb-3 form-group required">
                  <Input
                    label="Mobile #"
                    name="userContactNo"
                    error={formik.errors.userContactNo}
                    touched={formik.touched.userContactNo}
                    value={formik.values.userContactNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+88</InputAdornment>
                      ),
                    }}
                    className={classes.inputWidthSetter}
                    disabled={JSON.stringify(employee) !== "{}" ? true : false}
                  />
                  <Checkbox
                    label={`Take Mobile # as username ${
                      checkedMobile ? "✔" : "?"
                    }`}
                    name="isUserIdMobile"
                    value={checkedMobile}
                    onChange={(e) => {
                      if (checkedMobile) {
                        console.log("Hello from if");

                        setCheckedMobile(false);
                        setCheckedEmail(true);
                      } else {
                        console.log("Hello from else");
                        setCheckedMobile(true);
                        setCheckedEmail(false);
                      }
                    }}
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        height: "25px !important",
                      },
                    }}
                  />
                  {checkedMobile && formik.errors.general ? (
                    <p
                      className="semi-strong-text"
                      style={{ color: "#A52A2A" }}
                    >
                      {formik.errors.general}
                    </p>
                  ) : null}
                </div>
                {formik.values.customer !== "" &&
                formik.values.customer.label === "Trade Zentrum" ? (
                  <div
                    className="mb-3 form-group required"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled={loadingRoleList ? true : false}
                      id="role"
                      isOptionEqualToValue={roleOpctionCheck}
                      onChange={roleChange}
                      value={formik.values.role}
                      options={roles !== undefined ? roles : []}
                      onOpen={formik.handleBlur}
                      renderInput={(params) => (
                        <Input
                          label="Role Name"
                          name="role"
                          error={
                            formik.errors.role !== undefined
                              ? formik.errors.role.id
                              : ""
                          }
                          touched={
                            formik.touched.role !== undefined
                              ? formik.touched.role
                              : ""
                          }
                          {...params}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      freeSolo={true}
                      className={classes.inputWidthSetterwithButtoAside}
                    />

                    <AddCircleRoundedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // console.log(selectUnassignedMenus);
                        // setOpenPopup(true);
                        setOpenPopup({
                          ...openPopup,
                          isOpen: true,
                        });

                        // dispatch(createAssignedMenu(selectUnassignedMenus));
                        // console.log(selectUnassignedMenus);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <p className="thin-text">
              By signing up, you agree with our privacy and usage terms.
            </p>
            <button className="w-100 btn btn-sm btn-primary" type="submit">
              Sign up
            </button>
          </form>
          <div
            className="paperdesign2"
            style={{
              display: loadingCustomer ? "" : "none",
            }}
          >
            <div>
              <Circles
                height="70"
                width="100"
                color="#F3EC19"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                // wrapperClass=""
                // style={{
                //   width: "100%",
                //   height: "100%",
                //   display: "flex !important",
                //   justifyContent: "center",
                //   alignItems: "center",
                // }}
                visible={true}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  color: "white",
                }}
              >
                Please wait
              </p>
            </div>
          </div>
        </main>
        <AddRole openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "../../core/controls/DropDown.css";

const EditProductDropdownFieldMultiple = (props) => {
  const [selected, setSelected] = useState([]);
  const {
    info,
    name,
    allItem,
    itemizeditemedit,
    item,
    placeholder,
    ...others
  } = props;
  console.log("props.info");
  console.log(props.info);
  const changeValues = () => {
    // console.log("props.info");
    // console.log(props.info);
    setSelected(props.info);
    // let value = {
    //   id: info.id === "" ? "" : info.id,
    //   label: info.label === "" ? "" : info.label,
    // };
    // if (value.id === "") {
    //   setSelected([]);
    // } else {
    //   setSelected([value]);
    // }
  };
  useEffect(() => {
    if (props?.info) {
      changeValues();
    }
  }, [info]);

  // console.log("props.allItem.index");
  // console.log(props.allItem.index);
  return (
    <Typeahead
      clearButton
      //   style={{ position: "none !important" }}
      className="rbt2"
      id="basic-example"
      name={name}
      label="Batch #"
      multiple
      // disabled={loadingProduct ? true : false}
      onChange={(selected) => {
        console.log("selected");
        console.log(selected);
        setSelected(selected);
        let value =
          selected.length > 0
            ? {
                index: allItem.index === undefined ? "" : allItem.index,
                productId: allItem.productId,
                name: name,
                selectedBatch: selected,
              }
            : "";
        if (selected.length <= 0) {
          value = {
            index: allItem.index === undefined ? "" : allItem.index,
            productId: allItem.productId,
            name: name,
            selectedBatch: [],
          };
        }
        console.log("value");
        console.log(value);
        // setProduct({
        //   ...product,
        //   id: value.id,
        //   label: value.label,
        // });
        itemizeditemedit(value);
      }}
      options={item !== undefined ? item : []}
      placeholder={placeholder}
      selected={selected}
      {...others}
    />
  );
};

export default EditProductDropdownFieldMultiple;

import React, { useState } from "react";
import { useFormik } from "formik";

import AddIcon from "@mui/icons-material/Add";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RoleSchema } from "../schemas";
import Input from "../core/controls/Input";
import { useDispatch } from "react-redux";

import Button from "../core/controls/Button";
import FormPopup from "../core/FormPopup";
import { Form } from "../core/useForm";
import { createRole } from "../redux/actions/RoleAndMenuActions";

const useStyles = makeStyles(() => ({
  pageContent2: {
    // margin: useTheme().spacing(5),
    padding: "0 20px 0 10px",
    // "& .MuiGrid-item": {
    //   minWidth: "480px !important",
    // },
  },
}));
const initialValues = {
  rolename: "",
};
const AddRole = (props) => {
  const { openPopup, setOpenPopup } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const formik = useFormik({
    initialValues,
    validationSchema: RoleSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      dispatch(createRole(values));
      action.resetForm();
      setOpenPopup({
        ...openPopup,
        isOpen: false,
      });
    },
  });
  console.log("openPopup");
  console.log(openPopup);
  return (
    <>
      <FormPopup
        title="Create a Role"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Paper className={classes.pageContent2}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Input
                  label="Add a role"
                  name="rolename"
                  error={formik.errors.rolename}
                  touched={formik.touched.rolename}
                  value={formik.values.rolename}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ width: "98%" }}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  text="Add a role"
                  size="small"
                  startIcon={<AddIcon />}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </FormPopup>
    </>
  );
};

export default AddRole;

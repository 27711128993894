import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MailIcon from "@mui/icons-material/Mail";
import { Form } from "react-bootstrap";
import "./View.css";
import ReactToPrint from "react-to-print";

const download = (id) => {
  window.scrollTo(0, 0);
  setTimeout(() => {
    const divToPrint = document.querySelector("#invoiceCapture");

    const pdfOptions = {
      margin: 10,
      filename: `invoice-${id}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };
    html2canvas(divToPrint, {
      ...pdfOptions.html2canvas,
      useCORS: true, // Enable this if you have cross-origin content
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 193;
      const pageHeight = 298;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("pt", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
      // Adding margin to the top of the second page
      const topMarginOnSecondPage = 20; // Adjust this value as needed
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();

        doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
      }

      // PAGE NUMBERING & General Info
      // Add Page number at bottom-right
      // Get the number of pages
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);

      // For each page, print the page number and the total pages
      for (var i = 1; i <= pageCount; i++) {
        // Go to page i
        doc.setPage(i);
        //Print Page 1 of 4 for example

        doc.text(
          "This is a computer-generated document. No signature is required.             " +
            "Page" +
            String(i) +
            " of " +
            String(pageCount),
          210 - 20,
          297 - 30,
          null,
          null,
          "right"
        );
      }

      doc.save(`chalaan-${id}.pdf`);
    });
  }, 1000);
};

function GenerateInvoice(soSystemNo) {
  html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    const imgData = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: [612, 792],
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`chalaan-${soSystemNo}.pdf`);
  });
}

function GenerateInvoice2() {
  // var pdf = new jsPDF('p','pt','a4');

  var pdf = new jsPDF("p", "pt", "a4");

  var imgData =
    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAXwBfAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgIyIoLTkwKCo2KyIjMkQyNjs9QEBAJjBGS0U+Sjk/QD3/2wBDAQsLCw8NDx0QEB09KSMpPT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT3/wgARCAAaABQDAREAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAABQYAAwQB/8QAGAEBAQEBAQAAAAAAAAAAAAAAAwEAAgT/2gAMAwEAAhADEAAAAXKbOK1c92KOHzuQcxaHNjdidpy5yl//xAAfEAACAQMFAQAAAAAAAAAAAAABAgADEhMEEBEhIjH/2gAIAQEAAQUC+QuVq6duEqnoephWKDia/FLjLjt//8QAHREAAgIBBQAAAAAAAAAAAAAAAAIBEQMSEyAiMf/aAAgBAwEBPwEhIZLj2DOttcCkNp7G8xZfH//EAB4RAAIDAAEFAAAAAAAAAAAAAAABAgMREiAhIjFR/9oACAECAQE/AR2ONmS9MolkcZZ8aHDl4b2FTEaEun//xAAhEAABAwMEAwAAAAAAAAAAAAABAAIRAxAxEjJBQiFhYv/aAAgBAQAGPwJQ7acIg8FQWFzfS0B0t+shcpkNqHx1KqahU29rZKybf//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQVFhgZH/2gAIAQEAAT8hUFrUE1U6+ZZvXITcrvpNdp4xEO+l1b7Gv7BQdYMALdXDkpwD7ipT+kOT/9oADAMBAAIAAwAAABBnmCSOz//EABsRAQACAwEBAAAAAAAAAAAAAAEAESExYSBx/9oACAEDAQE/EAXUQdz5KIsIMuNjTLWFPNMVwaOQoRsVXn//xAAcEQEAAgIDAQAAAAAAAAAAAAABABEhMSBhcVH/2gAIAQIBAT8QUMsIdQ9/JZNpSUTIImK3bZ5AbtfZa3cpbvj/AP/EABwQAQACAwEBAQAAAAAAAAAAAAEAESExQXFRwf/aAAgBAQABPxCsIatahd4Y+dDAb93fjD4HtO4qLlXU0ej2pdETsO11xEdV8cP2hExkSA2d3NHkA0Q0CIxSEyKmjyf/2Q==";
  pdf.addImage(imgData, "JPEG", 20, 20, 20, 26);

  pdf.text(50, 40, "Header");

  pdf.addHTML(document.body, 40, 100, function () {
    pdf.save("web.pdf");
  });
}

class SalesOrderChalaanModal extends React.Component {
  constructor(props) {
    super(props);
    this.pdfRef = React.createRef();
  }

  dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    //console.log("d");
    //console.log(d);
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  getOldPrice = (price, vat, ait, discount) => {
    var lineTotalVat = price * (vat / 100);
    var lineTotalAit = price * (ait / 100);
    console.log("price");
    console.log(price);
    console.log("lineTotal");
    console.log(lineTotalVat + lineTotalAit + discount);
    var final = price - (lineTotalVat + lineTotalAit + discount);
    return final;
  };

  getVatAmount = (price, vat) => {
    var lineTotalVat = price * (vat / 100);

    return lineTotalVat.toFixed(2);
  };

  getAitAmount = (price, ait) => {
    var lineTotalAit = price * (ait / 100);
    return lineTotalAit.toFixed(2);
  };

  getTotalPrice = (arr, vat, ait, discount, adjustment) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodFinalCalculatedUnitPrice;
    });
    sum =
      parseFloat(sum) +
      parseFloat(vat) +
      parseFloat(ait) -
      (parseFloat(discount) + parseFloat(adjustment));
    console.log("sum");
    console.log(sum);
    return sum.toFixed(2);
  };

  getTotalQuantity = (arr) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sopProductQty;
    });

    return parseFloat(sum).toFixed(2);
  };

  render() {
   // console.log("this.props.info");
   // console.log(this.props.info);
    return (
      <div>
        <Modal
          show={this.props.showModal}
          onHide={this.props.closeModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <div
            id="invoiceCapture"
            className=" pt-1 "
            style={{ position: "relative" }}
            ref={this.pdfRef}
          >
            <div className="d-flex flex-row justify-content-between align-items-center bg-light w-100 py-4 px-2 invoice-header">
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/trade.png"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "80px", height: "50px" }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="fw-bold my-2">TRADE ZENTRUM</h4>
                <h6 className="fw-bold text-secondary mb-1">
                <div style={{ textAlignVertical: "center",textAlign: "center",}}>
                  Reg. Office: House #21, Road #01, Block-C, Banasree, Rampura, Dhaka
                  </div>
                </h6>
                <h6 className="fw-bold text-secondary mb-1 d-flex align-items-center justify-content-between">
                  <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                    ☎️&nbsp;+8801792 527261
                    {/* <div>01670205047</div> */}
                  </div>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                    📩&nbsp;tradezentrumbd@gmail.com
                    {/* <div>tradezentrumbd@gmail.com</div> */}
                  </div>
                </h6>
              </div>
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "120px", height: "50px" }}
                />
              </div>
            </div>
            <div
              className="p-4"
              style={{
                // backgroundImage:
                //   "url(" + `${process.env.PUBLIC_URL}/images/chalaan.png` + ")",
                backgroundSize: "500px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "bolder",
                    //border: "1px solid #e4e4e4",
                    padding: "5px",
                  }}
                >
                  CHALLAN
                  <br />
                  <br />
                </span>
              </div>

              <Row style={{ marginBottom: "3rem", height: "100px" }}>
                <Col md={7} lg={8}>

            

                  <div>
                    {this.props.info?.supplierName ? (
                      <>Supplier Name:&nbsp; : {this.props.info.supplierName}</>
                    ) : (
                      <>Customer Name:&nbsp; : {this.props.info?.customerName || "Not Available"}</>
                    )}
                </div>
                <div>
                {this.props.info?.supplierAddress ? (
                      <>Supplier Address:&nbsp; : {this.props.info.supplierAddress}</>
                    ) : (
                      <>Customer Address:&nbsp; : {this.props.info?.customerAddress || "Not Available"}</>
                    )}
                </div>
                </Col>

                <Col md={5} lg={4}>
                  <div>
                    Challan&nbsp;Number:&nbsp; :{" "}
                    {this.props.info?.soSystemNo || "1222i3y"}
                  </div>
                  <div>
                    Date Of Issue:&nbsp;
                    {this.dateConverter(this.props.info.invoiceDate) || ""}
                  </div>
                 
                  <div>
                  Ref:&nbsp;
                    {
                      this.props.info.marketingName || ""
                    }
                  </div>


                  {this.props.info?.soCustomerReferance && (
                      <div>
                        Customer Reference:&nbsp;{this.props.info.soCustomerReferance}
                      </div>
                    )}
                 
                  {this.props.info?.jobType !== "Cash_Sales" &&
                  this.props.info?.jobType !== "Credit_Sales" ? (
                    <div>
                      Order Type:&nbsp;
                      {this.props.info?.jobType || ""}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <div
                style={{
                  width: "auto",
                  overflowX: "scroll",
                }}
                className="row-scrollers"
              >
                <Table className="mb-0" style={{ border: "1px solid #e3e3e3" }}>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Order Quantity(KG)</th>
                      <th className="text-center">Pack Size(KG)</th>
                      <th className="text-center">Pack Type</th>
                      <th className="text-center">No of Pack</th>
                      <th className="text-center">Product Quantity(KG)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.info.stockOutPackagingItems.map((item, i) => {
                      return (
                        <tr id={i} key={i} style={{ height: "20px" }}>
                            <td>
                        <var>
                          {item.productName}
                          {item.sodBatches.map(batch => (
                          <sub key={batch.batchId}>
                                        
                          {batch.batchNo !== null &&
                            batch.batchNo !== "" &&
                            ` (${batch.batchNo})`}{" "}
                          </sub>
                          ))}
                                        
                          </var>
                      </td>
                          <td style={{ width: "70px" }}>
                            {item.sodConfirmedQty}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            {item.sopPackSize}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            {item.packTypeName}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            {item.sopNoPack}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            {item.sopProductQty}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Table style={{ marginTop: "2rem", border: "1px solid #e3e3e3" }}>
                <tbody>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold">
                      Total Quantity (KG)
                    </td>
                    <td className="fw-bold" style={{ width: "100px" }}>
                      {this.getTotalQuantity(
                        this.props.info?.stockOutPackagingItems
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* <Table style={{ marginTop: "2rem", border: "1px solid #e3e3e3" }}>
                <tbody>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: "100px" }}>
                      SUBTOTAL
                    </td>
                    <td className="text-end" style={{ width: "100px" }}>
                      {this.props.currency}{" "}
                      {this.props.info.stockOutBillData.calculatedBill}
                    </td>
                  </tr>
                  {this.props.info.stockOutBillData.billVatPercantage != 0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        VAT
                      </td>
                      <td className="text-end" style={{ width: "150px" }}>
                        {this.props.info.stockOutBillData.billVatPercantage}
                        {"%"} {" ~ "} {this.props.currency}{" "}
                        {this.getVatAmount(
                          this.props.info.stockOutBillData.calculatedBill,
                          this.props.info.stockOutBillData.billVatPercantage
                        )}
                      </td>
                    </tr>
                  )}
                  {this.props.info.stockOutBillData.billAitPercantage != 0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        AIT
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {this.props.info.stockOutBillData.billAitPercantage}
                        {"%"} {" ~ "} {this.props.currency}{" "}
                        {this.getAitAmount(
                          this.props.info.stockOutBillData.calculatedBill,
                          this.props.info.stockOutBillData.billAitPercantage
                        )}
                      </td>
                    </tr>
                  )}
                  {this.props.info.stockOutBillData.billDiscountAmount != 0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        Discount
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {this.props.currency}{" "}
                        {parseFloat(
                          this.props.info.stockOutBillData.billDiscountAmount
                        ).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {this.props.info.stockOutBillData.billAdjustmentAmount !=
                    0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold">Adjustment</td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {this.props.currency}{" "}
                        {parseFloat(
                          this.props.info.stockOutBillData.billAdjustmentAmount
                        ).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: "100px" }}>
                      TOTAL
                    </td>
                    <td className="text-end" style={{ width: "100px" }}>
                      {this.props.currency}{" "}
                      {this.getTotalPrice(
                        this.props.info.stockOutItemsData,
                        this.getVatAmount(
                          this.props.info.stockOutBillData.calculatedBill,
                          this.props.info.stockOutBillData.billVatPercantage
                        ),
                        this.getAitAmount(
                          this.props.info.stockOutBillData.calculatedBill,
                          this.props.info.stockOutBillData.billAitPercantage
                        ),
                        this.props.info.stockOutBillData.billDiscountAmount,
                        this.props.info.stockOutBillData.billAdjustmentAmount
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table> */}
              {this.props.info.notes && (
                <div className="bg-light py-3 px-4 rounded">
                  {this.props.info.notes}
                </div>
              )}
            </div>

            <div className="p-4 ">
              <Row className="mb-4 ">
                <Col md={3} lg={3}>
                <div
                    className="fw-bold text-left w-55 h-55 p-1"
                    style={{ height: "140px" ,
        
                  }}
                  >
                    Received By:
                    <div  style={{ textAlignVertical: "left",textAlign: "left",fontSize:"14px"}}>
                    <br/>
                      <div style={{padding:"4px"}}>Signature:</div>
                      <div style={{padding:"4px"}}>Name: </div>
                      <div style={{padding:"4px"}}> Mobile Number :</div>
                                
                      </div>    
                  </div>
                  {/* <div>{this.props.info.billTo || "Hello Uttara"}</div>
                  <div>{this.props.info.billToAddress || ""}</div>
                  <div>{this.props.info.billToEmail || ""}</div> */}
                </Col>
              </Row>
            </div>
            <p className="m-2 p-2">
              {" "}
              <div style={{ fontStyle:"italic", textAlignVertical: "center",textAlign: "center",}}>
              Note: Please report any product quality or quantity issues within
              72 hours; otherwise, it will be treated as your acceptance in this
              regard.
              <br/>
              <br/>
              </div>
            </p>
            <footer>
            <div style={{ textAlignVertical: "center",textAlign: "center",}}>
                This is a computer-generated document. No signature is required.
                </div>
            </footer>
          </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={6} className="mb-2">
                <Button
                  variant="primary"
                  className="d-flex align-items-center justify-content-center w-100"
                  onClick={() => {
                    download(this.props.info.soSystemNo);
                  }}
                >
                  <BiPaperPlane
                    style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                    className="me-2 "
                  />
                  Download as Image
                </Button>
              </Col>
              <Col md={6} className="mb-2">
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="primary"
                      className="d-flex align-items-center justify-content-center w-100"
                    >
                      <BiPaperPlane
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "-3px",
                        }}
                        className="me-2"
                      />
                      Download as PDF
                    </Button>
                  )}
                  content={() => this.pdfRef.current}
                  //content={() => this.pdfRef.current}
                />
              </Col>
              <Col md={6}>
                {/* <Button
                  variant="outline-primary"
                  className="d-flex align-items-center justify-content-center w-100 mt-3 mt-md-0"
                  onClick={GenerateInvoice2}
                >
                  <BiCloudDownload
                    style={{ width: "16px", height: "16px", marginTop: "-3px" }}
                    className="me-2"
                  />
                  Download Copy
                </Button> */}
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}

export default SalesOrderChalaanModal;

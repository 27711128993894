import { API } from "../config";
import Axios from "axios";
import { API_URL } from "../redux/constants/apiConstants";

export const signup = async (user) => {

  // console.log(name, email, password);
  var config = {
    method: "post",

    url: `${API_URL}/User/Signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(user),
  };

  const { response } = await Axios(config);

};

export const getValues = async (token, id) => {
  // console.log("user");
  // console.log(user);
  // console.log(name, email, password);
  var config = {
    method: "get",

    url: `${API_URL}/StockOut/GetMappedItemsAndBatchesByProduct/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await Axios(config);

  return data;
};

export const signin = (user) => {
  // console.log(name, email, password);
  return fetch(`${API}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
     // console.log(err);
    });
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

export const isAuthenticated = () => {
  if (typeof window === "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

export const signout = (next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    next();
    return fetch(`${API}/signout`, {
      method: "GET",
    })
      .then((response) => {
        //console.log("signout", response);
      })
      .catch((err) => console.log(err));
  }
};

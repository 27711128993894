import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./View.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane } from "react-icons/bi";
import styled from "@emotion/styled";
import { useReactToPrint } from "react-to-print";
import Barcode from 'react-barcode';

export default function SampleStickerModal({ showModal, closeModal, item_info }) {
  const reportRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    
  });
 // const barcodeValue =item?.generatedBarcodeData;
  
  return (
    <div>
      <SalesOrderModal show={showModal} onHide={closeModal} size="lg" centered>
	      {/* ... Your existing modal content ... */}
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal heading</Modal.Title> */}
      </Modal.Header>
      <div id="invoiceCapture">
      <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              <Button
                variant="primary"
               
                className="d-flex align-items-center justify-content-center w-100"
                
                onClick={handlePrint}
              >
                <BiPaperPlane
                  style={{ width: "15px", height: "15px", marginTop: "30px",display:"flex",alignItems:"flex-end" }}
                  className="me-2"
                />
                Print This Out
              </Button>
            </Col>
            <Col md={6}></Col>
          </Row>
        </div>
        <div className="capture-portion" ref={reportRef}>
          {item_info?.map((item) => (
            <div
              className="sticker-info"
              key={item?.siInvoiceNo}
              style={{
            }}
              
            >
            <div className="sales-order-sticker-base">                
                <div className="company-info" style={{ display: "flex", 
                justifyContent: "space-between", width: "100%",
                paddingLeft:"15px",
                paddingRight:"10px",
                paddingTop:"15px" }}>
                  <div className="logo" style={{ alignSelf: "flex-start" }}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/trade.png"}
                      alt=""
                      width="18"
                      height="8"
                    />

                </div>
                <div className="product-company-logo" style={{ alignSelf: "flex-end",paddingRight:"10px" }}>
                    <div className="image">
                      <img
                        src={process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"}
                        alt=""
                        width="30"
                        height="9"
                      />
                    </div>
                  </div>
                  
                </div>
                {/* <div style={{ paddingTop: "4px",paddingLeft:"10px" }}>
                    <div className="company-info">
                      <p className="address" style={{ fontSize: "4px",fontWeight:"bold", margin: 0 }}>
                        Kunia, Targas, Gazipur
                      </p>
                      <p className="phone" style={{ fontSize: "4px",fontWeight:"bold", margin: 0 }}>
                        01792 527261, tradezentrumbd@gmail.com
                      </p>
                    </div>
                </div> */}

                  <div className="product-info" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="info-data">
                      <div className="table-data">
                        <div className="info-tr" style={{ textAlign: "center" }}>
                          <Barcode
                            value={item?.generatedBarcodeData}
                            format="CODE128" // Set the barcode format
                            width={1} // Set the width to 10% of the parent container
                            height={34} // Set the fixed height
                            fontSize={10} // Specify the font size of the text below the barcode
                            textMargin={2} // Specify the margin between the barcode and the text
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                
            </div>
          </div>
            ))}
        </div>
      </div>
	    
      </SalesOrderModal>
      <hr className="mt-4 mb-3" />
    </div>
  );
}

// Your existing styled component...

const SalesOrderModal = styled(Modal)`
  #invoiceCapture {
  }

  // .sticker-info {
  //   display: flex;
  //   width: 402px;
  //   max-height: 218px;
  //   height: 218px;
  //   max-width: 402px;
  //   border: 1px solid #000;
  //   padding: 10px;
  //   flex-direction: column;
  //   row-gap: 4px;
  // }
  .sticker-info {
    display: flex;
    width: 1.98in; /* Adjusted for 0.01-inch margin */
    height: 0.98in; /* Adjusted for 0.01-inch margin */
    max-width: 2in;
    max-height: 1in;
    border: 1px solid #000;
    padding: 0.01in; /* Added margin */
    flex-direction: column;
    row-gap: 4px;
  }
  .product-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .capture-portion {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    row-gap: 4;
    flex-direction: column;
    align-items: center;
  }

  .sales-order-sticker-base {
    display: flex;
    column-gap: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__left-portion {
    width: 180px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info
    p {
    margin: 0;
    font-size: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__right-portion {
    width: calc(100% - 150px);
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info {
    margin-left: -90px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table {
    width: 100%;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr {
    display: flex;
    column-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr
    .info-td {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table
    tr
    td {
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag
    .invoice-number {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo {
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .date {
    font-size: 8px;
    margin: 0;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .image {
    display: flex;
    align-items: center;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .invoice-number {
    margin: 0;
    font-size: 8px;
  }

  .date-and-company-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .date-and-company-name p {
    margin: 0;
    font-size: 12px;
  }

  .date-and-company-name .date {
    font-size: 6px !important;
    margin-top: 15px;
  }
`;

import React, { useEffect } from "react";
import Decoder from "jwt-decode";
import parse from "html-react-parser";
import "bootstrap/dist/css/bootstrap.min.css";
// import { BrowserRouter, Switch, Route } from "react-router-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { createSalesOrderInternal } from "./redux/actions/invoiceActions";
import Signin from "./user/Signin";
import NotFound from "./pages/NotFound";

import { useSelector } from "react-redux";
import { routes } from "./routes";
import NotAuthorized from "./pages/NotAuthorized";
//import Dashboard from "./pages/Dashboard";
import Dashboard from "./pages/Dashboard/Dashboard";
import EditStockin from "./pages/stockin/EditStockin";
import CreateStockIn from "./pages/stockin/CreateStockIn";
import CreateIdea from "./pages/Invoices/CreateIdea";
import IncompleteTask from "./pages/Tasks/IncompleteTask";
import ChangePassword from "./user/ChangePassword";

import StockoutStatus from "./pages/Invoices/StockoutStatus";
import UnAssignedTask from "./pages/Tasks/UnAssignedTask";
import AddTask from "./pages/Tasks/AddTask";
import { createTask } from "./redux/actions/taskActions";
import UpdateStockoutStatus from "./pages/Invoices/UpdateStockoutStatus";
import AddTaskInvoiceFree from "./pages/Tasks/AddTaskInvoiceFree";

//Rakib

import SalesReport from "./pages/Reports/SalesReport";
import DeliveryReport from "./pages/Reports/DeliveryReport";
import ProductStockOutReport from "./pages/Reports/SalesReportByProduct"
import ProductStockInReport from "./pages/Reports/ProductStockIn";
import PaymentReport from "./pages/Reports/PaymentReport";
import MappingReport from "./pages/Reports/MappingReport";
import DeliveryConveyanceReport from "./pages/Reports/DeliveryConveyanceReport";

import DefinePending from "./pages/Invoices/DefinePending";
import AssignedTask from "./pages/Tasks/AssignedTask";
import IncompleteChecklistTask from "./pages/Tasks/IncompleteChecklistTask";
import EditTaskPendingChecklist from "./pages/Tasks/EditTaskPendingChecklist";
import CurrentStockReport from "./pages/Reports/CurrentStockReport";

//Admin Edit
//import SalesOrderInternalGeneralEdit from "./pages/AdminEdit/SalesOrderInternalGeneralEdit";
//import EscalateSalesOrderInternalWithPriceEdit from "./pages/AdminEdit/SalesInvoiceAdminEdit/EscalateSalesOrderInternalWithPriceEdit";
//import EditSalesOrderInternalAdmin from "./pages/AdminEdit/SalesOrderAdminEdit/EditSalesOrderInternalAdmin";
//import EditSalesOrderPacking from "./pages/AdminEdit/SalesPackingEdit/EditSalesOrderPacking";
//import SalesPendingPrice from "./pages/AdminEdit/SalesPendingPriceAdminGrid/SalesPendingPrice";


function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // console.log(userInfo.token);
  let decodeMenuInPlainListTokenData =
    userInfo !== null && userInfo !== undefined
      ? Decoder(userInfo.menuInPlainListToken)
      : null;

  decodeMenuInPlainListTokenData =
    decodeMenuInPlainListTokenData !== null
      ? JSON.parse(decodeMenuInPlainListTokenData.UserMenuPlainList)
      : null;
  console.log("decodeMenuInPlainListTokenData");
  // console.log(decodeMenuInPlainListTokenData);

  // useEffect(() => {
  //   document.body.style.zoom = "80%";
  // }, []);
  useEffect(() => {
    document.body.style.zoom = "95%";
  }, []);

  let filteredMenuItem = [];

  const check = () => {
    let c = decodeMenuInPlainListTokenData.map((single) => {
      if (
        routes.some(
          (item) => String(item.component) === String(single.MenuRelatedRoute)
        )
      ) {
        let filteredMenuItem = routes.filter((single2) => {
          return String(single2.component) === String(single.MenuRelatedRoute);
        });
        console.log("filteredMenuItem");
        console.log(filteredMenuItem);
      }
    });

    console.log("c");
    console.log(c);
  };

  function toPascalCase(string) {
    return `${string}`
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, "g"), " ")
      .replace(new RegExp(/[^\w\s]/, "g"), "")
      .replace(
        new RegExp(/\s+(.)(\w*)/, "g"),
        ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  }

  function toPascalCase2(str) {
    if (/^[a-z\d]+$/i.test(str)) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str
      .replace(
        /([a-z\d])([a-z\d]*)/gi,
        (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
      )
      .replace(/[^a-z\d]/gi, "");
  }

  useEffect(() => {}, [decodeMenuInPlainListTokenData]);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signin" component={Signin} exact />
        <Route path="/" component={Dashboard} exact /> //done
        <Route path="/dashboard" component={Dashboard} exact /> //done
        {/* <Route path="/stockin/create" component={CreateStockIn} exact /> // */}
        {/* <Route path="/task/incompletelist" component={IncompleteTask} exact />{" "} */}
        <Route path="/changepassword" component={ChangePassword} exact />
        //
        {/* <Route path="/stockin/:id/edit" component={EditStockin} exact /> // done */}
        {/* <Route path="/invoice/create" component={CreateInvoice} exact /> */}
        <Route path="/idea/create" component={CreateIdea} exact />
        <Route path="/stockout/status" component={StockoutStatus} exact />
        <Route path="/task/assign" component={UnAssignedTask} exact />
        <Route path="/task/:id/create" component={AddTask} exact />
        {/*<Route path="/stockout/update" component={UpdateStockoutStatus} exact />*/}
        <Route path="/stockout/update" component={DefinePending} exact />

  
        <Route
          path="/task/create/invoicefree"
          component={AddTaskInvoiceFree}
          exact
        />
        <Route path="/task/assignedlist" component={AssignedTask} exact />
        <Route
          path="/task/incompletechecklist"
          component={IncompleteChecklistTask}
          exact
        />
        {decodeMenuInPlainListTokenData !== null &&
          decodeMenuInPlainListTokenData.map((single) => {
            {
              // console.log(decodeMenuInPlainListTokenData);
              /* console.log("single");
            console.log(single);

            console.log("singletrue"); */
            }
            let filteredMenuItem = routes.filter((single2) => {
              return String(single2.path) === String(single.MenuURL);
            });
            // {
            //   // console.log(filteredMenuItem);
            // }
            if (filteredMenuItem.length > 0) {
              const index = routes.findIndex((object) => {
                return String(object.path) === String(single.MenuURL);
              });
              {
                /* console.log("index");
              console.log(index);
              console.log("routes[index]");
              console.log(routes[parseInt(index)]); */
              }
              return (
                <Route
                  path={routes[parseInt(index)].path}
                  component={routes[parseInt(index)].component}
                  exact
                />
              );
              console.log("filteredMenuItem");
              console.log(filteredMenuItem);
            }
          })}




        <Route path={"/report/sales"} component={SalesReport} exact />
        <Route path={"/report/delivery"} component={DeliveryReport} exact />
        <Route path={"/report/productout"} component={ProductStockOutReport} exact />
        <Route path={"/report/productin"} component={ProductStockInReport} exact />
        <Route path={"/report/payment"} component={PaymentReport} exact />
        <Route path={"/report/mapped"} component={MappingReport} exact />
        <Route
          path={"/report/delivery-conveyance"}
          component={DeliveryConveyanceReport}
          exact
        />
        <Route
          path={"/task/:id/editpendingchecklist"}
          component={EditTaskPendingChecklist}
          exact
        />
        <Route
          path={"/report/currentstock"}
          component={CurrentStockReport}
          exact
        />
        {/* {routes.map((single, i) => {
          return (
            <Route
              key={i}
              path={single.path}
              component={single.component}
              exact
            />
          );
        })} */}
        {/* {check} */}
        {/* {s.map((single, i) => {
          console.log("single.MenuRelatedRoute");
          console.log(single);
          let s = single.MenuRelatedRoute;
          console.log("s");
          console.log(s);
          console.log(typeof s);

          return (
            <Route
              key={i}
              path={single.url}
              component={toPascalCase2(single.component)}
              exact
            />
          );
        })} */}
        {/* {decodeMenuInPlainListTokenData.map((single) => {
          console.log("single.MenuRelatedRoute");
          console.log(single);
          let s = `<${single.MenuRelatedRoute} />`;
          console.log("s");
          console.log(s);
          let x = single.MenuRelatedRoute.replace(/['"]+/g, "");
          return <Route path={single.MenuURL} component={x} exact />;
        })} */}
        {/* <Route path="/signup" component={Signup} exact /> // done
        <Route path="/roles/users" component={UserRoles} exact /> // done
        <Route path="/" component={Dashboard} exact /> //done
        <Route path="/dashboard" component={Dashboard} exact /> //done
        <Route
          path="/inhouseemployees"
          component={InhouseEmployeesdupli}
          exact
        />{" "}
        // done
        <Route
          path="/addemployee"
          component={AddIhouseEmployeedupli}
          exact
        />{" "}
        // done
        <Route path="/employee/:id/edit" component={EditEmployee} exact /> //
        done
        <Route path="/products" component={Products} exact /> // done
        <Route
          path="/products/sample/update"
          component={UpdateProductSample}
          exact
        />{" "}
        // done
        <Route path="/addproduct" component={AddProduct} exact /> // done
        <Route path="/product/:id/edit" component={EditProduct} exact /> // done
        <Route path="/customers" component={Customers} exact /> // done
        <Route path="/addcustomer" component={AddCustomer} exact /> // done
        <Route path="/customer/:id/edit" component={EditCustomer} exact /> //
        done
        <Route path="/customers/map" component={CustomerMap} exact /> // done
        <Route path="/addsellprice" component={AddPrice} exact /> // done
        <Route path="/price" component={Price} exact /> // done
        <Route path="/price/:id/edit" component={EditPrice} exact /> // done
        <Route
          path="/manageroleandmenu"
          component={ManageRoleAndMenu}
          exact
        />{" "}
        // done
        <Route path="/changepassword" component={ChangePassword} exact />
        <Route
          path="/marketingactivities"
          component={MarketingActivities}
          exact
        />{" "}
        //done
        <Route
          path="/addmarketingactivity"
          component={AddMarketingActivity}
          exact
        />{" "}
        //done
        <Route
          path="/marketingactivity/:id/edit"
          component={EditMarketingActivity}
          exact
        />{" "}
        //done
        <Route
          path="/marketing/report/map"
          component={MarketingReportMap}
          exact
        />{" "}
        // done
        <Route path="/mappingproduct" component={MapProduct} exact /> // done
        <Route path="/geolocation" component={GeoLocation} exact /> // done
        <Route path="/invoice/create" component={CreateInvoice} exact />
        {/* <Route path="/idea/create" component={CreateIdea} exact /> */}
        {/* <Route
          path="/salesorderinternal"
          component={SalesOrderInternal}
          exact
        />{" "}
        // done
        <Route
          path="/salesorderinternal/create"
          component={CreateSalesOrderInternal}
          exact
        />{" "}
        // done
        <Route path="/marketing/report" component={MarketingReport} exact /> //
        done
        <Route path="/tallysales/add" component={AddTallySales} exact /> // done
        <Route
          path="/salesorderinternal/:id/edit"
          component={EditSalesOrderInternal}
          exact
        />{" "}
        // done
        <Route
          path="/salesorderinternal/:id/edit2"
          component={EditDemo}
          exact
        />
        <Route
          path="/salesorderinternal/:id/action"
          component={EscalateSalesOrderInternal}
          exact
        />{" "}
        // done
        <Route
          path="/salesorderinternal/:id/package"
          component={PackagingSalesOrderInternal}
          exact
        />{" "}
        // done
        <Route
          path="/salesorderinternal/escalationlist"
          component={EscalatedSalesOrderInternals}
          exact
        />{" "}
        // done
        <Route
          path="/salesorderinternal/packagelist"
          component={PackagedSalesOrderInternal}
          exact
        />{" "}
        // done
        <Route
          path="/salesorderinternal/packagelist/done"
          component={PackageDoneSalesOrderInternal}
          exact
        />{" "}
        // done
        <Route
          path="/packagelist/done/:id/edit"
          component={EditPackagedSalesOrderInternal}
          exact
        />{" "}
        // done
        <Route path="/suppliers/add" component={AddSupplier} exact /> // done
        <Route path="/supplier/:id/edit" component={EditSupplier} exact /> //
        done
        <Route path="/suppliers" component={Suppliers} exact /> // done
        <Route
          path="/assignemployeetocustomers"
          component={AssignCustomersToEmployee}
          exact
        />{" "}
        // done
        <Route path="/stockin/create" component={CreateStockInForm} exact /> //
        done
        <Route path="/stockin/list" component={Stockin} exact /> // done
        <Route
          path="/stockin/unapprovedqty/list"
          component={StockinForConfirmation}
          exact
        />{" "}
        // done
        <Route path="/stockin/:id/edit" component={EditStockin} exact /> // done
        <Route
          path="/stockin/:id/confirmQty"
          component={UpdateStockinQuantity}
          exact
        />{" "}
        // done
        <Route path="/purchaseprices" component={PurchasePrice} exact /> // done
        <Route
          path="/purchaseprice/add"
          component={AddPurchasePrice}
          exact
        />{" "}
        // done
        <Route
          path="/purchaseprice/:id/edit"
          component={EditPurchasePrice}
          exact
        />{" "}
        // done
        <Route path="/batches" component={Batches} exact /> // done
        <Route path="/quotation/list" component={Quotations} exact /> // done
        <Route
          path="/quotation/unapprovedlist"
          component={QuotationUnapprovedList}
          exact
        />{" "}
        // done
        <Route
          path="/quotation/approvedlist"
          component={QuotationApprovedList}
          exact
        />{" "}
        // done
        <Route path="/quotation/create" component={CreateQuotation} exact /> //
        done
        <Route path="/quotation/:id/edit" component={EditQuotation} exact /> //
        done
        <Route
          path="/quotation/:id/approve"
          component={ApproveQuotation}
          exact
        />{" "}
        // done
        <Route path="/cheque/list" component={Cheques} exact /> // done
        <Route path="/cheque/create" component={AddCheque} exact /> // done
        <Route path="/cheque/:id/edit" component={EditCheque} exact /> // done
        <Route path="/payment/list" component={Payments} exact /> // done
        <Route path="/payment/create" component={AddPayment} exact /> // done
        <Route path="/payment/:id/edit" component={EditPayment} exact /> // done
        <Route path="/task/create" component={AddTask} exact /> // done
        <Route path="/task/:id/edit" component={EditTask} exact /> // done
        <Route path="/delivery/create" component={AddDelivery} exact /> // done */}
        <Route component={NotAuthorized} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

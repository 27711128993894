import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Button as MuiButton,
} from "@mui/material";

import { Box, Typography, IconButton, Tooltip } from "@mui/material";

import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useTable from "../../core/useTable";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import InventoryIcon from "@mui/icons-material/Inventory";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RedoIcon from "@mui/icons-material/Redo";

import { Link, withRouter } from "react-router-dom";
import ActionButton from "../../core/controls/ActionButton";
import {
  deleteEmployee,
  listEmployees,
} from "../../redux/actions/employeeActions";
import { emprecords } from "../../DummyData";
import Actions from "../../core/Actions";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";

import {
  EMPLOYEE_CREATE_RESET,
  EMPLOYEE_DELETE_RESET,
  EMPLOYEE_UPDATE_RESET,
} from "../../redux/constants/employeeConstants";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "../../core/controls/Button";
import {
  escalateSalesOrderInternal,
  listSalesOrderInternal,
  listSalesOrderInternalDetails,
} from "../../redux/actions/invoiceActions";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import { Form } from "react-bootstrap";
import {
  SALESORDERINTERNAL_CREATE_REQUEST,
  SALESORDERINTERNAL_CREATE_RESET,
  SALESORDERINTERNAL_ESCALATION_RESET,
  SALESORDERINTERNAL_PACKAGE_RESET,
  SALESORDERINTERNAL_UPDATE_REQUEST,
  SALESORDERINTERNAL_UPDATE_RESET,
} from "../../redux/constants/invoiceConstants";
import SalesOrderInternalModal from "../../core/SalesOrderInternalModal";
import SalesOrderView from "../../core/SalesOrderView";
import KarjadeshView from "../../core/KarjadeshView";
import Example from "../../core/KarjadeshView";
import {
  listBatchDetails,
  listBatches,
} from "../../redux/actions/BatchActions";
import AddInhouseEmployee from "../Employees/AddInhouseEmployee";
import FormPopup from "../../core/FormPopup";
import EditBatch from "./EditBatch";
import { BATCH_UPDATE_RESET } from "../../redux/constants/BatchConstants";

const useStyles = makeStyles(() => ({
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#6C4AB6",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "350px !important",
      maxWidth: "350px !important",
    },

    // "& .MuiDataGrid-columnHeaderTitle": {
    //   minWidth: "140px !important",
    //   maxWidth: "140px !important",
    //   textAlign: "center",
    // },

    "& .MuiDataGrid-cell": {
      minWidth: "350px !important",
      maxWidth: "350px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#6C4AB6",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='batchId'], .MuiDataGrid-cell[data-field='batchId']":
      {
        // minWidth: "60px !important",
        // maxWidth: "60px !important",
        display: "none",
      },
    "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3']":
      {
        minWidth: "100px !important",
        maxWidth: "100px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#6C4AB6",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  // grid: {
  //   flexDirection: "column-reverse",
  // },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    "& div:nth-child(1)": {
      margin: "15px",
    },
    [useTheme().breakpoints.up("md")]: {
      display: "flex !important",
      justifyContent: "space-between !important",
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
      "& div:nth-child(1)": {
        margin: "0px",
      },
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },

  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  actions2: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "right",

      width: "420px",
    },
  },
}));

const Batches = ({ history }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  // console.log("classes");
  // console.log(classes);

  const batchList = useSelector((state) => state.batchList);
  const { loading, error, batches } = batchList;

  console.log("batches");
  console.log(batches);

  const salesOrderInternalPackage = useSelector(
    (state) => state.salesOrderInternalPackage
  );
  const {
    loading: loadingPackage,
    error: errorPackage,
    success: successPackage,
    packagedSalesOrder,
  } = salesOrderInternalPackage;

  // console.log("employees");
  // console.log(employees);

  const employeeDelete = useSelector((state) => state.employeeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = employeeDelete;

  const batchUpdate = useSelector((state) => state.batchUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = batchUpdate;

  const batchDetails = useSelector((state) => state.batchDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    batchSingle,
  } = batchDetails;

  const salesOrderInternalEscalate = useSelector(
    (state) => state.salesOrderInternalEscalate
  );
  const {
    loading: loadingEscalate,
    error: errorEscalate,
    success: successEscalate,
    escalatedSalesOrder,
  } = salesOrderInternalEscalate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,

    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whatOpen, setWhatOpen] = useState(-1);
  const [isOpenKarjadesh, setIsOpenKarjadesh] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("1");
  const [dataForEscalation, setDataForEscalation] = useState([]);
  const selectedOrderRef = useRef(null);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listBatches(selectedOrder));
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: BATCH_UPDATE_RESET });
      }
      if (successPackage) {
        setNotify({
          isOpen: true,
          message: "Saved Successfully",
          type: "success",
        });

        dispatch({ type: SALESORDERINTERNAL_PACKAGE_RESET });
      }

      if (successEscalate) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Escalated Successfully",
          type: "error",
        });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setDataForEscalation([]);

        dispatch({ type: SALESORDERINTERNAL_ESCALATION_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/batches" } },
      };
      history.push(location);
    }

    // console.log(successUpdate);
  }, [
    dispatch,
    classes,
    history,
    userInfo,
    successDelete,
    successUpdate,
    selectedOrder,
    successEscalate,
  ]);

  const handleEvent = (params) => {
    // history.push(`/employee/${params.row.employeeId}/edit`);
    // console.log(params.row);
  };

  // console.log("batchSingle");
  // console.log(batchSingle);

  const buttonsBasedOnSalesOrderType = () => {
    return (
      <Box className={classes.actions2}>
        <Button
          startIcon={<EditOutlinedIcon fontSize="small" />}
          text="Edit"
          color="secondary"
          onClick={() => {
            if (flag === true || rowId === null) {
              setOpenPopup({
                ...openPopup,
                title: "SELECTION ISSUE!!!",
                subTitle: "Minimum and Maximum one row have to be slected!",
                isOpen: true,
              });
            }
            if (rowClicked === true) {
              dispatch(listBatchDetails(rowId)).then(() => {
                setOpenPopup2({
                  ...openPopup2,
                  isOpen: true,
                });
              });
            }
          }}
        />
      </Box>
    );
  };

  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={batches}
          columns={columns_part_a}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.batchId + "-" + generateRandom()}
          onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
    // } else if (selectedOrder === "1") {
    // } else if (selectedOrder === "2") {
    // }
  };

  const onRowsSelectionHandler = (ids) => {
    // if (selectedOrder === "0") {
    //   if (ids.length > 1) {
    //     // console.log("ids");
    //     // console.log(ids);

    //     setRowClicked(false);
    //     setFlag(true);
    //   } else if (ids.length < 1) {
    //     setRowClicked(false);
    //     setFlag(true);
    //   } else {
    //     ids = ids[0].split("-");
    //     var temp = ids[0];
    //     ids = [];
    //     ids.push(temp);
    //     const arrOfNum = ids.map((str) => {
    //       return Number(str);
    //     });
    //     ids = arrOfNum;
    //     // console.log("ids");
    //     // console.log(ids);
    //     const selectedRowsData = ids.map((id) =>
    //       salesorderinternal.find((row) => row.soSystemId === id)
    //     );
    //     // console.log("selectedRowsData");
    //     // console.log(selectedRowsData);
    //     setRowId(selectedRowsData[0].soSystemId);
    //     setRowClicked(!rowClicked);
    //     setFlag(false);
    //   }
    // } else {
    if (ids.length > 1) {
      if (selectedOrder === "0") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        // console.log("newids");
        // console.log(newids);

        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        // console.log("ids");
        // console.log(ids);
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      // console.log("ids");
      // console.log(ids);
      const selectedRowsData = ids.map((id) =>
        batches.find((row) => row.batchId === id)
      );
      // console.log("selectedRowsData");
      // console.log(selectedRowsData);
      setDataForEscalation([selectedRowsData[0].batchId]);
      setRowId(selectedRowsData[0].batchId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteEmployee(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };

  const openModal = (id) => {
    setWhatOpen(1);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpen(true);
    });
    //
  };
  const closeModal = (event) => {
    setWhatOpen(-1);
    setIsOpen(false);
  };

  const openModalKarjadesh = (id) => {
    setWhatOpen(2);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpenKarjadesh(true);
    });
    //
  };
  const closeModalKarjadesh = (event) => {
    setWhatOpen(-1);
    setIsOpenKarjadesh(false);
  };

  // console.log("salesorderinternal");
  // console.log(salesorderinternal);

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const columns_part_a = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              dispatch(listBatchDetails(row.batchId)).then(() => {
                setOpenPopup2({
                  ...openPopup2,
                  isOpen: true,
                });
              });
            }}
          />
        );
      },
    },
    {
      field: "batchNo",
      headerName: "Batch No",
      headerAlign: "center",
      width: "50",
      align: "center",
    },
    {
      field: "productName",
      headerName: "Product Name",
      width:"60",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "batchProductionDate",
      headerName: "Production Date",
      valueGetter: (params) => dateConverter(params.row.batchProductionDate),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "batchExpiryDate",
      headerName: "Expiry Date",
      valueGetter: (params) => dateConverter(params.row.batchExpiryDate),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "batchId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Batch Control"
        subtitle="See the Batch List"
      />
      {loadingDelete && <>loading..</>}
      {error ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#6C4AB6" />
          {/* <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#6C4AB6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <div>
              <Form.Select
                aria-label="Default select example"
                value={selectedOrder}
                onChange={(e) => {
                  setSelectedOrder(e.target.value);
                  setRowId(null);
                  setRowClicked(false);
                }}
              >
                {/* <option value="0">Created Sales Orders</option> */}
                <option value="1">Active Batches</option>
                <option value="2">Inactive Batches</option>
                <option value="3">Paused Batches</option>
              </Form.Select>
            </div>
            {buttonsBasedOnSalesOrderType()}
          </Toolbar>
          {gridBasedOnSalesOrderType()}
          {/* </Box>
          </Box> */}
          {/* </Paper> */}
          {/* <TblPagination /> */}

          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          {JSON.stringify(batchSingle) !== "{}" &&
            batchSingle !== undefined && (
              <FormPopup
                title="Update Batch Info"
                openPopup={openPopup2}
                setOpenPopup={setOpenPopup2}
              >
                {/* <AddInhouseEmployee /> */}

                <EditBatch
                  openPopup={openPopup2}
                  setOpenPopup={setOpenPopup2}
                  data={batchSingle}
                />
              </FormPopup>
            )}
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(Batches);

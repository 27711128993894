export const CHEQUE_STATUS_LIST_REQUEST = "CHEQUE_STATUS_LIST_REQUEST";
export const CHEQUE_STATUS_LIST_SUCCESS = "CHEQUE_STATUS_LIST_SUCCESS";
export const CHEQUE_STATUS_LIST_FAIL = "CHEQUE_STATUS_LIST_FAIL";

export const BANK_LIST_REQUEST = "BANK_LIST_REQUEST";
export const BANK_LIST_SUCCESS = "BANK_LIST_SUCCESS";
export const BANK_LIST_FAIL = "BANK_LIST_FAIL";

export const ISSUER_BANK_LIST_REQUEST = "ISSUER_BANK_LIST_REQUEST";
export const ISSUER_BANK_LIST_SUCCESS = "ISSUER_BANK_LIST_SUCCESS";
export const ISSUER_BANK_LIST_FAIL = "ISSUER_BANK_LIST_FAIL";

export const CHEQUE_TYPE_LIST_REQUEST = "CHEQUE_TYPE_LIST_REQUEST";
export const CHEQUE_TYPE_LIST_SUCCESS = "CHEQUE_TYPE_LIST_SUCCESS";
export const CHEQUE_TYPE_LIST_FAIL = "CHEQUE_TYPE_LIST_FAIL";

export const CHEQUE_LIST_REQUEST = "CHEQUE_LIST_REQUEST";
export const CHEQUE_LIST_SUCCESS = "CHEQUE_LIST_SUCCESS";
export const CHEQUE_LIST_FAIL = "CHEQUE_LIST_FAIL";

export const CHEQUE_CREATE_REQUEST = "CHEQUE_CREATE_REQUEST";
export const CHEQUE_CREATE_SUCCESS = "CHEQUE_CREATE_SUCCESS";
export const CHEQUE_CREATE_FAIL = "CHEQUE_CREATE_FAIL";
export const CHEQUE_CREATE_RESET = "CHEQUE_CREATE_RESET";

export const CHEQUE_UPDATE_REQUEST = "CHEQUE_UPDATE_REQUEST";
export const CHEQUE_UPDATE_SUCCESS = "CHEQUE_UPDATE_SUCCESS";
export const CHEQUE_UPDATE_FAIL = "CHEQUE_UPDATE_FAIL";
export const CHEQUE_UPDATE_RESET = "CHEQUE_UPDATE_RESET";

export const CHEQUE_DETAILS_REQUEST = "CHEQUE_DETAILS_REQUEST";
export const CHEQUE_DETAILS_SUCCESS = "CHEQUE_DETAILS_SUCCESS";
export const CHEQUE_DETAILS_FAIL = "CHEQUE_DETAILS_FAIL";
export const CHEQUE_DETAILS_RESET = "CHEQUE_DETAILS_RESET";

export const CHEQUE_DELETE_REQUEST = "CHEQUE_DELETE_REQUEST";
export const CHEQUE_DELETE_SUCCESS = "CHEQUE_DELETE_SUCCESS";
export const CHEQUE_DELETE_FAIL = "CHEQUE_DELETE_FAIL";
export const CHEQUE_DELETE_RESET = "CHEQUE_DELETE_RESET";

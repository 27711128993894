import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateBatch } from "../../redux/actions/BatchActions";
import { useDispatch } from "react-redux";
import {
  listLocation,
  listVehicle,
  updateTransportation,
} from "../../redux/actions/taskActions";
import { StringSchema } from "yup";

const statusItems = [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
  { id: "3", title: "Pause" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  taskId: "",
  transportationId: "",
  vehicle: {
    id: "",
    label: "",
  },
  location: {
    id: "",
    label: "",
  },
  endLocation: {
    id: "",
    label: "",
  },
  fare: "",
  remarks: "",
};

const EditTransport = ({
  data,
  setData,
  openPopup,
  setOpenPopup,
  taskId,
  transportationId,
  handleBatchDel,
  history,
}) => {
  const dispatch = useDispatch();

  const vehicleList = useSelector((state) => state.vehicleList);
  const {
    loading: loadingVehicle,
    error: errorVehicle,
    vehicles,
  } = vehicleList;

  const locationList = useSelector((state) => state.locationList);
  const {
    loading: loadingLocation,
    error: errorLocation,
    locations,
  } = locationList;

  const transportUpdate = useSelector((state) => state.transportUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = transportUpdate;

  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      navigator.geolocation.getCurrentPosition(function (position) {
        dispatch(
          updateTransportation(
            {
              taskTransportationAutoId: values.transportationId,
              taskTransportationVehicleId: values.vehicle.id,
              taskTransportationCost: values.fare,
              taskTransportationStartLat: position.coords.latitude,
              taskTransportationStartLong: position.coords.longitude,
              taskTransportationEndLat: null,
              taskTransportationEndLong: null,
              taskTransportationStartLocation:values.location.label,
              taskTransportationEndLocation:values.endLocation.label,
              taskTransportationRemarks: values.remarks,
            },
            values.taskId
          )
        ).then((res) => {
          const filteredTask = data.items.filter((singleData) => {
            return String(singleData.taskId) === String(values.taskId);
          });
          let taskTransportationData = filteredTask[0].taskTransportationData;
          let updatedTaskTransportationData = {
            taskId: values.taskId,
            taskTransportationAutoId: values.transportationId,
            taskTransportationVehicleId: values.vehicle.id,
            taskTransportationCost: values.fare,
            taskTransportationStartLat: position.coords.latitude,
            taskTransportationStartLong: position.coords.longitude,
            taskTransportationEndLat: null,
            taskTransportationEndLong: null,
            taskTransportationStartLocation: values.location.label,
            taskTransportationEndLocation: values.endLocation.label,
            taskTransportationRemarks: values.remarks,
          };

          var newTaskTransportationData = taskTransportationData.map(function (
            sitems,
            index
          ) {
            // console.log("sitems");
            // console.log(sitems);
            for (var key in sitems) {
              if (sitems.taskTransportationAutoId === values.transportationId) {
                // console.log("Hello");
                sitems["taskTransportationAutoId"] =
                  updatedTaskTransportationData.taskTransportationAutoId;
                sitems["taskTransportationVehicleId"] =
                  updatedTaskTransportationData.taskTransportationVehicleId;
                sitems["vehicleName"] = values.vehicle.label;
                sitems["taskTransportationStartLocationId"] =
                  values.location.id;
                sitems["taskTransportationStartLocation"] =
                  updatedTaskTransportationData.taskTransportationStartLocation;
            
                sitems["taskTransportationEndLocationId"] =
                  values.endLocation.id;
                sitems["taskTransportationEndLocation"] =
                  updatedTaskTransportationData.taskTransportationEndLocation;

                sitems["taskTransportationStartLat"] =
                  updatedTaskTransportationData.taskTransportationStartLat;
                sitems["taskTransportationStartLong"] =
                  updatedTaskTransportationData.taskTransportationStartLong;
                sitems["taskTransportationEndLat"] =
                  updatedTaskTransportationData.taskTransportationEndLat;
                sitems["taskTransportationEndLong"] =
                  updatedTaskTransportationData.taskTransportationEndLong;

                sitems["taskTransportationCost"] =
                  updatedTaskTransportationData.taskTransportationCost;
                sitems["taskTransportationRemarks"] =
                  updatedTaskTransportationData.taskTransportationRemarks;
              }
            }
            return sitems;
          });

          var items = data.items.slice();
          var newItems = items.map(function (sitems) {
            // console.log("sitems");
            // console.log(sitems);
            for (var key in sitems) {
              if (sitems.taskId === values.taskId) {
                // console.log("Hello");
                sitems["taskTransportationData"] = newTaskTransportationData;
              }
            }
            return sitems;
          });

          setData({
            ...data,
            items: newItems,
          });
          setOpenPopup({
            ...openPopup,
            isOpen: false,
          });
        });

        // console.log("hello");
        // console.log(data);
      });
    },
  });

  useEffect(() => {
    dispatch(listVehicle());
    dispatch(listLocation());
  }, [dispatch, history]);

  const changeValues = () => {
    let filteredTask = data.items.filter((singleData) => {
      console.log("0somge");
      console.log(taskId);
      return String(singleData.taskId) === String(taskId);
    });

    console.log("filteredTask ss");
    console.log(filteredTask);

    let taskTransportationData = filteredTask[0].taskTransportationData;

    let filteredTaskTransportationData = taskTransportationData.filter(
      (singleData) => {
        return (
          String(singleData.taskTransportationAutoId) ===
          String(transportationId)
        );
      }
    );

    console.log("filteredTaskTransportationData");
    console.log(filteredTaskTransportationData);

    if (filteredTaskTransportationData.length > 0) {
      formik.setFieldValue("taskId", taskId);
      formik.setFieldValue("transportationId", transportationId);
      formik.setFieldValue("vehicle", {
        id: filteredTaskTransportationData[0].taskTransportationVehicleId || "",
        label: filteredTaskTransportationData[0].vehicleName || "",
      });

      formik.setFieldValue("location", {
        id:
          filteredTaskTransportationData[0].taskTransportationStartLocationId ||
          "",
        label:
          filteredTaskTransportationData[0].taskTransportationStartLocation ||
          "",
      });

      formik.setFieldValue("endLocation", {
        id:
          filteredTaskTransportationData[0].taskTransportationEndLocationId ||
          "",
        label:
          filteredTaskTransportationData[0].taskTransportationEndLocation || "",
      });

      formik.setFieldValue(
        "fare",
        filteredTaskTransportationData[0].taskTransportationCost
      );
      formik.setFieldValue(
        "remarks",
        filteredTaskTransportationData[0].taskTransportationRemarks
      );
    }
  };

  useEffect(() => {
    changeValues();
  }, [data, taskId, transportationId]);

  const vehicleChange = useCallback((e, v) => {
    formik.setFieldValue("vehicle", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const locationChange = useCallback((e, v) => {
    formik.setFieldValue("location", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const endLocationChange = useCallback((e, v) => {
    formik.setFieldValue("endLocation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Autocomplete
            // disablePortal
            size="small"
            disabled={loadingLocation ? true : false}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={locationChange}
            value={formik.values.location}
            options={locations !== undefined ? locations : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="From"
                name="location"
                error={
                  formik.errors.location !== undefined
                    ? formik.errors.location.id
                    : ""
                }
                touched={
                  formik.touched.location !== undefined
                    ? formik.touched.location
                    : ""
                }
                {...params}
                onBlur={formik.handleBlur}
                className="fullwidth-input-task"
                required
              />
            )}
          />
          <Autocomplete
            // disablePortal
            size="small"
            disabled={loadingLocation ? true : false}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={endLocationChange}
            value={formik.values.endLocation}
            options={locations !== undefined ? locations : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="To"
                name="endLocation"
                error={
                  formik.errors.endLocation !== undefined
                    ? formik.errors.endLocation.id
                    : ""
                }
                touched={
                  formik.touched.endLocation !== undefined
                    ? formik.touched.endLocation
                    : ""
                }
                {...params}
                onBlur={formik.handleBlur}
                className="fullwidth-input-task"
                required
              />
            )}
          />
          <Autocomplete
            size="small"
            disabled={loadingVehicle ? true : false}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={vehicleChange}
            value={formik.values.vehicle}
            options={vehicles !== undefined ? vehicles : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="Vehicle"
                name="vehicle"
                error={
                  formik.errors.vehicle !== undefined
                    ? formik.errors.vehicle.id
                    : ""
                }
                touched={
                  formik.touched.vehicle !== undefined
                    ? formik.touched.vehicle
                    : ""
                }
                {...params}
                onBlur={formik.handleBlur}
                className="fullwidth-input-task"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Fare"
            name="fare"
            error={formik.errors.fare}
            touched={formik.touched.fare}
            value={formik.values.fare}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="fullwidth-input-task"
            required
          />
          <Input
            label="Remarks"
            name="remarks"
            error={formik.errors.remarks}
            touched={formik.touched.remarks}
            value={formik.values.remarks}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            multiline
            className="fullwidth-input-task"
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Button
              style={{ marginRight: "0.5rem" }}
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <EditOutlinedIcon />
                )
              }
              type="submit"
              color="primary"
              size="large"
              text="Update"
            />

            <Button
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <DeleteIcon />
                )
              }
              type="button"
              color="error"
              size="large"
              text="Delete"
              onClick={() => {
                handleBatchDel(taskId, transportationId);
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withRouter(EditTransport);

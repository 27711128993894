import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Container, FloatingLabel } from "react-bootstrap";
import Menu from "../../core/Menu";
import {
  FilterProductsForInvoice,
    updatePackingStickerAction,
} from "../../redux/actions/invoiceActions";
import { API_URL } from "../../redux/constants/apiConstants";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../core/Popup";
import {packingListStockOutMapAction
} from "../../redux/actions/BarcodeActions"
import SuccessPopup from "../../core/SuccessPopup";
import PackingItemBarcodeMap from "../../core/PackingItemBarcdoeMap";
import { barcodeDecodeAction,insertStockOutBarcodeAction  } from "../../redux/actions/BarcodeActions";
import { updateStockOutBarocdeAction,removeStockOutBarcodeAction} from "../../redux/actions/BarcodeActions";
var FilteredProduct = [];

const StockOutBarcodeMap = ({ match, history }) => {
  const dispatch = useDispatch();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const packingBarcodeMappingState = useSelector(
    (state) => state.packingBarcodeMappingState
  );
  const {
    loading: loadingBar,
    error: errorBar,
    salesorderinternalpackagingdonesingle,
  } = packingBarcodeMappingState;
  
  const barcodeReadingState = useSelector(
    (state) => state.barcodeReadingState
  );
  const {
    loading:loading,
    error: error,
    decodedData,
  } = barcodeReadingState;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;
  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [barcodeId, setBarcodeId] = useState('');
 
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "1",
    unitPrice: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0",
    lineTotal: "0.00",
    switchDiscountPercentage: false,
  });
 
  const [salesOrderId, setSalesOrderId] = useState('');
  const [barcode, setBarCode] = useState('');
 
  const [total, setTotal] = useState(0.0);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [batchAndMap, setBatchAndMap] = useState();
  
  const [successPopup, setSuccessPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });


  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [data, setData] = useState({
    isOpen: false,
    currency: "TK",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    editableField: 1,
    items: [], // Initialize items array here
  });

  useEffect(() => {
    if (userInfo) {
      setSwitchTStuck(true);
      dispatch(packingListStockOutMapAction(salesOrderId));
      //console.log("Barcode value:", barcode);
      //dispatch(barcodeDecodeAction(barcode)); // Dispatch the action here
    } else {
      const location = {
        pathname: "/signin",
        state: {
          //from: { pathname: `/salesorderinternal/${salesOrderId}/edit` },
          from: {pathname: `/barcode/stockout`},
        },
      };
      history.push(location);
    }
  }, [salesOrderId, dispatch, history, userInfo]);
  

    const changeValues = async () => {
    setSwitchTStuck(true);
    if (
      JSON.stringify(salesorderinternalpackagingdonesingle) !== "{}" &&
      salesorderinternalpackagingdonesingle !== undefined
    ) {
      var d = new Date(salesorderinternalpackagingdonesingle.invoiceDate);
      d = d.toString();
     
      d = d.split(" ");
      d = [d[2], d[1], d[3]].join("-");
      setInvoiceDate(d);

      setSelectedCustomer([
        {
          
          id: salesorderinternalpackagingdonesingle.customerId,
          label: salesorderinternalpackagingdonesingle.customerName,
        },
      ]);

      setSelectedOrderType([
        {
          id: "",
          label: salesorderinternalpackagingdonesingle.jobType,
        },
      ]);
      if (batchAndMap !== undefined) {
        setData({
          ...data,
          invoiceNumber: salesorderinternalpackagingdonesingle.soSystemNo,
          items: batchAndMap,
        });
      }
    }
  };

  useEffect(() => {
    changeValues();
  }, [salesorderinternalpackagingdonesingle, batchAndMap]);

  useEffect(() => {
    if (salesorderinternalpackagingdonesingle?.stockOutPackagingItems) {
      const fetchData = async () => {
        const promises = salesorderinternalpackagingdonesingle.stockOutPackagingItems.map(async (singleData, i) => {
          // Fetch product information for each item individually
          const response = await fetch(`${API_URL}/Product/GetPackType`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          });
          const data = await response.json();
          // Process fetched data
          const finalPackType = data.dataObj.map(single => ({
            id: single.packId,
            label: single.packTypeName,
          }));
          return {
            index: `${singleData.sopProductId}-${i}`,
            productId: singleData.sopProductId,
            productName: singleData.productName,
            packSize: singleData.sopPackSize,
            packNo: singleData.sopNoPack,
            packQuantity: singleData.sopProductQty,
            quantity: singleData.sodConfirmedQty || 0.0,
            packTypeList: finalPackType,
            sopPackId: singleData.sopPackId,
            packInfo: {
              id: singleData.sopPackId === null ? "" : singleData.sopPackId,
              label: singleData.sopPackId === null ? "" : singleData.packTypeName,
            },
            barcodeData:singleData.barcodeData,
            delete: 1,
          };
        });

        // Wait for all promises to resolve
        const newDataItems = await Promise.all(promises);
        // Update state with fetched product information
        setData(prevData => ({
          ...prevData,
          items: newDataItems,
        }));
      };

      fetchData();
    }
  }, [salesorderinternalpackagingdonesingle, userInfo]);

  
  const handleRowDel = (items) => {
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.index;
    });
    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
  };

  //New Entry
  const handleReload = () => {
    window.location.reload(); // Reload the page
  };


//update stock out barcdoe 
const handleUpdate = (item) => {
  const indexPackingItem = parseInt(item.index.split('-')[1]);
  const formData = {
    customerId: salesorderinternalpackagingdonesingle.soCustomerId,
    soSystemId: salesorderinternalpackagingdonesingle.soSystemId,
    indexPackingItem: indexPackingItem,
    barcodeData: barcodeId,
    
  }; 
  // Check if any of the barcode data is blank
  if (!formData.barcodeData) {
    setOpenPopup({
      ...openPopup,
      title: indexPackingItem,
      subTitle: "Barcode cannot be blank",
      isOpen: true,
    });
    return;
  }
  dispatch(updateStockOutBarocdeAction(formData))
    .then((result) => {
      if (result?.errMsg) {
        setOpenPopup({
          ...openPopup,
          title: "Error",
          subTitle: result.errMsg,
          isOpen: true,
        });
      } else {
        setOpenPopup2({
          ...openPopup2,
          title: "Success",
          subTitle: "Update successful",
          isOpen: true,
        });
      }
    })
    .catch((error) => {
      setOpenPopup({
        ...openPopup,
        title: "Error",
        subTitle: error.message || "An error occurred",
        isOpen: true,
      });
    });
};


// // This function will handle the removal of an item by its index
// const handleRemove = (index) => {
 
//   const itemToRemove = data.items[index];
//   if (itemToRemove) {
//     const updatedItems = data.items.filter((item, i) => i !== index);
//     console.log(itemToRemove.barcodeData);
//     dispatch(removeStockOutBarcodeAction(itemToRemove.barcodeData));

//     setData({ ...data, items: updatedItems });
//     if (itemToRemove.barcodeData) {
//       dispatch(removeStockOutBarcodeAction(itemToRemove.barcodeData));
//     }
//     setSuccessPopup({
//       isOpen: true,
//       title: "Success",
//       subTitle: "Item removed successfully!",
//     });
//   } else {
//     console.error("Item to remove is undefined");
//   }
// };


const handleRemove = (index) => {
  
  const updatedItems = data.items.map(item => {
    if (item.index === index) {
      return { ...item, barcodeData: '' }; // Clear the barcodeData
    }
    return item;
  });

  // Update your state or context with the updated items list
  setData(prevState => ({
    ...prevState,
    items: updatedItems,
  }));
 
  const itemToRemove = data.items.find(item => item.index === index);
  if (itemToRemove) {
    dispatch(removeStockOutBarcodeAction(itemToRemove.barcodeData))
      .then((result) => {
        if (result?.errMsg) {
          setOpenPopup({
            ...openPopup,
            title: "Error",
            subTitle: result.errMsg,
            isOpen: true,
          });
        } else {
          setSuccessPopup({
            isOpen: true,
            title: "Success",
            subTitle: "Item removed successfully!",
          });
        }
      })
      .catch((error) => {
        setOpenPopup({
          ...openPopup,
          title: "Error",
          subTitle: error.message || "An error occurred",
          isOpen: true,
        });
      });
  }
};


// const handleRemove = (index) => {
//   //const itemToRemove = data.items.find(item => item.index === index);
//     //dispatch(removeStockOutBarcodeAction(itemToRemove.barcodeData))

//     const updatedItems = data.items.map(item => {
//       if (item.index === index) {
//         return { ...item, barcodeData: '' }; // Clear the barcodeData
//       }
//       return item;
//     });
    
//     // Update your state or context with the updated items list
//     setData(prevState => ({
//       ...prevState,
//       items: updatedItems,
//     }));
  
//     const itemToRemove = data.items.find(item => item.index === index);
//     if (itemToRemove) {
//       dispatch(removeStockOutBarcodeAction(itemToRemove.barcodeData));
//       setSuccessPopup({
//         isOpen: true,
//         title: "Success",
//         subTitle: "Item removed successfully!",
//       });
//     }  else {
//       console.error("Item to remove is undefined");
//     }
    
// };



  //insert barcode out data
  const handleFormSubmit = (item, values) => {
      const indexPackingItem = parseInt(item.index.split('-')[1]);
      
      const formData = {
        customerId: salesorderinternalpackagingdonesingle.soCustomerId,
        soSystemId: salesorderinternalpackagingdonesingle.soSystemId,
        indexPackingItem: indexPackingItem,
        barcodeData: barcodeId,
        
      }; 
      // Check if any of the barcode data is blank
      if (!formData.barcodeData) {
        setOpenPopup({
          ...openPopup,
          title: indexPackingItem,
          subTitle: "Barcode cannot be blank",
          isOpen: true,
        });
        return;
      }
      // Perform additional validation if needed
      dispatch(insertStockOutBarcodeAction(formData))
        .then((result) => {
          // Check if the result indicates success or error
          if (result?.errMsg) {
            // If there is an error message in the result, show the error popup
            setOpenPopup({
              ...openPopup,
              title: formData.barcodeData,
              subTitle: result.errMsg,
              isOpen: true,
            });
          } else {
            // If no error message, show the success popup
            setOpenPopup({
              ...openPopup,
              title: "Success",
              subTitle: "Success",
              isOpen: true,
            });
          }
        })
        .catch((error) => {
          // If there is a server-side exception, show the error popup
          setOpenPopup({
            ...openPopup,
            title: formData.barcodeData,
            subTitle: error.message || "An error occurred",
            isOpen: true,
          });
        });
  };

  const handleCalculateTotalFromEditable = (id, newItems) => {
   
    var items = newItems;
    var f = 0;
    items.map((single) => {
      if (single.packSize === "" && single.packNo === "") {
        f = 1;
      }
    });

    var editedNewItems = items.map(function (sitems, i) {
      for (var key in sitems) {
        if (key == "packQuantity" && sitems.index == id) {
          if (
            sitems["quantity"] <
            parseFloat(sitems.packSize) * parseFloat(sitems.packNo)
          ) {
            setOpenPopup({
              ...openPopup,
              title: `ERROR!!!`,
              subTitle: `Can't exceed original quantity.`,
              isOpen: true,
            });
          } else if (
            parseFloat(sitems["quantity"]) >=
            parseFloat(sitems.packSize) * parseFloat(sitems.packNo)
          ) {
            sitems[key] =
              parseFloat(sitems.packSize) * parseFloat(sitems.packNo);
          }
        }
      }
      return sitems;
    });

    var finalNewItem = editedNewItems.map((single, i) => {
      if (
        single.quantity >
          parseFloat(single.packSize) * parseFloat(single.packNo) &&
        single.index === id
      ) {
        return {
          index: single.productId + "-" + newItems.length,
          productId: single.productId,
          productName: single.productName,
          packSize: "",
          packNo: "",
          packQuantity: "",
          quantity: single.quantity,
          packTypeList: single.packTypeList,
          sopPackId: single.sopPackId,
          packInfo: {
            id: single.packInfo.id,
            label: single.packInfo.label,
          },
          barcodeData:single.barcodeData,
          delete: 1,
        };
      }
    });
    var a = finalNewItem.filter((s) => {
      return s !== undefined;
    });
    var flag = 0;
    var sum = 0;
    var newId = id.split("-");
    editedNewItems.map((single, i) => {
      if (single.productId === parseInt(newId[0])) {
        sum = sum + parseFloat(single.packSize) * parseFloat(single.packNo);
      }
    });

    var x = editedNewItems.filter((num) => {
      return num.index === id;
    });

    if (x[0].quantity === sum) {
      flag = -1;
    }

    if (flag === 0) {
      var arr = a;
      if (arr[0] !== undefined) {
        if (f === 0) {
          var index;
          editedNewItems.map((s, i) => {
            if (s.productId === arr[0].productId) {
              index = i;
            }
          });

          editedNewItems.splice(index + 1, 0, arr[0]);
        } else {
          setOpenPopup({
            ...openPopup,
            title: "MORE THAN ONE FIELD ERROR!!!",
            subTitle: "A empty field is open already!",
            isOpen: true,
          });
        }
      }
    }
    setData({
      ...data,
      items: editedNewItems,
    });
  };
  const onItemizedItemEdit = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var items = data.items.slice();
    var f = 0;
    items.map((single) => {
      if (single.packSize === "" && single.packNo === "") {
        f = 1;
      }
    });
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == item.name && sitems.index == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
    });
    var x = newItems.filter((num) => {
      return num.index === item.id;
    });
    setTimeout(() => {
      if (x[0].packSize !== "" && x[0].packNo !== "") {
        handleCalculateTotalFromEditable(item.id, newItems);
      }
    }, 2400);
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
  };


  const onItemizedBatchAndMapEdit = (evt) => {

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == evt.name && sitems.index == evt.index) {
          sitems[key] = evt.id;
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
    });
  };

  const editProductField = (event) => {
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
  };

  const openModal = (event) => {
    event.preventDefault();
    if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      // var flag = 0;
      if (data.items.length > 0) {
        var wasNull = false;
        for (var i in data.items) {
          if (
            data.items[i].packSize === "" ||
            data.items[i].packQuantity === ""
          ) {
            wasNull = true;
          }
        }
        if (wasNull === false) {
          var confirmedQty = data.items.map((singleData) => {
            return singleData.productId + "-" + singleData.quantity;
          });

          var removeDuplicateArrays = [...new Set(confirmedQty)];

          removeDuplicateArrays = removeDuplicateArrays.map((single) => {
            var id = single.split("-");
            return parseFloat(id[1]);
          });

          var sumQuantity = parseFloat(0);
          removeDuplicateArrays.map((singleValue) => {
            sumQuantity = sumQuantity + singleValue;
          });

          var sumPackQuantity = parseFloat(0);
          data.items.map((singleData) => {
            sumPackQuantity += singleData.packQuantity;
          });

          if (parseFloat(sumQuantity) === parseFloat(sumPackQuantity)) {
            var dataArray = data.items.map((singleData) => {
              return {
                soSystemId: parseInt(salesOrderId),
                sopProductId: parseInt(singleData.productId),
                sopProductQty: singleData.packQuantity,
                sopPackId: parseInt(singleData.sopPackId),
                sopPackSize: parseFloat(singleData.packSize),
                sopNoPack: parseInt(singleData.packNo),
              };
            });
           
            dispatch(updatePackingStickerAction(dataArray)).then(
              (result) => {
                if (result?.errMsg) {
                } else {
                  setOpenPopup2({
                    ...openPopup2,
                    title: `SUCCESS!!!`,
                    subTitle: `Packaging Done.`,
                    isOpen: true,
                  });
                }
              }
            );

            //handleCalculateTotal();
          } else {
            setOpenPopup({
              ...openPopup,
              title: "QUANTITY ISSUE!!!",
              subTitle: "Quantities and Pack Quantities are not equal!",
              isOpen: true,
            });
          }
        } else {
          setOpenPopup({
            ...openPopup,
            title: "EMPTY FIELDS!!!",
            subTitle: "No Fields can be empty!",
            isOpen: true,
          });
        }
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  
  
  const closeModal = (event) => setData({ ...data, isOpen: false });
// Define state to store decodedData for each item
const [decodedDataList, setDecodedDataList] = useState([]);

  const [barcodeProductName, setBarcodeProductName] = useState('');
  const [barcodePackSize, setBarcodePackSize] = useState('');
  
  
  // Modify handleBarcodeChange to update decodedDataList
const handleBarcodeChange = (barcodeId, index) => {
  setBarcodeId(barcodeId);
  if (barcodeId) {
    dispatch(barcodeDecodeAction(barcodeId)).then((decodedData) => {
      // Update decodedDataList with the decodedData at the corresponding index
      setDecodedDataList((prevList) => {
        const newList = [...prevList];
        newList[index] = decodedData;
        return newList;
      });
      //setBarcodeProductName(decodedData?.bcProductName || '');
      //setBarcodePackSize(decodedData?.bcPackSize || '');
    });
  }
};

  return (
    <div>
  
      <Menu />
      <Container>
        <Form>
          <Row style={{ fontSize: "12px" }}>  
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            > 
              <Card className="p-4 p-xl-5 my-3 my-xl-4">

              <Button variant="primary" onClick={handleReload} className="mb-3" style={{ width: 'fit-content' }}>
                New Entry
              </Button>

                <Form.Label className="fw-bold">
                  <Form.Control
                    type="text"
                    value={salesOrderId}
                    placeholder="Invoice Number"
                    onChange={(e) => setSalesOrderId(e.target.value)}
                  />
                </Form.Label>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">
                        Customer Name:
                      </Form.Label>

                      <Typeahead
                        clearButton
                        disabled
                        id="basic-example"
                        name="customerId"
                        label="Customer Name"
                        // disabled={
                        //   loadingCustomer
                        //     ? true
                        //     : data.items.length > 0 && switchTStuck
                        //     ? true
                        //     : false
                        // }
                        onChange={(selectedCustomer) => {
                          setSelectedCustomer(selectedCustomer.id);
                        }}
                        options={customers !== undefined ? customers : []}
                        placeholder="Customer Name"
                        selected={selectedCustomer}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          marginRight: "10px",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Type:</Form.Label>

                        <Typeahead
                        disabled
                          clearButton
                          id="basic-example"
                          name="orderTypeId"
                          label="Order Type"
                          // disabled={
                          //   loadingOrderType
                          //     ? true
                          //     : data.items.length > 0 && switchTStuck
                          //     ? true
                          //     : false
                          // }
                          onChange={(selectedOrderType) => {
                            setSelectedOrderType(selectedOrderType);
                          }}
                          options={orderTypes !== undefined ? orderTypes : []}
                          placeholder="Order Type"
                          selected={selectedOrderType}
                          
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Date:</Form.Label>
                        <Form.Control
                          placeholder={"Invoice Date"}
                          value={invoiceDate}
                          type="text"
                          name="dateOfIssue"
                          onChange={(event) => editField(event)}
                          autoComplete="name"
                          required="required"
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label className="fw-bold">
                      Customer&nbsp;Address:&nbsp;
                    </Form.Label>
                    <Form.Control
                      placeholder={"Customer Address"}
                      value={
                        JSON.stringify(
                          salesorderinternalpackagingdonesingle
                        ) !== "{}" &&
                        salesorderinternalpackagingdonesingle !== undefined
                          ? salesorderinternalpackagingdonesingle.customerAddress !==
                            null
                            ? salesorderinternalpackagingdonesingle.customerAddress
                            : "Not Available"
                          : ""
                      }
                      type="text"
                      name="invoiceNumber"
                      autoComplete="address"
                      onChange={(event) => editField(event)}
                      required="required"
                      as="textarea"
                      rows={3}
                      disabled
                    />
                  </Col>
                </Row>
                <hr className="my-4" />
                {data.items.length > 0 && switchTStuck && (
                <PackingItemBarcodeMap
                  items={data.items}
                  onItemizedItemEdit={onItemizedItemEdit}
                  onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
                  onSubmit={handleFormSubmit} 
                  switchMappingOption={switchMappingOption}
                  setSwitchMappingOption={setSwitchMappingOption}
                  onBarcodeChange={handleBarcodeChange}
                  allOptionAuto={true}
                  onUpdate={handleUpdate}
                  onRemove={handleRemove}
                />
              )}
              </Card>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
        
      </Container>
    </div>
  );
};
export default StockOutBarcodeMap;
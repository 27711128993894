import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { BiPaperPlane } from "react-icons/bi";
import styled from "@emotion/styled";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";

export default function ProductLoanPdfModal({
  showModal,
  closeModal,
  item_list,
 //date_range,
}) {
  const reportRef = useRef(null);

  const handleGeneratePdf = async (customerName) => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    const pageHeight = pdf.internal.pageSize.getHeight();

    let currentPageHeight = 0;
    let currentPage = reportRef.current;

    const conveyanceHeader = document.querySelector(".conveyance-header");
    let canvas = await html2canvas(conveyanceHeader);

    let canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = reportRef.current;
    }

    let imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      0.05,
      currentPageHeight,
      pdf.internal.pageSize.getWidth(),
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const conveyanceSubHeader = document.querySelector(
      ".conveyance-sub-header"
    );

    canvas = await html2canvas(conveyanceSubHeader);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      
      currentPage = reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight,

      pdf.internal.pageSize.getWidth() - 10,
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const conveyanceTableHeader = document.querySelector(
      ".conveyance-table-header"
    );

    canvas = await html2canvas(conveyanceTableHeader);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight,
      pdf.internal.pageSize.getWidth() - 10,
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const contentElements = document.querySelectorAll(".conveyance-info");
    // console.log("content elements: ", contentElements);

    for (const element of contentElements) {
      const canvas = await html2canvas(element);

      const canvasHeight =
        (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

      if (currentPageHeight + canvasHeight > pageHeight) {
        pdf.addPage();
        currentPageHeight = 0;
        currentPage = reportRef.current;
      }

      const imageData = canvas.toDataURL("image/png");

      pdf.addImage(
        imageData,
        "PNG",
        5,
        currentPageHeight,
        pdf.internal.pageSize.getWidth() - 10,
        canvasHeight
      );
      currentPageHeight += canvasHeight;
    }

    const conveyanceFooter = document.querySelector(".conveyance-footer");

    canvas = await html2canvas(conveyanceFooter);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight + 10,
      pdf.internal.pageSize.getWidth() - 10,
      canvasHeight
    );

    pdf.save(`Loan-Details-${customerName}.pdf`);
  };
//######
  const getTotalPrice = (arr) => {
    var sum = 0;
    arr?.map((single) => {
      sum = sum + single.taskTransportationCost;
    });

    sum = parseFloat(sum);

    return sum.toFixed(2);
  };

  const NumInWords = (number) => {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  };
  const totalLoanIssued = item_list.reduce((acc, item) => acc + item.loanGivenQty, 0);
  const totalLoanReceived = item_list.reduce((acc, item) => acc + item.loanReceivedQty, 0);
  const balance = totalLoanIssued - totalLoanReceived;

  return (
    <div>
      <SalesOrderModal show={showModal} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <div
          ref={reportRef}
          id="invoiceCapture"
          className=" pt-1 "
          style={{ position: "relative" }}
        >
          <div className="conveyance-header">
            <div className="d-flex flex-row justify-content-between align-items-center w-100 py-4 px-2">
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/trade.png"}
                  alt=""
                  width="80"
                  height="35"
                  style={{ width: "80px", height: "50px" }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="fw-bold my-2" style={{ fontSize: "18px" }}>
                  TRADE ZENTRUM
                </h4>
                <h6
                  className="fw-bold text-secondary mb-1 text-center"
                  style={{ fontSize: "14px" }}
                >
                  Kunia Targas, Gazipur
                </h6>
              </div>
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "130px", height: "40px" }}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="conveyance-sub-header">
              <div style={{ textAlign: "center", margin: "10px 0" }}>
                <span
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid #e4e4e4",
                    padding: "5px",
                    fontSize: "18px",
                  }}
                >
                  Product Loan Details
                </span>
              </div>
              <Row style={{ paddingBottom: "50px", height: "auto" }}>
                <Col md={7} lg={8}>
                  <div style={{ fontSize: "12px" }}>
                    {/* Bill&nbsp;Date:&nbsp; : {date_range} */}
                  </div>
                  
                  <div style={{ fontSize: "18px", marginTop: "20px"}}>
                    {/* Bill By:&nbsp; {item_list[0]?.billBy} */}
                     Company Name:&nbsp; {item_list[0]?.customerName} 
                     
                  </div>
                  <div style={{ fontSize: "14px", marginTop: "10px" }}>
                    Total Loan Issued:&nbsp; {totalLoanIssued}
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    Total Loan Received:&nbsp; {totalLoanReceived}
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    Balance:&nbsp; {balance}
                  </div>
                </Col>
              </Row>
            </div>

            <div
              style={{
                width: "auto",
                overflowX: "scroll",
              }}
              className="row-scrollers"
            >
              <Table
                className="mb-0"
                style={{
                  border: "1px solid #e3e3e3",
                  fontSize: "11px", 
                }}
              >
                <thead className="conveyance-table-header" style={{ fontSize: "12px" }}>
                  <tr>
                    <th>Invoie No</th>
                    <th>Invoice Date</th>
                    <th>Product Name</th>  
                    <th>Loan Received</th>
                    <th>Loan Given</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {item_list?.length > 0
                    ? item_list?.map((conveyance) => {
                        return (
                          <tr
                            key={conveyance?.manualInvoice}
                            className="conveyance-info"
                          >
                            <td>{conveyance?.manualInvoice}</td>
                            <td>{conveyance?.invoiceDate}</td>
                            <td>{conveyance?.productName}</td>
                            <td>{conveyance?.loanReceivedQty}</td>
                            <td>{conveyance?.loanGivenQty}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </div>

            <div className="conveyance-footer">
              {/* <Table style={{ marginTop: "2rem", border: "1px solid #e3e3e3" }}>
                <tbody>
                  <tr className="text-end">
                    <td></td>
                    <td
                      className="fw-bold"
                      style={{ width: "auto", fontSize: "12px" }}
                    >
                      Total Conveyance Amount
                    </td>
                    <td
                      className="text-end"
                      style={{ width: "100px", fontSize: "12px" }}
                    >
                    </td>
                  </tr>
                </tbody>
              </Table> */}
            </div>
          </div>
        </div>
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              <Button
                variant="primary"
                className="d-flex align-items-center justify-content-center w-100"
                onClick={() => {
                  // GenerateInvoice(item_list[0]?.taskId);
                  handleGeneratePdf(item_list[0]?.customerName);
                }}
              >
                <BiPaperPlane
                  style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                  className="me-2"
                />
                Print This Out
              </Button>
            </Col>
            <Col md={6}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center justify-content-center w-100"
                  >
                    Export as PDF
                  </Button>
                )}
                content={() => reportRef.current}
              />
            </Col>
          </Row>
        </div>
      </SalesOrderModal>
      <hr className="mt-4 mb-3" />
    </div>
  );
}

const SalesOrderModal = styled(Modal)`
  #invoiceCapture {
    padding: 5px;
  }

  table tr th,
  td {
    font-size: 10px;
    text-align: left;
  }

  .sticker-info {
    display: flex;
    width: 402px;
    max-height: 218px;
    height: 218px;
    max-width: 402px;
    border: 1px solid #000;
    padding: 10px;
    flex-direction: column;
    row-gap: 4px;
  }

  .capture-portion {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    row-gap: 4;
    flex-direction: column;
    align-items: center;
  }

  .sales-order-sticker-base {
    display: flex;
    column-gap: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__left-portion {
    width: 180px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info
    p {
    margin: 0;
    font-size: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__right-portion {
    width: calc(100% - 150px);
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info {
    margin-left: -90px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table {
    width: 100%;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr {
    display: flex;
    column-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr
    .info-td {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table
    tr
    td {
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag
    .invoice-number {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo {
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .date {
    font-size: 8px;
    margin: 0;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .image {
    display: flex;
    align-items: center;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .invoice-number {
    margin: 0;
    font-size: 8px;
  }

  .date-and-company-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .date-and-company-name p {
    margin: 0;
    font-size: 12px;
  }

  .date-and-company-name .date {
    font-size: 6px !important;
    margin-top: 15px;
  }
`;

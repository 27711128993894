import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  Toolbar,
  Button as MuiButton,
  createFilterOptions,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import NotInterestedRoundedIcon from '@mui/icons-material/NotInterestedRounded';

import {
  deleteEmployee,
} from "../../redux/actions/employeeActions";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
//import Button from "../../core/controls/Button";
import Button from "@mui/material/Button";
import {

  listSalesOrderInternalDetails,
} from "../../redux/actions/invoiceActions";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import { Form } from "react-bootstrap";

import SalesOrderInternalModal from "../../core/SalesOrderInternalModal";
import SalesOrderView from "../../core/SalesOrderView";
import KarjadeshView from "../../core/KarjadeshView";
import SalesOrderChalaanModal from "../../core/SalesOrderChalaanModal";
import Datepicker from "../../core/controls/Datepicker";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import { useFormik } from "formik";
import SampleStickerModal from "../../core/SampleStickerModal";
import BarcodeModal from "../../core/BarcodeModal";
import {
    getProductsList,
  } from "../../redux/actions/CurrentStockReportActions";
  import { listPrintBarcodeInfo,
    removeBarCodeDataByParamsAction } from "../../redux/actions/BarcodeActions";
const useStyles = makeStyles(() => ({
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#7f6000",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "290px !important",
      maxWidth: "290px !important",
    },

    "& .MuiDataGrid-cell": {
      minWidth: "290px !important",
      maxWidth: "290px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#6C4AB6",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='soSystemId'], .MuiDataGrid-cell[data-field='soSystemId']":
      {
        display: "none",
      },
    "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3'], .MuiDataGrid-columnHeader[data-field='actions4'], .MuiDataGrid-cell[data-field='actions4']":
      {
        minWidth: "100px !important",
        maxWidth: "100px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#7f6000",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#FF8C00",
    color: "#fff",
    margin: `${useTheme().spacing(0)} ${useTheme().spacing(1)}`,
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #FF8C00",
    },
    "&:hover": {
      backgroundColor: "#FFA07A",
    },
  },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(3),
  },
  pageContent2: {
    overflowX: "auto",
    justifyContent: "space-between",
    margin: useTheme().spacing(1),
    padding: `${useTheme().spacing(2)} ${useTheme().spacing(1)}`,
    [useTheme().breakpoints.up("md")]: {
      padding: `${useTheme().spacing(2)} ${useTheme().spacing(7)}`,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "none",
    justifyContent: "center !important",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    "& div:nth-child(1)": {
      margin: "15px",
    },
    [useTheme().breakpoints.up("md")]: {
      display: "none",
      justifyContent: "center !important",
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
      "& div:nth-child(1)": {
        margin: "0px",
      },
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },

  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  actions2: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "right",
      width: "420px",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
    invoiceNo:"",
    containerNo:"",
  product: {
    id: "",
    label: "",
  },
};

const PrintBarcode = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = () => {
    // Navigate to the desired page on button click
    history.push("/barcode/createbarcode");
  };

const listOrderForPackingAndEditState = useSelector(
    (state) => state.listOrderForPackingAndEditState
  );
  const { loading, error,orderList } = listOrderForPackingAndEditState;
  
  const salesOrderInternalPackage = useSelector(
    (state) => state.salesOrderInternalPackage
  );
  const {
    loading: loadingPackage,
    error: errorPackage,
    success: successPackage,
    packagedSalesOrder,
  } = salesOrderInternalPackage;

 const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const employeeDelete = useSelector((state) => state.employeeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = employeeDelete;

  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = employeeUpdate;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;


  const packingDetailsState = useSelector(
    (state) => state.packingDetailsState
  );
  const {
    loading: loadingDoneDetails,
    error: errorDoneDetails,
    salesorderinternalpackagingdonesingle,
  } = packingDetailsState;

  const productsNameList = useSelector((state) => state.productNameList);
  const {
    loading: loadingProductsName,
    error: errorProductsName,
    productNameList,
  } = productsNameList;



  const salesOrderInternalEscalate = useSelector(
    (state) => state.salesOrderInternalEscalate
  );
  const {
    loading: loadingEscalate,
    error: errorEscalate,
    success: successEscalate,
    escalatedSalesOrder,
  } = salesOrderInternalEscalate;


  const printBarcdoeListState = useSelector(
    (state) => state.printBarcdoeListState
  );
  const { loadingList,errorList,printbarcode } = printBarcdoeListState;

  const removeBarcdoeByParamsState = useSelector(
    (state) => state.removeBarcdoeByParamsState
  );
  const {}=removeBarcdoeByParamsState; 

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(50);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whatOpen, setWhatOpen] = useState(-1);
  const [isOpenKarjadesh, setIsOpenKarjadesh] = useState(false);
  const [isOpenInvoice, setIsOpenInvoice] = useState(false);
  const [isOpenSticker, setIsOpenSticker] = useState(false);
  const [isOpenChalaan, setIsOpenChalaan] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("3");
  const [dataForEscalation, setDataForEscalation] = useState([]);
  const selectedOrderRef = useRef(null);
  const [message, setMessage] = useState(""); // State to hold the message from the server


useEffect(() => {
    if (userInfo) {
      
      dispatch(getProductsList());

    } else {
      const location = {
        pathname: "/signin",
        state: {
          //from: { pathname: `/salesorderinternal/${salesOrderId}/edit` },
          from: { pathname: `/barcode/listprint` },
        },
      };
      history.push(location);
    }
  }, [ dispatch, history, userInfo]);


  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };
 
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      let data = {
        invoiceNo: values.siInvoiceNo ? values.siInvoiceNo : "empty", // If siInvoiceNo is null, set invoiceNo to empty string
        productId: values.product && values.product.id ? values.product.id : 0, // If product id is null or product itself is null, set productId to 0
        containerNo: values.packNo ? values.packNo : 0, // If packNo is null, set containerNo to 0
      };

        dispatch(listPrintBarcodeInfo(data)).then(
        (result) => {
          if (result?.title === "One or more validation errors occurred.") {
            action.setSubmitting(false);
          }
        }
      );
    },
  });

    const handleEvent = (params) => {
  };

  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={printbarcode === undefined ? [] : printbarcode}
          columns={
            //selectedOrder === "0"
             
               columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.soSystemId + "-" + generateRandom()}
          onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      if (selectedOrder === "0") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
      printbarcode.find((row) => row.soSystemId === id)
      );
      setDataForEscalation([selectedRowsData[0].soSystemId]);
      setRowId(selectedRowsData[0].soSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteEmployee(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };

  const openModal = (id) => {
    setWhatOpen(1);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpen(true);
    });
    //
  };
  const closeModal = (event) => {
    setWhatOpen(-1);
    setIsOpen(false);
  };

  const openModalKarjadesh = (id) => {
    setWhatOpen(2);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpenKarjadesh(true);
    });
    //
  };
  const closeModalKarjadesh = (event) => {
    setWhatOpen(-1);
    setIsOpenKarjadesh(false);
  };

  const openInvoiceModal = (id) => {
    setWhatOpen(3);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpenInvoice(true);
    });
    //
  };
  const closeInvoiceModal = (event) => {
    setWhatOpen(-1);
    setIsOpenInvoice(false);
  };


const handleClickRemove = (values) => {
  const data = {
      invoiceNo: values.siInvoiceNo ? values.siInvoiceNo : "empty",
      productId: values.product && values.product.id ? values.product.id : 0,
      containerNo: values.packNo ? values.packNo : 0,
  };
  
  dispatch(removeBarCodeDataByParamsAction(data.invoiceNo, data.productId, data.containerNo))
  .then((res) => {
      setIsOpenSticker(true);
  });
};

// Updated onClick handler for the Remove button
const handleRemoveButtonClick = (values) => {
  setConfirmDialog({
    isOpen: true,
    title: "Are you sure that you want to delete this data?",
    subTitle: "Once it is deleted, you can't restore!",
    onConfirm: () => {
      handleClickRemove(values);
      setConfirmDialog({ isOpen: false });
    },
  });
};

const openStickerModal = (values) => {
    setWhatOpen(5);
    let data = {
        invoiceNo: values.siInvoiceNo ? values.siInvoiceNo : "empty", // If siInvoiceNo is null, set invoiceNo to "empty"
        productId: values.product && values.product.id ? values.product.id : 0, // If product id is null or product itself is null, set productId to 0
        containerNo: values.packNo ? values.packNo : 0, // If packNo is null, set containerNo to 0
      };
      dispatch(listPrintBarcodeInfo(data)).then((res) => {
        setIsOpenSticker(true);
      });
  };
  const closeStickerModal = (event) => {
    setWhatOpen(-1);
    setIsOpenSticker(false);
  };

 
  const columns_part_a = [
    {
      field: "siInvoiceNo",
      headerName: "Invoice No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "systemDate",
      headerName: "Entry Date",
      headerAlign: "center",
      align: "center",
    },
    
    {
      field: "generatedBarcodeData",
      headerName: "Barcode",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "siSku",
      headerName: "Container No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "productName",
      headerName: "Product Name",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "packSize",
      headerName: "Pack Size",
      headerAlign: "center",
      align: "center",
    },
    
    {
        field: "packTypeName",
        headerName: "Pack Type",
        headerAlign: "center",
        align: "center",
      },
      
      {
        field: "productId",
        headerName: "Product Id",
        headerAlign: "center",
        align: "center",
      },
     
      
  ];



  const columns_part_b_O_3 = [
   
    {
      field: "actions5",
      headerName: "STICKER",
      headerClassName: "sticker-header",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="STICKER"
            focuselementref={selectedOrderRef}
            startIcon={
              <StickyNote2Icon
                fontSize="small"
                style={{ color: "#FFD43B", fontSize: "28px" }}
              />
            }
            text="STICKER"
            color="success"
            onClick={() => {
              openStickerModal(row.soSystemId);
            }}
            style={{ width: "fit-content" }}
          />
        );
      },
    },
    {
      field: "actions2",
      headerName: "PACKING LIST",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PACKING LIST"
            focuselementref={selectedOrderRef}
            startIcon={<PrintIcon fontSize="small" />}
            text="PACKING LIST"
            color="secondary"
            onClick={() => {
              openModalKarjadesh(row.soSystemId);
            }}
          />
        );
      },
    },
    
  ];

  const isOptionEqualToValue = useCallback(
    (option, value) => option.value === value.value
  );
  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("product", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
 
  return (

    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title={<span style={{ color: 'green', fontSize: '24px' }}>Stock In Barcode Execution Area</span>}
        subtitle="Generate Inatact Packing Barcode here......"
      />

      {loadingDelete && <>loading..</>}
      {
        <>
        
          <Paper className={`${classes.pageContent2} paperdesign1`}>
          <div style={{ textAlign: "right", marginBottom: "20px" }}>
            <Button variant="contained" size="large" onClick={handleClick}>
              Create Intact Barcode
            </Button>
          </div>
      
            <Form onSubmit={formik.handleSubmit}>
              <Grid container>
            
                <Grid item xs={12} md={3}>
                  <Input
                    label="Invoice Number"
                    name="siInvoiceNo"
                    value={formik.values.siInvoiceNo}
                    onChange={formik.handleChange}
                  />
                </Grid>
                
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    size="small"
                    disabled={loadingCustomer ? true : false}
                    id="combo-box-demo-channel"
                    // isOptionEqualToValue={isOptionEqualToValue}
                    onChange={customerChange}
                    value={formik.values.product}
                    options={productNameList !== undefined ? productNameList : []}
                    onOpen={formik.handleBlur}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Product Name"
                        name="product"
                        error={
                          formik.errors.product !== undefined
                            ? formik.errors.product.id
                            : ""
                        }
                        touched={
                          formik.touched.product !== undefined
                            ? formik.touched.product
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Input
                    label="Container Number"
                    name="packNo"
                    value={formik.values.packNo}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ButtonGroup className={classes.submitButtonGroup} style={{ justifyContent: 'space-between' }}>
                        <MuiButton
                        startIcon={
                            loadingList ? (
                            <CircularProgress size="1rem" color="error" />
                            ) : (
                            <SearchIcon />
                            )
                        }
                        size="large"
                        type="submit"
                        >
                        Search
                        </MuiButton>
                        <MuiButton
                        size="small"
                        startIcon={<ReplayIcon />}
                        onClick={() => {
                            formik.resetForm();
                        }}
                        />
                        <MuiButton
                        size="large"
                        style={{ backgroundColor: 'green', color: 'white' }} // Set background color to green and text color to white
                        startIcon={<PrintIcon />}
                        onClick={() => {
                            //openStickerModal("actions5");
                           // openStickerModal(row.siInvoiceNo);
                           openStickerModal(formik.values);
                        }}
                        >
                        Print
                        </MuiButton>
        
                        <MuiButton
                          size="large"
                          style={{ backgroundColor: 'red', color: 'white' }} // Set background color to green and text color to white
                          startIcon={<NotInterestedRoundedIcon />}
                          onClick={() => {
                            //handleClickRemove(formik.values);
                            handleRemoveButtonClick(formik.values);

                          }}
                          >
                              Remove
                          </MuiButton>
                    </ButtonGroup>
                    </Grid>
                    
              </Grid>
            </Form>
          </Paper>
          {error === "Network Error" ? (
            <div className={classes.wrapperWhite}>
              <Toolbar
                className={classes.toolBar}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  className="m-4"
                  src={process.env.PUBLIC_URL + "/images/networkerror.png"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "500px", height: "auto" }}
                />
              </Toolbar>
            </div>
          ) : loadingList ? (
            <div
              className="text-center"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <InfinitySpin width="200" color="#6C4AB6" />
            
            </div>
          ) : (
            <div className={classes.wrapperWhite}>
              {gridBasedOnSalesOrderType()}
              
             
             
              {JSON.stringify(printbarcode) !== "{}" &&
                
                whatOpen === 5 && (
                  <BarcodeModal
                    showModal={isOpenSticker}
                    closeModal={closeStickerModal}
                    item_info={printbarcode}
                  />
                )}

              <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
              <Notification notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
            </div>
          )}
        </>
      }
    </div>
  );
};

export default PrintBarcode;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Toolbar,
  Button as MuiButton,
} from "@mui/material";

import { Box, Typography, IconButton, Tooltip } from "@mui/material";

import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import InventoryIcon from "@mui/icons-material/Inventory";

import Popup from "../../../core/Popup";
import Notification from "../../../core/Notification";
import ConfirmDialog from "../../../core/ConfirmDialog";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "../../../core/controls/Button";
import {
  escalateSalesOrderInternal,
  listSalesOrderListForPackingAndEditAction,
  listSalesOrderInternalDetails,
} from "../../../redux/actions/invoiceActions";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import { Form } from "react-bootstrap";
import {
  //SALESORDERINTERNAL_CREATE_RESET,
  CREATE_PACKING_STICKER_RESET,
  //SALESORDERINTERNAL_PACKAGE_DONE_RESET,
  //SALESORDERINTERNAL_UPDATE_RESET,
  UPDATE_PACKING_STICKER_RESET,
} from "../../../redux/constants/invoiceConstants";
import SalesOrderInternalModal from "../../../core/SalesOrderInternalModal";

// Styles
const useStyles = makeStyles(() => ({
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#91B1A0",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "270px !important",
      maxWidth: "270px !important",
    },
    "& .MuiDataGrid-cell": {
      minWidth: "270px !important",
      maxWidth: "270px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#91B1A0",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='soSystemId'], .MuiDataGrid-cell[data-field='soSystemId']":
      {
        // minWidth: "60px !important",
        // maxWidth: "60px !important",
        display: "none",
      },
    "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3']":
      {
        minWidth: "100px !important",
        maxWidth: "100px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#91B1A0",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    "& div:nth-child(1)": {
      margin: "15px",
    },
    [useTheme().breakpoints.up("md")]: {
      display: "flex !important",
      justifyContent: "space-between !important",
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
      "& div:nth-child(1)": {
        margin: "0px",
      },
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  actions2: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "right",
      width: "420px",
    },
  },
  actions3: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "left",
      width: "420px",
    },
  },
}));

const SalesOrderPackageStickerList = ({ history }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  // State and selector variables
    
  const listOrderForPackingAndEditState = useSelector(
    (state) => state.listOrderForPackingAndEditState
  );
  const { loading, error, orderList } = listOrderForPackingAndEditState;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

  const salesOrderInternalPackageDone = useSelector(
    (state) => state.salesOrderInternalPackageDone
  );
  const {
    loading: loadingPackageDone,
    error: errorPackageDone,
    success: successPackageDone,
    packageDoneSalesOrder,
  } = salesOrderInternalPackageDone;

  const employeeCreate = useSelector((state) => state.employeeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    employee: createdProduct,
  } = employeeCreate;

  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = employeeUpdate;

  
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;
// Local state variables
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("2");
  const [dataForEscalation, setDataForEscalation] = useState([]);
   // Refs
  const selectedOrderRef = useRef(null);
// Function to generate random characters
  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };
  
  // useEffect to fetch data and handle success scenarios

  useEffect(() => {
    if (userInfo) {
      dispatch(listSalesOrderListForPackingAndEditAction(selectedOrder));
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: UPDATE_PACKING_STICKER_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: CREATE_PACKING_STICKER_RESET });
      }

      if (successPackageDone) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Packaged Successfully",
          type: "error",
        });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setDataForEscalation([]);

        //dispatch({ type: SALESORDERINTERNAL_PACKAGE_DONE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/salesorderinternal" } },
      };
      history.push(location);
    }

  }, [
    dispatch,
    classes,
    history,
    userInfo,
    successUpdate,
    selectedOrder,
    successPackageDone,
  ]);

  const handleEvent = (params) => {
  };
// Event handler for DataGrid row click

  const buttonsBasedOnSalesOrderType = () => {
    console.log("selectedOrder");
    console.log(selectedOrder);
    if (selectedOrder === "0") {
      return (
        <Box className={classes.actions}>
          <Button
            startIcon={<AddIcon fontSize="small" />}
            text="Add New"
            color="primary"
            onClick={() => {
              history.push("/salesorderinternal/create");
            }}
          />
          <Button
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/edit`);
              }
            }}
          />
          {/* <Button
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              if (dataForEscalation.length > 0) {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure that you want to escalate this data?",
                  subTitle: "This data will be sent to the operation team!",
                  onConfirm: () => {
                    dispatch(escalateSalesOrderInternal(dataForEscalation));
                  },
                });
              } else {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "No Data is slected!",
                  isOpen: true,
                });
              }
            }}
          /> */}
        </Box>
      );
    } else if (selectedOrder === "1") {
      // console.log("Hello");
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<TelegramIcon fontSize="small" />}
            text="ACTION"
            color="success"
            onClick={() => {
              // alert("clicked");
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/action`);
              }
            }}
          />
        </Box>
      );
    } else if (selectedOrder === "2") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<InventoryIcon fontSize="small" />}
            text="PACKAGING"
            color="success"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                //Global package
                history.push(`/stockoutpackagesticker/${rowId}/definepacking`);
                //history.push(`/salesorderinternal/${rowId}/package`);
                //history.push(`/salesorderinternal/${rowId}/package`);
              }
            }}
          />
        </Box>
      );
    } else if (selectedOrder === "3") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                //history.push(`/packagelist/done/${rowId}/edit`);
                history.push(`/stockoutpackagesticker/${rowId}/editdefinepacking`);
              }
            }}
          />
        </Box>
      );
    }
  };
 // Function to render DataGrid based on selectedOrder
  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={orderList}
          columns={
            selectedOrder === "0"
              ? [...columns_part_a, ...columns_part_b_O_0]
              : selectedOrder === "1"
              ? [...columns_part_a, ...columns_part_b_O_1]
              : selectedOrder === "2"
              ? [...columns_part_b_O_2, ...columns_part_a]
              : selectedOrder === "3"
              ? [...columns_part_b_O_3, ...columns_part_a]
              : columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.soSystemId + "-" + generateRandom()}
          onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
  };
// Handler for row selection in DataGrid
  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      if (selectedOrder === "0") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
      orderList.find((row) => row.soSystemId === id)
      );
      setDataForEscalation([selectedRowsData[0].soSystemId]);
      setRowId(selectedRowsData[0].soSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

    // Handler for employee deletion
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    
    setRowClicked(false);
    setFlag(false);
  };
 // Function to open SalesOrderInternalModal
  const openModal = (id) => {
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpen(true);
    });
    //
  };
  // Function to close SalesOrderInternalModal
  const closeModal = (event) => setIsOpen(false);
  // Columns definitions
  const columns_part_a = [
    {
      field: "soSystemNo",
      headerName: "Order No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "invoiceDate",
      headerName: "Order Date",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerName || params.row.supplierName,
    },
    {
      field: "customerAddress",
      headerName: "Customer Address",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerAddress || params.row.supplierAddress,
    },

    {
      field: "jobType",
      headerName: "Order Type",
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "soOrderFrom",
    //   headerName: "Order From",
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "soSystemId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
  ];

  const columns_part_b_O_0 = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              history.push(`/salesorderinternal/${row.soSystemId}/edit`);
            }}
          />
        );
      },
    },

    {
      field: "actions2",
      headerName: "ESCALATE",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to escalate this data?",
                subTitle: "This data will be sent to the operation team!",
                onConfirm: () => {
                  dispatch(escalateSalesOrderInternal([row.soSystemId]));
                },
              });
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_1 = [
    {
      field: "actions",
      headerName: "PUSH FOR PACKAGING",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="PUSH FOR PACKAGING"
            focuselementref={selectedOrderRef}
            startIcon={<TelegramIcon fontSize="small" />}
            text="PUSH FOR PACKAGING"
            color="success"
            onClick={() => {
              //history.push(`/salesorderinternal/${row.soSystemId}/action`);
              history.push(`/stockoutpackagesticker/${row.soSystemId}/definepacking`);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_2 = [
    {
      field: "actions",
      headerName: "PACKAGING",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PACKAGING"
            focuselementref={selectedOrderRef}
            startIcon={<InventoryIcon fontSize="small" />}
            text="PACKAGING"
            color="secondary"
            onClick={() => {
              history.push(`/stockoutpackagesticker/${row.soSystemId}/definepacking`);
              //history.push(`/salesorderinternal/${row.soSystemId}/package`);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_3 = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="EDIT"
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="EDIT"
            color="secondary"
            onClick={() => {
              //history.push(`/packagelist/done/${row.soSystemId}/edit`);
              ///stockoutpackagesticker/:id/editdefinepacking
              history.push(`/stockoutpackagesticker/${row.soSystemId}/editdefinepacking`);

            }}
          />
        );
      },
    },
  ];

    // Main component rendering
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Packaging Information"
        subtitle={
          selectedOrder === "2"
            ? "See the total Sales Packaging Pending List"
            : "See the total Sales Packaging Done List"
        }
      />
 
      {error ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#91B1A0" />
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <Box className={classes.actions3}>
              <Button
                // startIcon={<AddIcon fontSize="small" />}
                text="PACKAGE PENDING LIST"
                color="primary"
                onClick={() => {
                  setSelectedOrder("2");
                  setRowId(null);
                  setRowClicked(false);
                }}
                disabled={selectedOrder === "2" ? true : false}
              />
              <Button
                text="PACKAGE EDIT"
                color="error"
                onClick={() => {
                  setSelectedOrder("3");
                  setRowId(null);
                  setRowClicked(false);
                }}
                disabled={selectedOrder === "3" ? true : false}
              />
            </Box>
            {buttonsBasedOnSalesOrderType()}
          </Toolbar>
          {gridBasedOnSalesOrderType()}
          {JSON.stringify(salesorderinternalsingle) !== "{}" && (
            <SalesOrderInternalModal
              showModal={isOpen}
              closeModal={closeModal}
              info={salesorderinternalsingle}
              items={salesorderinternalsingle.stockOutItemsData}
              currency={"TK"}
              subTotal={
                salesorderinternalsingle.stockOutBillData.calculatedBill
              }
              taxAmmount={
                salesorderinternalsingle.stockOutBillData.billVatPercantage
              }
              discountAmmount={
                salesorderinternalsingle.stockOutBillData.billDiscountAmount
              }
              total={salesorderinternalsingle.stockOutBillData.finalBill}
            />
          )}
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </div>
      )}
    </div>
  );
};
export default SalesOrderPackageStickerList;

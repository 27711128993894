import {
  CHECKLIST_COMPLETE_FAIL,
  CHECKLIST_COMPLETE_REQUEST,
  CHECKLIST_COMPLETE_RESET,
  CHECKLIST_COMPLETE_SUCCESS,
  CHECK_DUPLI_LIST_FAIL,
  CHECK_DUPLI_LIST_REQUEST,
  CHECK_DUPLI_LIST_SUCCESS,
  CHECK_LIST_FAIL,
  CHECK_LIST_REQUEST,
  CHECK_LIST_SUCCESS,
  EMPLOYEE_TASK_LIST_FAIL,
  EMPLOYEE_TASK_LIST_REQUEST,
  EMPLOYEE_TASK_LIST_RESET,
  EMPLOYEE_TASK_LIST_SUCCESS,
  LOCATION_LIST_FAIL,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  TASK_CREATE_FAIL,
  TASK_CREATE_REQUEST,
  TASK_CREATE_RESET,
  TASK_CREATE_SUCCESS,
  TASK_DETAILS_FAIL,
  TASK_DETAILS_REQUEST,
  TASK_DETAILS_RESET,
  TASK_DETAILS_SUCCESS,
  TASK_END_FAIL,
  TASK_END_REQUEST,
  TASK_END_RESET,
  TASK_END_SUCCESS,
  TASK_INCOMPLETE_LIST_FAIL,
  TASK_INCOMPLETE_LIST_REQUEST,
  TASK_INCOMPLETE_LIST_SUCCESS,
  TASK_JOB_TYPE_LIST_FAIL,
  TASK_JOB_TYPE_LIST_REQUEST,
  TASK_JOB_TYPE_LIST_SUCCESS,
  TASK_NAME_FAIL,
  TASK_NAME_REQUEST,
  TASK_NAME_RESET,
  TASK_NAME_SUCCESS,
  TASK_PENDING_CHECKLIST_DETAILS_FAIL,
  TASK_PENDING_CHECKLIST_DETAILS_REQUEST,
  TASK_PENDING_CHECKLIST_DETAILS_RESET,
  TASK_PENDING_CHECKLIST_DETAILS_SUCCESS,
  TASK_START_FAIL,
  TASK_START_REQUEST,
  TASK_START_RESET,
  TASK_START_SUCCESS,
  TASK_UNASSIGNED_LIST_FAIL,
  TASK_UNASSIGNED_LIST_REQUEST,
  TASK_UNASSIGNED_LIST_SUCCESS,
  TASK_UPDATE_FAIL,
  TASK_UPDATE_REQUEST,
  TASK_UPDATE_RESET,
  TASK_UPDATE_SUCCESS,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_CREATE_REQUEST,
  TRANSPORT_CREATE_RESET,
  TRANSPORT_CREATE_SUCCESS,
  TRANSPORT_DELETE_FAIL,
  TRANSPORT_DELETE_REQUEST,
  TRANSPORT_DELETE_RESET,
  TRANSPORT_DELETE_SUCCESS,
  TRANSPORT_END_LOCATION_CREATE_FAIL,
  TRANSPORT_END_LOCATION_CREATE_REQUEST,
  TRANSPORT_END_LOCATION_CREATE_RESET,
  TRANSPORT_END_LOCATION_CREATE_SUCCESS,
  TRANSPORT_UPDATE_FAIL,
  TRANSPORT_UPDATE_REQUEST,
  TRANSPORT_UPDATE_RESET,
  TRANSPORT_UPDATE_SUCCESS,
  VEHICLE_LIST_FAIL,
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
} from "../constants/taskConstants";

export const TaskJobTypeListReducer = (state = { jobTypes: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case TASK_JOB_TYPE_LIST_REQUEST:
      return { loading: true };
    case TASK_JOB_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        jobTypes: action.payload,
      };
    case TASK_JOB_TYPE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const TaskCheckListReducer = (state = { checks: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case CHECK_LIST_REQUEST:
      return { loading: true };
    case CHECK_LIST_SUCCESS:
      return {
        loading: false,
        checks: action.payload,
      };
    case CHECK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const TaskCheckListReducerDupli = (
  state = { checksDupli: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case CHECK_DUPLI_LIST_REQUEST:
      return { loading: true };
    case CHECK_DUPLI_LIST_SUCCESS:
      return {
        loading: false,
        checksDupli: action.payload,
      };
    case CHECK_DUPLI_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const TaskDetailsReducer = (state = { taskSingle: {} }, action) => {
  switch (action.type) {
    case TASK_DETAILS_REQUEST:
      return { loading: true, ...state };
    case TASK_DETAILS_SUCCESS:
      return { loading: false, taskSingle: action.payload };
    case TASK_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case TASK_DETAILS_RESET:
      return { taskSingle: {} };

    default:
      return state;
  }
};

export const ListTaskPendingChecklistDetailsesreReducer = (state = { taskPendingChecklistSingle: {} }, action) => {
  switch (action.type) {
    case TASK_PENDING_CHECKLIST_DETAILS_REQUEST:
      return { loading: true, ...state };
    case TASK_PENDING_CHECKLIST_DETAILS_SUCCESS:
      return { loading: false, taskPendingChecklistSingle: action.payload };
    case TASK_PENDING_CHECKLIST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case TASK_PENDING_CHECKLIST_DETAILS_RESET:
      return { taskPendingChecklistSingle: {} };

    default:
      return state;
  }
};

export const TaskCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_CREATE_REQUEST:
      return { loading: true };
    case TASK_CREATE_SUCCESS:
      return { loading: false, success: true, task: action.payload };
    case TASK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TASK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const TaskUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_UPDATE_REQUEST:
      return { loading: true };
    case TASK_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TASK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TASK_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const EmployeeTaskListReducer = (
  state = { employeetasks: [] },
  action
) => {
  switch (action.type) {
    case EMPLOYEE_TASK_LIST_REQUEST:
      return { loading: true, ...state };
    case EMPLOYEE_TASK_LIST_SUCCESS:
      return { loading: false, employeetasks: action.payload };
    case EMPLOYEE_TASK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_TASK_LIST_RESET:
      return { employeetasks: [] };

    default:
      return state;
  }
};

export const VehicleListReducer = (state = { vehicles: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case VEHICLE_LIST_REQUEST:
      return { loading: true };
    case VEHICLE_LIST_SUCCESS:
      return {
        loading: false,
        vehicles: action.payload,
      };
    case VEHICLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const LocationListReducer = (state = { locations: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case LOCATION_LIST_REQUEST:
      return { loading: true };
    case LOCATION_LIST_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
      };
    case LOCATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const TransportCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSPORT_CREATE_REQUEST:
      return { loading: true };
    case TRANSPORT_CREATE_SUCCESS:
      return { loading: false, success: true, task: action.payload };
    case TRANSPORT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const TransportUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSPORT_UPDATE_REQUEST:
      return { loading: true };
    case TRANSPORT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TRANSPORT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const TransportDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSPORT_DELETE_REQUEST:
      return { loading: true };
    case TRANSPORT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TRANSPORT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const TransportEndLocationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSPORT_END_LOCATION_CREATE_REQUEST:
      return { loading: true };
    case TRANSPORT_END_LOCATION_CREATE_SUCCESS:
      return { loading: false, success: true, transportEnd: action.payload };
    case TRANSPORT_END_LOCATION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_END_LOCATION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const TaskStartReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_START_REQUEST:
      return { loading: true };
    case TASK_START_SUCCESS:
      return { loading: false, success: true, taskStartData: action.payload };
    case TASK_START_FAIL:
      return { loading: false, error: action.payload };
    case TASK_START_RESET:
      return {};
    default:
      return state;
  }
};

export const TaskEndReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_END_REQUEST:
      return { loading: true };
    case TASK_END_SUCCESS:
      return { loading: false, success: true, taskEndData: action.payload };
    case TASK_END_FAIL:
      return { loading: false, error: action.payload };
    case TASK_END_RESET:
      return {};
    default:
      return state;
  }
};

export const CheckListCompleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_COMPLETE_REQUEST:
      return { loading: true };
    case CHECKLIST_COMPLETE_SUCCESS:
      return {
        loading: false,
        success: true,
        completedchecklist: action.payload,
      };
    case CHECKLIST_COMPLETE_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_COMPLETE_RESET:
      return {};
    default:
      return state;
  }
};

export const TaskNameReducer = (state = { tasknamesingle: "" }, action) => {
  switch (action.type) {
    case TASK_NAME_REQUEST:
      return { loading: true, ...state };
    case TASK_NAME_SUCCESS:
      return { loading: false, tasknamesingle: action.payload };
    case TASK_NAME_FAIL:
      return { loading: false, error: action.payload };
    case TASK_NAME_RESET:
      return { tasknamesingle: "" };

    default:
      return state;
  }
};

export const TaskIncompleteListReducer = (
  state = { incompletetasks: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case TASK_INCOMPLETE_LIST_REQUEST:
      return { loading: true };
    case TASK_INCOMPLETE_LIST_SUCCESS:
      return {
        loading: false,
        incompletetasks: action.payload,
      };
    case TASK_INCOMPLETE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const TaskUnAssignedListReducer = (
  state = { unassignedTasks: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case TASK_UNASSIGNED_LIST_REQUEST:
      return { loading: true };
    case TASK_UNASSIGNED_LIST_SUCCESS:
      return {
        loading: false,
        unassignedTasks: action.payload,
      };
    case TASK_UNASSIGNED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

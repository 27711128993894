import {
  GET_CHANNEL_LIST_FAIL,
  GET_CHANNEL_LIST_REQUEST,
  GET_CHANNEL_LIST_SUCCESS,
  GET_CURRENT_STOCK_FAIL,
  GET_CURRENT_STOCK_REQUEST,
  GET_CURRENT_STOCK_RESET,
  GET_CURRENT_STOCK_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_REFERENCE_LIST_FAIL,
  GET_REFERENCE_LIST_REQUEST,
  GET_REFERENCE_LIST_SUCCESS,
  GET_STOCK_ADJUSTMENT_FAIL,
  GET_STOCK_ADJUSTMENT_REQUEST,
  GET_STOCK_ADJUSTMENT_RESET,
  GET_STOCK_ADJUSTMENT_SUCCESS,
} from "../constants/CurrentStockReportConstants";
import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAIL,
} from "../constants/customerConstants";


//get productsName list
export const getCustomersListReducer = (
  state = { customerNameList: [] },
  action
) => {
  //  console.log(action.payload);
  switch (action.type) {
    case CUSTOMER_LIST_REQUEST:
      return { loading: true };
    case CUSTOMER_LIST_SUCCESS:
      return {
        loading: false,
        customerNameList: action.payload,
      };
    case CUSTOMER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//get productsName list
export const getProductsListReducer = (
  state = { productNameList: [] },
  action
) => {
  //  console.log(action.payload);
  switch (action.type) {
    case GET_PRODUCT_LIST_REQUEST:
      return { loading: true };
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        productNameList: action.payload,
      };
    case GET_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//get channelsName list
export const getChannelsListReducer = (
  state = { channelNameList: [] },
  action
) => {
  //  console.log(action.payload);
  switch (action.type) {
    case GET_CHANNEL_LIST_REQUEST:
      return { loading: true };
    case GET_CHANNEL_LIST_SUCCESS:
      return {
        loading: false,
        channelNameList: action.payload,
      };
    case GET_CHANNEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//get current stock
export const getCurrentStockReducer = (
  state = { currentStockList: [] },
  action
) => {
  //  console.log(action.payload);
  switch (action.type) {
    case GET_CURRENT_STOCK_REQUEST:
      return { loading: true };
    case GET_CURRENT_STOCK_SUCCESS:
      return {
        loading: false,
        currentStockList: action.payload,
      };
    case GET_CURRENT_STOCK_FAIL:
      return { loading: false, error: action.payload };

    case GET_CURRENT_STOCK_RESET:
      return [];
    default:
      return state;
  }
};

export const getReferenceListReducer = (
  state = { referenceList: [] },
  action
) => {
  //  console.log(action.payload);
  switch (action.type) {
    case GET_REFERENCE_LIST_REQUEST:
      return { loading: true };
    case GET_REFERENCE_LIST_SUCCESS:
      return {
        loading: false,
        referenceList: action.payload,
      };
    case GET_REFERENCE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getCurrentStockAdjustmentReducer = (
  state = { currentStockAdjustmentList: [] },
  action
) => {
  //  console.log(action.payload);
  switch (action.type) {
    case GET_STOCK_ADJUSTMENT_REQUEST:
      return { loading: true };
    case GET_STOCK_ADJUSTMENT_SUCCESS:
      return {
        loading: false,
        currentStockAdjustmentList: action.payload,
      };
    case GET_STOCK_ADJUSTMENT_FAIL:
      return { loading: false, error: action.payload };

    case GET_STOCK_ADJUSTMENT_RESET:
      return [];
    default:
      return state;
  }
};

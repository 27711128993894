export const INVOICE_PRODUCT_LIST_REQUEST = "INVOICE_PRODUCT_LIST_REQUEST";
export const INVOICE_PRODUCT_LIST_SUCCESS = "INVOICE_PRODUCT_LIST_SUCCESS";
export const INVOICE_PRODUCT_LIST_FAIL = "INVOICE_PRODUCT_LIST_FAIL";

export const INVOICE_DUPLI_PRODUCT_LIST_REQUEST =
  "INVOICE_DUPLI_PRODUCT_LIST_REQUEST";
export const INVOICE_DUPLI_PRODUCT_LIST_SUCCESS =
  "INVOICE_DUPLI_PRODUCT_LIST_SUCCESS";
export const INVOICE_DUPLI_PRODUCT_LIST_FAIL =
  "INVOICE_DUPLI_PRODUCT_LIST_FAIL";

export const ORDER_TYPE_LIST_REQUEST = "ORDER_TYPE_LIST_REQUEST";
export const ORDER_TYPE_LIST_SUCCESS = "ORDER_TYPE_LIST_SUCCESS";
export const ORDER_TYPE_LIST_FAIL = "ORDER_TYPE_LIST_FAIL";

export const ORDER_FROM_LIST_REQUEST = "ORDER_FROM_LIST_REQUEST";
export const ORDER_FROM_LIST_SUCCESS = "ORDER_FROM_LIST_SUCCESS";
export const ORDER_FROM_LIST_FAIL = "ORDER_FROM_LIST_FAIL";

export const SALESORDERINTERNAL_CREATE_REQUEST =
  "SALESORDERINTERNAL_CREATE_REQUEST";
export const SALESORDERINTERNAL_CREATE_SUCCESS =
  "SALESORDERINTERNAL_CREATE_SUCCESS";
export const SALESORDERINTERNAL_CREATE_FAIL = "SALESORDERINTERNAL_CREATE_FAIL";
export const SALESORDERINTERNAL_CREATE_RESET =
  "SALESORDERINTERNAL_CREATE_RESET";

export const SALESORDERINTERNAL_UPDATE_REQUEST =
  "SALESORDERINTERNAL_UPDATE_REQUEST";
export const SALESORDERINTERNAL_UPDATE_SUCCESS =
  "SALESORDERINTERNAL_UPDATE_SUCCESS";
export const SALESORDERINTERNAL_UPDATE_FAIL = "SALESORDERINTERNAL_UPDATE_FAIL";
export const SALESORDERINTERNAL_UPDATE_RESET =
  "SALESORDERINTERNAL_UPDATE_RESET";

export const SALESORDERINTERNAL_PACKAGE_REQUEST =
  "SALESORDERINTERNAL_PACKAGE_REQUEST";
export const SALESORDERINTERNAL_PACKAGE_SUCCESS =
  "SALESORDERINTERNAL_PACKAGE_SUCCESS";
export const SALESORDERINTERNAL_PACKAGE_FAIL =
  "SALESORDERINTERNAL_PACKAGE_FAIL";
export const SALESORDERINTERNAL_PACKAGE_RESET =
  "SALESORDERINTERNAL_PACKAGE_RESET";

export const SALESORDERINTERNAL_LIST_REQUEST =
  "SALESORDERINTERNAL_LIST_REQUEST";
export const SALESORDERINTERNAL_LIST_SUCCESS =
  "SALESORDERINTERNAL_LIST_SUCCESS";
export const SALESORDERINTERNAL_LIST_FAIL = "SALESORDERINTERNAL_LIST_FAIL";

export const SALESORDERINTERNAL_FILTER_LIST_REQUEST =
  "SALESORDERINTERNAL_FILTER_LIST_REQUEST";
export const SALESORDERINTERNAL_FILTER_LIST_SUCCESS =
  "SALESORDERINTERNAL_FILTER_LIST_SUCCESS";
export const SALESORDERINTERNAL_FILTER_LIST_FAIL =
  "SALESORDERINTERNAL_FILTER_LIST_FAIL";
export const SALESORDERINTERNAL_FILTER_LIST_RESET =
  "SALESORDERINTERNAL_FILTER_LIST_RESET";

export const TALLYSALES_CREATE_REQUEST = "TALLYSALES_CREATE_REQUEST";
export const TALLYSALES_CREATE_SUCCESS = "TALLYSALES_CREATE_SUCCESS";
export const TALLYSALES_CREATE_FAIL = "TALLYSALES_CREATE_FAIL";
export const TALLYSALES_CREATE_RESET = "TALLYSALES_CREATE_RESET";

export const SALESORDERINTERNAL_DETAILS_REQUEST =
  "SALESORDERINTERNAL_DETAILS_REQUEST";
export const SALESORDERINTERNAL_DETAILS_SUCCESS =
  "SALESORDERINTERNAL_DETAILS_SUCCESS";
export const SALESORDERINTERNAL_DETAILS_FAIL =
  "SALESORDERINTERNAL_DETAILS_FAIL";
export const SALESORDERINTERNAL_DETAILS_RESET =
  "SALESORDERINTERNAL_DETAILS_RESET";

export const BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_REQUEST =
  "BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_REQUEST";
export const BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_SUCCESS =
  "BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_SUCCESS";
export const BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_FAIL =
  "BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_FAIL";
export const BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_RESET =
  "BATCHANDMAPPEDITEMBASEDONPRODUCTID_DETAILS_RESET";

export const SALESORDERINTERNAL_ESCALATION_REQUEST =
  "SALESORDERINTERNAL_ESCALATION_REQUEST";
export const SALESORDERINTERNAL_ESCALATION_SUCCESS =
  "SALESORDERINTERNAL_ESCALATION_SUCCESS";
export const SALESORDERINTERNAL_ESCALATION_FAIL =
  "SALESORDERINTERNAL_ESCALATION_FAIL";
export const SALESORDERINTERNAL_ESCALATION_RESET =
  "SALESORDERINTERNAL_ESCALATION_RESET";

export const SALESORDERINTERNAL_PACKAGE_DONE_REQUEST =
  "SALESORDERINTERNAL_PACKAGE_DONE_REQUEST";
export const SALESORDERINTERNAL_PACKAGE_DONE_SUCCESS =
  "SALESORDERINTERNAL_PACKAGE_DONE_SUCCESS";
export const SALESORDERINTERNAL_PACKAGE_DONE_FAIL =
  "SALESORDERINTERNAL_PACKAGE_DONE_FAIL";
export const SALESORDERINTERNAL_PACKAGE_DONE_RESET =
  "SALESORDERINTERNAL_PACKAGE_DONE_RESET";

export const SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_REQUEST =
  "SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_REQUEST";
export const SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_SUCCESS =
  "SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_SUCCESS";
export const SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_FAIL =
  "SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_FAIL";
export const SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_RESET =
  "SALESORDERINTERNAL_PACKAGE_DONE_DETAILS_RESET";

export const SALESORDERINTERNAL_PACKAGE_DONE_EDIT_REQUEST =
  "SALESORDERINTERNAL_PACKAGE_DONE_EDIT_REQUEST";
export const SALESORDERINTERNAL_PACKAGE_DONE_EDIT_SUCCESS =
  "SALESORDERINTERNAL_PACKAGE_DONE_EDIT_SUCCESS";
export const SALESORDERINTERNAL_PACKAGE_DONE_EDIT_FAIL =
  "SALESORDERINTERNAL_PACKAGE_DONE_EDIT_FAIL";
export const SALESORDERINTERNAL_PACKAGE_DONE_EDIT_RESET =
  "SALESORDERINTERNAL_PACKAGE_DONE_EDIT_RESET";

export const PRICESUMMARY_CUSTOMER_PRODUCT_LIST_REQUEST =
  "PRICESUMMARY_CUSTOMER_PRODUCT_LIST_REQUEST";
export const PRICESUMMARY_CUSTOMER_PRODUCT_LIST_SUCCESS =
  "PRICESUMMARY_CUSTOMER_PRODUCT_LIST_SUCCESS";
export const PRICESUMMARY_CUSTOMER_PRODUCT_LIST_FAIL =
  "PRICESUMMARY_CUSTOMER_PRODUCT_LIST_FAIL";
export const PRICESUMMARY_CUSTOMER_PRODUCT_LIST_RESET =
  "PRICESUMMARY_CUSTOMER_PRODUCT_LIST_RESET";

export const INVOICE_PACK_TYPE_LIST_REQUEST = "INVOICE_PACK_TYPE_LIST_REQUEST";
export const INVOICE_PACK_TYPE_LIST_SUCCESS = "INVOICE_PACK_TYPE_LIST_SUCCESS";
export const INVOICE_PACK_TYPE_LIST_FAIL = "INVOICE_PACK_TYPE_LIST_FAIL";

export const PENDING_LIST_REQUEST = "PENDING_LIST_REQUEST";
export const PENDING_LIST_SUCCESS = "PENDING_LIST_SUCCESS";
export const PENDING_LIST_FAIL = "PENDING_LIST_FAIL";

export const JOB_LIST_SINGLE_REQUEST = "JOB_LIST_SINGLE_REQUEST";
export const JOB_LIST_SINGLE_SUCCESS = "JOB_LIST_SINGLE_SUCCESS";
export const JOB_LIST_SINGLE_FAIL = "JOB_LIST_SINGLE_FAIL";
export const JOB_LIST_SINGLE_RESET = "JOB_LIST_SINGLE_RESET";

export const STOCKOUT_PENDING_UPDATE_REQUEST =
  "STOCKOUT_PENDING_UPDATE_REQUEST";
export const STOCKOUT_PENDING_UPDATE_SUCCESS =
  "STOCKOUT_PENDING_UPDATE_SUCCESS";
export const STOCKOUT_PENDING_UPDATE_FAIL = "STOCKOUT_PENDING_UPDATE_FAIL";
export const STOCKOUT_PENDING_UPDATE_RESET = "STOCKOUT_PENDING_UPDATE_RESET";

//admin edit
export const SALESORDERINTERNAL_PENDING_PRICE_LIST_REQUEST =
  "SALESORDERINTERNAL_PENDING_PRICE_LIST_REQUEST";
export const SALESORDERINTERNAL_PENDING_PRICE_LIST_SUCCESS =
  "SALESORDERINTERNAL_PENDING_PRICE_LIST_SUCCESS";
export const SALESORDERINTERNAL_PENDING_PRICE_LIST_FAIL =
  "SALESORDERINTERNAL_PENDING_PRICE_LIST_FAIL";

//Packing for Sticker starts
export const LIST_ORDER_FOR_PACKING_EIDT_STICKER_REQUEST="LIST_ORDER_FOR_PACKING_EIDT_STICKER_REQUEST";
export const LIST_ORDER_FOR_PACKING_EIDT_STICKER_SUCCESS="LIST_ORDER_FOR_PACKING_EIDT_STICKER_SUCCESS";
export const LIST_ORDER_FOR_PACKING_EIDT_STICKER_FAIL="LIST_ORDER_FOR_PACKING_EIDT_STICKER_FAIL";
export const LIST_ORDER_FOR_PACKING_EIDT_STICKER_RESET="LIST_ORDER_FOR_PACKING_EIDT_STICKER_RESET";


export const DETAILS_EDIT_PACKING_STICKER_REQUEST="DETAILS_EDIT_PACKING_STICKER_REQUEST";
export const DETAILS_EDIT_PACKING_STICKER_SUCCESS="DETAILS_EDIT_PACKING_STICKER_SUCCESS";
export const DETAILS_EDIT_PACKING_STICKER_FAIL="DETAILS_EDIT_PACKING_STICKER_FAIL";
export const DETAILS_EDIT_PACKING_STICKER_RESET="DETAILS_EDIT_PACKING_STICKER_RESET";

export const CREATE_PACKING_STICKER_REQUEST="CREATE_PACKING_STICKER_REQUEST";
export const CREATE_PACKING_STICKER_SUCCESS="CREATE_PACKING_STICKER_SUCCESS";
export const CREATE_PACKING_STICKER_FAIL="CREATE_PACKING_STICKER_FAIL";
export const CREATE_PACKING_STICKER_RESET="CREATE_PACKING_STICKER_RESET";

export const UPDATE_PACKING_STICKER_REQUEST="UPDATE_PACKING_STICKER_REQUEST";
export const UPDATE_PACKING_STICKER_SUCCESS="UPDATE_PACKING_STICKER_SUCCESS";
export const UPDATE_PACKING_STICKER_FAIL="UPDATE_PACKING_STICKER_FAIL";
export const UPDATE_PACKING_STICKER_RESET="UPDATE_PACKING_STICKER_RESET";

//Packing for Sticker End


//Asif
export const LATEST_BATCH_MAP_DETAILS_REQUEST =
  "LATEST_BATCH_MAP_DETAILS_REQUEST";
export const LATEST_BATCH_MAP_DETAILS_SUCCESS =
  "LATEST_BATCH_MAP_DETAILS_SUCCESS";
export const LATEST_BATCH_MAP_DETAILS_FAIL = "LATEST_BATCH_MAP_DETAILS_FAIL";
export const LATEST_BATCH_MAP_DETAILS_RESET = "LATEST_BATCH_MAP_DETAILS_RESET";

import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Button from "../../core/controls/Button";
import { CustomerSchema, SupplierSchema } from "../../schemas";
import { useEffect } from "react";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDesignation,
} from "../../redux/actions/channelActions";
import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  createSupplier,
  deleteSupplier,
  listSupplierDetails,
  updateSupplier,
} from "../../redux/actions/supplierActions";
import ConfirmDialog from "../../core/ConfirmDialog";


const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

const initialValues = {
  supplierId: "",
  supplierName: "",
  supplierAddress: "",
  supplierContactPerson: "",
  supplierContactNumber: "",
  supplierLat: "",
  supplierLong: "",
};

const EditSupplier = ({ match, history }) => {
  const supplierId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  // const { values, setValues, handleChange } = useForm(initialValues);
  const channelList = useSelector((state) => state.channelList);
  const {
    loading: loadingChannels,
    error: errorChannels,
    channels,
  } = channelList;

  const supplierCreate = useSelector((state) => state.supplierCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    supplier: createdSupplier,
  } = supplierCreate;

  const supplierDetails = useSelector((state) => state.supplierDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    supplier,
  } = supplierDetails;

  const supplierUpdate = useSelector((state) => state.supplierUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = supplierUpdate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: SupplierSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch("https://geolocation-db.com/json/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
 
          dispatch(
            updateSupplier({
              supplierId: values.supplierId,
              supplierName: values.supplierName,
              supplierAddress: values.supplierAddress,
              //locationId: values.channel.id,
              supplierContactPerson: values.supplierContactPerson,
              supplierContactNumber: values.supplierContactNumber,
              supplierLat: null,
              supplierLong: null,
            })
          ).then((result) => {
            if (result?.errMsg) {
              // console.log(result);
              if (result.errMsg == "Supplier Already Exist") {
                action.setFieldError("errorClientName", result.errMsg);
              } else if (result.errMsg == "Contact Number Already Exist") {
                action.setFieldError("errorClientPhone", result.errMsg);
              }

              action.setSubmitting(false);
            } else {
              history.push("/suppliers");
            }
          });
        })
        .catch((error) => console.log("error", error));
    },
  });




  const changeValues = () => {

    if (JSON.stringify(supplier) !== "{}" && supplier !== undefined) {
      formik.setValues({
        supplierId: supplier.supplierId || "",
        supplierName: supplier.supplierName || "",
        supplierAddress: supplier.supplierAddress || "",
        supplierContactPerson: supplier.supplierContactPerson || "",
        supplierContactNumber: supplier.supplierContactNumber || "",
        supplierLat: supplier.supplierLat || "",
        supplierLong: supplier.supplierLong || "",
      });

     
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listSupplierDetails(supplierId));
      dispatch(listDesignation());
      dispatch(listChannel());
    } else {
      const location = {
        pathname: "/signin",

        state: { from: { pathname: `/supplier/${supplierId}/edit` } },
      };
      history.push(location);
    }
  }, [supplierId, dispatch, history, userInfo]);

  useEffect(() => {
    changeValues();
  }, [supplier]);

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    dispatch(deleteSupplier(id));
    history.push("/suppliers");
  };


  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Suppliers"
        subtitle="Add a Supplier"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Input
                label="Supplier Name"
                name="supplierName"
                error={formik.errors.supplierName}
                extraError={formik.errors.errorClientName}
                touched={formik.touched.supplierName}
                value={formik.values.supplierName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Supplier Address"
                name="supplierAddress"
                error={formik.errors.supplierAddress}
                touched={formik.touched.supplierAddress}
                value={formik.values.supplierAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Input
                label="Contact Person Name"
                name="supplierContactPerson"
                error={formik.errors.supplierContactPerson}
                touched={formik.touched.supplierContactPerson}
                value={formik.values.supplierContactPerson}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Contact #"
                name="supplierContactNumber"
                error={formik.errors.supplierContactNumber}
                extraError={formik.errors.errorClientPhone}
                touched={formik.touched.supplierContactNumber}
                value={formik.values.supplierContactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              
            </Grid>

            <Grid item xs={12} md={6}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Update
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/suppliers",
                    state: {},
                  };
                  history.push(location);
                }}
              />
              <MuiButton
                onClick={() => {
                  // onDelete(params.row.employeeId);
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure that you want to delete this data?",
                    subTitle: "Once it is deleted, you can't restore!",
                    onConfirm: () => {
                      onDelete(match.params.id);
                    },
                  });
                }}
                color="error"
                startIcon={
                  loadingDetails ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : (
                    <DeleteIcon />
                  )
                }
                size="large"
              >
                Delete
              </MuiButton>
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loadingChannels ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(EditSupplier);

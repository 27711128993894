export const QUOTATION_UNAPPROVED_LIST_REQUEST =
  "QUOTATION_UNAPPROVED_LIST_REQUEST";
export const QUOTATION_UNAPPROVED_LIST_SUCCESS =
  "QUOTATION_UNAPPROVED_LIST_SUCCESS";
export const QUOTATION_UNAPPROVED_LIST_FAIL = "QUOTATION_UNAPPROVED_LIST_FAIL";

export const QUOTATION_APPROVED_LIST_REQUEST =
  "QUOTATION_APPROVED_LIST_REQUEST";
export const QUOTATION_APPROVED_LIST_SUCCESS =
  "QUOTATION_APPROVED_LIST_SUCCESS";
export const QUOTATION_APPROVED_LIST_FAIL = "QUOTATION_APPROVED_LIST_FAIL";

export const QUOTATION_CREATE_REQUEST = "QUOTATION_CREATE_REQUEST";
export const QUOTATION_CREATE_SUCCESS = "QUOTATION_CREATE_SUCCESS";
export const QUOTATION_CREATE_FAIL = "QUOTATION_CREATE_FAIL";
export const QUOTATION_CREATE_RESET = "QUOTATION_CREATE_RESET";

export const QUOTATION_DETAILS_REQUEST = "QUOTATION_DETAILS_REQUEST";
export const QUOTATION_DETAILS_SUCCESS = "QUOTATION_DETAILS_SUCCESS";
export const QUOTATION_DETAILS_FAIL = "QUOTATION_DETAILS_FAIL";
export const QUOTATION_DETAILS_RESET = "QUOTATION_DETAILS_RESET";

export const QUOTATION_UPDATE_REQUEST = "QUOTATION_UPDATE_REQUEST";
export const QUOTATION_UPDATE_SUCCESS = "QUOTATION_UPDATE_SUCCESS";
export const QUOTATION_UPDATE_FAIL = "QUOTATION_UPDATE_FAIL";
export const QUOTATION_UPDATE_RESET = "QUOTATION_UPDATE_RESET";

export const QUOTATION_APPROVE_UPDATE_REQUEST =
  "QUOTATION_APPROVE_UPDATE_REQUEST";
export const QUOTATION_APPROVE_UPDATE_SUCCESS =
  "QUOTATION_APPROVE_UPDATE_SUCCESS";
export const QUOTATION_APPROVE_UPDATE_FAIL = "QUOTATION_APPROVE_UPDATE_FAIL";
export const QUOTATION_APPROVE_UPDATE_RESET = "QUOTATION_APPROVE_UPDATE_RESET";

import {
    GET_DAILY_INVOICE_TRACKING_REQUEST,
    GET_DAILY_INVOICE_TRACKING_SUCCESS,
    GET_DAILY_INVOICE_TRACKING_FAIL,
  } from "../constants/dailyInvoiceTrackingConstants";


  
  //get daily invoice tracking report list
  export const dailyInvoiceTrackingReducer = (
    state = { dailyInvoiceTrackingReports: [] },
    action
  ) => {
    //  console.log(action.payload);
    switch (action.type) {
      case GET_DAILY_INVOICE_TRACKING_REQUEST:
        return { loading: true };
      case GET_DAILY_INVOICE_TRACKING_SUCCESS:
        return {
          loading: false,
          dailyInvoiceTrackingReports: action.payload,
        };
      case GET_DAILY_INVOICE_TRACKING_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import ListIcon from "@mui/icons-material/List";

import { useJsApiLoader } from "@react-google-maps/api";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import {
  listCustomers,
  listCustomersForMap,
} from "../../redux/actions/customerActions";
import { Box, Tooltip, IconButton, Toolbar } from "@mui/material";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";

import Popup from "../../core/Popup";

import { API_URL } from "../../redux/constants/apiConstants";
import { mapOptions } from "../../core/MapConfiguration";
import MarketingMap from "../../core/MarketingMap";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  listEmployees,
  listEmployeesAll,
} from "../../redux/actions/userActions";
import { InfinitySpin } from "react-loader-spinner";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#6C4AB6",
      color: "#ffff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
    },

    // "& .MuiDataGrid-columnHeaderTitle": {
    //   minWidth: "250px !important",
    //   maxWidth: "250px !important",
    //   textAlign: "center",
    // },

    "& .MuiDataGrid-cell": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#6C4AB6",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },
    "& .MuiDataGrid-columnHeader[data-field='customerId'], .MuiDataGrid-cell[data-field='customerId']":
      {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#6C4AB6",
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },

  sectionContainer: {
    width: "300px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    position: "absolute",
    zIndex: 10,
    left: "10px",
    top: "290px",
    borderRadius: "30px",
    boxShadow: `inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3)`,
    padding: "1rem 2rem 1rem 1rem",
    transition: "all 0.5s ease-out",
  },
}));

const MarketingReportMap = ({ history }) => {
  const { isLoaded } = useJsApiLoader({
    id: mapOptions.googleMapApiKey,
    googleMapsApiKey: mapOptions.googleMapApiKey,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const customerMapList = useSelector((state) => state.customerMapList);
  const { loading, error, customersinMap } = customerMapList;

  const employeeAllList = useSelector((state) => state.employeeAllList);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    employeesAll,
  } = employeeAllList;

  console.log(employeesAll);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // console.log(userInfo);

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const getEmployees = async () => {
    // var config = {
    //   method: "get",
    //   url: `${API_URL}/Marketing/GetReportData?userSysId=1013&startDate=${values.from}&endDate=${values.to}&customerId=${values.customer.id}`,
    //   headers: {
    //     Authorization: `Bearer ${userInfo.token}`,
    //     "Content-Type": "application/json",
    //   },
    //   data: "",
    // };
    // var finalGotData = await Axios(config)
    //   .then(function (response) {
    //     return response.data;
    //   })
    //   .catch(function (error) {
    //     return error.response.data;
    //   });
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForMap());
      dispatch(listCustomers());
      dispatch(listEmployeesAll());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/marketing/report/map" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  // const columnGroupingModel = [
  //   {
  //     groupId: "Full name",
  //     children: [{ field: "customerName" }, { field: "customerAddress" }],
  //   },
  // ];

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<ListIcon />}
        title="Customers Location in Map"
        subtitle="This is the customers map location"
      />

      {error === "Network Error" ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <InfinitySpin width="200" color="#6C4AB6" />
          {/* <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#6C4AB6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <div>
            <MarketingMap
              classes={classes}
              userInfo={userInfo}
              loadingEmployee={loadingEmployee}
              employees={employeesAll}
              customersinMap={customersinMap}
              isLoaded={isLoaded}
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              API_URL={API_URL}
            />
          </div>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        </div>
      )}
    </div>
  );
};

export default withRouter(MarketingReportMap);

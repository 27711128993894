import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { API_URL } from "../../redux/constants/apiConstants";
import moment from 'moment/moment';

function PaymentInfo({ customerId, userInfo,customerName,productId }) {
  const [lastPaymentAmount, setLastPaymentAmount] = useState(null);
  const [lastPaymentDate, setLastPaymentDate] = useState(null);
  const [currentStock, setCurrentStock] = useState(null);
  const [credit, setCredit] = useState(null);

 //For current stock
  useEffect(() => {
    if (productId && userInfo) {
      var channelId = jwtDecode(userInfo.token)
      const apiUrl = `${API_URL}/Stock/GetCurrentStock/${productId}/${channelId.ChannelId}`;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          
          setCurrentStock(data.dataObj[0].currentQty)
          
        })
        .catch((error) => console.error(error));
    }
  }, [productId,userInfo]);

  //For credit amount
  useEffect(() => {
    if (customerId && userInfo) {
      const apiUrl = `${API_URL}/Report/GetCustomerCreditSaleSummery/${customerId}`;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          
          setCredit(data.dataObj[0].openingCredit)
          
        })
        .catch((error) => console.error(error));
    }
  }, [customerId,userInfo]);

  //For payment info
  useEffect(() => {
    if (customerId && userInfo) {
      const apiUrl = `${API_URL}/CustomerPayment/GetCustomerLastPayment/${customerId}`;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if(data.dataObj[0].paymentDate != null)
          {
            // const options = { year: 'numeric', month: 'short', day: '2-digit' };
            // var date = new Date(data.dataObj[0].paymentDate).toLocaleDateString('en-GB',options)
            setLastPaymentDate(data.dataObj[0].paymentDate);
          }
          else{
            setLastPaymentAmount("");
          }
          const paymentAmount = data.dataObj[0].paymentAmount !== null ? data.dataObj[0].paymentAmount : 0;
          setLastPaymentAmount(paymentAmount);
          
        })
        .catch((error) => console.error(error));
    }
  }, [customerId, userInfo]);

  return (
    <>
      <p align="center">Current Stock: {currentStock}</p>
      <hr/>
      <div className='ml-2'>
        <p>{customerName}</p>
        <p>Credit: {credit}</p>
        <p>Last payment amount: {lastPaymentAmount}</p>
        <p>Last payment date:  {moment(lastPaymentDate,'YYYY-MM-DDTHH:mm:ss.S').format('DD-MMM-YYYY')}</p>
      </div>
      
    </>
  );
}

export default PaymentInfo;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";

import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";

import { Container, FloatingLabel } from "react-bootstrap";
import InvoiceItem from "../../../core/InvoiceItem";
import CustomTypeaheadSelect from "../../../core/CustomTypeaheadSelect";

import Menu from "../../../core/Menu";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import {
  createSalesOrderInternal,
  FilterProductsForInvoice,
  listBatchAndMappedItemBasedOnProduct,
  listOrderFromInvoice,
  listOrderTypeInvoice,
  listProductsForInvoice,
  listSalesOrderInternalDetails,
  //packageSalesOrderInternal,
  packageSalesOrderAdminInvoiceUpdate
} from "../../../redux/actions/invoiceActions";

import SalesOrderItem from "../../../core/SalesOrderItem";
import InvoiceModal from "../../../core/InvoiceModal";
import { API_URL } from "../../../redux/constants/apiConstants";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../../core/Popup";
import SalesOrderInternalModal from "../../../core/SalesOrderInternalModal";
import { listCategories } from "../../../redux/actions/productActions";
import SuccessPopup from "../../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import { getValues } from "../../../auth";
import SampleItem from "../../../core/SampleItem";
import DPWItem from "../../../core/DPWItem";
import ELItem from "../../../core/ELItem";
import SalesOrderItemWithPriceEdit from "../../../core/SalesOrderItemWithPriceEdit";
var FilteredProduct = [];
let obj = {};

const EscalateSalesOrderInternalWithPriceEdit = ({ match, history }) => {
  const salesOrderId = match.params.id;
  const dispatch = useDispatch();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const batchAndMappedItemListByProductDetails = useSelector(
    (state) => state.batchAndMappedItemListByProductDetails
  );
  const {
    loading: loadingbatchAndMappedItemListByProductDetail,
    error: errorbatchAndMappedItemListByProductDetail,
    batchAndMappedItems,
  } = batchAndMappedItemListByProductDetails;

  // console.log("batchAndMappedItems");
  // console.log(batchAndMappedItems);
  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;
  // console.log("products");
  // console.log(products);

  const invoiceOrderFromList = useSelector(
    (state) => state.invoiceOrderFromList
  );
  const {
    loading: loadingOrderFrom,
    error: errorOrderFrom,
    orderFroms,
  } = invoiceOrderFromList;

  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const salesOrderInternalPackage = useSelector(
    (state) => state.salesOrderInternalPackage
  );
  const {
    loading: loadingPackage,
    error: errorPackage,
    success: successPackage,
    packagedSalesOrder,
  } = salesOrderInternalPackage;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [switchDiscountPercentage, setSwitchDiscountPercentage] =
    useState(false);
  const [switchTOpen, setSwitchTOpen] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [mappingOption, setMappingOption] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState("");

  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "1",
    unitPrice: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0",
    lineTotal: "0.00",
    switchDiscountPercentage: false,
  });
  const [lineTotal, setLineTotal] = useState(0.0);
  const [productVatAmmount, setProductVatAmmount] = useState(0.0);
  const [productAitAmmount, setProductAitAmmount] = useState(0.0);
  const [quantityPrice, setQuantityPrice] = useState(0.0);
  const [vadTotal, setVadTotal] = useState(0.0);
  const [subPurchase, setSubPurchase] = useState(0.0);
  const [vatAmmount, setVatAmmount] = useState(0.0);
  const [aitAmmount, setAitAmmount] = useState(0.0);
  const [discountAmmount, setDiscountAmmount] = useState(0.0);

  const [total, setTotal] = useState(0.0);
  const [selected, setSelected] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [selectedOrderFrom, setSelectedOrderFrom] = useState([]);
  const [batchAndMap, setBatchAndMap] = useState();
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [data, setData] = useState({
    isOpen: false,
    currency: "TK",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    taxRate: "",
    taxAmmount: "0.00",
    vatRate: "",
    vatAmmount: "0.00",
    aitRate: "",
    aitAmmount: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0.00",
    discountRate: "",
    discountAmmount: "0.00",
    adjustmentAmount: "0.00",
    switchDiscountPercentage: false,
    editableField: 1,
    items: [],
  });
  //   uuidv4()
  const users = [
    { id: 1, label: "ann" },
    { id: 2, label: "rudi" },
    { id: 3, label: "rudolph" },
    { id: 3, label: "3meterstoolong" },
  ];

  // console.log("product");
  // console.log(product);

  useEffect(() => {
    if (userInfo) {
      setSwitchTStuck(true);
      dispatch(listProductsForInvoice());
      dispatch(listSalesOrderInternalDetails(salesOrderId));

      dispatch(listCategories());
      dispatch(listCustomersForSignup());

      dispatch(listOrderTypeInvoice());
      dispatch(listOrderFromInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: {
          from: { pathname: `/salesorderinternal/${salesOrderId}/edit` },
        },
      };
      history.push(location);
    }
  }, [salesOrderId, dispatch, history, userInfo]);

  const changeValues = async () => {
    setSwitchTStuck(true);
    if (
      JSON.stringify(salesorderinternalsingle) !== "{}" &&
      salesorderinternalsingle !== undefined
    ) {
      var d = new Date(
        salesorderinternalsingle.stockOutGeneralData.invoiceDate
      );
      d = d.toString();
      // console.log("d");
      // console.log(d);
      d = d.split(" ");
      //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      //   day = ("0" + date.getDate()).slice(-2);
      d = [d[2], d[1], d[3]].join("-");
      setInvoiceDate(d);
      // console.log("salesorderinternalsingle");
      // console.log(salesorderinternalsingle);
      setSelectedCustomer([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soCustomerId,
          label: salesorderinternalsingle.stockOutGeneralData.customerName,
        },
      ]);
      setSelectedOrderType([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soJobTypeId,
          label: salesorderinternalsingle.stockOutGeneralData.jobTypeName,
        },
      ]);
      setSelectedOrderFrom([
        {
          id: salesorderinternalsingle.stockOutGeneralData.soOrderFromId,
          label: salesorderinternalsingle.stockOutGeneralData.soOrderFrom,
        },
      ]);
      //   console.log("salesorderinternalsingle");
      //   console.log(salesorderinternalsingle);
      salesorderinternalsingle.stockOutItemsData.map((single) => {
        if (!FilteredProduct.includes(single.sodProductId)) {
          FilteredProduct.push(single.sodProductId);
        }
      });
      // console.log("FilteredProduct");
      // console.log(FilteredProduct);
      dispatch(FilterProductsForInvoice(FilteredProduct));
      setSwitchTStuck(true);

      // obj = salesorderinternalsingle.stockOutItemsData.find(
      //   (o) => o.sodUnitVatPercantage === 5 && o.sodUnitAitPercantage === 3
      // );
      // //   console.log("obj");
      // //   console.log(obj);
      // if (JSON.stringify(obj) !== "{}" || obj !== undefined) {
      //   // console.log("I entered");
      //   setSwitchT(true);
      //   setProduct({
      //     ...product,
      //     vat: 5 || 0,
      //     ait: 3 || 0,
      //   });
      //   setProductVatAmmount(product.unitPrice * (Math.abs(5) / 100));
      //   setProductAitAmmount(product.unitPrice * (Math.abs(3) / 100));
      // }
      // let salesOrderInternalArray =
      //   salesorderinternalsingle.stockOutItemsData.map((singleData) => {
      //     // console.log("batchAndMap");
      //     // console.log(final);

      //     return {
      //       productId: singleData.sodProductId,
      //       productName: singleData.productName,
      //       quantity: singleData.sodQty || 1.0,
      //       sobUnitDiscountType:
      //         singleData.sobUnitDiscountType === null
      //           ? "F"
      //           : singleData.sobUnitDiscountType,
      //       vat: singleData.sodUnitVatPercantage || 0,
      //       ait: singleData.sodUnitAitPercantage || 0,
      //       unitPrice: singleData.sodUnitPrice || 1,
      //       discountAmmount: singleData.sodUnitDiscountAmount || 0,
      //       lineTotal: singleData.sodFinalCalculatedUnitPrice || 1,
      //       // batchList: final.finalBatch,
      //       // mappedItemList: final.finalMappedItem,
      //     };
      //   });

      // console.log("batchAndMap");
      // console.log(batchAndMap);
      if (batchAndMap !== undefined) {
        setData({
          ...data,
          currency:
            salesorderinternalsingle.stockOutBillData.sobCurrencyName === "BDT"
              ? "TK"
              : "$",
          notes: salesorderinternalsingle.stockOutGeneralData.soRemarks,
          invoiceNumber:
            salesorderinternalsingle.stockOutGeneralData.soSystemNo,

          items: batchAndMap,
          subTotal: salesorderinternalsingle.stockOutBillData.calculatedBill,
          vatRate: salesorderinternalsingle.stockOutBillData.billVatPercantage,
          aitRate: salesorderinternalsingle.stockOutBillData.billAitPercantage,
          discount: parseFloat(
            salesorderinternalsingle.stockOutBillData.billDiscountRate
          ).toFixed(2),
          switchDiscountPercentage:
            salesorderinternalsingle.stockOutBillData.billDiscountType === "F"
              ? false
              : true,
          adjustmentAmount: parseFloat(
            salesorderinternalsingle.stockOutBillData.billAdjustmentAmount
          ).toFixed(2),
        });
      }
    }
  };
  // console.log("switchT");
  // console.log(switchT);
  // console.log("switchTStuck");
  // console.log(switchTStuck);
  useEffect(() => {
    changeValues();
  }, [salesorderinternalsingle, batchAndMap]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    if (
      JSON.stringify(salesorderinternalsingle) !== "{}" &&
      salesorderinternalsingle !== undefined
    ) {
      const newDataItems = salesorderinternalsingle.stockOutItemsData.map(
        (single) => {
          return {
            ...single,
            sodBatches:
              single.sodBatches.length > 0
                ? single.sodBatches.map((attribute) => {
                    console.log("attribute");
                    console.log(attribute);
                    return {
                      id: attribute.batchId,

                      label:
                        attribute.batchNo +
                        "-" +
                        attribute.batchExpiryRemainInDays +
                        "d",
                    };
                  })
                : [],
          };
        }
      );
      var newData = newDataItems.map(async (singleData) => {
        // console.log("singleData");
        // console.log(singleData);
        const response = await fetch(
          `${API_URL}/StockOut/GetMappedItemsAndBatchesByProduct/${singleData.sodProductId}`,
          requestOptions
        );
        const data = await response.json();
        // console.log("data");
        // console.log(data);
        const finalBatch = data.dataObj.data.batchList.map((single) => {
          return {
            id: single.batchId,
            label: single.batchNo + "-" + single.batchExpiryRemainInDays + "d",
          };
        });
        const finalMappedItem = data.dataObj.data.mappedItems.map((single) => {
          return {
            id: single.key,
            label: single.value,
          };
        });

        var final = {
          finalBatch: finalBatch,
          finalMappedItem: finalMappedItem,
        };

        // console.log("final");
        // console.log(final);
        return {
          productId: singleData.sodProductId,
          productName: singleData.productName,
          quantity: singleData.sodQty || 0,
          productLevel: singleData.productLevel,
          discountType:
            singleData.sodUnitDiscountType === null
              ? "F"
              : singleData.sodUnitDiscountType,
          vat: singleData.sodUnitVatPercantage || 0,
          ait: singleData.sodUnitAitPercantage || 0,
          unitPrice: singleData.sodUnitPrice || 0,
          discountRate: singleData.sodUnitDiscountRate || 0,
          discountAmmount: singleData.sodUnitDiscountAmount || 0,
          lineTotal:
            parseFloat(singleData.sodFinalCalculatedUnitPrice).toFixed(2) || 0,
          batchList: final.finalBatch,
          mappedItemList: final.finalMappedItem,
          sodMappedProductId: singleData?.sodMappedProductId || "",
          sodRealatedBatchId: singleData?.sodRealatedBatchId || "",
          mappedProductInfo: {
            id:
              singleData.sodMappedProductId === null ||
              singleData.sodMappedProductId === 0
                ? ""
                : singleData.sodMappedProductId,
            label:
              singleData.sodMappedProductId === null ||
              singleData.sodMappedProductId === 0
                ? ""
                : singleData.mappedProductName,
          },
          batchInfo: singleData.sodBatches,
        };
      });

      // console.log("newData");
      // console.log(newData);
      Promise.all(newData).then((products) => setBatchAndMap(products));
    }
    // JSON.stringify(salesorderinternalsingle) !== "{}" &&
    //   Promise.all(
    //     salesorderinternalsingle.stockOutItemsData.map(async (singleData) => {
    //       const response = await fetch(
    //         `${API_URL}/StockOut/GetMappedItemsAndBatchesByProduct/${singleData.sodProductId}`,
    //         requestOptions
    //       );
    //       const data = await response.json();
    //       const finalBatch = data.dataObj.data.batchList.map((single) => {
    //         return {
    //           value: single.key,
    //           label: single.value,
    //         };
    //       });
    //       const finalMappedItem = data.dataObj.data.mappedItems.map(
    //         (single) => {
    //           return {
    //             id: single.key,
    //             label: single.value,
    //           };
    //         }
    //       );

    //       var final = {
    //         finalBatch: finalBatch,
    //         finalMappedItem: finalMappedItem,
    //       };
    //       return {
    //         productId: singleData.sodProductId,
    //         productName: singleData.productName,
    //         quantity: singleData.sodQty || 1.0,
    //         sobUnitDiscountType:
    //           singleData.sobUnitDiscountType === null
    //             ? "F"
    //             : singleData.sobUnitDiscountType,
    //         vat: singleData.sodUnitVatPercantage || 0,
    //         ait: singleData.sodUnitAitPercantage || 0,
    //         unitPrice: singleData.sodUnitPrice || 1,
    //         discountAmmount: singleData.sodUnitDiscountAmount || 0,
    //         lineTotal: singleData.sodFinalCalculatedUnitPrice || 1,
    //         batchList: final.finalBatch,
    //         mappedItemList: final.finalMappedItem,
    //       };
    //     })
    //   ).then((products) => setBatchAndMap(products));
  }, [salesorderinternalsingle?.stockOutItemsData]);

  // useEffect(() => {
  //   changeValues();
  // }, [batchAndMap]);

  useEffect(() => {
    handleCalculateSubTotal();
    handleCalculateTotal();
    handleProductCalculateTotal();
  }, []);

  useEffect(() => {
    handleProductCalculateTotal();
  }, [product]);

  useEffect(() => {
    handleCalculateTotal();
  }, [data]);

  const handleRowDel = (items) => {
    // console.log("items");
    // console.log(items);
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.productId;
    });
    // console.log("FilteredProduct");
    // console.log(FilteredProduct);
    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
    handleCalculateSubTotal();
  };

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "1.00",
      description: "",
      quantity: 1,
    };

    if (
      product.id !== "" &&
      selectedCustomer.length > 0 &&
      selectedOrderType.length > 0
    ) {
      if (lineTotal < 0) {
        setOpenPopup({
          ...openPopup,
          title: "VALUE ISSUE!!!",
          subTitle: "Line Value can't be negative!",
          isOpen: true,
        });
      } else {
        setSwitchTStuck(true);
        // console.log("product.id");
        // console.log(product.id);
        dispatch(listBatchAndMappedItemBasedOnProduct(product.id)).then(
          (res) => {
            // console.log("res");
            // console.log(res);
            data.items.push({
              productId: product.id,
              productName: product.label,
              quantity: product.quantity || 1.0,
              vat: product.vat || 0,
              ait: product.ait || 0,
              unitPrice: lineTotal,
              discountAmmount: product.discount || 0,
              lineTotal: lineTotal * product.quantity || 1,
              batchList: res.finalBatch,
              mappedItemList: res.finalMappedItem,
              sodMappedProductId: "",
              sodRealatedBatchId: "",
            });
          }
        );

        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "1",
          unitPrice: "0.00",
          vat: JSON.stringify(obj) !== "{}" || obj !== undefined ? 5 : "0.00",
          ait: JSON.stringify(obj) !== "{}" || obj !== undefined ? 3 : "0.00",
          discount: "0",
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        handleCalculateSubTotal();
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };
  // console.log("products");
  // console.log(products);
  const handleProductCalculateTotal = () => {
    // console.log("product");
    // console.log(product);
    var quantity = parseFloat(product.quantity || 1.0);
    var unitPrice = parseFloat(product.unitPrice || 1.0);
    var vat = parseFloat(product.vat || 0);
    var ait = parseFloat(product.ait || 0);
    var discount = parseFloat(product.discount || 0);
    var productDiscountAmmount = discount;

    // let text = "5";
    // let pattern = /(^([1-9]|[1-9][0-9]|100))$/;
    // let result = pattern.test(text);
    var lineTotal = parseFloat(0);
    var quantityPrice = parseFloat(0);
    var vadTotal = parseFloat(0);
    var subPurchase = parseFloat(0);

    if (product.switchDiscountPercentage) {
      productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
    }
    lineTotal = lineTotal + (unitPrice - productDiscountAmmount);

    // console.log("unitPrice");
    // console.log(unitPrice);

    // console.log("productDiscountAmmount");
    // console.log(productDiscountAmmount);

    // console.log("lineTotal");
    // console.log(lineTotal);

    quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

    var lineTotalVat = unitPrice * (vat / 100);
    var lineTotalAit = unitPrice * (ait / 100);

    lineTotal = lineTotal + lineTotalVat + lineTotalAit;

    vadTotal = lineTotalVat + lineTotalAit;
    vadTotal = parseFloat(vadTotal.toFixed(2));
    lineTotal = lineTotal.toFixed(2);

    quantityPrice = parseFloat(quantityPrice.toFixed(2));

    // console.log("typeof vadTotal");
    // console.log(typeof vadTotal);
    // console.log("typeof quantityPrice");
    // console.log(typeof quantityPrice);
    subPurchase = unitPrice + vadTotal;
    subPurchase = parseFloat(subPurchase.toFixed(2));
    // setProduct((prevState) => ({
    //   ...prevState,
    //   lineTotal: lineTotal,
    // }));

    setQuantityPrice(quantityPrice);
    setVadTotal(vadTotal);
    setSubPurchase(subPurchase);
    setLineTotal(lineTotal);
    // setProduct({
    //   ...product,
    //   lineTotal: lineTotal,
    // });
  };

  // console.log("selectedOrderType");
  // console.log(selectedOrderType);

  const handleCalculateSubTotal = () => {
    var items = data.items;
    // console.log("items");
    // console.log(items);
    // console.log("data.vatRate");
    // console.log(data.vatRate);
    var subTotal = parseFloat(0);

    items.map(function (item) {
      // console.log("item");
      var singleLineTotal = parseFloat(item.lineTotal);
      // console.log(typeof singleLineTotal);
      subTotal = subTotal + singleLineTotal;
      // subTotal = parseFloat(
      //   subTotal + parseFloat(item.price).toFixed(2) * parseInt(item.quantity)
      // ).toFixed(2);
      // console.log("subTotal");
      // console.log(subTotal);
      subTotal = parseFloat(subTotal);
    });
    setData({
      ...data,
      subTotal: parseFloat(subTotal).toFixed(2),
      taxAmmount: parseFloat(
        parseFloat(subTotal) * (data.taxRate / 100)
      ).toFixed(2),
      discountAmmount: parseFloat(
        parseFloat(subTotal) * (data.discountRate / 100)
      ).toFixed(2),
      total: subTotal - data.discountAmmount + parseFloat(data.taxAmmount),
    });
    setTotal(subTotal - data.discountAmmount + parseFloat(data.taxAmmount));
  };
  const handleCalculateTotal = () => {
    var items = data.items;
    // console.log("items");
    // console.log(items);
    // console.log("data.aitRate");
    // console.log(data.discount);
    var subTotal = parseFloat(0);
    var discountOverall = parseFloat(data.discount || 0);
    var adjustmentAmount = parseFloat(data.adjustmentAmount || 0);
    var productOverallDiscountAmmount = discountOverall;

    items.map(function (item) {
      // console.log("item");
      var singleLineTotal = parseFloat(item.lineTotal);
      // console.log(typeof singleLineTotal);
      subTotal = subTotal + singleLineTotal;
      // subTotal = parseFloat(
      //   subTotal + parseFloat(item.price).toFixed(2) * parseInt(item.quantity)
      // ).toFixed(2);
      // console.log("subTotal");
      // console.log(subTotal);
      subTotal = parseFloat(subTotal);
    });
    var totalT = parseFloat(0);
    var vatAmmountT = parseFloat(0);
    vatAmmountT = subTotal * (data.vatRate / 100);
    setVatAmmount(vatAmmountT);

    var aitAmmountT = parseFloat(0);
    aitAmmountT = subTotal * (data.aitRate / 100);
    setAitAmmount(aitAmmountT);

    totalT = subTotal + vatAmmountT + aitAmmountT;

    if (data.switchDiscountPercentage) {
      productOverallDiscountAmmount =
        totalT * (productOverallDiscountAmmount / 100);
    }

    setDiscountAmmount(productOverallDiscountAmmount);
    // console.log("totalT");
    // console.log(totalT);
    totalT = totalT - productOverallDiscountAmmount - adjustmentAmount;

    setTotal(totalT.toFixed(2));
    // setData({
    //   ...data,
    //   subTotal: parseFloat(subTotal).toFixed(2),
    //   taxAmmount: parseFloat(
    //     parseFloat(subTotal) * (data.taxRate / 100)
    //   ).toFixed(2),
    //   discountAmmount: parseFloat(
    //     parseFloat(subTotal) * (data.discountRate / 100)
    //   ).toFixed(2),
    //   total: subTotal - data.discountAmmount + parseFloat(data.taxAmmount),
    // });
  };

  const handleCalculateTotalFromEditable = (id, items) => {
    // console.log("data.items");
    // console.log(data.items);
    var items = items;
    var subTotal = parseFloat(0);
    var sum = 0;

    items.map(function (items) {
      // console.log("items");
      // console.log(items);
      // console.log("subTotal");
      // console.log(
      //   parseFloat(items.unitPrice).toFixed(2) * parseInt(items.quantity)
      // );
      // subTotal = parseFloat(
      //   subTotal +
      //     parseFloat(items.unitPrice).toFixed(2) * parseInt(items.quantity)
      // ).toFixed(2);
      sum =
        sum +
        parseFloat(items.unitPrice).toFixed(2) * parseFloat(items.quantity);
    });
    // console.log("sum");
    // console.log(sum);
    var newItems = items.map(function (sitems) {
      // console.log("sitems");
      // console.log(sitems);
      for (var key in sitems) {
        if (key == "lineTotal" && sitems.productId == id) {
          sitems[key] = parseFloat(
            parseFloat(sitems.unitPrice).toFixed(2) *
              parseFloat(sitems.quantity)
          ).toFixed(2);
        }
      }
      return sitems;
    });
    // console.log(subTotal);
    setData({
      ...data,
      items: newItems,
      subTotal: parseFloat(sum).toFixed(2),
    });
  };

  const onItemizedItemEdit = (evt) => {
    // console.log("evt");
    // console.log(evt.target.value);
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    // console.log("item");
    // console.log(item);
    var items = data.items.slice();

    var newItems = items.map(function (sitems) {
      //   console.log("sitems");
      //   console.log(sitems);
      for (var key in sitems) {
        if (key == item.name && sitems.productId == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });
    // console.log("newItems");
    // console.log(newItems);

    // setData({
    //   ...data,
    //   items: newItems,
    // });
    // handleCalculateSubTotal();
    handleCalculateTotalFromEditable(item.id, newItems);
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
    // handleCalculateTotal();
  };

  const onItemizedBatchAndMapEdit = (evt) => {
    console.log("evt");
    console.log(evt);

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      // console.log("sitems");
      // console.log(sitems);
      for (var key in sitems) {
        if (key == evt.name && sitems.productId == evt.productId) {
          // console.log("Hello");
          sitems[key] = evt.id;
        }
      }
      return sitems;
    });

    console.log("newItems");
    console.log(newItems);
    setData({
      ...data,
      items: newItems,
    });
  };

  const onItemizedBatchEdit = (evt) => {
    console.log("evt");
    console.log(evt);

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      // console.log("sitems");
      // console.log(sitems);
      for (var key in sitems) {
        if (key == evt.name && sitems.productId == evt.productId) {
          // console.log("Hello");
          sitems[key] = evt.selectedBatch;
        }
      }
      return sitems;
    });

    // console.log("newItems");
    // console.log(newItems);
    setData({
      ...data,
      items: newItems,
    });
  };

  const editProductField = (event) => {
    // await setProduct({
    //   ...product,

    // });
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
    // console.log("product");
    // console.log(product);
    // handleProductCalculateTotal();
    // this.handleCalculateTotal();
    // handleProductCalculateTotal();
  };

  const openModal = (event) => {
    event.preventDefault();
    // dispatch(createSalesOrderInternal({}));
    // console.log("selectedOrderType");
    // console.log(selectedOrderType);
    // console.log("data.items");
    // console.log(data);
    if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      var flag = 0;
      if (data.items.length > 0) {
        var selectedBatch = [];

        const newDataItems = data.items.map((single) => {
          console.log(single.sodRealatedBatchId);
          return {
            ...single,
            batchInfo:
              single.batchInfo.length > 0 &&
              single.batchInfo.map((attribute) => {
                return {
                  batchId: attribute.id,
                  batchNo: attribute.label,
                };
              }),
          };
        });
        console.log("newDataItems");
        console.log(newDataItems);
        var dataArray = newDataItems.map((singleData) => {
          // console.log("singleData.unitPrice");
          // console.log(typeof singleData.unitPrice);
          if (
            singleData.unitPrice === "1.00" ||
            singleData.unitPrice === "1" ||
            singleData.unitPrice === 1
          ) {
            flag = 1;
          }
          // console.log("singleData");
          // console.log(singleData);
          return {
            sodProductId: parseInt(singleData.productId),

            sodMappedProductId:
              singleData.sodMappedProductId === ""
                ? null
                : singleData.sodMappedProductId,
            // sodMappedProductId: singleData.sodMappedProductId,
            // sodRealatedBatchId:
            //   singleData.sodRealatedBatchId === ""
            //     ? null
            //     : singleData.sodRealatedBatchId,
            sodBatches:
              singleData.batchInfo.length > 0 ? singleData.batchInfo : null,
            sodRealatedBatchNo: "string",
            productName: singleData.productName,
            mappedProductName: "string",
            sodQty: parseFloat(singleData.quantity),
            sodUnitPrice: parseFloat(singleData.unitPrice),
            sodUnitDiscountType: singleData.discountType,
            sodUnitDiscountRate: parseFloat(singleData.discountRate),
            sodUnitDiscountAmount: parseFloat(singleData.discountAmmount),

            sodUnitVatPercantage: parseInt(singleData.vat),
            sodUnitAitPercantage: parseInt(singleData.ait),
            sodFinalCalculatedUnitPrice: parseFloat(singleData.lineTotal),
            itemRemark: "sdw",
          };
        });
        // console.log("dataArray");
        // console.log(dataArray);
        // console.log("salesorderinternalsingle.stockOutGeneralData.invoiceDate");
        // console.log(salesorderinternalsingle.stockOutGeneralData.invoiceDate);
        var data1 = {
          stockOutGeneralData: {
            soSystemId: parseInt(salesOrderId),
            soSystemNo: salesorderinternalsingle.stockOutGeneralData.soSystemNo,
            soJobTypeId: parseInt(selectedOrderType[0].id),
            soOrderFromId: parseInt(selectedOrderFrom[0].id),
            // invoiceDate: new Date(
            //   salesorderinternalsingle.stockOutGeneralData.invoiceDate
            // ),
            invoiceDate: new Date(
              salesorderinternalsingle.stockOutGeneralData.invoiceDate
            ),
            // invoiceDate: new Date(),
            soCurrentStatus: 0,
            soRemarks: "string",
            soDestinationChannelId: 0,
            soCustomerId: selectedCustomer[0].id,
            soPriceReview: 0,
            soCompanyId: 2,
            customerName: selectedCustomer[0].label,
            jobTypeName: selectedOrderType[0].label,
          },
          stockOutItemsData: dataArray,
          stockOutBillData: {
            calculatedBill: parseFloat(data.subTotal),
            billDiscountType: data.switchDiscountPercentage ? "P" : "F",
            billDiscountRate: data.switchDiscountPercentage ? data.discount : 0,
            billDiscountAmount: discountAmmount,
            billDiscountNote: "stweering",
            billVatPercantage: data.vatRate || 0,
            billAitPercantage: data.aitRate || 0,
            billAdjustmentAmount: data.adjustmentAmount || 0,
            finalBill: parseFloat(total),
            sobCurrencyName: data.currency === "TK" ? "BDT" : "USD",
          },
        };
        console.log("data1");
        console.log(data1);

        dispatch(packageSalesOrderAdminInvoiceUpdate(data1)).then((result) => {
          if (result?.title === "One or more validation errors occurred.") {
            setOpenPopup({
              ...openPopup,
              title: "ERROR!!!",
              subTitle: "Something went wrong!",
              isOpen: true,
            });
          } else {
            const location = {
              pathname: history.location.state?.data,
              state: {},
            };
            history.push(location);
          }
        });

        handleCalculateTotal();
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });
  // console.log("products");
  // console.log(products);
  // console.log(data.invoiceNumber);
  // console.log("loadingEscalate");
  // console.log(loadingEscalate);
  console.log("salesorderinternalsingle");
  console.log(salesorderinternalsingle);
  return (
    <div>
      <Menu />

      <Container>
        <Form>
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                {/* <div className="d-flex flex-row align-items-start justify-content-between mb-1">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row align-items-center">
                        <span className="fw-bold me-2">
                          Invoice&nbsp;Number:&nbsp;
                        </span>

                        <Form.Control
                          type="text"
                          value={data.invoiceNumber}
                          name={"invoiceNumber"}
                          onChange={(event) => editField(event)}
                          min="1"
                          style={{
                            maxWidth: "140px",
                          }}
                          required="required"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <Form.Label className="fw-bold">
                  Invoice&nbsp;Number:&nbsp;{data.invoiceNumber}
                </Form.Label>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">
                        Customer Name:
                      </Form.Label>

                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="customerId"
                        label="Customer Name"
                        disabled={
                          loadingCustomer
                            ? true
                            : data.items.length > 0 && switchTStuck
                            ? true
                            : false
                        }
                        onChange={(selectedCustomer) => {
                          //   console.log("selectedCustomer");
                          //   console.log(selectedCustomer);
                          setSelectedCustomer(selectedCustomer);
                        }}
                        options={customers !== undefined ? customers : []}
                        placeholder="Customer Name"
                        selected={selectedCustomer}
                        // disabled={switchTStuck ? true : false}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          marginRight: "10px",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Type:</Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="orderTypeId"
                          label="Order Type"
                          disabled={
                            loadingOrderType
                              ? true
                              : data.items.length > 0 && switchTStuck
                              ? true
                              : false
                          }
                          onChange={(selectedOrderType) => {
                            // console.log("selected");
                            // console.log(selected);
                            setSelectedOrderType(selectedOrderType);
                          }}
                          options={orderTypes !== undefined ? orderTypes : []}
                          placeholder="Order Type"
                          selected={selectedOrderType}
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <Form.Group controlId="form-customerId">
                          <Form.Label className="fw-bold">
                            Order From:
                          </Form.Label>

                          <Typeahead
                            clearButton
                            id="basic-example"
                            name="customerId"
                            label="Order From"
                            disabled={
                              loadingOrderFrom
                                ? true
                                : data.items.length > 0 && switchTStuck
                                ? true
                                : false
                            }
                            onChange={(selectedOrderFrom) => {
                              //   console.log("selectedOrderFrom");
                              //   console.log(selectedOrderFrom);
                              setSelectedOrderFrom(selectedOrderFrom);
                            }}
                            options={orderFroms !== undefined ? orderFroms : []}
                            placeholder="Order From"
                            selected={selectedOrderFrom}
                            // disabled={switchTStuck ? true : false}
                          />
                        </Form.Group>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label className="fw-bold">
                      Customer&nbsp;Address:&nbsp;
                    </Form.Label>
                    <Form.Control
                      placeholder={"customerAddress"}
                      value={
                        JSON.stringify(salesorderinternalsingle) !== "{}" &&
                        salesorderinternalsingle !== undefined
                          ? salesorderinternalsingle.stockOutGeneralData
                              .customerAddress !== null
                            ? salesorderinternalsingle.stockOutGeneralData
                                .customerAddress
                            : "Not Available"
                          : "loading.."
                      }
                      type="text"
                      name="invoiceNumber"
                      autoComplete="address"
                      onChange={(event) => editField(event)}
                      required="required"
                      as="textarea"
                      rows={1}
                      disabled
                    />
                    <Form.Label className="fw-bold">Order Date:</Form.Label>
                    <Form.Control
                      placeholder={"Who is this invoice to?"}
                      value={invoiceDate}
                      type="text"
                      name="dateOfIssue"
                      onChange={(event) => editField(event)}
                      autoComplete="name"
                      required="required"
                      disabled
                    />
                  </Col>
                </Row>
                <hr className="my-3" />

                {/* <Row className="mb-1">
                  <Col>
                    <InputGroup.Text id="basic-addon1">
                      <Form.Check
                        type="checkbox"
                        id="custom-checkbox"
                        label="Enable Mapping Product Option?"
                        className=""
                        checked={switchMappingOption}
                        onChange={(e) => {
                          setSwitchMappingOption(!switchMappingOption);
                        }}
                      />
                    </InputGroup.Text>
                  </Col>
                </Row> */}
                {selectedOrderType.length > 0 &&
                selectedOrderType[0].label === "Sample_Issue_HOLD" ? (
                  <SampleItem
                    onItemizedItemEdit={onItemizedItemEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                    switchMappingOption={false}
                    allOptionAuto={false}
                  />
                ) : selectedOrderType.length > 0 &&
                  (selectedOrderType[0].label === "Dilution_Issue_HOLD" ||
                    selectedOrderType[0].label === "Warehouse_Transfer_HOLD" ||
                    selectedOrderType[0].label === "Purchase_Return_HOLD") ? (
                  <DPWItem
                    onItemizedItemEdit={onItemizedItemEdit}
                    onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                    escalate={data.editableField}
                    switchMappingOption={switchMappingOption}
                    setSwitchMappingOption={setSwitchMappingOption}
                    allOptionAuto={true}
                    mappingOption={{ mappingOption, setMappingOption }}
                  />
                ) : selectedOrderType.length > 0 &&
                  (selectedOrderType[0].label === "Exchange_Outward_HOLD" ||
                    selectedOrderType[0].label === "Loan_Issue_HOLD") ? (
                  <ELItem
                    onItemizedItemEdit={onItemizedItemEdit}
                    onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                    escalate={data.editableField}
                    switchMappingOption={switchMappingOption}
                    setSwitchMappingOption={setSwitchMappingOption}
                    allOptionAuto={true}
                    mappingOption={{ mappingOption, setMappingOption }}
                  />
                ) : (
                  <SalesOrderItemWithPriceEdit
                    onItemizedBatchEdit={onItemizedBatchEdit}
                    onItemizedItemEdit={onItemizedItemEdit}
                    onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                    escalate={data.editableField}
                    switchMappingOption={switchMappingOption}
                    setSwitchMappingOption={setSwitchMappingOption}
                    allOptionAuto={true}
                    mappingOption={{ mappingOption, setMappingOption }}
                  />
                )}
                <Row className="mt-4 justify-content-end">
                  <Col lg={6}>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Subtotal:</span>
                      <span>
                        {data.currency}
                        {parseFloat(data.subTotal).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">VAT:</span>
                      <span>
                        <span className="small ">({data.vatRate || 0}%)</span>
                        {data.currency}
                        {vatAmmount.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">AIT:</span>
                      <span>
                        <span className="small ">({data.aitRate || 0}%)</span>
                        {data.currency}
                        {aitAmmount.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">Discount:</span>
                      <span>
                        {data.switchDiscountPercentage ? (
                          <span className="small ">
                            ({data.discount || 0}%)
                          </span>
                        ) : (
                          ""
                        )}

                        {data.currency}
                        {discountAmmount.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Adjustment amount:</span>
                      <span>
                        {data.currency} {data.adjustmentAmount || 0}
                      </span>
                    </div>
                    <hr />
                    <div
                      className="d-flex flex-row align-items-start justify-content-between"
                      style={{
                        fontSize: "1.125rem",
                      }}
                    >
                      <span className="fw-bold">Total:</span>
                      <span className="fw-bold">
                        {data.currency}
                        {total || 0}
                      </span>
                    </div>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Form.Label className="fw-bold">Remarks:</Form.Label>
                <Form.Control
                  placeholder="Keep some notes!"
                  name="notes"
                  as="textarea"
                  className="my-2"
                  value={data.notes}
                  onChange={(event) => {
                    setData({
                      ...data,
                      notes: event.target.value,
                    });
                  }}
                  rows={1}
                  disabled
                />
                <hr className="my-3" />

                <div>
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingPackage ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Save
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={() => {
                      const location = {
                        pathname: history.location.state?.data,
                        state: {},
                      };
                      history.push(location);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </Card>
            </Col>
            <Col
              md={4}
              lg={3}
              style={{
                display: "none",
                transition: "all 0.5s ease-out",
              }}
            >
              <div
                className="pt-md-3 pt-xl-4 mt-4 p-3"
                style={{
                  backgroundColor: "#f6f6fd",
                  backgroundImage:
                    "url(" +
                    "https://www.transparenttextures.com/patterns/asfalt-light.png" +
                    ")",
                }}
              >
                {/* <SalesOrderInternalModal
                  showModal={data.isOpen}
                  closeModal={closeModal}
                  info={data}
                  items={data.items}
                  currency={data.currency}
                  subTotal={data.subTotal}
                  taxAmmount={data.taxAmmount}
                  discountAmmount={data.discountAmmount}
                  total={data.total}
                /> */}
                {/* <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Currency:</Form.Label>
                <Form.Select
                  onChange={(event) =>
                    this.onCurrencyChange({ currency: event.target.value })
                  }
                  className="btn btn-light my-1"
                  aria-label="Change Currency"
                >
                  <option value="$">USD (United States Dollar)</option>
                  <option value="£">GBP (British Pound Sterling)</option>
                  <option value="¥">JPY (Japanese Yen)</option>
                  <option value="$">CAD (Canadian Dollar)</option>
                  <option value="$">AUD (Australian Dollar)</option>
                  <option value="$">SGD (Signapore Dollar)</option>
                  <option value="¥">CNY (Chinese Renminbi)</option>
                  <option value="₿">BTC (Bitcoin)</option>
                </Form.Select>
              </Form.Group> */}
                <div
                  style={{
                    backgroundColor: "white",
                    height: "300px",
                    width: "100%",
                    /* From https://css.glass */
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "16px",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                ></div>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">VAT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="vatRate"
                      type="number"
                      value={data.vatRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      min="0.00"
                      step="0.01"
                      max="100.00"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">AIT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="aitRate"
                      type="number"
                      value={data.aitRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      min="0.00"
                      step="0.01"
                      max="100.00"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Discount rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="discount"
                      type="number"
                      value={data.discount}
                      onChange={(event) => {
                        editField(event);
                        // if (event.target.value === "") {
                        //   setData({
                        //     ...data,
                        //     discount: "0",
                        //   });
                        // }
                        // console.log("data.discount");
                        // console.log(event.target.value);
                      }}
                      placeholder="0.0"
                      min="0"
                      step="any"
                      max="100.00"
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      <Form.Check
                        type="checkbox"
                        id="custom-checkbox"
                        label="%"
                        className=""
                        checked={data.switchDiscountPercentage}
                        onChange={(e) => {
                          if (data.switchDiscountPercentage) {
                            // setSwitchDiscountPercentage(false);
                            setData({
                              ...data,
                              switchDiscountPercentage: false,
                            });
                          } else {
                            // setSwitchDiscountPercentage(true);
                            setData({
                              ...data,
                              switchDiscountPercentage: true,
                            });
                            // setData({
                            //   ...data,
                            //   vat: "0.00",
                            //   ait: "0.00",
                            // });
                          }
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">
                    Adjustment Amount:
                  </Form.Label>
                  <Form.Control
                    name="adjustmentAmount"
                    type="number"
                    value={data.adjustmentAmount}
                    onChange={(event) => {
                      editField(event);
                      // if (event.target.value === "") {
                      //   setProduct({
                      //     ...product,
                      //     unitPrice: "1.00",
                      //   });
                      // }
                    }}
                    placeholder="1.00"
                    // min="1"
                    // step="0.1"
                    // presicion="2"
                    // required="required"
                  />
                </Form.Group>
                <div>
                  <Button
                    variant="primary"
                    size="sm"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingPackage ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Save
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      const location = {
                        pathname: history.location.state?.data,
                        state: {},
                      };
                      history.push(location);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      </Container>
    </div>
  );
};

export default EscalateSalesOrderInternalWithPriceEdit;

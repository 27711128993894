export const CUSTOMER_LIST_REQUEST = "CUSTOMER_LIST_REQUEST";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAIL = "CUSTOMER_LIST_FAIL";

// export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
// export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
// export const GET_PRODUCT_LIST_FAIL = "GET_PRODUCT_LIST_FAIL";

export const CUSTOMER_MAP_LIST_REQUEST = "CUSTOMER_MAP_LIST_REQUEST";
export const CUSTOMER_MAP_LIST_SUCCESS = "CUSTOMER_MAP_LIST_SUCCESS";
export const CUSTOMER_MAP_LIST_FAIL = "CUSTOMER_MAP_LIST_FAIL";

export const CUSTOMER_DETAILS_REQUEST = "CUSTOMER_DETAILS_REQUEST";
export const CUSTOMER_DETAILS_SUCCESS = "CUSTOMER_DETAILS_SUCCESS";
export const CUSTOMER_DETAILS_FAIL = "CUSTOMER_DETAILS_FAIL";
export const CUSTOMER_DETAILS_RESET = "CUSTOMER_DETAILS_RESET";

export const CUSTOMER_DELETE_REQUEST = "CUSTOMER_DELETE_REQUEST";
export const CUSTOMER_DELETE_SUCCESS = "CUSTOMER_DELETE_SUCCESS";
export const CUSTOMER_DELETE_FAIL = "CUSTOMER_DELETE_FAIL";
export const CUSTOMER_DELETE_RESET = "CUSTOMER_DELETE_RESET";

export const CUSTOMER_CREATE_REQUEST = "CUSTOMER_CREATE_REQUEST";
export const CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS";
export const CUSTOMER_CREATE_FAIL = "CUSTOMER_CREATE_FAIL";
export const CUSTOMER_CREATE_RESET = "CUSTOMER_CREATE_RESET";

export const CUSTOMER_UPDATE_REQUEST = "CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAIL = "CUSTOMER_UPDATE_FAIL";
export const CUSTOMER_UPDATE_RESET = "CUSTOMER_UPDATE_RESET";

export const PRODUCT_PRODUCTS_TOP_REQUEST = "PRODUCT_PRODUCTS_TOP_REQUEST";
export const PRODUCT_PRODUCTS_TOP_SUCCESS = "PRODUCT_PRODUCTS_TOP_SUCCESS";
export const PRODUCT_PRODUCTS_TOP_FAIL = "PRODUCT_PRODUCTS_TOP_FAIL";

export const UNASSIGNED_CUSTOMER_LIST_REQUEST =
  "UNASSIGNED_CUSTOMER_LIST_REQUEST";
export const UNASSIGNED_CUSTOMER_LIST_SUCCESS =
  "UNASSIGNED_CUSTOMER_LIST_SUCCESS";
export const UNASSIGNED_CUSTOMER_LIST_FAIL = "UNASSIGNED_CUSTOMER_LIST_FAIL";

export const ASSIGNED_CUSTOMER_LIST_REQUEST = "ASSIGNED_CUSTOMER_LIST_REQUEST";
export const ASSIGNED_CUSTOMER_LIST_SUCCESS = "ASSIGNED_CUSTOMER_LIST_SUCCESS";
export const ASSIGNED_CUSTOMER_LIST_FAIL = "ASSIGNED_CUSTOMER_LIST_FAIL";

export const ASSIGNED_CUSTOMER_UPDATE_REQUEST =
  "ASSIGNED_CUSTOMER_UPDATE_REQUEST";
export const ASSIGNED_CUSTOMER_UPDATE_SUCCESS =
  "ASSIGNED_CUSTOMER_UPDATE_SUCCESS";
export const ASSIGNED_CUSTOMER_UPDATE_FAIL = "ASSIGNED_CUSTOMER_UPDATE_FAIL";
export const ASSIGNED_CUSTOMER_UPDATE_RESET = "ASSIGNED_CUSTOMER_UPDATE_RESET";

import React, { Fragment, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BiTrash, BiPlusCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";

import { Container, FloatingLabel, Tab, Tabs } from "react-bootstrap";
import InvoiceItem from "../../core/InvoiceItem";
import CustomTypeaheadSelect from "../../core/CustomTypeaheadSelect";
import { API_URL } from "../../redux/constants/apiConstants";

import Menu from "../../core/Menu";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import {
  createSalesOrderInternal,
  FilterProductsForInvoice,
  listBatchAndMappedItemBasedOnProduct,
  listOrderFromInvoice,
  listOrderTypeInvoice,
  listPriceSummaryBasedOnProductAndCustomer,
  listProductsForInvoice,
} from "../../redux/actions/invoiceActions";
import SalesOrderItem from "../../core/SalesOrderItem";
import InvoiceModal from "../../core/InvoiceModal";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../core/Popup";
import SalesOrderInternalModal from "../../core/SalesOrderInternalModal";
import {
  listCategories,
  listProductDetailsBasedCategory,
} from "../../redux/actions/productActions";
import SuccessPopup from "../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import SampleItem from "../../core/SampleItem";
import Checkbox from "../../core/controls/Checkbox";
import { listCustomerDetails } from "../../redux/actions/customerActions";
import QuotationItem from "../../core/QuotationItem";
import {
  createQuotation,
  listQuotationDetails,
  updateApproveQuotation,
} from "../../redux/actions/quotationActions";
var FilteredProduct = [];

var date = new Date();
var d = date.toString();
d = d.split(" ");
//   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
//   day = ("0" + date.getDate()).slice(-2);
var d = [d[2], d[1], d[3]].join("-");

console.log("d");
console.log(d);

const ApproveQuotation = ({ match, history }) => {
  const systemId = match.params.id;
  const dispatch = useDispatch();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const invoiceOrderFromList = useSelector(
    (state) => state.invoiceOrderFromList
  );
  const {
    loading: loadingOrderFrom,
    error: errorOrderFrom,
    orderFroms,
  } = invoiceOrderFromList;

  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const priceSummaryBasedOnProductAndCustomerList = useSelector(
    (state) => state.priceSummaryBasedOnProductAndCustomerList
  );
  const {
    loading: loadingPriceSummaryList,
    error: errorPriceSummaryList,
    priceSummaryList,
  } = priceSummaryBasedOnProductAndCustomerList;

  const quotationApproveUpdate = useSelector(
    (state) => state.quotationApproveUpdate
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedApproveQuotation,
  } = quotationApproveUpdate;

  const quotatioDetails = useSelector((state) => state.quotatioDetails);
  const {
    loading: loadingQuotationDetails,
    error: errorQuotationDetails,
    quotationsingle,
  } = quotatioDetails;

  const categoryBasedProductDetails = useSelector(
    (state) => state.categoryBasedProductDetails
  );
  const {
    loading: loadingCategoryBasedProducts,
    error: errorCategoryBasedProducts,
    productList: categoryBasedProduct,
  } = categoryBasedProductDetails;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const customerDetails = useSelector((state) => state.customerDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    customer,
  } = customerDetails;

  console.log("customer");
  console.log(customer);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [switchDiscountPercentage, setSwitchDiscountPercentage] =
    useState(false);
  const [switchTOpen, setSwitchTOpen] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [mappingOption, setMappingOption] = useState(false);
  const [categoryQuantity, setCategoryQuantity] = useState(1);
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "1",
    unitPrice: "",
    vat: "",
    ait: "",
    discount: "",
    adjustableAmount: "",
    adjustableType: "plus",
    lineTotal: "0.00",
    switchDiscountPercentage: false,
    discountType: "F",
    discountRate: "",
  });
  // const [productForSample, setProductForSample] = useState({
  //   id: "",
  //   label: "",
  //   quantity: "1",
  //   unitPrice: "0.00",
  //   vat: "0.00",
  //   ait: "0.00",
  //   discount: "0",
  //   lineTotal: "0.00",
  //   switchDiscountPercentage: false,
  // });
  const [lineTotal, setLineTotal] = useState(0);
  const [lineTotalFinal, setLineTotalFinal] = useState(0);
  const [productVatAmmount, setProductVatAmmount] = useState(0.0);
  const [productAitAmmount, setProductAitAmmount] = useState(0.0);
  const [quantityPrice, setQuantityPrice] = useState(0.0);
  const [vadTotal, setVadTotal] = useState(0.0);
  const [subPurchase, setSubPurchase] = useState(0.0);
  const [vatAmmount, setVatAmmount] = useState(0.0);
  const [aitAmmount, setAitAmmount] = useState(0.0);
  const [discountAmmount, setDiscountAmmount] = useState(0.0);
  const [adjustmentAmmount, setAdjustmentAmmount] = useState(0.0);

  const [invoiceDate, setInvoiceDate] = useState("");
  const [total, setTotal] = useState(0.0);
  const [selected, setSelected] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [selectedOrderFrom, setSelectedOrderFrom] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState({
    isOpen: false,
    currency: "TK",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    taxRate: "",
    taxAmmount: "0.00",
    vatRate: "",
    vatAmmount: "0.00",
    aitRate: "",
    aitAmmount: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "",
    discountRate: "",
    discountAmmount: "0.00",
    adjustmentAmount: "",
    switchDiscountPercentage: false,
    items: [],
  });

  const [showMore, setShowMore] = useState(false);
  //   uuidv4()
  const users = [
    { id: 1, label: "ann" },
    { id: 2, label: "rudi" },
    { id: 3, label: "rudolph" },
    { id: 3, label: "3meterstoolong" },
  ];

  console.log("priceSummaryList");
  console.log(priceSummaryList);

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
      dispatch(listCustomersForSignup());
      dispatch(listProductsForInvoice());
      dispatch(listQuotationDetails(systemId));
      dispatch(listOrderTypeInvoice());
      dispatch(listOrderFromInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/quotation/${systemId}/edit` } },
      };
      history.push(location);
    }
  }, [systemId, dispatch, history, userInfo]);

  console.log("quotationsingle");
  console.log(quotationsingle);
  const changeValues = () => {
    setSwitchTStuck(true);
    if (
      JSON.stringify(quotationsingle) !== "{}" &&
      quotationsingle !== undefined
    ) {
      console.log("salesorderinternalsingle");
      console.log(quotationsingle.data.priceQuotationMasterData.pqOrderDate);
      var d = new Date(
        quotationsingle.data.priceQuotationMasterData.pqOrderDate
      );
      d = d.toString();
      console.log("d");
      console.log(d);
      d = d.split(" ");
      d = [d[2], d[1], d[3]].join("-");
      setInvoiceDate(d);

      setSelectedCustomer([
        {
          id: quotationsingle.data.priceQuotationMasterData.pqCustomerId,
          label: quotationsingle.data.priceQuotationMasterData.customerName,
        },
      ]);
      quotationsingle.data.priceQuotationDetailList.map((single) => {
        if (!FilteredProduct.includes(single.pqdProductId)) {
          FilteredProduct.push(single.pqdProductId);
        }
      });
      // console.log("FilteredProduct");
      // console.log(FilteredProduct);
      dispatch(FilterProductsForInvoice(FilteredProduct));
      setSwitchTStuck(true);

      let dataArray = quotationsingle.data.priceQuotationDetailList.map(
        (singleData) => {
          // console.log("singleData");
          // console.log(singleData);
          return {
            productId: singleData.pqdProductId,
            productName: singleData.productName,
            quantity: 1,
            vat: singleData.pqdAppliedVatPercantage || 0,
            ait: singleData.pqdAppliedAitPercantage || 0,
            unitPrice: singleData.pqdProductUnitPrice,
            discountRate: singleData.pqdIncreasedAmount || 0,
            discountType:
              singleData.pqdIncreasedType === null
                ? "F"
                : singleData.pqdIncreasedType,

            discountAmmount: singleData.sodUnitDiscountAmount || 0,
            lineTotal:
              parseFloat(singleData.pqdCalculatedPrice).toFixed(2) || 0,
            adjustableAmount: singleData.pqdAdustedAmount || 0,
            adjustableType: "plus",
            lineTotalFinal:
              parseFloat(singleData.pqdFinalPrice).toFixed(2) || 0,
          };
        }
      );
      // console.log("salesOrderInternalArray");
      // console.log(salesOrderInternalArray);
      setData({
        ...data,
        currency:
          quotationsingle.data.priceQuotationMasterData.pqCurrencyName === null
            ? "TK"
            : "$",
        items: dataArray,
      });
    }
  };
  // console.log("switchT");
  // console.log(switchT);
  // console.log("switchTStuck");
  // console.log(switchTStuck);
  useEffect(() => {
    changeValues();
  }, [quotationsingle]);

  useEffect(() => {
    handleCalculateSubTotal();
    handleCalculateTotal();
    handleProductCalculateTotal();
  }, []);

  useEffect(() => {
    handleProductCalculateTotal();
  }, [product]);

  useEffect(() => {
    handleCalculateTotal();
  }, [data]);

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    console.log("d");
    console.log(d);
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const handleRowDel = (items) => {
    // console.log("items");
    // console.log(items);
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.productId;
    });
    // console.log("FilteredProduct");
    // console.log(FilteredProduct);

    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
    handleCalculateSubTotal();
  };

  // const getNumberOfItems = ()

  const handleAddEvent = (evt) => {
    console.log("added");
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "0",
      description: "",
      quantity: 1,
    };
    // console.log("selectedCustomer");
    // console.log(selectedCustomer);
    if (product.id !== "" && selectedCustomer.length > 0) {
      if (
        selectedOrderType.length > 0 &&
        selectedOrderType[0].label === "Sample_Issue"
      ) {
        setSwitchTStuck(true);
        data.items.push({
          productId: product.id,
          productName: product.label,
          quantity: product.quantity || 0,
          vat: product.vat || 0,
          ait: product.ait || 0,
          unitPrice: 0,
          discountType: "F",
          discountRate: 0,
          discountAmmount: 0,
          lineTotal: 0,
        });
        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "",
          unitPrice: "",
          vat: "",
          ait: "",
          discount: "",
          switchDiscountPercentage: false,
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        setAdjustmentAmmount(0.0);
        handleCalculateSubTotal();
      } else {
        if (lineTotal < 0) {
          setOpenPopup({
            ...openPopup,
            title: "VALUE ISSUE!!!",
            subTitle: "Line Total can't be negative!",
            isOpen: true,
          });
        } else {
          setSwitchTStuck(true);

          // console.log("product.switchDiscountPercentage");
          // console.log(product.switchDiscountPercentage);
          var discountAmmount = product.discount || 0;
          if (product.switchDiscountPercentage) {
            discountAmmount = lineTotal * (product.discount / 100);
          }
          console.log("product.switchDiscountPercentage");
          console.log(product.switchDiscountPercentage);
          data.items.push({
            productId: product.id,
            productName: product.label,
            quantity: product.quantity || 0.0,
            vat: product.vat || 0,
            ait: product.ait || 0,
            unitPrice: lineTotal,
            discountRate: product.discount || 0,
            discountType: product.switchDiscountPercentage ? "P" : "F",
            discountAmmount: discountAmmount || 0,
            lineTotal: parseFloat(lineTotal * product.quantity).toFixed(2) || 0,
            adjustableAmount: product.adjustableAmount || 0,
            adjustableType: product.adjustableType || "plus",
            lineTotalFinal: parseFloat(lineTotalFinal).toFixed(2) || 0,
          });
          setData({
            ...data,
            items: data.items,
            vatRate: "",
            aitRate: "",
          });

          setProduct({
            ...product,
            id: "",
            label: "",
            quantity: "1",
            unitPrice: "",
            adjustableAmount: "",
            adjustableType: "plus",
            vat: "",
            ait: "",
            discount: "",
            switchDiscountPercentage: false,
          });
          FilteredProduct.push(product.id);
          dispatch(FilterProductsForInvoice(FilteredProduct));
          setSelected([]);
          setLineTotal(0.0);
          setLineTotalFinal(0.0);
          setProductVatAmmount(0.0);
          setProductAitAmmount(0.0);
          setDiscountAmmount(0.0);
          setAdjustmentAmmount(0.0);
          handleCalculateSubTotal();
        }
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };
  // console.log("products");
  // console.log(products);
  const handleProductCalculateTotal = () => {
    // console.log("product");
    // console.log(product);
    var quantity = parseFloat(product.quantity || 0);
    var unitPrice = parseFloat(product.unitPrice || 0);
    var vat = parseFloat(product.vat || 0);
    var ait = parseFloat(product.ait || 0);
    var discount = parseFloat(product.discount || 0);
    var adjustableAmount = parseFloat(product.adjustableAmount || 0);
    var productDiscountAmmount = discount;
    var productAdjustableAmount = adjustableAmount;

    // let text = "5";
    // let pattern = /(^([1-9]|[1-9][0-9]|100))$/;
    // let result = pattern.test(text);
    var lineTotal = parseFloat(0);
    var lineTotalFinal = parseFloat(0);
    var quantityPrice = parseFloat(0);
    var vadTotal = parseFloat(0);
    var subPurchase = parseFloat(0);

    if (product.switchDiscountPercentage) {
      productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
    }
    lineTotal = lineTotal + (unitPrice - productDiscountAmmount);

    quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

    var lineTotalVat = unitPrice * (vat / 100);
    var lineTotalAit = unitPrice * (ait / 100);

    lineTotal = lineTotal + lineTotalVat + lineTotalAit;

    vadTotal = lineTotalVat + lineTotalAit;
    vadTotal = parseFloat(vadTotal.toFixed(2));

    if (product.adjustableType === "plus") {
      lineTotalFinal = lineTotal + adjustableAmount;
    } else {
      lineTotalFinal = lineTotal - adjustableAmount;
    }

    lineTotal = lineTotal.toFixed(2);
    lineTotalFinal = lineTotalFinal.toFixed(2);

    quantityPrice = parseFloat(quantityPrice.toFixed(2));

    // console.log("typeof vadTotal");
    // console.log(typeof vadTotal);
    // console.log("typeof quantityPrice");
    // console.log(typeof quantityPrice);
    subPurchase = unitPrice + vadTotal;
    subPurchase = parseFloat(subPurchase.toFixed(2));

    // setProduct((prevState) => ({
    //   ...prevState,
    //   lineTotal: lineTotal,
    // }));

    setQuantityPrice(quantityPrice);
    setVadTotal(vadTotal);
    setSubPurchase(subPurchase);
    setLineTotal(lineTotal);
    setLineTotalFinal(lineTotalFinal);

    // setProduct({
    //   ...product,
    //   lineTotal: lineTotal,
    // });
  };

  // console.log("selectedOrderType");
  // console.log(selectedOrderType);

  const handleCalculateSubTotal = () => {
    var items = data.items;
    // console.log("items");
    // console.log(items);
    // console.log("data.vatRate");
    // console.log(data.vatRate);
    var subTotal = parseFloat(0);

    items.map(function (item) {
      // console.log("item");
      var singleLineTotal = parseFloat(item.lineTotal);
      // console.log(typeof singleLineTotal);
      subTotal = subTotal + singleLineTotal;
      // subTotal = parseFloat(
      //   subTotal + parseFloat(item.price).toFixed(2) * parseInt(item.quantity)
      // ).toFixed(2);
      // console.log("subTotal");
      // console.log(subTotal);
      subTotal = parseFloat(subTotal);
    });
    setData({
      ...data,
      subTotal: parseFloat(subTotal).toFixed(2),
      taxAmmount: parseFloat(
        parseFloat(subTotal) * (data.taxRate / 100)
      ).toFixed(2),
      discountAmmount: parseFloat(
        parseFloat(subTotal) * (data.discountRate / 100)
      ).toFixed(2),
      total: subTotal - data.discountAmmount + parseFloat(data.taxAmmount),
    });
    setTotal(subTotal - data.discountAmmount + parseFloat(data.taxAmmount));
  };
  const handleCalculateTotal = () => {
    var items = data.items;
    // console.log("items");
    // console.log(items);
    // console.log("data.aitRate");
    // console.log(data.discount);
    var subTotal = parseFloat(0);
    var discountOverall = parseFloat(data.discount || 0);
    var adjustmentAmount = parseFloat(data.adjustmentAmount || 0);
    var productOverallDiscountAmmount = discountOverall;

    items.map(function (item) {
      // console.log("item");
      var singleLineTotal = parseFloat(item.lineTotal);
      // console.log(typeof singleLineTotal);
      subTotal = subTotal + singleLineTotal;
      // subTotal = parseFloat(
      //   subTotal + parseFloat(item.price).toFixed(2) * parseInt(item.quantity)
      // ).toFixed(2);
      // console.log("subTotal");
      // console.log(subTotal);
      subTotal = parseFloat(subTotal);
    });
    var totalT = parseFloat(0);
    var vatAmmountT = parseFloat(0);
    vatAmmountT = subTotal * (data.vatRate / 100);
    setVatAmmount(vatAmmountT);

    var aitAmmountT = parseFloat(0);
    aitAmmountT = subTotal * (data.aitRate / 100);
    setAitAmmount(aitAmmountT);

    totalT = subTotal + vatAmmountT + aitAmmountT;

    if (data.switchDiscountPercentage) {
      productOverallDiscountAmmount =
        totalT * (productOverallDiscountAmmount / 100);
    }

    setDiscountAmmount(productOverallDiscountAmmount);
    setAdjustmentAmmount(adjustmentAmount);
    // console.log("totalT");
    // console.log(totalT);
    totalT = totalT - productOverallDiscountAmmount - adjustmentAmount;

    setTotal(totalT.toFixed(2));
    // setData({
    //   ...data,
    //   subTotal: parseFloat(subTotal).toFixed(2),
    //   taxAmmount: parseFloat(
    //     parseFloat(subTotal) * (data.taxRate / 100)
    //   ).toFixed(2),
    //   discountAmmount: parseFloat(
    //     parseFloat(subTotal) * (data.discountRate / 100)
    //   ).toFixed(2),
    //   total: subTotal - data.discountAmmount + parseFloat(data.taxAmmount),
    // });
  };

  const handleCalculateTotalFromEditable = (id, items) => {
    // console.log("data.items");
    // console.log(data.items);
    var items = items;
    var subTotal = parseFloat(0);
    var sum = 0;

    var newItems = items.map(function (items) {
      var quantity = parseFloat(items.quantity || 0);
      var unitPrice = parseFloat(items.unitPrice || 0);
      var vat = parseFloat(items.vat || 0);
      var ait = parseFloat(items.ait || 0);
      var discount = parseFloat(items.discountRate || 0);
      var adjustableAmount = parseFloat(items.adjustableAmount || 0);
      var productDiscountAmmount = discount;
      var productAdjustableAmount = adjustableAmount;

      var lineTotal = parseFloat(0);
      var lineTotalFinal = parseFloat(0);
      var quantityPrice = parseFloat(0);
      var vadTotal = parseFloat(0);
      var subPurchase = parseFloat(0);

      if (items.switchDiscountPercentage) {
        productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
      }
      lineTotal = lineTotal + (unitPrice - productDiscountAmmount);

      quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

      var lineTotalVat = unitPrice * (vat / 100);
      var lineTotalAit = unitPrice * (ait / 100);

      lineTotal = lineTotal + lineTotalVat + lineTotalAit;

      vadTotal = lineTotalVat + lineTotalAit;
      vadTotal = parseFloat(vadTotal.toFixed(2));

      if (items.adjustableType === "plus") {
        lineTotalFinal = lineTotal + adjustableAmount;
      } else {
        lineTotalFinal = lineTotal - adjustableAmount;
      }

      lineTotal = lineTotal.toFixed(2);
      lineTotalFinal = lineTotalFinal.toFixed(2);

      quantityPrice = parseFloat(quantityPrice.toFixed(2));

      subPurchase = unitPrice + vadTotal;
      subPurchase = parseFloat(subPurchase.toFixed(2));

      for (var key in items) {
        items["vat"] = vat || 0;
        items["ait"] = ait || 0;
        items["unitPrice"] = unitPrice || 0;
        items["discountRate"] = discount || 0;
        items["discountAmmount"] = productDiscountAmmount || 0;

        items["lineTotal"] = lineTotal || 0;
        items["adjustableAmount"] = adjustableAmount || 0;
        items["lineTotalFinal"] = lineTotalFinal || 0;
      }

      return items;
    });

    console.log("newItems");
    console.log(newItems);
    setData({
      ...data,
      items: newItems,
      subTotal: parseFloat(sum).toFixed(2),
    });
  };

  const onItemizedItemEdit = (evt) => {
    // console.log("evt");
    // console.log(evt.target.value);
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    console.log("item");
    console.log(item);
    var items = data.items.slice();

    var newItems = items.map(function (sitems) {
      //   console.log("sitems");
      //   console.log(sitems);
      for (var key in sitems) {
        if (key == item.name && sitems.productId == item.id) {
          console.log("key");
          console.log(key);
          sitems[key] = item.value;
        }
      }
      return sitems;
    });
    console.log("newItems");
    console.log(newItems);
    setData({
      ...data,
      items: newItems,
    });
    // handleCalculateSubTotal();
    // handleCalculateTotalFromEditable(item.id, newItems);
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
    // handleCalculateTotal();
  };

  const editProductField = (event) => {
    // await setProduct({
    //   ...product,

    // });
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
    // console.log("product");
    // console.log(product);
    // handleProductCalculateTotal();
    // this.handleCalculateTotal();
    // handleProductCalculateTotal();
  };

  const openModal = (event) => {
    event.preventDefault();
    // dispatch(createSalesOrderInternal({}));
    // console.log("selectedOrderType");
    // console.log(selectedOrderType);
    // console.log("data.items");
    // console.log(data);

    if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      var flag = 0;
      if (data.items.length > 0) {
        // console.log("dataArray");
        // console.log(dataArray);
        var data1 = {
          pqSystemId: systemId,
          isApproved: true,
        };
        console.log("data1");
        console.log(data1);

        dispatch(updateApproveQuotation(data1)).then((result) => {
          if (result?.title === "One or more validation errors occurred.") {
            setOpenPopup({
              ...openPopup,
              title: "ERROR!!!",
              subTitle: "Something went wrong!",
              isOpen: true,
            });
          } else {
            history.push("/quotation/list");
          }
        });

        handleCalculateTotal();
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });

  const handleKeyDown = (event, callback) => {
    console.log("event");
    console.log(event.key);
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      callback(event);
    }
  };
  console.log("categoryBasedProduct");
  console.log(categoryBasedProduct);
  // console.log(data.invoiceNumber);
  return (
    <div>
      <Menu />

      <Container>
        <Form
        // onSubmit={openModal}
        // onKeyDown={(e) => {
        //   handleKeyDown(e, openModal);
        // }}
        >
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">
                        Customer Name:
                      </Form.Label>

                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="customerId"
                        label="Customer Name"
                        disabled={
                          loadingCustomer
                            ? true
                            : data.items.length > 0 && switchTStuck
                            ? true
                            : false
                        }
                        onChange={(selected) => {
                          // console.log("selected.length");
                          // console.log(selected.length);
                          if (selected.length > 0) {
                            // console.log("selected");
                            // console.log(selected);
                            dispatch(listCustomerDetails(selected[0].id));
                            if (product.id !== "" && selected.length > 0) {
                              dispatch(
                                listPriceSummaryBasedOnProductAndCustomer({
                                  customerId: selected[0].id,
                                  prodcutId: product.id,
                                })
                              );
                            }
                          }
                          setSelectedCustomer(selected);
                        }}
                        options={customers !== undefined ? customers : []}
                        placeholder="Customer Name"
                        selected={selectedCustomer}
                        // disabled={switchTStuck ? true : false}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Date:</Form.Label>
                        <Form.Control
                          placeholder={"date?"}
                          value={invoiceDate}
                          type="text"
                          name="dateOfIssue"
                          onChange={(event) => editField(event)}
                          autoComplete="name"
                          required="required"
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label className="fw-bold">
                      Customer&nbsp;Address:&nbsp;
                    </Form.Label>
                    <Form.Control
                      placeholder={"customerAddress"}
                      value={
                        JSON.stringify(customer) !== "{}"
                          ? customer.customerAddress !== null
                            ? customer.customerAddress
                            : "Not Available"
                          : "Not Available"
                      }
                      type="text"
                      name="invoiceNumber"
                      autoComplete="address"
                      onChange={(event) => editField(event)}
                      required="required"
                      as="textarea"
                      rows={3}
                      disabled
                    />
                  </Col>
                </Row>
                <hr className="my-3" />

                {selectedOrderType.length > 0 &&
                (selectedOrderType[0].label === "Sample_Issue" ||
                  selectedOrderType[0].label === "Warehouse_Transfer") ? (
                  <SampleItem
                    onItemizedItemEdit={onItemizedItemEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                    switchMappingOption={false}
                    allOptionAuto={false}
                  />
                ) : (
                  <QuotationItem
                    onItemizedItemEdit={onItemizedItemEdit}
                    onRowAdd={handleAddEvent}
                    onRowDel={handleRowDel}
                    currency={data.currency}
                    items={data.items}
                    switchMappingOption={false}
                    allOptionAuto={false}
                    mappingOption={{ mappingOption, setMappingOption }}
                  />
                )}
                <hr className="my-4" />

                <div>
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Approve
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={() => {
                      history.push("/quotation/unapprovedlist");
                    }}
                  >
                    Back
                  </Button>
                </div>
                <hr className="my-4" />
                {selectedOrderType.length > 0 &&
                (selectedOrderType[0].label === "Sample_Issue" ||
                  selectedOrderType[0].label === "Dilution_Issue" ||
                  selectedOrderType[0].label === "Exchange_Outward" ||
                  selectedOrderType[0].label === "Loan_Issue" ||
                  selectedOrderType[0].label === "Purchase_Return" ||
                  selectedOrderType[0].label === "Warehouse_Transfer") ? (
                  <div>
                    <Button
                      variant="primary"
                      size="lg"
                      type="button"
                      onClick={openModal}
                    >
                      {loadingUpdate ? (
                        <CircularProgress size="1rem" color="error" />
                      ) : (
                        ""
                      )}{" "}
                      Create Sales Order
                    </Button>{" "}
                    <Button
                      variant="danger"
                      size="lg"
                      onClick={() => {
                        history.push("/quotation/unapprovedlist");
                      }}
                    >
                      Back
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Card>
            </Col>
            <Col
              md={4}
              lg={3}
              style={{
                display: "none",

                transition: "all 0.5s ease-out",
              }}
            >
              <div
                className="pt-md-3 pt-xl-4 mt-4 p-3"
                style={{
                  backgroundColor: "#f6f6fd",
                  backgroundImage:
                    "url(" +
                    "https://www.transparenttextures.com/patterns/asfalt-light.png" +
                    ")",
                }}
              >
                {/* <SalesOrderInternalModal
                  showModal={data.isOpen}
                  closeModal={closeModal}
                  info={data}
                  items={data.items}
                  currency={data.currency}
                  subTotal={data.subTotal}
                  taxAmmount={data.taxAmmount}
                  discountAmmount={data.discountAmmount}
                  total={data.total}
                /> */}
                {/* <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Currency:</Form.Label>
                <Form.Select
                  onChange={(event) =>
                    this.onCurrencyChange({ currency: event.target.value })
                  }
                  className="btn btn-light my-1"
                  aria-label="Change Currency"
                >
                  <option value="$">USD (United States Dollar)</option>
                  <option value="£">GBP (British Pound Sterling)</option>
                  <option value="¥">JPY (Japanese Yen)</option>
                  <option value="$">CAD (Canadian Dollar)</option>
                  <option value="$">AUD (Australian Dollar)</option>
                  <option value="$">SGD (Signapore Dollar)</option>
                  <option value="¥">CNY (Chinese Renminbi)</option>
                  <option value="₿">BTC (Bitcoin)</option>
                </Form.Select>
              </Form.Group> */}
                <div
                  style={{
                    backgroundColor: "white",
                    height:
                      JSON.stringify(priceSummaryList) === "[]"
                        ? "200px"
                        : "auto",
                    width: "100%",
                    /* From https://css.glass */
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "16px",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                >
                  {JSON.stringify(priceSummaryList) !== "[]" ? (
                    priceSummaryList !== undefined ? (
                      <div style={{ padding: "15px" }}>
                        <h4>{priceSummaryList[0].productName}</h4>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {priceSummaryList
                              .slice(0, showMore ? priceSummaryList.length : 3)
                              .map((single) => {
                                return (
                                  <tr>
                                    <td>{dateConverter(single.salesDate)}</td>
                                    <td>{single.salesPrice}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Show Less" : "Show More"}
                        </button>
                      </div>
                    ) : (
                      <div style={{ padding: "15px" }}>Loading.. </div>
                    )
                  ) : (
                    <div style={{ padding: "15px" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>No Data</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">VAT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="vatRate"
                      type="number"
                      value={data.vatRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">AIT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="aitRate"
                      type="number"
                      value={data.aitRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Discount rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="discount"
                      type="number"
                      value={data.discount}
                      onChange={(event) => {
                        editField(event);
                        // if (event.target.value === "") {
                        //   setData({
                        //     ...data,
                        //     discount: "0",
                        //   });
                        // }
                        console.log("data.discount");
                        console.log(event.target.value);
                      }}
                      placeholder="0.00"
                      step="any"
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      <Form.Check
                        type="checkbox"
                        id="custom-checkbox"
                        label="%"
                        className=""
                        checked={data.switchDiscountPercentage}
                        onChange={(e) => {
                          if (data.switchDiscountPercentage) {
                            // setSwitchDiscountPercentage(false);
                            setData({
                              ...data,
                              switchDiscountPercentage: false,
                            });
                          } else {
                            // setSwitchDiscountPercentage(true);
                            setData({
                              ...data,
                              switchDiscountPercentage: true,
                            });
                            // setData({
                            //   ...data,
                            //   vat: "0.00",
                            //   ait: "0.00",
                            // });
                          }
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">
                    Adjustment Amount:
                  </Form.Label>
                  <Form.Control
                    name="adjustmentAmount"
                    type="number"
                    value={data.adjustmentAmount}
                    onChange={(event) => {
                      editField(event);
                      // if (event.target.value === "") {
                      //   setProduct({
                      //     ...product,
                      //     unitPrice: "1.00",
                      //   });
                      // }
                    }}
                    placeholder="0.00"
                    // min="1"
                    // step="0.1"
                    // presicion="2"
                    // required="required"
                  />
                </Form.Group>
                <Checkbox
                  label="Stay in this Page"
                  name="stay"
                  value={checked}
                  onChange={(e) => {
                    setChecked(!checked);
                  }}
                />

                <div>
                  <Button
                    variant="primary"
                    size="sm"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Create Sales Order
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      history.push("/quotation/unapprovedlist");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      </Container>
    </div>
  );
};

export default ApproveQuotation;

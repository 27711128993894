import {
  QUOTATION_APPROVED_LIST_FAIL,
  QUOTATION_APPROVED_LIST_REQUEST,
  QUOTATION_APPROVED_LIST_SUCCESS,
  QUOTATION_APPROVE_UPDATE_FAIL,
  QUOTATION_APPROVE_UPDATE_REQUEST,
  QUOTATION_APPROVE_UPDATE_RESET,
  QUOTATION_APPROVE_UPDATE_SUCCESS,
  QUOTATION_CREATE_FAIL,
  QUOTATION_CREATE_REQUEST,
  QUOTATION_CREATE_RESET,
  QUOTATION_CREATE_SUCCESS,
  QUOTATION_DETAILS_FAIL,
  QUOTATION_DETAILS_REQUEST,
  QUOTATION_DETAILS_RESET,
  QUOTATION_DETAILS_SUCCESS,
  QUOTATION_UPDATE_FAIL,
  QUOTATION_UPDATE_REQUEST,
  QUOTATION_UPDATE_RESET,
  QUOTATION_UPDATE_SUCCESS,
} from "../constants/quotationConstants";

export const QuotationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case QUOTATION_CREATE_REQUEST:
      return { loading: true };
    case QUOTATION_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        createdQuotation: action.payload,
      };
    case QUOTATION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case QUOTATION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const QuotatioDetailsReducer = (
  state = { quotationsingle: {} },
  action
) => {
  switch (action.type) {
    case QUOTATION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case QUOTATION_DETAILS_SUCCESS:
      return { loading: false, quotationsingle: action.payload };
    case QUOTATION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case QUOTATION_DETAILS_RESET:
      return { quotationsingle: {} };

    default:
      return state;
  }
};

export const QuotationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case QUOTATION_UPDATE_REQUEST:
      return { loading: true };
    case QUOTATION_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedQuotation: action.payload,
      };
    case QUOTATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case QUOTATION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const QuotationApproveUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case QUOTATION_APPROVE_UPDATE_REQUEST:
      return { loading: true };
    case QUOTATION_APPROVE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedApproveQuotation: action.payload,
      };
    case QUOTATION_APPROVE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case QUOTATION_APPROVE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const QuotationApprovedListReducer = (
  state = { quotationApproved: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case QUOTATION_APPROVED_LIST_REQUEST:
      return { loading: true };
    case QUOTATION_APPROVED_LIST_SUCCESS:
      return {
        loading: false,
        quotationApproved: action.payload,
      };
    case QUOTATION_APPROVED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Box,
  Toolbar,
  Button as MuiButton,
  createFilterOptions,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import Button from "../../core/controls/Button";

import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import {
  listDetailsEditPackingStickerAction,
  listSalesOrderListForPackingAndEditAction,//for Grid
  listSalesOrderInternalDetails,
} from "../../redux/actions/invoiceActions";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import { Form } from "react-bootstrap";
import {
  SALESORDERINTERNAL_FILTER_LIST_RESET,
  SALESORDERINTERNAL_PACKAGE_RESET,
  SALESORDERINTERNAL_UPDATE_RESET,
} from "../../redux/constants/invoiceConstants";
import SalesOrderView from "../../core/SalesOrderView";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import { useFormik } from "formik";
import SalesOrderStickerModal from "../../core/SalesOrderStickerModal";
import KarjadeshView from "../../core/KarjadeshView";

// Define column widths for the data grid
const columnWidths = {
  soSystemNo: "150px",
  invoiceDate: "250px",
  customerName: "230px",
  customerAddress: "280px",
  jobType: "150px",
  soSystemId: "100px",
  
};

// Define styling for the components using Material-UI makeStyles
const useStyles = makeStyles(() => ({
   // Styles for the header and layout of the data grid
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },
  // Styling for various elements within the data grid
    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },
// Styling for individual column headers in the data grid
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#000000",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: (props) => columnWidths[props.field] || "auto",
      maxWidth: (props) => columnWidths[props.field] || "auto",
    },
 // Styling for individual cells in the data grid
    "& .MuiDataGrid-cell": {
      minWidth: (props) => columnWidths[props.field] || "auto",
      maxWidth: (props) => columnWidths[props.field] || "auto",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#000000",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='soSystemId'], .MuiDataGrid-cell[data-field='soSystemId']":
      {
        // minWidth: "60px !important",
        // maxWidth: "60px !important",
        display: "none",
      },
    "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3'], .MuiDataGrid-columnHeader[data-field='actions4'], .MuiDataGrid-cell[data-field='actions4']":
      {
        minWidth: "100px !important",
        maxWidth: "100px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#000000",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
    
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: `${useTheme().spacing(0)} ${useTheme().spacing(1)}`,
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },

  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(3),
  },
  pageContent2: {
    overflowX: "auto",
    justifyContent: "space-between",
    margin: useTheme().spacing(1),
    padding: `${useTheme().spacing(2)} ${useTheme().spacing(1)}`,
    [useTheme().breakpoints.up("md")]: {
      padding: `${useTheme().spacing(2)} ${useTheme().spacing(7)}`,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "none",
    justifyContent: "center !important",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    "& div:nth-child(1)": {
      margin: "15px",
    },
    [useTheme().breakpoints.up("md")]: {
      display: "none",
      justifyContent: "center !important",
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
      "& div:nth-child(1)": {
        margin: "0px",
      },
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "420px",
    },
  },
  actions2: {
    display: "flex",
    justifyContent: "space-between",
    width: "500px",
    padding: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "right",
      width: "420px",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
// Initial values for date and customer in the form
const initialValues = {
  startDate: [date.getFullYear(), mnth, day].join("-"),
  endDate: [date.getFullYear(), mnth, day].join("-"),
  customer: {
    id: "",
    label: "",
  },
};

// Functional component for the main page
const PrintInfoPanel = ({ history }) => {
  const dispatch = useDispatch();
  // Material-UI styling hook
  const classes = useStyles();

  // Redux state and dispatch usage for fetching data

  const salesOrderInternalPackage = useSelector(
    (state) => state.salesOrderInternalPackage
  );
  const {
    loading: loadingPackage,
    error: errorPackage,
    success: successPackage,
    packagedSalesOrder,
  } = salesOrderInternalPackage;
  
  const listOrderForPackingAndEditState = useSelector(
    (state) => state.listOrderForPackingAndEditState
  );
  const { loading, error,orderList } = listOrderForPackingAndEditState;


  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  const employeeDelete = useSelector((state) => state.employeeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = employeeDelete;

  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = employeeUpdate;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

  const packingDetailsState = useSelector(
    (state) => state.packingDetailsState
  );
  const {
    loading: loadingDoneDetails,
    error: errorDoneDetails,
    salesorderinternalpackagingdonesingle,
  } = packingDetailsState;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whatOpen, setWhatOpen] = useState(-1);
  const [isOpenSticker, setIsOpenSticker] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("5");
  const [isOpenKarjadesh, setIsOpenKarjadesh] = useState(false);
  const [dataForEscalation, setDataForEscalation] = useState([]);
  const selectedOrderRef = useRef(null);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };
 
  // Formik hook for form management
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
       // Handle form submission and dispatch relevant actions
      let data = {
        startDate: values.startDate,
        endDate: values.endDate,
        customerId: values.customer.id !== "" ? values.customer.id : 0,
      };
      dispatch(listSalesOrderListForPackingAndEditAction(selectedOrder)).then(
        (result) => {
          
          if (result?.title === "One or more validation errors occurred.") {
            action.setSubmitting(false);
          }
        }
      );
    },
  });

 // UseEffect hook to handle side effects based on Redux state changes
  useEffect(() => {
    if (userInfo) {
      dispatch(listSalesOrderListForPackingAndEditAction(selectedOrder));
      // dispatch(listSalesOrderInternalFilterReducer(selectedOrder));
      dispatch(listCustomersForSignup());
      dispatch({ type: SALESORDERINTERNAL_FILTER_LIST_RESET });
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: SALESORDERINTERNAL_UPDATE_RESET });
      }
      if (successPackage) {
        setNotify({
          isOpen: true,
          message: "Saved Successfully",
          type: "success",
        });

        dispatch({ type: SALESORDERINTERNAL_PACKAGE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/salesorderinternal" } },
      };
      history.push(location);
    }

  }, [
    dispatch,
    classes,
    history,
    userInfo,
    successDelete,
    successUpdate,
    selectedOrder,
    
  ]);
// Functions to handle various events and actions

  const handleEvent = (params) => {
    // history.push(`/employee/${params.row.employeeId}/edit`);
    // console.log(params.row);
  };

  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={orderList}
          columns={
            selectedOrder === "0"
              ? [...columns_part_a, ...columns_part_b_O_0]
              : selectedOrder === "5"
              ? [...columns_part_b_O_3, ...columns_part_a]
              : selectedOrder === "4"
              ? [...columns_part_b_O_0, ...columns_part_a]
              : columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.soSystemId + "-" + generateRandom()}
          onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
           autoHeight //data grid height
        />
      </div>
      
    );
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      if (selectedOrder === "0") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
      orderList.find((row) => row.soSystemId === id)
      );
      setDataForEscalation([selectedRowsData[0].soSystemId]);
      setRowId(selectedRowsData[0].soSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  // Functions to open and close various modals
  
  const openStickerModal = (id) => {
    setWhatOpen(5);

    dispatch(listDetailsEditPackingStickerAction(id)).then((res) => {
      //console.log("res===> ", res);
      setIsOpenSticker(true);
    });
  };

  const closeStickerModal = (event) => {
    setWhatOpen(-1);
    setIsOpenSticker(false);
  };

  const closeModal = (event) => {
    setWhatOpen(-1);
    setIsOpen(false);
  };

  const openModalKarjadesh = (id) => {
    setWhatOpen(4);
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpenKarjadesh(true);
    });
    //
  };
  const closeModalKarjadesh = (event) => {
    setWhatOpen(-1);
    setIsOpenKarjadesh(false);
  };
  
  const columns_part_a = [
    {
      field: "soSystemNo",
      headerName: "Order No",
      headerAlign: "center",
      align: "center",
      width: "200",
    },
    {
      field: "invoiceDate",
      headerName: "Order Date",
      headerAlign: "center",
      align: "center",
      width: "230",
    },
    {
      field: "customerName",
      headerName: "Partner Name",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerName || params.row.supplierName,
      width: "350",
    },
    {
      field: "customerAddress",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerAddress || params.row.supplierAddress,
      width: "450",
    },
    {
      field: "jobType",
      headerName: "Order Type",
      headerAlign: "center",
      align: "center",
      width: "200",
    },
    {
      field: "soSystemId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
  ];

  const columns_part_b_O_0 = [
    {
      field: "actions2",
      headerName: "MINI INVOICE",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PACKING LIST"
            focuselementref={selectedOrderRef}
            startIcon={<PrintIcon fontSize="small"
            
            style={{ color: "#5434DD", fontSize: "38px" }}
            />}
            
            text="PACKING LIST"
            //color="secondary"
            onClick={() => {
              openModalKarjadesh(row.soSystemId);
            }}
            
          />
        );
      },
    },
  ];
  const columns_part_b_O_3 = [
    {
      field: "actions5",
      headerName: "STICKER",
      headerClassName: "sticker-header",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="STICKER"
            focuselementref={selectedOrderRef}
            startIcon={
              <StickyNote2Icon
                fontSize="small"
                style={{ color: "#f84d37", fontSize: "38px" }}
              />
            }
            
            text="STICKER"
            color="success"
            onClick={() => {
              openStickerModal(row.soSystemId);
            }}
            style={{ width: "fit-content" }}
          />
        );
      },
    
    },
  ];

  const isOptionEqualToValue = useCallback(
    (option, value) => option.value === value.value
  );

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
 
    // JSX structure for rendering the component
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Print Module"
        subtitle={ selectedOrder === "5"
        ? "Print Sticker for Packing"
        : "Print Mini Invoice"
      }        
      />
        {loadingDelete && <>loading..</>}
      {
        <>
          {error === "Network Error" ? (
            <div className={classes.wrapperWhite}>
              <Toolbar
                className={classes.toolBar}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  className="m-4"
                  src={process.env.PUBLIC_URL + "/images/networkerror.png"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "500px", height: "auto" }}
                />
              </Toolbar>
            </div>
          ) : loading ? (
            <div
              className="text-center"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <InfinitySpin width="200" color="#6C4AB6" />
            </div>
          ) : (

        <div className={classes.wrapperWhite} >
          <Toolbar className={classes.toolBar}>
            <Box className={classes.actions3} >
              <Button
                // startIcon={<AddIcon fontSize="small" />}
                text="STICKER FOR PACKING"
                color="success"
                onClick={() => {
                  setSelectedOrder("5");
                  setRowId(null);
                  setRowClicked(false);
                  formik.resetForm();
                }}
                disabled={selectedOrder === "5" ? true : false}
              />
              <Button
                text="MINI INVOICE"
                color="error"
                onClick={() => {
                  setSelectedOrder("4");
                  setRowId(null);
                  setRowClicked(false);
                  formik.resetForm();
                }}
                disabled={selectedOrder === "4" ? true : false}
              />
            </Box>
          </Toolbar>
          
              {gridBasedOnSalesOrderType()}
                {/*  Sticker  */}
              {JSON.stringify(salesorderinternalsingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 1 && (
                  <SalesOrderView
                    showModal={isOpen}
                    closeModal={closeModal}
                    info={salesorderinternalsingle}
                    items={salesorderinternalsingle.stockOutItemsData}
                    currency={"TK"}
                    subTotal={
                      salesorderinternalsingle.stockOutBillData.calculatedBill
                    }
                    taxAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billVatPercantage
                    }
                    discountAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billDiscountAmount
                    }
                    total={salesorderinternalsingle.stockOutBillData.finalBill}
                  />
                )}
                  
              {JSON.stringify(salesorderinternalpackagingdonesingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 5 && (
                  <SalesOrderStickerModal
                    showModal={isOpenSticker}
                    closeModal={closeStickerModal}
                    item_info={salesorderinternalpackagingdonesingle}
                  />
                )}

              {/*  MiniInvice  */}
              {JSON.stringify(salesorderinternalsingle) !== "{}" &&
                salesorderinternalsingle !== undefined &&
                whatOpen === 4 && (
                  <KarjadeshView
                    showModal={isOpenKarjadesh}
                    closeModal={closeModalKarjadesh}
                    info={salesorderinternalsingle}
                    items={salesorderinternalsingle.stockOutItemsData}
                    currency={"TK"}
                    subTotal={
                      salesorderinternalsingle.stockOutBillData.calculatedBill
                    }
                    taxAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billVatPercantage
                    }
                    discountAmmount={
                      salesorderinternalsingle.stockOutBillData
                        .billDiscountAmount
                    }
                    total={salesorderinternalsingle.stockOutBillData.finalBill}
                  />
                )}

              <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
              <Notification notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
            </div>
          )}
        </>
      }
    </div>
  );
};
export default PrintInfoPanel;

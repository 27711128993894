export const PURCHASE_PRICE_LIST_REQUEST = "PURCHASE_PRICE_LIST_REQUEST";
export const PURCHASE_PRICE_LIST_SUCCESS = "PURCHASE_PRICE_LIST_SUCCESS";
export const PURCHASE_PRICE_LIST_FAIL = "PURCHASE_PRICE_LIST_FAIL";

export const PURCHASE_PRICE_HISTORY_DETAILS_REQUEST =
  "PURCHASE_PRICE_HISTORY_DETAILS_REQUEST";
export const PURCHASE_PRICE_HISTORY_DETAILS_SUCCESS =
  "PURCHASE_PRICE_HISTORY_DETAILS_SUCCESS";
export const PURCHASE_PRICE_HISTORY_DETAILS_FAIL =
  "PURCHASE_PRICE_HISTORY_DETAILS_FAIL";
export const PURCHASE_PRICE_HISTORY_DETAILS_RESET =
  "PURCHASE_PRICE_HISTORY_DETAILS_RESET";

export const PRODUCT_PURCHASE_PRICE_CREATE_REQUEST =
  "PRODUCT_PURCHASE_PRICE_CREATE_REQUEST";
export const PRODUCT_PURCHASE_PRICE_CREATE_SUCCESS =
  "PRODUCT_PURCHASE_PRICE_CREATE_SUCCESS";
export const PRODUCT_PURCHASE_PRICE_CREATE_FAIL =
  "PRODUCT_PURCHASE_PRICE_CREATE_FAIL";
export const PRODUCT_PURCHASE_PRICE_CREATE_RESET =
  "PRODUCT_PURCHASE_PRICE_CREATE_RESET";

export const PRODUCT_PURCHASE_PRICE_DETAILS_REQUEST =
  "PRODUCT_PURCHASE_PRICE_DETAILS_REQUEST";
export const PRODUCT_PURCHASE_PRICE_DETAILS_SUCCESS =
  "PRODUCT_PURCHASE_PRICE_DETAILS_SUCCESS";
export const PRODUCT_PURCHASE_PRICE_DETAILS_FAIL =
  "PRODUCT_PURCHASE_PRICE_DETAILS_FAIL";
export const PRODUCT_PURCHASE_PRICE_DETAILS_RESET =
  "PRODUCT_PURCHASE_PRICE_DETAILS_RESET";

export const PRODUCT_PURCHASE_PRICE_UPDATE_REQUEST =
  "PRODUCT_PURCHASE_PRICE_UPDATE_REQUEST";
export const PRODUCT_PURCHASE_PRICE_UPDATE_SUCCESS =
  "PRODUCT_PURCHASE_PRICE_UPDATE_SUCCESS";
export const PRODUCT_PURCHASE_PRICE_UPDATE_FAIL =
  "PRODUCT_PURCHASE_PRICE_UPDATE_FAIL";
export const PRODUCT_PURCHASE_PRICE_UPDATE_RESET =
  "PRODUCT_PURCHASE_PRICE_UPDATE_RESET";

export const SELLING_PRICE_LIST_REQUEST = "SELLING_PRICE_LIST_REQUEST";
export const SELLING_PRICE_LIST_SUCCESS = "SELLING_PRICE_LIST_SUCCESS";
export const SELLING_PRICE_LIST_FAIL = "SELLING_PRICE_LIST_FAIL";

export const SELLING_PRICE_HISTORY_DETAILS_REQUEST =
  "SELLING_PRICE_HISTORY_DETAILS_REQUEST";
export const SELLING_PRICE_HISTORY_DETAILS_SUCCESS =
  "SELLING_PRICE_HISTORY_DETAILS_SUCCESS";
export const SELLING_PRICE_HISTORY_DETAILS_FAIL =
  "SELLING_PRICE_HISTORY_DETAILS_FAIL";
export const SELLING_PRICE_HISTORY_DETAILS_RESET =
  "SELLING_PRICE_HISTORY_DETAILS_RESET";

export const PRODUCT_SELLING_PRICE_CREATE_REQUEST =
  "PRODUCT_SELLING_PRICE_CREATE_REQUEST";
export const PRODUCT_SELLING_PRICE_CREATE_SUCCESS =
  "PRODUCT_SELLING_PRICE_CREATE_SUCCESS";
export const PRODUCT_SELLING_PRICE_CREATE_FAIL =
  "PRODUCT_SELLING_PRICE_CREATE_FAIL";
export const PRODUCT_SELLING_PRICE_CREATE_RESET =
  "PRODUCT_SELLING_PRICE_CREATE_RESET";

export const PRODUCT_SELLING_PRICE_DETAILS_REQUEST =
  "PRODUCT_SELLING_PRICE_DETAILS_REQUEST";
export const PRODUCT_SELLING_PRICE_DETAILS_SUCCESS =
  "PRODUCT_SELLING_PRICE_DETAILS_SUCCESS";
export const PRODUCT_SELLING_PRICE_DETAILS_FAIL =
  "PRODUCT_SELLING_PRICE_DETAILS_FAIL";
export const PRODUCT_SELLING_PRICE_DETAILS_RESET =
  "PRODUCT_SELLING_PRICE_DETAILS_RESET";

export const PRODUCT_SELLING_PRICE_UPDATE_REQUEST =
  "PRODUCT_SELLING_PRICE_UPDATE_REQUEST";
export const PRODUCT_SELLING_PRICE_UPDATE_SUCCESS =
  "PRODUCT_SELLING_PRICE_UPDATE_SUCCESS";
export const PRODUCT_SELLING_PRICE_UPDATE_FAIL =
  "PRODUCT_SELLING_PRICE_UPDATE_FAIL";
export const PRODUCT_SELLING_PRICE_UPDATE_RESET =
  "PRODUCT_SELLING_PRICE_UPDATE_RESET";

import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash, BiPlusCircle } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import EditableField from "./EditableField";
import EditableProductField from "./EditableProductField";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useCallback } from "react";
import { Autocomplete, Chip } from "@mui/material";
import Input from "./controls/Input";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import EditIcon from "@mui/icons-material/Edit";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./controls/DropDown.css";

import EditProductDropdownField from "./controls/EditProductDropdownField";
import EditProductDropdownFieldMultiple from "./controls/EditProductDropdownFieldMultiple";

const TransportationItem = (props) => {
  // const { switchMappingOption } = this.state;
  // var setSwitchMappingOption = this.setState;

  const [dataFilteredTask, setDataFilteredTask] = useState();
  var onItemizedItemDestinationReach = props.onItemizedItemDestinationReach;
  var onItemizedItemViewForTransports = props.onItemizedItemViewForTransports;
  var onItemizedItemViewForBatches = props.onItemizedItemViewForBatches;
  var onItemizedItemAddForBatches = props.onItemizedItemAddForBatches;
  var onItemizedItemAddForMaps = props.onItemizedItemAddForMaps;
  var onItemizedItemViewForMaps = props.onItemizedItemViewForMaps;
  var onItemizedBatchEdit = props.onItemizedBatchEdit;
  var onItemizedItemEdit = props.onItemizedItemEdit;
  var onItemizedBatchAndMapEdit = props.onItemizedBatchAndMapEdit;
  var currency = props.currency;
  var rowDel = props.onRowDel;
  var batchDel = props.onBatchDel;
  var packDel = props.onPackDel;
  var transportationDel = props.onTransportationDel;
  var taskKey = props.taskKey;
  // console.log("dataFilteredTask");
  // console.log(dataFilteredTask);
  // console.log("props.data");
  // console.log(props.data);
  var itemTable =
    dataFilteredTask !== undefined &&
    dataFilteredTask.taskTransportationData.length > 0 &&
    dataFilteredTask.taskTransportationData.map(function (item) {
      // console.log("item");
      // console.log(item);
      return (
        <ItemRow2
          onItemizedItemDestinationReach={onItemizedItemDestinationReach}
          onItemizedItemViewForTransports={onItemizedItemViewForTransports}
          onItemizedItemViewForBatches={onItemizedItemViewForBatches}
          onItemizedItemAddForBatches={onItemizedItemAddForBatches}
          onItemizedItemAddForMaps={onItemizedItemAddForMaps}
          onItemizedItemViewForMaps={onItemizedItemViewForMaps}
          onItemizedBatchEdit={onItemizedBatchEdit}
          onItemizedItemEdit={onItemizedItemEdit}
          onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
          item={item}
          onTransportationDelEvent={transportationDel}
          onDelEvent={rowDel.bind(this)}
          onBatchDelEvent={batchDel.bind(this)}
          onPackDelEvent={packDel.bind(this)}
          key={item.taskTransportationAutoId}
          taskId={props.taskKey}
          currency={currency}
        />
      );
    });

  const changeValues = () => {
    // formik.setFieldValue("productId", productId);
    // console.log("props.key");
    // console.log(props.taskKey);
    if (props.data !== undefined && props.taskKey !== undefined) {
      let filteredTask = props.data.items.filter((singleData) => {
        // console.log("singleData.taskId === props.taskKey");
        // console.log(String(singleData.taskId) === String(props.taskKey));
        return String(singleData.taskId) === String(props.taskKey);
      });
      // console.log("props.data.items");
      // console.log(props.data.items);
      // console.log("filteredTask");
      // console.log(filteredTask);
      setDataFilteredTask(filteredTask[0]);
    }
  };

  useEffect(() => {
    changeValues();
  }, [props.data, props.taskKey]);
  return (
    <div style={{ position: "relative !important" }}>
      <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
        <Table>
          <thead>
            <tr>
              <th className="text-center">From</th>
              <th className="text-center">To</th>
              <th className="text-center">Type</th>
              <th className="text-center">Fare</th>
              <th className="text-center">Remarks</th>
              <th className="text-center">Settings</th>

              {/* <th className="text-center">Edit</th>
              <th className="text-center">Reached</th>
              <th className="text-center">Delete</th> */}
            </tr>
          </thead>
          <tbody>{itemTable}</tbody>
        </Table>
      </div>
    </div>
  );
};

const ItemRow2 = (props) => {
  var myRef = React.useRef(null);
  const onDelEvent = () => {
    props.onDelEvent(props.item);
  };
  //   const [switchMappingOption, setSwitchMappingOption] = useState(false);

  //   const onDelEvent = () => {
  //     props.onDelEvent(props.item);
  //   };

  //   useEffect(() => {
  //     if (props?.item?.mappedProductInfo?.id !== "") {
  //       setSwitchMappingOption(true);
  //     }
  //   }, [props.item.mappedProductInfo]);

  // console.log("props.item");
  // console.log(props.item);

  return (
    <tr
      ref={myRef}
      style={{
        opacity:
          props.item.taskTransportationEndLat !== null &&
          props.item.taskTransportationEndLong !== null
            ? 0.4
            : 1,
        cursor:
          props.item.taskTransportationEndLat !== null &&
          props.item.taskTransportationEndLong !== null
            ? "not-allowed"
            : "pointer",
      }}
    >
      <td className="text-center">
        {props.item.taskTransportationStartLocation}
      </td>
      <td className="text-center">
        {props.item.taskTransportationEndLocation}
      </td>
      <td className="text-center">{props.item.vehicleName}</td>
      <td className="text-center">{props.item.taskTransportationCost}</td>
      <td className="text-center">{props.item.taskTransportationRemarks}</td>
      <td className="text-center">
        <DropdownButton
          id="dropdown-basic-button"
          title={<FiSettings />}
          size="sm"
          className="rbt2 text-center new-rbt2 text-white"
          variant="warning"
          disabled={
            props.item.taskTransportationEndLat !== null &&
            props.item.taskTransportationEndLong !== null
              ? true
              : false
          }
        >
          <Dropdown.Item
            onClick={() => {
              props.onItemizedItemViewForTransports({
                id: props.taskId,
                transportationId: props.item.taskTransportationAutoId,
              });
            }}
          >
            <EditIcon /> Edit
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              props.onTransportationDelEvent(
                props.taskId,
                props.item.taskTransportationAutoId
              );
            }}
          >
            <DeleteIcon /> Delete
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.onItemizedItemDestinationReach({
                id: props.taskId,
                transportationId: props.item.taskTransportationAutoId,
              });
            }}
          >
            <AccessibilityNewIcon /> Reached
          </Dropdown.Item>
        </DropdownButton>
      </td>

      {/* <td className="text-center">
        <Button
          variant="outline-info"
          onClick={() => {
            props.onItemizedItemViewForTransports({
              id: props.taskId,
              transportationId: props.item.taskTransportationAutoId,
            });
          }}
          size="sm"
        >
          Edit
        </Button>
      </td>
      <td className="text-center">
        <Button
          variant="outline-success"
          onClick={() => {
            props.onItemizedItemAddForBatches({
              id: props.item.productId,
            });
          }}
          size="sm"
        >
          Reached
        </Button>
      </td>

      <td className="text-center" style={{ minWidth: "50px" }}>
        <BiTrash
          onClick={() => {
            props.onTransportationDelEvent(
              props.taskId,
              props.item.taskTransportationAutoId
            );
          }}
          style={{ height: "33px", width: "33px", padding: "7.5px" }}
          className="text-white btn btn-danger"
        />
      </td> */}
    </tr>
  );
};
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      switchMappingOption: false,
    };
  }
  onDelEvent() {
    this.props.onDelEvent(this.props.item);
  }

  render() {
    const { switchMappingOption } = this.state;
    // linear-gradient(45deg, #eee 35%, transparent 10%, transparent 75%, #eee 85%, #eee 100%),linear-gradient(45deg, #eee 29%, white 60%, white 62%, #eee 83%, #eee 100%)
    return (
      <tr ref={this.myRef}>
        <td style={{ width: "200px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "text",
              name: "productName",
              placeholder: "Item name",
              value: this.props.item.productName,
              id: this.props.item.productName,
            }}
            style={{ width: "200px" }}
            disabled
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "number",
              name: "quantity",
              textAlign: "center",
              step: "1",
              value: this.props.item.quantity,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        {/* <td style={{ minWidth: "130px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              min: 1,
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.unitPrice,
            }}
            disabled={this.props.escalate}
          />
        </td> */}
        <td style={{ minWidth: "150px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "lineTotal",
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.lineTotal,
              id: this.props.item.lineTotal,
            }}
            disabled
          />
        </td>
        {/* <td></td> */}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <>
            <td style={{ minWidth: "auto", display: "flex" }}>
              <div>
                <InputGroup.Text id="basic-addon1">
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label=""
                    className=""
                    checked={this.props.switchMappingOption}
                    onChange={(e) => {
                      this.setState({
                        switchMappingOption: !switchMappingOption,
                      });
                    }}
                  />
                </InputGroup.Text>
              </div>
              <div style={{ marginLeft: "9px" }}>
                {switchMappingOption ? (
                  <Form.Group
                    controlId="form-productId"
                    style={{ minWidth: "250px" }}
                  >
                    <EditProductDropdownField
                      name="sodMappedProductId"
                      itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                      info={this.props.item.mappedProductInfo}
                      item={this.props.item.mappedItemList}
                      allItem={this.props.item}
                      placeholder="Map with a product"
                      // disabled
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
              </div>
            </td>
            <td style={{ minWidth: "250px" }}>
              <Form.Group controlId="form-productId">
                <EditProductDropdownField
                  name="sodRealatedBatchId"
                  itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                  info={this.props.item.batchInfo}
                  item={this.props.item.batchList}
                  allItem={this.props.item}
                  placeholder="Batch #"
                  // disabled
                />
              </Form.Group>
            </td>
          </>
        ) : (
          ""
        )}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <></>
        ) : (
          <td className="text-center" style={{ minWidth: "50px" }}>
            <BiTrash
              onClick={this.onDelEvent.bind(this)}
              style={{ height: "33px", width: "33px", padding: "7.5px" }}
              className="text-white mt-1 btn btn-danger"
            />
          </td>
        )}
      </tr>
    );
  }
}

export default TransportationItem;

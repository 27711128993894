import React, { useState, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import styled from "@emotion/styled";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { API_URL } from "./../../redux/constants/apiConstants";
import axios from "axios";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Input from "../../core/controls/Input";
import { useFormik } from "formik";

const initialValues = {
  work_status: {
    id: "",
    label: "",
  },
  remarks: "",
};

export const DefinePendingStatusModal = ({
  showModal,
  closeModal,
  item_info,
  userInfo,
}) => {
  //   console.log("item info: ", item_info);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [statusDropdownList, getStatusDropdownList] = useState([]);
  const [remarksValue, setRemarksValue] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState(null);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const updatePendingStatus = async () => {
    setUpdateLoading(true);
    let workStatusId = item_info?.soAssociateCurrentStatus;
    if (formik.values.work_status.id !== "") {
      workStatusId = formik.values.work_status.id;
    }

    let remarksValue = item_info?.actionTakenRemarks;

    if (formik.values.remarks !== "") {
      remarksValue = formik.values.remarks;
    }

    const axios_config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    const api_endpoint = `${API_URL}/StockOut/UpdateStockOutAssociateCurrentStatus/${
      item_info?.soSystemId
    }/${workStatusId}?soAssociateStatusRemarks=${encodeURIComponent(
      remarksValue
    )}`;

    const response = await axios.put(api_endpoint, {}, axios_config);

    if (response?.errMsg) {
      setDropdownLoading(false);
      setAlertOpen(true);
      setAlertSeverity("error");
      setAlertMsg("There was some error, please try again.");
    } else {
      if (response.data.statusCode === 200) {
        setAlertOpen(true);
        setAlertSeverity("success");
        setAlertMsg("Update Successfully done");
      }
    }
    setUpdateLoading(false);
  };

  const filterDropdownData = (list) => {
    const updatedList = [];

    for (const value of list) {
      updatedList.push({
        id: value?.key,
        label: value?.value,
      });
    }

    return updatedList;
  };

  const getPendingDropdownList = async () => {
    setDropdownLoading(true);
    const axios_config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    const api_endpoint = `${API_URL}/KeyValue/GetLifecyclesInKeyValue/stock_out/true`;

    const response = await axios.get(api_endpoint, axios_config);
    const { data } = response;

    if (response?.errMsg) {
      setDropdownLoading(false);
    } else {
      setDropdownLoading(false);
      if (data?.dataObj?.length > 0) {
        getStatusDropdownList(filterDropdownData(data?.dataObj));
      }
    }
  };

  const formik = useFormik({
    initialValues,
  });

  const getDropdownValueWithLabel = (id) => {
    const filteredId = statusDropdownList?.filter((data) => {
      return data?.id === id;
    });
    if (filteredId.length > 0) return filteredId[0];
    return null;
  };

  const isOptionEqualToValue = useCallback(
    (option, value) => option.value === value.value
  );

  const statusChange = useCallback((e, v) => {
    formik.setFieldValue("work_status", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const remarksChange = (event) => {
    formik.setFieldValue("remarks", event.target.value);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  useState(() => {
    getPendingDropdownList();
  }, []);

  return (
    <div>
      <StyledDefinePendingStatus
        show={showModal}
        onHide={closeModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Row responsive>
          {alertOpen ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity={alertSeverity}>{alertMsg}</Alert>
            </Stack>
          ) : null}
          <div className="table-content">
            <Table bordered hover>
              <tr>
                <td>Invoice #</td>
                <td>{item_info?.soSystemNo}</td>
              </tr>
              <tr>
                <td>Invoice Date</td>
                <td>{item_info?.invoiceDate}</td>
              </tr>
              <tr>
                <td>Customer Name</td>
                <td>{item_info?.customerName}</td>
              </tr>
              <tr>
                <td>Sales Type</td>
                <td>{item_info?.customerAddress}</td>
              </tr>
              <tr>
                <td>Work pending</td>
                <td>{item_info?.soSystemPending}</td>
              </tr>
              <tr>
                <td>Work pending Type</td>
                <td>
                  <Autocomplete
                    size="small"
                    disabled={dropdownLoading ? true : false}
                    id="combo-box-demo-channel"
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={statusChange}
                    value={
                      formik?.values?.work_status?.id !== ""
                        ? formik?.values?.work_status
                        : item_info?.soAssociateCurrentStatus !== null
                        ? {
                            ...getDropdownValueWithLabel(
                              item_info?.soAssociateCurrentStatus
                            ),
                          }
                        : formik?.values?.work_status
                    }
                    options={
                      statusDropdownList !== undefined ? statusDropdownList : []
                    }
                    onOpen={formik.handleBlur}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Status Name"
                        name="status"
                        error={
                          formik.errors.work_status !== undefined
                            ? formik.errors.work_status.id
                            : ""
                        }
                        touched={
                          formik.touched.work_status !== undefined
                            ? formik.touched.work_status
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>Remarks</td>
                <td>
                  <TextField
                    onChange={(e) => remarksChange(e)}
                    value={
                      formik.values.remarks !== ""
                        ? formik.values.remarks
                        : item_info?.actionTakenRemarks
                    }
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    onClick={() => updatePendingStatus()}
                    variant="warning"
                    size="sm"
                    style={{ width: "100%", background: "#ffc107" }}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            </Table>
          </div>
        </Row>
      </StyledDefinePendingStatus>
    </div>
  );
};

const StyledDefinePendingStatus = styled(Modal)`
  .modal-dialog {
    .modal-content {
      overflow-y: auto;

      .table-content {
        width: 90%;
        padding-top: 20px;
        margin: 0 auto;

        .table {
          tr {
            td {
              border: 1px solid #00000036;
              width: 50%;
              padding: 10px;
            }
          }
        }
      }
    }
  }
`;

export default DefinePendingStatusModal;

import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  GET_DAILY_INVOICE_TRACKING_REQUEST,
  GET_DAILY_INVOICE_TRACKING_SUCCESS,
  GET_DAILY_INVOICE_TRACKING_FAIL,
} from "../constants/dailyInvoiceTrackingConstants";

export const listDailyInvoiceTrackingReport =
  (datas) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_DAILY_INVOICE_TRACKING_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/Report/GetDailyActivityTAT?startDate=${datas.startDate}&endDate=${datas.endDate}&parterId=${datas.customerID}`,
        //url: `${API_URL}/Report/GetDailyActivityTAT?startDate=10%2F08%2F2023&endDate=10%2F12%2F2023&parterId=0`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);

      dispatch({
        type: GET_DAILY_INVOICE_TRACKING_SUCCESS,
        payload: data.dataObj,
      });
    } catch (error) {
      dispatch({
        type: GET_DAILY_INVOICE_TRACKING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { CustomerSchema, SupplierSchema } from "../../schemas";
import { createEmployee } from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import { createCustomer } from "../../redux/actions/customerActions";
import { API_URL } from "../../redux/constants/apiConstants";
import { CUSTOMER_CREATE_RESET } from "../../redux/constants/customerConstants";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDesignation,
  listEmployeeByChannel,
} from "../../redux/actions/channelActions";
import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import { createSupplier } from "../../redux/actions/supplierActions";
import { SUPPLIER_CREATE_RESET } from "../../redux/constants/supplierConstants";



const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

const initialValues = {
  supplierName: "",
  supplierAddress: "",
  supplierContactPerson: "",
  supplierContactNumber: "",
 // channel: {
   // id: "",
    //label: "",
  //},
  locationId:"",
  supplierLat: "",
  supplierLong: "",
};

const AddSupplier = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [departments, setDepartments] = useState([]);
  // const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
  // const { values, setValues, handleChange } = useForm(initialValues);
  const channelList = useSelector((state) => state.channelList);
  const {
    loading: loadingChannels,
    error: errorChannels,
    channels,
  } = channelList;

  const supplierCreate = useSelector((state) => state.supplierCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    supplier: createdSupplier,
  } = supplierCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: SupplierSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

        dispatch(
            createSupplier({
              supplierId: 0,
              supplierName: values.supplierName,
              supplierAddress: values.supplierAddress,
              //locationId: values.channel.id,
              supplierContactPerson: values.supplierContactPerson,
              supplierContactNumber: values.supplierContactNumber,
              supplierLat: null,
              supplierLong: null,
            })
          ).then((result) => {
            if (result?.errMsg) {
              // console.log(result);
              if (result.errMsg == "Supplier Already Exist") {
                action.setFieldError("errorClientName", result.errMsg);
              } else if (result.errMsg == "Contact Number Already Exist") {
                action.setFieldError("errorClientPhone", result.errMsg);
              }

              action.setSubmitting(false);
            } else {
              if (checked === true) {
                action.resetForm();
              } else {
                history.push("/suppliers");
              }
            }
          }); 
      },
    });

  


  useEffect(() => {
    if (userInfo) {

  
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: SUPPLIER_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/suppliers/add" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Suppliers"
        subtitle="Add a Supplier"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Input
                label="Supplier Name"
                name="supplierName"
                error={formik.errors.supplierName}
                extraError={formik.errors.errorClientName}
                touched={formik.touched.supplierName}
                value={formik.values.supplierName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Supplier Address"
                name="supplierAddress"
                error={formik.errors.supplierAddress}
                touched={formik.touched.supplierAddress}
                value={formik.values.supplierAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Input
                label="Contact Person Name"
                name="supplierContactPerson"
                error={formik.errors.supplierContactPerson}
                touched={formik.touched.supplierContactPerson}
                value={formik.values.supplierContactPerson}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Contact #"
                name="supplierContactNumber"
                error={formik.errors.supplierContactNumber}
                extraError={formik.errors.errorClientPhone}
                touched={formik.touched.supplierContactNumber}
                value={formik.values.supplierContactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />
            </Grid>
                <Grid></Grid>
            <Grid item xs={12} md={12}>
              <Checkbox
                label="Stay in this Page"
                name="stay"
                value={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/suppliers",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loadingChannels ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(AddSupplier);

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const resetForm = () => {
    setValues(initialValues);
    // have to set errors to empty object as well
  };
  return {
    values,
    setValues,
    handleChange,
    resetForm,
  };
};

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: useTheme().spacing(0.5),
      [useTheme().breakpoints.up("md")]: {
        width: "80%",
        margin: useTheme().spacing(1),
      },
    },
    "& .fullwidth-input": {
      width: "100%",
      [useTheme().breakpoints.up("md")]: {
        width: "90% !important",
      },
    },
    "& .fullwidth-input-task": {
      width: "97%",
      [useTheme().breakpoints.up("md")]: {
        width: "90% !important",
      },
    },
  },
}));

export const Form = (props) => {
  const { children, ...other } = props;
  const classes = useStyles();

  return (
    <form className={classes.root} {...other}>
      {props.children}
    </form>
  );
};

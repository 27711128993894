import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Box,
  Tooltip,
  Toolbar,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../core/Menu";
import PageHeader from "../core/PageHeader";
import { useForm, Form } from "../core/useForm";
import Input from "../core/controls/Input";
import RadioGroup from "../core/controls/RadioGroup";
import Select from "../core/controls/Select";
import Checkbox from "../core/controls/Checkbox";
import Datepicker from "../core/controls/Datepicker";
import Button from "../core/controls/Button";
import { CustomerSchema, RoleSchema } from "../schemas";
import { useEffect } from "react";
import { createCustomer } from "../redux/actions/customerActions";
import { API_URL } from "../redux/constants/apiConstants";
import {
  createAssignedMenu,
  createRole,
  createUnassignedMenu,
  listRoles,
  listRolesDetails,
  updateAssignedMenus,
} from "../redux/actions/RoleAndMenuActions";
import { DataGrid } from "@mui/x-data-grid";
import Notification from "../core/Notification";
import {
  ASSIGNED_MENU_UPDATE_RESET,
  ROLES_CREATE_RESET,
} from "../redux/constants/RoleAndMenuConstants";
import Popup from "../core/Popup";
import FormPopup from "../core/FormPopup";
import AddRole from "../user/AddRole";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    // margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
    // "& .MuiGrid-item": {
    //   minWidth: "480px !important",
    // },
  },
  pageContent2: {
    // margin: useTheme().spacing(5),
    padding: "0 20px 0 10px",
    // "& .MuiGrid-item": {
    //   minWidth: "480px !important",
    // },
  },
  colHeader: {
    // height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 10px",
    },

    "& .MuiDataGrid-root": {
      border: "1ps solid #e3e3e3",
      overflowX: "auto",
      height: 480,
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#03a9f4",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "157px !important",
      maxWidth: "157px !important",
      fontSize: "1.1rem",
    },

    "& .MuiDataGrid-columnHeader:last-child": {
      marginLeft: "2rem",
    },

    "& .MuiDataGrid-cell": {
      minWidth: "157px !important",
      maxWidth: "157px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#94e2cd",
    },
    "& .MuiDataGrid-cell:last-child": {
      display: "none",
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox": {
      display: "none !important",
    },

    "& .MuiDataGrid-cellCheckbox": {
      minWidth: "35px !important",
      maxWidth: "35px !important",
    },

    "& .MuiDataGrid-columnHeader[data-field='employeeId'], .MuiDataGrid-cell[data-field='employeeId']":
      {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      display: "none",
      borderTop: "none",
      backgroundColor: "#A4A9FC",
    },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  colHeader2: {
    // height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 10px",
    },

    "& .MuiDataGrid-root": {
      border: "1ps solid #e3e3e3",
      overflowX: "auto",
      height: 429,
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#4caf50",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      fontSize: "1.1rem",
      minWidth: "250px !important",
      maxWidth: "250px !important",
    },

    "& .MuiDataGrid-columnHeader:last-child": {
      marginLeft: "2rem",
    },

    "& .MuiDataGrid-cell": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#94e2cd",
    },
    "& .MuiDataGrid-cell:last-child": {
      display: "none",
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox": {
      display: "none !important",
    },
    "& .MuiDataGrid-cellCheckbox": {
      minWidth: "35px !important",
      maxWidth: "35px !important",
    },
    "& .MuiDataGrid-columnHeader[data-field='employeeId'], .MuiDataGrid-cell[data-field='employeeId']":
      {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      display: "none",
      borderTop: "none",
      backgroundColor: "#A4A9FC",
    },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  colHeader3: {
    // height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 10px",
    },

    "& .MuiDataGrid-root": {
      border: "1ps solid #e3e3e3",
      overflowX: "auto",
      height: 429,
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#ff9800",
      color: "#fff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      fontSize: "1.1rem",
      minWidth: "250px !important",
      maxWidth: "250px !important",
    },

    "& .MuiDataGrid-columnHeader:last-child": {
      marginLeft: "2rem",
    },

    "& .MuiDataGrid-cell": {
      minWidth: "250px !important",
      maxWidth: "250px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#94e2cd",
    },
    "& .MuiDataGrid-cell:last-child": {
      display: "none",
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox": {
      display: "none !important",
    },
    "& .MuiDataGrid-cellCheckbox": {
      minWidth: "35px !important",
      maxWidth: "35px !important",
    },
    "& .MuiDataGrid-columnHeader[data-field='employeeId'], .MuiDataGrid-cell[data-field='employeeId']":
      {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      display: "none",
      borderTop: "none",
      backgroundColor: "#A4A9FC",
    },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginRight: "10px",
    marginLeft: "10px",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    border: "1px solid #e3e3e3",
    minHeight: "52px !important",
    fontWeight: "bolder",

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px !important",
      // margin: "0 auto !important",
      padding: "0 10px !important",
    },
    //
  },

  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "50% !important",
    },
  },
}));

const initialValues = {
  rolename: "",
};

const ManageRoleAndMenu = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectUnassignedMenus, setSelectUnassignedMenus] = useState([]);
  const [selectAssignedMenus, setSelectAssignedMenus] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [send, setSend] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    rowId: "",
    usersWithRoles: [],
    title: "",
    subTitle: "",
  });
  const [searchValue, setSearchValue] = useState("");
  // const { values, setValues, handleChange } = useForm(initialValues);

  const roleList = useSelector((state) => state.roleList);
  const {
    loading: loadingRoleList,
    error: errorRoleList,
    success: successRoleList,
    roles,
  } = roleList;
  // console.log(roles);

  const roleDetails = useSelector((state) => state.roleDetails);
  const {
    loading: loadingRoleDetails,
    error: errorRoleDetails,
    success: successRoleDetails,
    roleId,
    roleName,
    roleActiveStatus,
    menuList,
    permittedMenuList,
  } = roleDetails;

  // console.log("role");
  // console.log(menuList);

  // console.log("roles");
  // console.log(roles);

  const updateAssignedMenu = useSelector((state) => state.updateAssignedMenu);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = updateAssignedMenu;

  const createdRole = useSelector((state) => state.createdRole);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = createdRole;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: RoleSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      dispatch(createRole(values));
      setOpenPopup(false);
    },
  });

  const columns = [
    {
      field: "roleName",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => <Box>Active</Box>,
    },
  ];

  const columns2 = [
    {
      field: "menuParentName",
      headerName: "Menu Name",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "menuName",
      headerName: "Action Name",
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    if (userInfo) {
      // loadDesignation();
      // loadLocation();
      dispatch(listRoles());
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: ASSIGNED_MENU_UPDATE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Created Successfully",
          type: "success",
        });
        dispatch({ type: ROLES_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/manageroleandmenu" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successUpdate, successCreate]);

  useEffect(() => {}, [roles, menuList, permittedMenuList]);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const handleEvent = (params) => {
    // history.push(`/employee/${params.row.employeeId}/edit`);
    // console.log(params.row);
  };

  const onRowsSelectionHandler = (ids) => {
    // console.log("ids1");
    // console.log(ids);
    // var freshData = ids.map((single) => {
    //   var x = single.split("-");

    //   var temp = x[0];
    //   return temp;
    // });

    // ids = [];
    // ids = freshData;
    // const arrOfNum = ids.map((str) => {
    //   return Number(str);
    // });
    // ids = arrOfNum;
    // console.log("ids");
    // console.log(ids);
    setSelectUnassignedMenus(ids);
  };

  // console.log("filterFn.fn()");
  // console.log(filterFn.fn());

  const onAssignedMenusRowsSelectionHandler = (ids) => {
    var freshData = ids.map((single) => {
      var x = single.split("-");

      var temp = x[0];
      return temp;
    });

    ids = [];
    ids = freshData;
    const arrOfNum = ids.map((str) => {
      return Number(str);
    });
    ids = arrOfNum;
    setSelectAssignedMenus(ids);
  };

  const handleSearch = (e) => {
    let target = e.target.value;
    setSearchValue(target);
    setFilterFn({
      fn: (items) => {
        if (e.target.value == "") return items;
        else
          return menuList.filter((x) =>
            x.menuName.toLowerCase().includes(e.target.value.toLowerCase())
          );
      },
    });
  };

  const getRoleDetails = (value) => {
    value = value.split("-");

    value = Number(value[0]);
    // console.log(value);
    // var temp = ids[0];
    // ids = [];
    // ids.push(temp);
    // const arrOfNum = ids.map((str) => {
    //   return Number(str);
    // });
    // ids = arrOfNum;
    // console.log("selectionModel");
    // console.log(value);
    setEmpty(false);

    dispatch(listRolesDetails(value));
  };

  // console.log("result selection");
  // console.log(selectionModel);
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Manage Role and Menu"
        subtitle="Here you can extend and limit user's access"
      />
      <Paper className={classes.pageContent}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <div className={classes.colHeader}>
              <DataGrid
                columnBuffer={2}
                columnThreshold={2}
                disableVirtualization
                checkboxSelection
                rows={roles !== undefined ? roles : []}
                columns={columns}
                getRowId={(row) => row.roleId + "-" + generateRandom()}
                onRowClick={handleEvent}
                selectionModel={selectionModel}
                // onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                onSelectionModelChange={(selection) => {
                  setSearchValue("");
                  setFilterFn({fn: (items) => {
                    return items;
                  }});
                  if (selection.length > 1) {
                    const selectionSet = new Set(selectionModel);
                    const result = selection.filter(
                      (s) => !selectionSet.has(s)
                    );

                    setSelectionModel(result);
                  } else {
                    setSelectionModel(selection);
                  }
                  // console.log("selection");
                  if (selection.length > 0) {
                    console.log("selection[selection.length - 1]");
                    console.log(selection[selection.length - 1]);

                    getRoleDetails(selection[selection.length - 1]);
                  } else {
                    setEmpty(true);
                  }
                }}
              />
              <div style={{ textAlign: "right", margin: "0.5rem 0 0 0" }}>
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  text="Add Role"
                  color="success"
                  size="small"
                  onClick={() => {
                    // console.log(selectUnassignedMenus);
                    setOpenPopup({
                      ...openPopup,
                      isOpen: true,
                    });

                    // dispatch(createAssignedMenu(selectUnassignedMenus));
                    // console.log(selectUnassignedMenus);
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4.5}>
            <Toolbar className={classes.toolBar}>
              <>Unassigned Menus</>{" "}
              <Input
                disabled={
                  menuList !== undefined && menuList.length > 1 ? false : true
                }
                label="Search Actions"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.searchInput}
                value={searchValue}
                onChange={handleSearch}
              />
            </Toolbar>
            <div className={classes.colHeader2}>
              <DataGrid
                columnBuffer={2}
                columnThreshold={2}
                disableVirtualization
                checkboxSelection
                rows={
                  empty === false && menuList !== undefined
                    ? filterFn.fn(menuList)
                    : []
                }
                columns={columns2}
                getRowId={(row) => row.menuId}
                onRowClick={handleEvent}
                // selectionModel={selectUnassignedMenus}
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              />
              <div style={{ textAlign: "right", margin: "0.5rem 0 0 0" }}>
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  text="Assign"
                  color="success"
                  size="small"
                  onClick={() => {
                    console.log("selectUnassignedMenusAsifs");
                    console.log(selectUnassignedMenus);
                    setSend(true);

                    dispatch(createAssignedMenu(selectUnassignedMenus));
                    // console.log(selectUnassignedMenus);
                  }}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4.5}>
            <Toolbar className={classes.toolBar}>Assigned Menus</Toolbar>
            <div className={classes.colHeader3}>
              <DataGrid
                columnBuffer={2}
                columnThreshold={2}
                disableVirtualization
                checkboxSelection
                rows={
                  empty === false && permittedMenuList !== undefined
                    ? permittedMenuList
                    : []
                }
                columns={columns2}
                getRowId={(row) => row.menuId + "-" + generateRandom()}
                onRowClick={handleEvent}
                onSelectionModelChange={(ids) =>
                  onAssignedMenusRowsSelectionHandler(ids)
                }
              />
              <div style={{ textAlign: "right", margin: "0.5rem 0 0 0" }}>
                <Button
                  style={{ margin: "0 0.5rem 0 0" }}
                  startIcon={<DeleteIcon fontSize="small" />}
                  text="Remove"
                  color="error"
                  size="small"
                  onClick={() => {
                    setSend(true);
                    dispatch(createUnassignedMenu(selectAssignedMenus));
                  }}
                />
                <Button
                  startIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <ReplayIcon fontSize="small" />
                    )
                  }
                  text="Update"
                  color="warning"
                  size="small"
                  onClick={() => {
                    // console.log(selectUnassignedMenus);
                    // console.log("permittedMenuList");
                    // console.log(permittedMenuList);
                    if (send === true && permittedMenuList !== undefined) {
                      const rolePermissionIdList = permittedMenuList.map(
                        (item) => item.menuId
                      );
                      dispatch(
                        updateAssignedMenus({
                          roleId: roleId,
                          roleName: roleName,
                          roleActiveStatus: roleActiveStatus,
                          rolePermissionIdList: rolePermissionIdList,
                        })
                      );
                      setSend(false);
                    } else {
                      setNotify({
                        isOpen: true,
                        message: "No Change Detected!",
                        type: "error",
                      });
                    }
                    // dispatch(createAssignedMenu(selectUnassignedMenus));
                    // console.log(selectUnassignedMenus);
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <AddRole openPopup={openPopup} setOpenPopup={setOpenPopup} />
      {/* <FormPopup
        title="Create a Role"
        openPopup={openPopup.isOpen}
        setOpenPopup={setOpenPopup}
      >
        <Paper className={classes.pageContent2}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Input
                  label="Add a role"
                  name="rolename"
                  error={formik.errors.rolename}
                  touched={formik.touched.rolename}
                  value={formik.values.rolename}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ width: "98%" }}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  text="Add a role"
                  size="small"
                  startIcon={<AddIcon />}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </FormPopup> */}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(ManageRoleAndMenu);

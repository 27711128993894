import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { signinSchema } from "../schemas";
import { Route, withRouter, Link, Redirect } from "react-router-dom";
import { signin, authenticate, isAuthenticated } from "../auth";
import { login } from "../redux/actions/userActions";
// import Geocode from "react-geocode";
import "./Signin.css";
import Popup from "../core/Popup";
const initialValues = {
  username: "",
  userPassword: "",
  redirectToReferrer: false,
};

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
// Geocode.setApiKey("AIzaSyBv2aartIPeHJJVpXF0NDOR9fyzqzgMyVI");

// set response language. Defaults to english.
// Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
// Geocode.setRegion("es");

// Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
// Geocode.enableDebug();

const Signin = ({ location, history }) => {
  // console.log("location");
  // console.log(location);
  // if (isAuthenticated()) {
  //   history.push("/books");
  // }
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  // console.log("userLogin signin page");
  // console.log(userLogin);
  const { loading, error, userInfo } = userLogin;
  // console.log("signin location");
  // console.log(location);

  const redirect = location.search
    ? location.search.split("=")[1]
    : "/dashboard";
  var { from } = location.state || { from: { pathname: "/" } };
  // console.log(redirect);

  useEffect(() => {
    if (userInfo) {
      history.push(from);
    }
  }, [history, userInfo, redirect, from]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signinSchema,
      onSubmit: (values, action) => {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            console.log("permissionStatus");
            console.log(permissionStatus.state);
            if (permissionStatus.state === "denied") {
              console.log("permissionStatus");
              console.log(permissionStatus);

              setStatus(permissionStatus.state);
              action.setSubmitting(false);

              setOpenPopup({
                ...openPopup,
                isOpen: true,
                title: "Geolocation permission is denied.",
                subTitle: (
                  <p style={{ color: "red", fontSize: "1rem" }}>
                    See this{" "}
                    <a
                      href="/FOLLOW_STEP_BY_STEP_TO_RESET_THE_PERMISSION.pdf"
                      download
                    >
                      guide
                    </a>{" "}
                    to reset the permission
                  </p>
                ),
              });
            } else {
              navigator.geolocation.getCurrentPosition(function (position) {
                var lat = 40.714224;
                var long = -73.961452;
                var lat = 23.69764;
                var long = 90.43763;
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                dispatch(login(values)).then((result) => {
                  // console.log("result");
                  // console.log(result);
                  if (result?.errMsg) {
                    // console.log(result);
                    if (result.errMsg == "Wrong User Name!") {
                      action.setFieldError("errorUserName", result.errMsg);
                    } else if (result.errMsg == "Wrong Password!") {
                      action.setFieldError("errorUserPassword", result.errMsg);
                    } else if (result.errMsg == "Inactive User!") {
                      setOpenPopup({
                        ...openPopup,
                        title: "YOU ARE DEACTIVATED!!!",
                        subTitle: "Contact to the Administrator!",
                        isOpen: true,
                      });
                    }
                    action.setSubmitting(false);
                  } else {
                    setStatus("");
                    history.push(from);
                  }
                });

                // Geocode.fromLatLng(lat, long).then(
                //   (response) => {
                //     console.log("response2");
                //     console.log(response);
                //     const address = response.results[0].formatted_address;
                //     let city, state, country;
                //     for (
                //       let i = 0;
                //       i < response.results[0].address_components.length;
                //       i++
                //     ) {
                //       for (
                //         let j = 0;
                //         j < response.results[0].address_components[i].types.length;
                //         j++
                //       ) {
                //         switch (response.results[0].address_components[i].types[j]) {
                //           case "locality":
                //             city =
                //               response.results[0].address_components[i].long_name;
                //             break;
                //           case "administrative_area_level_1":
                //             state =
                //               response.results[0].address_components[i].long_name;
                //             break;
                //           case "country":
                //             country =
                //               response.results[0].address_components[i].long_name;
                //             break;
                //         }
                //       }
                //     }
                //     console.log(city, state, country);
                //     console.log(address);
                //   },
                //   (error) => {
                //     console.error(error);
                //   }
                // );
              });
            }
          });

        //start real
        // console.log(values);
        // var requestOptions = {
        //   method: "GET",
        //   redirect: "follow",
        // };
        // fetch("https://geolocation-db.com/json/", requestOptions)
        //   .then((response) => response.json())
        //   .then((result) => {
        //     console.log(result);
        // console.log("Latitude is :", result.latitude);
        // console.log("Longitude is :",  result.longitude);

        // });
        //end real
        // setLoading(true);
        // signin(values).then((data) => {
        //   setLoading(false);
        //   if (data.error) {
        //     action.setFieldError("error", data.error);
        //     action.setSubmitting(false);
        //   } else {
        //     authenticate(data, () => {
        //       action.setFieldValue("redirectToReferrer", true);
        //     });
        //   }
        // }); // sending js object
      },
    });
  const redirectUser = () => {
    if (values.redirectToReferrer) {
      if (isAuthenticated()) {
        return <Redirect to="/books" />;
      }
    }
  };
  return (
    <>
      {/* {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            height: "100%",
          }}
        >
          <div
            className="spinner-grow"
            style={{ width: "5rem", height: "5rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : ( */}

      <div className="signin">
        {redirectUser()}
        <main className="form-signin w-100 m-auto">
          <form className="form" onSubmit={handleSubmit}>
            <img
              className="mb-2"
              src={process.env.PUBLIC_URL + "/images/trade.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "85px", height: "60px" }}
            />
            <h1 className="h4 mb-3 fw-normal">Sign in to your account</h1>

            <div className="mb-2 form-group required">
              <label htmlFor="username" className="form-label form-label-sm">
                username
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="username"
                name="username"
                placeholder="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.errorUserName ? (
                <p className="semi-strong-text" style={{ color: "#A52A2A" }}>
                  {errors.errorUserName}
                </p>
              ) : null}

              {errors.username && touched.username ? (
                <p className="semi-strong-text" style={{ color: "#A52A2A" }}>
                  {errors.username}
                </p>
              ) : null}
            </div>
            <div className="mb-2 form-group required">
              <label
                htmlFor="userPassword"
                className="form-label form-label-sm"
              >
                Password
              </label>
              <span style={{ display: "flex" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control form-control-sm"
                  id="userPassword"
                  name="userPassword"
                  placeholder="userPassword"
                  value={values.userPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <i
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"}
                  style={{ marginLeft: "-30px", cursor: "pointer" }}
                ></i>
              </span>

              {errors.errorUserPassword ? (
                <p className="semi-strong-text" style={{ color: "#A52A2A" }}>
                  {errors.errorUserPassword}
                </p>
              ) : null}
              {errors.userPassword && touched.userPassword ? (
                <p className="semi-strong-text" style={{ color: "#A52A2A" }}>
                  {errors.userPassword}
                </p>
              ) : null}
            </div>
            <p
              className="text-sm-start fst-normal semi-strong-text"
              onClick={() => {
                setOpenPopup({
                  ...openPopup,
                  title: "PASSWORD ISSUE!!!",
                  subTitle: "Contact to the Administrator!",
                  isOpen: !openPopup.isOpen,
                });
              }}
            >
              <Link to="#">Forgot Password?</Link>
            </p>
            <p className="thin-text">
              By signing in, you agree with our privacy and usage terms.
            </p>
            <button className="w-100 btn btn-sm btn-primary" type="submit">
              {loading ? (
                <div
                  className="spinner-border text-light"
                  role="status"
                  style={{
                    width: "15px",
                    height: "15px",
                    margin: "0 5px 0 5px",
                  }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
              Sign in
            </button>
          </form>
        </main>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      </div>
      {/* )} */}
    </>
  );
};

export default Signin;

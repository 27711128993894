import ExcelJs from "exceljs";

export const exportToExcelExcelJs = (
  data = [],
  headerKeyMapping,
  file_name = "Report",
  excel_header = "Report",
  totals = { totalLoanIssued: 0, totalLoanReceived: 0, balance: 0 }, // Default values for totals
  customerName = "Customer" // New parameter for customerName
) => {
  let sheetName = `${file_name}.xlsx`;
  let headerName = "RequestsList";

  let workbook = new ExcelJs.Workbook();
  let sheet = workbook.addWorksheet(sheetName, {
    views: [{ showGridLines: true }],
  });

  // Ensure default row alignment
  sheet.properties.defaultRowAlignment = {
    vertical: "bottom",
    horizontal: "left", // Set default horizontal alignment to left
  };

  let updatedTitle = [];

  for (const columnDataKey in headerKeyMapping) {
    updatedTitle.push({ name: headerKeyMapping[columnDataKey] });
  }

  // Add title
  sheet.addRow([
    `Loan Details Report For ${
       customerName
    }`,
  ]);
  sheet.getCell("A1").font = { size: 20, bold: true };
  sheet.addRow([]); // Empty row for separation

  // Add calculation information
  const calcStartRow = sheet.addRow(["Total Loan Issued :", totals.totalLoanIssued]).number;
  sheet.addRow(["Total Loan Received :", totals.totalLoanReceived]);
  sheet.addRow(["Balance :", totals.balance]);
  sheet.addRow([]); // Empty row for separation

  sheet.getCell(`A${calcStartRow}`).font = { italic: true };
  sheet.getCell(`A${calcStartRow + 1}`).font = { italic: true };
  sheet.getCell(`A${calcStartRow + 2}`).font = { italic: true };

  // Optional: You can also bold the values if desired
  //sheet.getCell(`B${calcStartRow}`).font = { bold: true };
  //sheet.getCell(`B${calcStartRow}`).alignment = { horizontal: 'right' };
  
  //sheet.getCell(`B${calcStartRow + 1}`).font = { bold: true };
  //sheet.getCell(`B${calcStartRow + 1}`).alignment = { horizontal: 'right' };
  
  //sheet.getCell(`B${calcStartRow + 2}`).font = { bold: true };
  //sheet.getCell(`B${calcStartRow + 2}`).alignment = { horizontal: 'right' };
  

  // Justify the cells in the calculation section
  for (let i = calcStartRow; i <= calcStartRow + 2; i++) {
    sheet.getCell(`A${i}`).alignment = { horizontal: 'left' };
    sheet.getCell(`B${i}`).alignment = { horizontal: 'right' };
  }

  // Set the width of column A to 25

  sheet.getColumn('A').width = 25;

  // Add the table header starting after the empty row
  const tableStartRow = calcStartRow + 4;
  sheet.addTable({
    name: headerName,
    ref: `A${tableStartRow}`,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
      width: 200,
      border: {
        top: { style: "double", color: { argb: "FF00FF00" } },
        left: { style: "double", color: { argb: "FF00FF00" } },
        bottom: { style: "double", color: { argb: "FF00FF00" } },
        right: { style: "double", color: { argb: "FF00FF00" } },
      },
    },
    columns: updatedTitle ? updatedTitle : [{ name: "" }],
    rows: data?.map((e) => {
      let arr = [];
      for (let i in e) {
        if (headerKeyMapping[i]) {
          arr.push(e[i]);
        }
      }
      return arr;
    }),
  });

  // Adjust column width based on content
  sheet.columns = updatedTitle.map((title, index) => {
    const col = sheet.getColumn(index + 1);
    col.width = Math.max(
      title.name.length,
      ...data.map(
        (row) =>
          row[Object.keys(headerKeyMapping)[index]]?.toString().length || 10
      )
    ) + 2; // Adding some extra space for better readability
    return col;
  });

  const table = sheet.getTable(headerName);
  for (let i = 0; i < table.table.columns.length; i++) {
    sheet.getCell(`${String.fromCharCode(65 + i)}${tableStartRow}`).font = { size: 12 };
    sheet.getCell(`${String.fromCharCode(65 + i)}${tableStartRow}`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "c5d9f1" },
    };

    for (let j = 0; j < table.table.rows.length; j++) {
      let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + tableStartRow + 1}`);
      rowCell.alignment = {
        wrapText: true,
        horizontal: "left", // Ensure each cell is left-aligned
      };
      rowCell.border = {
        bottom: {
          style: "thin",
          color: { argb: "a6a6a6" },
        },
      };
    }
  }
  table.commit();

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  workbook.xlsx.writeBuffer().then((buffer) => {
    writeFile(sheetName, buffer);
  });
};

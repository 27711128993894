import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  EMPLOYEE_CREATE_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_DELETE_FAIL,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DETAILS_FAIL,
  EMPLOYEE_DETAILS_REQUEST,
  EMPLOYEE_DETAILS_SUCCESS,
  EMPLOYEE_LIST_FAIL,
  EMPLOYEE_LIST_REQUEST,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_UPDATE_FAIL,
  EMPLOYEE_UPDATE_REQUEST,
  EMPLOYEE_UPDATE_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_PRODUCTS_TOP_REQUEST,
  PRODUCT_PRODUCTS_TOP_SUCCESS,
  PRODUCT_PRODUCTS_TOP_FAIL,
  EMPLOYEE_DEACTIVATE_REQUEST,
  EMPLOYEE_DEACTIVATE_SUCCESS,
  EMPLOYEE_DEACTIVATE_FAIL,
  EMPLOYEE_BY_DEPARTMENT_LIST_REQUEST,
  EMPLOYEE_BY_DEPARTMENT_LIST_SUCCESS,
  EMPLOYEE_BY_DEPARTMENT_LIST_FAIL,
} from "../constants/employeeConstants";

export const listEmployees = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Employee/GetEmployeeDetails`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({ type: EMPLOYEE_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployeeDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: EMPLOYEE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Employee/GetEmployeeById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log(data);

    dispatch({ type: EMPLOYEE_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createEmployee = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_CREATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      employeeCode: data.employeeCode,
      empFirstName: data.empFirstName,
      empLastName: data.empLastName,
      empPresentAddress: data.empPresentAddress,
      empParmanentAddress: data.empParmanentAddress,
      bloodGroup: data.bloodGroup,
      dateOfJoining: data.dateOfJoining,
      empContactNo: data.empContactNo,
      guardianContactNo: data.guardianContactNo,
      designationId: data.designationId,
      departmentId: data.departmentId,
      channelId: data.channelId,
      empTypeId: data.empTypeId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var finalGotData = await fetch(
      `${API_URL}/Employee/AddEmployee`,
      requestOptions
    )
      .then((response) => {
        // console.log(response.text());
        console.log("response");
        console.log(response);
        return response.json();
      })
      .then((result) => {
        if (result?.errMsg) {
          dispatch({ type: EMPLOYEE_CREATE_FAIL, payload: result.errMsg });
          return result;
        } else {
          dispatch({ type: EMPLOYEE_CREATE_SUCCESS, payload: result.dataObj });
          return result;
        }
      })
      .catch((error) => console.log("error", error));

    return finalGotData;
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateEmployee = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",
      url: `${API_URL}/Employee/UpdateEmployee`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: EMPLOYEE_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: EMPLOYEE_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    return finalGotData;
  } catch (error) {
    dispatch({
      type: EMPLOYEE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteEmployee = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "delete",

      url: `${API_URL}/Employee/DeleteEmployee?id=${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await Axios(config);
    dispatch({ type: EMPLOYEE_DELETE_SUCCESS });
    //
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deactivateEmployee = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_DEACTIVATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    let data = "";
    var config = {
      method: "put",

      url: `${API_URL}/Employee/DeactiveUserEmpStatus?userCode=${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      data: data,
    };
    await Axios(config);
    dispatch({ type: EMPLOYEE_DEACTIVATE_SUCCESS });
    //
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DEACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployeeByDepartment =
  (deptId) => async (dispatch, getState) => {
    try {
      dispatch({ type: EMPLOYEE_BY_DEPARTMENT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/Employee/GetEmployeeByDpt/${deptId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      // console.log("data");
      // console.log(data);
      dispatch({
        type: EMPLOYEE_BY_DEPARTMENT_LIST_SUCCESS,
        payload: data.dataObj,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_BY_DEPARTMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_PASSWORD_CHANGE_REQUEST = "USER_PASSWORD_CHANGE_REQUEST";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL = "USER_PASSWORD_CHANGE_FAIL";
export const USER_PASSWORD_CHANGE_RESET = "USER_PASSWORD_CHANGE_RESET";

export const USER_WITH_ROLE_LIST_REQUEST = "USER_WITH_ROLE_LIST_REQUEST";
export const USER_WITH_ROLE_LIST_SUCCESS = "USER_WITH_ROLE_LIST_SUCCESS";
export const USER_WITH_ROLE_LIST_FAIL = "USER_WITH_ROLE_LIST_FAIL";

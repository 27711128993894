import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  TableBody,
  TableCell,
  TableRow,
  createFilterOptions,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import MicIcon from "@mui/icons-material/Mic";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PublishIcon from "@mui/icons-material/Publish";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import MicOffIcon from "@mui/icons-material/MicOff";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Button from "../../core/controls/Button";
import {
  createProduct,
  createProductPrice,
  listCategories,
  listProductPriceById,
  mappedListProducts,
} from "../../redux/actions/productActions";

import {
  MarketingActivitySchema,
  MarketingActivityUpdateSchema,
  productPriceSchema,
} from "../../schemas";
import { withRouter } from "react-router-dom";
import { getDataGridUtilityClass } from "@mui/x-data-grid";
import useTable from "../../core/useTable";
import { emprecords } from "../../DummyData";
import Datepicker from "../../core/controls/Datepicker";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import ActionButton from "../../core/controls/ActionButton";
import {
  createActivity,
  deleteActivity,
  doneActivityDetails,
  FilterListActivity,
  listActivity,
  updateActivity,
} from "../../redux/actions/MarketingActivityActions";
import Popup from "../../core/Popup";
import InhouseEmployees from "../Employees/InhouseEmployees";
import { RestoreFromTrash } from "@material-ui/icons";
import Notification from "../../core/Notification";
import { ACTIVITY_CREATE_RESET } from "../../redux/constants/MarketingActivityConstants";
import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
const headCells = [
  {
    id: "mktActivityId",
    label: "ID",
  },
  {
    id: "mktActivityList",
    label: "Activity Name",
  },
  {
    id: "voiceOfCustomer",
    label: "Voice of Customer",
  },
  { id: "actions", label: "Action", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  inputFields: {
    width: "100%",
    /* From https://css.glass */
    padding: "1rem",
    paddingRight: "1.5rem",
    // background: "rgba(255, 245, 228,0.5)",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    marginBottom: "1rem",
    [useTheme().breakpoints.up("md")]: {
      width: "83%",
    },
  },
  pageContent: {
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  pageContentForTable: {
    overflowX: "auto",
    // overflowY: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup2: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "& .MuiButtonBase-root:nth-child(1)": {
      backgroundColor: "#1565c0",
    },
    "& .MuiButtonBase-root:nth-child(2)": {
      backgroundColor: "#c62828",
    },
    "& .MuiButtonBase-root:nth-child(3)": {
      backgroundColor: "#7b1fa2",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  customer: {
    id: "",
    label: "",
  },
  visitDate: [date.getFullYear(), mnth, day].join("-"),
  activity: {
    id: "",
    label: "",
  },
  voiceOfCustomer: "",
  category: {
    id: "",
    label: "",
  },
  product: {
    id: "",
    label: "",
  },
  sellingPrice: "",
  priceEffectiveDate: [date.getFullYear(), mnth, day].join("-"),
  priceValidDate: [date.getFullYear(), mnth, day].join("-"),
};

const data = [];

var FilteredActivity = [];

const AddMarketingActivity = ({ match, history }) => {
  const activityId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [filterFn2, setFilterFn2] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [count, setCount] = useState(1);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  // console.log(customers);

  const activityUpdate = useSelector((state) => state.activityUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = activityUpdate;

  const activityList = useSelector((state) => state.activityList);
  const {
    loading: loadingActivity,
    error: errorActivity,
    activities,
  } = activityList;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const productPriceById = useSelector((state) => state.productPriceById);
  const {
    loading: loadingProductPrice,
    error: errorProductPrice,
    products: productPrice,
  } = productPriceById;

  const activityDetails = useSelector((state) => state.activityDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    activity,
  } = activityDetails;

  // console.log(productPrice);
  const mappedProductList = useSelector((state) => state.mappedProductList);
  const {
    loading: mappedLoading,
    error: mappedError,
    mappedproducts,
  } = mappedProductList;
  //   console.log(mappedproducts);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(selectedActivities, headCells, filterFn);

  //   console.log(userInfo);
  const formik = useFormik({
    initialValues,
    validationSchema: MarketingActivityUpdateSchema,
    onSubmit: (values, action) => {
      if (selectedActivities.length > 0) {
        // console.log(decodedata);
        // console.log(selectedActivities);
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };
        fetch("https://geolocation-db.com/json/", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            // console.log("result");
            // console.log(result);
            // console.log("final values");
            // console.log(values);
            dispatch(
              updateActivity({
                visitId: activityId,
                customerId: values.customer.id,
                visitDetails: selectedActivities,
              })
            ).then(() => {
              history.push("/marketingactivities");
            });

            // if (loadingCreate === false) {
            // history.push("/customers");
            // }
          })
          .catch((error) => console.log("error", error));
      } else {
        setOpenPopup2({
          ...openPopup2,
          title: "EMPTY!!!",
          subTitle: "Activities List is Empty!",
          isOpen: true,
        });
      }
      // console.log(values);

      // dispatch(
      //   createProductPrice({
      //     productId: values.product.productId,
      //     priceEffectiveDate: values.priceEffectiveDate,
      //     priceValidDate: values.priceValidDate,
      //     sellingPrice: values.sellingPrice,
      //   })
      // );
      // console.log("loadingCreate");
      // console.log(loadingCreate);
      // if (loadingCreate === false) {
      //   history.push("/price");
      // }
    },
  });

  // console.log("activity");
  // console.log(activity);

  useEffect(() => {
    if (userInfo) {
      dispatch(doneActivityDetails(activityId));
      dispatch(listCategories());
      dispatch(listCustomersForSignup());
      dispatch(listActivity());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/marketingactivity/${activityId}/edit` } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  useEffect(() => {
    SpeechToText();
  }, [transcript]);

  const changeValues = () => {
    var date = new Date(activity.visitDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const d = [date.getFullYear(), mnth, day].join("-");
    // console.log(activity);
    formik.setFieldValue("customer", {
      id: activity.customerId || "",
      label: activity.customerName || "",
    });
    formik.setFieldValue("visitDate", d);
    setSelectedActivities(activity.visitDetailsUpdate);

    if (
      activity.visitDetailsUpdate !== undefined &&
      activity.visitDetailsUpdate.length > 0
    ) {
      var checkArr2 = activity.visitDetailsUpdate;
      if (checkArr2 !== undefined && checkArr2.length > 0) {
        for (var i = 1; i <= activity.visitDetailsUpdate.length; i++) {
          checkArr2[i - 1].mktActivityId = checkArr2[i - 1].mktActivityId;
          checkArr2[i - 1].mktActivityIdToShow = i;
          checkArr2[i - 1].mktActivityList = checkArr2[i - 1].mktActivityList;
          checkArr2[i - 1].voiceOfCustomer = checkArr2[i - 1].voiceOfCustomer;
        }

        setSelectedActivities(checkArr2);
      }

      activity.visitDetailsUpdate.map((single) => {
        if (!FilteredActivity.includes(single.mktActivityId)) {
          FilteredActivity.push(single.mktActivityId);
        }
      });

      dispatch(FilterListActivity(FilteredActivity));
    }
    // if (product.categoryId !== undefined && product.mappedWith !== null) {
    //   console.log("product.categoryId");
    //   console.log(product.categoryId);
    //   setMapping(true);
    //   setChecked(true);
    //   dispatch(mappedListProducts(product.categoryId));
    //   //   (product.mappedWith !== null ? true : false
    // }
    // console.log(product);
    // formik.setFieldValue("category", {
    //   id: product.categoryId || "",
    //   label: product.productCategoryName || "",
    // });
    // formik.setFieldValue("productName", product.productName || "");
    // formik.setFieldValue("functionName", product.functionName || "");
    // formik.setFieldValue("mappedCategory", {
    //   id: product.categoryId || "",
    //   label: product.productCategoryName || "",
    // });
    // formik.setFieldValue("mappedProduct", {
    //   id: product.mappedWith || "",
    //   label: product.mappedProduct || "",
    // });
  };

  useEffect(() => {
    changeValues();
    if (activity.visitDetailsUpdate !== undefined) {
      setCount(activity.visitDetailsUpdate.length + 1);
    }
  }, [activity]);
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteActivity(id));
    history.push("/marketingactivities");
  };

  const handleSearch = (e) => {
    // console.log("Hello");
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        // console.log("items");
        // console.log(items);
        if (target.value === "") {
          return [];
        } else if (target.value.length > 4) {
          return items.filter((x) =>
            x.label.toUpperCase().startsWith(target.value.toUpperCase())
          );
        } else {
          return [];
        }
      },
    });
  };

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const activityOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const activityChange = useCallback((e, v) => {
    formik.setFieldValue("activity", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const onTagsChange = (event, values) => {
    // console.log(values);
    dispatch(mappedListProducts(values.id));
  };

  const getData = (event, values) => {
    dispatch(listProductPriceById(values.id));
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const mappedProductOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const categoryChange = useCallback((e, v) => {
    formik.setFieldValue("category", {
      id: v?.id || "",
      label: v?.label || "",
    });
    onTagsChange(e, v);
  });

  const productChange = useCallback((e, v) => {
    // console.log(v.id);
    formik.setFieldValue("product", {
      id: v?.id || "",
      label: v?.label || "",
    });
    getData(e, v);
  });
  // console.log("selectedActivities");
  // console.log(selectedActivities);
  // console.log(formik.values.category);
  // console.log(categories);
  const SpeechToText = () => {
    // console.log(transcript);
    formik.setFieldValue("voiceOfCustomer", transcript);
  };
  // console.log("selectedActivities");
  // console.log(selectedActivities);

  useEffect(() => {
    var checkArr = selectedActivities;
    if (checkArr !== undefined && checkArr.length > 0) {
      for (var i = 1; i < count; i++) {
        checkArr[i - 1].mktActivityId = checkArr[i - 1].mktActivityId;
        checkArr[i - 1].mktActivityIdToShow = i;
        checkArr[i - 1].mktActivityList = checkArr[i - 1].mktActivityList;
        checkArr[i - 1].voiceOfCustomer = checkArr[i - 1].voiceOfCustomer;
      }

      setSelectedActivities(checkArr);
    }
    // console.log("selectedActivities");
    // console.log(checkArr);
    // console.log("count");
    // console.log(count);
  }, [count, selectedActivities]);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AddBusinessIcon />}
        title="Marketing Activity"
        subtitle="Add a Marketing Activity"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form
          onSubmit={(e) => {
            formik.handleSubmit(e);
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={classes.inputFields}>
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={true}
                  id="combo-box-demo1"
                  isOptionEqualToValue={customerOptionCheck}
                  onChange={(e, v) => {
                    // console.log("v");
                    // console.log(v);
                    formik.resetForm();
                    setSelectedActivities([]);

                    customerChange(e, v);
                  }}
                  value={formik.values.customer}
                  options={
                    customers !== undefined ? filterFn.fn(customers) : []
                  }
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label="Company Name"
                      name="customer"
                      error={
                        formik.errors.customer !== undefined
                          ? formik.errors.customer.id
                          : ""
                      }
                      touched={
                        formik.touched.customer !== undefined
                          ? formik.touched.customer
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                      style={{ width: "100%" }}
                    />
                  )}
                  freeSolo={true}
                  // noOptionsText={
                  //   showOptionMessage ? "No Option" : "continue typing.."
                  // }
                />

                <Datepicker
                  label="Visit Date"
                  name="visitDate"
                  value={formik.values.visitDate}
                  onChange={formik.handleChange}
                  disabled
                  style={{ width: "100%", color: "black", fontWeight: "bold" }}
                />
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingActivity ? true : false}
                  id="combo-box-demo1"
                  isOptionEqualToValue={activityOptionCheck}
                  onChange={(e, v) => {
                    // console.log("v");
                    // console.log(v);

                    activityChange(e, v);
                  }}
                  value={formik.values.activity}
                  options={
                    activities !== undefined ? filterFn.fn(activities) : []
                  }
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label="Activity Name"
                      name="activity"
                      error={
                        formik.errors.activity !== undefined
                          ? formik.errors.activity.id
                          : ""
                      }
                      touched={
                        formik.touched.activity !== undefined
                          ? formik.touched.activity
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                      style={{ width: "100%" }}
                    />
                  )}
                  freeSolo={true}

                  // noOptionsText={
                  //   showOptionMessage ? "No Option" : "continue typing.."
                  // }
                />

                <Input
                  label="Voice of Customer"
                  name="voiceOfCustomer"
                  multiline
                  error={formik.errors.voiceOfCustomer}
                  touched={formik.touched.voiceOfCustomer}
                  value={formik.values.voiceOfCustomer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ width: "100%" }}
                />
                <p style={{ marginBottom: "0px", marginLeft: "10px" }}>
                  You can use microphone instead
                </p>
                <p style={{ marginBottom: "0px", marginLeft: "10px" }}>
                  Microphone: {listening ? "on" : "off"}
                </p>
                <ButtonGroup className={classes.submitButtonGroup2}>
                  <MuiButton
                    endIcon={<MicIcon fontSize="small" />}
                    size="small"
                    type="button"
                    onClick={() => {
                      SpeechRecognition.startListening();
                      // console.log(transcript);
                      // SpeechToText();
                    }}
                  ></MuiButton>
                  <MuiButton
                    size="small"
                    color="error"
                    endIcon={<MicOffIcon fontSize="small" />}
                    onClick={SpeechRecognition.stopListening}
                  />
                  <MuiButton
                    size="small"
                    endIcon={<RestoreFromTrash fontSize="small" />}
                    onClick={() => {
                      formik.resetForm();
                      setSelectedActivities([]);
                    }}
                  />
                </ButtonGroup>

                <div>
                  <Button
                    color="success"
                    text="Add"
                    endIcon={<AddCircleOutlineIcon />}
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      // console.log(formik.values.activity.label);
                      // console.log(formik.values.voiceOfCustomer);
                      if (
                        formik.values.activity.id !== "" &&
                        formik.values.customer.id !== "" &&
                        formik.values.voiceOfCustomer !== ""
                      ) {
                        // console.log("formik.values.activity");
                        // console.log(formik.values.activity);
                        setSelectedActivities([
                          ...selectedActivities,
                          {
                            mktActivityId: formik.values.activity.id,
                            mktActivityIdToShow: count,
                            mktActivityList: formik.values.activity.label,
                            voiceOfCustomer: formik.values.voiceOfCustomer,
                          },
                        ]);
                        FilteredActivity.push(formik.values.activity.id);
                        // ConcatActivity = ConcatActivity.filter((single) => {
                        //   return single != formik.values.activity.id;
                        // });
                        // dispatch(ConcatListActivity(ConcatActivity));
                        dispatch(FilterListActivity(FilteredActivity));
                        setCount(count + 1);
                        // activityDisqualifier();
                        formik.setFieldValue("activity", {
                          id: "",
                          label: "",
                        });
                        formik.setFieldValue("voiceOfCustomer", "");
                      } else {
                        setOpenPopup({
                          ...openPopup,
                          title: "EMPTY!!!",
                          subTitle: "One or More Fields are Empty!",
                          isOpen: true,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper className={classes.pageContentForTable}>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {selectedActivities !== undefined &&
                    selectedActivities.length > 0 ? (
                      recordsAfterPagingAndSorting().map((item) => (
                        <TableRow key={item.mktActivityIdToShow}>
                          <TableCell>{item.mktActivityIdToShow}</TableCell>
                          <TableCell>{item.mktActivityList}</TableCell>
                          <TableCell>{item.voiceOfCustomer}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                color="error"
                                onClick={() => {
                                  // console.log("selectedActivities");
                                  // console.log(selectedActivities);
                                  setSelectedActivities(
                                    selectedActivities.filter((single) => {
                                      return (
                                        single.mktActivityIdToShow !==
                                        item.mktActivityIdToShow
                                      );
                                    })
                                  );
                                  // ConcatActivity.push(item.mktActivityId);
                                  FilteredActivity.push(item.mktActivityId);
                                  FilteredActivity = FilteredActivity.filter(
                                    (single) => {
                                      return single != item.mktActivityId;
                                    }
                                  );
                                  // console.log("FilteredActivityhere");
                                  // console.log(FilteredActivity);
                                  dispatch(
                                    FilterListActivity(FilteredActivity)
                                  ).then(() => {
                                    console.log("item.mktActivityId");
                                    console.log(item.mktActivityList);
                                    formik.setFieldValue("activity", {
                                      id: item?.mktActivityId || "",
                                      label: item?.mktActivityList || "",
                                    });
                                    formik.setFieldValue(
                                      "voiceOfCustomer",
                                      item?.voiceOfCustomer || ""
                                    );
                                  });
                                  // dispatch(
                                  //   FilterListActivity(FilteredActivity)
                                  // );
                                  // dispatch(ConcatListActivity(ConcatActivity));
                                  setCount(count - 1);
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </ActionButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>No Data</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </TblContainer>
              </Paper>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Update
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/marketingactivities",
                    state: {},
                  };
                  history.push(location);
                }}
              />
              <MuiButton
                onClick={() => {
                  // onDelete(params.row.employeeId);
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure that you want to delete this data?",
                    subTitle: "Once it is deleted, you can't restore!",
                    onConfirm: () => {
                      onDelete(match.params.id);
                    },
                  });
                }}
                color="error"
                startIcon={
                  loading ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : (
                    <DeleteIcon />
                  )
                }
                size="large"
              >
                Delete
              </MuiButton>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loadingCustomer && loadingActivity ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      <Popup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
    </div>
  );
};

export default withRouter(AddMarketingActivity);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";

import ListIcon from "@mui/icons-material/List";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

import * as XLSX from "xlsx";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import {
  deleteProduct,
  listProductPrice,
  listProducts,
} from "../../redux/actions/productActions";
import { Box, Tooltip, IconButton, Toolbar, Grid } from "@mui/material";
import ActionButton from "../../core/controls/ActionButton";
import Button from "../../core/controls/Button";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DELETE_RESET,
  PRODUCT_UPDATE_RESET,
} from "../../redux/constants/productConstants";
import Popup from "../../core/Popup";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import Input from "../../core/controls/Input";
import { Form } from "../../core/useForm";
import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import { createTallySales } from "../../redux/actions/invoiceActions";
import SuccessPopup from "../../core/SuccessPopup";
import { API_URL } from "../../redux/constants/apiConstants";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "center",
    width: "700px",
    padding: "0px !important",
    margin: "5px",
    [useTheme().breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "right",
      width: "420px",
    },
  },
  colHeader: {
    height: 550,

    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px",
      margin: "0 auto",
      padding: "0 60px",
    },

    "& .MuiDataGrid-root": {
      border: "none",
      overflowX: "auto",
      border: "1px solid #6C4AB6",
    },

    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: "auto",
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#6C4AB6",
      color: "#ffff",
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeader": {
      minWidth: "310px !important",
      maxWidth: "310px !important",
    },

    // "& .MuiDataGrid-columnHeaderTitle": {
    //   minWidth: "310px !important",
    //   maxWidth: "310px !important",
    //   textAlign: "center",
    // },

    "& .MuiDataGrid-cell": {
      minWidth: "310px !important",
      maxWidth: "310px !important",
      borderBottom: "none",
      textAlign: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
      outline: "none !important",
    },

    "& .name-column--cell": {
      color: "#6C4AB6",
    },
    "& .MuiDataGrid-cell:last-child": {
      [useTheme().breakpoints.up("md")]: {
        minWidth: "0px !important",
        maxWidth: "0px !important",
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      width: "2090px",
      overflowX: "scroll !important",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#B9B9B9",
    },

    "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
      minWidth: "60px !important",
      maxWidth: "60px !important",
    },
    "& .MuiDataGrid-columnHeader[data-field='productId'], .MuiDataGrid-cell[data-field='productId']":
      {
        minWidth: "120px !important",
        maxWidth: "120px !important",
      },

    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: "#6C4AB6",
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
      {
        color: "#fff",
      },

    "& .MuiButtonBase-root .MuiCheckbox-root": {
      backgroundColor: "#A4A9FC !important",
      color: "#b7ebde !important",
    },
  },

  //   grid: {
  //     border: "1px solid black",
  //   },
  pageContent: {
    overflowX: "auto",
    marginLeft: "auto",
    marginTop: useTheme().spacing(0),
    padding: useTheme().spacing(1.2),
    margin: useTheme().spacing(1.2),
  },
  searchInput: {
    width: "50%",
    [useTheme().breakpoints.up("md")]: {
      width: "30% !important",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    [useTheme().breakpoints.up("md")]: {
      maxWidth: "2000px !important",
      margin: "0 auto !important",
      padding: "0 60px !important",
    },
    "& .MuiButtonBase-root": {
      [useTheme().breakpoints.down("md")]: {
        width: "30% !important",
        fontSize: "smaller !important",
      },
    },
    //
  },
  wrapperWhite: {
    backgroundColor: "#fff",
    padding: "2px",
  },
}));

const AddTallySales = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const productPriceLists = useSelector((state) => state.productPriceList);
  const { loading, error, productPriceList } = productPriceLists;

  // console.log(productPriceList);

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = productCreate;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [flag, setFlag] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [loadingPleaseWait, setLoadingPleaseWait] = useState(false);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  const [lastDate, setLastDate] = useState("");

  // submit
  const [excelData, setExcelData] = useState(null);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listProductPrice());

      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: PRODUCT_UPDATE_RESET });
      }

      if (successDelete) {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });

        dispatch({ type: PRODUCT_DELETE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: PRODUCT_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/price" } },
      };
      history.push(location);
    }
  }, [
    dispatch,
    history,
    userInfo,
    successCreate,
    successDelete,
    successUpdate,
  ]);

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
    } else {
      const selectedRowsData = ids.map((id) =>
        productPriceList.find((row) => row.productId === id)
      );
      setRowId(selectedRowsData[0].productId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteProduct(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };
  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "text/x-comma-separated-values",
    "text/comma-separated-values",
    "application/octet-stream",
    "application/vnd.ms-excel",
    "application/x-csv",
    "text/x-csv",
    "text/csv",
    "application/csv",
    "application/excel",
    "application/vnd.msexcel",
    "text/plain",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingPleaseWait(true);
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, {
        type: "buffer",
        cellDates: true,
        dateNF: "dd-mm-yyyy;@",
      });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_row_object_array(worksheet, {
        raw: false,
      });
      console.log("data");
      console.log(data);
      setExcelData(data);
      setTimeout(() => {
        setLoadingPleaseWait(false);
        setSubmitButtonDisable(false);
      }, 2000);
    } else {
      setExcelData(null);
    }
  };

  console.log("excelData");
  console.log(excelData);

  const handleFile = (e) => {
    console.log(e.target.files[0]);
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("plz select your file");
    }
  };

  const columns = [
    {
      field: "customer_name",
      headerName: "customer name",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Product_name",
      headerName: "product name",
      //   cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "salesPrice",
      headerName: "salesPrice",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "salesDate",
      headerName: "salesDate",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "salesQuantity",
      headerName: "salesQuantity",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "tallyRefNo",
      headerName: "Tally Ref No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customerId",
      headerName: "customerId",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "productId",
      headerName: "productId",
      //   cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleEvent = (params) => {
    history.push(`/product/${params.row.productId}/edit`);
  };

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    console.log("d");
    console.log(d);
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const getLastDate = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${API_URL}/TallySales/GetLastDate`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("result");
        setLastDate(dateConverter(result.dataObj.salesDate));
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getLastDate();
  }, []);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<ListIcon />}
        title="Tally Sales"
        subtitle="Upload Tally Sales"
      />

      {error === "Network Error" ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loadingPleaseWait ? (
        <div
          className="paperdesign2"
          style={{
            display: loadingPleaseWait ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <Grid style={{ width: "60%" }}>
              <form
                className="form-group"
                autoComplete="off"
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "900px",
                  overflowX: "scroll !important",
                }}
              >
                {/* <Input
                  label="Upload a csv | xls | xlsx File"
                  type="file"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleFile}
                  value={excelFile}
                /> */}
                <div className="input-group mb-3">
                  <label
                    className="input-group-text"
                    htmlFor="inputGroupFile01"
                  >
                    <span style={{ fontSize: "0.7rem" }}>
                      Upload a csv | xls | xlsx File
                    </span>
                  </label>
                  <input
                    className="form-control"
                    id="inputGroupFile01"
                    type="file"
                    onChange={handleFile}
                    required
                  />
                  <button
                    className="btn btn-outline-success"
                    type="submit"
                    id="inputGroupFileAddon04"
                  >
                    Fetch
                  </button>
                </div>

                <div
                  className="container-fluid"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <a
                    className="navbar-brand"
                    href={process.env.PUBLIC_URL + "/tally_sales_demo.xlsx"}
                    download
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/excel.png"}
                      alt=""
                      // width="130"
                      // height="35"
                      // style={{ width: "90px", height: "50px" }}
                    />
                  </a>
                  <div className="blink-text">Last date: {lastDate}</div>
                </div>
                {/* <div className="container-fluid"></div> */}
                {/* <div style={{ width: "30%" }}></div> */}
                {/* <button
                  type="submit"
                  className="btn btn-success"
                  style={{ marginTop: 5 + "px", marginLeft: 5 + "px" }}
                >
                  Fetch
                </button> */}
              </form>
              {excelFileError && (
                <div className="text-danger" style={{ marginTop: 5 + "px" }}>
                  {excelFileError}
                </div>
              )}
            </Grid>
            <div className={classes.actions}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log(excelData);

                  var newExcelData = excelData.map((singleData) => {
                    var noSpecialChars = singleData.salesPrice.replace(",", "");
                    return {
                      id: 0,
                      customerId: parseInt(singleData.customerId),
                      productId: parseInt(singleData.productId),
                      salesPrice: parseFloat(noSpecialChars),
                      salesDate: singleData.salesDate,
                      salesQuantity: parseFloat(singleData.salesQuantity),
                      employeeCode: singleData.employeeCode,
                      tallyRefNo: parseInt(singleData.tallyRefNo),
                    };
                  });

                  var newExcelData = newExcelData.filter((singleData) => {
                    return singleData.salesQuantity !== null;
                  });
                  // var arraySlice = JSON.parse(
                  //   JSON.stringify(newExcelData.slice(0, 30000))
                  // );
                  var arraySlice = JSON.parse(JSON.stringify(newExcelData));
                  // var arraySlice = newExcelData;
                  console.log("arraySlice");
                  console.log(arraySlice);
                  dispatch(createTallySales(arraySlice)).then((result) => {
                    if (result?.errMsg) {
                      if (result.errMsg == "Customer Already Exist") {
                      } else if (
                        result.errMsg == "Contact Number Already Exist"
                      ) {
                      }
                    } else {
                      setOpenPopup2({
                        ...openPopup2,
                        title: `SUCCESS!!!`,
                        subTitle: `Tally Sales Added.`,
                        isOpen: true,
                      });
                      setExcelFile(null);
                      setExcelFileError(null);
                      setLoadingPleaseWait(false);
                      setSubmitButtonDisable(true);
                      setExcelData(null);
                    }
                  });
                }}
              >
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  type="submit"
                  text="Submit Data"
                  color="success"
                  // onClick={() => {
                  //   history.push("/addsellprice");
                  // }}
                  disabled={submitButtonDisable ? true : false}
                />
              </form>
            </div>
            {/* <Input
              label="Search Employees"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.searchInput}
              // onChange={handleSearch}
            /> */}
          </Toolbar>

          <div className={`${classes.colHeader} paperdesign1`}>
            <DataGrid
              className={classes.grid}
              columnBuffer={2}
              columnThreshold={2}
              disableVirtualization
              //   checkboxSelection
              rows={excelData !== null ? excelData : []}
              columns={columns}
              //   components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.productId + "-" + generateRandom()}
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
            />
            <div
              className="paperdesign2"
              style={{
                display: loadingPleaseWait ? "" : "none",
              }}
            >
              <div>
                <Circles
                  height="70"
                  width="100"
                  color="#F3EC19"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  // wrapperClass=""
                  // style={{
                  //   width: "100%",
                  //   height: "100%",
                  //   display: "flex !important",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                  // }}
                  visible={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    color: "white",
                  }}
                >
                  Please wait
                </p>
              </div>
            </div>
          </div>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        </div>
      )}
    </div>
  );
};

export default withRouter(AddTallySales);

import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  GET_CHANNEL_LIST_FAIL,
  GET_CHANNEL_LIST_REQUEST,
  GET_CHANNEL_LIST_SUCCESS,
  GET_CURRENT_STOCK_FAIL,
  GET_CURRENT_STOCK_REQUEST,
  GET_CURRENT_STOCK_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_REFERENCE_LIST_FAIL,
  GET_REFERENCE_LIST_REQUEST,
  GET_REFERENCE_LIST_SUCCESS,
  GET_STOCK_ADJUSTMENT_FAIL,
  GET_STOCK_ADJUSTMENT_REQUEST,
  GET_STOCK_ADJUSTMENT_SUCCESS,
} from "../constants/CurrentStockReportConstants";
import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAIL,
} from "../constants/customerConstants";

//get CustomerName list

export const getCustomersList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetCustomerName`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    dispatch({
      type: CUSTOMER_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//get productName list
export const getProductsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PRODUCT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetProductList`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    dispatch({
      type: GET_PRODUCT_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//get channelName list
export const getChannelsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CHANNEL_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetChanneltList`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    dispatch({
      type: GET_CHANNEL_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: GET_CHANNEL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Update the action to take both productNameId and channelNameId
// Update the action to take both productNameId and channelNameId
export const getCurrentStock2 = (productNameId, channelNameId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CURRENT_STOCK_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Stock/GetCurrentStock/${productNameId}/${channelNameId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: GET_CURRENT_STOCK_SUCCESS, payload: data.dataObj[0] });
  } catch (error) {
    dispatch({
      type: GET_CURRENT_STOCK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCurrentStock = (datas) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CURRENT_STOCK_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Stock/GetCurrentStock/${datas.productNameId}/${datas.channelNameId}`,
      // url: `${API_URL}/Stock/GetCurrentStock/${productId}/${channelId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: GET_CURRENT_STOCK_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: GET_CURRENT_STOCK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getReferencesList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_REFERENCE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetAdjsutmentRef`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    const final = data.dataObj.map((single) => {
      if (single.value !== null) {
        return {
          id: single.key,
          label: single.value,
        };
      }
    });

    const final2 = final.filter((single) => {
      return single !== undefined;
    });

    dispatch({
      type: GET_REFERENCE_LIST_SUCCESS,
      payload: final2.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: GET_REFERENCE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getStockAdjustment = (datas) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_STOCK_ADJUSTMENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/StockIn/GetStockAdjustmentDetails/${datas.referenceNameId}`,
      // url: `${API_URL}/Stock/GetCurrentStock/${productId}/${channelId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: GET_STOCK_ADJUSTMENT_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: GET_STOCK_ADJUSTMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

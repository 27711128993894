import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateBatch } from "../../redux/actions/BatchActions";
import { useDispatch } from "react-redux";

const statusItems = [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
  { id: "3", title: "Pause" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  productId: "",
  batchId: "",
  batchNo: "",
  batchProductionDate: [date.getFullYear(), mnth, day].join("-"),
  batchExpiryDate: [date.getFullYear(), mnth, day].join("-"),
  batchCertificateNo: "",
  batchTestDate: [date.getFullYear(), mnth, day].join("-"),
};

const AddBatchForStockin = ({
  data,
  setData,
  openPopup,
  setOpenPopup,
  productId,
}) => {
  const dispatch = useDispatch();
  const batchUpdate = useSelector((state) => state.batchUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = batchUpdate;
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      // console.log("hello");
      // console.log(data);
      const filteredProduct = data.items.filter((singleData) => {
        return singleData.productId === values.productId;
      });
      console.log("filteredProduct");
      console.log(filteredProduct);
      let sidBatches = filteredProduct[0].sidBatches;
      if (sidBatches.length > 0) {
        sidBatches.push({
          batchId: sidBatches.length,
          batchNo: values.batchNo,
          batchProductionDate: values.batchProductionDate,
          batchExpiryDate: values.batchExpiryDate,
          batchCertificateNo: values.batchCertificateNo,
          batchTestDate: values.batchTestDate,
          batchTestReport: 1,
        });
      } else {
        sidBatches.push({
          batchId: sidBatches.length,
          batchNo: values.batchNo,
          batchProductionDate: values.batchProductionDate,
          batchExpiryDate: values.batchExpiryDate,
          batchCertificateNo: values.batchCertificateNo,
          batchTestDate: values.batchTestDate,
          batchTestReport: 1,
        });
        // console.log("hello");
        // console.log(values);
      }
      console.log("hello");
      console.log(sidBatches);

      var items = data.items.slice();
      var newItems = items.map(function (sitems) {
        // console.log("sitems");
        // console.log(sitems);
        for (var key in sitems) {
          if (sitems.productId === values.productId) {
            // console.log("Hello");
            sitems["sidBatches"] = sidBatches;
          }
        }
        return sitems;
      });

      console.log("newItems");
      console.log(newItems);

      setData({
        ...data,
        items: newItems,
      });
      setOpenPopup({
        ...openPopup,
        isOpen: false,
      });
    },
  });

  const changeValues = () => {
    formik.setFieldValue("productId", productId);
  };

  useEffect(() => {
    changeValues();
  }, [data, productId]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Input
            label="Batch No"
            name="batchNo"
            error={formik.errors.batchNo}
            touched={formik.touched.batchNo}
            value={formik.values.batchNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Datepicker
            label="batchProductionDate"
            name="batchProductionDate"
            value={formik.values.batchProductionDate}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Datepicker
            label="batchExpiryDate"
            name="batchExpiryDate"
            value={formik.values.batchExpiryDate}
            onChange={formik.handleChange}
          />
          <Input
            label="Batch Certificate No"
            name="batchCertificateNo"
            error={formik.errors.batchCertificateNo}
            touched={formik.touched.batchCertificateNo}
            value={formik.values.batchCertificateNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Datepicker
            label="batchTestDate"
            name="batchTestDate"
            value={formik.values.batchTestDate}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Button
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <ControlPointIcon />
                )
              }
              type="submit"
              color="success"
              size="large"
              text="Add"
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withRouter(AddBatchForStockin);

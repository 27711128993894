import {
  BATCH_DETAILS_FAIL,
  BATCH_DETAILS_REQUEST,
  BATCH_DETAILS_RESET,
  BATCH_DETAILS_SUCCESS,
  BATCH_LIST_FAIL,
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_UPDATE_FAIL,
  BATCH_UPDATE_REQUEST,
  BATCH_UPDATE_RESET,
  BATCH_UPDATE_SUCCESS,
} from "../constants/BatchConstants";

export const batchListReducer = (state = { batches: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case BATCH_LIST_REQUEST:
      return { loading: true };
    case BATCH_LIST_SUCCESS:
      return {
        loading: false,
        batches: action.payload,
      };
    case BATCH_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const batchDetailsReducer = (state = { batchSingle: {} }, action) => {
  switch (action.type) {
    case BATCH_DETAILS_REQUEST:
      return { loading: true, ...state };
    case BATCH_DETAILS_SUCCESS:
      return { loading: false, batchSingle: action.payload };
    case BATCH_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_DETAILS_RESET:
      return { batchSingle: {} };

    default:
      return state;
  }
};

export const batchUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_UPDATE_REQUEST:
      return { loading: true };
    case BATCH_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case BATCH_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  ASSIGNED_MENU_CREATE_FAIL,
  ASSIGNED_MENU_CREATE_REQUEST,
  ASSIGNED_MENU_CREATE_SUCCESS,
  ASSIGNED_MENU_UPDATE_FAIL,
  ASSIGNED_MENU_UPDATE_REQUEST,
  ASSIGNED_MENU_UPDATE_SUCCESS,
  ROLES_CREATE_FAIL,
  ROLES_CREATE_REQUEST,
  ROLES_CREATE_SUCCESS,
  ROLES_DETAILS_FAIL,
  ROLES_DETAILS_REQUEST,
  ROLES_DETAILS_SUCCESS,
  ROLES_LIST_FAIL,
  ROLES_LIST_REQUEST,
  ROLES_LIST_SUCCESS,
  ROLE_FOR_SPECIFIC_USER_UPDATE_FAIL,
  ROLE_FOR_SPECIFIC_USER_UPDATE_REQUEST,
  ROLE_FOR_SPECIFIC_USER_UPDATE_SUCCESS,
  UNASSIGNED_MENU_CREATE_FAIL,
  UNASSIGNED_MENU_CREATE_REQUEST,
  UNASSIGNED_MENU_CREATE_SUCCESS,
} from "../constants/RoleAndMenuConstants";
import { unAssignedCustomerListReducer } from "../reducers/customerReducers";

export const listRoles = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLES_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Role/GetRoles/1`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    console.log(data);
    dispatch({ type: ROLES_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: ROLES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listRolesDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLES_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Role/GetRoleDetails/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const data = await Axios(config);

    // console.log("data[0]");
    console.log("data here");
    console.log(data.data);

    dispatch({ type: ROLES_DETAILS_SUCCESS, payload: data.data.dataObj });
  } catch (error) {
    dispatch({
      type: ROLES_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createAssignedMenu = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLES_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
      roleDetails: {
        roleId,
        roleName,
        roleActiveStatus,
        menuList,
        permittedMenuList,
      },
    } = getState();
    const response = menuList.filter((item) => data.includes(item.menuId));
    const response2 = permittedMenuList.concat(response);
    const UnAssignedResponseFilter = menuList.filter(
      (item) => !data.includes(item.menuId)
    );
    console.log("UnAssignedResponseFilter");
    console.log(UnAssignedResponseFilter);

    dispatch({
      type: ROLES_DETAILS_SUCCESS,
      payload: {
        roleId: roleId,
        roleName: roleName,
        roleActiveStatus: roleActiveStatus,
        menuList: UnAssignedResponseFilter,
        permittedMenuList: response2,
      },
    });
  } catch (error) {
    dispatch({
      type: ROLES_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createUnassignedMenu = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLES_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
      roleDetails: {
        roleId,
        roleName,
        roleActiveStatus,
        menuList,
        permittedMenuList,
      },
    } = getState();
    // console.log(menuList);
    const response = permittedMenuList.filter((item) =>
      data.includes(item.menuId)
    );
    const response2 = menuList.concat(response);
    const AssignedResponseFilter = permittedMenuList.filter(
      (item) => !data.includes(item.menuId)
    );

    dispatch({
      type: ROLES_DETAILS_SUCCESS,
      payload: {
        roleId: roleId,
        roleName: roleName,
        roleActiveStatus: roleActiveStatus,
        menuList: response2,
        permittedMenuList: AssignedResponseFilter,
      },
    });
  } catch (error) {
    dispatch({
      type: ROLES_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateAssignedMenus = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSIGNED_MENU_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    var config = {
      method: "put",

      url: `${API_URL}/Role/UpdateRoleDetails`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    Axios(config).then(function (response) {
      dispatch({ type: ASSIGNED_MENU_UPDATE_SUCCESS });
    });
  } catch (error) {
    dispatch({
      type: ASSIGNED_MENU_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createRole = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLES_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/Role/AddRole`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    await Axios(config)
      .then(function (response) {
        // console.log(response);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    dispatch({ type: ROLES_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ROLES_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateRoleForSpecificUser =
  (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_FOR_SPECIFIC_USER_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/Role/UpdateUserRole`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      await Axios(config)
        .then(function (response) {
          // console.log(response);
          // console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });

      dispatch({ type: ROLE_FOR_SPECIFIC_USER_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ROLE_FOR_SPECIFIC_USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

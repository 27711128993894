import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash } from "react-icons/bi";
import EditableField from "./EditableField";
import EditableProductField from "./EditableProductField";
import { Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useCallback } from "react";
import { Autocomplete } from "@mui/material";
import Input from "./controls/Input";
import Select from "react-select";
import "react-bootstrap-typeahead/css/Typeahead.css";

import EditProductDropdownField from "./controls/EditProductDropdownField";
import EditProductDropdownFieldMultiple from "./controls/EditProductDropdownFieldMultiple";
import EditProductChangeField from "./controls/EditProductChangeField";

class SalesOrderItemAdminEdit extends React.Component {
  constructor(props) {
    super(props);

    // this.update = this.update.bind(this);
    // this.increment = this.increment.bind(this);
  }
  // update = (nextState) => {
  //   this.setState(nextState);
  // };
  // increment() {
  //   this.setState({ count: this.state.count + 1 });
  // }
  render() {
    // const { switchMappingOption } = this.state;
    // var setSwitchMappingOption = this.setState;

    var onItemizedProductEdit = this.props.onItemizedProductEdit;
    var onItemizedBatchEdit = this.props.onItemizedBatchEdit;
    var products = this.props.products;
    var onItemizedItemEdit = this.props.onItemizedItemEdit;
    var onItemizedBatchAndMapEdit = this.props.onItemizedBatchAndMapEdit;
    var currency = this.props.currency;
    var rowDel = this.props.onRowDel;
    var escalate = this.props.escalate === 1 ? false : true;
    var allOptionAuto = this.props.allOptionAuto;

    var itemTable = this.props.items.map(function (item) {
      return (
        <ItemRow2
          onItemizedProductEdit={onItemizedProductEdit}
          products={products}
          onItemizedBatchEdit={onItemizedBatchEdit}
          onItemizedItemEdit={onItemizedItemEdit}
          onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
          item={item}
          onDelEvent={rowDel.bind(this)}
          key={item.index}
          currency={currency}
          escalate={escalate}
          // switchMappingOption={this.state.switchMappingOption}
          // setSwitchMappingOption={setSwitchMappingOption}
          allOptionAuto={allOptionAuto}
        />
      );
    });
    return (
      <div style={{ position: "relative !important" }}>
        <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
          <Table>
            <thead>
              <tr>
                <th>Product</th>
                <th className="text-center">Unit Price</th>
                <th className="text-center">Vat</th>
                <th className="text-center">Ait</th>
                <th className="text-center">Sum(Vat+Ait)</th>
                <th className="text-center">Discount</th>
                {escalate === false && this.props.allOptionAuto ? (
                  <th className="text-center">Level</th>
                ) : (
                  ""
                )}
                <th className="text-center">New Price</th>
                <th className="text-center">Quantity</th>

                <th className="text-center">Line Total</th>

                {escalate === false && this.props.allOptionAuto ? (
                  <>
                    <th className="text-center">Map?</th>

                    <th className="text-center">Batch #</th>
                  </>
                ) : (
                  <th className="text-center">Action</th>
                )}
              </tr>
            </thead>
            <tbody>{itemTable}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const ItemRow2 = (props) => {
  var myRef = React.useRef(null);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);

  const onDelEvent = () => {
    props.onDelEvent(props.item);
  };
  // console.log("props.item");
  // console.log(props.item);
  useEffect(() => {
    if (props?.item?.mappedProductInfo?.id !== "") {
      setSwitchMappingOption(true);
    }
  }, [props.item.mappedProductInfo]);
  //
  return (
    <tr ref={myRef}>
      <td style={{ width: "300px" }}>
        {/* <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            type: "text",
            name: "productName",
            placeholder: "Item name",
            value: props.item.productName,
            id: props.item.productName,
          }}
          style={{ width: "200px" }}
          disabled
        /> */}
        <EditProductChangeField
          name="productId"
          onItemizedProductEdit={props.onItemizedProductEdit}
          info={{
            index: props.item.index,
            id: props.item.productId,
            label: props.item.productName,
          }}
          item={props.products}
          allItem={props.item}
          placeholder="Product Name"
          style={{ width: "300px" }}
          // disabled
        />
        {/* <Typeahead
          clearButton
          id="basic-example"
          name="productId"
          label="Product Name"
          // disabled={loadingProduct ? true : false}
          onChange={(selected) => {
            // // console.log("selected");
            // // console.log(selected);
            // setSelected(selected);
            // const value =
            //   selected.length > 0
            //     ? {
            //         id: selected[0].id,
            //         label: selected[0].label,
            //       }
            //     : {
            //         id: "",
            //         label: "",
            //       };
            // // console.log(value);
            // setProduct({
            //   ...product,
            //   id: value.id,
            //   label: value.label,
            // });
            // if (
            //   value.id !== "" &&
            //   selectedCustomer.length > 0
            // ) {
            //   console.log("Hi");
            //   dispatch(
            //     listPriceSummaryBasedOnProductAndCustomer({
            //       customerId: selectedCustomer[0].id,
            //       prodcutId: value.id,
            //     })
            //   );
            // }
          }}
          options={props.products !== undefined ? props.products : []}
          placeholder="Product Name"
          // selected={[
          //   { id: props.item.sodProductId, label: props.item.productName },
          // ]}
          style={{ width: "300px" }}
        /> */}
      </td>

      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            leading: props.currency,
            type: "number",
            name: "sodBaseUnitPrice",
            textAlign: "text-end",
            value: props.item.sodBaseUnitPrice,
            id: props.item.index,
          }}
          min="0"
          style={{ width: "100px" }}
          // disabled={props.escalate}
          // disabled
        />
      </td>
      <td style={{ minWidth: "200px" }}>
        <InputGroup>
          <Form.Control
            name="vat"
            type="number"
            id={props.item.index}
            value={props.item.vat}
            onChange={props.onItemizedItemEdit}
            placeholder="0.0"
            min="0"
            // disabled={switchT ? false : true}
          />
          <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
          <InputGroup.Text id="basic-addon1">
            » {parseFloat(props.item.productVatAmmount).toFixed(2)}
          </InputGroup.Text>
        </InputGroup>
      </td>
      <td style={{ minWidth: "200px" }}>
        <InputGroup>
          <Form.Control
            name="ait"
            type="number"
            value={props.item.ait}
            id={props.item.index}
            onChange={props.onItemizedItemEdit}
            placeholder="0.0"
            min="0"
            // disabled={switchT ? false : true}
          />
          <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
          <InputGroup.Text id="basic-addon1">
            » {parseFloat(props.item.productAitAmmount).toFixed(2)}
          </InputGroup.Text>
        </InputGroup>
      </td>
      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            leading: props.currency,
            type: "number",
            name: "sumVatAit",
            textAlign: "text-end",
            value: props.item.sumVatAit,
            id: props.item.index,
          }}
          style={{ width: "100px" }}
          min="0"
          // disabled={props.escalate}
          disabled
        />
      </td>
      <td style={{ minWidth: "200px" }}>
        <InputGroup>
          <Form.Control
            style={{ width: "120px" }}
            name="discountRate"
            type="number"
            value={props.item.discountRate}
            id={props.item.index}
            onChange={props.onItemizedItemEdit}
            placeholder="0.00"
            // min="0"
            // step="1"
            autoComplete="off"
            min="0"
            // required="required"
          />

          <InputGroup.Text id="basic-addon1">
            <Form.Check
              type="checkbox"
              label="%"
              className=""
              name="switchDiscountPercentage"
              id={props.item.index}
              checked={props.item.switchDiscountPercentage}
              onChange={(e) => {
                if (props.item.switchDiscountPercentage) {
                  props.onItemizedItemEdit({
                    target: {
                      id: props.item.index,
                      name: "switchDiscountPercentage",
                      value: false,
                    },
                  });
                } else {
                  props.onItemizedItemEdit({
                    target: {
                      id: props.item.index,
                      name: "switchDiscountPercentage",
                      value: true,
                    },
                  });
                }
              }}
            />
          </InputGroup.Text>
        </InputGroup>
      </td>
      {props.escalate === false && props.allOptionAuto ? (
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={props.onItemizedItemEdit}
            celldata={{
              type: "text",
              name: "productLevel",
              placeholder: "Product Level",
              value: props.item.productLevel,
              id: props.item.productName,
            }}
            style={{ width: "70px" }}
            disabled
          />
        </td>
      ) : (
        ""
      )}
      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            leading: props.currency,
            type: "number",
            name: "unitPrice",
            textAlign: "text-end",
            value: props.item.unitPrice,
            id: props.item.index,
          }}
          style={{ width: "100px" }}
          // disabled={props.escalate}
          disabled
        />
      </td>
      {/* <td style={{ minWidth: "130px" }}>
        <EditableProductField
          itemizeditemedit={this.props.onItemizedItemEdit}
          celldata={{
            leading: this.props.currency,
            type: "number",
            name: "unitPrice",
            min: 1,
            step: "0.01",
            presicion: 2,
            textAlign: "text-end",
            value: this.props.item.unitPrice,
            id: this.props.item.unitPrice,
          }}
          disabled={this.props.escalate}
        />
      </td> */}
      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            type: "number",
            name: "quantity",
            textAlign: "center",
            step: "1",
            value: props.item.quantity,
            id: props.item.index,
          }}
          style={{ width: "80px" }}
          disabled={props.escalate}
        />
      </td>
      <td style={{ minWidth: "110px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            leading: props.currency,
            type: "number",
            name: "lineTotal",
            step: "0.01",
            presicion: 2,
            textAlign: "text-end",
            value: props.item.lineTotal,
            id: props.item.lineTotal,
          }}
          style={{ width: "100px" }}
          disabled
        />
      </td>
      {/* <td></td> */}
      {props.escalate === false && props.allOptionAuto ? (
        <>
          <td
            style={{
              display: switchMappingOption ? "flex" : "",
              width: switchMappingOption ? "300px" : "2px",
              minWidth: switchMappingOption ? "300px" : "2px",
            }}
          >
            <div>
              <InputGroup.Text id="basic-addon1" style={{ margin: "6px" }}>
                <Form.Check
                  type="checkbox"
                  id="custom-checkbox"
                  label=""
                  className=""
                  checked={switchMappingOption}
                  onChange={(e) => {
                    setSwitchMappingOption(!switchMappingOption);
                  }}
                />
              </InputGroup.Text>
            </div>
            <div style={{ marginLeft: switchMappingOption ? "9px" : "0px" }}>
              {switchMappingOption ? (
                <Form.Group
                  controlId="form-productId"
                  style={{ minWidth: "200px", margin: "5px" }}
                >
                  <EditProductDropdownField
                    name="sodMappedProductId"
                    itemizeditemedit={props.onItemizedBatchAndMapEdit}
                    info={props.item.mappedProductInfo}
                    item={props.item.mappedItemList}
                    allItem={props.item}
                    placeholder="Map with a product"
                    // disabled
                  />
                </Form.Group>
              ) : (
                <></>
              )}
            </div>
          </td>
          <td style={{ width: "200px" }}>
            <Form.Group controlId="form-productId" style={{ margin: "5px" }}>
              <EditProductDropdownFieldMultiple
                name="batchInfo"
                itemizeditemedit={props.onItemizedBatchEdit}
                info={props.item.batchInfo}
                item={props.item.batchList}
                allItem={props.item}
                placeholder="Batch #"
                // disabled
              />
            </Form.Group>
          </td>
        </>
      ) : (
        ""
      )}
      {props.escalate === false && props.allOptionAuto ? (
        <></>
      ) : (
        <td className="text-center" style={{ minWidth: "50px" }}>
          <BiTrash
            onClick={onDelEvent}
            style={{ height: "33px", width: "33px", padding: "7.5px" }}
            className="text-white mt-1 btn btn-danger"
          />
        </td>
      )}
    </tr>
  );
};
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      switchMappingOption: false,
    };
  }
  onDelEvent() {
    this.props.onDelEvent(this.props.item);
  }

  render() {
    const { switchMappingOption } = this.state;

    return (
      <tr ref={this.myRef}>
        <td style={{ width: "200px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "text",
              name: "productName",
              placeholder: "Item name",
              value: this.props.item.productName,
              id: this.props.item.productName,
            }}
            style={{ width: "200px" }}
            disabled
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "number",
              name: "quantity",
              textAlign: "center",
              step: "1",
              value: this.props.item.quantity,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        {/* <td style={{ minWidth: "130px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              min: 1,
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.unitPrice,
            }}
            disabled={this.props.escalate}
          />
        </td> */}
        <td style={{ minWidth: "150px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "lineTotal",
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.lineTotal,
              id: this.props.item.lineTotal,
            }}
            disabled
          />
        </td>
        {/* <td></td> */}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <>
            <td style={{ minWidth: "auto", display: "flex" }}>
              <div>
                <InputGroup.Text id="basic-addon1">
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label=""
                    className=""
                    checked={this.props.switchMappingOption}
                    onChange={(e) => {
                      this.setState({
                        switchMappingOption: !switchMappingOption,
                      });
                    }}
                  />
                </InputGroup.Text>
              </div>
              <div style={{ marginLeft: "9px" }}>
                {switchMappingOption ? (
                  <Form.Group
                    controlId="form-productId"
                    style={{ minWidth: "250px" }}
                  >
                    <EditProductDropdownField
                      name="sodMappedProductId"
                      itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                      info={this.props.item.mappedProductInfo}
                      item={this.props.item.mappedItemList}
                      allItem={this.props.item}
                      placeholder="Map with a product"
                      // disabled
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
              </div>
            </td>
            <td style={{ minWidth: "250px" }}>
              <Form.Group controlId="form-productId">
                <EditProductDropdownField
                  name="sodRealatedBatchId"
                  itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                  info={this.props.item.batchInfo}
                  item={this.props.item.batchList}
                  allItem={this.props.item}
                  placeholder="Batch #"
                  // disabled
                />
              </Form.Group>
            </td>
          </>
        ) : (
          ""
        )}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <></>
        ) : (
          <td className="text-center" style={{ minWidth: "50px" }}>
            <BiTrash
              onClick={this.onDelEvent.bind(this)}
              style={{ height: "33px", width: "33px", padding: "7.5px" }}
              className="text-white mt-1 btn btn-danger"
            />
          </td>
        )}
      </tr>
    );
  }
}

export default SalesOrderItemAdminEdit;

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "./controls/Button";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles(() => ({
  dialog: {
    width: "500px",
    padding: useTheme().spacing(2),
    position: "absolute",
    top: useTheme().spacing(5),
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "2.5rem !important",
  },
  dialogAction: {
    display: "flex !important",
    justifyContent: "flex-end !important",
  },
  titleIcon: {
    background: "rgba(255, 152, 0, 0.1)",
    color: useTheme().palette.warning.main,
    "&:hover": {
      //   backgroundColor: useTheme().palette.error.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
      color: "#ed6c02",
    },
  },
}));

const Popup = (props) => {
  const { openPopup, setOpenPopup } = props;
  // console.log("openPopup");
  // console.log(openPopup);
  const classes = useStyles();
  return (
    <Dialog open={openPopup.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogContent}>
        <IconButton disableRipple className={classes.titleIcon}>
          <ErrorOutlineIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className={classes.dialogTitle}>
          {openPopup.title}
        </Typography>
        <Typography variant="subtitle2">{openPopup.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          text="Ok"
          color="warning"
          onClick={() => {
            setOpenPopup({
              ...openPopup,
              isOpen: false,
            });
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default Popup;

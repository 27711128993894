import React from "react";
import { Container } from "react-bootstrap";
import ListIcon from "@mui/icons-material/List";
import InvoiceForm from "../../core/InvoiceForm";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import WorkOrderInternalForm from "../../core/controls/WorkOrderInternalForm";

const CreateIdea = () => {
  // d-flex flex-column align-items-center justify-content-center w-100
  return (
    <div>
      <Menu />
      {/* <div className="d-flex flex-column align-items-center justify-content-center w-100"> */}
      <Container>
        <InvoiceForm />
        {/* <WorkOrderInternalForm /> */}
      </Container>
      {/* </div> */}
    </div>
  );
};

export default CreateIdea;

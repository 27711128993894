import {
  STOCKIN_ADJUST_FAIL,
  STOCKIN_ADJUST_REQUEST,
  STOCKIN_ADJUST_RESET,
  STOCKIN_ADJUST_SUCCESS,
  STOCKIN_CREATE_FAIL,
  STOCKIN_CREATE_REQUEST,
  STOCKIN_CREATE_RESET,
  STOCKIN_CREATE_SUCCESS,
  STOCKIN_DETAILS_FAIL,
  STOCKIN_DETAILS_REQUEST,
  STOCKIN_DETAILS_RESET,
  STOCKIN_DETAILS_SUCCESS,
  STOCKIN_ESCALATION_FAIL,
  STOCKIN_ESCALATION_REQUEST,
  STOCKIN_ESCALATION_RESET,
  STOCKIN_ESCALATION_SUCCESS,
  STOCKIN_LIST_FAIL,
  STOCKIN_LIST_REQUEST,
  STOCKIN_LIST_SUCCESS,
  STOCKIN_QUANTITY_UPDATE_FAIL,
  STOCKIN_QUANTITY_UPDATE_REQUEST,
  STOCKIN_QUANTITY_UPDATE_RESET,
  STOCKIN_QUANTITY_UPDATE_SUCCESS,
  STOCKIN_UPDATE_FAIL,
  STOCKIN_UPDATE_REQUEST,
  STOCKIN_UPDATE_RESET,
  STOCKIN_UPDATE_SUCCESS,
} from "../constants/stockInConstants";

export const listStockinReducer = (state = { stockin: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case STOCKIN_LIST_REQUEST:
      return { loading: true };
    case STOCKIN_LIST_SUCCESS:
      return {
        loading: false,
        stockin: action.payload,
      };
    case STOCKIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const StockinCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCKIN_CREATE_REQUEST:
      return { loading: true };
    case STOCKIN_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        createdStockin: action.payload,
      };
    case STOCKIN_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case STOCKIN_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const StockinEscalateReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCKIN_ESCALATION_REQUEST:
      return { loading: true };
    case STOCKIN_ESCALATION_SUCCESS:
      return {
        loading: false,
        success: true,
        escalatedStockin: action.payload,
      };
    case STOCKIN_ESCALATION_FAIL:
      return { loading: false, error: action.payload };
    case STOCKIN_ESCALATION_RESET:
      return {};
    default:
      return state;
  }
};

export const StockinDetailsReducer = (
  state = { stockinsingle: {} },
  action
) => {
  switch (action.type) {
    case STOCKIN_DETAILS_REQUEST:
      return { loading: true, ...state };
    case STOCKIN_DETAILS_SUCCESS:
      return { loading: false, stockinsingle: action.payload };
    case STOCKIN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case STOCKIN_DETAILS_RESET:
      return { stockinsingle: {} };

    default:
      return state;
  }
};

export const StockinUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCKIN_UPDATE_REQUEST:
      return { loading: true };
    case STOCKIN_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedStockin: action.payload,
      };
    case STOCKIN_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STOCKIN_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const StockinQtyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCKIN_QUANTITY_UPDATE_REQUEST:
      return { loading: true };
    case STOCKIN_QUANTITY_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedStockinQty: action.payload,
      };
    case STOCKIN_QUANTITY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STOCKIN_QUANTITY_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const StockinAdjustReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCKIN_ADJUST_REQUEST:
      return { loading: true };
    case STOCKIN_ADJUST_SUCCESS:
      return {
        loading: false,
        success: true,
        adjustedStockin: action.payload,
      };
    case STOCKIN_ADJUST_FAIL:
      return { loading: false, error: action.payload };
    case STOCKIN_ADJUST_RESET:
      return {};
    default:
      return state;
  }
};

import {
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_RESET,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_RESET,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  MAPPED_PRODUCT_LIST_FAIL,
  MAPPED_PRODUCT_LIST_REQUEST,
  MAPPED_PRODUCT_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_PRODUCTS_TOP_FAIL,
  PRODUCT_PRODUCTS_TOP_REQUEST,
  PRODUCT_PRODUCTS_TOP_SUCCESS,
  PRODUCT_PRICE_BY_ID_REQUEST,
  PRODUCT_PRICE_BY_ID_SUCCESS,
  PRODUCT_PRICE_BY_ID_FAIL,
  PRODUCT_PRICE_CREATE_REQUEST,
  PRODUCT_PRICE_CREATE_SUCCESS,
  PRODUCT_PRICE_CREATE_FAIL,
  PRODUCT_PRICE_CREATE_RESET,
  PRODUCT_PRICE_LIST_REQUEST,
  PRODUCT_PRICE_LIST_SUCCESS,
  PRODUCT_PRICE_LIST_FAIL,
  PRODUCT_MAP_REQUEST,
  PRODUCT_MAP_SUCCESS,
  PRODUCT_MAP_FAIL,
  PRODUCT_MAP_RESET,
  //function
  FUNCTION_LIST_REQUEST,
  FUNCTION_LIST_SUCCESS,
  FUNCTION_LIST_FAIL,


  FUNCTION_CREATE_SUCCESS,
  FUNCTION_CREATE_REQUEST,
  FUNCTION_CREATE_FAIL,
  FUNCTION_CREATE_RESET,

  FUNCTION_LIST_DETAILS_SUCCESS,
  FUNCTION_LIST_DETAILS_FAIL,
  FUNCTION_LIST_DETAILS_REQUEST,
  FUNCTION_LIST_DETAILS_RESET,
  
  FUNCTION_UPDATE_REQUEST,
  FUNCTION_UPDATE_RESET,
  FUNCTION_UPDATE_SUCCESS,
  FUNCTION_UPDATE_FAIL,

  FUNCTION_DELETE_REQUEST,
  FUNCTION_DELETE_RESET,
  FUNCTION_DELETE_SUCCESS,
  FUNCTION_DELETE_FAIL,




  COUNTRY_ORIGIN_LIST_REQUEST,
  COUNTRY_ORIGIN_LIST_SUCCESS,
  COUNTRY_ORIGIN_LIST_FAIL,
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_LIST_FAIL,
  GROUP_LIST_REQUEST,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_FAIL,
  CATEGORYBASEDPRODUCT_DETAILS_REQUEST,
  CATEGORYBASEDPRODUCT_DETAILS_SUCCESS,
  CATEGORYBASEDPRODUCT_DETAILS_FAIL,
  CATEGORYBASEDPRODUCT_DETAILS_RESET,
  PRICE_DETAILS_REQUEST,
  PRICE_DETAILS_SUCCESS,
  PRICE_DETAILS_FAIL,
  PRICE_DETAILS_RESET,
  MAPPED_PRODUCTS_REQUEST,
  MAPPED_PRODUCTS_SUCCESS,
  MAPPED_PRODUCTS_FAIL,
  SAMPLEPRODUCT_UPDATE_REQUEST,
  SAMPLEPRODUCT_UPDATE_SUCCESS,
  SAMPLEPRODUCT_UPDATE_FAIL,
  SAMPLEPRODUCT_UPDATE_RESET,
} from "../constants/productConstants";

export const productListReducer = (state = { products: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mappedProductListReducer = (
  state = { mappedproducts: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case MAPPED_PRODUCT_LIST_REQUEST:
      return { loading: true };
    case MAPPED_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        mappedproducts: action.payload,
      };
    case MAPPED_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryListReducer = (state = { categories: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true };
    case CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
      };
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



export const functionListReducer = (state = { functions: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case FUNCTION_LIST_REQUEST:
      return { loading: true };
    case FUNCTION_LIST_SUCCESS:
      return {
        loading: false,
        functions: action.payload,
      };
    case FUNCTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
//function reducer start
export const productFunctionListReducer = (state = { functions: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case FUNCTION_LIST_REQUEST:
      return { loading: true };
    case FUNCTION_LIST_SUCCESS:
      return {
        loading: false,
        functions: action.payload,
      };
    case FUNCTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const productFunctionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FUNCTION_CREATE_REQUEST:
      return { loading: true };
    case FUNCTION_CREATE_SUCCESS:
      return { loading: false, success: true, functions: action.payload };
    case FUNCTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case FUNCTION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};


export const productFunctionDetailsReducer = (state = { funlist: {} }, action) => {
  switch (action.type) {
    case FUNCTION_LIST_DETAILS_REQUEST:
      return { loading: true, ...state };
    case FUNCTION_LIST_DETAILS_SUCCESS:
      return { loading: false, funlist: action.payload };
    case FUNCTION_LIST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case FUNCTION_LIST_DETAILS_RESET:
      return { funlist: {} };

    default:
      return state;
  }
};

export const productFunctionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case FUNCTION_UPDATE_REQUEST:
      return { loading: true };
    case FUNCTION_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case FUNCTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case FUNCTION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productFunctionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FUNCTION_DELETE_REQUEST:
      return { loading: true };
    case FUNCTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case FUNCTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case FUNCTION_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
//function reducer end

export const courntryOriginListReducer = (
  state = { countryOrigins: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case COUNTRY_ORIGIN_LIST_REQUEST:
      return { loading: true };
    case COUNTRY_ORIGIN_LIST_SUCCESS:
      return {
        loading: false,
        countryOrigins: action.payload,
      };
    case COUNTRY_ORIGIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const brandListReducer = (state = { brands: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case BRAND_LIST_REQUEST:
      return { loading: true };
    case BRAND_LIST_SUCCESS:
      return {
        loading: false,
        brands: action.payload,
      };
    case BRAND_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const groupListReducer = (state = { groups: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case GROUP_LIST_REQUEST:
      return { loading: true };
    case GROUP_LIST_SUCCESS:
      return {
        loading: false,
        groups: action.payload,
      };
    case GROUP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DETAILS_RESET:
      return { product: {} };

    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productMapReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_MAP_REQUEST:
      return { loading: true };
    case PRODUCT_MAP_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_MAP_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_MAP_RESET:
      return {};
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

//sell price

export const productPriceByIdListReducer = (
  state = { products: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case PRODUCT_PRICE_BY_ID_REQUEST:
      return { loading: true };
    case PRODUCT_PRICE_BY_ID_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_PRICE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productPriceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PRICE_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_PRICE_CREATE_SUCCESS:
      return { loading: false, success: true, productPrice: action.payload };
    case PRODUCT_PRICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_PRICE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productPriceListReducer = (
  state = { productPriceList: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case PRODUCT_PRICE_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_PRICE_LIST_SUCCESS:
      return {
        loading: false,
        productPriceList: action.payload,
      };
    case PRODUCT_PRICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const CategoryBasedProductDetailsReducer = (
  state = {
    productList: [],
  },
  action
) => {
  switch (action.type) {
    case CATEGORYBASEDPRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case CATEGORYBASEDPRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        productList: action.payload.sort((p1, p2) =>
          p1.productId > p2.productId ? 1 : p1.productId < p2.productId ? -1 : 0
        ),
      };
    case CATEGORYBASEDPRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORYBASEDPRODUCT_DETAILS_RESET:
      return { rolessss: {} };

    default:
      return state;
  }
};

export const priceDetailsReducer = (state = { priceSingle: [] }, action) => {
  switch (action.type) {
    case PRICE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRICE_DETAILS_SUCCESS:
      return { loading: false, priceSingle: action.payload };
    case PRICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PRICE_DETAILS_RESET:
      return { priceSingle: [] };

    default:
      return state;
  }
};

export const mappedProductsReducer = (
  state = { mappedproducts: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case MAPPED_PRODUCTS_REQUEST:
      return { loading: true };
    case MAPPED_PRODUCTS_SUCCESS:
      return {
        loading: false,
        mappedproducts: action.payload,
      };
    case MAPPED_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSampleUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SAMPLEPRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case SAMPLEPRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SAMPLEPRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SAMPLEPRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";

import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";

import { Container, FloatingLabel } from "react-bootstrap";

import Menu from "../../../core/Menu";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import {
  FilterProductsForInvoice,
  listBatchAndMappedItemBasedOnProduct,
  listOrderTypeInvoice,
  //listPackagingDoneSalesOrderInternalDetails,
  listDetailsEditPackingStickerAction,
  listProductsForInvoice,
  //packageDoneEditSalesOrderInternal,
  updatePackingStickerAction,
} from "../../../redux/actions/invoiceActions";

import { API_URL } from "../../../redux/constants/apiConstants";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../../core/Popup";
import { listCategories } from "../../../redux/actions/productActions";
import SuccessPopup from "../../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PackagingItem from "../../../core/PackagingItem";
var FilteredProduct = [];
let obj = {};

const EditStockOutDefinePackingForSticker = ({ match, history }) => {
  const salesOrderId = match.params.id;
  const dispatch = useDispatch();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const batchAndMappedItemListByProductDetails = useSelector(
    (state) => state.batchAndMappedItemListByProductDetails
  );
  const {
    loading: loadingbatchAndMappedItemListByProductDetail,
    error: errorbatchAndMappedItemListByProductDetail,
    batchAndMappedItems,
  } = batchAndMappedItemListByProductDetails;

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const packingStickerUpdateState = useSelector(
    (state) => state.packingStickerUpdateState
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedSalesOrderPackageDone,
  } = packingStickerUpdateState;

  const packingDetailsState = useSelector(
    (state) => state.packingDetailsState
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalpackagingdonesingle,
  } = packingDetailsState;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState("");

  const [switchDiscountPercentage, setSwitchDiscountPercentage] =
    useState(false);
  const [switchTOpen, setSwitchTOpen] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "1",
    unitPrice: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0",
    lineTotal: "0.00",
    switchDiscountPercentage: false,
  });
  const [lineTotal, setLineTotal] = useState(0.0);
  const [productVatAmmount, setProductVatAmmount] = useState(0.0);
  const [productAitAmmount, setProductAitAmmount] = useState(0.0);
  const [quantityPrice, setQuantityPrice] = useState(0.0);
  const [vadTotal, setVadTotal] = useState(0.0);
  const [subPurchase, setSubPurchase] = useState(0.0);
  const [vatAmmount, setVatAmmount] = useState(0.0);
  const [aitAmmount, setAitAmmount] = useState(0.0);
  const [discountAmmount, setDiscountAmmount] = useState(0.0);

  const [total, setTotal] = useState(0.0);
  const [selected, setSelected] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [batchAndMap, setBatchAndMap] = useState();
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [data, setData] = useState({
    isOpen: false,
    currency: "TK",
    currentDate: "",
    dateOfIssue: new Date().toLocaleDateString(),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    taxRate: "",
    taxAmmount: "0.00",
    vatRate: "",
    vatAmmount: "0.00",
    aitRate: "",
    aitAmmount: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "0.00",
    discountRate: "",
    discountAmmount: "0.00",
    adjustmentAmount: "0.00",
    switchDiscountPercentage: false,
    editableField: 1,
    items: [],
  });
  useEffect(() => {
    if (userInfo) {
      setSwitchTStuck(true);
      dispatch(listProductsForInvoice());
      dispatch(listDetailsEditPackingStickerAction(salesOrderId));

      dispatch(listCategories());
      dispatch(listCustomersForSignup());

      dispatch(listOrderTypeInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: {
          from: { pathname: `/salesorderinternal/${salesOrderId}/edit` },
        },
      };
      history.push(location);
    }
  }, [salesOrderId, dispatch, history, userInfo]);

  const sumDuplicate = (arr) => {
    const map = arr.reduce((acc, val) => {
      if (acc.has(val)) {
        acc.set(val, acc.get(val) + 1);
      } else {
        acc.set(val, 1);
      }
      return acc;
    }, new Map());
    return Array.from(map, (el) => el[0] * el[1]);
  };
  const changeValues = async () => {
    setSwitchTStuck(true);
    if (
      JSON.stringify(salesorderinternalpackagingdonesingle) !== "{}" &&
      salesorderinternalpackagingdonesingle !== undefined
    ) {
      var d = new Date(salesorderinternalpackagingdonesingle.invoiceDate);
      d = d.toString();
     
      d = d.split(" ");
      d = [d[2], d[1], d[3]].join("-");
      setInvoiceDate(d);

      setSelectedCustomer([
        {
          id: "",
          label: salesorderinternalpackagingdonesingle.customerName,
        },
      ]);

      setSelectedOrderType([
        {
          id: "",
          label: salesorderinternalpackagingdonesingle.jobType,
        },
      ]);
      if (batchAndMap !== undefined) {
        setData({
          ...data,
          invoiceNumber: salesorderinternalpackagingdonesingle.soSystemNo,
          items: batchAndMap,
        });
      }
    }
  };

  useEffect(() => {
    changeValues();
  }, [salesorderinternalpackagingdonesingle, batchAndMap]);

  useEffect(() => {
    if (salesorderinternalpackagingdonesingle?.stockOutPackagingItems) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      if (JSON.stringify(salesorderinternalpackagingdonesingle) !== "{}") {
        var newData =
          salesorderinternalpackagingdonesingle.stockOutPackagingItems.map(
            async (singleData, i) => {
              const response = await fetch(
                `${API_URL}/Product/GetPackType`,
                requestOptions
              );
              const data = await response.json();
              const finalPackType = data.dataObj.map((single) => {
                return {
                  id: single.packId,
                  label: single.packTypeName,
                };
              });
              return {
                index: singleData.sopProductId + "-" + i,
                productId: singleData.sopProductId,
                productName: singleData.productName,
                packSize: singleData.sopPackSize,
                packNo: singleData.sopNoPack,
                packQuantity: singleData.sopProductQty,
                quantity: singleData.sodConfirmedQty || 0.0,
                packTypeList: finalPackType,
                sopPackId: singleData.sopPackId,

                packInfo: {
                  id: singleData.sopPackId === null ? "" : singleData.sopPackId,
                  label:
                    singleData.sopPackId === null
                      ? ""
                      : singleData.packTypeName,
                },
                delete: 1,
              };
            }
          );
        Promise.all(newData).then((products) => {
          setBatchAndMap(products);
        });
      }
    }
  }, [salesorderinternalpackagingdonesingle]);

  useEffect(() => {
    handleCalculateSubTotal();
    handleCalculateTotal();
    handleProductCalculateTotal();
  }, []);

  useEffect(() => {
    handleProductCalculateTotal();
  }, [product]);

  useEffect(() => {
    handleCalculateTotal();
  }, [data]);

  const handleRowDel = (items) => {
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.index;
    });
    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
    handleCalculateSubTotal();
  };

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "1.00",
      description: "",
      quantity: 1,
    };
    if (
      product.id !== "" &&
      selectedCustomer.length > 0 &&
      selectedOrderType.length > 0
    ) {
      if (lineTotal < 0) {
        setOpenPopup({
          ...openPopup,
          title: "VALUE ISSUE!!!",
          subTitle: "Line Value can't be negative!",
          isOpen: true,
        });
      } else {
        setSwitchTStuck(true);
        dispatch(listBatchAndMappedItemBasedOnProduct(product.id)).then(
          (res) => {
            data.items.push({
              productId: product.id,
              productName: product.label,
              quantity: product.quantity || 1.0,
              vat: product.vat || 0,
              ait: product.ait || 0,
              unitPrice: lineTotal,
              discountAmmount: product.discount || 0,
              lineTotal: lineTotal * product.quantity || 1,
              batchList: res.finalBatch,
              mappedItemList: res.finalMappedItem,
              sodMappedProductId: "",
              sodRealatedBatchId: "",
            });
          }
        );

        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "1",
          unitPrice: "0.00",
          vat: JSON.stringify(obj) !== "{}" || obj !== undefined ? 5 : "0.00",
          ait: JSON.stringify(obj) !== "{}" || obj !== undefined ? 3 : "0.00",
          discount: "0",
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        handleCalculateSubTotal();
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };
  const handleProductCalculateTotal = () => {
    var quantity = parseFloat(product.quantity || 1.0);
    var unitPrice = parseFloat(product.unitPrice || 1.0);
    var vat = parseFloat(product.vat || 0);
    var ait = parseFloat(product.ait || 0);
    var discount = parseFloat(product.discount || 0);
    var productDiscountAmmount = discount;
    var lineTotal = parseFloat(0);
    var quantityPrice = parseFloat(0);
    var vadTotal = parseFloat(0);
    var subPurchase = parseFloat(0);

    if (product.switchDiscountPercentage) {
      productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
    }
    lineTotal = lineTotal + (unitPrice - productDiscountAmmount);
    quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

    var lineTotalVat = unitPrice * (vat / 100);
    var lineTotalAit = unitPrice * (ait / 100);

    lineTotal = lineTotal + lineTotalVat + lineTotalAit;

    vadTotal = lineTotalVat + lineTotalAit;
    vadTotal = parseFloat(vadTotal.toFixed(2));
    lineTotal = lineTotal.toFixed(2);

    quantityPrice = parseFloat(quantityPrice.toFixed(2));
    subPurchase = unitPrice + vadTotal;
    subPurchase = parseFloat(subPurchase.toFixed(2));

    setQuantityPrice(quantityPrice);
    setVadTotal(vadTotal);
    setSubPurchase(subPurchase);
    setLineTotal(lineTotal);
  };

  const handleCalculateSubTotal = () => {
    var items = data.items;
    var subTotal = parseFloat(0);

    items.map(function (item) {
      var singleLineTotal = parseFloat(item.lineTotal);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    setData({
      ...data,
      subTotal: parseFloat(subTotal).toFixed(2),
      taxAmmount: parseFloat(
        parseFloat(subTotal) * (data.taxRate / 100)
      ).toFixed(2),
      discountAmmount: parseFloat(
        parseFloat(subTotal) * (data.discountRate / 100)
      ).toFixed(2),
      total: subTotal - data.discountAmmount + parseFloat(data.taxAmmount),
    });
    setTotal(subTotal - data.discountAmmount + parseFloat(data.taxAmmount));
  };
  const handleCalculateTotal = () => {
    var items = data.items;
    var subTotal = parseFloat(0);
    var discountOverall = parseFloat(data.discount || 0);
    var adjustmentAmount = parseFloat(data.adjustmentAmount || 0);
    var productOverallDiscountAmmount = discountOverall;

    items.map(function (item) {
      var singleLineTotal = parseFloat(item.lineTotal);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    var totalT = parseFloat(0);
    var vatAmmountT = parseFloat(0);
    vatAmmountT = subTotal * (data.vatRate / 100);
    setVatAmmount(vatAmmountT);

    var aitAmmountT = parseFloat(0);
    aitAmmountT = subTotal * (data.aitRate / 100);
    setAitAmmount(aitAmmountT);

    totalT = subTotal + vatAmmountT + aitAmmountT;

    if (data.switchDiscountPercentage) {
      productOverallDiscountAmmount =
        totalT * (productOverallDiscountAmmount / 100);
    }

    setDiscountAmmount(productOverallDiscountAmmount);
    totalT = totalT - productOverallDiscountAmmount - adjustmentAmount;

    setTotal(totalT.toFixed(2));
  };

  const handleCalculateTotalFromEditable = (id, newItems) => {
   
    var items = newItems;
    var f = 0;
    items.map((single) => {
      if (single.packSize === "" && single.packNo === "") {
        f = 1;
      }
    });

    var editedNewItems = items.map(function (sitems, i) {
      for (var key in sitems) {
        if (key == "packQuantity" && sitems.index == id) {
          if (
            sitems["quantity"] <
            parseFloat(sitems.packSize) * parseFloat(sitems.packNo)
          ) {
            setOpenPopup({
              ...openPopup,
              title: `ERROR!!!`,
              subTitle: `Can't exceed original quantity.`,
              isOpen: true,
            });
          } else if (
            parseFloat(sitems["quantity"]) >=
            parseFloat(sitems.packSize) * parseFloat(sitems.packNo)
          ) {
            sitems[key] =
              parseFloat(sitems.packSize) * parseFloat(sitems.packNo);
          }
        }
      }
      return sitems;
    });

    var finalNewItem = editedNewItems.map((single, i) => {
      if (
        single.quantity >
          parseFloat(single.packSize) * parseFloat(single.packNo) &&
        single.index === id
      ) {
        return {
          index: single.productId + "-" + newItems.length,
          productId: single.productId,
          productName: single.productName,
          packSize: "",
          packNo: "",
          packQuantity: "",
          quantity: single.quantity,
          packTypeList: single.packTypeList,
          sopPackId: single.sopPackId,
          packInfo: {
            id: single.packInfo.id,
            label: single.packInfo.label,
          },
          delete: 1,
        };
      }
    });
    var a = finalNewItem.filter((s) => {
      return s !== undefined;
    });
    var flag = 0;
    var sum = 0;
    var newId = id.split("-");
    editedNewItems.map((single, i) => {
      if (single.productId === parseInt(newId[0])) {
        sum = sum + parseFloat(single.packSize) * parseFloat(single.packNo);
      }
    });

    var x = editedNewItems.filter((num) => {
      return num.index === id;
    });

    if (x[0].quantity === sum) {
      flag = -1;
    }

    if (flag === 0) {
      var arr = a;
      if (arr[0] !== undefined) {
        if (f === 0) {
          var index;
          editedNewItems.map((s, i) => {
            if (s.productId === arr[0].productId) {
              index = i;
            }
          });

          editedNewItems.splice(index + 1, 0, arr[0]);
        } else {
          setOpenPopup({
            ...openPopup,
            title: "MORE THAN ONE FIELD ERROR!!!",
            subTitle: "A empty field is open already!",
            isOpen: true,
          });
        }
      }
    }
    setData({
      ...data,
      items: editedNewItems,
    });
  };
  const onItemizedItemEdit = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var items = data.items.slice();
    var f = 0;
    items.map((single) => {
      if (single.packSize === "" && single.packNo === "") {
        f = 1;
      }
    });
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == item.name && sitems.index == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });
    // s

    setData({
      ...data,
      items: newItems,
    });
    var x = newItems.filter((num) => {
      return num.index === item.id;
    });
    setTimeout(() => {
      if (x[0].packSize !== "" && x[0].packNo !== "") {
        handleCalculateTotalFromEditable(item.id, newItems);
      }
    }, 2400);
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
  };

  const onItemizedBatchAndMapEdit = (evt) => {

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == evt.name && sitems.index == evt.index) {
          sitems[key] = evt.id;
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
    });
  };

  const editProductField = (event) => {
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
  };

  const openModal = (event) => {
    event.preventDefault();
    if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      // var flag = 0;
      if (data.items.length > 0) {
        var wasNull = false;
        for (var i in data.items) {
          if (
            data.items[i].packSize === "" ||
            data.items[i].packQuantity === ""
          ) {
            wasNull = true;
          }
        }
        if (wasNull === false) {
          var confirmedQty = data.items.map((singleData) => {
            return singleData.productId + "-" + singleData.quantity;
          });

          var removeDuplicateArrays = [...new Set(confirmedQty)];

          removeDuplicateArrays = removeDuplicateArrays.map((single) => {
            var id = single.split("-");
            return parseFloat(id[1]);
          });

          var sumQuantity = parseFloat(0);
          removeDuplicateArrays.map((singleValue) => {
            sumQuantity = sumQuantity + singleValue;
          });

          var sumPackQuantity = parseFloat(0);
          data.items.map((singleData) => {
            sumPackQuantity += singleData.packQuantity;
          });

          if (parseFloat(sumQuantity) === parseFloat(sumPackQuantity)) {
            var dataArray = data.items.map((singleData) => {
              return {
                soSystemId: parseInt(salesOrderId),
                sopProductId: parseInt(singleData.productId),

                sopProductQty: singleData.packQuantity,
                sopPackId: parseInt(singleData.sopPackId),
                sopPackSize: parseFloat(singleData.packSize),
                sopNoPack: parseInt(singleData.packNo),
              };
            });

            dispatch(updatePackingStickerAction(dataArray)).then(
              (result) => {
                if (result?.errMsg) {
                } else {
                  setOpenPopup2({
                    ...openPopup2,
                    title: `SUCCESS!!!`,
                    subTitle: `Packaging Done.`,
                    isOpen: true,
                  });
                }
              }
            );

            handleCalculateTotal();
          } else {
            setOpenPopup({
              ...openPopup,
              title: "QUANTITY ISSUE!!!",
              subTitle: "Quantities and Pack Quantities are not equal!",
              isOpen: true,
            });
          }
        } else {
          setOpenPopup({
            ...openPopup,
            title: "EMPTY FIELDS!!!",
            subTitle: "No Fields can be empty!",
            isOpen: true,
          });
        }
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });
  return (
    <div>
      <Menu />

      <Container>
        <Form>
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <Form.Label className="fw-bold">
                  Invoice&nbsp;Number:&nbsp;{data.invoiceNumber}
                </Form.Label>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-customerId">
                      <Form.Label className="fw-bold">
                        Customer Name:
                      </Form.Label>

                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="customerId"
                        label="Customer Name"
                        disabled={
                          loadingCustomer
                            ? true
                            : data.items.length > 0 && switchTStuck
                            ? true
                            : false
                        }
                        onChange={(selectedCustomer) => {
                          setSelectedCustomer(selectedCustomer);
                        }}
                        options={customers !== undefined ? customers : []}
                        placeholder="Customer Name"
                        selected={selectedCustomer}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          marginRight: "10px",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Type:</Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="orderTypeId"
                          label="Order Type"
                          disabled={
                            loadingOrderType
                              ? true
                              : data.items.length > 0 && switchTStuck
                              ? true
                              : false
                          }
                          onChange={(selectedOrderType) => {
                            setSelectedOrderType(selectedOrderType);
                          }}
                          options={orderTypes !== undefined ? orderTypes : []}
                          placeholder="Order Type"
                          selected={selectedOrderType}
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <Form.Label className="fw-bold">Order Date:</Form.Label>
                        <Form.Control
                          placeholder={"Who is this invoice to?"}
                          value={invoiceDate}
                          type="text"
                          name="dateOfIssue"
                          onChange={(event) => editField(event)}
                          autoComplete="name"
                          required="required"
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label className="fw-bold">
                      Customer&nbsp;Address:&nbsp;
                    </Form.Label>
                    <Form.Control
                      placeholder={"customerAddress"}
                      value={
                        JSON.stringify(
                          salesorderinternalpackagingdonesingle
                        ) !== "{}" &&
                        salesorderinternalpackagingdonesingle !== undefined
                          ? salesorderinternalpackagingdonesingle.customerAddress !==
                            null
                            ? salesorderinternalpackagingdonesingle.customerAddress
                            : "Not Available"
                          : "loading.."
                      }
                      type="text"
                      name="invoiceNumber"
                      autoComplete="address"
                      onChange={(event) => editField(event)}
                      required="required"
                      as="textarea"
                      rows={3}
                      disabled
                    />
                  </Col>
                </Row>
                <hr className="my-4" />
                <PackagingItem
                  onItemizedItemEdit={onItemizedItemEdit}
                  onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
                  onRowAdd={handleAddEvent}
                  onRowDel={handleRowDel}
                  currency={data.currency}
                  items={data.items}
                  escalate={data.editableField}
                  switchMappingOption={switchMappingOption}
                  setSwitchMappingOption={setSwitchMappingOption}
                  allOptionAuto={true}
                />

                <hr className="my-4" />
                <div>
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <EditIcon size="1rem" />
                    )}{" "}
                    Edit
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={() => {
                      history.push("/stockoutpackagesticker/orderlist");

                    }}
                  >
                    Back
                  </Button>
                </div>
              </Card>
            </Col>
            <Col
              md={4}
              lg={3}
              style={{
                display: "none",
              }}
            >
              <div
                className="pt-md-3 pt-xl-4 mt-4 p-3"
                style={{
                  backgroundColor: "#f6f6fd",
                  backgroundImage:
                    "url(" +
                    "https://www.transparenttextures.com/patterns/asfalt-light.png" +
                    ")",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    height: "300px",
                    width: "100%",
                    /* From https://css.glass */
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "16px",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                ></div>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">VAT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="vatRate"
                      type="number"
                      value={data.vatRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      min="0.00"
                      step="0.01"
                      max="100.00"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">AIT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="aitRate"
                      type="number"
                      value={data.aitRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      min="0.00"
                      step="0.01"
                      max="100.00"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Discount rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="discount"
                      type="number"
                      value={data.discount}
                      onChange={(event) => {
                        editField(event);
                      }}
                      placeholder="0.0"
                      min="0"
                      step="any"
                      max="100.00"
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      <Form.Check
                        type="checkbox"
                        id="custom-checkbox"
                        label="%"
                        className=""
                        checked={data.switchDiscountPercentage}
                        onChange={(e) => {
                          if (data.switchDiscountPercentage) {
                            // setSwitchDiscountPercentage(false);
                            setData({
                              ...data,
                              switchDiscountPercentage: false,
                            });
                          } else {
                            setData({
                              ...data,
                              switchDiscountPercentage: true,
                            });
                          }
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">
                    Adjustment Amount:
                  </Form.Label>
                  <Form.Control
                    name="adjustmentAmount"
                    type="number"
                    value={data.adjustmentAmount}
                    onChange={(event) => {
                      editField(event);
                    }}
                    placeholder="1.00"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="button"
                  onClick={openModal}
                  className="d-block w-100"
                >
                  {loadingUpdate ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : (
                    ""
                  )}{" "}
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
      </Container>
    </div>
  );
};

export default EditStockOutDefinePackingForSticker;

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Decoder from "jwt-decode";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import Checkbox from "../../core/controls/Checkbox";
import Button from "../../core/controls/Button";
import { barcodeSchema } from "../../schemas";
import { withRouter } from "react-router-dom";
import Notification from "../../core/Notification";
import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import { listPackTypesForInvoice } from "../../redux/actions/invoiceActions";
import { createBarcdoeInfos } from "../../redux/actions/BarcodeActions";
import { BARCODE_CREATE_RESET } from "../../redux/constants/BarcodeConstants";
import {
  getProductsList,
} from "../../redux/actions/CurrentStockReportActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  siInvoiceNo: "",
  product: {
    id: "",
    label: "",
  },
  
  packSize: "",
  packType: {
    id: "",
    label: "",
  },
  packNo: "",
  
};

const CreateBarcdoe = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  const [entryLimit, setEntryLimit] = useState(0);
  const [initialEntryLimit, setInitialEntryLimit] = useState(0);
 
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
  const [receivedQuantity, setReceivedQuantity] = useState("");

  const invoicePackTypeList = useSelector((state) => state.invoicePackTypeList);
  const {
    loading: loadingPackType,
    error: errorPackType,
    packTypes,
  } = invoicePackTypeList;

  const barcodeCreateState = useSelector(
    (state) => state.barcodeCreateState
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    barcode: createdBarcdoe,
  } = barcodeCreateState;

  const productsNameList = useSelector((state) => state.productNameList);
  const {
    loading: loadingProductsName,
    error: errorProductsName,
    productNameList,
  } = productsNameList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  // const formik = useFormik({
  //   initialValues,
  //   //validationSchema: barcodeSchema,
    
  //   onSubmit: (values, action) => {
      
  //     if (entryLimit > 0) {
  //       dispatch(
  //         createBarcdoeInfos({
  //           siInvoiceNo: values.siInvoiceNo,
  //           totalNoOfSku: parseInt(initialEntryLimit),
  //           productId: values.product.id,
  //           packTypeId: values.packType.id,
  //           packSize: values.packSize,
  //           packNo: values.packNo,
  //         })
  //       ).then(() => {
  //         if (checked) {
  //           setEntryLimit((prevLimit) => prevLimit - 1);
  //           if (entryLimit - 1 > 0) {
  //             action.resetForm({
  //               values: {
  //                 ...initialValues,
  //                 siInvoiceNo: values.siInvoiceNo,
  //                 entryLimit: entryLimit - 1,
  //               },
  //             });
  //           } else {
  //             formik.resetForm({
  //               values: {
  //                 ...initialValues,
  //                 siInvoiceNo: values.siInvoiceNo,
  //                 entryLimit: 0,
  //               },
  //             });
  //           }
  //         } else {
  //           history.push("/barcode/listprint");
  //         }
  //       });
  //     } else {
  //       setNotify({
  //         isOpen: true,
  //         message: "Entry limit reached",
  //         type: "error",
  //       });
  //     }
  //     setReceivedQuantity("");
  //   },
    
  // });


  const formik = useFormik({
    initialValues,
    validationSchema: barcodeSchema,
    onSubmit: async (values, action) => {
      try {
        if (entryLimit > 0) {
          const result = await dispatch(
            createBarcdoeInfos({
              siInvoiceNo: values.siInvoiceNo,
              totalNoOfSku: parseInt(initialEntryLimit),
              productId: values.product.id,
              packTypeId: values.packType.id,
              packSize: values.packSize,
              packNo: values.packNo,
            })
          );
  
          // Check if the server response contains an errMsg
          if (result?.errMsg) {
            // Prevent form submission
            action.setSubmitting(false);
            // Show server error message to the user
            setNotify({
              isOpen: true,
              message: result.errMsg,
              type: "error",
            });
          } else {
            // If no error, proceed with form actions
            if (checked) {
              setEntryLimit((prevLimit) => prevLimit - 1);
              if (entryLimit - 1 > 0) {
                action.resetForm({
                  values: {
                    ...initialValues,
                    siInvoiceNo: values.siInvoiceNo,
                    entryLimit: entryLimit - 1,
                  },
                });
              } else {
                action.resetForm({
                  values: {
                    ...initialValues,
                    //siInvoiceNo: values.siInvoiceNo,
                    entryLimit: 0,
                  },
                });
              }
            } else {
              history.push("/barcode/listprint");
            }
          }
        } else {
          // If entry limit reached, show error message
          setNotify({
            isOpen: true,
            message: "Entry limit reached",
            type: "error",
          });
        }
        // Reset receivedQuantity after form submission
        setReceivedQuantity("");
         await fetchTableData(values.siInvoiceNo);
      } catch (error) {
        // Handle any other errors that occur during form submission
        console.error("Error:", error);
        // Prevent form submission
        action.setSubmitting(false);
        // Show server error message to the user
        setNotify({
          isOpen: true,
          message: error.response?.data?.errMsg || "An error occurred",
          type: "error",
        });
      }
    },
  });

  useEffect(() => {
    // Set initialEntryLimit when entryLimit is set for the first time by the user
    setInitialEntryLimit(entryLimit);
  }, []);

  useEffect(() => {
    if (formik.values.packNo !== "" && receivedQuantity !== "") {
      const calculatedPackSize = receivedQuantity / formik.values.packNo;
      formik.setFieldValue("packSize", calculatedPackSize); // Assuming packSize is a decimal value
    }
  }, [receivedQuantity, formik.values.packNo]);


  useEffect(() => {
    // Fetch table data initially and on every form submission
    fetchTableData(formik.values.siInvoiceNo);
}, [formik.values.siInvoiceNo]); // Include formik.values.siInvoiceNo in the dependency array


const fetchTableData = async (invoiceNo) => {
  try {
    const { data } = await axios.get(
      `https://octopi.algologix.com.bd/api/StockIn/GetBarcodeInsertedSummaryInfo/${invoiceNo}`
    );

    // Ensure dataObj is an array before setting it to tableData
    if (Array.isArray(data.dataObj)) {
      setTableData(data.dataObj);
      //setTableData(data.dataObj.reverse());
    } else {
      setTableData([]); // Set tableData as empty array if dataObj is not an array
      
    }
  } catch (error) {
    console.error("Error fetching table data:", error);
    setNotify({
      isOpen: true,
      message: "Failed to fetch table data",
      type: "error",
    });
  }
};


  useEffect(() => {
    if (userInfo) {
      dispatch(listPackTypesForInvoice());
      dispatch(getProductsList());
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: BARCODE_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/barcode/listprint" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AddShoppingCartIcon />}
        title="STOCK IN BARCODE CREATE "
        subtitle="Create Barcode"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    label="Purchase Invoice Number"
                    name="siInvoiceNo"
                    error={formik.errors.siInvoiceNo}
                    touched={formik.touched.siInvoiceNo}
                    value={formik.values.siInvoiceNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Input
                    label="Entry Limit"
                    name="entryLimit"
                    type="number"
                    value={entryLimit}
                    onChange={(e) => {
                      setEntryLimit(parseInt(e.target.value)); // Update entryLimit state
                      setInitialEntryLimit(parseInt(e.target.value)); // Set initialEntryLimit after user input
                    }}
                    //onChange={(e) => setEntryLimit(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    disabled={loadingProductsName ? true : false}
                    id="combo-box-demo"
                    isOptionEqualToValue={useCallback(
                      (option, value) => option.value === value.value
                    )}
                    onChange={useCallback((e, v) => {
                      formik.setFieldValue("product", {
                        id: v?.id || "",
                        label: v?.label || "",
                      });
                    })}
                    value={formik.values.product}
                    options={productNameList !== undefined ? productNameList : []}
                    onOpen={formik.handleBlur}
                    renderInput={(params) => (
                      <Input
                        label="Product Name"
                        name="packType"
                        error={
                          formik.errors.product !== undefined
                            ? formik.errors.product.id
                            : ""
                        }
                        touched={
                          formik.touched.product !== undefined
                            ? formik.touched.product
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <Input
                    label="Received Quantity"
                    name="receivedQuantity"
                    type="number"
                    value={receivedQuantity}
                    onChange={(e) => setReceivedQuantity(e.target.value)}
                  />
                  <Input
                    label="Number of Pack"
                    name="packNo"
                    error={formik.errors.packNo}
                    touched={formik.touched.packNo}
                    value={formik.values.packNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Autocomplete
                    disablePortal
                    size="small"
                    disabled={loadingPackType ? true : false}
                    id="combo-box-demo"
                    isOptionEqualToValue={useCallback(
                      (option, value) => option.value === value.value
                    )}
                    onChange={useCallback((e, v) => {
                      formik.setFieldValue("packType", {
                        id: v?.id || "",
                        label: v?.label || "",
                      });
                    })}
                    value={formik.values.packType}
                    options={packTypes !== undefined ? packTypes : []}
                    onOpen={formik.handleBlur}
                    renderInput={(params) => (
                      <Input
                        label="Pack Type"
                        name="packType"
                        error={
                          formik.errors.packType !== undefined
                            ? formik.errors.packType.id
                            : ""
                        }
                        touched={
                          formik.touched.packType !== undefined
                            ? formik.touched.packType
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <Input
                    label="Pack Size"
                    name="packSize"
                    error={formik.errors.packSize}
                    touched={formik.touched.packSize}
                    value={formik.values.packSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Checkbox
                    label="Stay in this Page"
                    name="stay"
                    value={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ButtonGroup className={classes.submitButtonGroup}>
                    <MuiButton
                      endIcon={
                        loadingCreate ? (
                          <CircularProgress size="1rem" color="error" />
                        ) : (
                          <PublishIcon />
                        )
                      }
                      size="large"
                      type="submit"
                    >
                      Submit
                    </MuiButton>
                    <MuiButton
                      size="small"
                      startIcon={<ReplayIcon />}
                      onClick={() => {
                        formik.resetForm();
                        setChecked(true);
                        setEntryLimit(0);
                      }}
                    />
                  </ButtonGroup>
                  <Button
                    color="error"
                    text="Back"
                    onClick={() => {
                      const location = {
                        pathname: "/barcode/listprint",
                        state: {},
                      };
                      history.push(location);
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>

          <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Packs</TableCell>
                    <TableCell>Packs Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.productName}</TableCell>
                      <TableCell>{row.totalQuantity}</TableCell>
                      <TableCell>{row.barcodeCount}</TableCell>
                      <TableCell>{row.packTypeName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>
        </Grid>

        <div
          className="paperdesign2"
          style={{
            display: loadingProductsName ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
export default withRouter(CreateBarcdoe);

import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  FormHelperText,
} from "@mui/material";

const Select = (props) => {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    options,
    error = null,
    touched = null,
    ...other
  } = props;
  return (
    <FormControl
      variant={variant || "outlined"}
      {...(error && touched && { error: true })}
      {...other}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect label={label} name={name} value={value} onChange={onChange}>
        {options &&
          options.length > 0 &&
          options.map((item) => {
            return (
              <MenuItem id={item.id} key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            );
          })}
      </MuiSelect>
      {error && touched && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Select;

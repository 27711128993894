import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./View.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane } from "react-icons/bi";
import styled from "@emotion/styled";
import { useReactToPrint } from "react-to-print";

export default function SalesOrderStickerModal({ showModal, closeModal, item_info }) {
  const reportRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    
  });

  return (
    <div>
      <SalesOrderModal show={showModal} onHide={closeModal} size="lg" centered>
	      {/* ... Your existing modal content ... */}
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal heading</Modal.Title> */}
      </Modal.Header>
      <div id="invoiceCapture">
        <div className="capture-portion" ref={reportRef}>
          {item_info?.stockOutPackagingItems?.map((item) => (
            <div
              className="sticker-info"
              key={item?.sodProductId}
              style={{
                //height: "2in",
                //width: "4in",
                //margin: "10px",
                //marginLeft: '30px' ,
                //marginTop: '8px',
                //marginRight: '12px' ,
                //marginBottom: '1px',
                //position: "relative"
            
            }}
              
            >
            <div className="sales-order-sticker-base">                
                <div className="company-info" style={{ display: "flex", 
                justifyContent: "space-between", width: "100%",
                paddingLeft:"30px",
                paddingTop:"10px" }}>
                  <div className="logo" style={{ alignSelf: "flex-start" }}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/trade.png"}
                      alt=""
                      width="50"
                      height="20"
                    />

                </div>
                <div className="product-company-logo" style={{ alignSelf: "flex-end",paddingRight:"10px" }}>
                    <div className="image">
                      <img
                        src={process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"}
                        alt=""
                        width="80"
                        height="25"
                      />
                    </div>
                  </div>
                  
                </div>
                <div style={{ paddingTop: "5px",paddingLeft:"30px" }}>
                    <div className="company-info">
                      <p className="address" style={{ fontSize: "8px",fontWeight:"bold", margin: 0 }}>
                        Kunia, Targas, Gazipur
                      </p>
                      <p className="phone" style={{ fontSize: "8px",fontWeight:"bold", margin: 0 }}>
                        01792 527261, tradezentrumbd@gmail.com
                      </p>
                    </div>
                </div>

                <div className="product-info" style={{padding:"6px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <div className="info-data">
                    <div className="table-data">
                      <div className="info-tr">
                        <span className="info-td" style={{ fontSize: "10px" , fontWeight:"bold" }}>Product Name :</span>
                        <span className="info-td" style={{ fontSize: "10px"  , fontWeight:"bold", letterSpacing: "2px"}}>&nbsp;{item?.productName}</span>
                      </div>
                      <div className="info-tr">
                        <span className="info-td" style={{ fontSize: "10px" , fontWeight:"bold"}}>Weight :</span>
                        <span className="info-td" style={{ fontSize: "10px", fontWeight:"bold" }}>&nbsp;{item?.sopProductQty} KG</span>
                      </div>
                      {item_info?.jobType !== "Sample_Issue" && (
                        <div className="info-tr">
                          <span className="info-td" style={{ fontSize: "8px",fontWeight:"bold"  }}>Lot :</span>
                          <span className="info-td"></span>
                        </div>
                      )}
                      {item_info?.jobType !== "Sample_Issue" && (
                        <div className="info-tr">
                          <span className="info-td" style={{ fontSize: "8px",fontWeight:"bold"  }}>Date :</span>
                          <span className="info-td"></span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                
            </div>
             
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      paddingLeft: "20px",
                      paddingRight: "20px", // Adjust the right padding as needed
                      fontSize: "8px",
                      fontWeight: "bold",
                      margin: "20px 0" // Adjust the margin as needed
                    }}
                  >
                    <div className="date-and-company-name">
                      <p
                        className="date"
                        style={{
                          marginTop: item_info?.jobType === "Sample_Issue" && "10px",
                          fontSize: "10px",
                          margin: 0 // Remove default margin
                        }}
                      >
                        {/*{item_info?.invoiceDate}*/}
                      </p>
                      <div>
                        {item_info?.customerName ? (
                          <strong>{item_info.customerName}</strong>
                        ) : (
                          <strong>{item_info?.supplierName}</strong>
                        )}{" "}
                        <span className="invoice-number">
                          ({item_info?.soSystemNo})
                        </span>
                      </div>
                    </div>
                    <div style={{ fontSize: "6px" }}>
                      {new Date().toLocaleDateString(undefined, { day: '2-digit', month: 'short', year: 'numeric' })}
                    </div>
                  </div>



          </div>
          
            ))}
        </div>
      </div>

		
	    <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              <Button
                variant="primary"
               
                className="d-flex align-items-center justify-content-center w-100"
                
                onClick={handlePrint}
              >
                <BiPaperPlane
                  style={{ width: "15px", height: "15px", marginTop: "30px",display:"flex",alignItems:"flex-end" }}
                  className="me-2"
                />
                Print This Out
              </Button>
            </Col>
            <Col md={6}></Col>
          </Row>
        </div>
      </SalesOrderModal>
      <hr className="mt-4 mb-3" />
    </div>
  );
}

// Your existing styled component...

const SalesOrderModal = styled(Modal)`
  #invoiceCapture {
  }

  .sticker-info {
    display: flex;
    width: 402px;
    max-height: 218px;
    height: 218px;
    max-width: 402px;
    border: 1px solid #000;
    padding: 10px;
    flex-direction: column;
    row-gap: 4px;
  }

  .capture-portion {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    row-gap: 4;
    flex-direction: column;
    align-items: center;
  }

  .sales-order-sticker-base {
    display: flex;
    column-gap: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__left-portion {
    width: 180px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info
    p {
    margin: 0;
    font-size: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__right-portion {
    width: calc(100% - 150px);
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info {
    margin-left: -90px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table {
    width: 100%;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr {
    display: flex;
    column-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr
    .info-td {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table
    tr
    td {
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag
    .invoice-number {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo {
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .date {
    font-size: 8px;
    margin: 0;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .image {
    display: flex;
    align-items: center;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .invoice-number {
    margin: 0;
    font-size: 8px;
  }

  .date-and-company-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .date-and-company-name p {
    margin: 0;
    font-size: 12px;
  }

  .date-and-company-name .date {
    font-size: 6px !important;
    margin-top: 15px;
  }
`;

import React from "react";
import Decoder from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { signout, isAuthenticated } from "../auth";
import { logout } from "../redux/actions/userActions";
import "bootstrap/dist/css/bootstrap.min.css";
import Notification from "./Notification";
import Notifications from "./Notifications";

// const isActive = (history, path) => {
//   if (history.location.pathname === path) {
//     return {
//       color: "#C92EFF",
//       padding: "0.4rem",
//       border: "1px solid #C92EFF",
//       borderRadius: "10px",
//       // textDecoration: "underline",
//     };
//   } else {
//     return {};
//   }
// };

const listItems = [
  {
    UTC: "1408648665",
    list: [
      {
        type: "Message",
        content: "New Sales Order is escalated.",
        count: 3,
        timestamp: "1PM",
      },
    ],
  },
  {
    UTC: "1598103780",
    list: [
      {
        type: "Login",
        content: "New Sales Order is escalated.",
        count: 1,
      },
    ],
  },
  {
    UTC: "1595594400",
    list: [
      {
        type: "Login",
        content: "New Sales Order is escalated.",
        count: 4,
      },
    ],
  },
  {
    UTC: "1595575200",
    list: [
      {
        type: "Critical",
        content: "New Sales Order is escalated.",
        count: 3,
      },
    ],
  },
];

const MaterialAppBar = ({ location, history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // console.log(userInfo);

  const decodedata =
    userInfo !== null && userInfo !== undefined
      ? Decoder(userInfo.token)
      : null;
  // console.log("decodedata");
  // console.log(decodedata);
  let decodeMenuData =
    userInfo !== null && userInfo !== undefined
      ? Decoder(userInfo.menuToken)
      : null;
  // console.log("decodeMenuData");
  // console.log(decodeMenuData);
  decodeMenuData =
    decodeMenuData === null
      ? [{ MenuId: "-1", MenuName: "Dashboard", MenuURL: "/dashboard" }]
      : JSON.parse(decodeMenuData.UserMenus);
  // console.log("decodeMenuData");
  // console.log(decodeMenuData);
  // decodeMenuData = JSON.parse(decodeMenuData.UserMenus);
  // console.log(decodeMenuData);
  const logoutHandler = () => {
    history.push("/signin");
    dispatch(logout());
  };
  return (
    <nav
      className="navbar navbar-expand-lg sticky-top"
      style={{
        backgroundColor: "white",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFFilter: "blur(5px)",
        WebkitBackdropFilter: "blur(5px)",
        border: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img
            src={process.env.PUBLIC_URL + "/images/trade.png"}
            alt=""
            width="130"
            height="35"
            style={{ width: "85px", height: "60px" }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {userInfo
              ? decodeMenuData.map((menu) => {
                  if (menu.ChildMenus !== null) {
                    return (
                      <li className="nav-item dropdown" key={menu.MenuId}>
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {menu.MenuName}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {menu.ChildMenus.map((subChildMenu) => {
                            if (subChildMenu.MenuIsVisible) {
                              return (
                                <li key={subChildMenu.MenuId}>
                                  <Link
                                    className="dropdown-item"
                                    to={subChildMenu.MenuURL}
                                    // to={
                                    //   subChildMenu.MenuURL[0] === "/"
                                    //     ? subChildMenu.MenuURL
                                    //     : "/" + subChildMenu.MenuURL
                                    // }
                                  >
                                    {subChildMenu.MenuName}
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li className="nav-item" key={menu.MenuId}>
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to={
                            menu.MenuURL[0] === "/"
                              ? menu.MenuURL
                              : "/" + menu.MenuURL
                          }
                        >
                          {menu.MenuName}
                        </Link>
                      </li>
                    );
                  }
                })
              : ""}

            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Admin
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/inhouseemployees">
                    Employee Registration
                  </Link>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="/manageroleandmenu">
                    Manage Role and Menu
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Manage user access
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Work order internal
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Work order external
                  </a>
                </li>
                <li>
                  <Link className="dropdown-item" to="/price">
                    Price registration
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Invoice generate
                  </a>
                </li>
                <li>
                  <Link className="dropdown-item" to="/customers">
                    Customer Registration
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/products">
                    Product Registration
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Sales Call Activity Tracking
                  </a>
                </li>
              </ul>
            </li> */}
        {/*userInfo ? (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Report
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to={"/report/sales"}>
                        Sales Report
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to={"/report/payment"}>
                        Payment Report
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to={"/report/mapped"}>
                        Mapping Report
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/report/delivery-conveyance"}
                      >
                        Delivery Conveyance Report
                      </Link>
                    </li>
                  </ul>
                </li> 
              </>
            ) : null*/}

            <li className="nav-item dropdown">
              <Notifications listItems={listItems} />
            </li>
          </ul>
          <div className="d-flex">
            <div className="btn-group">
              <button
                className="btn btn-info btn-lg"
                style={{ color: "white", width: "200px" }}
                type="button"
              >
                ☰ {userInfo ? decodedata.UserFullName : ""}
              </button>
              <button
                type="button"
                className="btn btn-info dropdown-toggle dropdown-toggle-split"
                style={{ color: "white" }}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/changepassword">
                    Password Change
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#"
                    className="dropdown-item"
                    onClick={logoutHandler}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(MaterialAppBar);

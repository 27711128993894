// Import necessary dependencies from React, MUI, and Redux
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Circles } from "react-loader-spinner";

// Import components from the core folder and Redux actions
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import { listCustomers } from "../../redux/actions/customerActions";
import { listProducts } from "../../redux/actions/productActions";
import { getCurrentStock2 } from "../../redux/actions/CurrentStockReportActions";



// Define styles using makeStyles hook
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

// Functional component for CustomerList
const CustomerList = ({ match, history }) => {
  // Extracting customer ID from route parameters
  const customerId = match.params.id;
  const productId = match.params.id;
  
  const initialValues = {
    // startDate: [date.getFullYear(), mnth, day].join("-"),
    // endDate: [date.getFullYear(), mnth, day].join("-"),
    
    channelName: {
      id: "2",
      label: "",
    },
  };
  
  const columns = [
    {
      Header: "Id",
      accessor: "currentStockAutoId",
    },
    {
      accessor: "currentQty",
      Header: "CURRENT QUANTITY",
    },]
  // Redux setup
  const dispatch = useDispatch();

  // Local state for managing customer data and selection
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  


  // Styling setup
  const classes = useStyles();

  // Selecting customer data from Redux store
  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomers,
    error: errorCustomers,
    customers: allCustomers,
  } = customerList;

//
  const productList = useSelector((state) => state.productList);
  const{
    loading: loadingProducts,
    error: errorProducts,
    products: allProducts,

  } = productList;
//


  // Fetching customer list from Redux on component mount
  useEffect(() => {
    dispatch(listCustomers());
  }, [dispatch]);

  // Fetching product name from Redux on component mount
  useEffect(() =>{
    dispatch(listProducts());
  }, [dispatch] );

  useEffect(() =>{
    dispatch(getCurrentStock2());
  }, [dispatch] );


 

  // Updating local state when customer data changes
  useEffect(() => {
    if (allCustomers) {
      setCustomers(allCustomers);
    }
  }, [allCustomers]);

    // Updating local state when product data changes
    useEffect(()=>{
      if(allProducts){
        setProducts(allProducts);
      }

    }, [allProducts]);

  // Handling customer selection from Autocomplete
  const onCustomerSelect = (event, value) => {
    setSelectedCustomer(value);
  };

  const onProductSelect = (event, value) => {
    setSelectedProduct(value);
    if (value) {
      dispatch(getCurrentStock2());
    }
  };
  // Function to render customer details based on selection
  const renderCustomerDetails = () => {
    // Implement your logic to render customer details based on selectedCustomer
    if (selectedCustomer) {
      return (
        <div>
          {/* Render customer details here */}
          <p><b>Customer Id:</b> {selectedCustomer.customerId}</p>
          <p><b>Customer Name:</b> {selectedCustomer.customerName}</p>
          <p><b>Contact Number:</b> {selectedCustomer.contactNumber}</p>
          <p><b>Customer Address:</b> {selectedCustomer.customerAddress}</p>
          <p><b>Belongs To:</b> <span style={{ fontWeight: 'bold', color: 'red' }}>{selectedCustomer.empFirstName} {selectedCustomer.empLastName}</span></p>

          {/* Add more details as needed */}
        </div>
      );
    }
    return null;
  };

    // Function to render products details based on selection
    const renderProductDetails = () => {
      // Implement your logic to render customer details based on selectedCustomer
      if (selectedProduct) {
        
        const fixedChannel = 2;
        
        return (
          <div>
            {/* Render customer details here */}
            
            <p><b>Product Id:</b> {selectedProduct.productId}</p>
            <p><b>Product Name:</b> {selectedProduct.productName}</p>
            <p><b>Product Category:</b> {selectedProduct.productCategoryName}</p>
            <p><b>Product Name:</b> {selectedProduct.productName}</p>
            <p><b>Function name:</b> {selectedProduct.function}</p>
            <p><b>Total Quantity:</b> {selectedProduct.currentQty}</p>
        
           
            {/* Add more details as needed */}
          </div>
        );
      }
      return null;
    };
  
    return (
      <>
        {/* Menu and PageHeader components */}
        <Menu />
        <PageHeader icon={<PersonAddIcon />} title="Customers" subtitle="Edit Customer's Data" />
        
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', height: 'auto', minHeight: '300px' }}>
          {/* Product Selection Section */}
          <div style={{ flex: 1, marginRight: '10px' }}>
            <Paper className={`${classes.pageContent} paperdesign1`}>
              <Form>
                <Autocomplete
                  id="product-dropdown"
                  options={products}
                  getOptionLabel={(option) => option.productName || ""}
                  onChange={onProductSelect}
                  renderInput={(params) => (
                    <Input label="Select Product" {...params} onBlur={params.onBlur} />
                  )}
                />
                {selectedProduct && (
                  <div>
                    <p><b>Product Id:</b> {selectedProduct.productId}</p>
                    <p><b>Product Name:</b> {selectedProduct.productName}</p>
                    <p><b>Product Category:</b> {selectedProduct.productCategoryName}</p>
                    <p><b>Function Name:</b> {selectedProduct.function}</p>
                    <p><b>Current Quantity:</b> {selectedProduct.currentQty}</p>
                    {/* Add more product details as needed */}
                  </div>
                )}
              </Form>
            </Paper>
          </div>
    
          {/* Customer Selection Section */}
          <div style={{ flex: 1 }}>
            <Paper className={`${classes.pageContent} paperdesign1`}>
              <Form>
                <Autocomplete
                  id="customer-dropdown"
                  options={customers}
                  getOptionLabel={(option) => option.customerName || ""}
                  onChange={onCustomerSelect}
                  renderInput={(params) => (
                    <Input label="Select Customer" {...params} onBlur={params.onBlur} />
                  )}
                />
                {selectedCustomer && (
                  <div>
                    <p><b>Customer Id:</b> {selectedCustomer.customerId}</p>
                    <p><b>Customer Name:</b> {selectedCustomer.customerName}</p>
                    <p><b>Contact Number:</b> {selectedCustomer.contactNumber}</p>
                    <p><b>Customer Address:</b> {selectedCustomer.customerAddress}</p>
                    <p><b>Belongs To:</b> <span style={{ fontWeight: 'bold', color: 'red' }}>{selectedCustomer.empFirstName} {selectedCustomer.empLastName}</span></p>

                    {/* Add more details as needed */}
                  </div>
                )}
              </Form>
            </Paper>
          </div>
        </div>
    
        {/* Loading spinner component */}
        <div className="paperdesign2" style={{ display: loadingCustomers ? "" : "none" }}>
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              visible={true}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ color: "white" }}>Please wait</p>
          </div>
        </div>
      </>
    );


};

// Export the component using withRouter for access to match and history props
export default withRouter(CustomerList);
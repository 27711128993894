import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  GET_PENDING_AND_CANCEL_REQUEST,
  GET_PENDING_AND_CANCEL_SUCCESS,
  GET_PENDING_AND_CANCEL_FAIL,
} from "../constants/PendingAndCancelReportConstants";

export const listPendingAndCancelReport =
  (datas) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_PENDING_AND_CANCEL_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/Report/GetPendingAndCancelReport?status=1`,
        // url: `${API_URL}/Stock/GetCurrentStock/${productId}/${channelId}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      console.log("data actions");
      console.log(data.dataObj);
      dispatch({ type: GET_PENDING_AND_CANCEL_SUCCESS, payload: data.dataObj });
    } catch (error) {
      dispatch({
        type: GET_PENDING_AND_CANCEL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

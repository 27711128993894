import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash, BiPlusCircle } from "react-icons/bi";
import EditableField from "./EditableField";
import EditableProductField from "./EditableProductField";
import { Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useCallback } from "react";
import { Autocomplete, Chip } from "@mui/material";
import Input from "./controls/Input";
import Select from "react-select";
import "react-bootstrap-typeahead/css/Typeahead.css";

import EditProductDropdownField from "./controls/EditProductDropdownField";
import EditProductDropdownFieldMultiple from "./controls/EditProductDropdownFieldMultiple";

const CheckListItem = (props) => {
  // update = (nextState) => {
  //   this.setState(nextState);
  // };
  // increment() {
  //   this.setState({ count: this.state.count + 1 });
  // }

  // const { switchMappingOption } = this.state;
  // var setSwitchMappingOption = this.setState;
  const [dataFilteredTask, setDataFilteredTask] = useState();
  var onItemizedItemCheckListComplete = props.onItemizedItemCheckListComplete;
  var taskSingle = props.taskSingle;
  var onItemizedItemViewForBatches = props.onItemizedItemViewForBatches;
  var onItemizedItemAddForBatches = props.onItemizedItemAddForBatches;
  var onItemizedItemAddForMaps = props.onItemizedItemAddForMaps;
  var onItemizedItemViewForMaps = props.onItemizedItemViewForMaps;
  var onItemizedBatchEdit = props.onItemizedBatchEdit;
  var onItemizedItemEdit = props.onItemizedItemEdit;
  var onItemizedBatchAndMapEdit = props.onItemizedBatchAndMapEdit;
  var currency = props.currency;
  var rowDel = props.onRowDel;
  var batchDel = props.onBatchDel;
  var packDel = props.onPackDel;
  var itemTable =
    dataFilteredTask !== undefined &&
    dataFilteredTask.taskChecklistsData.length > 0 &&
    dataFilteredTask.taskChecklistsData.map(function (item, i) {
      console.log("itemssss");
      console.log(taskSingle);
      return (
        <ItemRow2
          onItemizedItemCheckListComplete={onItemizedItemCheckListComplete}
          onItemizedItemViewForBatches={onItemizedItemViewForBatches}
          onItemizedItemAddForBatches={onItemizedItemAddForBatches}
          onItemizedItemAddForMaps={onItemizedItemAddForMaps}
          onItemizedItemViewForMaps={onItemizedItemViewForMaps}
          onItemizedBatchEdit={onItemizedBatchEdit}
          onItemizedItemEdit={onItemizedItemEdit}
          onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
          item={item}
          taskId={props.taskKey}
          i={i + 1}
          onDelEvent={rowDel.bind(this)}
          onBatchDelEvent={batchDel.bind(this)}
          onPackDelEvent={packDel.bind(this)}
          key={item.taskChecklistAutoId}
          dataFilteredTask={dataFilteredTask}
          currency={currency}
        />
      );
    });

  const changeValues = () => {
    // formik.setFieldValue("productId", productId);
    // console.log("props.key");
    // console.log(props.taskKey);
    if (props.data !== undefined && props.taskKey !== undefined) {
      let filteredTask = props.data.items.filter((singleData) => {
        // console.log("singleData.taskId === props.taskKey");
        // console.log(String(singleData.taskId) === String(props.taskKey));
        return String(singleData.taskId) === String(props.taskKey);
      });
      // console.log("props.data.items");
      // console.log(props.data.items);
      // console.log("filteredTask");
      // console.log(filteredTask);
      setDataFilteredTask(filteredTask[0]);
    }
  };

  useEffect(() => {
    changeValues();
  }, [props.data, props.taskKey]);
  return (
    <div style={{ position: "relative !important" }}>
      <p style={{ margin: 0 }}>
        <span style={{ fontSize: "1rem", fontWeight: "bolder" }}>
          Invoice:{" "}
        </span>
        <span>
          {taskSingle.soSystemNo !== null
            ? taskSingle.soSystemNo
            : "not provided"}
        </span>
      </p>
      <p style={{ margin: 0 }}>
        <span style={{ fontSize: "1rem", fontWeight: "bolder" }}>
          Destination Name:{" "}
        </span>
        <span>
          {taskSingle.taskDestination !== null
            ? taskSingle.taskDestination
            : "not provided"}
        </span>
      </p>
      <p style={{ marginBottom: "0.5rem" }}>
        <span style={{ fontSize: "1rem", fontWeight: "bolder" }}>
          Destination Address:{" "}
        </span>
        <span>
          {taskSingle.taskDestinationAddress !== null
            ? taskSingle.taskDestinationAddress
            : "not provided"}
        </span>
      </p>
      <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
        <Table className="table table-sm" bordered>
          <thead className="thead-dark">
            <tr>
              <th className="text-center">Serial no</th>
              <th className="text-center">Check List Name</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>{itemTable}</tbody>
        </Table>
      </div>
    </div>
  );
};

const ItemRow2 = (props) => {
  var myRef = React.useRef(null);
  const onDelEvent = () => {
    props.onDelEvent(props.item);
  };
  //   const [switchMappingOption, setSwitchMappingOption] = useState(false);

  //   const onDelEvent = () => {
  //     props.onDelEvent(props.item);
  //   };

  //   useEffect(() => {
  //     if (props?.item?.mappedProductInfo?.id !== "") {
  //       setSwitchMappingOption(true);
  //     }
  //   }, [props.item.mappedProductInfo]);

  // console.log("props.dataFilteredTask");
  // console.log(props.dataFilteredTask);

  // console.log("props.dataFilteredTask.taskStartTime");
  // console.log(props.dataFilteredTask.taskStartTime);

  // console.log("props.dataFilteredTask.taskEndTime");
  // console.log(props.dataFilteredTask.taskEndTime);

  return (
    <tr
      ref={myRef}
      style={{
        opacity: props.item.checklistCompletionStatus === 1 ? 0.4 : 1,
      }}
    >
      <td className="text-center">{props.i}</td>
      <td className="text-center">{props.item.taskChecklistName}</td>
      <td className="text-center">
        {" "}
        {/* Asif
        <Form.Select
          aria-label="Default select example"
          size="sm"
          value={props.item.checklistCompletionStatus}
          style={{
            opacity:
              props.dataFilteredTask.taskStartTime !== null &&
              props.dataFilteredTask.taskEndTime !== null &&
              (props.item.checklistCompletionStatus === 1 ||
                props.item.checklistCompletionStatus === 0)
                ? 0.4
                : 1,
            cursor:
              props.dataFilteredTask.taskStartTime !== null &&
              props.dataFilteredTask.taskEndTime !== null &&
              (props.item.checklistCompletionStatus === 1 ||
                props.item.checklistCompletionStatus === 0)
                ? "not-allowed"
                : "pointer",
          }}
          disabled={
            props.dataFilteredTask.taskStartTime !== null &&
            props.dataFilteredTask.taskEndTime !== null &&
            (props.item.checklistCompletionStatus === 1 ||
              props.item.checklistCompletionStatus === 0)
              ? true
              : false
          }
          onChange={(e) => {
            if (String(e.target.value) === String(1)) {
              props.onItemizedItemCheckListComplete({
                id: props.taskId,
                checkListId: props.item.taskChecklistAutoId,
              });
            }
          }}
        >
          <option value="0">Pending</option>
          <option value="1">Completed</option>
        </Form.Select>
        */}

          <Form.Select
            aria-label="Default select example"
            size="sm"
            value={props.item.checklistCompletionStatus}
            style={{
              opacity:
                props.dataFilteredTask.taskStartTime !== null &&
                props.dataFilteredTask.taskEndTime !== null &&
                (props.item.checklistCompletionStatus === 1 ||
                  props.item.checklistCompletionStatus === 0)
                  ? 0.4
                  : 1,
              cursor:
                props.dataFilteredTask.taskStartTime !== null &&
                props.dataFilteredTask.taskEndTime !== null &&
                (props.item.checklistCompletionStatus === 1 ||
                  props.item.checklistCompletionStatus === 0)
                  ? "not-allowed"
                  : "pointer",
            }}
            disabled={
              props.dataFilteredTask.taskStartTime !== null &&
              props.dataFilteredTask.taskEndTime !== null &&
              (props.item.checklistCompletionStatus === 1 ||
                props.item.checklistCompletionStatus === 0)
                ? true
                : false
            }
            onChange={(e) => {
              if (String(e.target.value) === String(1)) {
                props.onItemizedItemCheckListComplete({
                  id: props.taskId,
                  checkListId: props.item.taskChecklistAutoId,
                });
              }
            }}
          >
            <option value="0">Pending</option>
            <option value="1">Completed</option>
          
          </Form.Select>

      </td>
    </tr>
  );
};
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      switchMappingOption: false,
    };
  }
  onDelEvent() {
    this.props.onDelEvent(this.props.item);
  }

  render() {
    const { switchMappingOption } = this.state;

    return (
      <tr ref={this.myRef}>
        <td style={{ width: "200px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "text",
              name: "productName",
              placeholder: "Item name",
              value: this.props.item.productName,
              id: this.props.item.productName,
            }}
            style={{ width: "200px" }}
            disabled
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              type: "number",
              name: "quantity",
              textAlign: "center",
              step: "1",
              value: this.props.item.quantity,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.productId,
            }}
            style={{ width: "100px" }}
            disabled={this.props.escalate}
          />
        </td>
        {/* <td style={{ minWidth: "130px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "unitPrice",
              min: 1,
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.unitPrice,
              id: this.props.item.unitPrice,
            }}
            disabled={this.props.escalate}
          />
        </td> */}
        <td style={{ minWidth: "150px" }}>
          <EditableProductField
            itemizeditemedit={this.props.onItemizedItemEdit}
            celldata={{
              leading: this.props.currency,
              type: "number",
              name: "lineTotal",
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: this.props.item.lineTotal,
              id: this.props.item.lineTotal,
            }}
            disabled
          />
        </td>
        {/* <td></td> */}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <>
            <td style={{ minWidth: "auto", display: "flex" }}>
              <div>
                <InputGroup.Text id="basic-addon1">
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label=""
                    className=""
                    checked={this.props.switchMappingOption}
                    onChange={(e) => {
                      this.setState({
                        switchMappingOption: !switchMappingOption,
                      });
                    }}
                  />
                </InputGroup.Text>
              </div>
              <div style={{ marginLeft: "9px" }}>
                {switchMappingOption ? (
                  <Form.Group
                    controlId="form-productId"
                    style={{ minWidth: "250px" }}
                  >
                    <EditProductDropdownField
                      name="sodMappedProductId"
                      itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                      info={this.props.item.mappedProductInfo}
                      item={this.props.item.mappedItemList}
                      allItem={this.props.item}
                      placeholder="Map with a product"
                      // disabled
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
              </div>
            </td>
            <td style={{ minWidth: "250px" }}>
              <Form.Group controlId="form-productId">
                <EditProductDropdownField
                  name="sodRealatedBatchId"
                  itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
                  info={this.props.item.batchInfo}
                  item={this.props.item.batchList}
                  allItem={this.props.item}
                  placeholder="Batch #"
                  // disabled
                />
              </Form.Group>
            </td>
          </>
        ) : (
          ""
        )}
        {this.props.escalate === false && this.props.allOptionAuto ? (
          <></>
        ) : (
          <td className="text-center" style={{ minWidth: "50px" }}>
            <BiTrash
              onClick={this.onDelEvent.bind(this)}
              style={{ height: "33px", width: "33px", padding: "7.5px" }}
              className="text-white mt-1 btn btn-danger"
            />
          </td>
        )}
      </tr>
    );
  }
}

export default CheckListItem;

import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MailIcon from "@mui/icons-material/Mail";
import { Form } from "react-bootstrap";
import "./View.css";

function GenerateInvoice(soSystemNo) {
  html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    const imgData = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: [612, 792],
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`invoice-${soSystemNo}.pdf`);
  });
}

function GenerateInvoice2() {
  // var pdf = new jsPDF('p','pt','a4');

  var pdf = new jsPDF("p", "pt", "a4");

  var imgData =
    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAXwBfAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgIyIoLTkwKCo2KyIjMkQyNjs9QEBAJjBGS0U+Sjk/QD3/2wBDAQsLCw8NDx0QEB09KSMpPT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT3/wgARCAAaABQDAREAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAABQYAAwQB/8QAGAEBAQEBAQAAAAAAAAAAAAAAAwEAAgT/2gAMAwEAAhADEAAAAXKbOK1c92KOHzuQcxaHNjdidpy5yl//xAAfEAACAQMFAQAAAAAAAAAAAAABAgADEhMEEBEhIjH/2gAIAQEAAQUC+QuVq6duEqnoephWKDia/FLjLjt//8QAHREAAgIBBQAAAAAAAAAAAAAAAAIBEQMSEyAiMf/aAAgBAwEBPwEhIZLj2DOttcCkNp7G8xZfH//EAB4RAAIDAAEFAAAAAAAAAAAAAAABAgMREiAhIjFR/9oACAECAQE/AR2ONmS9MolkcZZ8aHDl4b2FTEaEun//xAAhEAABAwMEAwAAAAAAAAAAAAABAAIRAxAxEjJBQiFhYv/aAAgBAQAGPwJQ7acIg8FQWFzfS0B0t+shcpkNqHx1KqahU29rZKybf//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQVFhgZH/2gAIAQEAAT8hUFrUE1U6+ZZvXITcrvpNdp4xEO+l1b7Gv7BQdYMALdXDkpwD7ipT+kOT/9oADAMBAAIAAwAAABBnmCSOz//EABsRAQACAwEBAAAAAAAAAAAAAAEAESExYSBx/9oACAEDAQE/EAXUQdz5KIsIMuNjTLWFPNMVwaOQoRsVXn//xAAcEQEAAgIDAQAAAAAAAAAAAAABABEhMSBhcVH/2gAIAQIBAT8QUMsIdQ9/JZNpSUTIImK3bZ5AbtfZa3cpbvj/AP/EABwQAQACAwEBAQAAAAAAAAAAAAEAESExQXFRwf/aAAgBAQABPxCsIatahd4Y+dDAb93fjD4HtO4qLlXU0ej2pdETsO11xEdV8cP2hExkSA2d3NHkA0Q0CIxSEyKmjyf/2Q==";
  pdf.addImage(imgData, "JPEG", 20, 20, 20, 26);

  pdf.text(50, 40, "Header");

  pdf.addHTML(document.body, 40, 100, function () {
    pdf.save("web.pdf");
  });
}

const PaymentHistoryView = (props) => {
  // constructor(props) {
  //   super(props);
  // }
  // useEffect(() => {
  //   document.body.style.zoom = "100%";
  // }, []);
  
  //console.log("props");//shahed
  //console.log(props);//shahed

  const getBatchWithoutRemainingDays = (date) => {};

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    //console.log("d");//shahed
    //console.log(d);//shahed
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const getOldPrice = (price, vat, ait, discount) => {
    var lineTotalVat = price * (vat / 100);
    var lineTotalAit = price * (ait / 100);
    //console.log("price");
    //console.log(price);
    //console.log("lineTotal");
    //console.log(lineTotalVat + lineTotalAit + discount);
    var final = price - (lineTotalVat + lineTotalAit + discount);
    return final;
  };

  const getVatAmount = (price, vat) => {
    var lineTotalVat = price * (vat / 100);

    return lineTotalVat.toFixed(2);
  };

  const getAitAmount = (price, ait) => {
    var lineTotalAit = price * (ait / 100);
    return lineTotalAit.toFixed(2);
  };

  const getTotalPrice = (arr, vat, ait, discount, adjustment) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodFinalCalculatedUnitPrice;
    });
    sum =
      parseFloat(sum) +
      parseFloat(vat) +
      parseFloat(ait) -
      (parseFloat(discount) + parseFloat(adjustment));
    //console.log("sum");
    //console.log(sum);
    return sum.toFixed(2);
  };

  const getTotalQuantity = (arr) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodQty;
    });

    return parseFloat(sum).toFixed(2);
  };

  //console.log("props.info");
  //console.log(props.info);
  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <div
          id="invoiceCapture"
          className=" pt-1 "
          style={{ position: "relative" }}
        >
          <div
            className="p-4"
            style={{
              paddingTop: "0.5rem !important",
            }}
          >
            <Row>
              <h4>Payment History:</h4>
            </Row>
            <div
              style={{
                width: "auto",
                overflowX: "scroll",
              }}
              className="row-scrollers"
            >
              <Table className="mb-0" style={{ border: "1px solid #e3e3e3" }}>
                <thead>
                  <tr>
                    <th className="text-center">Customer Name</th>
                    <th className="text-center">Final Bill</th>
                    <th className="text-center">Payment Amount</th>
                    <th className="text-center">Payment Date</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.info.map((item, i) => {
                    return (
                      <tr id={i} key={i} style={{ height: "20px" }}>
                        <td className="text-center">{item.customerName}</td>

                        <td className="text-center">{item.sobFinalBill}</td>
                        <td className="text-center">{item.paymentAmount}</td>
                        <td className="text-center">{item.paymentDate}</td>
                      </tr>
                    );
                  })}
                  {/* <tr style={{ height: "20px" }}>
                    <td className="text-center">{props.info.customerName}</td>
                    <td className="text-center">{props.info.sobFinalBill}</td>
                    <td className="text-center">{props.info.paymentAmount}</td>
                    <td className="text-center">{props.info.paymentDate}</td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="p-4 " style={{ display: "none" }}>
            <Row className="mb-4 ">
              <Col md={3} lg={3}>
                <div
                  className="fw-bold text-center w-55 h-55 p-1"
                  style={{ height: "120px", border: "1px solid #e3e3e3 " }}
                >
                  Authorized By:
                </div>
                {/* <div>{props.info.billTo || "Hello Uttara"}</div>
                  <div>{props.info.billToAddress || ""}</div>
                  <div>{props.info.billToEmail || ""}</div> */}
              </Col>
              <Col md={3} lg={3}>
                <div
                  className="fw-bold text-center w-55 h-55 p-1"
                  style={{ height: "120px", border: "1px solid #e3e3e3 " }}
                >
                  Prepared By:
                </div>
                {/* <div>{props.info.billTo || "Hello Uttara"}</div>
                  <div>{props.info.billToAddress || ""}</div>
                  <div>{props.info.billToEmail || ""}</div> */}
              </Col>

              <Col md={3} lg={3}>
                <div
                  className="fw-bold text-center w-55 h-55 p-1"
                  style={{ height: "120px", border: "1px solid #e3e3e3 " }}
                >
                  Mode of Transport:
                </div>
                {/* <div>{props.info.billTo || "Hello Uttara"}</div>
                  <div>{props.info.billToAddress || ""}</div>
                  <div>{props.info.billToEmail || ""}</div> */}
              </Col>

              <Col md={3} lg={3}>
                <div
                  className="fw-bold text-center w-55 h-55 p-1"
                  style={{ height: "120px", border: "1px solid #e3e3e3 " }}
                >
                  Received By:
                </div>
                {/* <div>{props.info.billTo || "Hello Uttara"}</div>
                  <div>{props.info.billToAddress || ""}</div>
                  <div>{props.info.billToEmail || ""}</div> */}
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <hr className="mt-4 mb-3" />
    </div>
  );
};

export default PaymentHistoryView;

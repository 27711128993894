export const ACTIVITY_LIST_REQUEST = "ACTIVITY_LIST_REQUEST";
export const ACTIVITY_LIST_SUCCESS = "ACTIVITY_LIST_SUCCESS";
export const ACTIVITY_LIST_FAIL = "ACTIVITY_LIST_FAIL";

export const ACTIVITYDUPLI_LIST_REQUEST = "ACTIVITYDUPLI_LIST_REQUEST";
export const ACTIVITYDUPLI_LIST_SUCCESS = "ACTIVITYDUPLI_LIST_SUCCESS";
export const ACTIVITYDUPLI_LIST_FAIL = "ACTIVITYDUPLI_LIST_FAIL";

export const DONE_ACTIVITY_LIST_REQUEST = "DONE_ACTIVITY_LIST_REQUEST";
export const DONE_ACTIVITY_LIST_SUCCESS = "DONE_ACTIVITY_LIST_SUCCESS";
export const DONE_ACTIVITY_LIST_FAIL = "DONE_ACTIVITY_LIST_FAIL";

export const DONE_ACTIVITY_DELETE_REQUEST = "DONE_ACTIVITY_DELETE_REQUEST";
export const DONE_ACTIVITY_DELETE_SUCCESS = "DONE_ACTIVITY_DELETE_SUCCESS";
export const DONE_ACTIVITY_DELETE_FAIL = "DONE_ACTIVITY_DELETE_FAIL";
export const DONE_ACTIVITY_DELETE_RESET = "DONE_ACTIVITY_DELETE_RESET";

export const DONE_ACTIVITY_DETAILS_REQUEST = "DONE_ACTIVITY_DETAILS_REQUEST";
export const DONE_ACTIVITY_DETAILS_SUCCESS = "DONE_ACTIVITY_DETAILS_SUCCESS";
export const DONE_ACTIVITY_DETAILS_FAIL = "DONE_ACTIVITY_DETAILS_FAIL";
export const DONE_ACTIVITY_DETAILS_RESET = "DONE_ACTIVITY_DETAILS_RESET";

export const DONE_ACTIVITY_UPDATE_REQUEST = "DONE_ACTIVITY_UPDATE_REQUEST";
export const DONE_ACTIVITY_UPDATE_SUCCESS = "DONE_ACTIVITY_UPDATE_SUCCESS";
export const DONE_ACTIVITY_UPDATE_FAIL = "DONE_ACTIVITY_UPDATE_FAIL";
export const DONE_ACTIVITY_UPDATE_RESET = "DONE_ACTIVITY_UPDATE_RESET";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const MAPPED_PRODUCT_LIST_REQUEST = "MAPPED_PRODUCT_LIST_REQUEST";
export const MAPPED_PRODUCT_LIST_SUCCESS = "MAPPED_PRODUCT_LIST_SUCCESS";
export const MAPPED_PRODUCT_LIST_FAIL = "MAPPED_PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET";

export const ACTIVITY_CREATE_REQUEST = "ACTIVITY_CREATE_REQUEST";
export const ACTIVITY_CREATE_SUCCESS = "ACTIVITY_CREATE_SUCCESS";
export const ACTIVITY_CREATE_FAIL = "ACTIVITY_CREATE_FAIL";
export const ACTIVITY_CREATE_RESET = "ACTIVITY_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_PRICE_BY_ID_REQUEST = "PRODUCT_PRICE_BY_ID_REQUEST";
export const PRODUCT_PRICE_BY_ID_SUCCESS = "PRODUCT_PRICE_BY_ID_SUCCESS";
export const PRODUCT_PRICE_BY_ID_FAIL = "PRODUCT_PRICE_BY_ID_FAIL";
export const PRODUCT_PRICE_BY_ID_RESET = "PRODUCT_PRICE_BY_ID_RESET";

export const PRODUCT_PRICE_CREATE_REQUEST = "PRODUCT_PRICE_CREATE_REQUEST";
export const PRODUCT_PRICE_CREATE_SUCCESS = "PRODUCT_PRICE_CREATE_SUCCESS";
export const PRODUCT_PRICE_CREATE_FAIL = "PRODUCT_PRICE_CREATE_FAIL";
export const PRODUCT_PRICE_CREATE_RESET = "PRODUCT_PRICE_CREATE_RESET";

export const PRODUCT_PRICE_LIST_REQUEST = "PRODUCT_PRICE_LIST_REQUEST";
export const PRODUCT_PRICE_LIST_SUCCESS = "PRODUCT_PRICE_LIST_SUCCESS";
export const PRODUCT_PRICE_LIST_FAIL = "PRODUCT_PRICE_LIST_FAIL";

export const PRODUCT_PRODUCTS_TOP_REQUEST = "PRODUCT_PRODUCTS_TOP_REQUEST";
export const PRODUCT_PRODUCTS_TOP_SUCCESS = "PRODUCT_PRODUCTS_TOP_SUCCESS";
export const PRODUCT_PRODUCTS_TOP_FAIL = "PRODUCT_PRODUCTS_TOP_FAIL";

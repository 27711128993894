import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";
import { Form as Form1 } from "react-bootstrap";

import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateBatch } from "../../redux/actions/BatchActions";
import { useDispatch } from "react-redux";
import { listPackTypesForInvoice } from "../../redux/actions/invoiceActions";
import { Typeahead } from "react-bootstrap-typeahead";

const statusItems = [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
  { id: "3", title: "Pause" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  productId: "",
  packSize: "",
  packQuantity: "",
  packRemarks: "",
  pack: {
    id: "",
    label: "",
  },
};

const AddMapForStockin = ({
  history,
  data,
  setData,
  openPopup,
  setOpenPopup,
  productId,
}) => {
  const dispatch = useDispatch();
  const batchUpdate = useSelector((state) => state.batchUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = batchUpdate;

  const invoicePackTypeList = useSelector((state) => state.invoicePackTypeList);
  const {
    loading: loadingPackType,
    error: errorPackType,
    packTypes,
  } = invoicePackTypeList;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [selectedPackType, setSelectedPackType] = useState([]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      // console.log("hello");
      // console.log(data);
      const filteredProduct = data.items.filter((singleData) => {
        return singleData.productId === values.productId;
      });
      console.log("filteredProduct");
      console.log(filteredProduct);
      let sidPacks = filteredProduct[0].sidPacks;
      if (sidPacks.length > 0) {
        sidPacks.push({
          sipPackTypeId: values.pack.id,
          sipPackSize: values.packSize,
          sipPackQty: values.packQuantity,
          sipPackRemarks: values.packRemarks,
          packTypeName: values.pack.label,
        });
      } else {
        sidPacks.push({
          sipPackTypeId: values.pack.id,
          sipPackSize: values.packSize,
          sipPackQty: values.packQuantity,
          sipPackRemarks: values.packRemarks,
          packTypeName: values.pack.label,
        });
        // console.log("hello");
        // console.log(values);
      }
      console.log("hello");
      console.log(sidPacks);

      var items = data.items.slice();
      var newItems = items.map(function (sitems) {
        // console.log("sitems");
        // console.log(sitems);
        for (var key in sitems) {
          if (sitems.productId === values.productId) {
            // console.log("Hello");
            sitems["sidPacks"] = sidPacks;
          }
        }
        return sitems;
      });

      console.log("newItems");
      console.log(newItems);

      setData({
        ...data,
        items: newItems,
      });
      setOpenPopup({
        ...openPopup,
        isOpen: false,
      });
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listPackTypesForInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/addcustomer" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  const changeValues = () => {
    formik.setFieldValue("productId", productId);
  };

  const packChange = useCallback((e, v) => {
    formik.setFieldValue("pack", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  useEffect(() => {
    changeValues();
  }, [data, productId]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Autocomplete
            // disablePortal
            size="small"
            disabled={loadingPackType ? true : false}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={packChange}
            value={formik.values.pack}
            options={packTypes !== undefined ? packTypes : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="Pack Type"
                name="pack"
                error={
                  formik.errors.pack !== undefined ? formik.errors.pack.id : ""
                }
                touched={
                  formik.touched.pack !== undefined ? formik.touched.pack : ""
                }
                {...params}
                onBlur={formik.handleBlur}
              />
            )}
          />
          <Input
            label="Pack Size"
            name="packSize"
            error={formik.errors.packSize}
            touched={formik.touched.packSize}
            value={formik.values.packSize}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Pack Quantity"
            name="packQuantity"
            error={formik.errors.packQuantity}
            touched={formik.touched.packQuantity}
            value={formik.values.packQuantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Remarks"
            name="packRemarks"
            multiline
            error={formik.errors.packRemarks}
            touched={formik.touched.packRemarks}
            value={formik.values.packRemarks}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Button
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <ControlPointIcon />
                )
              }
              type="submit"
              color="success"
              size="large"
              text="Add"
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withRouter(AddMapForStockin);

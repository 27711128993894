export const CHANNEL_LIST_REQUEST = "CHANNEL_LIST_REQUEST";
export const CHANNEL_LIST_SUCCESS = "CHANNEL_LIST_SUCCESS";
export const CHANNEL_LIST_FAIL = "CHANNEL_LIST_FAIL";

export const CHANNEL_CREATE_REQUEST = "CHANNEL_CREATE_REQUEST";
export const CHANNEL_CREATE_SUCCESS = "CHANNEL_CREATE_SUCCESS";
export const CHANNEL_CREATE_FAIL = "CHANNEL_CREATE_FAIL";
export const CHANNEL_CREATE_RESET = "CHANNEL_CREATE_RESET";

export const EMPLOYEEBY_CHANNEL_LIST_REQUEST =
  "EMPLOYEEBY_CHANNEL_LIST_REQUEST";
export const EMPLOYEEBY_CHANNEL_LIST_SUCCESS =
  "EMPLOYEEBY_CHANNEL_LIST_SUCCESS";
export const EMPLOYEEBY_CHANNEL_LIST_FAIL = "EMPLOYEEBY_CHANNEL_LIST_FAIL";

export const DESIGNATION_LIST_REQUEST = "DESIGNATION_LIST_REQUEST";
export const DESIGNATION_LIST_SUCCESS = "DESIGNATION_LIST_SUCCESS";
export const DESIGNATION_LIST_FAIL = "DESIGNATION_LIST_FAIL";

export const DEPARTMENT_LIST_REQUEST = "DEPARTMENT_LIST_REQUEST";
export const DEPARTMENT_LIST_SUCCESS = "DEPARTMENT_LIST_SUCCESS";
export const DEPARTMENT_LIST_FAIL = "DEPARTMENT_LIST_FAIL";

import React, { useCallback, useState } from "react";
import { useFormik } from "formik";

import UpgradeIcon from "@mui/icons-material/Upgrade";
import { Grid, Paper, Autocomplete } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { RoleSchema, UpdateRoleSchema } from "../schemas";
import Input from "../core/controls/Input";
import { useDispatch, useSelector } from "react-redux";

import Button from "../core/controls/Button";
import FormPopup from "../core/FormPopup";
import { Form } from "../core/useForm";
import {
  createRole,
  updateRoleForSpecificUser,
} from "../redux/actions/RoleAndMenuActions";
import { useEffect } from "react";
import Select from "../core/controls/Select";
import { listRolesUserPage } from "../redux/actions/userActions";

const useStyles = makeStyles(() => ({
  pageContent2: {
    // margin: useTheme().spacing(5),
    padding: "0 20px 0 10px",
    // "& .MuiGrid-item": {
    //   minWidth: "480px !important",
    // },
  },
  inputWidthSetterwithButtoAside: {
    position: "relative !important",
    overflow: "hidden",
    "& .MuiFormControl-root": {
      width: "100%",
      [useTheme().breakpoints.up("md")]: {
        width: "98% !important",
      },
    },

    "& .MuiAutocomplete-popper": {
      position: "relative !important",
      zIndex: "100 !important",
    },
  },
}));
const initialValues = {
  roleId: "",
};
const UpdateRole = (props) => {
  const { openPopup, setOpenPopup } = props;
  // console.log("openPopup.isOpen");
  // console.log(openPopup.isOpen);
  let fData = [];

  const dispatch = useDispatch();
  const classes = useStyles();

  const roleList = useSelector((state) => state.roleList);
  const {
    loading: loadingRoleList,
    error: errorRoleList,
    success: successRoleList,
    roles,
  } = roleList;

  let finData = [];

  const formik = useFormik({
    initialValues,
    validationSchema: UpdateRoleSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      dispatch(
        updateRoleForSpecificUser({
          userSystemId: openPopup.rowId,
          userRoleId: values.roleId,
        })
      ).then((data) => {
        setOpenPopup({
          ...openPopup,
          title: "",
          subTitle: "",
          isOpen: false,
        });
      });
    },
  });

  useEffect(() => {
    dispatch(listRolesUserPage());
  }, [dispatch]);

  useEffect(() => {
    if (
      openPopup.usersWithRoles !== undefined &&
      openPopup.usersWithRoles.length > 0
    ) {
      fData = openPopup.usersWithRoles.filter((data) => {
        return data.userSystemId === openPopup.rowId;
      });
      console.log("fData");
      console.log(fData);
      formik.setValues({
        roleId: fData.length > 0 ? fData[0].userRoleId : "",
      });
    }
  }, [openPopup]);

  const roleOpctionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const roleChange = useCallback((e, v) => {
    formik.setFieldValue("role", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });
  //   console.log("formik.values");
  //   console.log(formik.values);
  return (
    <>
      <FormPopup
        title="Update a Role"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Paper className={classes.pageContent2}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                className={classes.inputWidthSetterwithButtoAside}
              >
                <Select
                  variant="outlined"
                  size="small"
                  label="Role"
                  name="roleId"
                  error={formik.errors.roleId}
                  touched={formik.touched.roleId}
                  value={"" || formik.values.roleId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={roles}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  text="Update role"
                  size="small"
                  startIcon={<UpgradeIcon />}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </FormPopup>
    </>
  );
};

export default UpdateRole;

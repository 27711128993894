// Import necessary React hooks and components from Material-UI and other modules
import React, { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";

// Import custom components and actions from Redux
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Button from "../../../core/controls/Button";
import { useEffect } from "react";
import { getProductsList, getCustomersList } from "../../../redux/actions/CurrentStockReportActions";
import Notification from "../../../core/Notification";
import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";

import axios from "axios";
import { exportToExcelExcelJs } from "../../../utils/json-to-excel-export-exceljs-loan-details";

import { exportToExcelExcelJsSummary } from "../../../utils/json-to-excel-export-exceljs-loan-summary";

import ProductLoanPdfModal from "../../Reports/ProductLoan/component/ProductLoanPdfModal";
// Define Material-UI styles using makeStyles hook
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

// Get current date to set initial values for the date picker
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

// Define initial form values
const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  productName: {
    id: "",
    label: "",
  },
  customerName: {
    id: "",
    label: "",
  },
};

// Define the main component
const ProductLoan = ({ history }) => {
  // Initialize Redux dispatch
  const dispatch = useDispatch();

  // Initialize Material-UI styles
  const classes = useStyles();

  // Initialize useRef for CSV download
  const csvDownloadRef = useRef(0);

  // Initialize state for notifications
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // Initialize state for checkbox
  const [checked, setChecked] = useState(true);

  // Initialize state for pop-up
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [productLoanData, setProductLoanData] = useState([]);
  const [openProductLoaneModal, setopenProductLoaneModal] = useState(false);
  const [openProductLoanModal, setOpenProductLoanModal] = useState(false);


  // Function to handle Excel export
const handleExcelExport = async () => {
  formik.submitForm(); // This will trigger formik's onSubmit to get and export data
  
};

// // Function to open the PDF modal
// const handlePdfModalOpen = () => {
//   setOpenProductLoanModal(true);
// };
const handlePdfModalOpen = async () => {
  await fetchProductLoanData(); // Fetch data for PDF independently
  setOpenProductLoanModal(true);
};
const fetchProductLoanData = async () => {
  const axios_config = {
    headers: { Authorization: `Bearer ${userInfo.token}`, "Content-Type": "application/json" },
  };
  const api_endpoint = `${API_URL}/Report/GetCustomerLoanDetails/${formik.values.customerName.id || "0"}/${formik.values.productName.id || "0"}`;
  const response = await axios.get(api_endpoint, axios_config);
  const { data } = response;
  if (data?.dataObj?.length > 0) {
    setProductLoanData(data.dataObj);
  }
};

// Function to close the PDF modal
const closeProductLoanModal = () => {
  setOpenProductLoanModal(false);
};


  // Redux selectors for getting product and customer lists
  const productsNameList = useSelector((state) => state.productNameList);
  const {
    loading: loadingProductsName,
    error: errorProductsName,
    productNameList,
  } = productsNameList;

  const customerNameListState = useSelector((state) => state.customerNameListState);
  const {
    loading: loadingCustomersName,
    error: errorCustomersName,
    customerNameList,
  } = customerNameListState;

  // Redux selector for getting user login information
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Define heading structure for the excel export
  const heading_with_title = {
    manualInvoice: "Invoice No",
    //systemInvoice: "Invoice No2",
    invoiceDate: "Invoice Date",   
    //customerName: "Customer Name",
    //customerAddress: "Customer Address",
    productName: "Product Name",
    loanReceivedQty: "Loan Receive Quantity",
    loanGivenQty: "Loan Given Quantity",
    //customerId: "Customer Id",
    //productId: "Product Id",
   // loanReceivedUnitPrice: "Receive Unit Price",
    //loanGivenUnitPrice: "Given Unit Price",
    
  };

  // Define formik form handling
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      // Set up Axios configuration for API call
      const axios_config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      // Construct API endpoint URL
      const api_endpoint = `${API_URL}/Report/GetCustomerLoanDetails/${values?.customerName?.id !== "" ? 
        values?.customerName?.id : "0"}/${values?.productName?.id !== "" ? 
          values?.productName?.id : "0"}`;

      // Make API call using Axios
      const response = await axios.get(api_endpoint, axios_config);
      const { data } = response;
      if (response?.errMsg) {
        // Handle errors if any
      } else {
        if (data?.dataObj?.length > 0) {
          let updatedData = [];
          let totalLoanIssued = 0;
          let totalLoanReceived = 0;
  
          for (const value of data?.dataObj) {
            const newSerializedObject = {
              manualInvoice: value?.manualInvoice,
              //systemInvoice: value?.systemInvoice,
              invoiceDate: value?.invoiceDate,
              //customerName: value?.customerName,
              productName: value?.productName,
              loanReceivedQty: value?.loanReceivedQty ?? 0,
              loanGivenQty: value?.loanGivenQty ?? 0,
             // customerId: value?.customerId,
             // productId: value?.productId,
            };
  
            setProductLoanData(data?.dataObj);
            setopenProductLoaneModal(true);

            totalLoanIssued += value?.loanGivenQty ?? 0;
            totalLoanReceived += value?.loanReceivedQty ?? 0;
  
            updatedData.push(newSerializedObject);
          }
  
          const balance = totalLoanIssued - totalLoanReceived;
          const customerName = formik.values.customerName?.label || "All_Customers";
          // Call the utility function to export data to Excel
          exportToExcelExcelJs(
            updatedData,
            heading_with_title,
            `Loan_Details_${
              values?.customerName?.id !== ""
                ? data?.dataObj[0]?.customerName
                : "All_Customer"
            }_${
              values?.productName?.id !== ""
                ? data?.dataObj[0]?.productName
                : "All_Products"
            }_${values.from}`,
            `Loan Details Report For ${
              values?.customerName?.id !== ""
                ? data?.dataObj[0]?.customerName
                : "All Product"
            } `,
            { totalLoanIssued, totalLoanReceived, balance },
            customerName // Pass the customerName here
          );
        } else {
          // If no data is found, show a notification
          action.setSubmitting(false);
          setOpenPopup({
            ...openPopup,
            title: "ZERO DATA!!!",
            subTitle: "No Data found with this specifications!",
            isOpen: true,
          });
        }
      }
    },
  });

// Add a new function for the new Excel export

const handleNewExcelExport = async () => {
  try {
    // Fetch data for the second Excel export only
    const axios_config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const api_endpoint = `${API_URL}/Report/GetCustomerLoanSummery/${formik.values.customerName.id || "0"}/${formik.values.productName.id || "0"}`;

    const response = await axios.get(api_endpoint, axios_config);
    const { data } = response;

    if (data?.dataObj?.length > 0) {
      const updatedData = data.dataObj.map((value) => ({
        productName: value?.productName,
        loanReceivedQty: value?.loanReceivedQty ?? 0,
        loanGivenQty: value?.loanGivenQty ?? 0,
        balanceQty: value?.balanceQty ?? 0,
      }));

      // Ensure customerName is passed correctly
      const customerName = formik.values.customerName.label || "All_Customers";

      // Proceed with the export
      exportToExcelExcelJsSummary(
        updatedData,
        { productName: "Product Name", loanReceivedQty: "Loan Received Quantity", loanGivenQty: "Loan Given Quantity", balanceQty: "Balance Quantity" },
        `Loan_Summary_${customerName}_${formik.values.productName.label || "All_Products"}_${formik.values.from}`,
        "Customer Loan Summary Report",
        customerName // Pass customerName here
      );
    }
  } catch (error) {
    setNotify({
      isOpen: true,
      message: "Error occurred while exporting summary data.",
      type: "error",
    });
  }
};



  // Fetch product and customer lists on component mount
  useEffect(() => {
    if (userInfo) {
      dispatch(getProductsList());
      dispatch(getCustomersList());
    } else {
      // Redirect to login if user information is not available
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/productout" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  // Create filter options for Autocomplete
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  // Handle product name change in Autocomplete
  const productNameChange = useCallback((e, v) => {
    formik.setFieldValue("productName", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  // Handle customer name change in Autocomplete
  const customerNameChange = useCallback((e, v) => {
    formik.setFieldValue("customerName", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  // Render the main component structure
  return (
    <div>
      {/* Render the Menu component */}
      <Menu />
      {/* Render the PageHeader component with relevant information */}
      <PageHeader
        icon={<AssessmentIcon />}
        title="Product Loan Details Report"
        subtitle="Generate Report"
      />
      {/* Render the main content inside a Paper component */}
      <Paper className={classes.pageContent}>
        {/* Render the form inside a Form component */}
        <Form onSubmit={formik.handleSubmit}>
          {/* Create a grid container */}
          <Grid container>
            {/* Grid item for Autocomplete components */}
            <Grid item xs={12} md={6}>
                 {/* Autocomplete for Customer Name */}
              <Autocomplete
                size="small"
                disabled={loadingCustomersName ? true : false}
                id="combo-box-demo-channel"
                onChange={customerNameChange}
                value={formik.values.customerName}
                options={customerNameList !== undefined ? customerNameList : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                sx={{ width: 600 }} 
                renderInput={(params) => (
                  <Input
                    label="Customer Name"
                    name="customer"
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              {/* Autocomplete for Product Name */}
              <Autocomplete
                size="small"
                disabled={loadingProductsName ? true : false}
                id="combo-box-demo-channel"
                onChange={productNameChange}
                value={formik.values.productName}
                options={productNameList !== undefined ? productNameList : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                sx={{ width: 600 }} 
                renderInput={(params) => (
                  <Input
                    label="Product Name"
                    name="customer"
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
             
            </Grid>
            
            {/* Grid item for buttons */}
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
            
                {/* Button for the first Excel export (Details) */}
                <Grid item>
                  <MuiButton
                    className={classes.submitButtonGroup}
                    endIcon={loadingProductsName ? <CircularProgress size="1rem" color="error" /> : null}
                    size="large"
                    onClick={handleExcelExport}
                    style={{ display: "block", textAlign: "center" }}
                  >
                    <img
                      width={55}
                      height={55}
                      src={process.env.PUBLIC_URL + "/images/excel-icon.png"}
                      alt="Excel Icon"
                    />
                    <div style={{ marginTop: 8, fontSize: 12 }}>Details</div> {/* Label */}
                  </MuiButton>
                </Grid>

              {/* Button for the second Excel export (Summary) */}
              <Grid item>
                <MuiButton
                  style={{ display: "block", textAlign: "center" }} // Different color styling
                  className={classes.submitButtonGroup}
                  onClick={handleNewExcelExport}
                >
                  <img
                    width={55}
                    height={55}
                    src={process.env.PUBLIC_URL + "/images/summary.png"}
                    alt="Summary Icon"
                  />
                  <div style={{ marginTop: 8, fontSize: 12 }}>Summary</div> {/* Label */}
                </MuiButton>
              </Grid>

              {/* Button for PDF export (Details) */}
              <Grid item>
                <MuiButton
                  className={classes.submitButtonGroup}
                  size="large"
                  onClick={handlePdfModalOpen}
                  style={{ display: "block", textAlign: "center" }}
                >
                  <img
                    width={55}
                    height={55}
                    src={process.env.PUBLIC_URL + "/images/pdf-icon.png"}
                    alt="PDF Icon"
                  />
                  <div style={{ marginTop: 8, fontSize: 12 }}>Details</div> {/* Label */}
                </MuiButton>
              </Grid>

        {/* Back button */}
        {/* <Grid item>
          <Button
            color="error"
            text="Back"
            onClick={() => {
              const location = {
                pathname: "/",
                state: {},
              };
              history.push(location);
            }}
          />
        </Grid> */}
      </Grid>
            
            </Grid>
        </Form>
      </Paper>
      {/* Notification component for displaying messages */}
      <Notification notify={notify} setNotify={setNotify} />
      {/* The PDF modal component */}
    {openProductLoanModal && (
      <ProductLoanPdfModal
        showModal={openProductLoanModal}
        closeModal={closeProductLoanModal}
        item_list={productLoanData}
      />
    )}
    </div>
  );
};

// Export the component with withRouter for access to history object
export default withRouter(ProductLoan);

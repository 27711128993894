import {
  ASSIGNED_MENU_UPDATE_FAIL,
  ASSIGNED_MENU_UPDATE_REQUEST,
  ASSIGNED_MENU_UPDATE_RESET,
  ASSIGNED_MENU_UPDATE_SUCCESS,
  ROLES_CREATE_FAIL,
  ROLES_CREATE_REQUEST,
  ROLES_CREATE_RESET,
  ROLES_CREATE_SUCCESS,
  ROLES_DETAILS_FAIL,
  ROLES_DETAILS_REQUEST,
  ROLES_DETAILS_RESET,
  ROLES_DETAILS_SUCCESS,
  ROLES_LIST_FAIL,
  ROLES_LIST_REQUEST,
  ROLES_LIST_SUCCESS,
  ROLE_FOR_SPECIFIC_USER_UPDATE_FAIL,
  ROLE_FOR_SPECIFIC_USER_UPDATE_REQUEST,
  ROLE_FOR_SPECIFIC_USER_UPDATE_RESET,
  ROLE_FOR_SPECIFIC_USER_UPDATE_SUCCESS,
} from "../constants/RoleAndMenuConstants";

export const RoleListReducer = (state = { roles: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case ROLES_LIST_REQUEST:
      return { loading: true };
    case ROLES_LIST_SUCCESS:
      return {
        loading: false,
        roles: action.payload,
      };
    case ROLES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const RoleListDetailsReducer = (
  state = {
    roleId: "",
    roleName: "",
    roleActiveStatus: "",
    menuList: [],
    permittedMenuList: [],
  },
  action
) => {
  switch (action.type) {
    case ROLES_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ROLES_DETAILS_SUCCESS:
      return {
        loading: false,
        roleId: action.payload.roleId,
        roleName: action.payload.roleName,
        roleActiveStatus: action.payload.roleActiveStatus,
        menuList: action.payload.menuList.sort((p1, p2) =>
          p1.menuId > p2.menuId ? 1 : p1.menuId < p2.menuId ? -1 : 0
        ),
        permittedMenuList: action.payload.permittedMenuList,
      };
    case ROLES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ROLES_DETAILS_RESET:
      return { role: {} };

    default:
      return state;
  }
};

export const updateAssignedMenuReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGNED_MENU_UPDATE_REQUEST:
      return { loading: true };
    case ASSIGNED_MENU_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ASSIGNED_MENU_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ASSIGNED_MENU_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const roleCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLES_CREATE_REQUEST:
      return { loading: true };
    case ROLES_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        createdSingleRole: action.payload,
      };
    case ROLES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ROLES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const roleForSpecificUserUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_FOR_SPECIFIC_USER_UPDATE_REQUEST:
      return { loading: true };
    case ROLE_FOR_SPECIFIC_USER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedUserRole: action.payload,
      };
    case ROLE_FOR_SPECIFIC_USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ROLE_FOR_SPECIFIC_USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

import React from "react";
import { Button as MuiButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#50577A",
    margin: useTheme().spacing(1),
    "& .MuiButton-label": {
      textTransform: "none",
    },
  },
}));

const Button = (props) => {
  const { text, size, color, variant, onClick, ...other } = props;
  const [buttonColor, setButtonColor] = useState(color);
  const classes = useStyles();

  useEffect(() => {}, [buttonColor, color]);

  return (
    <MuiButton
      variant={variant || "contained"}
      size={size || "large"}
      color={buttonColor || "primary"}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
    >
      {text}
    </MuiButton>
  );
};

export default Button;

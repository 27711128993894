import React, { useRef } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
// import "./View.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { BiPaperPlane } from "react-icons/bi";

import styled from "@emotion/styled";

import html2canvas from "html2canvas";

import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";

export default function ConveyanceExportModal({
  showModal,
  closeModal,
  item_list,
  date_range,
}) {
  const reportRef = useRef(null);

  const handleGeneratePdf = async (soSystemNo) => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    const pageHeight = pdf.internal.pageSize.getHeight();

    let currentPageHeight = 0;
    let currentPage = reportRef.current;

    const conveyanceHeader = document.querySelector(".conveyance-header");
    let canvas = await html2canvas(conveyanceHeader);

    let canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = reportRef.current;
    }

    let imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      0.05,
      currentPageHeight,
      pdf.internal.pageSize.getWidth(),
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const conveyanceSubHeader = document.querySelector(
      ".conveyance-sub-header"
    );

    canvas = await html2canvas(conveyanceSubHeader);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      
      currentPage = reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight,

      pdf.internal.pageSize.getWidth() - 10,
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const conveyanceTableHeader = document.querySelector(
      ".conveyance-table-header"
    );

    canvas = await html2canvas(conveyanceTableHeader);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight,
      pdf.internal.pageSize.getWidth() - 10,
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const contentElements = document.querySelectorAll(".conveyance-info");
    // console.log("content elements: ", contentElements);

    for (const element of contentElements) {
      const canvas = await html2canvas(element);

      const canvasHeight =
        (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

      if (currentPageHeight + canvasHeight > pageHeight) {
        pdf.addPage();
        currentPageHeight = 0;
        currentPage = reportRef.current;
      }

      const imageData = canvas.toDataURL("image/png");

      pdf.addImage(
        imageData,
        "PNG",
        5,
        currentPageHeight,
        pdf.internal.pageSize.getWidth() - 10,
        canvasHeight
      );
      currentPageHeight += canvasHeight;
    }

    const conveyanceFooter = document.querySelector(".conveyance-footer");

    canvas = await html2canvas(conveyanceFooter);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight + 10,
      pdf.internal.pageSize.getWidth() - 10,
      canvasHeight
    );

    pdf.save(`employee-conveyance-${soSystemNo}.pdf`);
  };

  const getTotalPrice = (arr) => {
    var sum = 0;
    arr?.map((single) => {
      sum = sum + single.taskTransportationCost;
    });

    sum = parseFloat(sum);

    return sum.toFixed(2);
  };

  const NumInWords = (number) => {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  };

  // ==========================================================================
  // Function Declaration :: Convert Numbers to Words :: Indian Format
  // ==========================================================================

  const convertNumberToWords = (num) => {
    console.log("num");
    console.log(num);

    var ones = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    if ((num = num.toString()).length > 9)
      return "Overflow: Maximum 9 digits supported";
    const n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    console.log("n");
    console.log(n);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] != 0
        ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) +
          "Thousand "
        : "";
    str +=
      n[4] != 0
        ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) +
          "Hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]])
        : "";
    return str;
  };

  const getNumbers = (inputNumber, currency) => {
    console.log("currency");
    console.log(currency);
    var num = inputNumber;
    var str = num.toString();
    var numarray = str.split(".");
    var a = new Array();
    a = numarray;
    console.log("a[0]");
    console.log(a[0]);
    console.log("a[1]");
    console.log(a[1]);
    inputNumber = a[0];
    console.log("NumToWord(inputNumber)");
    console.log(NumToWord(inputNumber));
    var s = NumToWord(inputNumber);

    if (a[1] === undefined) {
      var c = currency === "BDT" ? " Taka only." : "Dollar only";
      s = s + c;
    } else if (a[1] === "00") {
      var c = currency === "BDT" ? " Taka only." : "Dollar only";

      s = s + c;
    } else if (a[1][0] == 0) {
      var c =
        currency === "BDT"
          ? " Taka and Zero"
          : "Dollar and Zero" + NumToWord(a[1][1]) + currency === "BDT"
          ? " poisha only."
          : " penny only.";
      s = s + c;
    } else {
      var c =
        currency === "BDT"
          ? " Taka and "
          : " Dollar and " + NumToWord(a[1]) + currency === "BDT"
          ? " poisha only."
          : " penny only.";
      s = s + c;
    }
    console.log("s");
    console.log(s);
    console.log("NumToWord(a[1])");
    console.log(a[1][0]);
    return s;
  };

  const NumToWord = (inputNumber) => {
    var str = new String(inputNumber);
    var splt = str.split("");
    var rev = splt.reverse();
    var once = [
      "Zero",
      " One",
      " Two",
      " Three",
      " Four",
      " Five",
      " Six",
      " Seven",
      " Eight",
      " Nine",
    ];
    var twos = [
      "Ten",
      " Eleven",
      " Twelve",
      " Thirteen",
      " Fourteen",
      " Fifteen",
      " Sixteen",
      " Seventeen",
      " Eighteen",
      " Nineteen",
    ];
    var tens = [
      "",
      "Ten",
      " Twenty",
      " Thirty",
      " Forty",
      " Fifty",
      " Sixty",
      " Seventy",
      " Eighty",
      " Ninety",
    ];

    var numLength = rev.length;
    var word = new Array();
    var j = 0;

    for (var i = 0; i < numLength; i++) {
      switch (i) {
        case 0:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = "" + once[rev[i]];
          }
          word[j] = word[j];
          break;

        case 1:
          aboveTens();
          break;

        case 2:
          if (rev[i] == 0) {
            word[j] = "";
          } else if (rev[i - 1] == 0 || rev[i - 2] == 0) {
            word[j] = once[rev[i]] + " Hundred ";
          } else {
            word[j] = once[rev[i]] + " Hundred and";
          }
          break;

        case 3:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] != 0 || rev[i] > 0) {
            word[j] = word[j] + " Thousand";
          }
          break;

        case 4:
          aboveTens();
          break;

        case 5:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] !== "0" || rev[i] > "0") {
            word[j] = word[j] + " Lakh";
          }

          break;

        case 6:
          aboveTens();
          break;

        case 7:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] !== "0" || rev[i] > "0") {
            word[j] = word[j] + " Crore";
          }
          break;

        case 8:
          aboveTens();
          break;

        //            This is optional.

        //            case 9:
        //                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
        //                    word[j] = '';
        //                }
        //                else {
        //                    word[j] = once[rev[i]];
        //                }
        //                if (rev[i + 1] !== '0' || rev[i] > '0') {
        //                    word[j] = word[j] + " Arab";
        //                }
        //                break;

        //            case 10:
        //                aboveTens();
        //                break;

        default:
          break;
      }
      j++;
    }

    function aboveTens() {
      if (rev[i] == 0) {
        word[j] = "";
      } else if (rev[i] == 1) {
        word[j] = twos[rev[i - 1]];
      } else {
        word[j] = tens[rev[i]];
      }
    }

    word.reverse();
    var finalOutput = "";
    for (i = 0; i < numLength; i++) {
      finalOutput = finalOutput + word[i];
    }
    return finalOutput;
  };

  return (
    <div>
      <SalesOrderModal show={showModal} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <div
          ref={reportRef}
          id="invoiceCapture"
          className=" pt-1 "
          style={{ position: "relative" }}
        >
          <div className="conveyance-header">
            <div className="d-flex flex-row justify-content-between align-items-center w-100 py-4 px-2">
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/trade.png"}
                  alt=""
                  width="80"
                  height="35"
                  style={{ width: "80px", height: "50px" }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="fw-bold my-2" style={{ fontSize: "18px" }}>
                  TRADE ZENTRUM
                </h4>
                <h6
                  className="fw-bold text-secondary mb-1 text-center"
                  style={{ fontSize: "14px" }}
                >
                  Kunia Targas, Gazipur
                </h6>
              </div>
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "130px", height: "40px" }}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="conveyance-sub-header">
              <div style={{ textAlign: "center", margin: "10px 0" }}>
                <span
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid #e4e4e4",
                    padding: "5px",
                    fontSize: "12px",
                  }}
                >
                  Conveyance Bill
                </span>
              </div>
              <Row style={{ paddingBottom: "50px", height: "auto" }}>
                <Col md={7} lg={8}>
                  <div style={{ fontSize: "12px" }}>
                    Bill&nbsp;Date:&nbsp; : {date_range}
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Bill By:&nbsp; {item_list[0]?.billBy}
                  </div>
                </Col>
              </Row>
            </div>

            <div
              style={{
                width: "auto",
                overflowX: "scroll",
            
              }}
              className="row-scrollers"
            >
              
              <Table
                className="mb-0"
                style={{
                  border: "1px solid #e3e3e3",
                  fontSize: "11px", 
                }}
              >
                <thead className="conveyance-table-header" style={{ fontSize: "12px" }}>
                  <tr>
                    <th>Task Name</th>
                    <th>Invoice Id</th>
                    <th>Factory Name</th>
                    
                    <th>From</th>
                    <th>To</th>
                    <th>Transport</th>
                    <th>Fair</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {item_list?.length > 0
                    ? item_list?.map((conveyance) => {
                        return (
                          <tr
                            key={conveyance?.taskRelatedInvoiceId}
                            className="conveyance-info"
                          >
                      
                            <td>{conveyance?.taskName}</td>
                            <td>{conveyance?.soSystemNo}</td>
                            <td>{conveyance?.taskDestination}</td>
                          
                            <td>
                              {conveyance?.taskTransportationStartLocation}
                            </td>
                            <td>{conveyance?.taskTransportationEndLocation}</td>
                            <td>{conveyance?.vehicleName}</td>
                            <td>{conveyance?.taskTransportationCost}</td>
                  
                          </tr>
                        );
                      })
                    : null}
                </tbody>
          
              </Table>
            
            </div>

            <div className="conveyance-footer">
              <Table style={{ marginTop: "2rem", border: "1px solid #e3e3e3" }}>
                <tbody>
                  <tr className="text-end">
                    <td></td>
                    <td
                      className="fw-bold"
                      style={{ width: "auto", fontSize: "12px" }}
                    >
                      Total Conveyance Amount
                    </td>
                    <td
                      className="text-end"
                      style={{ width: "100px", fontSize: "12px" }}
                    >
                      {getTotalPrice(item_list)}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div className="bg-light py-3 px-4 rounded">
                {/* {getNumbers(143395.05)} */}
                <div style={{ fontSize: "12px" }}>
                  In Word: {NumInWords(getTotalPrice(item_list))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              <Button
                variant="primary"
                className="d-flex align-items-center justify-content-center w-100"
                onClick={() => {
                  // GenerateInvoice(item_list[0]?.taskId);
                  handleGeneratePdf(item_list[0]?.soSystemNo);
                }}
              >
                <BiPaperPlane
                  style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                  className="me-2"
                />
                Print This Out
              </Button>
            </Col>
            <Col md={6}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center justify-content-center w-100"
                  >
                    Export as PDF
                  </Button>
                )}
                content={() => reportRef.current}
              />
            </Col>
          </Row>
        </div>
      </SalesOrderModal>
      <hr className="mt-4 mb-3" />
    </div>
  );
}

const SalesOrderModal = styled(Modal)`
  #invoiceCapture {
    padding: 5px;
  }

  table tr th,
  td {
    font-size: 10px;
    text-align: left;
  }

  .sticker-info {
    display: flex;
    width: 402px;
    max-height: 218px;
    height: 218px;
    max-width: 402px;
    border: 1px solid #000;
    padding: 10px;
    flex-direction: column;
    row-gap: 4px;
  }

  .capture-portion {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    row-gap: 4;
    flex-direction: column;
    align-items: center;
  }

  .sales-order-sticker-base {
    display: flex;
    column-gap: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__left-portion {
    width: 180px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__left-portion
    .company-info
    p {
    margin: 0;
    font-size: 8px;
  }

  .sales-order-sticker-base .sales-order-sticker-base__right-portion {
    width: calc(100% - 150px);
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info {
    margin-left: -90px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table {
    width: 100%;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr {
    display: flex;
    column-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    .table-data
    .info-tr
    .info-td {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .info-data
    table
    tr
    td {
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-info
    .tag
    .invoice-number {
    font-size: 10px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo {
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 4px;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .date {
    font-size: 8px;
    margin: 0;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .image {
    display: flex;
    align-items: center;
  }

  .sales-order-sticker-base
    .sales-order-sticker-base__right-portion
    .product-company-logo
    .invoice-number {
    margin: 0;
    font-size: 8px;
  }

  .date-and-company-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .date-and-company-name p {
    margin: 0;
    font-size: 12px;
  }

  .date-and-company-name .date {
    font-size: 6px !important;
    margin-top: 15px;
  }
`;

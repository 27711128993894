import {
  BANK_LIST_FAIL,
  BANK_LIST_REQUEST,
  BANK_LIST_SUCCESS,
  CHEQUE_CREATE_FAIL,
  CHEQUE_CREATE_REQUEST,
  CHEQUE_CREATE_RESET,
  CHEQUE_CREATE_SUCCESS,
  CHEQUE_DELETE_FAIL,
  CHEQUE_DELETE_REQUEST,
  CHEQUE_DELETE_RESET,
  CHEQUE_DELETE_SUCCESS,
  CHEQUE_DETAILS_FAIL,
  CHEQUE_DETAILS_REQUEST,
  CHEQUE_DETAILS_RESET,
  CHEQUE_DETAILS_SUCCESS,
  CHEQUE_LIST_FAIL,
  CHEQUE_LIST_REQUEST,
  CHEQUE_LIST_SUCCESS,
  CHEQUE_STATUS_LIST_FAIL,
  CHEQUE_STATUS_LIST_REQUEST,
  CHEQUE_STATUS_LIST_SUCCESS,
  CHEQUE_TYPE_LIST_FAIL,
  CHEQUE_TYPE_LIST_REQUEST,
  CHEQUE_TYPE_LIST_SUCCESS,
  CHEQUE_UPDATE_FAIL,
  CHEQUE_UPDATE_REQUEST,
  CHEQUE_UPDATE_RESET,
  CHEQUE_UPDATE_SUCCESS,
  ISSUER_BANK_LIST_FAIL,
  ISSUER_BANK_LIST_REQUEST,
  ISSUER_BANK_LIST_SUCCESS,
} from "../constants/chequeConstants";

export const ChequeListReducer = (state = { cheques: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case CHEQUE_LIST_REQUEST:
      return { loading: true };
    case CHEQUE_LIST_SUCCESS:
      return {
        loading: false,
        cheques: action.payload,
      };
    case CHEQUE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ChequeStatusListReducer = (
  state = { chequestatuss: [] },
  action
) => {
  // console.log(action.payload);
  switch (action.type) {
    case CHEQUE_STATUS_LIST_REQUEST:
      return { loading: true };
    case CHEQUE_STATUS_LIST_SUCCESS:
      return {
        loading: false,
        chequestatuss: action.payload,
      };
    case CHEQUE_STATUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const BankListReducer = (state = { banks: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case BANK_LIST_REQUEST:
      return { loading: true };
    case BANK_LIST_SUCCESS:
      return {
        loading: false,
        banks: action.payload,
      };
    case BANK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const IssuerBankListReducer = (state = { issuerbanks: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case ISSUER_BANK_LIST_REQUEST:
      return { loading: true };
    case ISSUER_BANK_LIST_SUCCESS:
      return {
        loading: false,
        issuerbanks: action.payload,
      };
    case ISSUER_BANK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ChequeTypeListReducer = (state = { chequeTypes: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case CHEQUE_TYPE_LIST_REQUEST:
      return { loading: true };
    case CHEQUE_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        chequeTypes: action.payload,
      };
    case CHEQUE_TYPE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ChequeDetailsReducer = (state = { chequeSingle: {} }, action) => {
  switch (action.type) {
    case CHEQUE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case CHEQUE_DETAILS_SUCCESS:
      return { loading: false, chequeSingle: action.payload };
    case CHEQUE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CHEQUE_DETAILS_RESET:
      return { chequeSingle: {} };

    default:
      return state;
  }
};

export const ChequeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CHEQUE_CREATE_REQUEST:
      return { loading: true };
    case CHEQUE_CREATE_SUCCESS:
      return { loading: false, success: true, cheque: action.payload };
    case CHEQUE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CHEQUE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const ChequeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHEQUE_DELETE_REQUEST:
      return { loading: true };
    case CHEQUE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CHEQUE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CHEQUE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const ChequeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CHEQUE_UPDATE_REQUEST:
      return { loading: true };
    case CHEQUE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CHEQUE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CHEQUE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

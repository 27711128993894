import { API_URL } from "../constants/apiConstants";
import Axios from "axios";
import Decoder from "jwt-decode";
import {
  USER_DETAILS_RESET,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_WITH_ROLE_LIST_FAIL,
  USER_WITH_ROLE_LIST_REQUEST,
  USER_WITH_ROLE_LIST_SUCCESS,
} from "../constants/userConstants";
import {
  CUSTOMER_LIST_FAIL,
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
} from "../constants/customerConstants";
import {
  EMPLOYEEALL_LIST_FAIL,
  EMPLOYEEALL_LIST_REQUEST,
  EMPLOYEEALL_LIST_SUCCESS,
  EMPLOYEE_DETAILS_FAIL,
  EMPLOYEE_DETAILS_REQUEST,
  EMPLOYEE_DETAILS_SUCCESS,
  EMPLOYEE_LIST_FAIL,
  EMPLOYEE_LIST_REQUEST,
  EMPLOYEE_LIST_SUCCESS,
} from "../constants/employeeConstants";
import {
  ROLES_LIST_FAIL,
  ROLES_LIST_REQUEST,
  ROLES_LIST_SUCCESS,
} from "../constants/RoleAndMenuConstants";

export const login = (user) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    var config = {
      method: "post",

      url: `${API_URL}/auth/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(user),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        const decodedata =
          response.data.dataObj !== null && response.data.dataObj !== undefined
            ? Decoder(response.data.dataObj.token)
            : null;
        // console.log("decodedata");
        // console.log(decodedata);

        if (parseInt(decodedata.UserActiveStatus) === 0) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: {
              errMsg: "User Deactivated!",
            },
          });
          return {
            errMsg: "User Deactivated!",
          };
        } else if (parseInt(decodedata.UserActiveStatus) === 1) {
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: response.data.dataObj,
          });
          localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data.dataObj)
          );
          return response.data;
        }
      })
      .catch(function (error) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    return finalGotData;
    // console.log(data);

    //
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
};

export const listCustomersForSignup = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Customer/GetCustomerDetails`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.customerId,
        label: single.customerName,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: CUSTOMER_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployees = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/User/GetUnAssignUserList`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.employeeId,
        label:
          single.empFirstName +
          (single.empLastName === null
            ? "-" + single.employeeCode
            : " " + single.empLastName + "-" + single.employeeCode),
      };
    });
    dispatch({ type: EMPLOYEE_LIST_SUCCESS, payload: final });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployeesForDropdown = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Employee/GetEmployeeDetails`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.employeeId,
        label:
          single.empFirstName +
          (single.empLastName === null
            ? "-" + single.employeeCode
            : " " + single.empLastName + "-" + single.employeeCode),
      };
    });
    dispatch({ type: EMPLOYEE_LIST_SUCCESS, payload: final });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployeesAll = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEEALL_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Employee/GetEmployeeDetails`,
    };
    const { data } = await Axios(config);

    const final = data.dataObj.map((single) => {
      return {
        id: single.employeeId,
        label:
          single.empFirstName +
          (single.empLastName === null
            ? "-" + single.employeeCode
            : " " + single.empLastName + "-" + single.employeeCode),
      };
    });
    dispatch({ type: EMPLOYEEALL_LIST_SUCCESS, payload: final });
  } catch (error) {
    dispatch({
      type: EMPLOYEEALL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listEmployeeDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: EMPLOYEE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Employee/GetEmployeeById/${id}`,
    };

    const { data } = await Axios(config);

    // console.log(data);

    dispatch({ type: EMPLOYEE_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePassword = (data) => async (dispatch, getState) => {
  try {
    // console.log("data");
    // console.log(data);
    dispatch({ type: USER_PASSWORD_CHANGE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    var config = {
      method: "put",

      url: `${API_URL}/User/ChangePassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    Axios(config).then(function (response) {
      dispatch({ type: USER_PASSWORD_CHANGE_SUCCESS });
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_CHANGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listRoles = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLES_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Role/GetRoles/1`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    const rolesData = data.dataObj.map((single) => {
      return {
        id: single.roleId,
        label: single.roleName,
      };
    });
    // console.log(rolesData);
    const rolesDataFilter = rolesData.filter((single) => {
      return single.label !== "Customer";
    });
    dispatch({ type: ROLES_LIST_SUCCESS, payload: rolesDataFilter });
  } catch (error) {
    dispatch({
      type: ROLES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listRolesUserPage = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLES_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Role/GetRoles/1`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    const rolesData = data.dataObj.map((single) => {
      return {
        id: single.roleId,
        title: single.roleName,
      };
    });
    // console.log(rolesData);
    const rolesDataFilter = rolesData.filter((single) => {
      return single.id !== 10;
    });
    dispatch({ type: ROLES_LIST_SUCCESS, payload: rolesDataFilter });
  } catch (error) {
    dispatch({
      type: ROLES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUsersWithRoles = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_WITH_ROLE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/Role/GetUserRoleList`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: USER_WITH_ROLE_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: USER_WITH_ROLE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
  DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_REQUEST,
  DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_SUCCESS,
  DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_FAIL,
  DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_REQUEST,
  DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_SUCCESS,
  DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_FAIL,
  DASHBOARD_CALENDER_SALES_INFO_REQUEST,
  DASHBOARD_CALENDER_SALES_INFO_SUCCESS,
  DASHBOARD_CALENDER_SALES_INFO_FAIL,
  DASHBOARD_CUSTOMER_SALES_INFO_REQUEST,
  DASHBOARD_CUSTOMER_SALES_INFO_SUCCESS,
  DASHBOARD_CUSTOMER_SALES_INFO_FAIL,
  DASHBOARD_PRODUCT_SALES_INFO_REQUEST,
  DASHBOARD_PRODUCT_SALES_INFO_SUCCESS,
  DASHBOARD_PRODUCT_SALES_INFO_FAIL
} from "../constants/dashboardConstants";


export const listTodaysSalesInfos = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Dashboard/ GetBoxItemTodysSaleInfo`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const listCurrentMonthSalesInfos = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Dashboard/ GetBoxItemCurrentMonthSaleInfo`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



export const listCalenderSalesInfos = (datas) => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_CALENDER_SALES_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Dashboard/GetCalenderSalesInfo?employeeId=-1&startSalesYear=${datas.startSalesYear}&endSalesYear=${datas.endSalesYear}&salesMonth=${datas.salesMonth}`,
    //  url: `${API_URL}/Dashboard/GetCalenderSalesInfo?employeeId=-1&startSalesYear=0&endSalesYear=0&salesMonth=empty`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: DASHBOARD_CALENDER_SALES_INFO_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DASHBOARD_CALENDER_SALES_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};




export const listCustomerSalesInfos = (datas) => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_CUSTOMER_SALES_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Dashboard/GetCustomerSalesChart?employeeId=-1&topNumber=5&startSalesYear=${datas.startSalesYear}&salesMonth=${datas.salesMonth}`,
      // url: `${API_URL}/Dashboard/GetCustomerSalesChart?employeeId=-1&topNumber=5&startSalesYear=0&salesMonth=empty`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: DASHBOARD_CUSTOMER_SALES_INFO_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DASHBOARD_CUSTOMER_SALES_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



export const listProductSalesInfos = (datas) => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_PRODUCT_SALES_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/Dashboard/GetByProductSalesChart?employeeId=-1&topNumber=5&startSalesYear=${datas.startSalesYear}&salesMonth=${datas.salesMonth}`,
     // url: `${API_URL}/Dashboard/GetByProductSalesChart?employeeId=-1&topNumber=5&startSalesYear=0&salesMonth=empty`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);

    dispatch({ type: DASHBOARD_PRODUCT_SALES_INFO_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: DASHBOARD_PRODUCT_SALES_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
import {
  DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_REQUEST,
  DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_SUCCESS,
  DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_FAIL,
  DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_REQUEST,
  DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_SUCCESS,
  DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_FAIL,
  DASHBOARD_CALENDER_SALES_INFO_REQUEST,
  DASHBOARD_CALENDER_SALES_INFO_SUCCESS,
  DASHBOARD_CALENDER_SALES_INFO_FAIL,
  DASHBOARD_CUSTOMER_SALES_INFO_REQUEST,
  DASHBOARD_CUSTOMER_SALES_INFO_SUCCESS,
  DASHBOARD_CUSTOMER_SALES_INFO_FAIL,
  DASHBOARD_PRODUCT_SALES_INFO_REQUEST,
  DASHBOARD_PRODUCT_SALES_INFO_SUCCESS,
  DASHBOARD_PRODUCT_SALES_INFO_FAIL
} from "../constants/dashboardConstants";



export const dashboardTodaysSaleInfoReducer = (state = { todaysSalesInfo: [] }, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_REQUEST:
      return { loading: true };
    case DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_SUCCESS:
      return {
        loading: false,
        todaysSalesInfo: action.payload,
      };
    case DASHBOARD_GET_BOX_ITEM_TODAYS_SALES_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const dashboardCurrMonthSaleInfoReducer = (state = { currMonthSalesInfo: [] }, action) => {
  switch (action.type) {
    case DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_REQUEST:
      return { loading: true };
    case DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_SUCCESS:
      return {
        loading: false,
        currMonthSalesInfo: action.payload,
      };
    case DASHBOARD_GET_BOX_ITEM_CURRENT_MONTH_SALES_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const dashboardCalenderSalesInfoReducer = (state = { calenderSalesInfo: [] }, action) => {
  switch (action.type) {
    case DASHBOARD_CALENDER_SALES_INFO_REQUEST:
      return { loading: true };
    case DASHBOARD_CALENDER_SALES_INFO_SUCCESS:
      return {
        loading: false,
        calenderSalesInfo: action.payload,
      };
    case DASHBOARD_CALENDER_SALES_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const dashboardCustomerSalesInfoReducer = (state = { customerSalesInfo: [] }, action) => {
  switch (action.type) {
    case DASHBOARD_CUSTOMER_SALES_INFO_REQUEST:
      return { loading: true };
    case DASHBOARD_CUSTOMER_SALES_INFO_SUCCESS:
      return {
        loading: false,
        customerSalesInfo: action.payload,
      };
    case DASHBOARD_CUSTOMER_SALES_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



export const dashboardProductSalesInfoReducer = (state = { productSalesInfo: [] }, action) => {
  switch (action.type) {
    case DASHBOARD_PRODUCT_SALES_INFO_REQUEST:
      return { loading: true };
    case DASHBOARD_PRODUCT_SALES_INFO_SUCCESS:
      return {
        loading: false,
        productSalesInfo: action.payload,
      };
    case DASHBOARD_PRODUCT_SALES_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
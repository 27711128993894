import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateBatch } from "../../redux/actions/BatchActions";
import { useDispatch } from "react-redux";
import {
  createTransporation,
  listLocation,
  listVehicle,
} from "../../redux/actions/taskActions";

const statusItems = [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
  { id: "3", title: "Pause" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  taskId: "",
  vehicle: {
    id: "",
    label: "",
  },
  location: {
    id: "",
    label: "",
  },
  endLocation: {
    id: "",
    label: "",
  },
  fare: "",
  remarks: "",
};

const AddTransport = ({
  data,
  setData,
  openPopup,
  setOpenPopup,
  taskId,
  history,
}) => {
  const dispatch = useDispatch();

  const vehicleList = useSelector((state) => state.vehicleList);
  const {
    loading: loadingVehicle,
    error: errorVehicle,
    vehicles,
  } = vehicleList;

  const locationList = useSelector((state) => state.locationList);
  const {
    loading: loadingLocation,
    error: errorLocation,
    locations,
  } = locationList;
  
  const transportCreate = useSelector((state) => state.transportCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    transport: createdTransport,
  } = transportCreate;
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      // console.log("hello");
      // console.log(data);
      navigator.geolocation.getCurrentPosition(function (position) {
        dispatch(
          createTransporation({
            taskId: values.taskId,
            taskTransportationVehicleId: values.vehicle.id,
            taskTransportationCost: values.fare,
            taskTransportationStartLat: position.coords.latitude,
            taskTransportationStartLong: position.coords.longitude,
            taskTransportationEndLat: null,
            taskTransportationEndLong: null,
            taskTransportationStartLocation: values.location.label,
            taskTransportationEndLocation: values.endLocation.label,
            taskTransportationRemarks: values.remarks,
          })
        ).then((res) => {
          console.log("final res");
          console.log(res);
          const filteredTask = data.items.filter((singleData) => {
            return String(singleData.taskId) === String(values.taskId);
          });

          let taskTransportationData = filteredTask[0].taskTransportationData;
          if (taskTransportationData.length > 0) {
            taskTransportationData.push({
              taskTransportationAutoId: res.dataObj.data,
              taskTransportationVehicleId: values.vehicle.id,
              vehicleName: values.vehicle.label,
              taskTransportationStartLocationId: values.location.id,
              taskTransportationStartLocation: values.location.label,
              taskTransportationEndLocationId: values.endLocation.id,
              taskTransportationEndLocation: values.endLocation.label,
              taskTransportationStartLat: position.coords.latitude,
              taskTransportationStartLong: position.coords.longitude,
              taskTransportationEndLat: null,
              taskTransportationEndLong: null,
              taskTransportationCost: values.fare,
              taskTransportationRemarks: values.remarks,
            });
          } else {
            taskTransportationData.push({
              taskTransportationAutoId: res.dataObj.data,
              taskTransportationVehicleId: values.vehicle.id,
              vehicleName: values.vehicle.label,

              taskTransportationStartLocationId: values.location.id,
              taskTransportationStartLocation: values.location.label,
              taskTransportationEndLocationId: values.endLocation.id,
              taskTransportationEndLocation: values.endLocation.label,
              taskTransportationStartLat: position.coords.latitude,
              taskTransportationStartLong: position.coords.longitude,
              taskTransportationEndLat: null,
              taskTransportationEndLong: null,
              taskTransportationCost: values.fare,
              taskTransportationRemarks: values.remarks,
            });
            // console.log("hello");
            // console.log(values);
          }

          var items = data.items.slice();
          var newItems = items.map(function (sitems) {
            // console.log("sitems");
            // console.log(sitems);
            for (var key in sitems) {
              if (sitems.taskId === values.taskId) {
                // console.log("Hello");
                sitems["taskTransportationData"] = taskTransportationData;
              }
            }
            return sitems;
          });

          setData({
            ...data,
            items: newItems,
          });
          setOpenPopup({
            ...openPopup,
            isOpen: false,
          });
        });
      });
    },
  });

  useEffect(() => {
    dispatch(listVehicle());
    dispatch(listLocation());
  }, [dispatch, history]);

  const changeValues = () => {
    formik.setFieldValue("taskId", taskId);
  };

  useEffect(() => {
    changeValues();
  }, [data, taskId]);

  const vehicleChange = useCallback((e, v) => {
    formik.setFieldValue("vehicle", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const locationChange = useCallback((e, v) => {
    formik.setFieldValue("location", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const endLocationChange = useCallback((e, v) => {
    formik.setFieldValue("endLocation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Autocomplete
            // disablePortal
            size="small"
            disabled={loadingLocation ? true : false}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={locationChange}
            value={formik.values.location}
            options={locations !== undefined ? locations : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="From"
                name="location"
                error={
                  formik.errors.location !== undefined
                    ? formik.errors.location.id
                    : ""
                }
                touched={
                  formik.touched.location !== undefined
                    ? formik.touched.location
                    : ""
                }
                {...params}
                onBlur={formik.handleBlur}
                className="fullwidth-input-task"
                required
              />
            )}
          />
          <Autocomplete
            // disablePortal
            size="small"
            disabled={loadingLocation ? true : false}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={endLocationChange}
            value={formik.values.endLocation}
            options={locations !== undefined ? locations : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="To"
                name="endLocation"
                error={
                  formik.errors.endLocation !== undefined
                    ? formik.errors.endLocation.id
                    : ""
                }
                touched={
                  formik.touched.endLocation !== undefined
                    ? formik.touched.endLocation
                    : ""
                }
                {...params}
                onBlur={formik.handleBlur}
                className="fullwidth-input-task"
                required
              />
            )}
          />
          <Autocomplete
            size="small"
            disabled={loadingVehicle ? true : false}
            id="combo-box-demo-channel"
            isOptionEqualToValue={useCallback(
              (option, value) => option.value === value.value
            )}
            onChange={vehicleChange}
            value={formik.values.vehicle}
            options={vehicles !== undefined ? vehicles : []}
            onOpen={formik.handleBlur}
            renderInput={(params) => (
              <Input
                label="Vehicle"
                name="vehicle"
                error={
                  formik.errors.vehicle !== undefined
                    ? formik.errors.vehicle.id
                    : ""
                }
                touched={
                  formik.touched.vehicle !== undefined
                    ? formik.touched.vehicle
                    : ""
                }
                {...params}
                onBlur={formik.handleBlur}
                className="fullwidth-input-task"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Fare"
            name="fare"
            error={formik.errors.fare}
            touched={formik.touched.fare}
            value={formik.values.fare}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="fullwidth-input-task"
            required
          />
          <Input
            label="Remarks"
            name="remarks"
            error={formik.errors.remarks}
            touched={formik.touched.remarks}
            value={formik.values.remarks}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            multiline
            className="fullwidth-input-task"
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Button
              endIcon={
                loadingCreate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <ControlPointIcon />
                )
              }
              type="submit"
              color="success"
              size="large"
              text="Add"
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withRouter(AddTransport);

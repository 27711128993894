import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateBatch } from "../../redux/actions/BatchActions";
import { useDispatch } from "react-redux";

const statusItems = [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
  { id: "3", title: "Pause" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  productId: "",
  batchId: "",
  batchNo: "",
  batchProductionDate: [date.getFullYear(), mnth, day].join("-"),
  batchExpiryDate: [date.getFullYear(), mnth, day].join("-"),
  batchCertificateNo: "",
  batchTestDate: [date.getFullYear(), mnth, day].join("-"),
};

const EditBatchForStockin = ({
  data,
  newData,
  setData,
  openPopup,
  setOpenPopup,
  productId,
  batchId,
  handleBatchDel,
}) => {
  const dispatch = useDispatch();
  const batchUpdate = useSelector((state) => state.batchUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = batchUpdate;
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      const filteredProduct = data.items.filter((singleData) => {
        return singleData.productId === values.productId;
      });

      let sidBatches = filteredProduct[0].sidBatches;

      let updatedBatch = {
        batchId: sidBatches.length,
        batchNo: values.batchNo,
        batchProductionDate: values.batchProductionDate,
        batchExpiryDate: values.batchExpiryDate,
        batchCertificateNo: values.batchCertificateNo,
        batchTestDate: values.batchTestDate,
        batchTestReport: 1,
      };

      var newBatches = sidBatches.map(function (sitems, index) {
        for (var key in sitems) {
          if (sitems["batchId"] === values.batchId) {
            console.log("updatedBatch.batchProductionDate");
            console.log(updatedBatch.batchProductionDate);
            sitems["batchId"] = updatedBatch.batchId;
            sitems["batchNo"] = updatedBatch.batchNo;
            sitems["batchProductionDate"] = updatedBatch.batchProductionDate;
            sitems["batchExpiryDate"] = updatedBatch.batchExpiryDate;
            sitems["batchCertificateNo"] = updatedBatch.batchCertificateNo;
            sitems["batchTestDate"] = updatedBatch.batchTestDate;
            sitems["batchTestReport"] = updatedBatch.batchTestReport;
          }
        }
        return sitems;
      });

      var items = data.items.slice();
      var newItems = items.map(function (sitems) {
        for (var key in sitems) {
          if (sitems["productId"] === values.productId) {
            sitems["sidBatches"] = newBatches;
          }
        }
        return sitems;
      });

      newData(newItems);
      setOpenPopup({
        ...openPopup,
        isOpen: false,
      });
    },
  });

  const changeValues = () => {
    let filteredProduct = data.items.filter((singleData) => {
      return singleData.productId === productId;
    });

    let sidBatches = filteredProduct[0].sidBatches;

    let filteredSidBatches = sidBatches.filter((singleData) => {
      return singleData.batchId === batchId;
    });

    if (filteredSidBatches.length > 0) {
      var batchProductionDate = new Date(
          filteredSidBatches[0].batchProductionDate
        ),
        mnth = ("0" + (batchProductionDate.getMonth() + 1)).slice(-2),
        day = ("0" + batchProductionDate.getDate()).slice(-2);
      const batchProductionDateD = [
        batchProductionDate.getFullYear(),
        mnth,
        day,
      ].join("-");
      var batchExpiryDate = new Date(filteredSidBatches[0].batchExpiryDate),
        mnth = ("0" + (batchExpiryDate.getMonth() + 1)).slice(-2),
        day = ("0" + batchExpiryDate.getDate()).slice(-2);
      const batchExpiryDateD = [batchExpiryDate.getFullYear(), mnth, day].join(
        "-"
      );

      var batchTestDate = new Date(filteredSidBatches[0].batchTestDate),
        mnth = ("0" + (batchTestDate.getMonth() + 1)).slice(-2),
        day = ("0" + batchTestDate.getDate()).slice(-2);
      const batchTestDateD = [batchTestDate.getFullYear(), mnth, day].join("-");
      formik.setFieldValue("productId", productId);
      formik.setFieldValue("batchId", filteredSidBatches[0].batchId);
      formik.setFieldValue("batchNo", filteredSidBatches[0].batchNo);
      formik.setFieldValue("batchProductionDate", batchProductionDateD);
      formik.setFieldValue("batchExpiryDate", batchExpiryDateD);
      formik.setFieldValue(
        "batchCertificateNo",
        filteredSidBatches[0].batchCertificateNo
      );
      formik.setFieldValue("batchTestDate", batchTestDateD);
      formik.setFieldValue(
        "batchTestReport",
        filteredSidBatches[0].batchTestReport
      );
    }
  };

  useEffect(() => {
    changeValues();
  }, [data, productId, batchId]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Input
            label="Batch No"
            name="batchNo"
            error={formik.errors.batchNo}
            touched={formik.touched.batchNo}
            value={formik.values.batchNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Datepicker
            label="batchProductionDate"
            name="batchProductionDate"
            value={formik.values.batchProductionDate}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Datepicker
            label="batchExpiryDate"
            name="batchExpiryDate"
            value={formik.values.batchExpiryDate}
            onChange={formik.handleChange}
          />
          <Input
            label="Batch Certificate No"
            name="batchCertificateNo"
            error={formik.errors.batchCertificateNo}
            touched={formik.touched.batchCertificateNo}
            value={formik.values.batchCertificateNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Datepicker
            label="batchTestDate"
            name="batchTestDate"
            value={formik.values.batchTestDate}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Button
              style={{ marginRight: "0.5rem" }}
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <EditOutlinedIcon />
                )
              }
              type="submit"
              color="primary"
              size="large"
              text="Update"
            />

            <Button
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <DeleteIcon />
                )
              }
              type="button"
              color="error"
              size="large"
              text="Delete"
              onClick={() => {
                handleBatchDel(productId, batchId);
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withRouter(EditBatchForStockin);

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

//Function 
export const FUNCTION_LIST_REQUEST = "FUNCTION_LIST_REQUEST";
export const FUNCTION_LIST_SUCCESS = "FUNCTION_LIST_SUCCESS";
export const FUNCTION_LIST_FAIL = "FUNCTION_LIST_FAIL";
//New constant related function Add
export const FUNCTION_CREATE_REQUEST = "FUNCTION_CREATE_REQUEST";
export const FUNCTION_CREATE_SUCCESS = "FUNCTION_CREATE_SUCCESS";
export const FUNCTION_CREATE_FAIL = "FUNCTION_CREATE_FAIL";
export const FUNCTION_CREATE_RESET = "FUNCTION_CREATE_RESET";

export const FUNCTION_UPDATE_REQUEST = "FUNCTION_UPDATE_REQUEST";
export const FUNCTION_UPDATE_SUCCESS = "FUNCTION_UPDATE_SUCCESS";
export const FUNCTION_UPDATE_FAIL = "FUNCTION_UPDATE_FAIL";
export const FUNCTION_UPDATE_RESET = "FUNCTION_UPDATE_RESET";

export const FUNCTION_DELETE_REQUEST = "FUNCTION_DELETE_REQUEST";
export const FUNCTION_DELETE_SUCCESS = "FUNCTION_DELETE_SUCCESS";
export const FUNCTION_DELETE_FAIL = "FUNCTION_DELETE_FAIL";
export const FUNCTION_DELETE_RESET = "FUNCTION_DELETE_RESET";

export const FUNCTION_LIST_DETAILS_REQUEST = "FUNCTION_LIST_DETAILS_REQUEST";
export const FUNCTION_LIST_DETAILS_SUCCESS = "FUNCTION_LIST_DETAILS_SUCCESS";
export const FUNCTION_LIST_DETAILS_FAIL = "FUNCTION_LIST_DETAILS_FAIL";
export const FUNCTION_LIST_DETAILS_RESET = "FUNCTION_LIST_DETAILS_RESET";


//Close
export const COUNTRY_ORIGIN_LIST_REQUEST = "COUNTRY_ORIGIN_LIST_REQUEST";
export const COUNTRY_ORIGIN_LIST_SUCCESS = "COUNTRY_ORIGIN_LIST_SUCCESS";
export const COUNTRY_ORIGIN_LIST_FAIL = "COUNTRY_ORIGIN_LIST_FAIL";

export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL";

export const GROUP_LIST_REQUEST = "GROUP_LIST_REQUEST";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_FAIL = "GROUP_LIST_FAIL";

export const MAPPED_PRODUCT_LIST_REQUEST = "MAPPED_PRODUCT_LIST_REQUEST";
export const MAPPED_PRODUCT_LIST_SUCCESS = "MAPPED_PRODUCT_LIST_SUCCESS";
export const MAPPED_PRODUCT_LIST_FAIL = "MAPPED_PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_MAP_REQUEST = "PRODUCT_MAP_REQUEST";
export const PRODUCT_MAP_SUCCESS = "PRODUCT_MAP_SUCCESS";
export const PRODUCT_MAP_FAIL = "PRODUCT_MAP_FAIL";
export const PRODUCT_MAP_RESET = "PRODUCT_MAP_RESET";

export const PRODUCT_PRICE_BY_ID_REQUEST = "PRODUCT_PRICE_BY_ID_REQUEST";
export const PRODUCT_PRICE_BY_ID_SUCCESS = "PRODUCT_PRICE_BY_ID_SUCCESS";
export const PRODUCT_PRICE_BY_ID_FAIL = "PRODUCT_PRICE_BY_ID_FAIL";
export const PRODUCT_PRICE_BY_ID_RESET = "PRODUCT_PRICE_BY_ID_RESET";

export const PRODUCT_PRICE_CREATE_REQUEST = "PRODUCT_PRICE_CREATE_REQUEST";
export const PRODUCT_PRICE_CREATE_SUCCESS = "PRODUCT_PRICE_CREATE_SUCCESS";
export const PRODUCT_PRICE_CREATE_FAIL = "PRODUCT_PRICE_CREATE_FAIL";
export const PRODUCT_PRICE_CREATE_RESET = "PRODUCT_PRICE_CREATE_RESET";

export const PRODUCT_PRICE_LIST_REQUEST = "PRODUCT_PRICE_LIST_REQUEST";
export const PRODUCT_PRICE_LIST_SUCCESS = "PRODUCT_PRICE_LIST_SUCCESS";
export const PRODUCT_PRICE_LIST_FAIL = "PRODUCT_PRICE_LIST_FAIL";

export const PRODUCT_PRODUCTS_TOP_REQUEST = "PRODUCT_PRODUCTS_TOP_REQUEST";
export const PRODUCT_PRODUCTS_TOP_SUCCESS = "PRODUCT_PRODUCTS_TOP_SUCCESS";
export const PRODUCT_PRODUCTS_TOP_FAIL = "PRODUCT_PRODUCTS_TOP_FAIL";

export const CATEGORYBASEDPRODUCT_DETAILS_REQUEST =
  "CATEGORYBASEDPRODUCT_DETAILS_REQUEST";
export const CATEGORYBASEDPRODUCT_DETAILS_SUCCESS =
  "CATEGORYBASEDPRODUCT_DETAILS_SUCCESS";
export const CATEGORYBASEDPRODUCT_DETAILS_FAIL =
  "CATEGORYBASEDPRODUCT_DETAILS_FAIL";
export const CATEGORYBASEDPRODUCT_DETAILS_RESET =
  "CATEGORYBASEDPRODUCT_DETAILS_RESET";

export const SAMPLEPRODUCT_UPDATE_REQUEST = "SAMPLEPRODUCT_UPDATE_REQUEST";
export const SAMPLEPRODUCT_UPDATE_SUCCESS = "SAMPLEPRODUCT_UPDATE_SUCCESS";
export const SAMPLEPRODUCT_UPDATE_FAIL = "SAMPLEPRODUCT_UPDATE_FAIL";
export const SAMPLEPRODUCT_UPDATE_RESET = "SAMPLEPRODUCT_UPDATE_RESET";

export const PRICE_DETAILS_REQUEST = "PRICE_DETAILS_REQUEST";
export const PRICE_DETAILS_SUCCESS = "PRICE_DETAILS_SUCCESS";
export const PRICE_DETAILS_FAIL = "PRICE_DETAILS_FAIL";
export const PRICE_DETAILS_RESET = "PRICE_DETAILS_RESET";

export const MAPPED_PRODUCTS_REQUEST = "MAPPED_PRODUCTS_REQUEST";
export const MAPPED_PRODUCTS_SUCCESS = "MAPPED_PRODUCTS_SUCCESS";
export const MAPPED_PRODUCTS_FAIL = "MAPPED_PRODUCTS_FAIL";

export const PRODUCT_FUNCTION_REQUEST="PRODUCT_FUNCTION_REQUEST";
export const PRODUCT_FUNCTION_SUCCESS="PRODUCT_FUNCTION_SUCCESS";
export const PRODUCT_FUNCTION_FAIL="PRODUCT_FUNCTION_FAIL";

import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Button from "./controls/Button";
import ActionButton from "./controls/ActionButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const useStyles = makeStyles(() => ({
  dialogWrapper: {
    padding: `${useTheme().spacing(2)} !important`,
    position: "absolute !important",
    top: useTheme().spacing(2),
    width: "100%",
    padding: "30px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  dialogContent: {
    padding: "0px !important",
    border: "none !important",
  },
}));

const FormPopup = (props) => {
  const { title, children, openPopup, setOpenPopup } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup.isOpen}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <ActionButton
            color="error"
            onClick={() => {
              setOpenPopup({
                ...openPopup,
                title: "",
                subTitle: "",
                isOpen: false,
              });
            }}
          >
            <CloseOutlined />
          </ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers classes={{ root: classes.dialogContent }}>
        <div>{children}</div>
      </DialogContent>
    </Dialog>
  );
};

export default FormPopup;

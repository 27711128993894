import React, { useState } from "react";
// import "../../Reports/PendingAndCancelReport/PendingAndCancelReport.css";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { Tooltip } from "react-tooltip";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
import { PrevNextButton, PageButton } from "../../../shared/Button";
import { classNames } from "../../../utils/Utils";
import { SortDownIcon, SortIcon, SortUpIcon } from "../../../shared/Icons";
import { CSVLink } from "react-csv";

import { Button as MuiButton } from "@mui/material";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CircularProgress from "@mui/material/CircularProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PDFGenerator from "../../../core/PDFGenerator";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">Search: </span>
      <input
        type="text"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700 w-full">{render("Header")}: </span>
      <select
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("delivered") ? "bg-green-100 text-green-700" : null,
        status.startsWith("at_delivery")
          ? "bg-yellow-100 text-yellow-700"
          : null,
        status.startsWith("at_invoicing") ? "bg-red-100 text-red-700" : null,
        status.startsWith("at_assign") ? "bg-green-100 text-green-700" : null,
        status.startsWith("at_packing") ? "bg-yellow-100 text-yellow-700" : null
      )}
    >
      {status}
    </span>
  );
}

export default function Table({ pdfShow, columns, data, classes, loading }) {
  // Use the state and functions returned from useTable to build your UI
  const [myTooltipClick, setMyTooltipClick] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Contains the column headers and table data in the required format for CSV
  const csvData = [
    [
      "Product Id",
      "Product Category",
      "Product Name",
      "Current Quantity",
      "Channel Name",
    ],
    ...data.map(
      ({
        productId,
        productCategoryName,
        productName,
        currentQty,
        channelName,
        
        
      }) => [
        productId,
        productCategoryName,
        productName,
        currentQty,
        channelName,
       
      ]
    ),
  ];

  const statusFill = (value) => {
    const status = value ? value.toLowerCase() : "unknown";

    return (
      <span
        className={classNames(
          "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
          status.startsWith("at_escalation")
            ? "bg-green-100 text-green-700"
            : null,
          status.startsWith("at_delivery")
            ? "bg-yellow-100 text-yellow-700"
            : null,
          status.startsWith("at_invoicing") ? "bg-red-100 text-red-700" : null,
          status.startsWith("at_assign") ? "bg-green-100 text-green-700" : null,
          status.startsWith("at_packing")
            ? "bg-yellow-100 text-yellow-700"
            : null
        )}
      >
        {status}
      </span>
    );
  };

  return (
    <>
      <div className="flex gap-x-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div key={column.id}>
                {/* <label for={column.id}>{column.render("Header")}: </label> */}
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}

        {/* Export csv Button Start */}
        <CSVLink
          className="downloadbtn"
          filename="Current_Stock_Report.csv"
          data={csvData}
        >
          <MuiButton
            className={classes.submitButtonGroup}
            startIcon={
              loading ? (
                <CircularProgress size="1rem" color="error" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              )
            }
            size="large"
            text="Export CSV"
          >
            <img
              width={40}
              height={40}
              src={process.env.PUBLIC_URL + "/images/excel-icon.png"}
              alt=""
            />
          </MuiButton>
        </CSVLink>
        {/* Export csv Button End */}
        {!pdfShow ? (
          <></>
        ) : (
          <PDFGenerator
            columns={columns}
            data={data}
            classes={classes}
            loading={loading}
            pdfTitle={"Current Stock Report"}
          />
        )}
        {/* Export pdf Button Start */}

        {/* Export pdf Button End */}

        {/* Export pdf Button End */}
      </div>

      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortDownIcon className="w-4 h-4 text-gray-400" />
                              ) : (
                                <SortUpIcon className="w-4 h-4 text-gray-400" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row, i) => {
                    {
                    }
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, i) => {
                          if (cell.column.Header === "Invoice Number") {
                            {
                            }
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 whitespace-nowrap"
                              >
                                <a
                                  data-tooltip-id={cell.value}
                                  data-tooltip-html={`Customer Name: ${
                                    row.values.customerName !== null
                                      ? row.values.customerName
                                      : "NOT PROVIDED"
                                  }<br />Pending Status: ${
                                    row.values.soSystemPending
                                  } <br />Invoice Number: ${
                                    row.values.soSystemNo
                                  }`}
                                  // data-tooltip-id={cell.value}
                                  // id={`${cell.value}`}
                                  className={`tw-cursor-pointer`}
                                  // onClick={() => {
                                  //   setMyTooltipClick(cell.value);
                                  // }}
                                >
                                  {cell.render("Cell")}
                                </a>
                                <Tooltip
                                  id={`${cell.value}`}
                                  openOnClick={["click"]}
                                  style={{
                                    background: "rgba(0, 0, 0, 0.8)",
                                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                                    backdropFilter: "blur(5px)",
                                    WebkitBackdropFilter: "blur(5px)",
                                  }}
                                />
                              </td>
                            );
                          } else {
                            {
                            }
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 whitespace-nowrap"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <PrevNextButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </PrevNextButton>
          <PrevNextButton onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </PrevNextButton>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <label>
            <span className="sr-only">Items Per Page</span>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              value={state.pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </label>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
      {/* <Tooltip anchorSelect=".my-anchor-element" place="top">
        Hello world!
      </Tooltip> */}
    </>
  );
}

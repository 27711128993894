import React, { useCallback, useRef, useState } from "react";
import Decoder from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import PageHeader from "../../../core/PageHeader";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
  ButtonGroup,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";

import Menu from "../../../core/Menu";
import Button from "../../../core/controls/Button";
import { useEffect } from "react";
import { API_URL } from "../../../redux/constants/apiConstants";
import ListAltIcon from "@mui/icons-material/ListAlt";
import axios from "axios";
import { listDailyInvoiceTrackingReport } from "../../../redux/actions/dailyInvoiceTrackingActions";
import { useTable } from "react-table";
import { Table as T2 } from "react-bootstrap";
import Table, { SelectColumnFilter, StatusPill } from "./Table";
import { Col, Form, Row } from "react-bootstrap";
import Datepicker from "../../../core/controls/Datepicker";
import Input from "../../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { listSalesOrderInternalDetails } from "../../../redux/actions/invoiceActions";

import "./index.css";
// import "../../Customers/Customer.css";
import { Modal, Button as AntButton, Image } from "antd";
import Draggable from "react-draggable";
import {
  getChannelsList,
  getCurrentStock,
  getProductsList,
  getReferencesList,
  getStockAdjustment,
} from "../../../redux/actions/CurrentStockReportActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: `${useTheme().spacing(2)} ${useTheme().spacing(1)}`,
    [useTheme().breakpoints.up("md")]: {
      padding: `${useTheme().spacing(2)} ${useTheme().spacing(7)}`,
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
  pageContent2: {
    overflowX: "auto",
    justifyContent: "space-between",
    margin: useTheme().spacing(1),
    padding: `${useTheme().spacing(2)} ${useTheme().spacing(1)}`,
    [useTheme().breakpoints.up("md")]: {
      padding: `${useTheme().spacing(2)} ${useTheme().spacing(7)}`,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-root": {
      alignItems: "center",
    },
  },
}));

const dateConverter = (date) => {
  var d = new Date(date);
  d = d.toString();
  d = d.split(" ");
  //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //   day = ("0" + date.getDate()).slice(-2);
  d = [d[2], d[1], d[3]].join("-");
  return d;
};

function humanReadableDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  return (
    dateTime.toLocaleDateString("en-US", dateOptions) +
    " at " +
    dateTime.toLocaleTimeString("en-US", timeOptions)
  );
}

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  // startDate: [date.getFullYear(), mnth, day].join("-"),
  // endDate: [date.getFullYear(), mnth, day].join("-"),
  referenceName: {
    id: "",
    label: "",
  },
  channelName: {
    id: "",
    label: "",
  },
};

const StockAdjustmentReport = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const dailyInvoiceTrackingReportList = useSelector(
    (state) => state.dailyInvoiceTrackingReportList
  );

  const {
    loading: loadingDailyInvoiceTrackingReport,
    error: errorDailyInvoiceTrackingReport,
    dailyInvoiceTrackingReports,
  } = dailyInvoiceTrackingReportList;

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

  const ReferenceNameList = useSelector((state) => state.ReferenceNameList);
  const {
    loading: loadingReferencesName,
    error: errorReferencesName,
    referenceList,
  } = ReferenceNameList;
  const getChannelsNameList = useSelector((state) => state.getChannelNameList);
  const {
    loading: loadingChannelsName,
    error: errorChannelsName,
    channelNameList,
  } = getChannelsNameList;

  const getCurrentStockAdjustment = useSelector(
    (state) => state.getCurrentStockAdjustment
  );
  const {
    loading: loadingCurrentStock,
    error: errorCurrentStock,
    currentStockAdjustmentList,
  } = getCurrentStockAdjustment;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [data, setData] = useState([]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      // alert("clicked");
      // console.log(values);
      let data = {
        // startDate: values.startDate,
        // endDate: values.endDate,

        referenceNameId: values.reference.id !== "" ? values.reference.id : 0,
      };

      dispatch(getStockAdjustment(data)).then((res) => {
        // console.log("res");
        // console.log(res);
      });
    },
  });

  const productNameChange = useCallback((e, v) => {
    formik.setFieldValue("reference", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });
  const channelNameChange = useCallback((e, v) => {
    formik.setFieldValue("channelName", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      dispatch(
        listDailyInvoiceTrackingReport({
          startDate: Date.now(),
          endDate: Date.now(),
          customerID: 0,
        })
      );
      dispatch(listCustomersForSignup());
      dispatch(getReferencesList());
      dispatch(getChannelsList());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/report/daily-invoice-tracking" } },
      };
      history.push(location);
    }
  }, [dispatch, classes, history, userInfo]);

  const getOldPrice = (price, vat, ait, discount) => {
    var lineTotalVat = price * (vat / 100);
    var lineTotalAit = price * (ait / 100);

    var final = price - (lineTotalVat + lineTotalAit + discount);
    return final;
  };

  const closeModal = (event) => {
    setIsOpen(false);
  };

  const columns = [
    {
      Header: "Id",
      accessor: "sadAutoId",
    },
    {
      accessor: "productName",
      Header: "PRODUCT NAME",
      filter: "includes",
      Filter: SelectColumnFilter,
    },
    {
      accessor: "sadProductQty",
      Header: "PRODUCT QUANTITY",
    },
    {
      id: "sadIsIncreased",
      accessor: (d) => {
        return d.sadIsIncreased ? "Yes" : "No";
      },
      Header: "INCREASED?",
    },
  ];

  // Testing ant dragable modal

  const [state, setState] = useState({
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    showFooter: false,
  });

  // const draggleRef = React.createRef();

  const draggleRef = useRef(null);

  const showModal = () => {
    setState({
      visible: true,
    });
    // dispatch(listSalesOrderInternalDetails(id)).then((res) => {
    //   setIsOpen(true);
    // });
  };

  const handleOk = (e) => {
    setState({
      visible: false,
    });
  };

  const handleCancel = (e) => {
    setState({
      visible: false,
    });
  };

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setState({
      bounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      },
    });
  };

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();

    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  // const getOldPrice = (price, vat, ait, discount) => {
  //   var lineTotalVat = price * (vat / 100);
  //   var lineTotalAit = price * (ait / 100);

  //   var final = price - (lineTotalVat + lineTotalAit + discount);
  //   return final;
  // };

  const getVatAmount = (price, vat) => {
    var lineTotalVat = price * (vat / 100);

    return lineTotalVat.toFixed(2);
  };

  const getAitAmount = (price, ait) => {
    var lineTotalAit = price * (ait / 100);
    return lineTotalAit.toFixed(2);
  };

  const getTotalPrice = (arr, vat, ait, discount, adjustment) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodFinalCalculatedUnitPrice;
    });
    sum =
      parseFloat(sum) +
      parseFloat(vat) +
      parseFloat(ait) -
      (parseFloat(discount) + parseFloat(adjustment));

    return sum.toFixed(2);
  };

  const getTotalQuantity = (arr) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodQty;
    });

    return parseFloat(sum).toFixed(2);
  };

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AssessmentIcon />}
        title="Stock Adjustment Report"
        subtitle="See recent stock adjustments"
      />

      <Paper className={`${classes.pageContent2} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid sx={{ mr: 2 }} item xs={12} md={3}>
              <Autocomplete
                size="small"
                disabled={loadingReferencesName ? true : false}
                id="combo-box-demo-channel"
                // isOptionEqualToValue={isOptionEqualToValue}
                onChange={productNameChange}
                value={formik.values.reference}
                options={referenceList !== undefined ? referenceList : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Reference"
                    name="reference"
                    error={
                      formik.errors.reference !== undefined
                        ? formik.errors.reference.id
                        : ""
                    }
                    touched={
                      formik.touched.reference !== undefined
                        ? formik.touched.reference
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <ButtonGroup className={classes.submitButtonGroup}>
                <MuiButton
                  startIcon={
                    loadingCurrentStock ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <SearchIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Search
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                  }}
                />
              </ButtonGroup>
            </Grid>
          </Grid>
        </Form>
      </Paper>

      {/* Table Paper */}
      <Paper className={classes.pageContent}>
        <div className="min-h-screen text-gray-900">
          <div className="mt-4">
            <Table
              columns={columns}
              data={
                currentStockAdjustmentList === undefined
                  ? []
                  : currentStockAdjustmentList
              }
              classes={classes}
              loading={loadingCurrentStock}
              pdfShow={true}
            />
          </div>
        </div>
      </Paper>

      {/* Draggable Modal */}
      <Modal
        mask={false}
        maskClosable={false}
        keyboard={false}
        wrapClassName="aaa"
        width={500}
        style={{
          position: "fixed",
          // transform: 'translateX(-50%)',
          left: (document.body.clientWidth - 500) / 2,
        }}
        // zIndex={-1}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (state.disabled) {
                setState({
                  disabled: false,
                });
              }
            }}
            onMouseOut={() => {
              this.setState({
                disabled: true,
              });
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            <div
              style={{
                width: "auto",
                overflowX: "scroll",
              }}
              className="row-scrollers"
            ></div>
          </div>
        }
        footer={
          <AntButton key="back" onClick={() => handleCancel()}>
            Close
          </AntButton>
        }
        visible={state.visible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        modalRender={(modal) => (
          <Draggable
            disabled={state.disabled}
            bounds={state.bounds}
            onStart={(event, uiData) => () => onStart(event, uiData)}
          >
            <div aa="2" ref={draggleRef}>
              {modal}
            </div>
          </Draggable>
        )}
      >
        {JSON.stringify(salesorderinternalsingle) !== "{}" &&
          salesorderinternalsingle !== undefined && (
            <div
              id="invoiceCapture"
              className=" pt-1 "
              style={{ position: "relative" }}
            >
              <div className="p-4">
                <div
                  style={{
                    width: "auto",
                    overflowX: "scroll",
                  }}
                  className="row-scrollers"
                >
                  <T2 className="mb-0" style={{ border: "1px solid #e3e3e3" }}>
                    <thead>
                      {salesorderinternalsingle?.stockOutGeneralData
                        ?.jobTypeName === "Sample_Issue" ||
                      salesorderinternalsingle?.stockOutGeneralData
                        ?.jobTypeName === "Warehouse_Transfer" ? (
                        <tr>
                          <th>NAME</th>

                          <th className="text-center">QTY</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>PRODUCT NAME</th>

                          <th className="text-center">QTY</th>
                          <th className="text-center">PRICE/KG</th>
                          <th className="text-center">AMOUNT</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {salesorderinternalsingle?.stockOutItemsData.map(
                        (item, i) => {
                          return (
                            <>
                              {salesorderinternalsingle?.stockOutGeneralData
                                ?.jobTypeName === "Sample_Issue" ||
                              salesorderinternalsingle?.stockOutGeneralData
                                ?.jobTypeName === "Warehouse_Transfer" ? (
                                <tr id={i} key={i} style={{ height: "20px" }}>
                                  <td>{item.productName}</td>

                                  <td className="text-center">{item.sodQty}</td>
                                </tr>
                              ) : (
                                <tr id={i} key={i} style={{ height: "20px" }}>
                                  <td>{item.productName}</td>

                                  <td className="text-center">{item.sodQty}</td>
                                  <td className="text-center">
                                    {item.sodUnitPrice}
                                  </td>

                                  <td
                                    className="text-center"
                                    style={{ width: "100px" }}
                                  >
                                    {
                                      salesorderinternalsingle.stockOutBillData
                                        .sobCurrencyName
                                    }{" "}
                                    {item.sodFinalCalculatedUnitPrice}
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </T2>
                </div>
                {salesorderinternalsingle?.stockOutGeneralData?.jobTypeName ===
                  "Sample_Issue" ||
                salesorderinternalsingle?.stockOutGeneralData?.jobTypeName ===
                  "Warehouse_Transfer" ? (
                  <T2
                    style={{ marginTop: "2rem", border: "1px solid #e3e3e3" }}
                  >
                    <tbody>
                      <tr className="text-end">
                        <td></td>
                        <td className="fw-bold" style={{ width: "100px" }}>
                          Quantity
                        </td>
                        <td className="fw-bold" style={{ width: "100px" }}>
                          {getTotalQuantity(
                            salesorderinternalsingle?.stockOutItemsData
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </T2>
                ) : (
                  <T2
                    style={{ marginTop: "2rem", border: "1px solid #e3e3e3" }}
                  >
                    <tbody>
                      <tr className="text-end">
                        <td></td>
                        <td className="fw-bold" style={{ width: "100px" }}>
                          SUBTOTAL
                        </td>
                        <td className="text-end" style={{ width: "100px" }}>
                          {
                            salesorderinternalsingle.stockOutBillData
                              .sobCurrencyName
                          }{" "}
                          {
                            salesorderinternalsingle?.stockOutBillData
                              .calculatedBill
                          }
                        </td>
                      </tr>
                      {salesorderinternalsingle?.stockOutBillData
                        .billVatPercantage != 0 && (
                        <tr className="text-end">
                          <td></td>
                          <td className="fw-bold" style={{ width: "100px" }}>
                            VAT
                          </td>
                          <td className="text-end" style={{ width: "150px" }}>
                            {
                              salesorderinternalsingle?.stockOutBillData
                                .billVatPercantage
                            }
                            {"%"} {" ~ "}{" "}
                            {
                              salesorderinternalsingle.stockOutBillData
                                .sobCurrencyName
                            }{" "}
                            {getVatAmount(
                              salesorderinternalsingle.stockOutBillData
                                .calculatedBill,
                              salesorderinternalsingle.stockOutBillData
                                .billVatPercantage
                            )}
                          </td>
                        </tr>
                      )}
                      {salesorderinternalsingle?.stockOutBillData
                        .billAitPercantage != 0 && (
                        <tr className="text-end">
                          <td></td>
                          <td className="fw-bold" style={{ width: "100px" }}>
                            AIT
                          </td>
                          <td className="text-end" style={{ width: "100px" }}>
                            {
                              salesorderinternalsingle?.stockOutBillData
                                .billAitPercantage
                            }
                            {"%"} {" ~ "}{" "}
                            {
                              salesorderinternalsingle.stockOutBillData
                                .sobCurrencyName
                            }{" "}
                            {getAitAmount(
                              salesorderinternalsingle?.stockOutBillData
                                .calculatedBill,
                              salesorderinternalsingle?.stockOutBillData
                                .billAitPercantage
                            )}
                          </td>
                        </tr>
                      )}
                      {salesorderinternalsingle?.stockOutBillData
                        .billDiscountAmount != 0 && (
                        <tr className="text-end">
                          <td></td>
                          <td className="fw-bold" style={{ width: "100px" }}>
                            Discount
                          </td>
                          <td className="text-end" style={{ width: "100px" }}>
                            {
                              salesorderinternalsingle.stockOutBillData
                                .sobCurrencyName
                            }{" "}
                            {parseFloat(
                              salesorderinternalsingle.stockOutBillData
                                .billDiscountAmount
                            ).toFixed(2)}
                          </td>
                        </tr>
                      )}
                      {salesorderinternalsingle?.stockOutBillData
                        .billAdjustmentAmount != 0 && (
                        <tr className="text-end">
                          <td></td>
                          <td className="fw-bold">Adjustment</td>
                          <td className="text-end" style={{ width: "100px" }}>
                            {
                              salesorderinternalsingle.stockOutBillData
                                .sobCurrencyName
                            }{" "}
                            {parseFloat(
                              salesorderinternalsingle.stockOutBillData
                                .billAdjustmentAmount
                            ).toFixed(2)}
                          </td>
                        </tr>
                      )}
                      <tr className="text-end">
                        <td></td>
                        <td className="fw-bold" style={{ width: "100px" }}>
                          TOTAL
                        </td>
                        <td className="text-end" style={{ width: "100px" }}>
                          {
                            salesorderinternalsingle.stockOutBillData
                              .sobCurrencyName
                          }{" "}
                          {getTotalPrice(
                            salesorderinternalsingle?.stockOutItemsData,
                            getVatAmount(
                              salesorderinternalsingle?.stockOutBillData
                                .calculatedBill,
                              salesorderinternalsingle?.stockOutBillData
                                .billVatPercantage
                            ),
                            getAitAmount(
                              salesorderinternalsingle?.stockOutBillData
                                .calculatedBill,
                              salesorderinternalsingle?.stockOutBillData
                                .billAitPercantage
                            ),
                            salesorderinternalsingle?.stockOutBillData
                              .billDiscountAmount,
                            salesorderinternalsingle?.stockOutBillData
                              .billAdjustmentAmount
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </T2>
                )}

                {salesorderinternalsingle.notes && (
                  <div className="bg-light py-3 px-4 rounded">
                    {salesorderinternalsingle.notes}
                  </div>
                )}
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
};

export default withRouter(StockAdjustmentReport);

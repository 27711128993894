import Axios from "axios";
import { API_URL } from "../constants/apiConstants";
import {
    BARCODE_CREATE_REQUEST,
    BARCODE_CREATE_SUCCESS,
    BARCODE_CREATE_FAIL,
  
    BARCODE_PRINT_LIST_SUCCESS,
    BARCODE_PRINT_LIST_REQUEST,
    BARCODE_PRINT_LIST_FAIL,

    BARCODE_REMOVE_SUCCESS,
    BARCODE_REMOVE_REQUEST,
    BARCODE_REMOVE_FAIL,

    REPACKING_BARCODE_CREATE_REQUEST,
    REPACKING_BARCODE_CREATE_SUCCESS,
    REPACKING_BARCODE_CREATE_FAIL,

    REPACKING_PRINT_LIST_SUCCESS,
    REPACKING_PRINT_LIST_REQUEST,
    REPACKING_PRINT_LIST_FAIL,

    REPACKING_BARCODE_REMOVE_SUCCESS,
    REPACKING_BARCODE_REMOVE_REQUEST,
    REPACKING_BARCODE_REMOVE_RESET,
    REPACKING_BARCODE_REMOVE_FAIL,

    BARCODE_STOCK_OUT_MAP_SUCCESS,
    BARCODE_STOCK_OUT_MAP_REQUEST,
    BARCODE_STOCK_OUT_MAP_FAIL,

    BARCODE_DECODE_SUCCESS,
    BARCODE_DECODE_REQUEST,
    BARCODE_DECODE_FAIL,
    UPDATE_DECODED_DATA,
    
    INSERT_SO_BARCODE_REQUEST,
    INSERT_SO_BARCODE_SUCCESS,
    INSERT_SO_BARCODE_FAIL,
    INSERT_SO_BARCODE_RESET,

    UPDATE_SO_BARCODE_REQUEST,
    UPDATE_SO_BARCODE_SUCCESS,
    UPDATE_SO_BARCODE_FAIL,
    UPDATE_SO_BARCODE_RESET,

    REMOVE_SO_BARCODE_REQUEST,
    REMOVE_SO_BARCODE_SUCCESS,
    REMOVE_SO_BARCODE_FAIL
  
}from "../constants/BarcodeConstants"

// export const createBarcodeInfos = (data, packNo) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: BARCODE_CREATE_REQUEST });

//     const {
//       userLogin: { userInfo },
//     } = getState();

//     const config = {
//       method: "post",
//       url: `${API_URL}/StockIn/CreateBarCode/${packNo}`, // Include packNo in the URL
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//         "Content-Type": "application/json",
//       },
//       data: JSON.stringify(data),
//     };

//     const response = await Axios(config);

//     dispatch({
//       type: BARCODE_CREATE_SUCCESS,
//       payload: response.data.dataObj,
//     });
//   } catch (error) {
//     dispatch({
//       type: BARCODE_CREATE_FAIL,
//       payload: error.response ? error.response.data.errMsg : error.message,
//     });
//   }
// };
export const createBarcdoeInfos = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: BARCODE_CREATE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      var config = {
        method: "post",
  
        url: `${API_URL}/StockIn/CreateBarCode`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
  
      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: BARCODE_CREATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: BARCODE_CREATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      return finalGotData;
    } catch (error) {
      dispatch({
        type: BARCODE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const listPrintBarcodeInfo =
  (datas) => async (dispatch, getState) => {
    try {
  
      dispatch({ type: BARCODE_PRINT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockIn/GetBarCodeInfoPrint/${datas.invoiceNo}/${datas.productId}/${datas.containerNo}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      dispatch({
        type: BARCODE_PRINT_LIST_SUCCESS,
        payload: data.dataObj,
      });
      return data;
    } catch (error) {
      dispatch({
        type: BARCODE_PRINT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const removeBarCodeDataByParamsAction = (invoice, productId, container) => async (dispatch, getState) => {
    try {
      dispatch({ type: BARCODE_REMOVE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      var config = {
        method: "get",
        url: `${API_URL}/StockIn/RemoveBarCodeDataByInvoiceProudctContainer/${invoice}/${productId}/${container}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await Axios(config);
      dispatch({ type: BARCODE_REMOVE_SUCCESS });
    } catch (error) {
      dispatch({
        type: BARCODE_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const createRepackingBarcdoeInfosAction = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: REPACKING_BARCODE_CREATE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        method: "post",
        url: `${API_URL}/StockIn/CreateRepackingChildBarCode`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
  
      const response = await Axios(config);
  
      dispatch({
        type: REPACKING_BARCODE_CREATE_SUCCESS,
        payload: response.data.dataObj,
      });
  
      return response.data;
  
    } catch (error) {
      dispatch({
        type: REPACKING_BARCODE_CREATE_FAIL,
        payload: error.response && error.response.data.errMsg
          ? error.response.data.errMsg
          : error.message,
      });
  
      return error.response ? error.response.data : { message: error.message };
    }
  };

  export const listRepackingPrintBarcodeInfoAction =
  (datas) => async (dispatch, getState) => {
    try {
  
      dispatch({ type: REPACKING_PRINT_LIST_REQUEST});

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/StockIn/GetRepackingBarCodeInfoPrint/${datas.baseBarcodeData}/${datas.productId}/${datas.childBarcodeData}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      dispatch({
        type: REPACKING_PRINT_LIST_SUCCESS,
        payload: data.dataObj,
      });
      return data;
    } catch (error) {
      dispatch({
        type: REPACKING_PRINT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  
  export const removeRepackingBarcodeAction = (childBarcodeData) => async (dispatch, getState) => {
    try {
      dispatch({ type: REPACKING_BARCODE_REMOVE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      var config = {
        method: "get",
        url: `${API_URL}/StockIn/RemoveRepackingByBarcodeByContainer/${childBarcodeData}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      await Axios(config);
  
      dispatch({ type: REPACKING_BARCODE_REMOVE_SUCCESS });
    } catch (error) {
      dispatch({
        type: REPACKING_BARCODE_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const packingListStockOutMapAction = (id) => async (dispatch, getState) => {

    try {
      dispatch({ type: BARCODE_STOCK_OUT_MAP_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      var config = {
        method: "get",
  
        url: `${API_URL}/StockOut/GetPackingDetailsForBarcodeMap/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await Axios(config);
  
      //dispatch({ type: FUNCTION_LIST_DETAILS_SUCCESS, payload: data.dataObj[0] });
      dispatch({ type: BARCODE_STOCK_OUT_MAP_SUCCESS, payload: data.dataObj });
    } catch (error) {
      dispatch({
        type: BARCODE_STOCK_OUT_MAP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const barcodeDecodeAction = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: BARCODE_DECODE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      var config = {
        method: "get",
        url: `${API_URL}/StockIn/GetDecodedBarCode/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      dispatch({ type: BARCODE_DECODE_SUCCESS, payload: data.dataObj });
    } catch (error) {
      console.error("Error decoding barcode:", error);
      dispatch({
        type: BARCODE_DECODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
//#################
// export const barcodeDecodeAction = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: BARCODE_DECODE_REQUEST });
//     const {
//       userLogin: { userInfo },
//     } = getState();
//     var config = {
//       method: 'get',
//       url: `${API_URL}/StockIn/GetDecodedBarCode/${id}`,
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };
//     const { data } = await Axios(config);
//     console.log("Action Decoded data received:", data); // Log the decoded data received from the API
//     dispatch({ type: BARCODE_DECODE_SUCCESS, payload: data.dataObj });
//   } catch (error) {
//     console.error("Action Error in barcodeDecodeAction:", error); // Log any errors that occur
//     dispatch({
//       type: BARCODE_DECODE_FAIL,
//       payload: error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message,
//     });
//   }
// };

  // export const barcodeDecodeAction = (id) => async (dispatch, getState) => {
  //   try {
  //     dispatch({ type: BARCODE_DECODE_REQUEST });
  //     const {
  //       userLogin: { userInfo },
  //     } = getState();
  //     var config = {
  //       method: 'get',
  //       url: `${API_URL}/StockIn/GetDecodedBarCode/${id}`,
  //       headers: {
  //         Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     };
  //     const { data } = await Axios(config);
  //     dispatch({ type: BARCODE_DECODE_SUCCESS, payload: data.dataObj });
  //   } catch (error) {
  //     dispatch({
  //       type: BARCODE_DECODE_FAIL,
  //       payload: error.response && error.response.data.message
  //         ? error.response.data.message
  //         : error.message,
  //     });
  //   }
  // };
  
//   export const barcodeDecodeAction = (barcodeInfo) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: BARCODE_DECODE_REQUEST });
//       const {
//         userLogin: { userInfo },
//       } = getState();
//       var config = {
//         method: "get",
//         url: `${API_URL}/StockIn/GetDecodedBarCode/${barcodeInfo}`, // Append barcodeInfo to URL
//         headers: {
//           Authorization: `Bearer ${userInfo.token}`,
//         },
//       };
//       const { data } = await Axios(config);
//       dispatch({ type: BARCODE_DECODE_SUCCESS, payload: data.dataObj });
//     } catch (error) {
//       dispatch({
//         type: BARCODE_DECODE_FAIL,
//         payload:
//           error.response && error.response.data.message
//             ? error.response.data.message
//             : error.message,
//       });
//     }
// };

  // Action creator for updating decoded data
  export const updateDecodedDataAction = (decodedData, index) => {
    return {
      type: UPDATE_DECODED_DATA,
      payload: { decodedData, index },
    };
  };

  export const insertStockOutBarcodeAction = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: INSERT_SO_BARCODE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      var config = {
        method: "post",
  
        url: `${API_URL}/StockOut/InsertStockOutBarCode`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
  
      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: INSERT_SO_BARCODE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: INSERT_SO_BARCODE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      return finalGotData;
    } catch (error) {
      dispatch({
        type: INSERT_SO_BARCODE_RESET,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const updateStockOutBarocdeAction = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_SO_BARCODE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      var config = {
        method: "put",
        url: `${API_URL}/StockOut/UpdateSoBarcodeInfo`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
  
      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: UPDATE_SO_BARCODE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: UPDATE_SO_BARCODE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      return finalGotData;
    } catch (error) {
      dispatch({
        type: UPDATE_SO_BARCODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  export const removeStockOutBarcodeAction = (id) => async (dispatch, getState) => {
    console.log("Dispatching removeStockOutBarcodeAction with id:", id);
    try {
      dispatch({ type: REMOVE_SO_BARCODE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      var config = {
        method: "delete",
  
        url: `${API_URL}/StockOut/RemoveSoBarCodeInfo/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await Axios(config);
      dispatch({ type: REMOVE_SO_BARCODE_SUCCESS });
      //
    } catch (error) {
      dispatch({
        type: REMOVE_SO_BARCODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
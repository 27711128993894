import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateBatch } from "../../redux/actions/BatchActions";
import { useDispatch } from "react-redux";

const statusItems = [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
  { id: "3", title: "Pause" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
const initialValues = {
  id: 0,
  status: "1",
  productionDate: [date.getFullYear(), mnth, day].join("-"),
  expiryDate: [date.getFullYear(), mnth, day].join("-"),
};

const EditBatch = ({ data, openPopup, setOpenPopup }) => {
  const dispatch = useDispatch();
  const batchUpdate = useSelector((state) => state.batchUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = batchUpdate;
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      console.log("hello");
      console.log(values);
      dispatch(
        updateBatch({
          batchId: values.id,
          batchProductionDateInText: values.productionDate,
          batchExpiryDateInText: values.expiryDate,
          batchCurrentStatus: values.status,
        })
      ).then((result) => {
        if (result?.errMsg) {
          // console.log(result);
          if (result.errMsg == "Product price already Exist") {
            // setOpenPopup({
            //   ...openPopup,
            //   title: "",
            //   subTitle: `${result.errMsg}`,
            //   isOpen: true,
            // });
          }

          action.setSubmitting(false);
        } else {
          setOpenPopup({
            ...openPopup,
            isOpen: false,
          });
        }
      });
    },
  });

  const changeValues = () => {
    var date = new Date(data.batchProductionDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const d = [date.getFullYear(), mnth, day].join("-");

    var date2 = new Date(data.batchExpiryDate),
      mnth2 = ("0" + (date.getMonth() + 1)).slice(-2),
      day2 = ("0" + date.getDate()).slice(-2);
    const d2 = [date.getFullYear(), mnth, day].join("-");
    formik.setValues({
      id: data.batchId,
      status: data.batchCurrentStatus,
      productionDate: d,
      expiryDate: d2,
    });
  };

  useEffect(() => {
    changeValues();
  }, [data]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Datepicker
            label="productionDate"
            name="productionDate"
            value={formik.values.productionDate}
            onChange={formik.handleChange}
          />
          <Datepicker
            label="expiryDate"
            name="expiryDate"
            value={formik.values.expiryDate}
            onChange={formik.handleChange}
          />
          <RadioGroup
            label="Status"
            name="status"
            value={formik.values.status}
            onChange={formik.handleChange}
            items={statusItems}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Button
              endIcon={
                loadingUpdate ? (
                  <CircularProgress size="1rem" color="error" />
                ) : (
                  <EditOutlinedIcon />
                )
              }
              type="submit"
              color="primary"
              size="large"
              text="Update"
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withRouter(EditBatch);

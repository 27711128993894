  import Axios from "axios";
  import { API_URL } from "../constants/apiConstants";
  import {
    PRODUCT_CREATE_FAIL,
    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    MAPPED_PRODUCT_LIST_FAIL,
    MAPPED_PRODUCT_LIST_REQUEST,
    MAPPED_PRODUCT_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    PRODUCT_UPDATE_FAIL,
    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,
    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_PRODUCTS_TOP_REQUEST,
    PRODUCT_PRODUCTS_TOP_SUCCESS,
    PRODUCT_PRODUCTS_TOP_FAIL,
    PRODUCT_PRICE_BY_ID_REQUEST,
    PRODUCT_PRICE_BY_ID_SUCCESS,
    PRODUCT_PRICE_BY_ID_FAIL,
    PRODUCT_PRICE_CREATE_REQUEST,
    PRODUCT_PRICE_CREATE_SUCCESS,
    PRODUCT_PRICE_CREATE_FAIL,
    PRODUCT_PRICE_LIST_REQUEST,
    PRODUCT_PRICE_LIST_SUCCESS,
    PRODUCT_PRICE_LIST_FAIL,
    PRODUCT_MAP_REQUEST,
    PRODUCT_MAP_SUCCESS,
    PRODUCT_MAP_FAIL,
    //function
    FUNCTION_LIST_REQUEST,
    FUNCTION_LIST_SUCCESS,
    FUNCTION_LIST_FAIL,

    FUNCTION_CREATE_SUCCESS,
    FUNCTION_CREATE_REQUEST,
    FUNCTION_CREATE_FAIL,
    FUNCTION_CREATE_RESET,

    FUNCTION_LIST_DETAILS_SUCCESS,
    FUNCTION_LIST_DETAILS_FAIL,
    FUNCTION_LIST_DETAILS_REQUEST,
    FUNCTION_LIST_DETAILS_RESET,
    
    FUNCTION_UPDATE_REQUEST,
    FUNCTION_UPDATE_RESET,
    FUNCTION_UPDATE_SUCCESS,
    FUNCTION_UPDATE_FAIL,

    FUNCTION_DELETE_REQUEST,
    FUNCTION_DELETE_RESET,
    FUNCTION_DELETE_SUCCESS,
    FUNCTION_DELETE_FAIL,

    //function
    COUNTRY_ORIGIN_LIST_REQUEST,
    COUNTRY_ORIGIN_LIST_SUCCESS,
    COUNTRY_ORIGIN_LIST_FAIL,
    BRAND_LIST_REQUEST,
    BRAND_LIST_SUCCESS,
    BRAND_LIST_FAIL,
    GROUP_LIST_REQUEST,
    GROUP_LIST_SUCCESS,
    GROUP_LIST_FAIL,
    CATEGORYBASEDPRODUCT_DETAILS_REQUEST,
    CATEGORYBASEDPRODUCT_DETAILS_SUCCESS,
    CATEGORYBASEDPRODUCT_DETAILS_FAIL,
    SAMPLEPRODUCT_UPDATE_REQUEST,
    SAMPLEPRODUCT_UPDATE_SUCCESS,
    SAMPLEPRODUCT_UPDATE_FAIL,
    PRICE_DETAILS_REQUEST,
    PRICE_DETAILS_SUCCESS,
    PRICE_DETAILS_FAIL,
    MAPPED_PRODUCTS_REQUEST,
    MAPPED_PRODUCTS_SUCCESS,
    MAPPED_PRODUCTS_FAIL,
  } from "../constants/productConstants";

  export const listProductDetails = (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: PRODUCT_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetProductById/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);

      // console.log(data[0]);

      dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data.dataObj });
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listProducts = () => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetProducts`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data.dataObj });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listCategories = () => async (dispatch, getState) => {
    try {
      dispatch({ type: CATEGORY_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetCategory`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      const final = data.dataObj.map((single) => {
        return {
          id: single.productCategoryId,
          label: single.productCategoryName,
        };
      });
      dispatch({ type: CATEGORY_LIST_SUCCESS, payload: final });
    } catch (error) {
      dispatch({
        type: CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listFunctions = () => async (dispatch, getState) => {
    try {
      dispatch({ type: FUNCTION_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/Product/GetFunction`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
        const { data } = await Axios(config);
        const final = data.dataObj.map((single) => {
        return {
            id: single.functionId,
          label: single.functionName,
        };
        });
      dispatch({ type: FUNCTION_LIST_SUCCESS, payload: final });
      //dispatch({ type: FUNCTION_LIST_SUCCESS, payload: data.dataObj });
    } catch (error) {
      dispatch({
        type: FUNCTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //PRODUCT FUNCTION STARTS HERE

  export const listProductFunctionsAction = () => async (dispatch, getState) => {
    try {
      dispatch({ type: FUNCTION_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/Product/GetFunction`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      
      dispatch({ type: FUNCTION_LIST_SUCCESS, payload: data.dataObj });
    } catch (error) {
      dispatch({
        type: FUNCTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //FUNCTION CREATE START
  export const createProductFunctionAction = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: FUNCTION_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/Product/AddProductFunction`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: FUNCTION_CREATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: FUNCTION_CREATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
    
      return finalGotData;
    } catch (error) {
      dispatch({
        type: FUNCTION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //function update start
  export const updateProductFunctionAction = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: FUNCTION_UPDATE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "put",

        url: `${API_URL}/Product/ProductFunctionUpdate`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: FUNCTION_UPDATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: FUNCTION_UPDATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });

      return finalGotData;
    } catch (error) {
      dispatch({
        type: FUNCTION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //FUNCTION GET DETAILS BY ID
  export const listProductFunctionDetailsAction = (id) => async (dispatch, getState) => {

    try {
      dispatch({ type: FUNCTION_LIST_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetProductFunctionById/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);
  
      //dispatch({ type: FUNCTION_LIST_DETAILS_SUCCESS, payload: data.dataObj[0] });
      dispatch({ type: FUNCTION_LIST_DETAILS_SUCCESS, payload: data.dataObj });
    } catch (error) {
      dispatch({
        type: FUNCTION_LIST_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //PRODUCT FUNCTION END HERE
  export const listCountryOrigins = () => async (dispatch, getState) => {
    try {
      dispatch({ type: COUNTRY_ORIGIN_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetCountryOrigin`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      const final = data.dataObj.map((single) => {
        return {
          id: single.cooId,
          label: single.countryOfOrigin,
        };
      });
      dispatch({ type: COUNTRY_ORIGIN_LIST_SUCCESS, payload: final });
    } catch (error) {
      dispatch({
        type: COUNTRY_ORIGIN_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listBrands = () => async (dispatch, getState) => {
    try {
      dispatch({ type: BRAND_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetBrand`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      const final = data.dataObj.map((single) => {
        return {
          id: single.brandId,
          label: single.brandName,
        };
      });
      dispatch({ type: BRAND_LIST_SUCCESS, payload: final });
    } catch (error) {
      dispatch({
        type: BRAND_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listGroups = () => async (dispatch, getState) => {
    try {
      dispatch({ type: GROUP_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetGroup`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      const final = data.dataObj.map((single) => {
        return {
          id: single.groupId,
          label: single.groupName,
        };
      });
      dispatch({ type: GROUP_LIST_SUCCESS, payload: final });
    } catch (error) {
      dispatch({
        type: GROUP_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const mappedListProducts = (id) => async (dispatch, getState) => {
    // console.log("id from action");
    // console.log(id);
    try {
      dispatch({ type: MAPPED_PRODUCT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetMappedList/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      const final = data.dataObj.map((single) => {
        return {
          id: single.productId,
          label: single.productName,
        };
      });
      dispatch({ type: MAPPED_PRODUCT_LIST_SUCCESS, payload: final });
    } catch (error) {
      dispatch({
        type: MAPPED_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const createProduct = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/Product/AddProduct`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: PRODUCT_CREATE_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      console.log("finalGotData");
      console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: PRODUCT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const updateProduct = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_UPDATE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      var config = {
        method: "put",

        url: `${API_URL}/Product/ProductUpdate`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      Axios(config).then(function (response) {
        dispatch({ type: PRODUCT_UPDATE_SUCCESS });
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const mapProduct = (id, dataArray) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_MAP_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      var config = {
        method: "put",

        url: `${API_URL}/Product/MapProduct/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(dataArray),
      };

      var finalGotData = await Axios(config)
        .then(function (response) {
          dispatch({
            type: PRODUCT_MAP_SUCCESS,
            payload: response.data.dataObj,
          });
          return response.data;
        })
        .catch(function (error) {
          dispatch({
            type: PRODUCT_MAP_FAIL,
            payload: error.response.data.errMsg,
          });
          return error.response.data;
        });
      // console.log("finalGotData");
      // console.log(finalGotData);
      return finalGotData;
    } catch (error) {
      dispatch({
        type: PRODUCT_MAP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const deleteProduct = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_DELETE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "delete",
        url: `${API_URL}/Product/DeleteProduct?id=${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await Axios(config);
      dispatch({ type: PRODUCT_DELETE_SUCCESS });
      //
    } catch (error) {
      dispatch({
        type: PRODUCT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //sell price

  export const listProductPriceById = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_PRICE_BY_ID_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        // /Purchase/GetPriceById/${id}
        url: `${API_URL}/Purchase/GetPriceById/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      dispatch({ type: PRODUCT_PRICE_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_PRICE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const createProductPrice = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_PRICE_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "post",

        url: `${API_URL}/Product/AddSellPrice`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      await Axios(config)
        .then(function (response) {
          // console.log(response);
          // console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });

      dispatch({ type: PRODUCT_PRICE_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_PRICE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listProductPrice = () => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_PRICE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Price/GetAllSellingPrice`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      console.log("dataObj");
      console.log(data.dataObj);
      dispatch({ type: PRODUCT_PRICE_LIST_SUCCESS, payload: data.dataObj.data });
    } catch (error) {
      dispatch({
        type: PRODUCT_PRICE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listProductDetailsBasedCategory =
    (id) => async (dispatch, getState) => {
      try {
        dispatch({ type: CATEGORYBASEDPRODUCT_DETAILS_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();

        var config = {
          method: "get",

          url: `${API_URL}/Product/GetProductByCatId/${id}`,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await Axios(config);

        console.log("data[0]");
        console.log(data);

        dispatch({
          type: CATEGORYBASEDPRODUCT_DETAILS_SUCCESS,
          payload: data.dataObj,
        });
        return data.dataObj;
      } catch (error) {
        dispatch({
          type: CATEGORYBASEDPRODUCT_DETAILS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

    export const listGetSamplesByCategory =
    (id) => async (dispatch, getState) => {
      try {
        dispatch({ type: CATEGORYBASEDPRODUCT_DETAILS_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();

        var config = {
          method: "get",

          url: `${API_URL}/Product/GetSample/${id}`,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await Axios(config);

        console.log("data[0]");
        console.log(data);

        dispatch({
          type: CATEGORYBASEDPRODUCT_DETAILS_SUCCESS,
          payload: data.dataObj,
        });
        return data.dataObj;
      } catch (error) {
        dispatch({
          type: CATEGORYBASEDPRODUCT_DETAILS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

  export const updateProductSamples = (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: SAMPLEPRODUCT_UPDATE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      var config = {
        method: "put",

        url: `${API_URL}/Product/SampleUpdate`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      Axios(config).then(function (response) {
        dispatch({ type: SAMPLEPRODUCT_UPDATE_SUCCESS });
      });
    } catch (error) {
      dispatch({
        type: SAMPLEPRODUCT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listPriceDetails = (id) => async (dispatch, getState) => {
    // console.log(id);
    try {
      dispatch({ type: PRICE_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Price/GetAllSellingPriceByProductId/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await Axios(config);

      console.log("data[0]");
      console.log(data);

      dispatch({
        type: PRICE_DETAILS_SUCCESS,
        payload: data.dataObj.data,
      });
    } catch (error) {
      dispatch({
        type: PRICE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const mappedProductsPerProduct = (id) => async (dispatch, getState) => {
    // console.log("id from action");
    // console.log(id);
    try {
      dispatch({ type: MAPPED_PRODUCTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",

        url: `${API_URL}/Product/GetProductMappedProducts/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await Axios(config);
      const final = data.dataObj.map((single) => {
        return {
          id: single.key,
          label: single.value,
        };
      });
      dispatch({ type: MAPPED_PRODUCTS_SUCCESS, payload: final });
      return final;
    } catch (error) {
      dispatch({
        type: MAPPED_PRODUCTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
